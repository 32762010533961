import { ColumnDef } from '@tanstack/react-table'
import { Flex } from '@/common/layout'
import { PdqStore } from '@/types/settings'
import { StoreActionDropdown } from './StoreActionDropdown'

export const columns: ColumnDef<PdqStore, any>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: row => row.name || row.registration?.store_id,
  },
  {
    header: 'Alias',
    accessorKey: 'alias',
  },
  {
    header: 'Roster Effective',
    id: 'roster-effective',
    accessorFn: row => row.registration?.roster_effective_date,
  },
  {
    header: 'Timekeeping Effective',
    id: 'timekeeping-effective',
    accessorFn: row => row.registration?.timekeeping_effective_date,
  },
  {
    header: 'Disbursements Effective',
    id: 'disbursements-effective',
    accessorFn: row => row.registration?.disbursement_effective_date,
  },
  {
    header: '',
    id: 'actions',
    cell: ({ row }) => (
      <Flex justify="center">
        <StoreActionDropdown original={row.original} />,
      </Flex>
    ),
    size: 50,
    enableSorting: false,
  },
]
