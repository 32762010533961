import { LockClosedIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { Button } from '@/common/button'
import {
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
  PageViewContainer,
} from '@/components/layout/page'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { AddRegistrationModal } from './AddRegistrationModal'
import { InboundEmailForm } from './InboundEmailForm'

type Props = {
  sidebar: React.ReactNode
}

export const FileProcessingContainer: FC<Props> = ({ children, sidebar }) => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const openAddRegistrationModal = () => {
    registerModal(<AddRegistrationModal />)
  }

  const openInboundEmailModal = () => registerModal(<InboundEmailForm />)

  return (
    <SettingsContainer>
      <PageViewContainer sidebar={sidebar}>
        <PageTop simple>
          <PageHeader>
            <PageTitle primary="File Processing" />
            <PageActions>
              <Button
                mode="filled"
                css={{ mr: '$4' }}
                onClick={openInboundEmailModal}
              >
                Edit Inbound Email Addresses
              </Button>
              <Button
                disabled={!isGlobalAdmin}
                mode="filled"
                onClick={openAddRegistrationModal}
                iconRight
                hideIcon={isGlobalAdmin}
              >
                Add Registration
                <LockClosedIcon />
              </Button>
            </PageActions>
          </PageHeader>
        </PageTop>
        <PageBody>{children}</PageBody>
      </PageViewContainer>
    </SettingsContainer>
  )
}
