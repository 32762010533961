import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { GET_PROCESSORS_QUERY } from '@/src//queries/file-processing'
import { IProcessor } from '@/types/settings'
import { IProcessorRow } from '../lib/subcolumns'

interface Props {
  original: IProcessorRow
}

export const ProcessorCell: FC<Props> = ({ original }) => {
  const queryClient = useQueryClient()
  const processors = queryClient.getQueryData<IProcessor[]>(
    GET_PROCESSORS_QUERY
  )
  const processorName = original.processor_name

  const processor = (processors || []).find(p => p.key === processorName)

  if (!processor) return null

  return <span>{processor.name}</span>
}
