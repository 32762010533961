import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input, InputIcon, TextArea } from '@/common/input'
import { Flex } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { FundingSourceSelect } from '@/components/funding-source-select/FundingSourceSelect'
import { InvoicePaymentType } from '@/types/invoice'

interface Props {
  onSubmit: (values: AddInvoiceFormValues) => void
}

export type AddInvoiceFormValues = {
  reason: string
  amount: number
  funding_source_id: number
  payment_type: InvoicePaymentType
}

export const defaultValues = {
  amount: 0,
  funding_source_id: 0,
  reason: '',
  payment_type: InvoicePaymentType.CREDIT,
}

export const invoicePaymentTypes = Object.keys(
  InvoicePaymentType
).sort() as Array<keyof typeof InvoicePaymentType>

const schema = yup.object().shape({
  reason: yup
    .string()
    .required('Reason required')
    .max(56),
  amount: yup.number().min(0.01, 'Must be at least $0.01'),
  funding_source_id: yup.string().required('Must select a funding source'),
  payment_type: yup.string().required('Must select a payment type'),
})

export const AddPaybacksForm: FC<Props> = ({ onSubmit }) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<AddInvoiceFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const paymentType = watch('payment_type')

  const invoicePaymentTypeOptions = invoicePaymentTypes.map(rec => ({
    value: InvoicePaymentType[rec],
    label: rec,
  }))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" align="start" css={{ marginBottom: '$12' }}>
        <Text as="label" htmlFor="amount">
          Amounts
        </Text>
        <Flex css={{ width: '100%' }}>
          <InputIcon>$</InputIcon>
          <Input
            {...register(`amount`)}
            step=".01"
            type="number"
            min="0"
            placeholder="0"
            iconLeft
          />
        </Flex>
        <Text color="alert">{errors.amount?.message}</Text>
      </Flex>
      <FundingSourceSelect
        selectedId={+watch('funding_source_id') || undefined}
        setSelectedId={id => {
          setValue('funding_source_id', id)
        }}
      />
      <Text color="alert">{errors.funding_source_id?.message}</Text>
      <Flex direction="column" align="start">
        <Text as="label" htmlFor="reason">
          Reason
        </Text>
        <TextArea {...register(`reason`)} placeholder="required" />
        <Text color="alert">{errors.reason?.message}</Text>
      </Flex>
      <Flex direction="column" align="start" css={{ marginTop: '$12' }}>
        <Text as="label" htmlFor="payment_type">
          Payment Type
        </Text>
        <Select
          options={invoicePaymentTypeOptions}
          value={paymentType}
          onChange={val => setValue('payment_type', val)}
        />
      </Flex>
      <Flex justify="end">
        <Button
          mode="filled"
          type="submit"
          css={{ mt: '$24' }}
          disabled={
            !!errors.amount?.message ||
            !!errors.funding_source_id?.message ||
            !!errors.reason?.message
          }
        >
          Continue
        </Button>
      </Flex>
    </form>
  )
}
