import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Flex } from '@/common/layout'
import {
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupItem,
  RadioGroupItemContainer,
} from '@/common/radio'
import { Text } from '@/common/text'
import { toTitleCase } from '@/lib/strings'
import { useUpdateOrgLocationSettings } from '@/queries/settings'
import { useToasterStore } from '@/store/toast'
import { EWA_TYPE } from '@/types/common'
import { SectionTitle } from './styles'

type GeneralSetupFormValues = {
  EWA_TYPE?: EWA_TYPE
}

interface Props {
  initialValue?: EWA_TYPE
}

const resetForm = (data?: EWA_TYPE) => ({ EWA_TYPE: data })

export const EwaType: FC<Props> = ({ initialValue }) => {
  const updateSettings = useUpdateOrgLocationSettings()
  const { addToast } = useToasterStore()
  const {
    formState: { isDirty },
    handleSubmit,
    setValue,
    watch,
    register,
    reset,
  } = useForm({
    defaultValues: resetForm(initialValue),
  })

  const onSubmitForm = async (value: GeneralSetupFormValues) => {
    if (!value) return
    updateSettings.mutate(
      { ewa_type: value.EWA_TYPE },
      {
        onSuccess: () => {
          addToast({
            type: 'success',
            title: `Updated organization's ewa_type to "${value.EWA_TYPE}".`,
          })
        },
        onError: () => {
          addToast({
            type: 'error',
            title:
              'Please try again soon, and if the issue persists, contact support.',
          })
        },
      }
    )
  }

  useEffect(() => {
    reset(resetForm(initialValue))
  }, [initialValue, reset])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <SectionTitle>Earned Wage Access (EWA)</SectionTitle>
      <RadioGroup
        defaultValue={watch('EWA_TYPE')}
        {...register('EWA_TYPE', {
          onChange: value => setValue('EWA_TYPE', value.target.value),
        })}
      >
        {(Object.keys(EWA_TYPE) as Array<keyof typeof EWA_TYPE>).map(val => (
          <RadioGroupItemContainer id={val} key={val}>
            <RadioGroupItem value={EWA_TYPE[val]} color="blue">
              <RadioGroupIndicator color="primary" />
            </RadioGroupItem>
            <Text>{toTitleCase(val.replaceAll('_', ' '))}</Text>
          </RadioGroupItemContainer>
        ))}
      </RadioGroup>
      <Flex>
        <Button
          type="submit"
          mode="filled"
          disabled={updateSettings.isLoading || !isDirty}
          size="md"
          css={{ ml: 'auto' }}
        >
          Save
        </Button>
      </Flex>
    </form>
  )
}
