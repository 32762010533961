import { ChevronDownIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { Box } from '../layout'

interface Props {
  pageSize: number
  onChange: (pageSize: number) => void
}

export const PageSizeSelector: FC<Props> = ({ pageSize, onChange }) => {
  const pageSizeOptions = [5, 10, 25, 50, 100]

  return (
    <Box css={{ mt: '1px' }}>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            size="lg"
            mode="flat"
            color="black"
            iconRight
            css={{ mr: '$8' }}
          >
            {pageSize} results per page
            <ChevronDownIcon
              style={{
                transform: 'scale(1.75)',
              }}
            ></ChevronDownIcon>
          </Button>
        </DropdownMenuTrigger>
        <DropdownContent>
          {pageSizeOptions.map(pageSize => (
            <DropdownMenuItem onClick={() => onChange(pageSize)} key={pageSize}>
              {pageSize}
            </DropdownMenuItem>
          ))}
        </DropdownContent>
      </DropdownMenu>
    </Box>
  )
}
