import { FC, ReactElement, ReactNode, useState } from 'react'
import { Button } from '@/common/button'
import { Flex } from '@/common/layout'
import { useModalStore } from '@/store/modal'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContainer,
  AlertDialogContentContainer,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertOverlay,
  AlertPortal,
} from './styles'

interface Props {
  trigger?: ReactNode
  title: string
  handleAction: () => Promise<any>
  actionDescription: string
  disabled?: boolean
  description?: string | ReactElement
  isDestructive?: boolean
}

export const AlertDialogContent: FC = ({ children, ...props }) => (
  <AlertPortal>
    <AlertOverlay />
    <AlertDialogContentContainer {...props}>
      {children}
    </AlertDialogContentContainer>
  </AlertPortal>
)

export const AlertDialog: FC<Props> = ({
  trigger,
  title,
  handleAction,
  actionDescription,
  disabled = false,
  description,
  isDestructive,
}) => {
  const { removeModal } = useModalStore()
  const [alertOpen, setAlertOpen] = useState(true)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    handleAction().then(() => close())
  }

  const close = () => {
    removeModal()
    setAlertOpen(false)
  }

  return (
    <AlertDialogContainer open={alertOpen}>
      <AlertDialogTrigger
        asChild
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {trigger}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        {description ? (
          <AlertDialogDescription>{description}</AlertDialogDescription>
        ) : null}
        <Flex justify="end">
          <AlertDialogCancel asChild>
            <Button
              mode="flat"
              color="black"
              css={{ mr: '$16' }}
              onClick={e => {
                e.stopPropagation()
                close()
              }}
            >
              Cancel
            </Button>
          </AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button
              mode="filled"
              color={isDestructive ? 'danger' : 'tertiary'}
              onClick={handleClick}
              disabled={disabled}
            >
              {actionDescription}
            </Button>
          </AlertDialogAction>
        </Flex>
      </AlertDialogContent>
    </AlertDialogContainer>
  )
}
