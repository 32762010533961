import { ILocation } from '@/types/location'

export enum STATUS_TYPES {
  SUCCEEDED,
  REVIEW,
  FAILED,
}

export interface IReviewRow {
  name: string
  alternate: string
  address: string
  city: string
  state: string
  postalCode: string
  country?: string
  status: STATUS_TYPES
  message: string
  duplicateId: string | null
  existingLocation: ILocation | null
}

// {
//   name: string
//   alternate: string
//   status: STATUS_TYPES
//   message: string
//   duplicateId: string | null
//   returnedRow: ILocation | null
// }
