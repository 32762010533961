import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import {
  DropdownContent,
  DropdownIconTrigger,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownSeparator,
} from '@/common/dropdown'
import { useNavigationTree } from '@/hooks/useNavigationTree'
import { NavItem } from './NavItem'

export const MobileNav = () => {
  const { permittedNavigationTree } = useNavigationTree()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownIconTrigger>
          <HamburgerMenuIcon />
        </DropdownIconTrigger>
      </DropdownMenuTrigger>
      <DropdownContent sideOffset={4}>
        {permittedNavigationTree.map(segment => {
          if (!segment.show) return null
          const nav = !segment.submenu.length ? (
            <NavItem item={segment} />
          ) : (
            segment.submenu.map(
              item => !item.isHidden && <NavItem item={item} key={item.route} />
            )
          )

          return (
            <div key={segment.route}>
              {nav}
              <DropdownSeparator />
            </div>
          )
        })}
      </DropdownContent>
    </DropdownMenu>
  )
}
