import { FC } from 'react'
import { RemittanceAdvances } from '@/types/remittances'
import { ViewDetailsLink } from './ViewDetailsLink'

interface Props {
  original: RemittanceAdvances
}

export const ViewDetailsCell: FC<Props> = ({ original }) => (
  <ViewDetailsLink advance={original} />
)
