import { RowSelectionState } from '@tanstack/react-table'
import { useState } from 'react'
import { PayoutRecord, PayoutStatus } from '@/types/payouts'

const { FAILED, PAYBACK_FAILED, CREATED, SCHEDULED, CANCELLED } = PayoutStatus

export const useMultiActionDisbursementHeader = () => {
  const [rowsSelected, setRowsSelected] = useState<RowSelectionState>({})
  const [selected, setSelected] = useState<PayoutRecord[]>([])

  const validRetryPayouts = selected.filter(({ status }) =>
    [FAILED, PAYBACK_FAILED, CREATED, CANCELLED].includes(status)
  )

  const setRetryPayouts = () => {
    setSelected(validRetryPayouts)
  }

  const toggleRetryPayouts = (record: PayoutRecord) => {
    if (selected.find((rec: PayoutRecord) => rec.id === record.id)) {
      setSelected(selected.filter(rec => rec.id !== record.id))
    } else {
      const newSelectedState = selected
      newSelectedState.push(record)
      setSelected(newSelectedState)
    }
  }

  const retryValid = selected.length === validRetryPayouts.length

  const validHidePayouts = selected.filter(({ status }) =>
    [FAILED, PAYBACK_FAILED].includes(status)
  )

  const hideValid = selected.length === validHidePayouts.length

  const validCancelPayouts = selected.filter(({ status }) =>
    [CREATED, SCHEDULED].includes(status)
  )

  const cancelValid = selected.length === validCancelPayouts.length

  return {
    rowsSelected,
    setRowsSelected,
    selected,
    setSelected,
    setRetryPayouts,
    toggleRetryPayouts,
    validRetryPayouts,
    validHidePayouts,
    validCancelPayouts,
    retryValid,
    hideValid,
    cancelValid,
  }
}
