const ORG_ROUTES = {
  ADVANCES_INSIGHTS: '/:orgKey/advances-insights',
  ADOPTION_INSIGHTS: '/:orgKey/adoption-insights',
  DIRECT_DEPOSITS: '/:orgKey/direct-deposits',
  WORKERS: '/:orgKey/workers',
  PAYROLL_PERIODS: '/:orgKey/payroll',
  LOCATIONS: '/:orgKey/locations',
  CASH_OUTS: '/:orgKey/cash-outs',
  CREATE_ACCOUNTS: '/:orgKey/create-accounts',
  INSTANT_CARDS: '/:orgKey/instant-cards',
  INSTANT_CARD_ORDERS: '/:orgKey/card-orders',
  ADVANCES: '/:orgKey/advances',
  TIMEKEEPING: '/:orgKey/timekeeping',
  GROSS_EARNINGS: '/:orgKey/gross-earnings',
  DISBURSEMENTS: '/:orgKey/disbursements',
  INVOICES: '/:orgKey/invoices',
  REMITTANCES: '/:orgKey/remittances',
  JOBS: '/:orgKey/jobs',
  REMITTANCE_COMPLETE: '/:orgKey/remittances/:id/complete',
  REMITTANCE: '/:orgKey/remittances/:id',
  RESOURCES: '/:orgKey/resources',
  OLD_PEOPLE: '/:orgKey/people',
  OLD_PAYOUTS: '/:orgKey/payouts',
  OLD_PAYBACKS: '/:orgKey/paybacks',
  OLD_REMITTANCE_COMPLETE: '/:orgKey/reports/deductions/:id/complete',
  OLD_REMITTANCE: '/:orgKey/reports/deductions/:id',
  OLD_EMPLOYEES: '/:orgKey/employees',

  SETTINGS: '/:orgKey/settings',
  FILE_PROCESSING_SETTINGS: '/:orgKey/file-processing',
  INTEGRATION: '/:orgKey/settings/integration',
  ORG_LOCATION_SETTINGS: '/:orgKey/location-settings',
  ROLES_PERMISSIONS_SETTINGS: '/:orgKey/role-permission-settings',
  EMBEDDED_ONBOARDING: '/:orgKey/embedded-onboarding',
  PAYMENTS_SETTINGS: '/:orgKey/payments-settings',
  ONBOARDING_SETTINGS: '/:orgKey/onboarding',
  SUPPORT_SETTINGS: '/:orgKey/support',
  INVOICE_CONFIGURATIONS: '/:orgKey/invoice-configurations',
  WEBHOOKS: '/:orgKey/webhooks',
  WEBHOOK_TESTING: '/:orgKey/webhook-testing',
  SSO_CONFIG: '/:orgKey/sso-config',
  TABLE_CONFIG: '/:orgKey/settings/table-configuration',
}

export const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  ORGANIZATION: '/:orgKey',
  ...ORG_ROUTES,
}

// these are URLs that should be matched and resolved
// to a previously cached organization if available,
// or the first organization matched if not.
export const OMITTED_ORG_URLS = Object.keys(ORG_ROUTES).map(key =>
  (ORG_ROUTES as { [k: string]: string })[key].replace('/:orgKey', '')
)
