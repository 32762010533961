import { FC } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { IconButton } from '@/common/button'
import { TrashIcon } from '@/common/icons'
import { useQsrRemoveLocation } from '@/src/queries/integrations'
import { QsrRegistrationForm } from './QsrIntegrationForm'
interface Props {
  storeIds: string[]
  currentStoreIds: string[]
  setNewStoreIds: UseFormSetValue<QsrRegistrationForm>
}

export const QsrStores: FC<Props> = ({
  currentStoreIds,
  storeIds,
  setNewStoreIds,
}) => {
  const removeLocation = useQsrRemoveLocation()
  if (!storeIds.length) {
    return <p>No locations.</p>
  }

  const handleRemove = (id: string) => {
    if (currentStoreIds.includes(id)) return removeLocation.mutate(id)
    setNewStoreIds(
      'store_ids',
      storeIds.filter(rec => rec !== id)
    )
  }

  return (
    <ul className="pl-0">
      {storeIds.map(rec => (
        <li key={rec} className="d-flex align-items-center">
          <IconButton className="mr-3" onClick={() => handleRemove(rec)}>
            <TrashIcon />
          </IconButton>
          {rec}
        </li>
      ))}
    </ul>
  )
}
