import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { uploadLocations } from '@/api/location'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { InputDropzone } from '@/components/file-upload/InputDropzone'
import { LocationUploadModal } from '@/components/location-uploader/LocationUploadModal'
import { useModalStore } from '@/store/modal'
import { downloadLocationsTemplate } from '../lib/downloadLocationsTemplate'

interface Props {
  handleClose: () => void
}

interface LocationsFormValues {
  files: File[]
  secondaryNs: string
}

const schema = yup.object().shape({
  files: yup
    .array()
    .min(1)
    .required(),
  secondaryNs: yup.string(),
})

const initialValues: LocationsFormValues = {
  files: [],
  secondaryNs: '',
}

export const AddLocationsModal: FC<Props> = ({ handleClose }) => {
  const { registerModal, removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const { id: orgId } = useCurrentOrg()

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm<LocationsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleDownloadTemplate = (e: React.MouseEvent) => {
    e.preventDefault()
    downloadLocationsTemplate()
  }

  const onSubmitForm = (values: LocationsFormValues) => {
    const { files, secondaryNs } = values
    close()
    registerModal(
      <LocationUploadModal
        files={files}
        secondaryNs={secondaryNs}
        onClose={handleClose}
        handleSubmit={() =>
          uploadLocations(
            {
              file: files[0],
              secondaryNs,
              overrides: {
                name: false,
                address: false,
                city: false,
                state: false,
                zip: false,
                organization: false,
              },
            },
            orgId
          )
        }
      />
    )
  }

  const description = (
    <p>
      Process multiple locations for this organization by uploading a file below
      that adheres to our{' '}
      <span className="link" onClick={handleDownloadTemplate}>
        location add template
      </span>
    </p>
  )

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Add Locations"
      description={description}
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <FlexGrid direction="column" fullWidth>
          <div>
            <InputDropzone
              handleDrop={files => setValue('files', files)}
              files={watch('files')}
              allowMultiple={false}
              label="Upload file (Drag n Drop or Select)"
              hasError={false}
              error=""
              supportedFileTypes={['csv']}
              subtext="Accepts CSV"
            />
            <Text color="alert" size="sm">
              {(errors.files as any)?.message}
            </Text>
          </div>
          <div>
            <Label>Secondary NS</Label>
            <Input {...register('secondaryNs')} />
            <Text color="alert" size="sm">
              {errors.secondaryNs?.message}
            </Text>
          </div>
        </FlexGrid>
        <Flex justify="end" css={{ mt: '$24' }}>
          <Button mode="filled" type="submit" disabled={false}>
            Add Locations
          </Button>
        </Flex>
      </form>
    </Dialog>
  )
}
