import { useEffect, useMemo } from 'react'
import { Table } from '@/common/table'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useCashouts } from '@/queries/cash-outs'
import { useCashoutsStore } from '@/store/cashouts'
import { CashOutsContainer } from './components/CashOutsContainer'
import { columns } from './lib/columns'

const initialSortedState = [
  {
    id: 'id',
    desc: true,
  },
]

export const CashOuts = () => {
  const {
    page,
    pageSize,
    sorted,
    setSorted,
    updatePage,
    isReady,
    ready,
  } = useCashoutsStore()

  useEffect(() => {
    if (ready) return
    setSorted(initialSortedState)
    isReady()
  }, [setSorted, isReady, ready])

  const { data, isLoading } = useCashouts()

  const cashoutData = useMemo(() => data?.content || [], [data])

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    data?.total_pages
  )

  return (
    <CashOutsContainer>
      <Table
        columns={columns}
        data={cashoutData}
        controlledPagination={true}
        sorted={sorted}
        onSortedChange={setSorted}
        initialSortedState={initialSortedState}
        isLoading={isLoading}
        page={page}
        pageRows={pageSize}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        changePage={updatePage}
      />
    </CashOutsContainer>
  )
}
