import { styled } from '@/styles/stitches.config'

export const CardInset = styled('div', {
  padding: '$16',
  margin: '$16 0',
  border: '1px dashed $tertiary500',
  borderRadius: '$lg',
})

export const CardInsetHeading = styled('h3', {
  fontSize: 16,
  fontWeight: 700,
  marginBottom: '$12',
  color: '$tertiary500',
})
