const SvgAdvances = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={48} height={49} {...props}>
    <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <circle fill="#2A515A54" cx={24} cy={24} r={24} />
      <g fill="#2A515A">
        <path d="M27.041 20.998a1.122 1.122 0 0 0 1.12 1.12 1.12 1.12 0 0 0 1.112-1.12 1.12 1.12 0 0 0-1.104-1.129.742.742 0 0 0-.133.01c-.56.062-.995.539-.995 1.119zM17.27 33.127c2.404 1.837 4.37 2.867 5.768 2.867h6.22c1.231 0 1.86-.34 2.262-1.558.529-2.649.88-5.237 1.073-7.838.002-1.112-.975-1.851-2.297-1.851h-5.883a.747.747 0 0 0-.744.75c0 .414.333.75.744.75h5.883c.578 0 .811.176.811.294a63.44 63.44 0 0 1-1.023 7.512c-.147.427-.174.442-.825.442h-6.221c-.967 0-2.715-.917-4.87-2.564a.74.74 0 0 0-1.042.145c-.248.33-.184.8.144 1.05zM21.256 22.112l-1.906 2.392a.643.643 0 0 1-.482.243H17.72a.747.747 0 0 0-.744.75c0 .414.333.75.744.75h1.174a2.138 2.138 0 0 0 1.617-.804l1.906-2.392a.754.754 0 0 0-.115-1.054.74.74 0 0 0-1.045.115z" />
        <path d="M16.975 34.5h-2.231a.747.747 0 0 0-.744.75c0 .413.333.75.744.75h2.48c.32.012.66-.126.902-.381.242-.256.363-.603.334-.955l.003-9.344c.026-.29-.095-.637-.337-.892a1.218 1.218 0 0 0-.932-.38h-2.45a.747.747 0 0 0-.744.749c0 .414.333.75.744.75h2.234l-.003 8.953zM28.558 13.5c.41 0 .743-.336.743-.75a.747.747 0 0 0-.743-.75h-7.492c-.373 0-.727.163-.97.447-.244.284-.354.66-.302 1.031l1.535 10.83c.059.41.435.695.842.636a.749.749 0 0 0 .631-.848L21.3 13.5h7.258z" />
        <path d="M31.472 16.537l-1.326 8.913c-.061.41.219.792.625.853a.745.745 0 0 0 .846-.63l1.368-9.198a1.254 1.254 0 0 0-.288-1.006 1.236 1.236 0 0 0-.952-.432h-6.834a1.233 1.233 0 0 0-.922.412c-.237.264-.35.617-.313.97l.908 9.152c.04.412.405.713.814.672a.748.748 0 0 0 .666-.82l-.882-8.886h6.29z" />
      </g>
    </g>
  </svg>
)

export default SvgAdvances
