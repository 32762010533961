import { IGetUserRolesResponse } from '@/api/types/employees'
import { IValidateTextCodeResponse, SignUpResponse } from '@/api/types/insights'
import { IGetOrganizationsResponse } from '@/api/types/organization'
import { ISignupOptions } from '@/types/auth'
import http from './http'
import { IGetUserDetailsResponse, ISignupBody } from './types/auth'

/**
 * Sends a text pin to the phone number provided when user is registering
 */
export const postSignupTextCode = (phone: string) =>
  http.post('/v2/signup/get-code', { phone_number: phone })

/**
 * Creates an account for a user
 */
export const signup = ({ invite_code, ...options }: ISignupOptions) => {
  const body: ISignupBody = {
    account_type: 'employee',
    employee_id: null,
    locations: [],
    ...options,
  }
  const path = `/v2/signup${invite_code ? `?invite_code=${invite_code}` : ''}`
  return http.post<SignUpResponse>(path, body)
}

/**
 * logout user from app
 */
export const logout = () => http.post('/v2/login/logout')

/**
 * Sends a text pin to the phone number provided
 */
export const postTextCode = (phone: string) =>
  http.post('/v2/login/get-code', { phone })

/**
 * Attempts to validate the text code sent from `postTextCode`
 */
export const validateTextCode = (phone: string, code: string) =>
  http.post<IValidateTextCodeResponse>('/v2/login/validate-code', {
    phone,
    code,
  })

/**
 * Retrieves the user's roles from our roles/permissions API
 * @param userId
 */
export const getUserRoles = (userId: string | null) =>
  http.get<IGetUserRolesResponse>(`/p1/users/${userId}/roles`)

/**
 * Retrieves organizations that the user has access to
 */
export const getOrganizations = (userId: string | null) =>
  http.get<IGetOrganizationsResponse>(`/p1/users/${userId}/organizations`)

/**
 * Retrieves a user's profile data
 * @param userId
 */
export const getUserDetails = (userId: string | null) =>
  http.get<IGetUserDetailsResponse>(`/p1/users/${userId}`)
