import qs from 'qs'
import { PaginatedIndefiniteData } from '@/types/common'

export const encodeQueryFromObject = (data: { [key: string]: string }) => {
  let query = ''
  for (let d in data)
    query += encodeURIComponent(d) + '=' + encodeURIComponent(data[d]) + '&'
  return query.slice(0, -1)
}

export const encodeQueryFromArray = (data: (string | number)[][]) => {
  let query = ''

  data.forEach(d => {
    query += encodeURIComponent(d[0]) + '=' + encodeURIComponent(d[1]) + '&'
  })

  return query.slice(0, -1)
}

export const getQueryParamObjectFromString = (paramString: string) => {
  if (!paramString) return null
  let str = paramString[0] === '?' ? paramString.substring(1) : paramString
  return qs.parse(str)
}

export const parseRouteParams = <T>(route: string | null): T => {
  if (!route) return {} as T

  const queryString = route.split('?')[1]
  const params = queryString.split('&')

  return params.reduce((acc, curr) => {
    const keyValue = curr.split('=')
    return {
      ...acc,
      [keyValue[0]]: keyValue[1],
    }
  }, {} as T)
}

export const parsePaginationParams = <T>(
  data: PaginatedIndefiniteData<any>
) => ({
  ...data,
  nextParams: parseRouteParams<T>(data.next_page),
  prevParams: parseRouteParams<T>(data.prev_page),
})
