const SvgAdvancedAllTime = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={48} height={49} {...props}>
    <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <circle fill="#5A3E2A54" cx={24} cy={24} r={24} />
      <g fill="#5A3E2A">
        <path d="M25.501 22.5h-2.033a2.092 2.092 0 0 0-.78 4.033l2.066.826a.592.592 0 0 1-.222 1.141h-2.03a.75.75 0 1 0 0 1.5h2.03a2.092 2.092 0 0 0 .78-4.033l-2.065-.826A.592.592 0 0 1 23.469 24H25.5a.75.75 0 0 0 0-1.5z" />
        <path d="M24.751 30.75v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 1 0 1.5 0zM24.751 23.25v-1.5a.75.75 0 0 0-1.5 0v1.5a.75.75 0 1 0 1.5 0z" />
        <path d="M18.751 34.5a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .08-.335l2.25-4.5A.75.75 0 0 1 21 13.5h6a.75.75 0 0 1 .671.415l2.25 4.5a.75.75 0 0 1 .08.335v15a.75.75 0 0 1-.75.75h-10.5zm.75-1.5h9V18.927L26.538 15h-5.073L19.5 18.927V33z" />
        <path d="M13.501 33V18.75a.75.75 0 1 0-1.5 0v15c0 .414.336.75.75.75h6a.75.75 0 1 0 0-1.5h-5.25zM31.85 15l2 3H29.25a.75.75 0 1 0 0 1.5h6a.75.75 0 0 0 .624-1.166l-3-4.5a.75.75 0 0 0-.624-.334h-5.25a.75.75 0 1 0 0 1.5h4.849z" />
        <path d="M16.153 15H21a.75.75 0 1 0 0-1.5h-5.25a.75.75 0 0 0-.624.334l-3 4.5a.75.75 0 0 0 .624 1.166h6a.75.75 0 1 0 0-1.5h-4.598l2-3zM12.751 23.25h6a.75.75 0 1 0 0-1.5h-6a.75.75 0 0 0 0 1.5zM29.251 23.25h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 1 0 0 1.5zM12.751 27h6a.75.75 0 1 0 0-1.5h-6a.75.75 0 1 0 0 1.5zM29.251 27h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 1 0 0 1.5zM12.751 30.75h6a.75.75 0 1 0 0-1.5h-6a.75.75 0 1 0 0 1.5zM29.251 30.75h6a.75.75 0 0 0 0-1.5h-6a.75.75 0 1 0 0 1.5z" />
        <path d="M34.501 33h-5.25a.75.75 0 1 0 0 1.5h6a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-1.5 0V33zM29.251 18h-10.5a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 0-1.5z" />
      </g>
    </g>
  </svg>
)

export default SvgAdvancedAllTime
