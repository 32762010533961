import { FC, useEffect } from 'react'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { Table } from '@/common/table/Table'
import { useSharedPartnerRoster } from '@/hooks/usePartnerRoster'
import { useIndefiniteTablePagination } from '@/hooks/useTablePagination'
import { useRosterQuery } from '@/queries/employees'
import { useRosterStore } from '@/store/roster'
import { IOrganizationEmployee } from '@/types/employees'
import { rosterColumns } from '../lib/roster-columns'
import { useEnrollmentMatching } from '../lib/useEnrollmentMatching'
import { useOptionalRosterColumns } from '../lib/useOptionalKeys'
import { EmployeeFilters } from './EmployeeFilters'

interface TableProps {
  openEmployeeSidebar: (user: IOrganizationEmployee, id: number) => void
  search: string
}

const initialSortedState = [
  {
    id: 'id',
    desc: false,
  },
]

export const EmployeeRosterTable: FC<TableProps> = ({
  openEmployeeSidebar,
  search,
}) => {
  const {
    page,
    pageSize,
    updatePageSize,
    sorted,
    computed: { filterArray },
    updatePage,
    setSorted,
    setPaginationParams,
    isReady,
    ready,
    updateFilters,
  } = useRosterStore()

  useEffect(() => {
    if (ready) return
    setSorted(initialSortedState)
    isReady()
  }, [isReady, setSorted, ready])

  useEffect(() => {
    updateFilters({
      name: search,
      desc: false,
    })
  }, [updateFilters, search])

  const { data, isLoading } = useRosterQuery()

  const readOnly = useSharedPartnerRoster()

  const changePage = (nextPage: number) => {
    if (!data) return
    const pageParams = nextPage > page ? data.nextParams : data.prevParams
    const params = nextPage ? pageParams : undefined

    updatePage(nextPage)
    setPaginationParams(params)
  }

  const { hasNextPage, hasPrevPage } = useIndefiniteTablePagination(
    data?.has_next_page,
    data?.has_prev_page
  )

  const optionalColumns = useOptionalRosterColumns()
  const rosterData = useEnrollmentMatching(data?.content)

  return (
    <div className="mt-4">
      {!data?.content.length && !filterArray.length && !isLoading ? (
        <ContentEmpty
          icon={<EmptyTableIcon />}
          title="We haven't added anyone yet"
          subtitle="Once we receive a roster, we can start showing you data here."
          height="sm"
        />
      ) : (
        <>
          <div className="d-flex justify-content-between mb-2">
            <h4 className="mb-2">Roster</h4>
            <div className="d-inline-flex">
              <EmployeeFilters sorted={sorted} />
            </div>
          </div>
          <Table
            columns={rosterColumns}
            data={rosterData}
            controlledPagination={true}
            sorted={sorted}
            onSortedChange={setSorted}
            hiddenColumns={optionalColumns}
            initialSortedState={initialSortedState}
            isLoading={isLoading}
            page={page}
            pageRows={pageSize}
            updatePageSize={updatePageSize}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            changePage={changePage}
            rowOnClickFn={openEmployeeSidebar}
            hideLastColumnBorder={!readOnly}
          />
        </>
      )}
    </div>
  )
}
