import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { QsrRegistrationResponse } from '@/api/types/settings'
import { Button, IconButton } from '@/common/button'
import { Card } from '@/common/card'
import { AddIcon } from '@/common/icons'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { YearMonthDayRegex } from '@/lib/regex'
import { useQsrCreateRegistration } from '@/src/queries/integrations'
import { QsrStores } from './QsrStores'

export type QsrRegistrationForm = {
  timekeeping_effective_date: string
  roster_effective_date: string
  store_ids: string[]
}

const schema = yup.object().shape({
  roster_effective_date: yup
    .string()
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
  timekeeping_effective_date: yup
    .string()
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
})

interface Props {
  data?: QsrRegistrationResponse
}

const resetForm = (data?: QsrRegistrationResponse) => ({
  timekeeping_effective_date: !!data
    ? moment(data.timekeeping_effective_date).format('YYYY-MM-DD')
    : '',
  roster_effective_date: !!data
    ? moment(data.roster_effective_date).format('YYYY-MM-DD')
    : '',
  store_ids: data?.store_ids || [],
})

export const QsrIntegrationForm: FC<Props> = ({ data }) => {
  const [storeId, setStoreId] = useState('')
  const createRegistration = useQsrCreateRegistration()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: resetForm(data),
  })

  const onFormSubmit = (values: QsrRegistrationForm) => {
    const payload = {
      ...values,
      timekeeping_effective_date: moment(
        values.timekeeping_effective_date
      ).format(),
      roster_effective_date: moment(values.roster_effective_date).format(),
    }
    createRegistration.mutate(payload)
  }

  useEffect(() => {
    reset(resetForm(data))
  }, [data, reset])

  return (
    <Card css={{ mt: '$24' }}>
      <FlexGrid
        align="start"
        direction="column"
        fullWidth
        size="lg"
        css={{
          'div > *:not(:last-child)': {
            mb: '$8',
          },
        }}
      >
        <div>
          <Text as="h5">Configure Integration</Text>
        </div>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div>
            <Label htmlFor="roster_effective_date">Roster Effective Date</Label>
            <Input
              type="text"
              {...register('roster_effective_date')}
              placeholder="2021-12-30"
            />
            <Text color="alert" size="sm">
              {errors.roster_effective_date?.message}
            </Text>
          </div>
          <div>
            <Label htmlFor="timekeeping_effective_date">
              Timekeeping Effective Date
            </Label>
            <Input type="text" {...register('timekeeping_effective_date')} />
            <Text color="alert" size="sm">
              {errors.timekeeping_effective_date?.message}
            </Text>
          </div>
          <div>
            <Label>Add Location</Label>
            <div className="d-flex align-items-center">
              <Input
                type="text"
                name="addLocation"
                onChange={e => setStoreId(e.target.value)}
                placeholder="0000001"
                value={storeId}
              />
              <IconButton
                disabled={storeId.length !== 7}
                onClick={() => {
                  const newStoreIds = getValues('store_ids')
                  newStoreIds.push(storeId)
                  setValue('store_ids', newStoreIds)
                  setStoreId('')
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
          <div>
            <QsrStores
              currentStoreIds={data?.store_ids || []}
              storeIds={watch('store_ids') || []}
              setNewStoreIds={setValue}
            />
          </div>
          <Flex justify="end">
            <Button
              mode="filled"
              type="submit"
              loading={createRegistration.isLoading}
              disabled={
                (data?.store_ids || []).length === watch('store_ids')?.length ||
                createRegistration.isLoading
              }
            >
              Update
            </Button>
          </Flex>
        </form>
      </FlexGrid>
    </Card>
  )
}
