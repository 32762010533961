import { FC } from 'react'
import { DetailsView, WorkerDetails } from '@/common/detail'
import { IOrganizationEmployee } from '@/types/employees'

interface Props {
  user: IOrganizationEmployee
  remove: () => void
}

export const EmployeeDetailsView: FC<Props> = ({ user, remove }) => (
  <DetailsView>
    <WorkerDetails user={user} remove={remove} />
  </DetailsView>
)
