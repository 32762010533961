export enum PaybackStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  FINALIZED = 'FINALIZED',
  BILLED = 'BILLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface PaybackRecord {
  id: number
  organization_id: number
  correlation_id: string
  amount: number | null
  description: string
  org_transfer_id: number | null
  funding_source_id: number
  status: PaybackStatus
  time_transitioned: string
  file_location: null | string
  close_time: string
  bill_time: string
  time_created: string
  time_modified: string
}

export type PaybackSortableFields =
  | 'id'
  | 'funding_source_id'
  | 'amount'
  | 'close_time'
  | 'bill_time'
  | 'time_created'

export interface DisbursementSummaryItem {
  id: string
  amount: number
}
