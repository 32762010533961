import * as RadioGroup from '@radix-ui/react-radio-group'
import { styled } from '@/styles/stitches.config'

export const StyledRadioGroup = styled(RadioGroup.Root, {})

export const StyledRadioGroupItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '12px 0',
})

export const StyledRadioGroupItem = styled(RadioGroup.Item, {
  all: 'unset',
  backgroundColor: 'white',
  size: 10,
  borderRadius: '100%',
  border: '2px solid $tertiary500',
  marginRight: '.3rem',
  '&:focus': { boxShadow: `0 0 0 2px $colors$tertiary500` },
  variants: {
    color: {
      blue: {
        border: '2px solid $primary500',
        '&:focus': { boxShadow: `$focus` },
      },
    },
  },
})

export const StyledRadioGroupIndicator = styled(RadioGroup.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    size: 8,
    borderRadius: '50%',
    backgroundColor: '$primary500',
  },
})
