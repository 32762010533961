import {
  ColumnOrderState,
  ColumnResizeMode,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import { useTablePagination } from '@/hooks/useTablePagination'
import { SimpleTableProps } from '@/types/table'
import { Flex } from '../layout'
import { PageSizeSelector } from './PageSizeSelector'
import { TableContainer } from './styles'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { TableLoadingComponent } from './TableLoadingComponent'
import { TablePagination } from './TablePagination'

const DEFAULT_PAGE_SIZE = 25

export const SimpleTable = <TData extends Object>({
  data,
  columns,
  isLoading,
  renderSubComponent,
  selected,
  setSelected,
  enableRowSelection = true,
  initialSortedState = [],
  hideLastColumnBorder = false,
  showPageSizeSelector = false,
  columnOrderState = [],
  hiddenColumns,
  size = DEFAULT_PAGE_SIZE,
}: SimpleTableProps<TData>) => {
  const [sorting, setSorting] = useState<SortingState>(initialSortedState)
  const [pageSize, setPageSize] = useState<number>(size)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: size,
  })
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [columnResizeMode] = useState<ColumnResizeMode>('onChange')
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    columnOrderState
  )

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getRowCanExpand: () => true,
    state: {
      sorting,
      pagination,
      expanded,
      rowSelection: selected,
      columnVisibility: hiddenColumns,
      columnOrder: columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    getRowId: (row: TData) => (row as any).id,
    enableRowSelection: enableRowSelection,
    onRowSelectionChange: setSelected,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
  })

  const {
    previousPageDisabled,
    nextPageDisabled,
    advancePage,
    previousPage,
    pageNumber,
  } = useTablePagination({
    table,
    page: pagination.pageIndex,
  })

  return (
    <Flex direction="column" css={{ height: '100%' }}>
      <TableContainer hideLastColumnBorder={hideLastColumnBorder}>
        <table>
          <TableHeader
            table={table}
            sorting={sorting}
            columnResizeMode={columnResizeMode}
          />
          <TableBody table={table} renderSubComponent={renderSubComponent} />
        </table>
        <TableLoadingComponent isLoading={isLoading} dataSize={data.length} />
      </TableContainer>
      <Flex css={{ mt: '$16', ml: 'auto' }}>
        <TablePagination
          previousPageDisabled={previousPageDisabled}
          previousPage={previousPage}
          nextPageDisabled={nextPageDisabled}
          nextPage={advancePage}
          pageNumber={pageNumber}
        />
        {showPageSizeSelector && (
          <PageSizeSelector pageSize={pageSize} onChange={setPageSize} />
        )}
      </Flex>
    </Flex>
  )
}
