import { FC, useMemo, useState } from 'react'
import { Button } from '@/common/button'
import { Checkbox } from '@/common/checkbox'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import { YearMonthDayRegex } from '@/lib/regex'
import { useRunPdqRegistrationJob } from '@/src/queries/pdq'
import { useModalStore } from '@/store/modal'
import { PdqStore } from '@/types/settings'

interface Props {
  store?: PdqStore
}

const initTypes = (store?: PdqStore) => {
  const types = [
    {
      id: 'ROSTER',
      label: 'Roster',
      isChecked: !!store?.registration?.roster_effective_date,
    },
    {
      id: 'TIMEKEEPING',
      label: 'Timekeeping',
      isChecked: !!store?.registration?.timekeeping_effective_date,
    },
    {
      id: 'DISBURSEMENT',
      label: 'Disbursement',
      isChecked: !!store?.registration?.disbursement_effective_date,
    },
  ]

  if (store) {
    return types.filter(t => t.id !== 'ROSTER')
  }

  return types
}

export const RunJobModal: FC<Props> = ({ store }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const mutation = useRunPdqRegistrationJob()
  const [types, setTypes] = useState(initTypes(store))
  const [date, setDate] = useState('')
  const [dateIsValid, setDateIsValid] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  // we need to hide the date field
  // when only the "roster" checkbox is selected
  const onlyRosterSelected = useMemo(() => {
    const filteredTypes = types.filter(t => t.isChecked)
    return filteredTypes.length === 1 && filteredTypes[0].id === 'ROSTER'
  }, [types])

  const toggleType = (id: string) => {
    const updated = types.map(t =>
      t.id === id ? { ...t, isChecked: !t.isChecked } : t
    )
    setTypes(updated)
  }

  const handleSubmit = () => {
    const runTypes = types.filter(t => t.isChecked).map(t => t.id)

    return mutation.mutate(
      {
        store_id: store?.id,
        types: runTypes,
        date: !onlyRosterSelected && date ? date : undefined,
      },
      {
        onSuccess: close,
      }
    )
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    const valid = !value || YearMonthDayRegex.test(value)

    setDateIsValid(valid)
    setDate(e.currentTarget.value)
  }

  return (
    <Dialog open={open} setOpen={close} title="Run Jobs">
      <p>
        Running jobs for <strong>{!!store ? store.alias : 'all stores'}</strong>
        .
      </p>
      <FlexGrid direction="column" align="start" css={{ mb: '$16' }}>
        {types.map(type => (
          <Flex key={type.id}>
            <Checkbox
              id={type.id}
              checked={type.isChecked}
              onChange={() => toggleType(type.id)}
            />
            <Label css={{ ml: '$12' }}>{type.id} </Label>
          </Flex>
        ))}
      </FlexGrid>
      {!onlyRosterSelected && (
        <div>
          <Label>Date (optional)</Label>
          <Input
            type="text"
            value={date}
            onChange={handleDateChange}
            placeholder="YYYY-MM-DD"
          />
          {!dateIsValid && (
            <Text color="alert">Date must be in YYYY-MM-DD format</Text>
          )}
        </div>
      )}
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button
          mode="filled"
          onClick={handleSubmit}
          disabled={!dateIsValid || types.every(t => !t.isChecked)}
          loading={mutation.isLoading}
        >
          Submit
        </Button>
      </Flex>
    </Dialog>
  )
}
