const SvgTrash = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M18.097 8.25c.115 0 .225.05.302.136a.422.422 0 01.106.318l-1.052 11.78A1.648 1.648 0 0115.823 22H8.176a1.648 1.648 0 01-1.63-1.516L5.493 8.704a.422.422 0 01.106-.319.406.406 0 01.303-.135zm-8.14 2.708a.62.62 0 00-.614.625v7.5a.62.62 0 00.613.625.62.62 0 00.614-.625v-7.5a.62.62 0 00-.614-.625zm4.09 0a.62.62 0 00-.614.625v7.5a.62.62 0 00.614.625.62.62 0 00.614-.625v-7.5a.62.62 0 00-.614-.625zM14.045 2c1.13 0 2.046.933 2.046 2.083v1.042c0 .115.091.208.204.208h3.887c.452 0 .818.373.818.834 0 .46-.366.833-.818.833H3.818A.826.826 0 013 6.167c0-.46.366-.834.818-.834h3.887a.203.203 0 00.144-.06.21.21 0 00.06-.148V4.083C7.91 2.933 8.825 2 9.955 2zm0 1.667h-4.09a.413.413 0 00-.41.416v1.042c0 .115.092.208.205.208h4.5a.206.206 0 00.205-.208V4.083a.413.413 0 00-.41-.416z"
        fill="#212121"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgTrash
