import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'

interface Props {
  value: number
}

export const ViewCardsCell: FC<Props> = ({ value }) => {
  const path = useOrgPath(ROUTES.INSTANT_CARDS)
  return <Link to={`${path}?filter_order_id=${value}`}>View Cards</Link>
}
