import {
  IGetAdoptionTimeSeriesDataConfig,
  IGetAdvancesTimeSeriesDataConfig,
} from '@/types/insights'
import http from './http'
import {
  IAdoptionTimeSeriesResponse,
  IAdvancesTimeSeriesResponse,
} from './types/insights'

/**
 * Allows customer to view adoption aggregate
 */
export const getAdoptionTimeSeries = (
  orgId: number,
  config: IGetAdoptionTimeSeriesDataConfig
) => {
  const params = {
    end: config.end.format(),
    start: config.start.format(),
    organization_id: orgId,
    region: config.region,
    interval: config.interval,
  }

  return http.post<IAdoptionTimeSeriesResponse>(
    `v2/proto/reports/pay/adoption/get_time_series`,
    params
  )
}

/**
 * Allows customer to view advances grouped by day
 */
export const getAdvancesTimeSeries = (
  orgId: number,
  config: IGetAdvancesTimeSeriesDataConfig
) => {
  const params = {
    end: config.end.format(),
    start: config.start.format(),
    organization_id: orgId,
    region: config.region,
    interval: config.interval,
  }

  return http.post<IAdvancesTimeSeriesResponse>(
    `v2/proto/reports/pay/advances/get_time_series`,
    params
  )
}
