import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { Flex } from '../layout'

interface Props {
  value?: string | number
  defaultLabel: string
  handleChange: (value?: string | number) => void
  options: { label: string; value: string | number }[]
}

export const OptionFilter: FC<Props> = ({
  value,
  options,
  handleChange,
  defaultLabel,
}) => {
  const selectedOption = options.find(option => option.value === value)
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button mode="outline" color="black" iconRight>
          {selectedOption?.label || defaultLabel}
          <ChevronDownIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownContent>
        <DropdownMenuItem onClick={() => handleChange('')} key={'all'}>
          <Flex direction="row" justify="between">
            {'All'}
            {!selectedOption?.value ? <CheckIcon /> : null}
          </Flex>
        </DropdownMenuItem>
        {options.map(option => {
          return (
            <DropdownMenuItem
              onClick={() => handleChange(option.value)}
              key={option.value}
            >
              <Flex direction="row" justify="between">
                {option.label}
                {selectedOption?.value === option.value ? <CheckIcon /> : null}
              </Flex>
            </DropdownMenuItem>
          )
        })}
      </DropdownContent>
    </DropdownMenu>
  )
}
