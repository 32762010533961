import { useState } from 'react'
import { generatePath, Redirect, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Alert } from '@/common/alert'
import { Text } from '@/common/text'
import { handleHttpException } from '@/lib/httpException'
import { useGetTextCodeQuery, useValidateTextCodeQuery } from '@/queries/auth'
import { ROUTES } from '@/router/routes'
import { getCachedCurrentOrganizationKey } from '@/src/sessionstorage'
import { AuthenticatePinForm } from './AuthenticatePinForm'
import { SendPinForm } from './SendPinForm'
import { LoginContainer } from './styles'

export const Login = () => {
  const { state } = useLocation<
    { from?: Location; orgKey?: string } | undefined
  >()
  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [showPinAuth, setShowPinAuth] = useState(false)
  const [phone, setPhone] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const getTextCode = useGetTextCodeQuery()
  const validateTextCode = useValidateTextCodeQuery()

  if (redirectToReferrer) {
    const orgKey = getCachedCurrentOrganizationKey()

    let path = '/workers'
    if (state?.from) {
      path = state.from.pathname
    }
    if (orgKey) {
      path = generatePath(ROUTES.WORKERS, {
        orgKey,
      })
    }
    return <Redirect to={path} />
  }

  const handleSendPin = (phone: string) => {
    setErrorMessage('')
    getTextCode.mutate(phone, {
      onSettled: () => {
        setPhone(phone)
        setShowPinAuth(true)
      },
    })
  }

  const handleSubmitPin = async (code: string) => {
    setErrorMessage('')
    try {
      const payload = { phone, code }
      await validateTextCode.mutateAsync(payload, {
        onError: (err: any) => {
          handleHttpException(err, {
            onHttpError: ({ response }) => {
              const errorDetail = response?.data?.meta?.errorDetail
              const errorMessage = response?.data?.meta?.message
              if (errorMessage === 'Too Many Requests') {
                setShowPinAuth(false)
                throw new Error(
                  'Too Many Attempts to Login. Please try again in a few minutes.'
                )
              }
              if (errorDetail) {
                throw new Error(errorDetail)
              }
            },
            onOtherError: () => {
              throw new Error(
                'An unknown error occured trying to validate your code'
              )
            },
          })
        },
      })
      setRedirectToReferrer(true)
    } catch (e) {
      const error = e as Error
      setErrorMessage(error.message)
    }
  }
  return (
    <LoginContainer>
      {!showPinAuth && (
        <SendPinForm
          handleSendPin={handleSendPin}
          title="Log in to Pay Admin"
          loading={getTextCode.isLoading}
        />
      )}
      {showPinAuth && (
        <AuthenticatePinForm
          phone={phone}
          onSubmit={handleSubmitPin}
          isAuthenticating={validateTextCode.isLoading}
          handleSendPin={handleSendPin}
          setShowPinAuth={setShowPinAuth}
        />
      )}
      {!!errorMessage && (
        <Alert type="danger" css={{ mt: '$24' }}>
          {errorMessage}
        </Alert>
      )}
      <Text css={{ display: 'block', mt: '$16' }}>
        New to Pay Admin? <Link to={ROUTES.REGISTER}>Register</Link>
      </Text>
    </LoginContainer>
  )
}
