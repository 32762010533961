import moment from 'moment'
import { FC } from 'react'
import { IconButton } from '@/common/button'
import {
  DetailActions,
  DetailHeader,
  DetailHeading,
  DetailProperty,
  DetailSection,
  DetailSeperator,
} from '@/common/detail'
import { CloseIcon } from '@/common/icons'
import { toDollar } from '@/lib/currency'
import { PaybackRecord } from '@/types/paybacks'
import { InvoiceNotes } from './InvoiceNotes'
import { PaybackAmount } from './PaybackAmount'
import { PaybackFundingSource } from './PaybackFundingSource'
import { StatusBadge } from './StatusBadge'
import { StoreSummary } from './StoreSummary'
import { ViewDetailsLink } from './ViewDetailsLink'

interface Props {
  payback: PaybackRecord
  remove: () => void
}

export const PaybackDetailsView: FC<Props> = ({ payback, remove }) => (
  <>
    <DetailHeader>
      <DetailHeading>Invoice Details</DetailHeading>
      <DetailActions>
        <IconButton onClick={remove}>
          <CloseIcon />
        </IconButton>
      </DetailActions>
    </DetailHeader>

    <DetailSeperator />

    <DetailSection>
      <DetailProperty label="ID" value={payback.id} />

      <DetailProperty
        label="Invoice Date"
        value={moment(payback.time_created).format('MM/DD/YYYY')}
      />

      <DetailProperty label="Description" value={payback.description} />

      <DetailProperty
        label="Total"
        value={toDollar(payback.amount)}
        renderValue={value => <PaybackAmount value={value} payback={payback} />}
      />

      <DetailProperty
        label="Effective Date"
        value={moment(payback.bill_time).format('MM/DD/YYYY')}
      />

      <DetailProperty
        label="Status"
        value={payback.status}
        renderValue={() => <StatusBadge payback={payback} badgeSize="sm" />}
      />

      {payback.close_time && (
        <DetailProperty
          label="Payment Date"
          value={moment(payback.close_time).format('MM/DD/YYYY')}
        />
      )}

      <DetailProperty
        label="Details"
        value={payback.id}
        renderValue={() => <ViewDetailsLink payback={payback} />}
      />
    </DetailSection>
    <DetailSection>
      <PaybackFundingSource payback={payback} />
    </DetailSection>
    <DetailSection>
      <StoreSummary payback={payback} />
    </DetailSection>
    <DetailSection>
      <InvoiceNotes payback={payback} />
    </DetailSection>
  </>
)
