import { yupResolver } from '@hookform/resolvers/yup'
import moment, { Moment } from 'moment'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { SingleDatePicker } from '@/common/datepicker'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { IPayrollRecord } from '@/queries/payroll'
import { generatePayroll } from '../lib/generate-payroll'
import { DatePickerContainer } from './styles'

interface Props {
  setPendingPayroll: (pendingPayroll: IPayrollRecord[]) => void
}

const PayPeriodOptions = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-Weekly', value: 'biweekly' },
  { label: 'Semi-Monthly', value: 'semi-monthly' },
  { label: 'Monthly', value: 'monthly' },
]

export type PayrollGenerationFormValues = {
  payPeriodType: string
  deadline1: Date | null
  payDate1: Date | null
  deadline2: Date | null
  payDate2: Date | null
}

export const PayrollGenerationFormInitialValues: PayrollGenerationFormValues = {
  payPeriodType: '',
  deadline1: null,
  payDate1: null,
  deadline2: null,
  payDate2: null,
}

const schema = yup.object().shape({
  payPeriodType: yup.string().required('Required'),
  deadline1: yup.mixed<Moment>().required('Required'),
  payDate1: yup.mixed<Moment>().required('Required'),
  deadline2: yup.mixed<Moment>().when('payPeriodType', {
    is: 'semi-monthly',
    then: yup.mixed<Moment>().required('Required'),
  }),
  payDate2: yup.mixed<Moment>().when('payPeriodType', {
    is: 'semi-monthly',
    then: yup.mixed<Moment>().required('Required'),
  }),
})

export const PayrollGenerationForm: FC<Props> = ({ setPendingPayroll }) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: PayrollGenerationFormInitialValues,
    resolver: yupResolver(schema),
  })
  const payPeriodType = watch('payPeriodType')
  const deadline = watch('deadline1') ? moment(watch('deadline1')) : null
  const payDate = watch('payDate1') ? moment(watch('payDate1')) : null
  const deadline2 = watch('deadline2') ? moment(watch('deadline2')) : null
  const payDate2 = watch('payDate2') ? moment(watch('payDate2')) : null

  const onSubmitForm = (values: PayrollGenerationFormValues) => {
    const newPayrollDates = generatePayroll(
      values.payPeriodType,
      moment(values.deadline1),
      moment(values.payDate1),
      values.deadline2 ? moment(values.deadline2) : undefined,
      values.payDate2 ? moment(values.payDate2) : undefined
    )
    setPendingPayroll(newPayrollDates)
  }

  const handleChange = (
    value: Moment | null,
    fieldName: 'deadline1' | 'payDate1' | 'deadline2' | 'payDate2'
  ) => {
    if (value) setValue(fieldName, value.toDate())
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <FlexWrap direction="column" size="lg">
        <Flex css={{ width: '100%' }} direction="column" align="start">
          <Label>Pay Period</Label>
          <Select
            options={PayPeriodOptions}
            placeholder="Select Pay Period"
            value={watch('payPeriodType')}
            onChange={val =>
              setValue('payPeriodType', val, { shouldDirty: true })
            }
          />
          <Text color="alert">{errors.payPeriodType?.message}</Text>
        </Flex>
        <FlexWrap direction="row" align="start" css={{ width: '100%' }}>
          <DatePickerContainer direction="column" align="start" mobileFullWidth>
            <Label>Deadline Date</Label>
            <SingleDatePicker
              value={deadline?.toDate()}
              onChange={(val: Date) => handleChange(moment(val), 'deadline1')}
            />
            <Text color="alert">{errors.deadline1?.message}</Text>
          </DatePickerContainer>
          <DatePickerContainer direction="column" align="start" mobileFullWidth>
            <Label>Payroll Date</Label>
            <SingleDatePicker
              value={payDate?.toDate()}
              onChange={(val: Date) => handleChange(moment(val), 'payDate1')}
            />
            <Text color="alert">{errors.payDate1?.message}</Text>
          </DatePickerContainer>
        </FlexWrap>
        {payPeriodType === 'semi-monthly' ? (
          <FlexWrap direction="row" align="start" css={{ width: '100%' }}>
            <DatePickerContainer
              direction="column"
              align="start"
              mobileFullWidth
            >
              <Label>Second Deadline Date</Label>
              <SingleDatePicker
                value={deadline2?.toDate()}
                onChange={(val: Date) => handleChange(moment(val), 'deadline2')}
              />
              <Text color="alert">{errors.deadline2?.message}</Text>
            </DatePickerContainer>
            <DatePickerContainer
              direction="column"
              align="start"
              mobileFullWidth
            >
              <Label>Second Payroll Date</Label>
              <SingleDatePicker
                value={payDate2?.toDate()}
                onChange={(val: Date) => handleChange(moment(val), 'payDate2')}
              />
              <Text color="alert">{errors.payDate2?.message}</Text>
            </DatePickerContainer>
          </FlexWrap>
        ) : null}
        <Flex justify="end" css={{ width: '100%' }}>
          <Button type="submit" mode="outline">
            Add Dates
          </Button>
        </Flex>
      </FlexWrap>
    </form>
  )
}
