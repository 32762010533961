import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { Input, TextArea } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { LockedIndicator } from '@/common/locked-indicator/LockedIndicator'
import { Text } from '@/common/text'
import Spinner from '@/components/common/loader/Spinner'
import { UploadAsset } from '@/components/common/upload-asset/UploadAsset'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { usePermissionsStore } from '@/store/permissions'
import { useSettingsStore } from '@/store/settings'

const ACCEPTED_FILE_DIMENSION = 96

const schema = Yup.object().shape({
  USER_TERMS: Yup.string().url(
    'Must be a valid url. Example: https://branchapp.com'
  ),
  ONBOARDING_REDIRECT_DEEPLINK: Yup.string().url(
    'Must be a valid url. Example: https://branchapp.com'
  ),
  ROSTER_NOTIFICATION_MESSAGE: Yup.string().max(
    150,
    'Enter less than 150 characters'
  ),
})

export type AdditionalSettingsValues = {
  CO_BRAND_LOGO_URL: string
  USER_TERMS: string
  ROSTER_NOTIFICATION_MESSAGE: string
  ONBOARDING_REDIRECT_DEEPLINK: string
}

const resetForm = (settings: AdditionalSettingsValues) => ({
  CO_BRAND_LOGO_URL: settings.CO_BRAND_LOGO_URL,
  ROSTER_NOTIFICATION_MESSAGE: settings.ROSTER_NOTIFICATION_MESSAGE,
  USER_TERMS: settings.USER_TERMS,
  ONBOARDING_REDIRECT_DEEPLINK: settings.ONBOARDING_REDIRECT_DEEPLINK,
})

export const AdditionalSettings = () => {
  const { settings, settingsDescriptions, isLoading } = useSettingsStore()
  const updateSettings = useUpdateSettingsMutation()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: resetForm(settings),
  })

  const onSubmit = (values: AdditionalSettingsValues) => {
    const updates: { [key: string]: any } = {
      ...values,
      ...(values.ROSTER_NOTIFICATION_MESSAGE
        ? {
            ROSTER_NOTIFICATION_MESSAGE: values.ROSTER_NOTIFICATION_MESSAGE,
          }
        : {
            ROSTER_NOTIFICATION_MESSAGE: undefined,
          }),
    }
    updateSettings.mutate(updates)
  }

  useEffect(() => {
    reset(resetForm(settings))
  }, [settings, reset])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <hr />
      <FlexGrid direction="column" fullWidth>
        <Flex direction="row" justify="between">
          <h5>Additional Settings</h5>
          <LockedIndicator />
        </Flex>
        <div>
          <UploadAsset
            title="Cobrand Logo"
            disabled={!isGlobalAdmin}
            label="CoBranded Onboarding Logo"
            description={settingsDescriptions.CO_BRAND_LOGO_URL}
            handleSubmit={url =>
              setValue('CO_BRAND_LOGO_URL', url, { shouldDirty: true })
            }
            placeholder={watch('CO_BRAND_LOGO_URL')}
            acceptedHeight={ACCEPTED_FILE_DIMENSION}
          />
        </div>
        <div>
          <Label htmlFor="USER_TERMS">Term URLs</Label>
          <Input
            type="text"
            {...register('USER_TERMS')}
            disabled={!isGlobalAdmin}
          />
          <Text color="alert" size="sm">
            {errors.USER_TERMS?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="ONBOARDING_REDIRECT_DEEPLINK">
            Onboarding Redirect Deeplink
          </Label>
          <Input
            type="text"
            {...register('ONBOARDING_REDIRECT_DEEPLINK')}
            disabled={!isGlobalAdmin}
          />
          <Text color="hint" size="sm">
            {settingsDescriptions.ONBOARDING_REDIRECT_DEEPLINK}
          </Text>
          <Text color="alert" size="sm">
            {errors.ONBOARDING_REDIRECT_DEEPLINK?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="ROSTER_NOTIFICATION_MESSAGE">
            Roster Notification Message
          </Label>
          <TextArea
            {...register('ROSTER_NOTIFICATION_MESSAGE')}
            disabled={!isGlobalAdmin}
          />
          <Text color="alert" size="sm">
            {errors.ROSTER_NOTIFICATION_MESSAGE?.message}
          </Text>
        </div>
      </FlexGrid>
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button
          mode="filled"
          type="submit"
          loading={updateSettings.isLoading}
          disabled={updateSettings.isLoading || !isDirty}
        >
          Update Settings
        </Button>
      </Flex>
    </form>
  )
}
