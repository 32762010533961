type Props = React.SVGAttributes<SVGSVGElement>

const SvgProcessDeductionsIcon = (props: Props) => (
  <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F5F5F5"
        d="M26.546 32.27H2.586a2.552 2.552 0 01-2.55-2.55V2.6A2.552 2.552 0 012.587.049h23.959A2.552 2.552 0 0129.099 2.6v27.118a2.552 2.552 0 01-2.553 2.552"
      />
      <path
        fill="#E2E8EB"
        d="M6.733 10.284c0 .384.313.696.694.696h14.281a.696.696 0 000-1.39H7.427a.693.693 0 00-.694.694zm0 3.917c0 .384.313.695.694.695h14.281a.696.696 0 000-1.39H7.427a.693.693 0 00-.694.695zm0 3.917c0 .384.313.695.694.695h14.281a.696.696 0 000-1.39H7.427a.692.692 0 00-.694.695zm-.001 3.918c0 .383.304.695.705.695h8.388c.39 0 .705-.309.705-.695a.693.693 0 00-.705-.696H7.437a.698.698 0 00-.705.696z"
      />
      <path
        fill="#EBEEF2"
        d="M38.552 38.565a2.28 2.28 0 01-3.223 0l-6.906-6.906a2.28 2.28 0 013.223-3.223l6.906 6.907c.89.89.89 2.333 0 3.222"
      />
      <path
        fill="#FFF"
        d="M16.576 28.967c-4.595-3.558-4.593-10.446.001-14.004 3.132-2.425 7.619-2.425 10.751 0 4.595 3.558 4.594 10.447-.001 14.005-3.133 2.425-7.62 2.425-10.751 0"
      />
      <path
        fill="#F5F5F5"
        d="M24.894 13.765a.698.698 0 01-.7.695H13.83c-.387 0-.495-.23-.238-.518l.317-.354c.255-.286.765-.518 1.152-.518h9.142a.69.69 0 01.69.695zm-13.736 3.917c0 .384.312.695.7.695h12.336c.386 0 .7-.308.7-.695a.697.697 0 00-.7-.695H11.858c-.387 0-.7.308-.7.695zm0 3.918c0 .383.312.695.7.695h12.336c.386 0 .7-.309.7-.695a.697.697 0 00-.7-.696H11.858c-.387 0-.7.309-.7.696zm0 3.917c0 .383.306.694.703.694h7.184a.697.697 0 00.703-.694.693.693 0 00-.703-.695H11.86a.697.697 0 00-.703.695z"
      />
      <path
        fill="#6CD27D"
        d="M16.576 28.967c-4.595-3.558-4.593-10.446.001-14.004 3.132-2.425 7.619-2.425 10.751 0 4.595 3.558 4.594 10.447-.001 14.005-3.133 2.425-7.62 2.425-10.751 0M38.509 35.3l-3.566-3.566c-1.344-1.344-1.836-3.361-1.145-5.13 1.753-4.49.87-9.777-2.658-13.453-4.7-4.896-12.633-5.204-17.682-.67-5.468 4.909-5.638 13.36-.51 18.488 3.678 3.677 9.062 4.623 13.624 2.849 1.787-.694 3.822-.189 5.177 1.167l3.58 3.58c1 1 2.697.876 3.525-.37.6-.906.424-2.127-.345-2.895"
      />
      <path
        fill="#42994C"
        d="M36.383 33.175l-3.222 3.222 2.168 2.169a2.28 2.28 0 003.223-3.224l-2.169-2.167z"
      />
      <path
        fill="#5DBB6B"
        d="M36.383 33.175l-3.222 3.222.977.978 3.223-3.224z"
      />
    </g>
  </svg>
)

export default SvgProcessDeductionsIcon
