import {
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'

export const WebhookTestContainer: React.FC = ({ children }) => (
  <SettingsContainer>
    <PageTop simple>
      <PageHeader>
        <PageTitle primary="Webhook Testing" />
      </PageHeader>
    </PageTop>
    <PageBody>{children}</PageBody>
  </SettingsContainer>
)
