import Papa from 'papaparse'

const parseCSV = async (file: File): Promise<string[][]> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      const result: any = reader.result
      const parsed = Papa.parse<string[]>(result, {
        skipEmptyLines: true,
      })
      resolve(parsed.data)
    }

    reader.onerror = reject

    reader.readAsText(file)
  })
}

export default parseCSV
