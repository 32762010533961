import { FC } from 'react'
import {
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { IEmployeeDirectDepositForm } from '@/types/employees'

interface Props {
  error?: Error
  filteredData: IEmployeeDirectDepositForm[]
}

export const DirectDepositsContainer: FC<Props> = ({ error, children }) => (
  <>
    <PageTop>
      <PageHeader>
        <PageTitle primary="Direct Deposit Requests" />
      </PageHeader>
    </PageTop>
    <PageBody hasError={error}>{children}</PageBody>
  </>
)
