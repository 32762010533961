import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Flex } from '@/common/layout'
import { JobsRecord } from '@/types/jobs'
import { RetryCell } from '../components/RetryCell'

export const columns: ColumnDef<JobsRecord, any>[] = [
  {
    header: 'Completed At',
    id: 'time_completed',
    accessorFn: row => moment(row.time_completed),
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
  },
  {
    header: 'Filename',
    id: 'filename',
    accessorFn: row => row.filename,
  },
  {
    header: 'Job Name',
    id: 'job_name',
    accessorFn: row => row.job_name,
  },
  {
    header: 'ID',
    id: 'id',
    accessorFn: row => row.id,
  },
  {
    header: 'Job Status',
    id: 'job_status',
    accessorFn: row => row.job_status,
  },
  {
    header: 'Status Reason',
    id: 'additional_info',
    accessorFn: row => row.additional_info,
  },
  {
    id: 'retry',
    enableSorting: false,
    size: 100,
    cell: ({ row }) => (
      <Flex justify="center">
        <RetryCell original={row.original} />
      </Flex>
    ),
  },
]
