import { useEffect, useState } from 'react'
import { Button } from '@/common/button'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import {
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'

export const EmbeddedOnboarding = () => {
  const [script, setScript] = useState<HTMLScriptElement | null>(null)
  const [client, setClient] = useState<any>(null)
  const { id: orgId, name } = useCurrentOrg()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = process.env.REACT_APP_WEB_ONBOARDING_URL || ''
    script.async = true

    document.body.appendChild(script)
    setScript(script)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (script) {
      script.addEventListener('load', () => {
        // @ts-ignore
        const client = new Branch.Onboarding({
          organizationId: orgId,
          onLoad: () => null,

          // Optionally do something when the
          // user successfully creates an account
          onAccountCreated: (user: any) => console.log(user),

          // Optionally do something when the
          // user has completed and exited onboarding
          onComplete: () => console.log('onboarding complete'),

          // Optionally do something when the
          // user has exited onboarding early
          onExit: () => console.log('onboarding early exit'),
        })
        setClient(client)
      })
    }
  }, [orgId, script])

  const openModal = () => client && client.open()

  return (
    <SettingsContainer>
      <PageTop simple>
        <PageHeader>
          <PageTitle primary="Embedded Web Onboarding" />
        </PageHeader>
      </PageTop>
      <PageBody>
        <Text css={{ mb: '$12' }}>
          Preview the embedded web onboarding modal for {name}
        </Text>
        <Button onClick={openModal}>Open Modal</Button>
      </PageBody>
    </SettingsContainer>
  )
}
