import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useAdvancesStore } from '@/store/advances'
import { additionalFilters } from '../lib/filters'
import { useDownloadTransactions } from '../lib/useDownloadTransactions'

export const TransactionFilters: FC = () => {
  const { sorted, filters, updateFilters } = useAdvancesStore()
  const { download, loading } = useDownloadTransactions({
    filters,
    sorted,
  })

  return (
    <TableFiltersMain
      searchProperty="name"
      filters={filters}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleDownload={download}
    />
  )
}
