import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useOrderInstantCardsMutation } from '@/src/queries/instant-cards'
import { useModalStore } from '@/store/modal'
import InstantCardsConfirmation from './InstantCardsConfirmation'
import InstantCardsOrderForm from './InstantCardsOrderForm'
import { CardOrderFormValues, formatFormValuesForAPI } from './lib/orderForm'

const InstantCardsOrderModal: FC = () => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [order, setOrder] = useState<CardOrderFormValues>()
  const orderCards = useOrderInstantCardsMutation()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleSubmit = (values: CardOrderFormValues) => {
    setShowConfirmation(true)
    setOrder(values)
  }

  const handleOrder = () => {
    const config = formatFormValuesForAPI(order as CardOrderFormValues)
    orderCards.mutate(config, {
      onSuccess: () => close(),
    })
  }

  return (
    <Dialog open={open} setOpen={close} title="Order Instant Cards">
      {showConfirmation ? (
        <InstantCardsConfirmation
          loading={orderCards.isLoading}
          handleOrder={handleOrder}
          order={order}
        />
      ) : (
        <InstantCardsOrderForm onSubmit={handleSubmit} />
      )}
    </Dialog>
  )
}

export default InstantCardsOrderModal
