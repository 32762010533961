import { FC } from 'react'
import { IRemittanceBalance } from '@/types/remittances'

interface Props {
  original: IRemittanceBalance
}

export const NameCell: FC<Props> = ({ original }) => {
  const firstName = original.first_name
  const lastName = original.last_name

  return (
    <span>
      {firstName} {lastName}
    </span>
  )
}
