import moment from 'moment'
import { Flex } from '@/common/layout'
import { StatusCell } from '@/components/employees/components/StatusCell'
import { UserActionDropdown } from '@/components/employees/components/UserActionDropdown'
import { toDollar } from '@/lib/currency'
import { formatPhoneNumber } from '@/lib/phoneLib'
import { IOrganizationEmployee } from '@/types/employees'
import { ColumnDefIdRequired } from '@/types/table'
import { STATUS_KEYS } from './employees.types'

export const rosterColumns: ColumnDefIdRequired<
  IOrganizationEmployee,
  any
>[] = [
  {
    accessorFn: row => `${row.first_name} ${row.last_name}`,
    id: 'first_name',
    header: 'Name',
    cell: row => row.getValue(),
    enableSorting: true,
  },
  {
    accessorFn: row => row.phone_number,
    header: 'Phone',
    id: 'phone_number',
    cell: row => formatPhoneNumber(row.getValue()),
    enableSorting: true,
  },
  {
    accessorKey: 'employee_id',
    header: 'ID',
    id: 'id',
    cell: row => row.getValue(),
    enableSorting: true,
  },
  {
    accessorFn: row =>
      row.time_first_verified && moment(row.time_first_verified),
    header: 'Added',
    id: 'time_first_verified',
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
    enableSorting: true,
    enablePinning: false,
  },
  {
    accessorFn: row => toDollar(row.rate),
    header: 'Rate',
    id: 'rate',
    enableSorting: false,
  },
  {
    accessorKey: 'email_address',
    header: 'Email',
    id: 'email_address',
    enableSorting: false,
    size: 300,
  },
  {
    accessorKey: 'shift_type',
    header: 'Shift Type',
    id: 'shift_type',
    enableSorting: false,
  },
  {
    accessorKey: 'location_metadata',
    header: 'Location',
    id: 'location',
    enableSorting: false,
  },
  {
    accessorFn: row => (!!row.wallet.wallet_id ? 'Yes' : 'No'),
    header: 'Wallet',
    id: 'hasWallet',
    enableSorting: false,
  },
  {
    accessorFn: row => (row.wallet.has_activated_card ? 'Yes' : 'No'),
    header: 'Activated Card',
    id: 'hasActivatedCard',
    enableSorting: false,
  },
  {
    accessorFn: row => row.wallet.account_number,
    header: 'Account #',
    id: 'accountNumber',
    enableSorting: false,
  },
  {
    accessorFn: row => (row.user?.is_minor ? 'Yes' : 'No'),
    header: 'Minor',
    id: 'minor',
    enableSorting: false,
  },
  {
    accessorFn: row => row.wallet.routing_number,
    header: 'Routing #',
    id: 'routingNumber',
    enableSorting: false,
  },
  {
    accessorFn: row =>
      row.direct_deposit_form
        ? moment(row.direct_deposit_form.time_created)
        : null,
    header: 'DD Date',
    id: 'dd',
    enableSorting: false,
  },
  {
    accessorFn: row =>
      !row.user ? STATUS_KEYS.NOT_ENROLLED : STATUS_KEYS.ENROLLED,
    header: 'Status',
    id: 'status',
    enableSorting: false,

    cell: ({ row }) => <StatusCell original={row.original} />,
  },
  {
    header: '',
    accessorKey: 'actions',
    id: 'actions',
    size: 75,
    enableSorting: false,
    cell: ({ row }) => (
      <Flex justify="center">
        <UserActionDropdown original={row.original} />
      </Flex>
    ),
  },
]
// demo for columns customization
export const columnsCustomization = rosterColumns.map((column, index) => ({
  id: column.id,
  index: index,
  hidden: false,
  locked: column.id === 'actions' ? true : false,
}))
