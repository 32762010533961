import moment from 'moment'
import qs from 'qs'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { RemittanceAdvances } from '@/types/remittances'

interface Props {
  advance: RemittanceAdvances
}

export const ViewDetailsLink: FC<Props> = ({ advance }) => {
  const detailsPath = useOrgPath(ROUTES.ADVANCES)

  const params = useMemo(() => {
    if (!advance) return
    return qs.stringify({
      filter_employee_id: advance.employee_id,
      filter_amount: parseFloat(`${advance.total_amount}`) / 100,
      filter_to: moment(advance.payout_date).format('YYYY-MM-DD'),
      filter_from: moment(advance.payout_date).format('YYYY-MM-DD'),
    })
  }, [advance])

  return <Link to={`${detailsPath}?${params}`}>View Advance</Link>
}
