import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useJobsStore } from '@/store/jobs'
import { additionalFilters, jobStatusOptions } from '../lib/filters'

export const JobsFilters: FC = () => {
  const { filters, updateFilters } = useJobsStore()

  return (
    <TableFiltersMain
      filters={filters}
      statusOptions={jobStatusOptions}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
    />
  )
}
