import { WebhookDefinition, WebhookEventType } from '@/types/webhooks'
import http, { sandboxUtilityHttp } from './http'
import { WebhookPayload, WebhookRegistrationResponse } from './types/webhooks'

export const getWebhookEventTypes = () =>
  http.get('clientnotification/webhooks/event_types')

export const getWebhookDefinition = (orgId: number, definitionId: number) =>
  http.get<WebhookDefinition>(
    `clientnotification/organizations/${orgId}/webhooks/${definitionId}`
  )

export const getWebhookDefinitions = (orgId: number) =>
  http.get<WebhookDefinition[]>(
    `clientnotification/organizations/${orgId}/webhooks`
  )

export const createWebhookDefinition = (orgId: number, url: string) =>
  http.post(`clientnotification/organizations/${orgId}/webhooks`, { url })

export const deleteWebhookDefinition = (orgId: number, definitionId: number) =>
  http.delete(
    `clientnotification/organizations/${orgId}/webhooks/${definitionId}`
  )

export const getWebhookRegistrations = (orgId: number, definitionId: number) =>
  http.get<WebhookRegistrationResponse>(
    `clientnotification/organizations/${orgId}/webhooks/${definitionId}/registrations`
  )

export const createWebhookRegistration = (
  orgId: number,
  definitionId: number,
  event_type: string
) =>
  http.post(
    `clientnotification/organizations/${orgId}/webhooks/${definitionId}/registrations`,
    { event_type }
  )

export const deleteWebhookRegistration = (
  orgId: number,
  definitionId: number,
  registrationId: number
) =>
  http.delete(
    `clientnotification/organizations/${orgId}/webhooks/${definitionId}/registrations/${registrationId}`
  )

export const retryWebhookEvent = (orgId: number, eventId: number) =>
  http.put(
    `clientnotification/organizations/${orgId}/events/${eventId}/webhooks`
  )

export const mockWebhookEvent = (
  orgId: number,
  partnerId: number | null,
  event: WebhookEventType,
  data: WebhookPayload
) =>
  sandboxUtilityHttp.post(`/organizations/${orgId}/webhooks/${event}`, data, {
    headers: { 'X-Consumer-Custom-ID': partnerId },
  })
