import { useMemo } from 'react'
import { useSearchEnrollmentRequests } from '@/queries/employees'
import { IOrganizationEmployee } from '@/types/employees'

export const useEnrollmentMatching = (roster?: IOrganizationEmployee[]) => {
  const unenrolledUsers = (roster || [])
    .filter(user => !user.user)
    .map(user => ({
      id: user.employee_id,
      name: `${user.first_name} ${user.last_name}`,
    }))

  const searchNames = unenrolledUsers.map(u => u.name)
  const { data } = useSearchEnrollmentRequests(searchNames)

  return useMemo(() => {
    const rosterWithRequests = (roster || []).reduce((acc, rec) => {
      let user: IOrganizationEmployee = { ...rec, enrollmentRequests: [] }
      if (!!user.user) {
        acc.push(user)
        return acc
      }
      const matches = data?.filter(r => {
        const requesterName = `${r.first_name} ${r.last_name}`.toLowerCase()
        const userName = `${rec.first_name} ${rec.last_name}`.toLowerCase()
        return requesterName === userName
      })
      user.enrollmentRequests = matches || []
      acc.push(user)
      return acc
    }, [] as IOrganizationEmployee[])
    return rosterWithRequests
  }, [data, roster])
}
