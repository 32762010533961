import { FC } from 'react'
import { useHistory } from 'react-router'
import { Button } from '@/common/button'
import { NAV_GROUPS } from '@/router/menus'
import useOrgPath from '@/router/useOrgPath'
import { useNavIsActive } from './useNavIsActive'

interface Props {
  label: string
  route: string
  group: NAV_GROUPS
  show?: boolean
}

export const NavLink: FC<Props> = ({ label, route, group, show = true }) => {
  const urlPath = useOrgPath(route)
  const history = useHistory()
  const isActive = useNavIsActive(group)

  const handleClick = () => {
    history.push(urlPath)
  }

  const buttonId = label.toLowerCase().replace(/\s/g, '')

  if (!show) return null

  return (
    <Button
      mode={isActive ? 'filled' : 'flat'}
      onClick={handleClick}
      id={buttonId}
      css={{
        color: 'white',
        backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : 'transparent',
        border: 'none',
        '&:hover': {
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
      }}
    >
      {label}
    </Button>
  )
}
