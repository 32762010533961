import { FC } from 'react'
import { Alert } from '@/common/alert'
import { InitiationUserRecord } from '@/types/create-accounts'
import { AccountFailure } from './AccountFailure'

interface Props {
  record: InitiationUserRecord
}

export const StatusReason: FC<Props> = ({ record }) => {
  let component

  switch (record.status) {
    case 'FAILED':
      component = <AccountFailure record={record} />
      break
    case 'UNLINKED':
      component = (
        <Alert type="info">
          Account is now active. This user needs to sign-up in the Branch app
          and activate a card to complete setup.
        </Alert>
      )
      break
    case 'LINKED':
      component = (
        <Alert type="success">
          Wallet is activated and linked to the account.
        </Alert>
      )
      break
    case 'PENDING':
      component = (
        <Alert type="info">We're still verifying these account details.</Alert>
      )
      break
    case 'PENDING_MANUAL':
    case 'PENDING_REVIEW':
      component = (
        <>
          <Alert type="warning">
            Oops, couldn’t verify based on details provided. Please double check
            the information and try again. If all information is correct, the
            user will need to provide the requested additional information.
          </Alert>
          <AccountFailure record={record} />
        </>
      )
      break
    case 'CANCELED':
      component = (
        <Alert type="warning">Account creation attempt was aborted.</Alert>
      )
      break
  }

  return component
}
