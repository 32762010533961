import { CrossCircledIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useRemoveAdmin } from '@/queries/admin-users'
import { useModalStore } from '@/store/modal'
import { Admin } from '@/types/organization'
interface Props {
  original: Admin
}

export const ActionCell: FC<Props> = ({ original }) => {
  const removeUser = useRemoveAdmin()
  const { registerModal } = useModalStore()

  const getRemoveModal = () => (
    <AlertDialog
      title="Remove Admin"
      description={`${original.first_name} ${original.last_name} will be removed as an admin.`}
      actionDescription="Remove"
      isDestructive={true}
      handleAction={() => removeUser.mutateAsync(`${original.id}`)}
    />
  )

  const openRemoveModal = () => {
    registerModal(getRemoveModal())
  }

  return (
    <TableActionDropdown
      menu={[
        {
          label: 'Remove',
          handleClick: openRemoveModal,
          icon: <CrossCircledIcon />,
        },
      ]}
    />
  )
}
