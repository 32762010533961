import { Stat, StatsContainer } from '@/common/stat'
import { useEmployeeStatsQuery } from '@/queries/employees'

export const EmployeeStats = () => {
  const { isLoading, error, data } = useEmployeeStatsQuery()

  if (isLoading || error) {
    return null
  }

  return (
    <StatsContainer>
      <Stat
        label="Enrolled"
        value={`${data?.percentEnrolled}%`}
        color="#2A5A42"
      />
      <Stat
        label="Not Enrolled"
        value={`${data?.percentNotEnrolled}%`}
        color="#5A2A3C"
      />
    </StatsContainer>
  )
}
