export const stripWhitespace = (val: string) => val.replace(/\s/g, '')

export const replaceCommasWithSemicolons = (val: string) =>
  val.replace(/[,]/g, ';')

export const replaceSemicolonsWithCommas = (val: string) =>
  val.replace(/[;]/g, ', ')

export const trimStringToLength = (
  str: string,
  config: {
    addEllipsis: boolean
    max: number
  }
) => {
  const { addEllipsis, max } = config
  const shouldTrim = str.length > max
  if (shouldTrim) {
    const append = addEllipsis ? '...' : ''
    const trimmed = str.substring(0, max)
    return `${trimmed}${append}`
  }
  return str
}

export const toTitleCase = (value: string) =>
  value.replaceAll(
    /\S*/g,
    word => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  )

export const getRouteDomain = (route: string) => route.replace(`/:orgKey/`, '')
