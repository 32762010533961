import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { FC } from 'react'
import { TooltipArrow, TooltipContent, TooltipTrigger } from './styles'

export interface Props extends TooltipPrimitive.TooltipContentProps {
  trigger: JSX.Element
}

export const Tooltip: FC<Props> = ({ trigger, children, ...props }) => (
  <TooltipPrimitive.Provider delayDuration={50}>
    <TooltipPrimitive.Root>
      <TooltipTrigger asChild>{trigger}</TooltipTrigger>
      <TooltipPrimitive.Portal>
        <TooltipContent {...props}>
          <TooltipArrow />
          {children}
        </TooltipContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
)
