import { FC } from 'react'
import { Loader } from '@/common/loader'
import { usePdqRegistrationQuery } from '@/queries/pdq'
import { PdqRegistration } from './PdqRegistration'
import { PdqStores } from './PdqStores'

export const PdqIntegration: FC = () => {
  const registration = usePdqRegistrationQuery()

  if (registration.isLoading) {
    return <Loader loading={true} />
  }

  if (registration.isError) {
    return <PdqRegistration create={true} />
  }

  return <PdqStores />
}
