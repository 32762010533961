import { FC } from 'react'
import {
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { GrossEarningFilters } from './GrossEarningFilters'

export const GrossEarningRecordsContainer: FC = ({ children }) => (
  <>
    <PageTop>
      <PageHeader>
        <PageTitle primary="Gross Earnings" />
      </PageHeader>
      <PageFilters>
        <GrossEarningFilters />
      </PageFilters>
    </PageTop>
    <PageBody>{children}</PageBody>
  </>
)
