import styles from '../index.module.scss'

interface IProps {
  Icon: (props: React.HTMLAttributes<SVGSVGElement>) => JSX.Element
  text: string
}

const ProgressCardTitle: React.FC<IProps> = ({ Icon, text }) => {
  return (
    <div className={styles.ProgressCardTitle}>
      <Icon />
      <h3>{text}</h3>
    </div>
  )
}

export default ProgressCardTitle
