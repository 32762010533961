import { BoolFilterOptions } from '@/common/filters/FlagFilterOptions'
import { AdditionalFilterItem } from '@/common/filters/types'

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'phone_number', label: 'Phone' },
  { id: 'employee_id', label: 'ID' },
  { id: 'rate', label: 'Rate' },
  { id: 'email_address', label: 'Email' },
  { id: 'shift_type', label: 'Shift Type' },
  { id: 'location', label: 'Location' },
  { id: 'business_name', label: 'Business Name' },
  {
    id: 'include_deleted',
    label: 'Include Deleted',
    renderInput: BoolFilterOptions,
  },
  {
    id: 'is_linked',
    label: 'Enrolled',
    renderInput: BoolFilterOptions,
  },
  // NOT SUPPORTED YET
  // { id: 'joined', label: 'Added' },
  // {
  //   id: 'hasWallet',
  //   label: 'Has Wallet',
  //   renderInput: FlagFilterOptions,
  // },
  // {
  //   id: 'hasActivatedCard',
  //   label: 'Has Activated Card',
  //   renderInput: FlagFilterOptions,
  // },
  // {
  //   id: 'minor',
  //   label: 'Is Minor',
  //   renderInput: FlagFilterOptions,
  // },
  // { id: 'accountNumber', label: 'Account #' },
  // { id: 'routingNumber', label: 'Routing #' },
  // { id: 'dd', label: 'DD Requested' },
]
