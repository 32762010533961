import { Redirect, Route, Switch } from 'react-router-dom'
import { AuthContainer } from '@/components/auth/AuthContainer'
import AuthenticatedContainer from '@/components/auth/AuthenticatedContainer'
import { CashOuts } from '@/components/cash-outs/CashOuts'
import { CreateAccounts } from '@/components/create-accounts/CreateAccounts'
import { DeductionReport } from '@/components/deduction-report/DeductionReport'
import { DirectDeposits } from '@/components/direct-deposits/DirectDeposits'
import { Employees } from '@/components/employees/Employees'
import { Adoption } from '@/components/insights/adoption/Adoption'
import { TransactionInsights } from '@/components/insights/transactions/TransactionInsights'
import { InstantCardOrders } from '@/components/instant-card-orders/InstantCardOrders'
import { InstantCards } from '@/components/instant-cards/InstantCards'
import { Jobs } from '@/components/jobs/Jobs'
import { Locations } from '@/components/locations/Locations'
import { Paybacks } from '@/components/paybacks/Paybacks'
import { Payouts } from '@/components/payouts/Payouts'
import Payroll from '@/components/payroll/Payroll'
import { GrossEarningRecords } from '@/components/records/grossearnings/GrossEarningRecords'
import { TimekeepingRecords } from '@/components/records/timekeeping/TimekeepingRecords'
import { TransactionRecords } from '@/components/records/transactions/TransactionRecords'
import { DeductionReports } from '@/components/reports/deduction-reports/DeductionReports'
import { Resources } from '@/components/resources/Resources'
import {
  CompanySettings,
  DirectDepositSettings,
  EmbeddedOnboarding,
  FileProcessingSettings,
  IntegrationSettings,
  InvoiceConfigurations,
  OnboardingSettings,
  OrgLocationSettings,
  RolesPermissionsSettings,
  SSOConfiguration,
  SupportSettings,
  Webhooks,
  WebhookTesting,
} from '@/components/settings'
import LoggedInRoute from '@/router/LoggedInRoute'
import { TableConfiguration } from './components/settings/table-config/TableConfiguration'
import { AdminRoute, ROUTES } from './router'
import ProtectedRoute from './router/ProtectedRoute'

const App = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.LOGIN} component={AuthContainer} />
      <Route exact path={ROUTES.REGISTER} component={AuthContainer} />
      <LoggedInRoute path={ROUTES.ORGANIZATION}>
        <AuthenticatedContainer>
          <Switch>
            <Redirect
              from={ROUTES.OLD_REMITTANCE_COMPLETE}
              to={ROUTES.REMITTANCE_COMPLETE}
            />
            <Redirect from={ROUTES.OLD_REMITTANCE} to={ROUTES.REMITTANCE} />
            <Redirect from={ROUTES.OLD_PEOPLE} to={ROUTES.WORKERS} />
            <Redirect from={ROUTES.OLD_EMPLOYEES} to={ROUTES.WORKERS} />
            <Redirect from={ROUTES.OLD_PAYBACKS} to={ROUTES.INVOICES} />
            <Redirect from={ROUTES.OLD_PAYOUTS} to={ROUTES.DISBURSEMENTS} />

            <ProtectedRoute
              exact
              path={ROUTES.SETTINGS}
              component={CompanySettings}
            />
            <ProtectedRoute
              exact
              path={ROUTES.SUPPORT_SETTINGS}
              component={SupportSettings}
            />
            <ProtectedRoute
              exact
              path={ROUTES.ROLES_PERMISSIONS_SETTINGS}
              component={RolesPermissionsSettings}
            />
            <ProtectedRoute
              exact
              path={ROUTES.ONBOARDING_SETTINGS}
              component={OnboardingSettings}
            />
            <ProtectedRoute
              exact
              path={ROUTES.PAYMENTS_SETTINGS}
              component={DirectDepositSettings}
            />
            <ProtectedRoute
              exact
              path={ROUTES.INTEGRATION}
              component={IntegrationSettings}
            />
            <ProtectedRoute
              exact
              path={ROUTES.WEBHOOK_TESTING}
              component={WebhookTesting}
            />
            <AdminRoute
              exact
              path={ROUTES.FILE_PROCESSING_SETTINGS}
              component={FileProcessingSettings}
            />
            <AdminRoute
              exact
              path={ROUTES.SSO_CONFIG}
              component={SSOConfiguration}
            />
            <AdminRoute
              exact
              path={ROUTES.INVOICE_CONFIGURATIONS}
              component={InvoiceConfigurations}
            />
            <AdminRoute exact path={ROUTES.WEBHOOKS} component={Webhooks} />
            <AdminRoute
              exact
              path={ROUTES.TABLE_CONFIG}
              component={TableConfiguration}
            />
            <AdminRoute
              exact
              path={ROUTES.ORG_LOCATION_SETTINGS}
              component={OrgLocationSettings}
            />
            <AdminRoute
              exact
              path={ROUTES.EMBEDDED_ONBOARDING}
              component={EmbeddedOnboarding}
            />

            <ProtectedRoute
              exact
              path={ROUTES.ADVANCES}
              component={TransactionRecords}
            />
            <ProtectedRoute
              exact
              path={ROUTES.DISBURSEMENTS}
              component={Payouts}
            />
            <ProtectedRoute
              exact
              path={ROUTES.CASH_OUTS}
              component={CashOuts}
            />
            <ProtectedRoute exact path={ROUTES.INVOICES} component={Paybacks} />
            <ProtectedRoute
              exact
              path={ROUTES.TIMEKEEPING}
              component={TimekeepingRecords}
            />
            <ProtectedRoute
              exact
              path={ROUTES.GROSS_EARNINGS}
              component={GrossEarningRecords}
            />
            <ProtectedRoute exact path={ROUTES.WORKERS} component={Employees} />
            <ProtectedRoute
              exact
              path={ROUTES.LOCATIONS}
              component={Locations}
            />
            <ProtectedRoute
              exact
              path={ROUTES.CREATE_ACCOUNTS}
              component={CreateAccounts}
            />
            <ProtectedRoute
              exact
              path={ROUTES.PAYROLL_PERIODS}
              component={Payroll}
            />
            <ProtectedRoute
              exact
              path={ROUTES.INSTANT_CARDS}
              component={InstantCards}
            />
            <ProtectedRoute
              exact
              path={ROUTES.INSTANT_CARD_ORDERS}
              component={InstantCardOrders}
            />
            <ProtectedRoute
              exact
              path={ROUTES.ADVANCES_INSIGHTS}
              component={TransactionInsights}
            />
            <ProtectedRoute
              exact
              path={ROUTES.ADOPTION_INSIGHTS}
              component={Adoption}
            />
            <ProtectedRoute
              exact
              path={ROUTES.DIRECT_DEPOSITS}
              component={DirectDeposits}
            />
            <ProtectedRoute exact path={ROUTES.JOBS} component={Jobs} />
            <ProtectedRoute
              exact
              path={ROUTES.REMITTANCES}
              component={DeductionReports}
            />
            <ProtectedRoute
              path={ROUTES.REMITTANCE}
              component={DeductionReport}
            />
            <ProtectedRoute
              exact
              path={ROUTES.RESOURCES}
              component={Resources}
            />
            <Redirect from="/:orgKey" to={ROUTES.DISBURSEMENTS} />
          </Switch>
        </AuthenticatedContainer>
      </LoggedInRoute>
      {/*
          We don't know the org key, so its omitted from the redirect URL.
          The org key gets injected into the URL if one is available.
      */}
      <Redirect from="*" to="workers" />
    </Switch>
  )
}

export default App
