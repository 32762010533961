import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { WebhookPayload } from '@/api/types/webhooks'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { useMockWebhookEvent } from '@/queries/webhooks'

export const WalletCreatedEvent: FC = () => {
  const { register, handleSubmit } = useForm<WebhookPayload>()
  const simulateWebhook = useMockWebhookEvent()

  const onSubmit = (data: WebhookPayload) => {
    simulateWebhook.mutate({ data, event: 'WALLET_CREATED' })
  }

  return (
    <Box>
      <Card>
        <Text bold css={{ mb: '$8' }}>
          Wallet Created
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box css={{ mb: '$8' }}>
            <Label>Employee ID</Label>
            <Input type="text" {...register('employee_id')} />
          </Box>
          <Box css={{ mb: '$8' }}>
            <Label>Routing Number</Label>
            <Input type="text" {...register('routing_number')} />
          </Box>
          <Box css={{ mb: '$8' }}>
            <Label>Account Number</Label>
            <Input type="text" {...register('account_number')} />
          </Box>
          <Box css={{ mb: '$8' }}>
            <Label>Routing Link</Label>
            <Input type="text" {...register('routing_link')} />
          </Box>
          <Flex justify="end">
            <Button
              type="submit"
              mode="filled"
              color="tertiary"
              disabled={simulateWebhook.isLoading}
              loading={simulateWebhook.isLoading}
            >
              Trigger Event
            </Button>
          </Flex>
        </form>
      </Card>
    </Box>
  )
}
