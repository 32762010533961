import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { OrgSettingsUpdates } from '@/api/types/organization'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { Text } from '@/common/text'
import Spinner from '@/components/common/loader/Spinner'
import { isCommaDelimitedEmailList } from '@/lib/customYupValidators'
import { replaceCommasWithSemicolons, stripWhitespace } from '@/lib/strings'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { useSettingsStore } from '@/store/settings'
import { OrgSettings } from '@/types/organization'

export type CompanyFormValues = {
  PAY_DEFAULT_ADMIN_EMAIL: string
}

const schema = Yup.object().shape({
  PAY_DEFAULT_ADMIN_EMAIL: Yup.string()
    .required('Required')
    .test(isCommaDelimitedEmailList),
})

const resetForm = (settings: OrgSettings) => ({
  PAY_DEFAULT_ADMIN_EMAIL: settings.PAY_DEFAULT_ADMIN_EMAIL,
})

export const Company: FC = () => {
  const { settings, isLoading } = useSettingsStore()
  const updateSettings = useUpdateSettingsMutation()

  const adminEmails = settings.PAY_DEFAULT_ADMIN_EMAIL
    ? settings.PAY_DEFAULT_ADMIN_EMAIL.replace(/[;]/g, ', ')
    : ''

  const defaultValues = {
    PAY_DEFAULT_ADMIN_EMAIL: adminEmails,
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onFormSubmit = (values: CompanyFormValues) => {
    const payDefaultAdminEmail = replaceCommasWithSemicolons(
      stripWhitespace(values.PAY_DEFAULT_ADMIN_EMAIL)
    )

    let payload: OrgSettingsUpdates = {
      PAY_DEFAULT_ADMIN_EMAIL: payDefaultAdminEmail,
    }

    updateSettings.mutate(payload, {
      onSuccess: () => {
        reset(values)
      },
    })
  }

  useEffect(() => {
    reset(resetForm(settings))
  }, [settings, reset])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box>
      <hr />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Text as="h5" css={{ mt: '$24' }}>
          Notifications
        </Text>
        <p>Control who should be alerted to all things Branch.</p>
        <div>
          <Label htmlFor="PAY_DEFAULT_ADMIN_EMAIL">
            Notification Email Recipients
          </Label>
          <Input {...register('PAY_DEFAULT_ADMIN_EMAIL')} />
          <Text color="hint" size="sm">
            Add one or more email addresses, separated by commas.
          </Text>
          <Text color="alert" size="sm">
            {errors?.PAY_DEFAULT_ADMIN_EMAIL?.message}
          </Text>
        </div>
        <Flex justify="end" css={{ mt: '$24' }}>
          <Button
            mode="filled"
            type="submit"
            disabled={updateSettings.isLoading || !isDirty}
          >
            Update Settings
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
