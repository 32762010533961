import { Cross2Icon } from '@radix-ui/react-icons'
import { FC, ReactNode } from 'react'
import { Flex } from '@/common/layout'
import { useModalStore } from '@/store/modal'
import {
  DialogClose,
  DialogContainer,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from './styles'

interface Props {
  open: boolean
  setOpen: (openState: boolean) => void
  trigger?: ReactNode
  title?: string | ReactNode
  description?: string | ReactNode
  isDestructive?: boolean
  disableClickAway?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'unbound'
}

export const Dialog: FC<Props> = ({
  children,
  open,
  setOpen,
  trigger,
  title,
  description,
  disableClickAway = false,
  size,
}) => {
  const { removeModal } = useModalStore()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const clickAway = () => {
    if (disableClickAway) return
    setTimeout(() => (document.body.style.pointerEvents = ''), 0)
    close()
  }

  return (
    <DialogContainer open={open}>
      <DialogTrigger asChild onClick={() => setOpen(true)}>
        {trigger}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay>
          <DialogContent
            onClick={e => {
              e.stopPropagation()
            }}
            onPointerDownOutside={clickAway}
            size={size}
          >
            <Flex justify="end">
              <DialogClose
                asChild
                onClick={e => {
                  e.stopPropagation()
                  close()
                }}
              >
                <Cross2Icon />
              </DialogClose>
            </Flex>
            {title ? <DialogTitle>{title}</DialogTitle> : null}
            {description ? (
              <DialogDescription>{description}</DialogDescription>
            ) : null}
            {children}
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </DialogContainer>
  )
}
