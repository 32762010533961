import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  editPdqStoreRegistration,
  getPdqRegistration,
  getPdqStores,
  postPdqRegistration,
  postPdqStoreRegistration,
  putPdqRegistration,
  removePdqStore,
  runPdqRegistrationJob,
} from '@/api/pdq'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { PdqStoreRegistrationFormValues } from '@/components/settings/integrations/components/pdq/StoreForm'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'

export const PDQ_STORES_QUERY = 'pdq-stores'

export const usePdqStoresQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([PDQ_STORES_QUERY, orgId], () => getPdqStores(orgId), {
    retry: false,
  })
}

export const PDQ_REGISTRATION_QUERY = 'pdq-registration'

export const usePdqRegistrationQuery = () => {
  const { id: orgId } = useCurrentOrg()

  return useQuery(
    [PDQ_REGISTRATION_QUERY, orgId],
    () => getPdqRegistration(orgId),
    { retry: false }
  )
}

export const useUpdatePdqRegistrationQuery = () => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(
    (params: { id: string; secret: string }) =>
      putPdqRegistration(orgId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PDQ_REGISTRATION_QUERY)
        queryClient.invalidateQueries(PDQ_STORES_QUERY)
      },
    }
  )
}

export const useCreatePdqRegistrationQuery = () => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(
    (params: { id: string; secret: string }) =>
      postPdqRegistration(orgId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PDQ_REGISTRATION_QUERY)
        queryClient.invalidateQueries(PDQ_STORES_QUERY)
      },
    }
  )
}

export const useRemovePdqStore = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (storeId: string) => {
      const res = await removePdqStore(orgId, storeId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PDQ_STORES_QUERY)
      },
    }
  )
}

export const useRunPdqRegistrationJob = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()

  const queryClient = useQueryClient()

  return useMutation<
    void,
    Error,
    { types: string[]; store_id?: string; date?: string },
    unknown
  >(
    async params => {
      try {
        await runPdqRegistrationJob(orgId, params)
      } catch (err) {
        throw new Error(err.response?.data?.errorDetail)
      }
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Successfully ran the job.',
        })
        queryClient.invalidateQueries(PDQ_STORES_QUERY)
      },
      onError: err => {
        addToast({
          type: 'error',
          title: 'Failed to run the job.',
          description: `Reason: ${err.message}`,
        })
      },
    }
  )
}

type PdqStoreRegistrationConfig = {
  id: string
  values: PdqStoreRegistrationFormValues
}

export const useAddPdqStoreRegistration = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (config: PdqStoreRegistrationConfig) => {
      return postPdqStoreRegistration(config.id, config.values, orgId)
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('integration', 'pdq-add-store:success')
        addToast({
          type: 'success',
          title: 'Add store registration successful',
        })
        queryClient.invalidateQueries(PDQ_STORES_QUERY)
      },
      onError: () => {
        sendAnalyticsEvent('integration', 'pdq-add-store:failure')
        addToast({
          type: 'error',
          title: 'Failed to add store registration',
        })
      },
    }
  )
}

export const useEditPdqStoreRegistration = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (config: PdqStoreRegistrationConfig) => {
      return editPdqStoreRegistration(config.id, config.values, orgId)
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('integration', 'pdq-edit-store:success')
        addToast({
          type: 'success',
          title: 'Edit store registration successful',
        })
        queryClient.invalidateQueries(PDQ_STORES_QUERY)
      },
      onError: () => {
        sendAnalyticsEvent('integration', 'pdq-edit-store:failure')
        addToast({
          type: 'error',
          title: 'Failed to edit store registration',
        })
      },
    }
  )
}
