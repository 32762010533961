import { load } from 'js-yaml'
import { IngestionConfigs, ProcessorConfig } from '@/api/types/settings'
import { useIngestionConfig } from '@/queries/ingestion'
import { IngestionConfiguration } from '@/types/ingestion'

export const computeIngestionConfig = (
  ingestionConfig: IngestionConfiguration[]
) => {
  let c: IngestionConfigs = {}
  ingestionConfig.forEach(d => {
    const parsed = load(d.ingestion_configuration) as ProcessorConfig
    c[d.ingestion_configuration_type] = {
      filenameMatcher: d.filename_matcher,
      yamlConfiguration: d.ingestion_configuration,
      parsedConfig: parsed,
    }
  })
  return c
}

export const hasOptionalKeyInConfig = (
  ingestionConfigs: IngestionConfigs | undefined,
  configType: string,
  key: string
) =>
  ingestionConfigs
    ? !!ingestionConfigs[configType]?.parsedConfig?.processors.default
        .processor_to_database[key]
    : false

export const useOptionalRosterColumns = () => {
  const ingestionConfigs = useIngestionConfig()
  const ingestionConfig = computeIngestionConfig(
    ingestionConfigs.data?.data.ingestion_configurations || []
  )
  return {
    shift_type: hasOptionalKeyInConfig(ingestionConfig, 'roster', 'ShiftType'),
    rate: hasOptionalKeyInConfig(ingestionConfig, 'roster', 'Rate'),
    email_address: hasOptionalKeyInConfig(
      ingestionConfig,
      'roster',
      'EmailAddress'
    ),
    location: hasOptionalKeyInConfig(
      ingestionConfig,
      'roster',
      'LocationMetadata'
    ),
    minor: false,
    hasActivatedCard: false,
    accountNumber: false,
    routingNumber: false,
  }
}
