import ContentEmpty from '@/common/empty'
import { ResourcesIcon } from '@/common/icons'

const NoResourcesCard = () => {
  return (
    <ContentEmpty
      icon={<ResourcesIcon />}
      title="No resources added yet"
      height="sm"
      subtitle="If your Customer Success representative adds resources to your organization's account, they'll show up here."
    />
  )
}

export default NoResourcesCard
