import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createFundingSource,
  deleteFundingSource,
  getFundingSources,
} from '@/api/funding-sources'
import { ICreateFundingSourceConfig } from '@/api/types/invoice'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useToasterStore } from '@/store/toast'

export const GET_FUNDING_SOURCES_QUERY = 'get-funding-sources'
export const GET_FUNDING_SOURCE_MAPPINGS_QUERY = 'get-funding-source-mappings'

export const useFundingSources = () => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([GET_FUNDING_SOURCES_QUERY, orgId], async () => {
    const res = await getFundingSources(orgId)
    return res.data
  })
}

export const useCreateFundingSource = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  const { addToast } = useToasterStore()
  return useMutation(
    async (params: ICreateFundingSourceConfig) => {
      const res = await createFundingSource(orgId, params)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_FUNDING_SOURCES_QUERY, orgId])
        addToast({
          type: 'success',
          title: 'Successfully created funding source',
        })
      },
      onError: (err: AxiosError) => {
        addToast({
          type: 'error',
          title: 'An error occurred',
          description: err.response?.data.message,
        })
      },
    }
  )
}

export const useDeleteFundingSource = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await deleteFundingSource(orgId, id)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_FUNDING_SOURCES_QUERY, orgId])
        queryClient.invalidateQueries([
          GET_FUNDING_SOURCE_MAPPINGS_QUERY,
          orgId,
        ])
      },
    }
  )
}
