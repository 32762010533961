import { styled } from '@/styles/stitches.config'
import { Flex } from '../../layout'

export const DetailsView = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

export const DetailHeader = styled(Flex, {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
})

export const DetailHeading = styled('h2', {
  fontSize: '1.125rem',
  fontWeight: 'bold',
  flexGrow: 1,
  margin: 0,
})

export const DetailActions = styled('div', {})
export const DetailSection = styled('div', {
  margin: '1rem 0',
  width: '100%',
})

export const DetailSeperator = styled('hr', {
  margin: '0.25rem 0',
})
