import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { OrgSettingsUpdates } from '@/api/types/organization'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import { isCommaDelimitedEmailList } from '@/lib/customYupValidators'
import { replaceCommasWithSemicolons, stripWhitespace } from '@/lib/strings'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { useModalStore } from '@/store/modal'
import { useSettingsStore } from '@/store/settings'

type InboundEmailValues = {
  ALLOWED_INGESTION_EMAILS: string
}

const settingsSchema = Yup.object().shape({
  ALLOWED_INGESTION_EMAILS: Yup.string().test(isCommaDelimitedEmailList),
})

export const InboundEmailForm = () => {
  const settings = useSettingsStore(state => state.settings)
  const {
    formState: { isDirty, errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: settings,
    resolver: yupResolver(settingsSchema),
  })
  const updateSettings = useUpdateSettingsMutation()
  const [open, setOpen] = useState(true)
  const { removeModal } = useModalStore()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const onFormSubmit = (values: InboundEmailValues) => {
    const allowedIngestionEmails = replaceCommasWithSemicolons(
      stripWhitespace(values.ALLOWED_INGESTION_EMAILS)
    )

    let payload: OrgSettingsUpdates = {
      ALLOWED_INGESTION_EMAILS: allowedIngestionEmails,
    }

    updateSettings.mutate(payload, {
      onSuccess: close,
    })
  }

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title="Edit Inbound Email Addresses"
      description="Only emails received from these addresses can import data into our system."
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <Label htmlFor="ALLOWED_INGESTION_EMAILS">Email Addresses</Label>
          <Input {...register('ALLOWED_INGESTION_EMAILS')} />
          <Text color="hint">
            Add one or more email addresses, separated by commas.
          </Text>
          <Text color="alert">{errors.ALLOWED_INGESTION_EMAILS?.message}</Text>
        </div>
        <Flex justify="end">
          <Button
            mode="filled"
            type="submit"
            loading={updateSettings.isLoading}
            disabled={updateSettings.isLoading || !isDirty}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Dialog>
  )
}
