import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { InviteEmailForm } from './InviteEmailForm'

export const InviteEmailModal: FC = () => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Invite Admin to Pay Admin"
      description="Email an invitation and the recipient will receive Pay Admin role access when registering a new account."
    >
      <InviteEmailForm handleClose={close} />
    </Dialog>
  )
}
