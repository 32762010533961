import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Checkbox } from '@/common/checkbox'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { useSetting } from '@/hooks/useSetting'
import { RegexPhone, RegexPOBox, YearMonthDayRegex } from '@/lib/regex'
import { US_STATES } from '@/lib/states'

export type QualifyEmployeeFormValues = {
  firstName: string
  lastName: string
  employeeId: string
  birthdate: string
  ssn: string
  phone: string
  email: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  orderCard: boolean
  createEmployee: boolean
  businessName?: string
  ein?: string
}

const schema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  employeeId: yup.string().required('Required'),
  birthdate: yup
    .string()
    .required('Required')
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
  ssn: yup
    .string()
    .required('Required')
    .length(9, 'Must be 9 digits'),
  address1: yup
    .string()
    .required('Required')
    .test(
      'PO Box check',
      'Address cannot be a PO Box',
      value => !!value && !RegexPOBox.test(value)
    ),
  address2: yup.string(),
  city: yup.string().required('Required'),
  state: yup.string().required('Required'),
  zip: yup.string().required('Required'),
  phone: yup
    .string()
    .matches(RegexPhone, 'Must be in the format 123-456-7890')
    .required('Required'),
  email: yup
    .string()
    .email('Must be a valid email')
    .required('Required'),
})

const defaultValues = {
  firstName: '',
  lastName: '',
  employeeId: '',
  birthdate: '',
  ssn: '',
  phone: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  state: US_STATES[0],
  zip: '',
  orderCard: false,
  createEmployee: true,
  businessName: undefined,
  ein: undefined,
}

interface Props {
  handleFormSubmit: (values: QualifyEmployeeFormValues) => void
}

export const QualifyEmployeeForm: FC<Props> = ({ handleFormSubmit }) => {
  const includeBusinessInfo =
    useSetting('BUSINESS_INFO_ON_STATEMENT') === 'true'
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<QualifyEmployeeFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const updateVal = (
    key: keyof QualifyEmployeeFormValues,
    val: boolean | string
  ) => {
    setValue(key, val, { shouldDirty: true })
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <p>To create a new account, we’ll just need some information.</p>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="firstName">First Name</Label>
          <Input type="text" {...register('firstName')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.firstName?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label htmlFor="lastName">Last Name</Label>
          <Input type="text" {...register('lastName')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.lastName?.message}
          </Text>
        </Flex>
      </Flex>

      <Flex direction="column" align="start" css={{ width: '100%', mb: '$12' }}>
        <Label htmlFor="employeeId">ID</Label>
        <Input type="text" {...register('employeeId')} css={{ mt: '$4' }} />
        <Text color="alert" size="sm">
          {errors?.employeeId?.message}
        </Text>
      </Flex>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="birthdate">Birth Date</Label>
          <Input type="text" {...register('birthdate')} css={{ mt: '$4' }} />
          <Text color="hint" size="sm">
            Ex: 1980-04-30
          </Text>
          <Text color="alert" size="sm">
            {errors?.birthdate?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label htmlFor="ssn">SSN</Label>
          <Input type="text" {...register('ssn')} css={{ mt: '$4' }} />
          <Text color="hint" size="sm">
            Ex: 123121234
          </Text>
          <Text color="alert" size="sm">
            {errors?.ssn?.message}
          </Text>
        </Flex>
      </Flex>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="phone">Phone</Label>
          <Input type="text" {...register('phone')} css={{ mt: '$4' }} />
          <Text color="hint" size="sm">
            Ex: 123-456-7890
          </Text>
          <Text color="alert" size="sm">
            {errors?.phone?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label htmlFor="email">Email</Label>
          <Input type="text" {...register('email')} css={{ mt: '$4' }} />
          <Text color="hint" size="sm">
            Ex: name@email.com
          </Text>
          <Text color="alert" size="sm">
            {errors?.email?.message}
          </Text>
        </Flex>
      </Flex>

      {includeBusinessInfo && (
        <Flex css={{ mb: '$12' }}>
          <Flex
            direction="column"
            align="start"
            css={{ width: '100%', mr: '$12' }}
          >
            <Label htmlFor="phone">Business Name</Label>
            <Input
              type="text"
              {...register('businessName')}
              css={{ mt: '$4' }}
            />

            <Text color="alert" size="sm">
              {errors?.businessName?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="start" css={{ width: '100%' }}>
            <Label htmlFor="email">EIN</Label>
            <Input type="text" {...register('ein')} css={{ mt: '$4' }} />
            <Text color="alert" size="sm">
              {errors?.ein?.message}
            </Text>
          </Flex>
        </Flex>
      )}

      <h5 className="mb-3">Shipping Details</h5>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="address1">Address 1</Label>
          <Input type="text" {...register('address1')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.address1?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label htmlFor="address2">Address 2</Label>
          <Input type="text" {...register('address2')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.address2?.message}
          </Text>
        </Flex>
      </Flex>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="city">City</Label>
          <Input
            type="text"
            {...register('city')}
            css={{ width: '270px', mt: '$4' }}
          />
          <Text color="alert" size="sm">
            {errors?.city?.message}
          </Text>
        </Flex>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$8', mt: '$4' }}
        >
          <Label htmlFor="state">State</Label>
          <Select
            options={US_STATES.map(state => ({ label: state, value: state }))}
            value={watch('state')}
            onChange={val => setValue('state', val)}
          />
          <Text color="alert" size="sm">
            {errors?.state?.message}
          </Text>
        </Flex>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="zip">Zip</Label>
          <Input type="text" {...register('zip')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.zip?.message}
          </Text>
        </Flex>
      </Flex>

      <Flex direction="column" align="start" css={{ mb: '$12' }}>
        {/* <Checkbox
          id="orderCard"
          checked={watch('orderCard')}
          onChange={(_, value) => updateVal('orderCard', value)}
          label="Ship personalized card"
        /> */}
        <Checkbox
          id="createEmployee"
          checked={watch('createEmployee')}
          onChange={(_, value) => updateVal('createEmployee', value)}
          label="If no roster record exists, create one"
        />
      </Flex>
      <Flex>
        <Button mode="filled" type="submit" css={{ ml: 'auto' }}>
          Continue
        </Button>
      </Flex>
    </form>
  )
}
