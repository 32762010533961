import moment from 'moment'
import { FC } from 'react'
import { TableContainer } from '@/common/table/styles'
import { Text } from '@/common/text'
import { ConfirmationModal } from '@/components/paybacks/components/ConfirmationModal'
import { toDollar } from '@/lib/currency'
import { AddPayoutFormValues } from './AddPayoutForm'

interface Props {
  loading: boolean
  handleSubmit: () => void
  handleBack: () => void
  values?: AddPayoutFormValues
}

export const AddPayoutConfirmation: FC<Props> = ({
  loading,
  handleSubmit,
  handleBack,
  values,
}) => {
  if (!values) return null

  const scheduledDate = values.time_scheduled
    ? moment(values.time_scheduled).format('YYYY-MM-DD')
    : 'Instant'

  return (
    <ConfirmationModal
      loading={loading}
      btnLabel="Add Disbursement"
      handleSubmit={handleSubmit}
      handleBack={handleBack}
      values={values}
    >
      <TableContainer>
        <table>
          <tbody>
            <tr>
              <td>
                <Text bold>Worker</Text>
              </td>
              <td>{values.employeeName}</td>
            </tr>
            <tr>
              <td>
                <Text bold>ID</Text>
              </td>
              <td>{values.employeeId}</td>
            </tr>
            <tr>
              <td>
                <Text bold>Type</Text>
              </td>
              <td>{values.payoutType}</td>
            </tr>
            <tr>
              <td>
                <Text bold>Amount</Text>
              </td>
              <td>{toDollar(values.amount, { includeSymbol: true })}</td>
            </tr>
            {!!values.description && (
              <tr>
                <td className="font-weight-bold">Description</td>
                <td>{values.description}</td>
              </tr>
            )}
            {!!values.externalStoreId && (
              <tr>
                <td className="font-weight-bold">Group ID</td>
                <td>{values.externalStoreId}</td>
              </tr>
            )}
            {!!values.externalId && (
              <tr>
                <td className="font-weight-bold">Transaction ID</td>
                <td>{values.externalId}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Disbursement Date</td>
              <td>{scheduledDate}</td>
            </tr>
            {values.metadata.map(rec => (
              <tr key={rec.type}>
                <td className="font-weight-bold">{rec.type}</td>
                <td>{rec.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </ConfirmationModal>
  )
}
