import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  addEmailRegistration,
  getIngestionConfigurations,
  importRoster,
} from '@/api/ingestion'
import { IEmailRegistrationConfig } from '@/api/types/settings'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { ROSTER_QUERY } from './employees'

const GET_INGESTION_CONFIGS_QUERY = 'get-ingestion-configs'

export const useIngestionConfig = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([GET_INGESTION_CONFIGS_QUERY], async () => {
    const res = await getIngestionConfigurations(orgId)
    return res.data
  })
}

export const useIngestRoster = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: { [key: string]: string }) => {
      const res = await importRoster(params)
      return res.data
    },
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries([GET_INGESTION_CONFIGS_QUERY])
        if (params.save_data) {
          queryClient.invalidateQueries([ROSTER_QUERY])
        }
      },
    }
  )
}

export const useAddEmailRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  return useMutation(async (params: IEmailRegistrationConfig) => {
    const res = await addEmailRegistration(params, orgId)
    return res.data
  })
}
