import { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { editPayout } from '@/api/payouts'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { TextArea } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { handleHttpException } from '@/lib/httpException'
import { PAYOUTS_QUERY } from '@/src/queries/payouts'
import { useModalStore } from '@/store/modal'
import { useUser } from '@/store/user'
import { PayoutRecord, PayoutStatus } from '@/types/payouts'

type ActionLabel = 'Cancel' | 'Hide'

interface Props {
  payout: PayoutRecord
  action: ActionLabel
}

export const HidePayoutModal: FC<Props> = ({ action, payout }) => {
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [hasError, setHasError] = useState(false)

  const { removeModal } = useModalStore()
  const userId = useUser(state => state.user?.user_id)
  const [open, setOpen] = useState(true)
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const hidePayout = async () => {
    setLoading(true)
    setHasError(false)
    setErrorMessage(undefined)

    try {
      await editPayout(
        payout.id,
        {
          external_id: payout.external_id,
          type: payout.type,
          status: PayoutStatus.CANCELLED,
          updated_by: userId ? +userId : undefined,
          reason,
        },
        orgId
      )
      queryClient.invalidateQueries(PAYOUTS_QUERY)
      close()
    } catch (err) {
      handleHttpException(err, {
        onHttpError: ({ response }) => setErrorMessage(response?.data?.message),
      })
      setHasError(true)
      setLoading(false)
    }
  }

  const verb = action === 'Cancel' ? 'Canceling' : 'Hiding'

  const description = (
    <>
      {verb} this disbursement will remove it from the Disbursements table view.
      If you need to see it again, you can query for all hidden disbursements by
      filtering on the “Cancelled” status.
    </>
  )

  return (
    <Dialog
      open={open}
      setOpen={close}
      title={`${verb} Disbursement`}
      description={description}
    >
      {hasError && (
        <Alert type="danger" css={{ mb: '$12' }}>
          Encountered an error. ({errorMessage})
        </Alert>
      )}
      <div>
        <Label>Reason for {verb}</Label>
        <TextArea
          onChange={e => setReason(e.currentTarget.value)}
          value={reason}
        />
      </div>
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button
          mode="filled"
          onClick={hidePayout}
          loading={loading}
          disabled={reason.length < 1}
        >
          {action} Disbursement
        </Button>
      </Flex>
    </Dialog>
  )
}
