import { readFileAsDataURL } from './asyncReader'

export const humanFileSize = (bytes: number, si: boolean) => {
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  let b = bytes
  do {
    b /= thresh
    ++u
  } while (Math.abs(b) >= thresh && u < units.length - 1)
  return `${b.toFixed(1)} ${units[u]}`
}

export type Dimensions = {
  height: number
  width: number
}

export const getImageFileDimensions = async (file: File) => {
  try {
    const res = await readFileAsDataURL(file)
    return getImgsize(res)
  } catch (e) {
    console.error('Faled to get image file dimensions', e)
    throw e
  }
}

export const getImgsize = (src: string): Promise<Dimensions> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.src = src
  })
