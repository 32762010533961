import { CompleteIcon } from '@/common/icons'

const ProgressCardComplete = () => {
  return (
    <div className="d-flex flex-align-center">
      <CompleteIcon className="mr-2" />
      <i>Step Complete</i>
    </div>
  )
}

export default ProgressCardComplete
