import { CardProgram } from './components/CardProgram'
import { CustomDisbursementOptions } from './components/CustomDisbursementOptions'
import { GeneralSetup } from './components/GeneralSetup'
import { InstantTransferFees } from './components/InstantTransferFees'
import { OnboardingFlowConfig } from './components/OnboardingFlowConfig'
import { OrgLocation } from './components/OrgLocation'
import { OrgLocationContainer } from './components/OrgLocationContainer'
import { SharedPartner } from './components/SharedPartner'
import { SectionSeperator } from './components/styles'

export const OrgLocationSettings = () => (
  <OrgLocationContainer>
    <GeneralSetup />
    <SectionSeperator />
    <OrgLocation />
    <SectionSeperator />
    <CustomDisbursementOptions />
    <SectionSeperator />
    <OnboardingFlowConfig />
    <SectionSeperator />
    <InstantTransferFees />
    <SectionSeperator />
    <CardProgram />
    <SectionSeperator />
    <SharedPartner />
  </OrgLocationContainer>
)
