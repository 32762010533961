import { FC, useRef } from 'react'
import { Badge, BadgeSize, BadgeTypes } from '@/common/badge'
import { Tooltip } from '@/common/tooltip'
import { PayoutRecord, PayoutStatus } from '@/types/payouts'
import { PAYOUT_STATUS_LABEL_MAP } from '../lib/labelMap'

interface Props {
  payout: PayoutRecord
  showTooltip?: boolean
  badgeSize?: BadgeSize
}

export const StatusBadge: FC<Props> = ({ payout, showTooltip, badgeSize }) => {
  const { status, status_reason, number_of_attempts } = payout
  const itemRef = useRef<HTMLElement>(null)

  let badgeType: BadgeTypes = 'info'

  if (status === PayoutStatus.COMPLETED) {
    badgeType = 'success'
  }

  if (status === PayoutStatus.FAILED) {
    badgeType = 'danger'
  }

  if (status === PayoutStatus.PAYBACK_FAILED) {
    badgeType = 'warning'
  }

  if (!status) {
    return null
  }

  if (
    showTooltip &&
    (status === PayoutStatus.FAILED || status === PayoutStatus.CANCELLED) &&
    itemRef.current
  ) {
    return (
      <span ref={itemRef}>
        <Tooltip
          trigger={
            <Badge type={badgeType} size={badgeSize}>
              {PAYOUT_STATUS_LABEL_MAP[status]}
              {status === PayoutStatus.FAILED &&
                number_of_attempts > 1 &&
                ` x${number_of_attempts}`}
            </Badge>
          }
        >
          {status_reason || 'No reason provided'}
        </Tooltip>
      </span>
    )
  }

  return (
    <span ref={itemRef}>
      <Badge type={badgeType} size={badgeSize}>
        {PAYOUT_STATUS_LABEL_MAP[status]}
        {status === PayoutStatus.FAILED &&
          number_of_attempts > 1 &&
          ` x${number_of_attempts}`}
      </Badge>
    </span>
  )
}
