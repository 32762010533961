import classnames from 'classnames'
import { cloneElement } from 'react'
import styles from './StatDisplay.module.scss'

interface IStatProps {
  stat: string | number | null
  label: string
  icon?: React.ReactElement
  noPadding?: boolean
  ofTotal?: string
  bgColor?: string
}

export default function StatDisplay({
  stat,
  label,
  icon,
  ofTotal,
  noPadding,
  bgColor = '#ffffff',
}: IStatProps) {
  const classes = classnames(styles.StatDisplay, {
    [styles.StatDisplay_noPadding]: noPadding,
  })

  const style = {
    card: { backgroundColor: `${bgColor}2b` },
    label: { color: bgColor },
  }

  return (
    <div className={classes} style={style.card}>
      {icon && cloneElement(icon, { className: styles.Icon })}
      <div>
        <p className={styles.Label}>{label}</p>
        <h1 className={styles.Stat} style={style.label}>
          {stat} {ofTotal && <small className={styles.Label}>{ofTotal}</small>}
        </h1>
      </div>
    </div>
  )
}
