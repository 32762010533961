import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { useCreateWebhookDefinition } from '@/queries/webhooks'

interface FormValues {
  url: string
}

const scheme = yup.object().shape({
  url: yup
    .string()
    .url()
    .required(),
})

export const CreateWebhookDefinition = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { url: '' },
    resolver: yupResolver(scheme),
  })
  const createDefinition = useCreateWebhookDefinition()
  const onSubmit = (values: FormValues) =>
    createDefinition.mutate(values.url, {
      onSuccess: () => reset(),
    })

  return (
    <Card>
      <Text bold>Add Webhook</Text>
      <Flex css={{ mt: '$24' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexWrap align="end">
            <div>
              <Label htmlFor="url">Webhook URL</Label>
              <Input
                {...register('url')}
                id="url"
                placeholder="https://"
                css={{ minWidth: '350px' }}
              />
              <Text color="alert" size="sm">
                {errors.url?.message}
              </Text>
            </div>
            <Button
              mode="filled"
              type="submit"
              loading={createDefinition.isLoading}
              disabled={createDefinition.isLoading}
            >
              Add
            </Button>
          </FlexWrap>
        </form>
      </Flex>
    </Card>
  )
}
