import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router'
import {
  getOrganizations,
  getUserDetails,
  logout,
  postTextCode,
  validateTextCode,
} from '@/api/auth'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'
import { useUser } from '@/store/user'

export interface ValidatePhoneParams {
  phone: string
  code: string
}

export const useLogoutQuery = () => {
  const history = useHistory()
  const { addToast } = useToasterStore()
  const { parseToken } = useUser()

  const { setCurrentOrganization } = useUser()

  return useMutation(
    async () => {
      const res = await logout()
      return res.data.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('user', 'logout')
        parseToken(undefined)
        setCurrentOrganization(null)
        history.push('/')
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Error Logging Out',
          description: 'An error occured during log out. Please try again.',
        })
      },
    }
  )
}

export const useGetTextCodeQuery = () => {
  return useMutation(async (phoneNumber: string) => {
    const res = await postTextCode(phoneNumber)
    return res.data.data
  })
}

export const useValidateTextCodeQuery = () => {
  const { parseToken } = useUser()
  return useMutation(
    async ({ phone, code }: ValidatePhoneParams) => {
      const res = await validateTextCode(phone, code)
      return res.data.data
    },
    {
      onSuccess: data => {
        sendAnalyticsEvent('user', 'login')
        parseToken(data.token)
      },
    }
  )
}

export const USER_ORGANIZATIONS_QUERY = 'user-organizations'

export const useUserOrganizationsQuery = () => {
  const { id } = useUser()
  return useQuery(
    [USER_ORGANIZATIONS_QUERY, id],
    async () => {
      const res = await getOrganizations(id)
      return res.data.data
    },
    {
      enabled: !!id,
    }
  )
}

export const USER_DETAILS_QUERY = 'user-details'

export const useUserDetailsQuery = () => {
  const { id } = useUser()
  return useQuery(
    [USER_DETAILS_QUERY, id],
    async () => {
      const res = await getUserDetails(id)
      return res.data.data
    },
    {
      enabled: !!id,
    }
  )
}
