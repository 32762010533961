import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { AlertDialog } from '@/common/modal'
import { useCreateSubAccountMutation } from '@/src/queries/sub-account'
import { useModalStore } from '@/store/modal'

export const CreateSubAccount = () => {
  const { registerModal } = useModalStore()
  const mutation = useCreateSubAccountMutation()

  const description = (
    <p>
      Adding a Sub Account will assign an account and routing number to this
      organization.
    </p>
  )

  const openCreateModal = () => {
    registerModal(
      <AlertDialog
        title="Are you sure?"
        description={description}
        actionDescription={'Complete'}
        handleAction={mutation.mutateAsync}
      />
    )
  }

  return (
    <>
      <Alert type="info">No sub account yet.</Alert>
      <div className="d-flex justify-content-end">
        <Button mode="filled" onClick={openCreateModal}>
          Add Sub Account
        </Button>
      </div>
    </>
  )
}
