import { useMemo } from 'react'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import { usePayroll } from '@/queries/payroll'
import { payRollColumns } from './components/payroll-columns'
import { PayrollContainer } from './components/PayrollContainer'
import { formatPayroll } from './lib/generate-payroll'

const Payroll = () => {
  const payrollData = usePayroll()

  const payroll = useMemo(
    () => formatPayroll(payrollData.data?.payroll_periods),
    [payrollData]
  )

  return (
    <PayrollContainer>
      <Loader loading={payrollData.isLoading}>
        {payroll.length < 1 ? (
          <ContentEmpty
            icon={<EmptyTableIcon />}
            title="No data available"
            height="sm"
            subtitle="Doesn't look like we have your payroll periods set. Please reach out to your Branch representative."
          />
        ) : (
          <SimpleTable data={payroll} columns={payRollColumns} />
        )}
      </Loader>
    </PayrollContainer>
  )
}

export default Payroll
