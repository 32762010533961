import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { JobsRecord } from '@/types/jobs'

export const columns: ColumnDef<JobsRecord, any>[] = [
  {
    header: 'Completed At',
    id: 'time_completed',
    accessorFn: row => moment(row.time_completed),
    cell: row =>
      row.getValue() ? moment(row.getValue()).format('MM/DD/YYYY h:mmA') : null,
  },
  {
    header: 'Filename',
    id: 'filename',
    accessorFn: row => row.filename,
  },
  {
    header: 'Job Name',
    id: 'job_name',
    accessorFn: row => row.job_name,
  },
  {
    header: 'ID',
    id: 'id',
    accessorFn: row => row.id,
  },
  {
    header: 'Job Status',
    id: 'status',
    accessorFn: row => row.job_status,
  },
]
