type Props = React.SVGAttributes<SVGSVGElement>

const SvgChatQuestionIcon = (props: Props) => (
  <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#E2E8EB"
        d="M20.95 26.334L8.076 39.21c-.764.764-2.07.223-2.07-.857V26.334H20.95z"
      />
      <rect width={40} height={34} fill="#F5F5F5" rx={8} />
      <path
        fill="#6CD27D"
        d="M19.789 22.95c-1 0-1.81-.81-1.81-1.81v-.494c0-2.128 1.226-4.047 3.198-5.01 1.105-.54 2.062-1.847 1.707-3.551a3.125 3.125 0 00-2.393-2.392c-.984-.206-1.943.02-2.698.634a3.157 3.157 0 00-1.172 2.46 1.81 1.81 0 01-3.621 0c0-2.053.914-3.973 2.509-5.27 1.594-1.295 3.679-1.793 5.721-1.37 2.573.537 4.662 2.626 5.198 5.199.641 3.076-.865 6.178-3.663 7.544-.719.35-1.165 1.024-1.165 1.756v.494c0 1-.811 1.81-1.811 1.81"
      />
      <path
        fill="#42994C"
        d="M19.789 28.231c-.48 0-.941-.19-1.286-.525a2.123 2.123 0 01-.216-.28 1.604 1.604 0 01-.272-.652 1.562 1.562 0 01-.036-.354c0-.117.009-.235.036-.353a1.588 1.588 0 01.272-.65c.063-.1.135-.19.216-.273.68-.679 1.892-.679 2.562 0 .091.082.163.172.226.272.064.1.127.2.172.308.046.117.073.226.1.343.027.118.037.236.037.353 0 .118-.01.236-.037.353a2.36 2.36 0 01-.1.345 1.972 1.972 0 01-.172.307c-.063.1-.135.19-.226.281a1.809 1.809 0 01-1.276.525"
      />
    </g>
  </svg>
)

export default SvgChatQuestionIcon
