type Props = React.SVGAttributes<SVGSVGElement>

const SvgArrowRightIcon = (props: Props) => (
  <svg width={16} height={16} {...props}>
    <path
      d="M7.295.705a1 1 0 00.002 1.413L12.17 7H.996A.996.996 0 000 8c0 .552.448 1 .996 1H12.17l-4.882 4.873a.999.999 0 00.002 1.417 1.01 1.01 0 001.42 0l6.58-6.58a1.01 1.01 0 000-1.42L8.71.71A.998.998 0 007.295.705z"
      fill="#212121"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgArrowRightIcon
