import { DownloadIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { useSetting } from '@/hooks/useSetting'
import { Button, CSVDownloadButton } from '../button'
import { CSV_FORMAT_TYPE } from '../button/CSVDownloadForm'
import { ConditionalTooltip } from '../tooltip/ConditionalTooltip'

interface Props {
  downloadLoading?: boolean
  handleDownload?: () => void
  handleCSVDownload?: (val?: CSV_FORMAT_TYPE) => void
  disableEnterpriseReporting?: boolean
}

export const DownloadButton: FC<Props> = ({
  downloadLoading = false,
  disableEnterpriseReporting,
  handleDownload,
  handleCSVDownload,
}) => {
  const enterpriseReporting =
    useSetting('ENABLE_ENTERPRISE_REPORTING') === 'true' &&
    !disableEnterpriseReporting

  const renderButton = () => {
    if (!!handleCSVDownload && !enterpriseReporting)
      return (
        <CSVDownloadButton
          CSVDownloading={downloadLoading}
          handleDownload={handleCSVDownload}
        />
      )

    return (
      <Button
        mode="outline"
        color="black"
        loading={downloadLoading}
        onClick={handleDownload}
        iconRight
        disabled={enterpriseReporting}
        css={{ ml: '$12' }}
      >
        <DownloadIcon
          style={{ marginRight: '8px', transform: 'scale(1.25)' }}
        />
        Download
      </Button>
    )
  }

  return (
    <ConditionalTooltip
      trigger={renderButton()}
      showTooltip={enterpriseReporting}
    >
      Your organization is set up for enterprise reporting. Please contact your
      CSM to process this report.
    </ConditionalTooltip>
  )
}
