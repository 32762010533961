import { Cross2Icon, EyeNoneIcon, ReloadIcon } from '@radix-ui/react-icons'
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table'
import { FC, useEffect } from 'react'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { useMultiActionDisbursementHeader } from '@/hooks/useMultiActionDisbursementHeader'
import {
  useAddPayoutMutation,
  useEditPayoutMutation,
  usePayoutsQuery,
} from '@/src/queries/payouts'
import { useModalStore } from '@/store/modal'
import { useUser } from '@/store/user'
import { PayoutRecord, PayoutStatus } from '@/types/payouts'
import { MultiActionButton } from './MultiActionButton'
import { MultiActionTaskModal } from './MultiActionTaskModal'

interface Props {
  payouts: PayoutRecord[]
  setRowsSelected: OnChangeFn<RowSelectionState>
}

export const MultiActionHeader: FC<Props> = ({
  payouts = [],
  setRowsSelected,
}) => {
  const {
    retryValid,
    cancelValid,
    hideValid,
    setSelected,
  } = useMultiActionDisbursementHeader()

  const { isLoading } = usePayoutsQuery()

  useEffect(() => {
    if (isLoading) {
      setSelected([])
      setRowsSelected({})
    }
  }, [isLoading, setSelected, setRowsSelected])

  const userId = useUser(state => state.user?.user_id)
  const { registerModal } = useModalStore()

  const addPayout = useAddPayoutMutation()
  const editPayout = useEditPayoutMutation()

  const handleRetry = async () => {
    const retryTask = payouts.map(
      ({
        roster_record: { employee_id },
        amount,
        type,
        description,
        metadata,
        external_id,
        external_store_id,
      }) => {
        return addPayout.mutateAsync({
          employee_id,
          amount,
          type,
          description,
          metadata,
          external_id,
          external_store_id,
          retry: true,
        })
      }
    )
    registerModal(<MultiActionTaskModal action="retrying" task={retryTask} />)
    setSelected([])
    setRowsSelected({})
  }

  const handleHideCancel = async () => {
    const hideTask = payouts.map(({ id, external_id, type }) => {
      return editPayout.mutateAsync({
        id,
        config: {
          type,
          external_id,
          status: PayoutStatus.CANCELLED,
          updated_by: userId ? +userId : undefined,
        },
      })
    })
    registerModal(
      <MultiActionTaskModal action="hiding/canceling" task={hideTask} />
    )
    setSelected([])
    setRowsSelected({})
  }

  return (
    <Flex>
      <Text css={{ mr: '$12' }}>{payouts.length} selected</Text>
      <MultiActionButton
        handleAction={() => handleRetry()}
        isLoading={addPayout.isLoading}
        disabled={!retryValid || payouts.length === 0}
      >
        <ReloadIcon />
        <Text css={{ ml: '$8' }}> Retry</Text>
      </MultiActionButton>
      <MultiActionButton
        handleAction={() => handleHideCancel()}
        isLoading={addPayout.isLoading}
        disabled={!hideValid || payouts.length === 0}
      >
        <EyeNoneIcon />
        <Text css={{ ml: '$8' }}>Hide</Text>
      </MultiActionButton>
      <MultiActionButton
        handleAction={() => handleHideCancel()}
        isLoading={addPayout.isLoading}
        disabled={!cancelValid || payouts.length === 0}
      >
        <Cross2Icon />
        <Text css={{ ml: '$8' }}>Cancel</Text>
      </MultiActionButton>
    </Flex>
  )
}
