const SvgFirstTimeAdvances = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={48} height={49} {...props}>
    <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <circle fill="#2A515A54" cx={24} cy={24} r={24} />
      <path
        d="M13.5 29.25a6.003 6.003 0 0 1 8.356-5.518.75.75 0 1 0 .588-1.38A7.503 7.503 0 0 0 12 29.249a.75.75 0 0 0 1.5.002zm15-3.739V25.5a.75.75 0 1 1 1.5 0h.75a.75.75 0 1 1 0 1.5h-2.033a.592.592 0 0 0-.223 1.14l2.064.826A2.092 2.092 0 0 1 30 32.989V33a.75.75 0 1 1-1.5 0h-.75a.75.75 0 1 1 0-1.5h2.03a.592.592 0 0 0 .22-1.142l-2.064-.825a2.092 2.092 0 0 1 .564-4.022zm-9-3.761a4.875 4.875 0 1 1 0-9.75 4.875 4.875 0 0 1 0 9.75zm0-1.5a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75zM29.25 36a6.75 6.75 0 1 1 0-13.5 6.75 6.75 0 0 1 0 13.5zm0-1.5a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5z"
        fill="#2A515A"
      />
    </g>
  </svg>
)

export default SvgFirstTimeAdvances
