import { FC } from 'react'
import { Alert } from '@/common/alert'
import { AlertDialog } from '@/common/modal'
import { useCancelKycMutation } from '@/src/queries/create-accounts'
import { InitiationUserRecord } from '@/types/create-accounts'

interface Props {
  record: InitiationUserRecord
}

export const CancelKycModal: FC<Props> = ({ record }) => {
  const mutation = useCancelKycMutation(record.id)

  const handleCancel = async () => mutation.mutateAsync(undefined)

  return (
    <AlertDialog
      title={'Please Confirm Action'}
      description={'Are you sure you want to cancel this KYC?'}
      actionDescription={'Confirm'}
      handleAction={handleCancel}
      isDestructive={true}
    >
      {mutation.isError && <Alert type="danger">Encountered an error.</Alert>}
    </AlertDialog>
  )
}
