import qs from 'qs'
import {
  DisbursementGroupSummaryResponse,
  EditPaybackConfig,
  GetPaybackParams,
  IGetPaybackRecordsResponse,
} from '@/api/types/paybacks'
import {
  GetDisbursementsTotalParams,
  GetDisbursementsTotalResponse,
  GetPayoutsParams,
  IAddPayoutConfig,
  IEditPayoutConfig,
  IGetPayoutRecordsResponse,
} from '@/api/types/payouts'
import { PayoutRecord } from '@/types/payouts'
import http from './http'

/**
 * Allows Pay Admins to get all payout records
 */
export const getPayouts = (orgId: number, params: GetPayoutsParams) => {
  const query = qs
    .stringify(params, { encode: false })
    .replaceAll('#', '%23')
    .replaceAll('+', '%2B')
  return http.get<IGetPayoutRecordsResponse>(
    `/payout/organizations/${orgId}/disbursements?${query}`
  )
}

/**
 * Get all payout types ("Tips", "Mileage", etc.)
 */
export const getPayoutTypes = (orgId: number) =>
  http.get<string[]>(`payout/organizations/${orgId}/disbursements/types`)

/**
 * Allows Pay Admins to add a new payout
 */
export const addPayout = (orgId: number, config: IAddPayoutConfig) =>
  http.post<PayoutRecord>(
    `/payout/organizations/${orgId}/disbursements`,
    config
  )

export const editPayout = (
  id: number,
  config: IEditPayoutConfig,
  orgId: number
) => http.patch(`/payout/organizations/${orgId}/disbursements/${id}`, config)

/**
 * Get disbursement total
 */
export const getDisbursementTotal = (
  orgId: number,
  params: GetDisbursementsTotalParams
) => {
  const queryParams = qs.stringify(params)
  return http.get<GetDisbursementsTotalResponse>(
    `payout/organizations/${orgId}/disbursements/summary?${queryParams}`
  )
}

/**
 * Allows Pay Admins to bulk create disbursements using a csv
 * @param file CSV containing disbursement data
 */
export const createBulkDisbursements = (
  orgId: number,
  file: File,
  retry?: boolean
) => {
  const formData = new FormData()
  formData.append('file', file)
  const queryParams = retry ? '?RETRY_DISBURSEMENTS=true' : ''

  return http.post(
    `wfm/organizations/${orgId}/processors/enhancedTimeScheduledDisbursementFileProcessor/jobs${queryParams}`,
    formData
  )
}

/**
 * Allows Pay Admins to get all payback records
 */
export const getPaybacks = (orgId: number, params: GetPaybackParams) =>
  http.get<IGetPaybackRecordsResponse>(
    `/payout/organizations/${orgId}/invoices?${qs.stringify(params)}`
  )

/**
 * Retrive a list of available payback source types
 * @param orgId organization ID
 */
export const getPaybackTypes = (orgId: number) =>
  http.get<string[]>(`/payout/organizations/${orgId}/invoices/sources`)

/**
 * Retries a payback
 */
export const retryPayback = (params: {
  orgId: number
  id: number
  fundingSourceId?: number
}) =>
  http.put(`payout/organizations/${params.orgId}/invoices/${params.id}/retry`, {
    funding_source_id: params.fundingSourceId,
  })

/**
 * Edit a payback
 */
export const editPayback = (
  orgId: number,
  invoiceId: number,
  edits: EditPaybackConfig
) => http.patch(`payout/organizations/${orgId}/invoices/${invoiceId}`, edits)

export const getDisbursementsSummary = (params: {
  orgId: number
  paybackId: number
  groupBy: 'external_store_id' | 'type'
}) =>
  http.get<DisbursementGroupSummaryResponse>(
    `payout/reports/organizations/${params.orgId}/disbursements/summary?payback_id=${params.paybackId}&group_by=${params.groupBy}`
  )
