import { useState } from 'react'
import { getInitiatedUsers } from '@/api/create-accounts'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import {
  createInitiatedUserParams,
  InitiatedUsersQueryConfig,
} from '@/src/queries/create-accounts'
import { useToasterStore } from '@/store/toast'
import { downloadInitiatedUsersCSV } from './download'

export const useDownloadInitiatedUsers = (
  config: InitiatedUsersQueryConfig
) => {
  const { id: orgId } = useCurrentOrg()
  const [loading, setLoading] = useState(false)
  const { addToast, expireToast } = useToasterStore()
  const params = createInitiatedUserParams(config)

  const handleDownload = async () => {
    sendAnalyticsEvent('accounts', 'download', params)
    let toastId

    setLoading(true)

    try {
      const {
        data: { data },
      } = await getInitiatedUsers(orgId, params)
      const breakLimit = 5000
      let results = [...data.content]
      let currPage = 1
      let recordCount = data.content.length

      toastId = addToast({
        type: 'pending',
        title: 'Downloading created accounts',
        description: `We're fetching your records, it might take a moment.`,
      })

      while (recordCount === params.size && results.length < breakLimit) {
        const {
          data: { data: recursiveData },
        } = await getInitiatedUsers(orgId, {
          ...params,
          page: currPage + 1,
        })
        results = [...results, ...recursiveData.content]
        recordCount = recursiveData.content.length
        currPage += 1
      }

      downloadInitiatedUsersCSV(results)
    } catch (error) {
      addToast({
        type: 'error',
        title: "Couldn't download results.",
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      if (toastId) {
        expireToast(toastId, 0)
      }
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
