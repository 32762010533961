import { FC, useMemo } from 'react'
import { Card } from '@/common/card'
import { DetailHeading } from '@/common/detail'
import { Loader } from '@/common/loader'
import { TableContainer } from '@/common/table/styles'
import { toDollar } from '@/lib/currency'
import { usePayoutLocationSummaryQuery } from '@/src/queries/payouts'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  payback: PaybackRecord
}

export const StoreSummary: FC<Props> = ({ payback }) => {
  const summary = usePayoutLocationSummaryQuery(payback.id)

  const hasStoreSummary = useMemo(() => {
    if (!summary.data) return false
    const items = summary.data.group_by_totals[0].summary_items
    const hasInvalidData =
      items.length === 0 || (items.length === 1 && items[0].id === '')

    return !hasInvalidData
  }, [summary])

  return (
    <Loader loading={summary.isLoading}>
      <>
        {hasStoreSummary && (
          <Card>
            <DetailHeading css={{ mb: '$8' }}>Store Summary</DetailHeading>
            {summary.data?.group_by_totals.map(group => (
              <TableContainer>
                <table key={group.group_by}>
                  <thead>
                    <tr>
                      <th>Store</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.summary_items.map(item => (
                      <tr key={item.id}>
                        <td>{item.id === '' ? 'Unspecified' : item.id}</td>
                        <td>{toDollar(item.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableContainer>
            ))}
          </Card>
        )}
      </>
    </Loader>
  )
}
