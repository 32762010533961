import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { debounce } from '@/lib/debounce'
import { Input, InputIcon } from '../input'
import { Flex } from '../layout'

interface Props {
  handleChange: (value: string | undefined) => void
  fullWidth?: boolean
  placeholder: string | undefined
  defaultValue: any
}

export const SearchFilter: FC<Props> = ({
  handleChange,
  fullWidth,
  placeholder,
  defaultValue,
}) => {
  const debouncedHandler = debounce(handleChange, 750)
  return (
    <Flex css={{ width: fullWidth ? '100%' : 250 }}>
      <InputIcon css={{ height: 32 }}>
        <MagnifyingGlassIcon style={{ height: 20, width: 20 }} />
      </InputIcon>
      <Input
        defaultValue={defaultValue}
        onChange={e => debouncedHandler(e.target.value)}
        placeholder={placeholder}
        iconLeft
        css={{ height: 32 }}
      />
    </Flex>
  )
}
