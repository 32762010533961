import { GeneralSetupFormValues } from '../components/GeneralSetup'

export type CheckboxDescription = {
  label: string
  tooltip: string
}

export type GeneralSetupFormValuesCheckboxes = Omit<
  GeneralSetupFormValues,
  | 'EMPLOYEE_VERIFICATION_METHODS'
  | 'INVOICE_PAYMENT_ORIGINATOR'
  | 'FILE_DELIVERY_METHOD'
  | 'SFTP_ROOT_DIRECTORY'
>

export type GeneralSetupDescription = {
  [key in keyof GeneralSetupFormValuesCheckboxes]: CheckboxDescription
}

export const GeneralSetupDescriptions: GeneralSetupDescription = {
  ENABLE_BULK_ACCOUNT_CREATION: {
    label: 'Enable Customer Bulk Kyc',
    tooltip: 'When enabled, admins can create wallets from Create Accounts tab',
  },
  REQUIRE_FULL_DIRECT_DEPOSIT: {
    label: 'Require Full Direct Deposit',
    tooltip:
      'When enabled, Direct Deposit forms are fixed at 100% allocation to Branch',
  },
  DIRECT_DEPOSIT_WALLET_PULLBACK: {
    label: 'Direct Deposit Wallet Pullback',
    tooltip: 'When enabled, EWA advances are paid back from worker wallets',
  },
  DIRECT_DEPOSIT_PRECONFIGURED: {
    label: 'Direct Deposit Preconfigured',
    tooltip:
      'When enabled, workers are unable to submit direct deposit requests for this org (primarily for 1099 workers)',
  },
  ONBOARDING_INCLUDE_DD_SUBMISSION: {
    label: 'Allow Direct Deposit During Onboarding',
    tooltip:
      'When enabled, a prompt to setup direct deposit is displayed during worker onboarding',
  },
  ENABLE_MANUAL_DISBURSEMENTS: {
    label: 'Enable Manual Disbursements',
    tooltip:
      'When enabled, admins can send manual disbursements from the Disbursements tab',
  },
  ENABLE_REMITTANCE_ACH_PULL: {
    label: 'Enable Remittance Ach Pull',
    tooltip:
      "When enabled, EWA Remittances will be paid back via an organization's funding account",
  },
  REQUIRE_PHYSICAL_CARD_ORDERING: {
    label: 'Require Physical Card Ordering',
    tooltip:
      'When enabled, workers are required to order a physical card during onboarding',
  },
  ALLOW_MINOR_ACCOUNTS: {
    label: 'Allow Minor Accounts',
    tooltip:
      'When enabled, workers ages 14+ are allowed to create accounts (depending on state level requirements)',
  },
  ENABLE_CASH_OUT: {
    label: 'Enable Cashout',
    tooltip: 'When enabled, workers will see the "CashOut" tab in-app',
  },
  PERIOD_EARNINGS_FOR_EARNED_WAGES: {
    label: 'Enable If Using Gross Earnings For Ewa Instead Of Hours Worked',
    tooltip:
      'When enabled, EWA advances will be based off of Gross Earnings instead of Timekeeping.',
  },
  ENABLE_VARIABLE_PAY_PERIODS: {
    label: 'Enable Variable Pay Periods',
    tooltip:
      'When enabled, EWA has limited support for multiple pay periods. Review internally before enabling.',
  },
  REQUIRE_ROSTER_MATCH_BEFORE_KYC: {
    label: 'Require Roster Match Before Kyc',
    tooltip:
      "When enabled, workers must be verified in this organization before they're allowed to create a wallet",
  },
  ENABLE_EXPENSE_ACCOUNTS: {
    label: 'Enable Expense Accounts',
    tooltip:
      'When enabled, workers have the option to order Expense cards. Requires additional backend config to enable.',
  },
  CREATE_ABOUND_ACCOUNT: {
    label: 'Create Abound Account',
    tooltip:
      'When enabled, creates a 1099 form through Abound. Requires signed contract from organization before enabling.',
  },
  ENABLE_INSTANT_CARD_ORDERING: {
    label: 'Enable Instant Card Ordering',
    tooltip: 'When enabled, admins can place Instant Card orders on their own',
  },
  showInResults: {
    label: 'Company In Location Search',
    tooltip:
      'When enabled, workers can find this organization from the company search screen in-app',
  },
  SUPPORT_CHAT_ENABLED: {
    label: 'Enable In-app Support Chat',
    tooltip: 'When enabled, users can access the in-app support chat',
  },
  ENABLE_ENTERPRISE_REPORTING: {
    label: 'Enable Enterprise Reporting',
    tooltip:
      'When enabled, users must contact a CSM to process reports manually',
  },
  INSTANT_PAY_1099_ADVANCE_ORG: {
    label: '1099 Instant Pay',
    tooltip:
      'When enabled, all advances through this Organization will be for a 1099-contrator work. This will enable features like auto-confirmation through the remittance process.',
  },
  BUSINESS_INFO_ON_STATEMENT: {
    label: 'Business Info On Statement',
    tooltip:
      'When enabled, the business name will be displayed on statements and replace the worker name on disbursements.',
  },
  ENABLE_REM_FILE_AUTO_CONFIRM: {
    label: 'Enable Instant Pay Auto-Confirm',
    tooltip:
      'When enabled, all instant pay requests will be auto-confirmed and an invoice will be created.',
  },
}
