import { useHistory } from 'react-router'
import {
  useCreateRemittanceDeductions,
  useRemittanceBalances,
} from '@/queries/remittances'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { useDeductionReport } from '@/store/deduction-report'
import { useToasterStore } from '@/store/toast'
import {
  removeCachedRemittanceProgress,
  removeDraftFromLocalStorage,
} from '../lib/cache'
import { mapRemittanceAdjustments } from '../lib/mapRemittanceAdjustments'

export const usePostRemittance = (id: string) => {
  const history = useHistory()
  const balances = useRemittanceBalances(id)
  const createRemittanceDeductions = useCreateRemittanceDeductions()
  const { addToast } = useToasterStore()

  const adjustments = useDeductionReport(state => state.adjustments)
  const remittancePath = useOrgPath(ROUTES.REMITTANCE, {
    id,
  })

  const postRemittance = async () => {
    if (!balances.data) return
    const employeeAdjustments = mapRemittanceAdjustments(
      adjustments,
      balances.data.balances
    )

    createRemittanceDeductions.mutateAsync(
      {
        remittanceId: id,
        form: employeeAdjustments,
      },
      {
        onSuccess: () => {
          removeDraftFromLocalStorage()
          removeCachedRemittanceProgress(id)
          // refresh data
          history.push(remittancePath)
          addToast({
            type: 'success',
            title: `Remittance successfully completed`,
          })
        },
      }
    )
  }

  return {
    postRemittance,
    postRemittanceLoading: createRemittanceDeductions.isLoading,
    postRemittanceError: createRemittanceDeductions.error,
  }
}
