import { FC, useMemo } from 'react'
import { Card } from '@/common/card'
import { DetailHeading } from '@/common/detail'
import { useFundingSources } from '@/queries/funding-sources'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  payback: PaybackRecord
}

export const PaybackFundingSource: FC<Props> = ({ payback }) => {
  const { data } = useFundingSources()

  const fundingSource = useMemo(
    () => (data || []).find(rec => rec.id === payback.funding_source_id),
    [data, payback]
  )

  if (!fundingSource) return null

  return (
    <Card>
      <DetailHeading css={{ mb: '$8' }}>Funding Account</DetailHeading>
      <p className="mb-0" style={{ fontSize: '1rem' }}>
        {fundingSource.name}
      </p>
      <strong style={{ fontSize: '0.75rem' }}>
        Account: ••• {fundingSource.account_number_last_four}
      </strong>
    </Card>
  )
}
