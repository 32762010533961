import mixpanel from 'mixpanel-browser'
import { IUserDetails } from '@/types/auth'

type EventProperties = {
  [key: string]: string | number | boolean | null | number[]
}

type Domain =
  | 'PAGEVIEW'
  | 'remittances'
  | 'payroll'
  | 'workers'
  | 'accounts'
  | 'organization'
  | 'disbursements'
  | 'instant_cards'
  | 'invoices'
  | 'settings'
  | 'advances'
  | 'resources'
  | 'file_processing'
  | 'integration'
  | 'NPS'
  | 'user'
  | 'permissions'

export const sendAnalyticsEvent = (
  domain: Domain,
  event: string,
  properties?: EventProperties
) => {
  const eventName = `${domain}|${event}`
  if (process.env.REACT_APP_MIXPANEL_ACTIVATED === 'true') {
    mixpanel.track(eventName, properties)
  } else if (process.env.REACT_APP_MIXPANEL_DEBUGGING === 'true') {
    console.log('Tracking event: ', eventName, properties)
  }
}

type IdentifyUser = {
  user: IUserDetails
  orgs: string[]
}

export const identifyUser = ({
  user: { user_id, email, name, phone_number },
  orgs,
}: IdentifyUser) => {
  if (process.env.REACT_APP_MIXPANEL_ACTIVATED === 'true') {
    mixpanel.identify(user_id)
    mixpanel.people.set({
      $distinct_id: user_id,
      $email: email,
      $name: name,
      $phone: phone_number && `+1${phone_number}`,
      orgs,
    })
  }
}
