import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { invitePayAdmin } from '@/api/email'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useToasterStore } from '@/store/toast'

export const useInvitePayAdminEmail = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  return useMutation(
    async (email: string) => {
      const res = await invitePayAdmin(email, orgId)
      return res.data
    },
    {
      onSuccess: (_, email) => {
        addToast({
          type: 'success',
          title: 'Invite Successful',
          description: `Sent a Pay Admin invite to ${email}`,
        })
      },
      onError: (err: AxiosError) => {
        addToast({
          type: 'error',
          title: 'An error occurred',
          description: err.response?.data.message,
        })
      },
    }
  )
}
