import { FC } from 'react'
import { Badge, BadgeSize, BadgeTypes } from '@/common/badge'
import { STATUS_LABELS } from '../lib/utils'

interface Props {
  value: string | null
  badgeSize?: BadgeSize
}

export const UserStatusBadge: FC<Props> = ({ value, badgeSize }) => {
  let color: BadgeTypes = 'info'
  let label: string | undefined = ''
  switch (value) {
    case 'FAILED':
      color = 'danger'
      label = STATUS_LABELS.FAILED
      break
    case 'UNLINKED':
      color = 'info'
      label = STATUS_LABELS.UNLINKED
      break
    case 'LINKED':
      color = 'success'
      label = STATUS_LABELS.LINKED
      break
    case 'PENDING':
      color = 'info'
      label = STATUS_LABELS.PENDING
      break
    case 'PENDING_MANUAL':
      color = 'warning'
      label = STATUS_LABELS.PENDING_MANUAL
      break
    case 'PENDING_REVIEW':
      color = 'warning'
      label = STATUS_LABELS.PENDING_REVIEW
      break
    default:
      color = 'info'
      label = value?.toLowerCase()
      break
  }

  return (
    <Badge type={color} size={badgeSize}>
      {label}
    </Badge>
  )
}
