type Props = React.SVGAttributes<SVGSVGElement>

const SvgPayDocumentIcon = (props: Props) => (
  <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F5F5F5"
        d="M25.274 2.088A2.038 2.038 0 0023.236.05H2.096A2.039 2.039 0 00.059 2.088v30.185a7.626 7.626 0 007.626 7.625H32.9a7.625 7.625 0 01-7.625-7.625V2.088z"
      />
      <path
        fill="#E2E8EB"
        d="M9.606 12.227c0 .36-.125.664-.375.911-.25.248-.586.403-1.01.466v.426a.35.35 0 01-.094.253.331.331 0 01-.25.098.331.331 0 01-.25-.098.35.35 0 01-.094-.253v-.412a3.053 3.053 0 01-.75-.162 2.142 2.142 0 01-.628-.338.34.34 0 01-.121-.135.44.44 0 01-.034-.182c0-.095.028-.178.084-.25.057-.072.12-.108.193-.108.045 0 .086.008.125.024.038.015.089.041.152.077.207.135.41.234.61.297.201.063.412.095.632.095.302 0 .535-.059.699-.176a.584.584 0 00.246-.506.407.407 0 00-.135-.317.983.983 0 00-.337-.193 7.55 7.55 0 00-.567-.165 6.147 6.147 0 01-.824-.26 1.429 1.429 0 01-.547-.402c-.153-.18-.23-.423-.23-.73 0-.364.13-.674.392-.931.261-.257.603-.416 1.026-.48v-.425a.35.35 0 01.095-.253.331.331 0 01.25-.098c.103 0 .186.033.25.098a.35.35 0 01.094.253v.419a2.26 2.26 0 011.175.493.379.379 0 01.162.317.394.394 0 01-.084.25c-.057.072-.123.108-.2.108a.353.353 0 01-.118-.02.795.795 0 01-.152-.081 2.553 2.553 0 00-.523-.294 1.579 1.579 0 00-.584-.098c-.28 0-.502.062-.669.186a.6.6 0 00-.25.51c0 .148.048.268.142.36a.941.941 0 00.358.213c.144.05.34.106.588.17.328.08.596.164.803.249.207.086.384.215.53.388.147.174.22.409.22.706z"
      />
      <path
        fill="#6CD27D"
        d="M16.45 34.79c-.51 0-.976.293-1.195.753a7.616 7.616 0 01-6.88 4.354H33.59a7.595 7.595 0 006.002-2.93c.69-.885.082-2.177-1.04-2.177H16.45z"
      />
      <path
        fill="#5DBB6B"
        d="M25.709 34.79h-9.26c-.51 0-.975.293-1.194.753l-.004.008a7.632 7.632 0 01-1.149 1.744l-.032.036a7.566 7.566 0 01-.71.703l-.027.025c-.257.22-.527.422-.811.607-.03.02-.061.036-.091.055a7.556 7.556 0 01-.767.423l-.166.08c-.296.133-.599.25-.912.345-.039.012-.078.019-.115.03a7.782 7.782 0 01-.842.187c-.084.013-.17.028-.255.038-.328.043-.66.073-1 .073H32.9c-3.329 0-6.15-2.135-7.191-5.107"
      />
      <path
        fill="#E2E8EB"
        d="M5.572 17.806a.81.81 0 00.8.805h13.293a.801.801 0 00.801-.805.81.81 0 00-.8-.806H6.372c-.442 0-.8.358-.8.806zm6-9c0 .445.36.805.805.805h7.284a.805.805 0 100-1.611h-7.284a.803.803 0 00-.805.806zm-6 13.541a.81.81 0 00.8.805h13.293a.801.801 0 00.801-.805.81.81 0 00-.8-.806H6.372c-.442 0-.8.358-.8.806zm6-9c0 .445.36.805.805.805h7.284a.805.805 0 100-1.611h-7.284a.803.803 0 00-.805.806zm-6 13.541c0 .445.36.806.814.806h9.946a.806.806 0 000-1.611H6.386a.808.808 0 00-.814.805z"
      />
    </g>
  </svg>
)

export default SvgPayDocumentIcon
