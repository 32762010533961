import Papa from 'papaparse'
import { FC, useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { BulkUploadConfirmation } from '@/common/bulk-upload/BulkUploadConfirmation'
import { BulkUploadReview } from '@/common/bulk-upload/BulkUploadReview'
import { STEP_IDS, STEPS } from '@/common/bulk-upload/bulkUploadSteps'
import { Status } from '@/common/bulk-upload/types'
import { Loader } from '@/common/loader'
import { StepGroup, Transition } from '@/components/stepped-modal'
import useParsedCsv from '@/hooks/useParsedCsv'
import useSteps from '@/hooks/useSteps'
import { FileValidationError } from '@/types/common'
import { BulkUploadValidate } from './BulkUploadValidate'
import { errorColumns } from './columns'

export type TableData = { [key: string]: any }
export type TableColumn = {
  header: string
  accessorKey: string
}
interface Props {
  files: File[]
  open: boolean
  status: Status
  error: any
  errorData: FileValidationError[]
  isLoading: boolean
  isValidating: boolean
  columnHeaders?: string[]
  onConfirm: () => void
  onClose: () => void
  setData: (data: string[][]) => void
}

export const BulkUploadStepsModal: FC<Props> = ({
  files,
  open,
  children,
  status,
  error,
  errorData,
  isValidating,
  isLoading,
  columnHeaders = [],
  onConfirm,
  onClose,
  setData,
}) => {
  const parsed = useParsedCsv(files)
  const steps = useSteps(STEPS)
  const [columns, setColumns] = useState<TableColumn[]>([])
  const [tableData, setTableData] = useState<TableData[]>([])

  const onGoBack = () => {
    steps.goToPreviousStep()
  }

  const onSubmit = () => {
    steps.goToNextStep()
  }

  const makeColumns = (rawColumns?: string[]): TableColumn[] =>
    (rawColumns || []).map(column => ({ header: column, accessorKey: column }))
  useEffect(() => {
    Papa.parse<string[]>(files[0], {
      skipEmptyLines: 'greedy',
      complete: results => {
        const headers = results.data[0]?.map(
          (header, index) => header || columnHeaders[index] || `Misc ${index}`
        )
        const data = results.data.slice(1)
        const formedData: TableData[] = []
        data.forEach(info => {
          const obj: TableData = {}
          headers.forEach((header, k) => {
            obj[header] = info[k]
          })
          formedData.push(obj)
        })
        setData(data)
        setTableData(formedData)
        setColumns(makeColumns(headers))
      },
    })
  }, [files, setData, columnHeaders])

  return (
    <Modal isOpen={open} toggle={onClose} centered className="jumbo light-bg">
      <Loader loading={parsed.loading}>
        {!parsed.loading && parsed.csv.length > 1 && (
          <StepGroup>
            <Transition in={steps.currentStep.id === STEP_IDS.VALIDATE}>
              <BulkUploadValidate
                error={error}
                data={errorData}
                columns={errorColumns}
                isValidating={isValidating}
                handleClose={onClose}
                handleSubmit={onSubmit}
              />
            </Transition>
            <Transition in={steps.currentStep.id === STEP_IDS.REVIEW}>
              <BulkUploadReview
                data={tableData}
                columns={columns}
                handleClose={onClose}
                handleSubmit={onSubmit}
              />
            </Transition>
            <Transition in={steps.currentStep.id === STEP_IDS.CONFIRM}>
              <BulkUploadConfirmation
                handleClose={onClose}
                handleConfirm={onConfirm}
                status={status}
                error={error}
                isLoading={isLoading}
                handleGoback={onGoBack}
              >
                {children}
              </BulkUploadConfirmation>
            </Transition>
          </StepGroup>
        )}
      </Loader>
    </Modal>
  )
}
