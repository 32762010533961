import { Card, CardBody, CardText, CardTitle } from 'reactstrap'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import ContentEmpty from '@/common/empty'
import { EmptyPieChartIcon } from '@/common/icons'

interface IProps {
  data: {
    name: string
    value: number
  }[]
}

const COLORS = [
  '#549BEF',
  '#67AF61',
  '#F0BC40',
  '#DD3224',
  '#953FEA',
  '#ED743C',
]

const renderTooltip = (props: any) => {
  return props.payload ? (
    <Card>
      <CardBody>
        <CardTitle tag="b">
          ${props.payload.length > 0 && props.payload[0].name}
        </CardTitle>
        <CardText>
          {props.payload.length > 0 && props.payload[0].value} advances
        </CardText>
      </CardBody>
    </Card>
  ) : null
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = (labelData: any) => {
  const { cx, cy, midAngle, fill, outerRadius, payload } = labelData

  const radius = outerRadius + 15
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontWeight="bold"
    >
      {`$${payload.name}`}
    </text>
  )
}

const AdvanceDistribution = (props: IProps) => {
  const totalValues = props.data.reduce((count, curr) => {
    return count + curr.value
  }, 0)

  return (
    <>
      <h5>Advance Distribution</h5>
      {totalValues === 0 ? (
        <ContentEmpty
          icon={<EmptyPieChartIcon />}
          title="No data available"
          height="sm"
        />
      ) : (
        <ResponsiveContainer minWidth="100%" height={400}>
          <PieChart>
            <Pie
              dataKey="value"
              data={props.data}
              label={renderCustomizedLabel}
              labelLine={false}
              innerRadius="50%"
              paddingAngle={2}
              fill="#82ca9d"
            >
              {props.data.map((_, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={renderTooltip} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default AdvanceDistribution
