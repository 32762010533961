import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Text } from '@/common/text'
import { Tooltip } from '@/common/tooltip'
import { toDollar } from '@/lib/currency'
import { RemittanceAdvances } from '@/types/remittances'
import { ViewDetailsCell } from '../components/ViewDetailsCell'

export const columns: ColumnDef<RemittanceAdvances, any>[] = [
  {
    header: 'Date',
    id: 'payout_date',
    accessorFn: row => moment(row.payout_date).format('MM/DD/YYYY'),
  },
  {
    header: () => (
      <Tooltip trigger={<Text>Advance Amount</Text>}>
        The amount of the advance taken
      </Tooltip>
    ),
    id: 'advance_amount',
    accessorFn: row => toDollar(row.remittance_snapshot.advance_amount),
  },
  {
    header: () => (
      <Tooltip trigger={<Text>Prev. Deducted</Text>}>
        The amount deducted prior to this remittance
      </Tooltip>
    ),
    id: 'prev_deducted',
    accessorFn: row => toDollar(row.remittance_snapshot.prev_deducted),
  },
  {
    header: () => (
      <Tooltip trigger={<Text>Requested Amount</Text>}>
        The amount requested to be deducted this remittance
      </Tooltip>
    ),
    id: 'requested_amount',
    accessorFn: row => toDollar(row.remittance_snapshot.requested_amount),
  },
  {
    header: () => (
      <Tooltip trigger={<Text>Deducted Amount</Text>}>
        The amount successfully deducted for this remittance
      </Tooltip>
    ),
    id: 'deducted_amount',
    accessorFn: row => toDollar(row.remittance_snapshot.deducted_amount),
  },
  {
    header: 'Details',
    id: 'details',
    cell: ({ row }) => <ViewDetailsCell original={row.original} />,
  },
]
