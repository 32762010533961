import classnames from 'classnames'
import { CloseIcon } from '@/common/icons'

type Props = {
  label: string
  value?: string
  onClick?: () => void
  removeFilter: () => void
  formatValue?: (value?: string) => string
}

export const FilterPill: React.FC<Props> = props => {
  const { value, label, removeFilter, onClick, formatValue } = props

  const classes = classnames('filter-pill')

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    removeFilter()
  }

  return (
    <div className={classes} onClick={onClick}>
      <div className="filter-pill-label">
        {label || 'Filter'}:{' '}
        <strong>{formatValue ? formatValue(value) : value}</strong>
      </div>
      <div className="filter-pill-close" onClick={handleCloseClick}>
        <CloseIcon width="1rem" />
      </div>
    </div>
  )
}
