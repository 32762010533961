import { Card } from '@/common/card'
import { Box } from '@/common/layout'
import { Text } from '@/common/text'
import {
  useWebhookDefinitions,
  useWebhookEventsQuery,
} from '@/queries/webhooks'
import { WebhookDefinition } from './WebhookDefinition'

export const WebhookDefinitions = () => {
  const { data: events } = useWebhookEventsQuery()
  const { data } = useWebhookDefinitions()

  if (!data) {
    return null
  }

  return (
    <Box css={{ my: '$24' }}>
      <h5>Active Webhooks</h5>
      {data.map(rec => (
        <WebhookDefinition data={rec} events={events} key={rec.id} />
      ))}
      {data.length === 0 && (
        <Card>
          <Text>
            <em>No active webhooks.</em>
          </Text>
        </Card>
      )}
    </Box>
  )
}
