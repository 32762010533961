import { FC, useMemo } from 'react'
import { Flex } from '@/common/layout'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { ROUTES } from '@/router/routes'
import { usePermissionsStore } from '@/store/permissions'
import { SETTINGS_NAV } from './menu'
import { SettingsNavItem } from './SettingsNavItem'

export const SettingsNavMenu: FC = () => {
  const { partner_id } = useCurrentOrg()
  const { hasPermission, isEnabledOrg } = usePermissionsStore(
    state => state.computed
  )
  const settingsNav = useMemo(() => {
    return SETTINGS_NAV({
      isStaging:
        process.env.REACT_APP_IS_SANDBOX === 'true' ||
        process.env.NODE_ENV === 'development',
      hasPartnerOrg: !!partner_id,
    }).map(navItem => {
      if (navItem.route === ROUTES.ROLES_PERMISSIONS_SETTINGS) {
        return {
          ...navItem,
          isHidden: isEnabledOrg
            ? !hasPermission('permission.management')
            : !isEnabledOrg,
        }
      }
      return navItem
    })
  }, [partner_id, isEnabledOrg, hasPermission])

  return (
    <Flex direction="column" justify="normal" align="normal">
      {settingsNav
        .filter(navItem => !navItem.isHidden)
        .map(navItem => (
          <SettingsNavItem key={navItem.route} item={navItem} />
        ))}
    </Flex>
  )
}
