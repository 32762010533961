import { styled } from '@/styles/stitches.config'

export const IconContainer = styled('div', {
  height: '$24',
  width: '$24',
  mr: '$16',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
})

export const AdminIconContainer = styled('div', {
  position: 'absolute',
  bottom: '-10px',
  right: '-2px',
})

export const NavHeader = styled('div', {
  padding: '$24 $16',
  borderBottom: '1px solid $secondary800',
})

export const PageSettingsNav = styled('div', {
  backgroundColor: 'white',
  width: '360px',
  minHeight: ' 100vh',
  borderRight: '1px solid $secondary800',
})

export const NavItem = styled('a', {
  padding: '$16',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: '1px solid $secondary800',
  fontFamily: '$font-family-monospace',
  fontWeight: 'bold',
  color: '$secondary100',
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '$secondary800',
    textDecoration: 'none',
  },
  variants: {
    active: {
      true: { backgroundColor: '$secondary800' },
    },
  },
})
