import { IGetPayPeriodsResponse } from '@/api/types/payroll'
import http from './http'

/**
 * Retrieves an organization's payroll period data
 */
export const getPayPeriods = (orgId: number) =>
  http.get<IGetPayPeriodsResponse>(
    `p1/payroll_periods?organization_id=${orgId}`
  )

/**
 *
 * @param deadline deadline date in `2019-10-15` format
 * @param payDate pay date in `2019-10-18` format
 */
export const addPayPeriod = (
  deadline: string,
  payDate: string,
  orgId: number
) =>
  http.post<IGetPayPeriodsResponse>(
    `p1/payroll_periods?organization_id=${orgId}`,
    {
      payroll_periods: [{ payroll_deadline: deadline, pay_date: payDate }],
    }
  )

/**
 *
 * @param id payroll period ID
 * @param deadline deadline date in `2019-10-15` format
 * @param payDate pay date in `2019-10-18` format
 */
export const updatePayrollPeriod = (
  id: number,
  deadline: string,
  payDate: string,
  orgId: number
) =>
  http.put<IGetPayPeriodsResponse>(`p1/payroll_periods/${id}`, {
    payroll_period: {
      payroll_deadline: deadline,
      pay_date: payDate,
      organization_id: orgId,
    },
  })

/**
 * Deletes a payroll period
 */
export const deletePayrollPeriod = (id: number) =>
  http.delete(`p1/payroll_periods/${id}`)

/**
 * A customer success service that allows us to upload new payroll data for the user
 */
export const uploadPayroll = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return http.post(`p1/payroll_calendar`, formData)
}
