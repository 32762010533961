import { FC, useState } from 'react'
import { IconButton } from '@/common/button'
import { Card } from '@/common/card'
import { ChevronDownIcon } from '@/common/icons'
import { Box, Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { SubAccount } from '@/types/sub-account'
import { SubAccountDetails } from './SubAccountDetails'

interface Props {
  subAccount: SubAccount
}

export const SubAccountCard: FC<Props> = ({ subAccount }) => {
  const [open, setOpen] = useState(false)

  return (
    <Card>
      <Flex align="center">
        <div>
          <Text bold size="md">
            Account: ••• {subAccount.account_number.slice(-4)}
          </Text>
        </div>
        <Box css={{ ml: 'auto' }}>
          <IconButton tooltip="View Acct/Rtg" onClick={() => setOpen(!open)}>
            <ChevronDownIcon />
          </IconButton>
        </Box>
      </Flex>
      {open && <SubAccountDetails subAccount={subAccount} />}
    </Card>
  )
}
