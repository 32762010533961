import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useEnrollmentRequestedStore } from '@/store/enrollment-requested'
import { UserEnrollmentRequest } from '@/types/employees'
import { downloadEnrollmentRequests } from '../lib/download-enrollment-requests'
import { additionalFilters } from '../lib/static-filters'

interface Props {
  filteredData: UserEnrollmentRequest[]
}

export const EnrollmentRequestedFilters: FC<Props> = ({ filteredData }) => {
  const { filters, updateFilters } = useEnrollmentRequestedStore()
  const handleDownload = () => downloadEnrollmentRequests(filteredData)

  return (
    <TableFiltersMain
      filters={filters}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
      handleDownload={handleDownload}
      hasDateRange={false}
    />
  )
}
