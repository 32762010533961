import { ColumnOrderState } from '@tanstack/react-table'
import { FC, useState } from 'react'
import { Button } from '@/common/button'
import { Box, Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { SimpleTable } from '@/common/table/SimpleTable'
import { columns } from '@/components/payouts/lib/columns'
import { useModalStore } from '@/store/modal'
import { sampleDisbursementData } from '../lib/sample-data'

interface Props {
  hiddenColumns?: { [key: string]: boolean }
  columnOrder?: ColumnOrderState
}

export const TablePreview: FC<Props> = ({ hiddenColumns, columnOrder }) => {
  const [open, setOpen] = useState(true)
  const { removeModal } = useModalStore()
  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog open={open} setOpen={close} title="Table Preview" size="unbound">
      <Box css={{ pointerEvents: 'none' }}>
        <SimpleTable
          data={sampleDisbursementData}
          columns={columns}
          columnOrderState={columnOrder}
          hiddenColumns={hiddenColumns}
          hideLastColumnBorder
        />
      </Box>
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button mode="flat" onClick={close}>
          Close
        </Button>
      </Flex>
    </Dialog>
  )
}
