import { TriangleDownIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { Button } from '@/common/button'
import { Box } from '@/common/layout'
import { Popover } from '@/common/popover'
import { useCurrentOrg } from '../auth/hooks/useCurrentOrg'
import { OrganizationLogo } from '../layout/account/OrganizationLogo'
import { OrganizationNavigator } from './OrganizationNavigator'

export const OrganizationMenu: FC = () => {
  const currentOrg = useCurrentOrg()
  const [popoverOpen, setPopoverOpen] = useState(false)
  return (
    <Box css={{ ml: 'auto' }}>
      <Popover
        trigger={
          <Button
            mode="flat"
            css={{
              color: 'white',
              backgroundColor: 'transparent',
              '&:hover': {
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.1)',
              },
            }}
            iconRight
          >
            {currentOrg.name}
            <OrganizationLogo />
            <TriangleDownIcon />
          </Button>
        }
        open={popoverOpen}
        setOpen={setPopoverOpen}
        showClose
      >
        <OrganizationNavigator setPopoverOpen={setPopoverOpen} />
      </Popover>
    </Box>
  )
}
