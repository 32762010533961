import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useDeleteProcessorMutation } from '@/src/queries/file-processing'
import { useModalStore } from '@/store/modal'
import { IProcessorRow } from '../lib/subcolumns'

interface Props {
  original: IProcessorRow
}

export const ProcessorDropdown: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()
  const deleteMutation = useDeleteProcessorMutation()

  const openRemoveModal = () => {
    registerModal(
      <AlertDialog
        title="Delete registration"
        actionDescription="Delete"
        isDestructive={true}
        handleAction={() =>
          deleteMutation.mutateAsync({
            id: original.id,
            registrationId: original.registrationId,
            registrationType: original.registrationType,
          })
        }
        description="Are you sure you want to delete this registration?"
      />
    )
  }

  return (
    <TableActionDropdown
      menu={[{ label: 'Delete', handleClick: openRemoveModal }]}
    />
  )
}
