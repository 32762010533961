import { Button } from '@/common/button'
import { CheckmarkIcon } from '@/common/icons'
import styles from './index.module.scss'
import { IListItem } from './lib/types'

interface Props {
  entry: IListItem
  selected?: boolean
}

export const StoreListItemNewEntry: React.FC<Props> = ({ entry, selected }) => (
  <>
    <span className={styles.ListItem}>
      <p className={styles.ListItemName}>{entry.id}</p>
    </span>
    {selected && <CheckmarkIcon />}
    {!selected && (
      <Button mode="outline" size="sm">
        Use New
      </Button>
    )}
  </>
)
