import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { SSOConfig } from '@/api/types/sso'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Checkbox } from '@/common/checkbox'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { FlexGrid, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useCreateSSOConfig, useUpdateSSOConfig } from '@/queries/sso'

interface Props {
  data?: SSOConfig
}

const resetForm = (data?: SSOConfig) => ({
  idp_sso_url: data?.idp_sso_url || '',
  input1_label: data?.input1_label,
  input2_label: data?.input2_label,
  show_ewa_copy: !!data ? data.show_ewa_copy : false,
  sign_up_tip_copy: data?.sign_up_tip_copy,
  hosted_login: !!data ? data.hosted_login : true,
})

export const SSOForm: FC<Props> = ({ data }) => {
  const createSSOConfig = useCreateSSOConfig()
  const updateSSOConfig = useUpdateSSOConfig()
  const { org_key, id } = useCurrentOrg()
  const { register, handleSubmit, reset, watch, setValue } = useForm<SSOConfig>(
    {
      defaultValues: resetForm(data),
    }
  )

  const isHostedLogin = watch('hosted_login')
  const showEwaCopy = watch('show_ewa_copy')

  useEffect(() => {
    reset(resetForm(data))
  }, [data, reset])

  useEffect(() => {
    const route = isHostedLogin
      ? `organization/${org_key}/`
      : `${org_key}/saml/consume`

    setValue('idp_sso_url', `${process.env.REACT_APP_SSO_URL}/${route}`)
  }, [isHostedLogin, org_key, setValue])

  const onSubmit = (values: SSOConfig) => {
    if (!data) {
      const payload = { ...values, organization_ids: [id] }
      createSSOConfig.mutate(payload)
      return
    }

    updateSSOConfig.mutate(values)
  }

  return (
    <Card style={{ maxWidth: '800px' }}>
      <Text as="h5">{!!data ? 'Edit' : 'Create'} Configuration</Text>
      <br />
      <FlexGrid direction="column" align="start" size="lg" fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Checkbox
              id="hosted_login"
              label="Hosted Login"
              checked={isHostedLogin}
              onChange={(_, value) => setValue('hosted_login', value)}
            />
          </div>
          <div>
            <Label htmlFor="idp_sso_url">Identity Provider URL</Label>
            <Input {...register('idp_sso_url')} disabled />
            <Text color="hint">
              {isHostedLogin
                ? 'Hosted login requires a Configurable Roster File Processor.'
                : 'Contact engineering to have this route configured.'}
            </Text>
          </div>
          <div>
            <Label htmlFor="input1_label">Username label</Label>
            <Input {...register('input1_label')} />
          </div>
          <div>
            <Label htmlFor="input2_label">Password label</Label>
            <Input {...register('input2_label')} />
          </div>
          <div>
            <Label htmlFor="sign_up_tip_copy">Signup Tip</Label>
            <Input {...register('sign_up_tip_copy')} />
          </div>
          <div>
            <Checkbox
              id="show_ewa_copy"
              label="Show EWA copy"
              checked={showEwaCopy}
              onChange={(_, value) => setValue('show_ewa_copy', value)}
            />
          </div>
          <FlexWrap justify="end">
            <Button
              mode="filled"
              type="submit"
              loading={createSSOConfig.isLoading || updateSSOConfig.isLoading}
            >
              Save
            </Button>
          </FlexWrap>
        </form>
      </FlexGrid>
    </Card>
  )
}
