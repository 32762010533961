import { ColumnDef } from '@tanstack/react-table'
import { Flex } from '@/common/layout'
import { Admin } from '@/types/organization'
import { ActionCell } from '../components/ActionCell'

export const userColumns: ColumnDef<Admin, any>[] = [
  {
    header: 'ID',
    id: 'id',
    accessorKey: 'id',
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: row => `${row.first_name} ${row.last_name}`,
  },
  {
    header: '',
    id: 'actions',
    size: 25,
    cell: ({ row }) => (
      <Flex justify="center">
        <ActionCell original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]
