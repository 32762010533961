import { useMemo } from 'react'
import { IAdvancesInsightsData } from '@/lib/advancesInsights'

const useAggregatedRange = (report: IAdvancesInsightsData[]) => {
  return useMemo(() => {
    const aggregatedRange: { name: string; value: number }[] = []

    const map: { [key: string]: number } = {}

    report.forEach(data => {
      const { advanceRanges } = data
      advanceRanges.forEach(rangeObj => {
        const { amount } = rangeObj
        const min = rangeObj.min ? rangeObj.min.value : undefined
        const max = rangeObj.max ? rangeObj.max.value : undefined
        let name

        // range array can either have 2 values i.e. [start, end] or
        // one value [start] for the last case where we only know the lower bound
        // in order to handle both cases, we check the length label
        if (typeof max === 'number') {
          name = `${min} - ${max}`
        } else {
          name = `${min} +`
        }

        if (map[name]) {
          map[name] = map[name] + amount
        } else {
          map[name] = amount
        }
      })
    })

    for (const key in map) {
      aggregatedRange.push({
        name: key,
        value: map[key],
      })
    }

    return aggregatedRange
  }, [report])
}

export default useAggregatedRange
