import { FC, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Tabs } from '@/components/layout/tabs'
import TabsItem from '@/components/layout/tabs/TabsItem'
import { AddLocationForm } from './AddLocationForm'
import { IReviewRow } from './lib/types'
import { UpdateLocationForm } from './UpdateLocationForm'

interface Props {
  reviewRow: IReviewRow
  secondaryNs: string
}

const LocationReview: FC<Props> = ({ reviewRow, secondaryNs }) => {
  const [shouldUpdate, setShouldUpdate] = useState(true)

  if (!reviewRow.existingLocation || !reviewRow.duplicateId) {
    return null
  }

  return (
    <Card className="my-2 shadow-sm">
      <CardBody>
        <Tabs className="mb-4">
          <TabsItem
            isActive={shouldUpdate}
            onClick={() => setShouldUpdate(true)}
          >
            Update Existing
          </TabsItem>
          <TabsItem
            isActive={!shouldUpdate}
            onClick={() => setShouldUpdate(false)}
          >
            Create New
          </TabsItem>
        </Tabs>
        {shouldUpdate ? (
          <UpdateLocationForm reviewRow={reviewRow} secondaryNs={secondaryNs} />
        ) : (
          <AddLocationForm
            name={reviewRow.name}
            alternate={reviewRow.alternate}
            address={reviewRow.address}
            city={reviewRow.city}
            state={reviewRow.state}
            postalCode={reviewRow.postalCode}
            country={reviewRow.country}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default LocationReview
