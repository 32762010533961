import classnames from 'classnames'
import { FC } from 'react'
import styles from '@/src/styles/tabs.module.scss'

type Props = {
  className?: string
}

export const Tabs: FC<Props> = props => {
  const classes = classnames(props.className, styles.TabsWrapper)
  return <div className={classes}>{props.children}</div>
}
