import { ColumnDef } from '@tanstack/react-table'
import { Checkbox } from '@/common/checkbox'
import { Flex } from '@/common/layout'
import { OrgAdmin, OrganizationRole, OrgGroup } from '@/types/permissions'
import { ActionCell } from '../components/ActionCell'
import { AdminActionCell } from '../components/AdminActionCell'
import { RoleCountCell } from '../components/RoleCountCell'

export const columns: ColumnDef<OrganizationRole, any>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: row => `${row.name}`,
  },
  {
    header: 'Description',
    id: 'description',
    accessorFn: row => `${row.description}`,
  },
  {
    header: '',
    id: 'actions',
    size: 25,
    cell: ({ row }) => (
      <Flex justify="center">
        <ActionCell original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]

export const adminColumns: ColumnDef<OrgAdmin, any>[] = [
  {
    header: 'ID',
    id: 'id',
    accessorKey: 'id',
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: row => `${row.first_name} ${row.last_name}`,
  },
  {
    header: 'Email',
    id: 'email',
    accessorFn: row => row.email,
  },
  {
    header: 'Roles',
    id: 'roles',
    cell: ({ row }) => <RoleCountCell original={row.original} />,
    enableHiding: true,
  },
  {
    header: 'Location Access',
    id: 'organization_groups',
    cell: ({ row }) =>
      row.original.organization_groups.length === 0
        ? 'All'
        : row.original.organization_groups.length,
  },
  {
    header: '',
    id: 'actions',
    size: 25,
    cell: ({ row }) => (
      <Flex justify="center">
        <AdminActionCell original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]

export const LocationAccessColumn: ColumnDef<OrgGroup, any>[] = [
  {
    header: 'Location Name',
    id: 'location_name',
    accessorFn: row => row.name,
  },
  {
    id: 'select',
    header: ({ table }) => (
      <Flex css={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Checkbox
          id={'select'}
          nativeClickFn={table.getToggleAllRowsSelectedHandler()}
          checked={table.getIsAllRowsSelected()}
        />
      </Flex>
    ),
    cell: ({ row }) => (
      <Flex justify="center" onClick={e => e.stopPropagation()}>
        <Checkbox
          checked={row.getIsSelected()}
          id={row.original.id.toString()}
          onChange={row.getToggleSelectedHandler()}
        />
      </Flex>
    ),
  },
]
