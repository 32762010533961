import { useEffect, useState } from 'react'
import { matchPath, useHistory, useLocation, useParams } from 'react-router'
import { OMITTED_ORG_URLS } from '@/router/routes'
import {
  cacheCurrentOrganization,
  getCachedCurrentOrganization,
} from '@/src/sessionstorage'
import { usePermissionsStore } from '@/store/permissions'
import { useToasterStore } from '@/store/toast'
import { useUser } from '@/store/user'

// If the url isn't prefixed with a specific organization
// we resolve to the first one in the user's org list
const hasOmittedOrgUrl = (pathname: string) => {
  return !!OMITTED_ORG_URLS.find(
    path =>
      !!matchPath(pathname, {
        path,
        exact: true,
      })
  )
}

export const useCurrentOrgInitializer = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { orgKey } = useParams<{ orgKey: string }>()
  const { addToast } = useToasterStore()
  const { organizations, setCurrentOrganization } = useUser()
  const { setOrgId } = usePermissionsStore()

  useEffect(() => {
    if (organizations.length === 0) {
      return
    }

    let currentOrg = organizations[0]
    const cachedCurrent = getCachedCurrentOrganization()
    if (cachedCurrent) {
      const matchedCurrentOrg = organizations.find(
        org => org.id === cachedCurrent.id
      )
      if (matchedCurrentOrg) {
        currentOrg = matchedCurrentOrg
      }
    }

    if (currentOrg.org_key !== orgKey) {
      const urlSpecifiedOrganization = organizations.find(
        o => o.org_key === orgKey
      )
      if (urlSpecifiedOrganization) {
        currentOrg = urlSpecifiedOrganization
        if (organizations.length > 1) {
          addToast({
            type: 'success',
            title: `Now viewing ${currentOrg.name}`,
          })
        }
      }
    }
    cacheCurrentOrganization(currentOrg)
    setCurrentOrganization(currentOrg)
    setOrgId(currentOrg.id)
    setIsInitialized(true)

    let redirectUrl = ''

    const orgNotSpecifiedInUrl = hasOmittedOrgUrl(location.pathname)
    if (orgNotSpecifiedInUrl) {
      redirectUrl = `/${currentOrg.org_key}${
        location.pathname
      }${location.search || ''}`
      return history.replace(redirectUrl)
    }

    redirectUrl = `${location.pathname.replace(
      orgKey,
      currentOrg.org_key
    )}${location.search || ''}`

    return history.replace(redirectUrl)
  }, [
    setCurrentOrganization,
    history,
    organizations,
    location.pathname,
    location.search,
    orgKey,
    setOrgId,
    addToast,
  ])
  return { isInitialized }
}
