import { FC, useMemo } from 'react'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Box, Flex } from '@/common/layout'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import { Text } from '@/common/text'
import { usePdqStoresQuery } from '@/queries/pdq'
import { useModalStore } from '@/store/modal'
import { columns } from './columns'
import { PdqRegistration } from './PdqRegistration'
import { RunJobModal } from './RunJobModal'

export const PdqStores: FC = () => {
  const { data, isLoading, isError } = usePdqStoresQuery()
  const { registerModal } = useModalStore()

  const pdqData = useMemo(() => data?.data || [], [data])

  if (isLoading) {
    return <Loader loading={true} />
  }

  if (isError) {
    return <PdqRegistration create={false} />
  }

  const openRunJobModal = () => {
    registerModal(<RunJobModal />)
  }

  return (
    <Card>
      <Flex justify="between" css={{ mb: '$24' }}>
        <Text as="h5">Store Registrations</Text>
        <Button mode="filled" onClick={openRunJobModal}>
          Run Jobs
        </Button>
      </Flex>
      <Box css={{ height: 700 }}>
        <SimpleTable data={pdqData} columns={columns} />
      </Box>
    </Card>
  )
}
