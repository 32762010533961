import * as React from 'react'

const RetryIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg width={25} height={24} viewBox="0 0 25 24" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#349DF6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path d="M8 13.499v3H5M7.808 16.497A6.48 6.48 0 1110 18" />
    </g>
  </svg>
)

export default RetryIcon
