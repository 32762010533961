import { FC } from 'react'
import { Box, Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { Tooltip } from '@/common/tooltip'
import { OrgAdmin } from '@/types/permissions'

interface Props {
  original: OrgAdmin
}

export const RoleCountCell: FC<Props> = ({ original }) => (
  <>
    <Box>
      <Flex>
        {original.roles.map((role, index) => (
          <Tooltip
            key={role.id}
            trigger={
              <Text
                css={{
                  background: '$secondary800',
                  padding: '$4',
                  mr: '$4',
                  br: '4px',
                  cursor: 'pointer',
                }}
              >
                {role.name}
              </Text>
            }
          >
            {role.description}
          </Tooltip>
        ))}
      </Flex>
    </Box>
  </>
)
