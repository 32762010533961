type Props = {
  name: string
}

export const InstantCardExample: React.FC<Props> = ({ name }) => (
  <div className="position-relative">
    <img
      src="/images/instantcard.png"
      alt="Instant Card Example"
      style={{ width: '315px' }}
    />
    <span
      style={{
        position: 'absolute',
        top: '137px',
        left: '63px',
        color: 'white',
        fontSize: '11px',
        fontWeight: 'bold',
      }}
    >
      {name}
    </span>
  </div>
)
