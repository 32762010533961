import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { YearMonthDayRegex } from '@/lib/regex'
import { isEmptyObject } from '@/lib/validators'

export interface PdqStoreRegistrationFormValues {
  rosterEffective: string
  timekeepingEffective: string
  disbursementEffective: string
}

interface Props {
  initialValues?: PdqStoreRegistrationFormValues | null
  handleFormSubmit: (values: PdqStoreRegistrationFormValues) => void
  isLoading: boolean
}

const schema = yup.object().shape({
  rosterEffective: yup
    .string()
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
  timekeepingEffective: yup
    .string()
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
  disbursementEffective: yup.string().when({
    is: (val: string) => !!val,
    then: yup
      .string()
      .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
    otherwise: yup.string(),
  }),
})

export const StoreForm: FC<Props> = ({
  initialValues,
  handleFormSubmit,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PdqStoreRegistrationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || {
      rosterEffective: '',
      timekeepingEffective: '',
      disbursementEffective: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FlexGrid direction="column" fullWidth size="lg">
        <Flex direction="column" align="start">
          <Label htmlFor="rosterEffective">Roster Effective Date</Label>
          <Input
            type="text"
            {...register('rosterEffective')}
            css={{ mt: '$4' }}
          />
          <Text color="alert" size="sm">
            {errors?.rosterEffective?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start">
          <Label htmlFor="timekeepingEffective">
            Timekeeping Effective Date
          </Label>
          <Input
            type="text"
            {...register('timekeepingEffective')}
            css={{ mt: '$4' }}
          />
          <Text color="alert" size="sm">
            {errors?.timekeepingEffective?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start">
          <Label htmlFor="disbursementEffective">
            Disbursement Effective Date
          </Label>
          <Input
            type="text"
            {...register('disbursementEffective')}
            css={{ mt: '$4' }}
          />
          <Text color="alert" size="sm">
            {errors?.disbursementEffective?.message}
          </Text>
        </Flex>
      </FlexGrid>
      <Flex css={{ mt: '$12' }}>
        <Button
          mode="filled"
          type="submit"
          css={{ ml: 'auto' }}
          loading={isLoading}
          disabled={!isEmptyObject(errors)}
        >
          Submit
        </Button>
      </Flex>
    </form>
  )
}
