import * as Sentry from '@sentry/browser'
import { Component } from 'react'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Flex } from '@/common/layout'

class CalendarErrorBoundary extends Component<{}, { eventId?: string }> {
  state = {
    eventId: undefined,
  }

  componentDidCatch(error: Error | null, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (!!this.state.eventId) {
      return (
        <Card>
          <Flex direction="column" align="center">
            <h2>Something went wrong</h2>
            <p>If this continues to happen, please report it to us.</p>
            <Button
              mode="filled"
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </Button>
          </Flex>
        </Card>
      )
    }
    return this.props.children
  }
}

export default CalendarErrorBoundary
