import { PlusCircledIcon, TrashIcon } from '@radix-ui/react-icons'
import { FC, useEffect } from 'react'
import { FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Input, InputIcon } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { FeeTier } from '@/types/settings'
import { defaultTier, TiersValues } from './InstantTransferFees'

interface Props {
  data: TiersValues
  isLoading: boolean
  onSubmit: (values: TiersValues) => void
  onRemove: (rec: FeeTier) => void
}

const resetForm = (data: TiersValues) => data

export const InstantTransferFeeForm: FC<Props> = ({
  data,
  isLoading,
  onSubmit,
  onRemove,
}) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<TiersValues>({
    defaultValues: resetForm(data),
  })
  const { fields, append, remove } = useFieldArray({
    name: 'tiers',
    control,
  })

  useEffect(() => {
    reset(resetForm(data))
  }, [data, reset])

  const addTier = () => append({ ...defaultTier, max: data.tiers.length + 1 })

  const removeTier = (
    rec: FieldArrayWithId<TiersValues, 'tiers', 'id'>,
    index: number
  ) => {
    onRemove({ min: rec.min, max: rec.max, fee: rec.fee })
    remove(index)
  }

  return (
    <>
      <h5>Instant Transfer Fee Tiers</h5>
      <p>
        <strong>Notice:</strong> All fee adjustments require <i>internal</i>{' '}
        executive approval.
        <br />
        Any values entered below will <i>override</i> the default fee structure.
      </p>
      <p>
        <p>
          <strong>Default Fee Structure</strong>
        </p>
        <strong>W2</strong>
        <ul>
          <li>$2.99 - Up to $25</li>
          <li>$3.99 - $25.01 - $74.99</li>
          <li>$4.99 - $75 +</li>
        </ul>
        <strong>1099</strong>
        <ul>
          <li>2% with a $2.99 Minimum</li>
        </ul>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexWrap>
          <Label style={{ width: '30%' }}>Min Amount</Label>
          <Label style={{ width: '30%' }}>Max Amount</Label>
          <Label style={{ width: '30%' }}>Fee</Label>
        </FlexWrap>
        {fields.map((rec, index) => {
          return (
            <FlexWrap align="center" key={rec.id} css={{ marginBottom: '$8' }}>
              <>
                <Flex css={{ width: '30%' }}>
                  <InputIcon>$</InputIcon>
                  <Input
                    {...register(`tiers.${index}.min` as const, {
                      required: true,
                      valueAsNumber: true,
                    })}
                    step=".01"
                    type="number"
                    min="0"
                    placeholder="required"
                    css={{ width: '80%' }}
                    iconLeft
                  />
                </Flex>
                <Flex css={{ width: '30%' }}>
                  <InputIcon>$</InputIcon>
                  <Input
                    {...register(`tiers.${index}.max` as const, {
                      required: true,
                      valueAsNumber: true,
                    })}
                    step=".01"
                    type="number"
                    min="0"
                    placeholder="none"
                    css={{ width: '80%' }}
                    iconLeft
                  />
                </Flex>
                <Flex css={{ width: '30%' }}>
                  <InputIcon>$</InputIcon>
                  <Input
                    {...register(`tiers.${index}.fee` as const, {
                      required: true,
                      valueAsNumber: true,
                    })}
                    step=".01"
                    type="number"
                    min="0"
                    placeholder="required"
                    css={{ width: '80%' }}
                    iconLeft
                  />
                </Flex>
              </>

              <Button
                size="md"
                color="danger"
                mode="outline"
                onClick={() => removeTier(rec, index)}
                css={{ '&:svg': { margin: 0 } }}
              >
                <TrashIcon />
              </Button>
            </FlexWrap>
          )
        })}
        <Text size="sm">
          <strong>Note</strong>: Enter amounts in dollars.
        </Text>

        <Flex justify="between">
          <Button onClick={addTier}>
            <PlusCircledIcon />
            Add Tier
          </Button>

          <Button
            type="submit"
            mode="filled"
            disabled={isLoading || !isDirty}
            size="md"
            css={{ ml: 'auto' }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </>
  )
}
