import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { Spinner } from '@/components/common/loader'

interface IProps {
  onDrop: (files: File[]) => void
  multiple?: boolean
  title?: string
  subtitle?: string
  reading?: boolean
  className?: string
}

const Dropzone = (props: IProps) => {
  const {
    onDrop,
    multiple = false,
    title,
    subtitle,
    reading,
    className,
  } = props

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled: reading,
  })

  const classes = classnames('dropzone', className, {
    'drag-active': isDragActive,
  })

  return (
    <div {...getRootProps()} className={classes}>
      <div className="dropzone-content">
        {reading ? (
          <Spinner />
        ) : (
          <>
            <input {...getInputProps()} />
            {title && <h4 className="font-weight-bold">{title}</h4>}
            {subtitle && <p className="mb-0">{subtitle}</p>}
          </>
        )}
      </div>
    </div>
  )
}

export default Dropzone
