import classnames from 'classnames'
import { FC } from 'react'
import { Badge } from '@/common/badge'
import { CheckmarkIcon } from '@/common/icons'
import { Tooltip } from '@/common/tooltip'
import styles from './index.module.scss'
import { IListItem } from './types'

interface Props {
  entry: IListItem
  selected?: boolean
}

const RosterListItem: FC<Props> = ({ entry, selected }) => {
  const classes = classnames(styles.RosterListItem, {
    [styles.RosterListItem_Disabled]: entry.taken,
  })

  return (
    <>
      <span className={classes}>
        <p className={styles.RosterListItemName}>{entry.name}</p>
        <p className={styles.RosterListItemId}>ID: {entry.id}</p>
      </span>
      {selected && <CheckmarkIcon />}
      {entry.taken && (
        <Tooltip side="bottom" trigger={<Badge type="info">Enrolled</Badge>}>
          Another Branch user is already enrolled with this ID.
        </Tooltip>
      )}
    </>
  )
}

export default RosterListItem
