import moment, { Moment } from 'moment'
import { useQuery } from 'react-query'
import { getAdoptionTimeSeries, getAdvancesTimeSeries } from '@/api/insights'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

export const GET_ADOPTION_INSIGHTS_QUERY = 'get-adoption-insights'

export const useAdoptionInsightsQuery = ({
  startDate,
  endDate,
}: {
  startDate: Moment
  endDate: Moment
}) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [GET_ADOPTION_INSIGHTS_QUERY, orgId, startDate, endDate],
    async () => {
      const { data } = await getAdoptionTimeSeries(orgId, {
        start: startDate ? startDate : moment().year(2014),
        end: endDate ? endDate : moment(),
        region: 'America/Chicago',
        interval: 0,
      })
      return data.data.time_series
    }
  )
}

export const GET_TRANSACTION_INSIGHTS_QUERY = 'get-transaction-insights'

export const useTransactionInsightsQuery = ({
  startDate,
  endDate,
}: {
  startDate: Moment
  endDate: Moment
}) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [GET_TRANSACTION_INSIGHTS_QUERY, orgId, startDate, endDate],
    async () => {
      const { data } = await getAdvancesTimeSeries(orgId, {
        start: startDate,
        end: endDate,
        region: 'America/Chicago',
        interval: 0,
      })
      return data.data.time_series
    }
  )
}
