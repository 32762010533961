import { FC } from 'react'
import { useHistory } from 'react-router'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { ROUTES } from '@/router/routes'

interface Props {
  setShowPinAuth: (show: boolean) => void
  isAuthenticating: boolean
  handleSendPin: (phone: string) => void
  phone: string
}

export const AuthDropdown: FC<Props> = ({
  setShowPinAuth,
  isAuthenticating,
  handleSendPin,
  phone,
}) => {
  const history = useHistory()
  const rerouteToSignup = () => history.push(ROUTES.REGISTER)
  const rerouteToPhoneEntry = () => setShowPinAuth(false)
  const resendPin = () => handleSendPin(phone)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          disabled={isAuthenticating}
          mode="outline"
          display="block"
          css={{
            mt: '$12',
            width: '100%',
          }}
        >
          More Actions
        </Button>
      </DropdownMenuTrigger>
      <DropdownContent>
        <DropdownMenuItem onClick={rerouteToPhoneEntry} key="change-phone">
          Change phone number
        </DropdownMenuItem>
        <DropdownMenuItem onClick={rerouteToSignup} key="sign-up">
          I need to sign up
        </DropdownMenuItem>
        <DropdownMenuItem onClick={resendPin} key="resend-code">
          Resend code
        </DropdownMenuItem>
      </DropdownContent>
    </DropdownMenu>
  )
}
