import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import {
  useRemovePartnerId,
  useUkgDeleteRegistration,
} from '@/src/queries/integrations'
import { useToasterStore } from '@/store/toast'

export const UkgDeleteConfirmationModal: FC = () => {
  const deleteRegistration = useUkgDeleteRegistration()
  const removePartnerId = useRemovePartnerId()
  const { addToast } = useToasterStore()

  const confirmDeletion = async () => {
    try {
      await deleteRegistration.mutateAsync()
      await removePartnerId.mutateAsync()
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Deletion failed',
        description: 'Failed to delete UKG Integration.',
      })
    }
  }
  return (
    <AlertDialog
      title="Delete Confirmation"
      handleAction={confirmDeletion}
      actionDescription="Confirm"
      disabled={deleteRegistration.isLoading}
      description={<p>Are you sure you want to delete the UKG Integration?</p>}
      isDestructive={true}
    />
  )
}
