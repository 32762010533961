import classnames from 'classnames'
import { calculateSvgSize } from '@/lib/svgSize'

interface IProps extends React.HTMLAttributes<SVGSVGElement> {
  color: 'primary' | 'light'
  width?: number
  height?: number
}

const Spinner = (props: IProps) => {
  const { width = 80, height = 80, color, className } = props

  const viewBoxSize = {
    width: 112,
    height: 112,
  }
  const size = calculateSvgSize(viewBoxSize, { width, height })
  const classes = classnames('spinner', color, className)

  return (
    <svg
      {...props}
      width={`${size.width}px`}
      height={`${size.height}px`}
      viewBox={`0 0 ${viewBoxSize.width} ${viewBoxSize.height}`}
      className={classes}
    >
      <defs>
        <path
          d="M40,80 C17.90861,80 0,62.09139 0,40 C0,17.90861 17.90861,0 40,0 C62.09139,0 80,17.90861 80,40 C80,62.09139 62.09139,80 40,80 Z M40,70 C56.5685425,70 70,56.5685425 70,40 C70,23.4314575 56.5685425,10 40,10 C23.4314575,10 10,23.4314575 10,40 C10,56.5685425 23.4314575,70 40,70 Z"
          id="path-1"
        />
        <filter
          x="-30.0%"
          y="-30.0%"
          width="160.0%"
          height="160.0%"
          filterUnits="objectBoundingBox"
          id="filter-3"
        >
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="8"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          d="M40,0 C42.7614237,0 45,2.23857625 45,5 C45,7.76142375 42.7614237,10 40,10 C23.4314575,10 10,23.4314575 10,40 C10,42.7614237 7.76142375,45 5,45 C2.23857625,45 0,42.7614237 0,40 C0,17.90861 17.90861,0 40,0 Z"
          id="path-5"
        />
        <filter
          x="-26.7%"
          y="-26.7%"
          width="153.3%"
          height="153.3%"
          filterUnits="objectBoundingBox"
          id="filter-6"
        >
          <feOffset
            dx="0"
            dy="0"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.203921569   0 0 0 0 0.615686275   0 0 0 0 0.964705882  0 0 0 0.64 0"
            type="matrix"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mockup" transform="translate(-412.000000, -277.000000)">
          <g id="Stacked-Group" transform="translate(428.000000, 293.000000)">
            <g id="Loader-/-Spinner-/-Icon">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Outter">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-3)"
                  xlinkHref="#path-1"
                />
                <use
                  fillRule="evenodd"
                  xlinkHref="#path-1"
                  className="spinner-ring"
                />
              </g>
              <g id="Inner" mask="url(#mask-2)" className="spinner-inner">
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-6)"
                  xlinkHref="#path-5"
                />
                <use
                  fillRule="evenodd"
                  xlinkHref="#path-5"
                  className="spinner-spinny"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

Spinner.defaultProps = {
  color: 'primary',
}

export default Spinner
