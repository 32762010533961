import { saveAs } from 'file-saver'

const templateCsvContent =
  "Worker's ID,First Name,Last Name,Rate Type (optional),Rate (optional),Phone (optional),Email (optional),Location (optional),Business Name (optional)\n123456,Johnny,Appleseed,hourly,15.00,+14155552671,johnny@org.com,Store 1234,Business 21\n7891234,Agnes,Arber,hourly,20.00,+14155552672,agnes@org.com,Store 1235,Business 22\n2352355,Bob,Ross,hourly,15.00,+14155552673,bob@org.com,Store 1236,Business 23"

export const downloadRosterTemplate = () => {
  const blob = new Blob([templateCsvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch-roster-template.csv')
}
