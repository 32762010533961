import { FC } from 'react'
import { IOrganizationEmployee } from '@/types/employees'
import { StatusBadge } from './StatusBadge'

interface Props {
  original: IOrganizationEmployee
}

export const StatusCell: FC<Props> = ({ original }) => (
  <StatusBadge user={original} />
)
