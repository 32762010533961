import { FC, useState } from 'react'
import { Button } from '@/common/button'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import RosterSelect from '@/components/roster-select/RosterSelect'
import { useModalStore } from '@/store/modal'

interface Props {
  handleSubmit: (id: string) => void
  name: string
}

export const VerifyUserModal: FC<Props> = ({ name, handleSubmit }) => {
  const [id, setId] = useState('')
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleVerify = () => {
    handleSubmit(id)
    close()
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Enroll"
      description={`To enroll ${name}, enter their ID and select them from the list. If there's no match, create a new record for them.`}
    >
      <Label>ID</Label>
      <RosterSelect name={name} setFieldValue={setId} />
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button mode="filled" onClick={handleVerify} disabled={id.length < 1}>
          Enroll
        </Button>
      </Flex>
    </Dialog>
  )
}
