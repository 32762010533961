import { PayoutStatus } from '@/types/payouts'

export const PAYOUT_STATUS_LABEL_MAP: { [key in PayoutStatus]: string } = {
  FAILED: 'Failed',
  CREATED: 'Created',
  'ATTEMPTING PAYBACK': 'Attempting Invoice',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  PAYBACK_FAILED: 'Invoice Failed',
  SCHEDULED: 'Scheduled',
  SKIPPED: 'Skipped',
}
