import { TrashIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Input } from '@/common/input'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { useCreateSSOMapping, useDeleteSSOMapping } from '@/queries/sso'
import { useUser } from '@/store/user'
import { SingleSignOnMapping } from '@/types/sso'

interface Props {
  data: SingleSignOnMapping[]
}

export const SSOMappings: FC<Props> = ({ data }) => {
  const orgs = useUser(state => state.organizations)
  const removeMapping = useDeleteSSOMapping()
  const addMapping = useCreateSSOMapping()
  const [mapping, setMapping] = useState('')

  const onAdd = () => {
    const orgId = parseInt(mapping, 10)
    if (isNaN(orgId)) return

    addMapping.mutate(
      { organization_id: orgId },
      {
        onSuccess: () => {
          setMapping('')
        },
      }
    )
  }

  const onRemove = (id: number) => {
    removeMapping.mutate(id)
  }

  return (
    <Card css={{ mt: '$24', maxWidth: '800px' }}>
      <Text as="h6" bold>
        Connected Organizations
      </Text>
      <div>
        <FlexWrap>
          <Input
            value={mapping}
            onChange={e => setMapping(e.target.value)}
            placeholder="Org ID"
            inline
          />
          <Button
            mode="filled"
            onClick={onAdd}
            loading={addMapping.isLoading}
            disabled={!mapping}
          >
            Add
          </Button>
        </FlexWrap>
        {data.map(rec => {
          const org = orgs.find(o => o.id === +rec.organization_id)
          return (
            <Flex align="center" css={{ marginTop: '$16' }} key={rec.id}>
              <Button
                iconRight
                color="danger"
                mode="outline"
                onClick={() => onRemove(rec.id)}
                disabled={data.length === 1 || removeMapping.isLoading}
                css={{ mr: '$8' }}
              >
                <TrashIcon />
              </Button>
              {org?.name} - {rec.organization_id}
            </Flex>
          )
        })}
      </div>
    </Card>
  )
}
