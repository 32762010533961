import { FC, useEffect, useState } from 'react'
import { DetailsView, PayoutDetails } from '@/common/detail'
import { PayoutRecord } from '@/types/payouts'
import { WorkerDetailsLookup } from './WorkerDetailsLookup'

interface Props {
  payout: PayoutRecord
  remove: () => void
}

export const PayoutDetailsView: FC<Props> = ({ payout, remove }) => {
  const [showEmployee, setShowEmployee] = useState(false)

  useEffect(() => {
    setShowEmployee(false)
  }, [payout])

  return (
    <DetailsView>
      {showEmployee && (
        <WorkerDetailsLookup
          record={payout.employee}
          remove={() => setShowEmployee(false)}
        />
      )}
      {!showEmployee && (
        <PayoutDetails
          payout={payout}
          remove={remove}
          handleNameClick={() => setShowEmployee(true)}
        />
      )}
    </DetailsView>
  )
}
