import { ColumnDef } from '@tanstack/react-table'
import moment, { Moment } from 'moment'
import { enumerateDaysBetweenDates } from '@/common/datepicker/utils'
import { TimeKeepingRow } from '@/types/records'
import HoursCell from '../components/HoursCell'

export type TimekeepingColumn = ColumnDef<TimeKeepingRow, any>

export const STATIC_COLUMNS: TimekeepingColumn[] = [
  {
    header: "Worker's ID",
    id: 'employee_id',
    accessorFn: row => row.employee_id,
    size: 140,
    enableSorting: true,
  },
  {
    header: 'Name',
    id: 'first_name',
    accessorFn: row => row.name,
    size: 200,
    cell: ({ row }) =>
      row.original.name ? row.original.name : 'No name on file',
    enableSorting: true,
  },
  {
    header: 'Total Hrs',
    id: 'totalHours',
    accessorKey: 'total_hours',
    cell: ({ row }) => <HoursCell value={row.original.total_hours} />,
    size: 120,
    enableSorting: false,
  },
]

const getHours = (original: TimeKeepingRow, formattedDate: string) => {
  const shiftsOnThisDay = (original.tracked_times || []).filter(
    (t: any) => moment(t.date).format('ddd, MMM D') === formattedDate
  )
  const hours = shiftsOnThisDay.reduce(
    (acc: number, shift: any) => acc + shift.hours,
    0
  )
  return hours
}

export const getTableColumns = (range: {
  startDate: Moment
  endDate: Moment
}): TimekeepingColumn[] => {
  const dateColumns: TimekeepingColumn[] = enumerateDaysBetweenDates(
    range.startDate,
    range.endDate
  ).map((date, index) => {
    const formattedDate = date.format('ddd, MMM D')
    return {
      header: formattedDate,
      id: formattedDate,
      cell: ({ row }) => (
        <HoursCell value={getHours(row.original, formattedDate)} />
      ),
      // the first column should be a bit wider to accommodate
      // the dropshadow for fixed columns
      width: index === 0 ? 150 : 130,
    }
  })
  return [...STATIC_COLUMNS, ...dateColumns]
}
