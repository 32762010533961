import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useCreateAccountsStore } from '@/store/create-accounts'
import { additionalFilters, statusOptions } from '../lib/filters'
import { useDownloadInitiatedUsers } from '../lib/useDownloadInitiatedUsers'

export const CreateAccountsFilters: FC = () => {
  const { pageSize, filters, sorted, updateFilters } = useCreateAccountsStore()
  const { handleDownload, loading } = useDownloadInitiatedUsers({
    sorted,
    filtered: filters,
    pageSize: pageSize,
  })

  return (
    <TableFiltersMain
      searchProperty="employee_id"
      filters={filters}
      updateFilters={updateFilters}
      statusOptions={statusOptions}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleDownload={handleDownload}
      canDownloadData
    />
  )
}
