import { saveAs } from 'file-saver'
import { Moment } from 'moment'
import Papa from 'papaparse'
import { enumerateDaysBetweenDates } from '@/common/datepicker/utils'
import { TimeKeepingRecord } from '@/types/records'

type DateRange = { start: Moment; end: Moment }

export const downloadCSV = (
  records: TimeKeepingRecord[],
  orgName: string,
  range: DateRange
) => {
  const dateColumns = enumerateDaysBetweenDates(
    range.start,
    range.end
  ).map(date => date.format('YYYY-MM-DD'))

  const csvData = records.map(record => {
    let row: { [k: string]: string | number | null } = {}
    row["Worker's ID"] = record.employee_id
    row['Name'] = record.name
    dateColumns.forEach(date => (row[date] = 0))
    for (const trackedTime of record.tracked_times || []) {
      const { date, hours } = trackedTime
      row[date] = (parseFloat(row[date] as string) + hours).toFixed(2)
    }
    return row
  })

  const csvContent = Papa.unparse(csvData)

  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, `${orgName}_Timekeeping_Records.csv`)
}
