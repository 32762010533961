import { Text } from '@/common/text'
import { styled } from '@/styles/stitches.config'

export const CheckboxIndicator = styled('div', {
  position: 'absolute',
  left: '-24px',
  top: '-4px',
  color: '$secondary500',
})

export const HelperText = styled(Text, {
  color: '$secondary500',
  margin: '2px',
})

export const SectionTitle = styled('h5', {
  marginBottom: '1rem',
})

export const SectionSeperator = styled('hr', {
  marginBottom: '3rem',
  marginTop: '3rem',
})
