import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { styled } from '@/styles/stitches.config'

export const StyledContent = styled(DropdownPrimitive.Content, {
  minWidth: 220,
  backgroundColor: 'white',
  borderRadius: '$md',
  padding: '$8',
  zIndex: 1000,
  boxShadow: '$popOver',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
  },
})

export const StyledArrow = styled(DropdownPrimitive.Arrow, {
  fill: 'white',
})

export const StyledDropdownMenuItem = styled(
  DropdownPrimitive.DropdownMenuItem,
  {
    color: '$secondary300',
    fontSize: 14,
    fontWeight: 700,
    padding: '$8',
    margin: '$2 $8',
    borderRadius: '$sm',

    '&:hover': {
      color: '$secondary100',
      cursor: 'pointer',
      backgroundColor: '$secondary900',
    },

    '&:focus': {
      outline: 'none',
    },
  }
)

export const StyledSeparator = styled(DropdownPrimitive.Separator, {
  height: 1,
  backgroundColor: '$secondary700',
  margin: '$4 0',
})

export const StyledIconButton = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  margin: 0,
  height: '$32',
  width: '$32',
  borderRadius: '$lg',
  '&:hover': {
    color: '$primary500',
  },
})
