import { FC, useMemo } from 'react'
import { Alert } from '@/common/alert'
import { Label } from '@/common/label'
import { Loader } from '@/common/loader'
import { Select } from '@/common/select'
import { useFundingSources } from '@/queries/funding-sources'

interface Props {
  selectedId?: number | null
  setSelectedId: (selectedId: number) => void
  createable?: boolean
  onCreate?: () => void
}

export const FundingSourceSelect: FC<Props> = ({
  selectedId,
  setSelectedId,
  createable,
  onCreate,
}) => {
  const fundingSources = useFundingSources()

  const onSelect = (value: string) => {
    if (value === 'new') {
      onCreate && onCreate()
      return
    }
    setSelectedId(+value)
  }

  const defaultFundingSourceOptions = useMemo(
    () =>
      createable
        ? [
            { label: 'New', value: 'new' },
            { label: 'None', value: '' },
          ]
        : [{ label: 'None', value: '' }],
    [createable]
  )

  const fundingSourcesOptions = useMemo(
    () =>
      fundingSources.data
        ?.map(source => ({
          label: `${source.name} (${source.account_number_last_four})`,
          value: source.id.toString(),
        }))
        .concat(defaultFundingSourceOptions)
        .flat() || [],
    [fundingSources, defaultFundingSourceOptions]
  )

  return (
    <Loader loading={fundingSources.isLoading}>
      {fundingSources.data?.length === 0 ? (
        <Alert type="warning" css={{ mt: '$12', mb: '$12' }}>
          No funding sources configured.
        </Alert>
      ) : (
        <div>
          <Label>Funding Source</Label>
          <Select
            value={selectedId?.toString() || ''}
            onChange={onSelect}
            options={fundingSourcesOptions}
          />
        </div>
      )}
    </Loader>
  )
}
