import moment from 'moment'
import { Checkbox } from '@/common/checkbox'
import { Flex } from '@/common/layout'
import { PayoutRecord } from '@/types/payouts'
import { ColumnDefIdRequired } from '@/types/table'
import AmountCell from '../components/AmountCell'
import { PayoutActionDropdown } from '../components/PayoutActionDropdown'
import { StatusCell } from '../components/StatusCell'
import { ViewPaybackCell } from '../components/ViewPaybackCell'

export const columns: ColumnDefIdRequired<PayoutRecord, any>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Flex css={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Checkbox
          id={'select'}
          nativeClickFn={table.getToggleAllRowsSelectedHandler()}
          checked={table.getIsAllRowsSelected()}
        />
      </Flex>
    ),
    cell: ({ row }) => (
      <Flex justify="center" onClick={e => e.stopPropagation()}>
        <Checkbox
          checked={row.getIsSelected()}
          id={row.original.id.toString()}
          onChange={row.getToggleSelectedHandler()}
        />
      </Flex>
    ),
    enableSorting: false,
    size: 65,
  },
  {
    header: 'Created Date',
    id: 'time_created',
    accessorFn: row => moment(row.time_created).format(),
  },
  {
    header: 'Disbursement Date',
    id: 'disbursement_time',
    accessorFn: row => moment(row.disbursement_time).format(),
  },
  {
    header: 'Worker ID',
    id: 'employee_id',
    accessorFn: row => row.roster_record.employee_id,
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: row =>
      row.roster_record.first_name
        ? `${row.roster_record.first_name} ${row.roster_record.last_name}`
        : '',
    enableSorting: false,
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    id: 'amount',
    cell: ({ row }) => <AmountCell value={row.original.amount} />,
    size: 100,
  },
  {
    id: 'type',
    header: 'Type',
    accessorKey: 'type',
  },
  {
    header: 'Sub Label',
    id: 'payout_sub_label',
    accessorKey: 'payout_sub_label',
  },
  {
    header: 'Invoice ID',
    id: 'payback_id',
    accessorFn: row => row.payback_id,
    cell: ({ row }) => <ViewPaybackCell original={row.original} />,
    size: 110,
  },
  {
    header: 'Store ID',
    id: 'external_store_id',
    accessorFn: row => row.external_store_id,
    enableSorting: false,
    size: 110,
  },
  {
    header: 'Shift Timestamp',
    id: 'shift_timestamp',
    accessorFn: row => row.metadata?.shift_timestamp,
    enableSorting: false,
  },
  {
    header: 'Status',
    id: 'status',
    accessorFn: row => row.status,
    cell: ({ row }) => <StatusCell original={row.original} />,
    enableSorting: false,
  },
  {
    header: '',
    accessorKey: 'payout_actions',
    id: 'actions',
    size: 65,
    cell: ({ row }) => (
      <Flex justify="center">
        <PayoutActionDropdown original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]

export const disbursementColumnsDemo = columns.map((column, index) => ({
  id: column.id,
  hidden: false,
  locked: column.id === 'actions' || column.id === 'select' ? true : false,
  text: '',
}))
