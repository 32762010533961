import { FC } from 'react'
import { Flex } from '@/common/layout'
import { Loader } from '@/common/loader'
import { Text } from '@/common/text'
interface Props {
  isLoading?: boolean
  dataSize?: number
}
export const TableLoadingComponent: FC<Props> = ({
  isLoading,
  dataSize = 0,
}) => (
  <>
    {isLoading && (
      <Flex justify="center" align="center" css={{ padding: '$32' }}>
        <Loader loading={true} />
      </Flex>
    )}
    {!isLoading && dataSize === 0 && (
      <Flex justify="center" align="center" css={{ padding: '$32' }}>
        <Text css={{ fontWeight: 'bold' }}>No data</Text>
      </Flex>
    )}
  </>
)
