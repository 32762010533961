import { styled } from '@/styles/stitches.config'

export const StyledText = styled('p', {
  margin: 0,
  variants: {
    size: {
      sm: {
        fontSize: 12,
      },
      md: {
        fontSize: 14,
      },
      lg: {
        fontSize: 16,
      },
      xl: {
        fontSize: 20,
      },
    },
    bold: {
      true: { fontWeight: 600 },
    },
    color: {
      primary: {
        color: '$primary500',
      },
      alert: {
        color: 'red',
      },
      hint: {
        color: '$secondary500',
      },
    },
  },
})
