import { Placement } from '@popperjs/core'
import { ButtonHTMLAttributes, FC } from 'react'
import { Tooltip, TooltipContentProps } from '@/common/tooltip'
import { StyledIconButton, StyledIconButtonVariants } from './styles'

type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  StyledIconButtonVariants & {
    tooltip?: string
    placement?: Placement
    side?: TooltipContentProps['side']
  }

export const IconButton: FC<Props> = ({
  tooltip,
  onClick,
  children,
  placement,
  id,
  side,
  ...rest
}) => {
  if (!!tooltip) {
    return (
      <Tooltip
        side={side}
        trigger={
          <StyledIconButton onClick={onClick} type="button" {...rest}>
            {children}
          </StyledIconButton>
        }
      >
        {tooltip}
      </Tooltip>
    )
  }

  return (
    <StyledIconButton onClick={onClick} type="button" {...rest}>
      {children}
    </StyledIconButton>
  )
}
