import { FC } from 'react'
import { CashoutRecord } from '@/types/cash-outs'
import { ViewDetailsLink } from './ViewDetailsLink'

interface Props {
  original: CashoutRecord
}

export const ViewDetailsCell: FC<Props> = ({ original }) => (
  <ViewDetailsLink payback={original} />
)
