import { useEffect, useState } from 'react'
import { useUserOrganizationsQuery } from '@/queries/auth'
import { useUser } from '@/store/user'

export const useUserOrgsInitializer = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [error, setError] = useState<string>()
  const { data: orgs } = useUserOrganizationsQuery()
  const setOrganizations = useUser(state => state.setOrganizations)

  useEffect(() => {
    if (orgs) {
      if (!orgs.length) {
        setError(
          "This user has no organizations associated with their account, so we can't log them in."
        )
      }

      setOrganizations(orgs)
      setIsInitialized(true)
    }
  }, [orgs, setError, setOrganizations, setIsInitialized])

  return { isInitialized, error }
}
