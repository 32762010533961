import { PayoutRecord, PayoutStatus } from '@/types/payouts'

export const sampleDisbursementData: PayoutRecord[] = [
  {
    amount: 100,
    description: 'test',
    external_id: '123',
    external_store_id: '123',
    id: 123,
    failure_reason: 'test',
    status_reason: 'test',
    metadata: {
      test: 'test',
    },
    employee: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
      joined: 'test',
      linked: false,
    },
    roster_record: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
    },
    created_by: 123,
    system_created: true,
    organization_id: 123,
    number_of_attempts: 123,
    payback_id: 123,
    status: PayoutStatus.COMPLETED,
    disbursement_time: '2023-05-10T20:55:11Z',
    time_created: '2023-05-10T20:55:11Z',
    time_modified: 'test',
    time_scheduled: 'test',
    invoice_date: '2023-05-10T20:55:11Z',
    payout_sub_label: 'test',
    type: 'test',
    reason_code: 'test',
  },
  {
    amount: 100,
    description: 'test',
    external_id: '123',
    external_store_id: '123',
    id: 123,
    failure_reason: 'test',
    status_reason: 'test',
    metadata: {
      test: 'test',
    },
    employee: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
      joined: 'test',
      linked: false,
    },
    roster_record: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
    },
    created_by: 123,
    system_created: true,
    organization_id: 123,
    number_of_attempts: 123,
    payback_id: 123,
    status: PayoutStatus.FAILED,
    disbursement_time: '2023-05-10T20:55:11Z',
    time_created: '2023-05-10T20:55:11Z',
    time_modified: 'test',
    time_scheduled: 'test',
    invoice_date: '2023-05-10T20:55:11Z',
    payout_sub_label: 'test',
    type: 'test',
    reason_code: 'test',
  },
  {
    amount: 100,
    description: 'test',
    external_id: '123',
    external_store_id: '123',
    id: 123,
    failure_reason: 'test',
    status_reason: 'test',
    metadata: {
      test: 'test',
    },
    employee: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
      joined: 'test',
      linked: false,
    },
    roster_record: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
    },
    created_by: 123,
    system_created: true,
    organization_id: 123,
    number_of_attempts: 123,
    payback_id: 123,
    status: PayoutStatus.FAILED,
    disbursement_time: '2023-05-10T20:55:11Z',
    time_created: '2023-05-10T20:55:11Z',
    time_modified: 'test',
    time_scheduled: 'test',
    invoice_date: '2023-05-10T20:55:11Z',
    payout_sub_label: 'test',
    type: 'test',
    reason_code: 'test',
  },
  {
    amount: 100,
    description: 'test',
    external_id: '123',
    external_store_id: '123',
    id: 123,
    failure_reason: 'test',
    status_reason: 'test',
    metadata: {
      test: 'test',
    },
    employee: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
      joined: 'test',
      linked: false,
    },
    roster_record: {
      employee_id: '123',
      first_name: 'test',
      last_name: 'test',
      user_id: 123,
    },
    created_by: 123,
    system_created: true,
    organization_id: 123,
    number_of_attempts: 123,
    payback_id: 123,
    status: PayoutStatus.SCHEDULED,
    disbursement_time: '2023-05-10T20:55:11Z',
    time_created: '2023-05-10T20:55:11Z',
    time_modified: 'test',
    time_scheduled: 'test',
    invoice_date: '2023-05-10T20:55:11Z',
    payout_sub_label: 'test',
    type: 'test',
    reason_code: 'test',
  },
]
