import { create } from 'zustand'
import { OrgSetting, OrgSettings } from '@/types/organization'

export type SettingsStore = {
  settings: OrgSettings
  settingsDescriptions: OrgSettings
  settingsData: OrgSetting[]
  isLoading: boolean
  setSettings: (settings: OrgSetting[]) => void
}

export const useSettingsStore = create<SettingsStore>((set, get) => ({
  isLoading: true,
  settings: {} as OrgSettings,
  settingsData: [],
  settingsDescriptions: {} as OrgSettings,
  setSettings: (settings: OrgSetting[]) => {
    const orgSettings = (settings || []).reduce(
      (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
      {} as OrgSettings
    )
    const settingsDescriptions = (settings || []).reduce(
      (acc, curr) => ({ ...acc, [curr.name]: curr.description }),
      {} as OrgSettings
    )
    set(state => ({
      ...state,
      settings: orgSettings,
      settingsDescriptions: settingsDescriptions,
      settingsData: settings,
      isLoading: false,
    }))
  },
}))
