import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { Input, InputIcon } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { UploadAsset } from '@/components/common/upload-asset'
import { isEmptyObject } from '@/lib/validators'
import { useUpdateOrganization } from '@/queries/organizations'
import { useUpdateSettingsMutation } from '@/queries/settings'
import {
  removeCachedCurrentOrganization,
  removeCachedOrganizations,
} from '@/src/sessionstorage'
import { usePermissionsStore } from '@/store/permissions'
import { useSettingsStore } from '@/store/settings'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export type TOrganizationSettingsValues = {
  BRANDING_COLOR_HEX_CODE: string
  logoUrl: string
  name: string
}

const schema = Yup.object().shape({
  BRANDING_COLOR_HEX_CODE: Yup.string().length(6, 'Must be 6 characters'),
  logoUrl: Yup.string().url('Enter a valid URL'),
  name: Yup.string()
    .required('Required')
    .max(100, 'Enter less than 100 characters'),
})

export const DetailsModal: FC<Props> = ({ isOpen, onClose }) => {
  const currentOrg = useCurrentOrg()
  const settings = useSettingsStore(state => state.settings)
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const updateSettings = useUpdateSettingsMutation()
  const updateOrganization = useUpdateOrganization()

  const defaultValues = useMemo(() => {
    return {
      BRANDING_COLOR_HEX_CODE: settings.BRANDING_COLOR_HEX_CODE
        ? settings.BRANDING_COLOR_HEX_CODE.substring(1)
        : '',
      name: currentOrg.name,
      logoUrl: currentOrg.logo_url || '',
    }
  }, [currentOrg.logo_url, currentOrg.name, settings.BRANDING_COLOR_HEX_CODE])

  const {
    watch,
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TOrganizationSettingsValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    reset()
  }, [reset, settings.BRANDING_COLOR_HEX_CODE])

  const onSubmit = async ({
    BRANDING_COLOR_HEX_CODE,
    logoUrl,
    name,
  }: TOrganizationSettingsValues) => {
    const settingsPayload = {
      BRANDING_COLOR_HEX_CODE: BRANDING_COLOR_HEX_CODE
        ? `#${BRANDING_COLOR_HEX_CODE}`
        : undefined,
    }
    try {
      await updateSettings.mutateAsync(settingsPayload)
      await updateOrganization.mutateAsync({
        name,
        logo_url: logoUrl || undefined,
      })

      removeCachedCurrentOrganization()
      removeCachedOrganizations()
      onClose()
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  const handleUploadUrl = (url: string) => setValue('logoUrl', url)

  return (
    <Dialog open={isOpen} setOpen={onClose} title="Company Information">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexGrid direction="column" fillSpace fullWidth size="lg">
          <Flex direction="column" align="start">
            <Label htmlFor="name">Name</Label>
            <Input
              {...register('name')}
              type="text"
              disabled={!isGlobalAdmin}
            />
            <Text color="alert" size="sm">
              {errors?.name?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="start">
            <UploadAsset
              handleSubmit={handleUploadUrl}
              title="Logo"
              label="Logo (Drag n Drop or Select)"
              description="Logo for organization"
              placeholder={watch('logoUrl')}
              disabled={!isGlobalAdmin}
            />
            <Text color="alert" size="sm">
              {errors?.logoUrl?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="start">
            <Label htmlFor="BRANDING_COLOR_HEX_CODE">
              Branding Color (Hex Code)
            </Label>
            <Flex>
              <InputIcon>#</InputIcon>
              <Input
                {...register('BRANDING_COLOR_HEX_CODE')}
                type="text"
                iconLeft
                placeholder="4cb258"
                value={watch('BRANDING_COLOR_HEX_CODE')}
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="hint" size="sm">
              Change the primary navigation bar above to match your company
              branding.
            </Text>
            <Text color="alert" size="sm">
              {errors?.BRANDING_COLOR_HEX_CODE?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="end">
            <Button
              mode="filled"
              type="submit"
              loading={updateSettings.isLoading || updateOrganization.isLoading}
              disabled={!isEmptyObject(errors)}
            >
              Save
            </Button>
          </Flex>
        </FlexGrid>
      </form>
    </Dialog>
  )
}
