import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Flex } from '@/common/layout'
import { filterByNumericPhone } from '@/common/table/filters'
import { formatPhoneNumber } from '@/lib/phoneLib'
import { UserEnrollmentRequest } from '@/types/employees'
import { EnrollmentActionDropdown } from '../components/EnrollmentActionDropdown'

export const enrollmentColumns: ColumnDef<UserEnrollmentRequest, any>[] = [
  {
    accessorFn: row => `${row.first_name} ${row.last_name}`,
    id: 'name',
    header: 'Name',
    cell: row => row.getValue(),
    enableSorting: false,
  },
  {
    accessorFn: row => row.phone,
    header: 'Phone',
    id: 'phone',
    cell: row => formatPhoneNumber(row.getValue()),
    enableSorting: false,
    filterFn: (row, _, filterValue) =>
      filterByNumericPhone(row.original.phone, filterValue),
  },
  {
    accessorFn: row => row.time_created && moment(row.time_created),
    header: 'Added',
    id: 'timeCreated',
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
    enableSorting: false,
  },
  {
    accessorFn: row => (row.email ? row.email : ''),
    header: 'Email',
    id: 'email',
    enableSorting: false,
    cell: ({ row }) => (
      <Flex justify="between">
        {row.original.email}
        <EnrollmentActionDropdown original={row.original} />
      </Flex>
    ),
  },
]
