interface Props {
  primary: React.ReactNode
  secondary?: React.ReactNode
}

const PageTitle = ({ primary, secondary }: Props) => {
  return (
    <div className="page-title">
      <h1>{primary}</h1>
      {secondary && <p>{secondary}</p>}
    </div>
  )
}

export default PageTitle
