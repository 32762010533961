import { create } from 'zustand'
import {
  getUserIdCookie,
  getUserIdFromTokenString,
  removeUserIdCookie,
  setUserIdCookie,
} from '@/lib/token'
import { IUserDetails } from '@/types/auth'
import { IOrganization } from '@/types/organization'

export type User = {
  user?: IUserDetails
  id: string | null
  organizations: IOrganization[]
  currentOrganization: IOrganization | null
  setOrganizations: (orgs: IOrganization[]) => void
  setCurrentOrganization: (org: IOrganization | null) => void
  setUser: (user: IUserDetails) => void
  parseToken: (token?: string) => void
}

export const useUser = create<User>((set, get) => ({
  id: getUserIdCookie(),
  organizations: [],
  currentOrganization: null,
  setCurrentOrganization: (org: IOrganization | null) =>
    set(state => ({ ...state, currentOrganization: org })),
  setOrganizations: (orgs: IOrganization[]) =>
    set(state => ({ ...state, organizations: orgs })),
  setUser: (user: IUserDetails) => set(state => ({ ...state, user })),
  parseToken: (token?: string) =>
    set(state => {
      let userId
      if (token) {
        userId = getUserIdFromTokenString(token)
        setUserIdCookie(userId)
      } else {
        removeUserIdCookie()
      }
      return { ...state, id: userId }
    }),
}))
