import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Input, InputIcon } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { ToggleSwitch } from '@/common/toggleswitch/ToggleSwitch'
import { PHONE_REG_EXP } from '@/lib/phoneLib'
import { useEditRosterUser } from '@/queries/employees'
import { useModalStore } from '@/store/modal'
import { SHIFT_TYPES } from '@/types/common'
import { IEnrolledUser, INotEnrolledUser } from '@/types/employees'

interface Props {
  user: IEnrolledUser | INotEnrolledUser
}

interface IEditRosterValues {
  firstName: string
  lastName: string
  businessName: string | null
  ein: string | null
  rate: number
  shiftType: SHIFT_TYPES
  email: string
  phone: string
  overrideRate: boolean
  overrideShiftType: boolean
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  rate: yup
    .number()
    .typeError('Must be a number')
    .min(0, 'Must be greater than 0'),
  shiftType: yup.string(),
  phone: yup
    .string()
    .matches(PHONE_REG_EXP, 'Phone number does not match format +11234567890'),
  email: yup.string().email(),
})

const shiftTypes = [
  {
    label: 'Hourly',
    value: 'Hourly',
  },
  {
    label: 'Daily',
    value: 'Daily',
  },
]

export const EditRosterUserModal: FC<Props> = ({ user }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const editRosterUser = useEditRosterUser()
  const initialValues: IEditRosterValues = {
    firstName: user.first_name,
    lastName: user.last_name,
    businessName: user.business_name || null,
    ein: user.ein || null,
    rate: user.rate ? user.rate / 100 : 0,
    email: user.email_address || '',
    phone: user.phone_number || '',
    overrideRate: !!user.rate,
    overrideShiftType: !!user.shift_type,
    shiftType: user.shift_type || SHIFT_TYPES.DAILY,
  }

  const {
    watch,
    setValue,
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  })

  const close = () => {
    setOpen(false)
    removeModal()
  }
  const onSubmitForm = (values: IEditRosterValues) => {
    const edits = {
      first_name: values.firstName,
      last_name: values.lastName,
      business_name: values.businessName || null,
      ein: values.ein || null,
      phone_number: values.phone,
      email_address: values.email,
      rate: values.overrideRate ? values.rate * 100 : null,
      shift_type: values.overrideShiftType ? values.shiftType : null,
    }
    editRosterUser.mutate(
      { employeeId: user.employee_id, edits },
      {
        onSuccess: close,
      }
    )
  }

  return (
    <Dialog title="Edit Roster User" open={open} setOpen={setOpen}>
      <Alert type="warning" size="sm" css={{ mb: '$16' }}>
        Changes made to this information will only affect the roster record. The
        user's account will <em>not</em> be updated.
      </Alert>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <FlexGrid css={{ mb: '$16' }}>
          <div>
            <Label>First Name</Label>
            <Input {...register('firstName')} />
            <Text color="alert">{errors.firstName?.message}</Text>
          </div>
          <div>
            <Label>Last Name</Label>
            <Input {...register('lastName')} />
            <Text color="alert">{errors.lastName?.message}</Text>
          </div>
        </FlexGrid>
        <FlexGrid fillSpace css={{ my: '$16' }}>
          <div>
            <Label>Business Name (optional)</Label>
            <Input {...register('businessName')} />
            <Text color="alert">{errors.businessName?.message}</Text>
          </div>
          <div>
            <Label>EIN (optional)</Label>
            <Input {...register('ein')} />
            <Text color="alert">{errors.ein?.message}</Text>
          </div>
        </FlexGrid>
        <FlexGrid align="start" css={{ my: '$16' }}>
          <div>
            <Label>Phone Number</Label>
            <Input {...register('phone')} />
            <Text color="hint" size="sm">
              Ex. +11234567890
            </Text>
            <Text color="alert">{errors.phone?.message}</Text>
          </div>
          <div>
            <Label>Email Address</Label>
            <Input {...register('email')} />
            <Text color="alert">{errors.email?.message}</Text>
          </div>
        </FlexGrid>
        <FlexGrid fillSpace css={{ my: '$16' }}>
          <div>
            <Flex align="start" justify="between">
              <Label css={{ mt: '$4' }}>Shift Rate</Label>
              <ToggleSwitch
                toggle={() => {
                  setValue('overrideRate', !getValues('overrideRate'))
                }}
                tooltip={`Override default rate (${
                  getValues('overrideRate') ? 'on' : 'off'
                })`}
                enabled={watch('overrideRate')}
              />
            </Flex>
            <Flex>
              <InputIcon left>$</InputIcon>
              <Input
                {...register('rate')}
                type="number"
                step=".01"
                iconLeft
                disabled={!watch('overrideRate')}
              />
            </Flex>
            <Text color="alert">{errors.rate?.message}</Text>
          </div>
          <div>
            <Flex align="start" justify="between">
              <Label css={{ mt: '$4' }}>Shift Type</Label>
              <ToggleSwitch
                toggle={() => {
                  setValue('overrideShiftType', !getValues('overrideShiftType'))
                }}
                tooltip={`Override Shift Type (${
                  getValues('overrideShiftType') ? 'on' : 'off'
                })`}
                enabled={watch('overrideShiftType')}
              />
            </Flex>

            <Select
              options={shiftTypes}
              placeholder="Shift Type"
              value={watch('shiftType')}
              onChange={val => setValue('shiftType', val)}
              disabled={!watch('overrideShiftType')}
            />

            <Text color="alert">{errors.shiftType?.message}</Text>
          </div>
        </FlexGrid>

        <Flex justify="end" fullWidth css={{ mt: '$16' }}>
          <Button
            type="submit"
            mode="filled"
            disabled={editRosterUser.isLoading}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Dialog>
  )
}
