import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Flex } from '@/common/layout'
import { TableExpander } from '@/common/table'
import { DeductionStatusBadge } from '@/components/deduction-report/components/DeductionStatusBadge'
import { toDollar } from '@/lib/currency'
import { ROUTES } from '@/router/routes'
import { AdvanceRecord } from '@/types/records'
import { IAdvanceDeduction } from '@/types/remittances'
import { AdvancePaidCell } from '../components/AdvancePaidCell'
import { VerificationFileCell } from '../components/VerificationFileCell'

export const columns: ColumnDef<AdvanceRecord, any>[] = [
  {
    header: 'Date',
    id: 'advance_date',
    accessorFn: row =>
      row.payout_date ? moment(row.payout_date).format('MM/DD/YYYY') : null,
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: row => `${row.roster_first_name} ${row.roster_last_name}`,
  },
  {
    header: 'ID',
    accessorKey: 'employee_id',
  },
  {
    header: 'Amount',
    id: 'amount',
    accessorFn: row => toDollar(row.amount),
  },
  {
    header: 'Balance Paid',
    id: 'balance_paid',
    cell: ({ row }) => <AdvancePaidCell original={row.original} />,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ row }) => <DeductionStatusBadge value={row.original.status} />,
  },
  {
    header: 'Authorization File',
    id: 'authFile',
    cell: ({ row }) => <VerificationFileCell original={row.original} />,
  },
  {
    id: 'expand',
    enableSorting: false,
    size: 35,
    cell: ({ row }) => (
      <Flex
        justify="center"
        css={{ cursor: 'pointer' }}
        onClick={row.getToggleExpandedHandler()}
      >
        <TableExpander isExpanded={row.getIsExpanded()} />
      </Flex>
    ),
  },
]

interface Props {
  original: IAdvanceDeduction
}

export const RemittanceLink: FC<Props> = ({ original: { remittance_id } }) => (
  <Link to={`${ROUTES.REMITTANCES}/${remittance_id}`}>{remittance_id}</Link>
)

export const deductionColumns: ColumnDef<IAdvanceDeduction, any>[] = [
  {
    header: 'Date Paid',
    id: 'date',
    accessorFn: row =>
      row.date ? moment(row.date).format('MM/DD/YYYY') : null,
  },
  {
    header: 'Remittance Id',
    id: 'remittance_id',
    cell: ({ row }) => <RemittanceLink original={row.original} />,
  },
  {
    header: 'Amount',
    id: 'amount',
    accessorFn: row => toDollar(row.amount),
  },
]
