import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useDownloadRemittance } from '@/components/deduction-report/lib/useDownloadRemittance'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { IRemittance } from '@/types/remittances'

interface Props {
  original: IRemittance
}

export const DeductionReportActionsCell: FC<Props> = ({
  original: remittance,
}) => {
  const { download } = useDownloadRemittance(remittance)
  const remittancePath = useOrgPath(ROUTES.REMITTANCE, {
    id: remittance.id,
  })
  const remittanceCompletePath = useOrgPath(ROUTES.REMITTANCE_COMPLETE, {
    id: remittance.id,
  })

  const handleDownload = () => {
    download()
  }

  if (remittance.status === 'started') {
    return (
      <Link to={remittanceCompletePath}>
        <span className="link">Start Remittance</span>
      </Link>
    )
  }

  return (
    <>
      <span className="link" onClick={handleDownload}>
        Download
      </span>
      &nbsp;&nbsp;&nbsp;
      <Link to={remittancePath}>
        <span className="link">View</span>
      </Link>
    </>
  )
}
