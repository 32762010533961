type TProps = React.HTMLAttributes<SVGSVGElement>

const SvgEmptyPieChart = (props: TProps) => (
  <svg width={88} height={88} viewBox="0 0 88 88" {...props}>
    <defs>
      <circle id="EmptyPieChart_svg__a" cx={44} cy={44} r={44} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="EmptyPieChart_svg__b" fill="#fff">
        <use xlinkHref="#EmptyPieChart_svg__a" />
      </mask>
      <use fill="#349DF6" xlinkHref="#EmptyPieChart_svg__a" />
      <path
        stroke="#FFF"
        strokeWidth={1.956}
        fill="#E6E6E6"
        mask="url(#EmptyPieChart_svg__b)"
        d="M51.446 51.633l25.852 27.836 15.215-31.788L90.708 44H55.12z"
      />
      <path
        stroke="#FFF"
        strokeWidth={1.956}
        fill="#A237F3"
        mask="url(#EmptyPieChart_svg__b)"
        d="M34.974 54.02L9.477 77.994l27.721 14.477 36.228-4.165 1.036-11.601L50.995 50.51l-5.272 3.51-10.356-.774z"
      />
      <path
        fill="#E6E6E6"
        mask="url(#EmptyPieChart_svg__b)"
        d="M44.246 92.472l29.18-4.165V76.882l-21.98-24.47-7.12 1.607z"
      />
      <path
        stroke="#FFF"
        strokeWidth={1.956}
        fill="#E6E6E6"
        mask="url(#EmptyPieChart_svg__b)"
        d="M44.484-1.796l-.258 33.357L53.52 42.68l3.356.93 39.246.839L82.44 8.806 56.877-1.796z"
      />
      <ellipse
        fill="#FFF"
        mask="url(#EmptyPieChart_svg__b)"
        cx={44.226}
        cy={44}
        rx={14.441}
        ry={14.367}
      />
    </g>
  </svg>
)

export default SvgEmptyPieChart
