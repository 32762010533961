import Papa from 'papaparse'
import { useCallback, useEffect, useState } from 'react'
import { readFileAsText } from '@/lib/asyncReader'

export type PreviewColumn = {
  field: string
  rows: string[]
}

export const useFilePreview = (file: File) => {
  const [preview, setPreview] = useState<PreviewColumn[]>([])

  const generatePreview = useCallback(async () => {
    const fileText = await readFileAsText(file)
    const { data } = Papa.parse<string[]>(fileText, {
      preview: 4,
    })
    const p = data[0].map((column, index) => ({
      field: column,
      // skips the first row, then stored an array of the column values
      rows: data.slice(1).map(row => row[index]),
    }))
    setPreview(p)
  }, [file])

  useEffect(() => {
    generatePreview()
  }, [generatePreview])

  return preview
}
