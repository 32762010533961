import { FC, useState } from 'react'
import { Alert, Col, FormGroup, Input, Row } from 'reactstrap'
import { Button } from '@/components/common/button'
import {
  Step,
  StepBody,
  StepFooter,
  StepFooterLeft,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { Status } from './types'

interface Props {
  transition?: string
  status: Status
  error: any
  isLoading: boolean
  handleClose: () => void
  handleConfirm: () => void
  handleGoback: () => void
}

export const BulkUploadConfirmation: FC<Props> = ({
  transition,
  status,
  error,
  isLoading,
  children,
  handleClose,
  handleConfirm,
  handleGoback,
}) => {
  const [confirmValue, setConfirmValue] = useState('')

  if (status === Status.LOADING || isLoading) {
    return (
      <Step state={transition}>
        <StepBody>
          <StepTitle>Bulk Upload Process Started</StepTitle>
          <p>
            This process could take some time, depending on the number of orders
            you've created.
          </p>
        </StepBody>
      </Step>
    )
  }

  if (status === Status.UPLOAD_SUCCESS) {
    return (
      <Step state={transition}>
        <StepBody>
          <StepTitle>Bulk Upload Finished</StepTitle>
          <p>You are good to go! Your upload is being processed.</p>
        </StepBody>
        <StepFooter>
          <StepFooterRight>
            <Button
              mode="filled"
              className="ml-auto"
              onClick={handleClose}
              css={{
                ml: 'auto',
              }}
            >
              Sounds Good
            </Button>
          </StepFooterRight>
        </StepFooter>
      </Step>
    )
  }

  return (
    <Step state={transition}>
      <StepBody>
        {status === Status.INVALID_FILE && (
          <Alert className="mt-4" color="danger">
            Invalid file. Please reference the provided template and try again.
          </Alert>
        )}

        {error && (
          <Alert className="mt-4" color="danger">
            Upload failed. ({(error as any).message})
          </Alert>
        )}

        <h5 className="mb-3">Upload Summary:</h5>
        {children}
        <p>
          If everything looks correct, type{' '}
          <code className="font-weight-bold">CONFIRM</code> into the field
          below.
        </p>
        <Row>
          <Col md="6">
            <FormGroup>
              <Input
                type="text"
                onChange={e => {
                  setConfirmValue(e.currentTarget.value.toUpperCase())
                }}
                value={confirmValue}
                placeholder="CONFIRM"
              />
            </FormGroup>
          </Col>
        </Row>
      </StepBody>
      <StepFooter>
        <StepFooterLeft>
          <Button mode="outline" onClick={handleGoback}>
            Go Back
          </Button>
        </StepFooterLeft>
        <StepFooterRight>
          <Button
            mode="filled"
            onClick={handleConfirm}
            disabled={confirmValue !== 'CONFIRM'}
          >
            Confirm
          </Button>
        </StepFooterRight>
      </StepFooter>
    </Step>
  )
}
