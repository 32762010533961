import { Button } from '@/common/button'
import { Flex, FlexWrap } from '@/common/layout'
import { useModalStore } from '@/store/modal'
import { AddFundingSourceModal } from './AddFundingSourceModal'
import { FundingSources } from './FundingSources'
import { SubAccount } from './SubAccount'

export const Payments = () => {
  const { registerModal } = useModalStore()

  const openAddFundingSourceModal = () => {
    registerModal(<AddFundingSourceModal />)
  }

  return (
    <>
      <Flex align="center" justify="between">
        <h5 className="my-2">Funding Accounts</h5>
        <FlexWrap>
          <Button size="sm" mode="filled" onClick={openAddFundingSourceModal}>
            Add Funding Source
          </Button>
        </FlexWrap>
      </Flex>
      <FundingSources />
      <SubAccount />
    </>
  )
}
