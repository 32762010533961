import { yupResolver } from '@hookform/resolvers/yup'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { FilterPill } from '@/common/filters/FilterPill'
import { SearchFilter } from '@/common/filters/SearchFilter'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex, FlexGrid } from '@/common/layout'
import { Select } from '@/common/select'
import { SimpleTable } from '@/common/table/SimpleTable'
import { Text } from '@/common/text'
import { Tooltip } from '@/common/tooltip'
import { useDebounce } from '@/hooks/useDebounce'
import { RowSelection, useOrgGroups } from '@/hooks/useOrgGroups'
import { isEmptyObject, shallowEqual } from '@/lib/validators'
import {
  useAssignUserRoles,
  useInviteAdmin,
  useOrganizationRoles,
} from '@/queries/permissions'
import { AdminRole, ScopeType } from '@/types/permissions'
import { LocationAccessColumn } from '../lib/columns'

type RoleOption = {
  value: string | null
  label: string
}

type RoleOptions = RoleOption[]

interface Props {
  handleClose: () => void
  email?: string
  isInvite?: boolean
  roles?: AdminRole[]
  userId?: number
}

export type EditAdminRolesFormValues = {
  email: string
  role: string | null | undefined
  roles: string[]
}

export const ConfigureAdminRoles: FC<Props> = ({
  isInvite = false,
  email,
  handleClose,
  roles,
  userId,
}) => {
  const [search, setSearch] = useState('')
  const groupSearch = useDebounce(search)
  const { orgGroups, initialSelectedRows } = useOrgGroups(
    groupSearch,
    isInvite,
    `${userId}` ?? ''
  )
  const [rowSelection, setRowSelection] = useState<RowSelection>(
    initialSelectedRows
  )

  const organizationRolesQuery = useOrganizationRoles()
  const assingRoles = useAssignUserRoles()
  const inviteAdmin = useInviteAdmin()

  const schema = useMemo(
    () =>
      Yup.object().shape({
        role: isInvite
          ? Yup.string().required('Role is required.')
          : Yup.string().nullable(),
        email: Yup.string()
          .required()
          .email('Must be a valid email address'),
      }),
    [isInvite]
  )

  const organizationRoles = useMemo(() => organizationRolesQuery.data || [], [
    organizationRolesQuery,
  ])

  const roleList = useMemo(() => (roles || []).map(r => r.name), [roles])

  const organizationRolesOptions = useMemo(() => {
    const initialOptions: RoleOptions = isInvite
      ? []
      : [{ value: '', label: 'Remove Roles' }]
    const options = organizationRoles.reduce((acc, item) => {
      acc.push({ value: `${item.id}`, label: item.name })
      return acc
    }, initialOptions)
    return options
  }, [organizationRoles, isInvite])

  useEffect(() => {
    if (shallowEqual(rowSelection, initialSelectedRows)) return
    setRowSelection(initialSelectedRows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelectedRows])

  const {
    watch,
    register,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      role: undefined,
      email: isInvite ? '' : email || '',
      roles: roleList,
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = ({ role, email, roles }: EditAdminRolesFormValues) => {
    const organizationGroupIDs = Object.keys(rowSelection).map(s => s)
    if (isInvite) {
      const orgRole = organizationRoles.find(r => r.id === +(role || ''))
      if (!orgRole) return
      return inviteAdmin.mutate(
        {
          email,
          organization_group_ids: organizationGroupIDs,
          role_id: orgRole.id,
          scope_type: ScopeType.ORGANIZATION,
          scope: `${orgRole.organization_id}`,
        },
        {
          onSuccess: handleClose,
        }
      )
    }
    if (!userId) return
    const roleIds = organizationRoles
      .filter(r => roles.includes(r.name))
      .map(r => r.id)
    assingRoles.mutate(
      {
        userId,
        data: {
          role_ids: roleIds,
          organization_group_ids: organizationGroupIDs,
        },
      },
      {
        onSuccess: handleClose,
      }
    )
  }

  const addRoleList = (roleId?: string) => {
    if (!roleId) return setValue('roles', [])
    const role = organizationRoles.find(r => r.id === +roleId)
    if (!role) return
    if (getValues('roles').indexOf(role.name) > -1) return
    setValue('roles', [...getValues('roles'), role.name])
  }

  const removeRoleList = (roleName: string) => {
    setValue(
      'roles',
      getValues('roles').filter(item => item !== roleName)
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexGrid direction="column" align="start">
        <Flex
          direction="column"
          align="start"
          css={{ mb: '$16', width: '100%' }}
        >
          <Label htmlFor="email">Email</Label>
          <Input
            {...register('email')}
            defaultValue={email}
            type="text"
            placeholder="Admin Email"
            disabled={!isInvite}
          />
          <Text color="alert" size="sm">
            {errors?.email?.message}
          </Text>
        </Flex>
        <Flex
          direction="column"
          align="start"
          css={{ mb: '$16', width: '100%' }}
        >
          <Label
            htmlFor="roles"
            css={{ display: 'flex', alignItems: 'center' }}
          >
            <Text css={{ mr: '$4' }}>Roles</Text>
            <Tooltip trigger={<QuestionMarkCircledIcon />}>
              Create a role if selection list is empty.
            </Tooltip>
          </Label>
          {!isInvite && (
            <>
              {watch('roles').length === 0 && (
                <Text color="alert" size="sm">
                  By removing all roles, they will no longer have access to Pay
                  Admin.
                </Text>
              )}
              <Flex css={{ my: '$4', flexWrap: 'wrap' }}>
                {watch('roles') &&
                  watch('roles').map(role => (
                    <FilterPill
                      key={role}
                      label="role"
                      value={role}
                      removeFilter={() => removeRoleList(role)}
                    />
                  ))}
              </Flex>
            </>
          )}

          <Select
            placeholder="Select Role"
            value={watch('role')}
            options={organizationRolesOptions}
            onChange={v => {
              addRoleList(v)
              clearErrors('role')
              setValue('role', v)
            }}
          />
          <Text color="alert" size="sm">
            {errors?.role?.message}
          </Text>
        </Flex>
        {orgGroups.length > 0 && (
          <Box css={{ mb: '$16' }}>
            <Text bold>Restrict Location Access</Text>
            <Text color="hint" size="sm" css={{ mb: '$16' }}>
              Limit admin's access to select locations, but if no locations are
              selected, they will have access to all locations.
            </Text>
            <SearchFilter
              placeholder="Search by location..."
              defaultValue={search}
              handleChange={v => setSearch(v || '')}
              fullWidth
            />
            <Box css={{ my: '$16' }}>
              <SimpleTable
                data={orgGroups}
                selected={rowSelection}
                setSelected={setRowSelection}
                columns={LocationAccessColumn}
                size={10}
              />
            </Box>
          </Box>
        )}

        <Button
          css={{ ml: 'auto' }}
          mode="filled"
          type="submit"
          disabled={organizationRolesQuery.isLoading || !isEmptyObject(errors)}
        >
          {isInvite ? 'Invite Admin' : 'Update'}
        </Button>
      </FlexGrid>
    </form>
  )
}
