import { FC } from 'react'
import { Button } from '@/common/button'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import InstantCardsOrderModal from '@/components/order-instant-cards/InstantCardsOrderModal'
import { useSetting } from '@/hooks/useSetting'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { InstantCardGroupRow } from '../lib/columns'
import downloadCSV from '../lib/download'
import { InstantCardOrdersFilters } from './InstantCardOrdersFilters'

interface Props {
  downloadableRows: InstantCardGroupRow[]
  error?: Error
}

export const InstantCardOrdersContainer: FC<Props> = ({
  downloadableRows,
  children,
  error,
}) => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const orderingEnabled = useSetting('ENABLE_INSTANT_CARD_ORDERING') === 'true'

  const openInstantCardsOrderModal = () => {
    registerModal(<InstantCardsOrderModal />)
  }

  const handleDownload = () => {
    downloadCSV(downloadableRows)
  }

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Instant Card Orders" />
          <PageActions>
            <Button mode="outline" onClick={handleDownload} css={{ mr: '$4' }}>
              Download
            </Button>
            {(isGlobalAdmin || orderingEnabled) && (
              <Button
                mode="filled"
                onClick={openInstantCardsOrderModal}
                iconRight
                hideIcon={!isGlobalAdmin && orderingEnabled}
              >
                Order Cards
                <AdminIcon />
              </Button>
            )}
          </PageActions>
        </PageHeader>
        <PageFilters>
          <InstantCardOrdersFilters />
        </PageFilters>
      </PageTop>
      <PageBody hasError={error}>{children}</PageBody>
    </>
  )
}
