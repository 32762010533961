import ProgressCardComplete from './ProgressCardComplete'

interface IProps {
  stepComplete?: boolean
}

const ProgressCardFooter: React.FC<IProps> = ({ children, stepComplete }) => {
  return (
    <div className="mt-5 d-flex justify-content-between align-items-center">
      <div>{stepComplete && <ProgressCardComplete />}</div>
      {children}
    </div>
  )
}

export default ProgressCardFooter
