import { AdminBadge } from '@/common/admin-badge/AdminBadge'
import {
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'

export const IntegrationContainer: React.FC = ({ children }) => {
  return (
    <SettingsContainer>
      <PageTop simple>
        <PageHeader>
          <PageTitle primary="Integration" />
        </PageHeader>
        <AdminBadge />
      </PageTop>
      <PageBody>{children}</PageBody>
    </SettingsContainer>
  )
}
