import { styled } from '@/styles/stitches.config'

export const AuthContainerContent = styled('div', {
  flex: '0 0 550px',
  height: '100vh',
  position: 'relative',
  padding: '20vh 3rem 3rem',
  '@mobile': {
    flex: 1,
    height: '100vh',
    position: 'relative',
  },
})

export const AuthLogoContainer = styled('div', {
  textAlign: 'center',
  '@mobile': {
    pb: '$24',
  },
})

export const LoginContainer = styled('div', {
  padding: '$24',
  '@mobile': {
    padding: 0,
  },
})
