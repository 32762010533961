import {
  IGetOrgSettingsResponse,
  IGetPartnerSettingsResponse,
  ISetOrgSettingsResponse,
  ISetPartnerSettingsResponse,
  OrgSettingsUpdates,
} from '@/api/types/organization'
import { PartnerSettingsUpdate } from '@/api/types/organization'
import { TOrgLocationSettingsUpdates } from '@/types/location'
import { FeeTier } from '@/types/settings'
import http from './http'
import { GetLocationSettingsResponse } from './types/location'
import { TransferFeeRangesResponse } from './types/settings'

/**
 * Retrieves location settings
 */
export const getLocationSettings = async (locationId: string | null) =>
  http.get<GetLocationSettingsResponse>(
    `p1/support/locations/${locationId}/settings`
  )

/**
 * Retrieves an organization's settings
 * @param orgId organization ID
 */
export const getOrgSettings = (orgId: number) =>
  http.get<IGetOrgSettingsResponse>(`p1/organizations/${orgId}/settings`)

/**
 * Retrieves an organization's partner settings
 * @param partner_id partner ID
 */
export const getPartnerSettings = (partner_id?: number | null) =>
  http.get<IGetPartnerSettingsResponse>(
    `p1/organizations/${partner_id}/partner_settings`
  )

/**
 * Updates organization's partner settings
 * @param partnerId Partner ID
 * @param settings whitelisted partner settings
 */
export const setPartnerSettings = (params: {
  partnerId: string
  settings: PartnerSettingsUpdate
}) =>
  http.patch<ISetPartnerSettingsResponse>(
    `p1/partners/${params.partnerId}/settings`,
    params.settings
  )

/**
 * Takes an object of whitelisted organization settings and sets them
 * @param orgId organization ID
 * @param settings whitelisted organization settings
 */
export const setOrgSettings = (orgId: number, settings: OrgSettingsUpdates) =>
  http.put<ISetOrgSettingsResponse>(
    `p1/organizations/${orgId}/settings`,
    settings
  )

export const getTransferFeeRanges = (orgId: number) =>
  http.get<TransferFeeRangesResponse>(
    `p1/organizations/${orgId}/transfer_fees/ranges`
  )

export const putTransferFeeRanges = (orgId: number, body: FeeTier) =>
  http.put(`p1/organizations/${orgId}/transfer_fees/ranges`, body)

export const deleteTransferFeeRanges = (orgId: number, body: FeeTier) =>
  http.delete(`p1/organizations/${orgId}/transfer_fees/ranges`, {
    data: body,
  })

/**
 * Takes an object of location settings and sets them
 * @param settings location settings
 */
export const setOrgLocationSettings = (
  orgKey: string,
  settings: TOrgLocationSettingsUpdates
) =>
  http.put<ISetOrgSettingsResponse>(
    `p1/support/organizations/${orgKey}/locations/all/settings`,
    settings
  )
