import { IRemittanceBalance } from '@/types/remittances'
import { TEmployeeAdjustments } from '../types'

export const mapRemittanceAdjustments = (
  adjustments: TEmployeeAdjustments,
  balance: IRemittanceBalance[]
): TEmployeeAdjustments => {
  const remittanceAdjustments: TEmployeeAdjustments = { ...adjustments }
  balance.forEach(b => {
    if (!remittanceAdjustments[b.employee_id]) {
      remittanceAdjustments[b.employee_id] = {
        amount: b.balance,
      }
    }
  })
  return remittanceAdjustments
}
