import * as ToastPrimitive from '@radix-ui/react-toast'
import { keyframes, styled } from '@stitches/react'

const VIEWPORT_PADDING = 10

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
})

export const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 400,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  outline: 'none',
  zIndex: 1000,
})

export const Toast = styled(ToastPrimitive.Root, {
  backgroundColor: 'white',
  borderRadius: '$md',
  boxShadow: '$toast',
  padding: '$16',
  display: 'grid',

  gridTemplateAreas: 'icon" "title" "description" "close"',
  gridTemplateColumns: 'auto max-content',
  columnGap: 15,
  alignItems: 'center',
  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
  },
})

export const ToastTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  fontSize: 15,
  color: '$secondary100',
})

export const ToastDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
  margin: 'auto',
  fontSize: 13,
  overflowWrap: 'break-word',
  color: '$secondary300',
  width: 250,
  fontWeight: 'bold',
})

export const ToastClose = styled(ToastPrimitive.Close, {
  gridArea: 'close',
  cursor: 'pointer',
  opacity: 1,
  border: 'none',
  color: 'black',
  backgroundColor: 'white',
})

export const ToastIcon = styled('div', {
  gridArea: 'icon',
})

export const ToastRow = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
})

export const ToastContainer = styled('div', {
  position: 'fixed',
  top: 0,
  right: 0,
  width: 450,
  zIndex: 9999,
})

export const ToastList = styled('ol', {
  boxSizing: 'border-box',
})

export const ToastItem = styled('li', {
  listStyleType: 'none',
})

export const ToastProvider = ToastPrimitive.Provider
