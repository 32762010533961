import classnames from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { Badge } from '@/common/badge'
import { ArrowRightIcon, CompleteIcon } from '@/common/icons'
import { MatchRow } from '@/components/spreadsheet-mapper/useMatchStore'
import styles from './index.module.scss'

interface Props {
  match: MatchRow
  active: boolean
  handleClick: () => void
  index: number
  lastIndex: number
}

export const MatchCard: FC<Props> = ({
  match,
  handleClick,
  active,
  index,
  lastIndex,
}) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => {
    if (active && index !== 0) {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [active, index])

  const classes = classnames(styles.MatchingRow, {
    [styles.MatchingRow_Active]: active,
  })

  const preText =
    index === 0 ? 'First' : lastIndex === index ? 'Finally' : 'Next'

  return (
    <div ref={ref} className={classes} onClick={handleClick}>
      <div className={styles.MatchingRowFlex}>
        <span className={styles.MatchInfoNumber}>{index + 1}</span>
        <div>
          <div className={styles.MatchInfo}>
            <Badge type="info" size="sm">
              {match.label}
            </Badge>
            {!!match.value && (
              <>
                <ArrowRightIcon className={styles.ArrowIcon} />
                <Badge type="info" size="sm">
                  {match.value}
                </Badge>
                <CompleteIcon className={styles.CompleteIcon} />
              </>
            )}
          </div>
          {active && (
            <p className="mt-3 mb-0 font-weight-bold">
              {preText}, select the <i>{match.label}</i> column on the right
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
