export const getSelectedText = () => {
  var text = ''
  if (typeof window.getSelection != 'undefined') {
    text = (window.getSelection() as Selection).toString()
  } else if (
    typeof (document as any).selection != 'undefined' &&
    (document as any).selection.type === 'Text'
  ) {
    text = (document as any).selection.createRange().text
  }
  return text
}
