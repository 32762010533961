import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Flex } from '@/common/layout'
import { toDollar } from '@/lib/currency'
import { InvoiceNote } from '@/types/invoice'
import { PaybackRecord } from '@/types/paybacks'
import { AmountCell } from '../components/AmountCell'
import { Note } from '../components/Note'
import { PaybackActionDropdown } from '../components/PaybackActionDropdown'
import { PaybackFundingCell } from '../components/PaybackFundingCell'
import { StatusCell } from '../components/StatusCell'
import { ViewDetailsCell } from '../components/ViewDetailsCell'

export const columns: ColumnDef<PaybackRecord, any>[] = [
  {
    header: 'ID',
    id: 'id',
    accessorKey: 'id',
  },
  {
    header: 'Invoice Date',
    id: 'time_created',
    accessorFn: row => moment(row.time_created),
    cell: row =>
      row.getValue() ? moment(row.getValue()).format('MM/DD/YYYY') : null,
  },
  {
    header: 'Description',
    id: 'description',
    accessorFn: row => row.description,
  },
  {
    header: 'Amount',
    id: 'amount',
    accessorFn: row => toDollar(row.amount),
    cell: ({ row }) => (
      <AmountCell
        original={row.original}
        value={toDollar(row.original.amount) as string}
      />
    ),
  },
  {
    header: 'Effective Date',
    id: 'bill_time',
    accessorFn: row => (row.bill_time ? moment(row.bill_time) : null),
    cell: row =>
      row.getValue() ? moment(row.getValue()).format('MM/DD/YYYY') : null,
  },
  {
    header: 'Funding Account',
    id: 'funding_source_id',
    accessorKey: 'funding_source_id',
    cell: ({ row }) => <PaybackFundingCell original={row.original} />,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    id: 'status',
    cell: ({ row }) => <StatusCell original={row.original} />,
    enableSorting: false,
  },
  {
    header: 'Details',
    id: 'viewDisbursements',
    cell: ({ row }) => <ViewDetailsCell original={row.original} />,
    enableSorting: false,
  },
  {
    header: '',
    accessorKey: 'payback_actions',
    id: 'actions',
    size: 40,
    cell: ({ row }) => (
      <Flex justify="center">
        <PaybackActionDropdown original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]

export const invoiceNotesColumns: ColumnDef<InvoiceNote, any>[] = [
  {
    header: 'Note',
    id: 'note',
    accessorKey: 'note',
    cell: ({ row }) => <Note original={row.original} />,
  },
]
