import http from './http'

/**
 * Invites a user by email to Pay Admin. Assigns them a role on registration
 */
export const invitePayAdmin = (email: string, scope: number) => {
  const orgId = scope
  return http.post(`/p1/invites?org_id=${orgId}`, {
    role_id: 2,
    scope_type: 'organization',
    scope,
    email,
  })
}
