export const typeOptions = [
  { label: 'Not Selected', value: '' },
  { label: 'Tips', value: 'DISBURSEMENT_TIPS' },
  { label: 'Trip', value: 'DISBURSEMENT_TRIP' },
  { label: 'Mileage', value: 'DISBURSEMENT_MILEAGE' },
  { label: 'Paycheck', value: 'DISBURSEMENT_PAYCHECK' },
  { label: 'Miscellaneous', value: 'DISBURSEMENT_MISCELLANEOUS' },
  { label: 'Device', value: 'DISBURSEMENT_DEVICE' },
  { label: 'Reward', value: 'DISBURSEMENT_REWARD' },
  { label: 'Delivery', value: 'DISBURSEMENT_DELIVERY' },
  { label: 'Deposit', value: 'DISBURSEMENT_DEPOSIT' },
  { label: 'Bonus', value: 'DISBURSEMENT_BONUS' },
  { label: 'Remittance', value: 'REMITTANCE' },
]
