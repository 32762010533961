import { AdoptionTimeSeriesItem } from '@/types/insights'

export interface IAdoptionInsightsData {
  firstTimeAdvances: number
  firstTimeAdvancesToDate: number
  signups: number
  signupsToDate: number
  usersSignedUpAndHaveSinceUsedPay: number
  start: { seconds: number }
}

export const parseAdoptionTimeSeriesData = (
  data: AdoptionTimeSeriesItem[]
): IAdoptionInsightsData[] =>
  data.map(d => ({
    firstTimeAdvances: d.first_time_advances || 0,
    firstTimeAdvancesToDate: d.first_time_advances_to_date || 0,
    signups: d.signups || 0,
    signupsToDate: d.signups_to_date || 0,
    usersSignedUpAndHaveSinceUsedPay:
      d.users_signed_up_and_have_since_used_pay || 0,
    start: { ...d.start },
  }))
