import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { useInvitePayAdminEmail } from '@/queries/invite-email'

interface Props {
  handleClose: () => void
}

export type TFormValues = {
  email: string
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Please supply a valid email address.'),
})

export const InviteEmailForm: FC<Props> = ({ handleClose }) => {
  const inviteEmail = useInvitePayAdminEmail()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: TFormValues) => {
    inviteEmail.mutate(values.email, {
      onSuccess: () => {
        handleClose()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Label htmlFor="email">Email</Label>
        <Input type="text" {...register('email')} />
        <Text color="alert" size="sm">
          {errors?.email?.message}
        </Text>
      </div>
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button mode="filled" type="submit" disabled={inviteEmail.isLoading}>
          Invite Pay Admin
        </Button>
      </Flex>
    </form>
  )
}
