import {
  ICreateResourceFormData,
  ICreateResourceResponse,
  IGetResourcesResponse,
} from '@/api/types/resources'
import http from './http'

/**
 * Retrieves the downloadable resources for an organization
 */
export const getResources = (orgId: number) =>
  http.get<IGetResourcesResponse>(`p1/resources?org_id=${orgId}`)

/**
 * Allows a customer success rep to create a new resource
 */
export const createResource = (
  data: ICreateResourceFormData,
  orgId: number
) => {
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('title', data.title)
  formData.append('description', data.description)

  return http.post<ICreateResourceResponse>(
    `p1/resources?org_id=${orgId}&public=${data.public}`,
    formData
  )
}

/**
 * Retrieves a downloadable asset
 */
export const downloadResource = (resourceId: number) =>
  http.get(`p1/resources/${resourceId}/download`, {
    responseType: 'blob',
  })

/**
 * Allows a customer success rep to delete a resource
 */
export const deleteResource = (resourceId: number) =>
  http.delete(`p1/resources/${resourceId}`)
