import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  assignCardProgram,
  createCardProgram,
  getCardPrograms,
  getOrgCardPrograms,
  removeCardProgram,
} from '@/api/card-program'
import { ICreateCardProgram } from '@/api/types/card-program'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

export const CARD_PROGRAMS_ORG_QUERY = 'card-programs-org-query'

export const useCardProgramsOrgQuery = () => {
  const org = useCurrentOrg()

  return useQuery([CARD_PROGRAMS_ORG_QUERY, org.id], async () => {
    const res = await getOrgCardPrograms(org.id)
    return res.data.data.map(rec => ({ ...rec, token: rec.programToken }))
  })
}

export const CARD_PROGRAMS_QUERY = 'card-programs-query'

export const useCardProgramsQuery = () => {
  return useQuery(CARD_PROGRAMS_QUERY, async () => {
    const res = await getCardPrograms()
    return res.data.data
  })
}

export const useCreateCardProgramMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: ICreateCardProgram) => createCardProgram(params),
    {
      onSuccess: () => queryClient.invalidateQueries(CARD_PROGRAMS_QUERY),
    }
  )
}

export const useAssignCardProgramMutation = () => {
  const org = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation((params: string) => assignCardProgram(org.id, params), {
    onSuccess: () =>
      queryClient.invalidateQueries([CARD_PROGRAMS_ORG_QUERY, org.id]),
  })
}

export const useRemoveCardProgramMutation = () => {
  const org = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation((params: string) => removeCardProgram(org.id, params), {
    onSuccess: () =>
      queryClient.invalidateQueries([CARD_PROGRAMS_ORG_QUERY, org.id]),
  })
}
