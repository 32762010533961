import { useToasterStore } from '@/store/toast'
import { ToastContainer, ToastItem, ToastList } from './styles'

export const Toaster = () => {
  const { toasts } = useToasterStore()
  return (
    <ToastContainer>
      <ToastList>
        {toasts.map(toast => {
          return (
            <ToastItem key={toast.id}>
              <div>{toast.component}</div>
            </ToastItem>
          )
        })}
      </ToastList>
    </ToastContainer>
  )
}
