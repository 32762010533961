import { FC } from 'react'
import { Button } from '@/components/common/button'

interface Props {
  value?: string
  handleChange: (value?: string) => void
  options: { label: string; value: any }[]
}

export const MultiButtonFilter: FC<Props> = ({
  value,
  options,
  handleChange,
}) => {
  return (
    <div className="d-flex">
      {options.map(option => (
        <Button
          key={option.label}
          mode={option.value === value ? 'filled' : 'outline'}
          onClick={() => handleChange(option.value)}
          css={{ mr: '$4' }}
        >
          {option.label}
        </Button>
      ))}
    </div>
  )
}
