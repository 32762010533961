import { FC } from 'react'
import styles from './index.module.scss'

const StepAside: FC = props => {
  return (
    <div className={styles.StepAside}>
      <div className={styles.StepTransition}>{props.children}</div>
    </div>
  )
}

export default StepAside
