import { amber, green, red } from '@radix-ui/colors'
import { createStitches } from '@stitches/react'

const spacing = {
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  16: '16px',
  24: '24px',
  32: '32px',
  48: '48px',
}

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    // tint = > 500
    // base = 500
    // shade = < 500
    // color scheme:
    // https://www.radix-ui.com/colors
    colors: {
      primary900: '#EDF1F7',
      primary700: '#ACC3E7',
      primary500: '#1F6ADB',

      secondary900: '#F8F7F9',
      secondary800: '#E8E8E8',
      secondary700: '#E2DEE7',
      secondary500: '#78769C',
      secondary300: '#554E5C',
      secondary100: '#221B29',

      warn900: amber.amber12,
      warn700: '#E7DEDE',
      warn500: '#A56D6D',
      warn300: '#7E2C2C',
      warn200: amber.amber5,
      warn100: amber.amber4,

      info900: '#cce5ff',
      info700: '#004085',

      success900: green.green12,
      success500: green.green8,
      success200: green.green5,

      danger900: red.red12,
      danger200: red.red5,

      tertiary50: '#E2EFEC',
      tertiary100: '#B6D7CF',
      tertiary200: '#86BDB0',
      tertiary300: '#55A290',
      tertiary400: '#308E78',
      tertiary500: '#0C7A60',
      tertiary600: '#0A7258',
      tertiary700: '#08674E',
      tertiary800: '#065D44',
      tertiary900: '#034A33',
    },
    space: spacing,
    sizes: spacing,
    radii: {
      sm: '4px',
      md: '8px',
      lg: '16px',
      xl: '24px',
    },
    shadows: {
      button: 'rgb(43 34 51 / 4%) 0px 1px 4px',
      focus: 'rgb(31 106 219 / 25%) 0 0 0 2px',
      toast: 'rgb(9 30 66 / 25%) 0 8px 16px -4px, rgb(9 30 66 / 31%) 0 0 1px 0',
      card: '0 7px 30px -10px rgba(150,170,180,0.5)',
      popOver:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
      popOverFocus:
        'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px $info900',
    },
    fonts: {
      nunito: 'Nunito Sans, sans-serif',
      space: 'Space Grotesk, sans-serif',
    },
  },
  media: {
    mobile: '(max-width: 640px)',
    desktop: '(max-width: 1600px)',
  },
  utils: {
    bgAlpha10: (value: string) => ({ backgroundColor: value + '1A' }),
    size: (value: number) => ({ width: value, height: value }),
    mt: (value: string) => ({
      marginTop: value,
    }),
    mr: (value: string) => ({
      marginRight: value,
    }),
    mb: (value: string) => ({
      marginBottom: value,
    }),
    ml: (value: string) => ({
      marginLeft: value,
    }),
    mx: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    pt: (value: string) => ({
      paddingTop: value,
    }),
    pr: (value: string) => ({
      paddingRight: value,
    }),
    pb: (value: string) => ({
      paddingBottom: value,
    }),
    pl: (value: string) => ({
      paddingLeft: value,
    }),
    px: (value: string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    br: (value: string) => ({ borderRadius: value }),
  },
})
