import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useRemoveUser, useVerifyUser } from '@/queries/employees'
import { useModalStore } from '@/store/modal'
import { IEnrolledUser } from '@/types/employees'
import { EditRosterUserModal } from './EditRosterUserModal'
import { VerifyUserModal } from './VerifyUserModal'

interface Props {
  user: IEnrolledUser
}

export const EnrolledActions: FC<Props> = ({ user: record }) => {
  const { first_name, last_name, user, employee_id } = record
  const removeUser = useRemoveUser()
  const verifyUser = useVerifyUser()
  const { registerModal } = useModalStore()

  const name = `${first_name} ${last_name}`

  const openRemoveModal = () => {
    registerModal(
      <AlertDialog
        title="Revoke Access"
        actionDescription="Confirm"
        isDestructive={true}
        handleAction={() =>
          removeUser.mutateAsync({
            id: `${user.id}`,
            firstName: first_name,
            lastName: last_name,
          })
        }
        description={`${name} will no longer be able to use your organization's branch account`}
      />
    )
  }

  const openEditRosterUserModal = () => {
    registerModal(<EditRosterUserModal user={record} />)
  }

  const openVerifyModal = () => {
    registerModal(
      <VerifyUserModal
        name={name}
        handleSubmit={employeeId => {
          verifyUser.mutate({
            id: `${user.id}`,
            firstName: first_name,
            lastName: last_name,
            employeeId,
            previousId: employee_id,
          })
        }}
      />
    )
  }

  return (
    <TableActionDropdown
      menu={[
        { label: 'Change ID', handleClick: openVerifyModal },
        { label: 'Edit roster record', handleClick: openEditRosterUserModal },
        { label: 'Revoke access', handleClick: openRemoveModal },
      ]}
    />
  )
}
