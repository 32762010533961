import { CheckIcon } from '@radix-ui/react-icons'
import { FC, MouseEvent } from 'react'
import { Container, Label, StyledCheckbox, StyledIndicator } from './styles'

interface Props {
  id: string
  checked?: boolean
  onChange?: (id: string, value: boolean) => void
  nativeClickFn?: (e: MouseEvent<HTMLButtonElement>) => void
  label?: string
  disabled?: boolean
}

// nativeClickFn for instances where you want to receive MouseEvent<HTMLButtonElement> as a variable
// useful mainly for onClickFn for select all rows checkbox for tanstack-table

export const Checkbox: FC<Props> = ({
  id,
  checked,
  label,
  disabled,
  onChange,
  nativeClickFn,
}) => (
  <Container>
    <StyledCheckbox
      id={id}
      checked={checked}
      onCheckedChange={v => onChange && onChange(id, v as boolean)}
      disabled={disabled}
      onClick={e => nativeClickFn && nativeClickFn(e)}
    >
      <StyledIndicator>
        <CheckIcon />
      </StyledIndicator>
    </StyledCheckbox>
    {label && <Label htmlFor={id}>{label.toLowerCase()}</Label>}
  </Container>
)
