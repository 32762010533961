import { useCallback, useEffect } from 'react'

const useOutsideClickHander = (
  ref: React.RefObject<HTMLElement> | Element | null,
  onOutsideClick: (e: MouseEvent) => void,
  active: boolean = true
) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (ref) {
        let reference = ref instanceof Element ? ref : ref.current
        if (reference && !reference.contains(e.target as Node)) {
          onOutsideClick(e)
        }
      }
    },
    [ref, onOutsideClick]
  )

  useEffect(() => {
    if (active) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [handleClickOutside, active])
}

export default useOutsideClickHander
