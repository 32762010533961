import { CheckCircledIcon, PauseIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { InstantCardRow } from '../lib/columns'
import useStatusMetrics from '../lib/useStatusMetrics'

interface Props {
  cards: InstantCardRow[]
}

export const InstantCardsStats: FC<Props> = ({ cards }) => {
  const statusMetrics = useStatusMetrics(cards)

  return (
    <FlexWrap css={{ pb: '$12' }}>
      <Flex css={{ padding: '$4' }}>
        <CheckCircledIcon style={{ color: 'green', transform: 'scale(1.5)' }} />
        <Text css={{ ml: '$12' }}>
          {`Claimed ${statusMetrics.claimedPercentage}% of ${statusMetrics.claimedCount}/${cards.length}`}
        </Text>
      </Flex>
      <Flex css={{ padding: '$4' }}>
        <PauseIcon style={{ color: 'red', transform: 'scale(1.5)' }} />
        <Text css={{ ml: '$12' }}>
          {`Unclaimed ${statusMetrics.unclaimedPercentage}% of ${statusMetrics.unclaimedCount}/${cards.length}`}
        </Text>
      </Flex>
    </FlexWrap>
  )
}
