import { useMemo } from 'react'
import { Alert } from '@/common/alert'
import { useFundingSources } from '@/queries/funding-sources'

export const FundingSourceAlert = () => {
  const fundingSources = useFundingSources()
  const hasFundingSources = useMemo(
    () => !fundingSources.isFetched || !!fundingSources.data?.length,
    [fundingSources.isFetched, fundingSources.data]
  )

  return !hasFundingSources ? (
    <Alert type="warning" css={{ mb: '$24' }}>
      Heads up! You'll need to add a funding source to issue disbursements.
    </Alert>
  ) : null
}
