import { FC, useMemo } from 'react'
import { useFundingSources } from '@/queries/funding-sources'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  original: PaybackRecord
}

export const PaybackFundingCell: FC<Props> = ({ original }) => {
  const { data } = useFundingSources()

  const fundingSource = useMemo(
    () => (data || []).find(rec => rec.id === original.funding_source_id),
    [data, original]
  )

  if (!fundingSource) return <span>-</span>

  return (
    <span>
      {fundingSource.name} - {fundingSource.id}
    </span>
  )
}
