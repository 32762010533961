import { TableIcon } from '@radix-ui/react-icons'
import { ElementType } from 'react'
import {
  DirectDepositIcon,
  FileProcessingIcon,
  IntegrationIcon,
  OnboardingIcon,
  OrgLocationIcon,
  RolesPermissionsIcon,
  SupportIcon,
  YourCompanyIcon,
} from '@/common/icons'
import { ROUTES } from '@/router/routes'

interface Props {
  isStaging: boolean
  hasPartnerOrg: boolean
}

export type SettingsNavItemType = {
  label: string
  route: string
  icon: ElementType
  isAdmin?: boolean
  isHidden?: boolean
}

export const SETTINGS_NAV = ({
  isStaging,
  hasPartnerOrg,
}: Props): SettingsNavItemType[] => [
  {
    label: 'Your Company',
    route: ROUTES.SETTINGS,
    icon: YourCompanyIcon,
  },
  {
    label: 'Support',
    route: ROUTES.SUPPORT_SETTINGS,
    icon: SupportIcon,
  },
  {
    label: 'Table Configuration',
    route: ROUTES.TABLE_CONFIG,
    icon: () => (
      <TableIcon
        style={{
          transform: 'scale(1.4)',
        }}
      />
    ),
  },
  {
    label: 'Worker Onboarding',
    route: ROUTES.ONBOARDING_SETTINGS,
    icon: OnboardingIcon,
  },
  {
    label: 'Roles & Permissions',
    route: ROUTES.ROLES_PERMISSIONS_SETTINGS,
    icon: RolesPermissionsIcon,
  },
  {
    label: 'Worker Payments',
    route: ROUTES.PAYMENTS_SETTINGS,
    icon: DirectDepositIcon,
  },
  {
    label: 'Webhook Testing',
    route: ROUTES.WEBHOOK_TESTING,
    icon: IntegrationIcon,

    isHidden: !(isStaging && hasPartnerOrg),
  },
  {
    label: 'Integration',
    route: ROUTES.INTEGRATION,
    icon: IntegrationIcon,

    isAdmin: true,
  },
  {
    label: 'File Processing',
    route: ROUTES.FILE_PROCESSING_SETTINGS,
    icon: FileProcessingIcon,

    isAdmin: true,
  },
  {
    label: 'SSO Configuration',
    route: ROUTES.SSO_CONFIG,
    icon: OnboardingIcon,

    isAdmin: true,
  },
  {
    label: 'Webhooks',
    route: ROUTES.WEBHOOKS,
    icon: IntegrationIcon,

    isAdmin: true,
  },
  {
    label: 'Invoicing',
    route: ROUTES.INVOICE_CONFIGURATIONS,
    icon: DirectDepositIcon,

    isAdmin: true,
  },
  {
    label: 'Organization Location Settings',
    route: ROUTES.ORG_LOCATION_SETTINGS,
    icon: OrgLocationIcon,

    isAdmin: true,
  },
  {
    label: 'Embedded Onboarding',
    route: ROUTES.EMBEDDED_ONBOARDING,
    icon: OnboardingIcon,

    isAdmin: true,
  },
]
