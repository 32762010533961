import { FC, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Box, FlexWrap } from '@/common/layout'
import { Loader } from '@/common/loader'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import {
  IPayrollRecord,
  PAYROLL_QUERY,
  useCreatePayPeriod,
  usePayroll,
} from '@/queries/payroll'
import { useToasterStore } from '@/store/toast'
import { formatPayroll } from '../lib/generate-payroll'
import { PayrollGenerationForm } from './PayrollGenerationForm'
import { PayRollRecords } from './PayrollRecords'

export const PayrollAdmin: FC = () => {
  const [pendingPayroll, setPendingPayroll] = useState<IPayrollRecord[]>([])
  const payrollData = usePayroll()
  const createPayPeriod = useCreatePayPeriod()
  const { addToast } = useToasterStore()
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(true)

  const savePendingPayRoll = async () => {
    setErrorMessage(null)
    const payPeriodRequest = pendingPayroll.map(value => {
      return createPayPeriod.mutateAsync(value)
    })
    const results = await Promise.allSettled(payPeriodRequest)
    const failed: PromiseRejectedResult[] = []
    results.forEach((result, i) => {
      if (result.status === 'fulfilled') pendingPayroll.splice(i, 1)
      else failed.push(result)
    })
    if (failed.length > 0) {
      setPendingPayroll([...pendingPayroll])
      setErrorMessage(
        `Pay periods still in pending failed to be created: ${failed[0].reason?.response?.data?.meta?.errorDetail}`
      )
    } else {
      setPendingPayroll([])
      addToast({
        type: 'success',
        title: 'Success',
        description: `Payroll periods created`,
      })
    }
    queryClient.invalidateQueries([PAYROLL_QUERY])
  }

  const resetPendingPayroll = () => {
    setPendingPayroll([])
    setErrorMessage(null)
  }

  const payroll = useMemo(
    () => formatPayroll(payrollData.data?.payroll_periods),
    [payrollData]
  )

  return (
    <Dialog open={open} setOpen={setOpen} title="Manage Payroll">
      <Box>
        {errorMessage ? (
          <Alert type="danger" css={{ mb: '$12' }}>
            {errorMessage}
          </Alert>
        ) : null}
        <PayrollGenerationForm setPendingPayroll={setPendingPayroll} />

        {pendingPayroll.length > 0 ? (
          <FlexWrap
            size="lg"
            css={{ pb: '$16' }}
            direction="column"
            align="start"
          >
            <Text size="xl">Pending Payroll</Text>
            <PayRollRecords
              payRoll={pendingPayroll}
              setPendingPayroll={setPendingPayroll}
              saveState="pending"
            />
            <FlexWrap size="lg" justify="end" css={{ width: '100%' }}>
              <Button mode="flat" onClick={resetPendingPayroll}>
                Cancel
              </Button>
              <Button
                mode="outline"
                onClick={savePendingPayRoll}
                disabled={createPayPeriod.isLoading}
              >
                Save
              </Button>
            </FlexWrap>
          </FlexWrap>
        ) : null}
        <FlexWrap size="lg" direction="column" align="start">
          <Text size="xl">Existing Payroll Periods</Text>
          <Loader loading={payrollData.isLoading}>
            <PayRollRecords
              payRoll={payroll}
              setPendingPayroll={setPendingPayroll}
              saveState="save"
            />
          </Loader>
        </FlexWrap>
      </Box>
    </Dialog>
  )
}
