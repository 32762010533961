import { useMemo } from 'react'
import { Box, FlexGrid } from '@/common/layout'
import { Loader } from '@/common/loader'
import { useResources } from '@/queries/resources'
import { useResourcesStore } from '@/store/resources'
import NoResourcesCard from './components/NoResourcesCard'
import { ResourceCard } from './components/ResourceCard'
import { ResourcesContainer } from './components/ResourcesContainer'

export const Resources = () => {
  const { filters } = useResourcesStore()
  const resources = useResources()

  const filteredResources = useMemo(() => {
    return (resources.data || []).filter(resource => {
      if (filters.type === 'marketing') {
        return !resource.public
      } else if (filters.type === 'brand') {
        return resource.public
      } else {
        return true
      }
    })
  }, [resources, filters])

  return (
    <ResourcesContainer>
      <Loader loading={resources.isLoading}>
        {filteredResources.length === 0 && <NoResourcesCard />}
        <FlexGrid
          align="start"
          css={{ flexWrap: 'wrap', padding: 'none', gap: 0 }}
        >
          {filteredResources.map(resource => (
            <Box
              css={{
                width: '25%',
                padding: '$8',
                '@mobile': { width: '100%' },
              }}
              key={resource.id}
            >
              <ResourceCard resource={resource} />
            </Box>
          ))}
        </FlexGrid>
      </Loader>
    </ResourcesContainer>
  )
}
