import { ColumnDef } from '@tanstack/table-core'
import { PayRollDate } from '../lib/generate-payroll'

export const payRollColumns: ColumnDef<PayRollDate, any>[] = [
  {
    header: 'Payroll Deadline',
    accessorKey: 'deadline',
    id: 'payroll_deadline',
    cell: row => row.getValue().format('ll'),
  },
  {
    header: 'Pay Date',
    accessorKey: 'payDate',
    id: 'pay_date',
    cell: row => row.getValue().format('ll'),
  },
]
