import { IRegistration } from '@/types/settings'

export enum RegistrationType {
  s3 = 's3',
  email = 'email',
}

export type IRegistrationRow = IRegistration & {
  type: RegistrationType
}
