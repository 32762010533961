import { MagnifyingGlassIcon, PlusIcon } from '@radix-ui/react-icons'
import { FC, useMemo } from 'react'
import { useState } from 'react'
import { Button } from '@/common/button'
import { Input, InputIcon } from '@/common/input'
import { Flex } from '@/common/layout'
import { ScrollArea } from '@/common/scroll-area'
import { Tooltip } from '@/common/tooltip'
import { useUserOrganizationsQuery } from '@/queries/auth'
import { IOrganization } from '@/types/organization'
import { useCurrentOrg } from '../auth/hooks/useCurrentOrg'
import {
  OrgDropDownContainer,
  OrgDropDownContainerVariants,
  OrgDropDownItem,
  OrgItemSubTitle,
} from './styles'

interface Props {
  isGlobalAdmin: boolean
  addOrganization?: () => void
  onSelect: (organization: IOrganization) => void
  excludeCurrentOrg?: boolean
  size?: OrgDropDownContainerVariants['size']
  isOnNavbar?: boolean
}

export const OrganizationDropdown: FC<Props> = ({
  isGlobalAdmin,
  excludeCurrentOrg,
  addOrganization,
  onSelect,
  size,
  isOnNavbar,
}) => {
  const currentOrg = useCurrentOrg()
  const orgs = useUserOrganizationsQuery()

  const [filterValue, setFilterValue] = useState('')
  const hasMultipleOrgs = orgs.data && orgs.data.length > 1

  const orgList = useMemo(() => {
    const allOrgs = orgs.data || []
    let final: IOrganization[] = []
    let inactiveItems: IOrganization[] = []
    let sortableItems: IOrganization[] = []
    allOrgs.forEach((o: IOrganization) => {
      if (o.id === currentOrg.id && !excludeCurrentOrg) {
        return final.push(o)
      }
      if (o.name.includes('INACTIVE')) {
        return inactiveItems.push(o)
      }
      return sortableItems.push(o)
    })
    sortableItems = sortableItems.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    )
    final = [...final, ...sortableItems, ...inactiveItems]

    return final
  }, [orgs.data, currentOrg, excludeCurrentOrg])

  const filtered = useMemo(() => {
    return orgList.filter(org => {
      const search = `${org.id} ${org.name} ${org.org_key}`.toLowerCase()
      return search.includes(filterValue.toLowerCase())
    })
  }, [orgList, filterValue])

  if (!hasMultipleOrgs) {
    return null
  }

  return (
    <>
      <Flex css={{ width: '100%', padding: '$4' }} align="center">
        <InputIcon>
          <MagnifyingGlassIcon style={{ height: 20, width: 20 }} />
        </InputIcon>
        <Input
          onChange={e => {
            setFilterValue(e.target.value)
          }}
          autoFocus={isOnNavbar}
          placeholder="Find by name, key, or ID"
          iconLeft
        />
        {isGlobalAdmin && addOrganization && (
          <Tooltip
            trigger={
              <Button
                onClick={addOrganization}
                size="lg"
                css={{ backgroundColor: 'transparent' }}
                mode="flat"
              >
                <PlusIcon style={{ height: 20, width: 20 }} />
              </Button>
            }
          >
            Create Organization
          </Tooltip>
        )}
      </Flex>
      <OrgDropDownContainer size={size}>
        <ScrollArea>
          {filtered.map(organization => (
            <OrgDropDownItem
              key={organization.id}
              onClick={() => onSelect(organization)}
            >
              {organization.name}
              <OrgItemSubTitle>
                {organization.org_key}&nbsp;&nbsp;|&nbsp;&nbsp;
                {organization.id}
              </OrgItemSubTitle>
            </OrgDropDownItem>
          ))}
        </ScrollArea>
      </OrgDropDownContainer>
    </>
  )
}
