import moment from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { DateRangePreset } from '@/common/datepicker/types'
import { getPresetDateRange } from '@/common/datepicker/utils'
import { getQueryParamObjectFromString } from '@/lib/queryParams'
import {
  FILTER_KEY_PREFIX,
  prefixFilterKey,
  stripPrefixFromFilterKey,
} from './lib/utils'
import { TableFilters } from './types'

export const useQueryParamFilters = (
  initFilters: TableFilters
): TableFilters => {
  const location = useLocation()
  const hasDateRangeFilters =
    initFilters.from || initFilters.to || initFilters.preset

  return useMemo(() => {
    let filters: TableFilters = hasDateRangeFilters
      ? initFilters
      : getPresetDateRange(DateRangePreset.LAST_MONTH)

    if (!location.search) return filters

    const searchParams: any = getQueryParamObjectFromString(location.search)

    if (!searchParams) return filters

    Object.keys(searchParams)
      .filter(key => {
        const hasPrefix = key.startsWith(FILTER_KEY_PREFIX)
        const keyInBlacklist = [
          prefixFilterKey('from'),
          prefixFilterKey('to'),
          prefixFilterKey('preset'),
        ].includes(key)
        return hasPrefix && !keyInBlacklist
      })
      .forEach(key => {
        const filter = searchParams[key]
        filters[stripPrefixFromFilterKey(key)] = filter
      })

    const from = searchParams[prefixFilterKey('from')]
    const to = searchParams[prefixFilterKey('to')]
    const preset = searchParams[prefixFilterKey('preset')]

    const dateRegex = /\d{4}-\d{2}-\d{2}/g

    if (preset) {
      const presetRange = getPresetDateRange(preset)
      filters.from = presetRange.from
      filters.to = presetRange.to
      filters.preset = preset
    }

    if (from && from.match(dateRegex) && to && to.match(dateRegex)) {
      filters.from = moment(from).startOf('day')
      filters.to = moment(to).endOf('day')
      filters.preset = undefined
    }

    return filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
