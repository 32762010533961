import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@/router/routes'
import { CompleteRemittance } from './CompleteRemittance'
import { DeductionReportDetails } from './DeductionReportDetails'

export const DeductionReport: FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.REMITTANCE_COMPLETE}
      component={CompleteRemittance}
    />
    <Route exact path={ROUTES.REMITTANCE} component={DeductionReportDetails} />
  </Switch>
)
