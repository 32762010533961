import { FC } from 'react'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import InstantCardsBulkOrderModal from '@/components/order-instant-cards/InstantCardsBulkOrderModal'
import InstantCardsOrderModal from '@/components/order-instant-cards/InstantCardsOrderModal'
import { useSetting } from '@/hooks/useSetting'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { InstantCard } from '@/types/instant-cards'
import { InstantCardFilters } from './InstantCardFilters'

interface Props {
  downloadableRows: InstantCard[]
  error?: Error
}

export const InstantCardsContainer: FC<Props> = ({
  children,
  downloadableRows,
  error,
}) => {
  const { registerModal } = useModalStore()
  const orderingEnabled = useSetting('ENABLE_INSTANT_CARD_ORDERING') === 'true'
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const openInstantCardsOrderModal = () => {
    registerModal(<InstantCardsOrderModal />)
  }

  const openInstantCardsBulkOrderModal = () => {
    registerModal(<InstantCardsBulkOrderModal />)
  }

  const createMenu = [
    {
      label: 'Bulk Upload',
      handleClick: openInstantCardsBulkOrderModal,
    },
    {
      label: 'Create Single',
      handleClick: openInstantCardsOrderModal,
    },
  ]

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Instant Cards" />
          <PageActions>
            {(isGlobalAdmin || orderingEnabled) && (
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <Button
                    mode="filled"
                    iconRight
                    hideIcon={!isGlobalAdmin && orderingEnabled}
                  >
                    Order Cards
                    <AdminIcon />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownContent>
                  {createMenu.map(option => {
                    return (
                      <DropdownMenuItem
                        onClick={option.handleClick}
                        key={option.label}
                      >
                        {option.label}
                      </DropdownMenuItem>
                    )
                  })}
                </DropdownContent>
              </DropdownMenu>
            )}
          </PageActions>
        </PageHeader>
        <PageFilters>
          <InstantCardFilters downloadableRows={downloadableRows} />
        </PageFilters>
      </PageTop>
      <PageBody hasError={error}>{children}</PageBody>
    </>
  )
}
