import classnames from 'classnames'
import { forwardRef } from 'react'
import styles from './index.module.scss'

type Props = React.HTMLAttributes<HTMLDivElement>

const SearchDropdownContainer = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { className, ...rest } = props
    const classes = classnames(styles.SearchDropdownContainer, className)

    return (
      <div ref={ref} className={classes} {...rest}>
        {props.children}
      </div>
    )
  }
)

export default SearchDropdownContainer
