import { Support } from './components/Support'
import { SupportContainer } from './components/SupportContainer'

export const SupportSettings = () => {
  return (
    <SupportContainer>
      <Support />
    </SupportContainer>
  )
}
