import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  addInvoiceNote,
  addInvoiceTransition,
  createInvoiceConfig,
  createInvoiceConfigMapping,
  deleteInvoiceConfig,
  deleteInvoiceConfigMapping,
  deleteInvoiceNote,
  editInvoice,
  getInvoiceConfigs,
  getInvoiceNote,
  issueInvoice,
  updateInvoiceConfiguration,
} from '@/api/invoice-configs'
import {
  CreateInvoiceConfigMapping,
  CreateInvoiceConfiguration,
  EditInvoice,
  InvoiceParams,
  InvoiceTransition,
  UpdateInvoiceConfiguration,
} from '@/api/types/invoice'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { PAYBACKS_QUERY } from '@/queries/payouts'
import { useToasterStore } from '@/store/toast'

const INVOICE_CONFIGS_QUERY = 'invoice-configs'

export const useInvoiceConfigs = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([INVOICE_CONFIGS_QUERY, orgId], async () => {
    const res = await getInvoiceConfigs(orgId)
    return res.data
  })
}

export const useCreateInvoiceConfig = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateInvoiceConfiguration) => {
      const res = await createInvoiceConfig(orgId, data)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('invoices', 'create-invoice-config')
        queryClient.invalidateQueries([INVOICE_CONFIGS_QUERY, orgId])
      },
    }
  )
}

type UpdateInvoiceConfigurationProps = {
  configId: number
  data: UpdateInvoiceConfiguration
}

export const useUpdateInvoiceConfig = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async ({ configId, data }: UpdateInvoiceConfigurationProps) => {
      const res = await updateInvoiceConfiguration(orgId, configId, data)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('invoices', 'update-invoice-config')
        queryClient.invalidateQueries([INVOICE_CONFIGS_QUERY, orgId])
      },
    }
  )
}

export const useDeleteInvoiceConfig = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (configId: number) => {
      const res = await deleteInvoiceConfig(orgId, configId)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('invoices', 'delete-invoice-config')
        queryClient.invalidateQueries([INVOICE_CONFIGS_QUERY, orgId])
      },
    }
  )
}

type CreateInvoiceConfigMappingProps = {
  configId: number
  data: CreateInvoiceConfigMapping
}

export const useCreateInvoiceConfigMapping = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async ({ configId, data }: CreateInvoiceConfigMappingProps) => {
      const res = await createInvoiceConfigMapping(orgId, configId, data)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('invoices', 'create-invoice-config-mapping:success')
        queryClient.invalidateQueries([INVOICE_CONFIGS_QUERY, orgId])
      },
    }
  )
}

type DeleteInvoiceConfigMappingProps = {
  configId: number
  mappingId: number
}

export const useDeleteInvoiceConfigMapping = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async ({ configId, mappingId }: DeleteInvoiceConfigMappingProps) => {
      const res = await deleteInvoiceConfigMapping(orgId, configId, mappingId)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('invoices', 'delete-invoice-config:success')
        queryClient.invalidateQueries([INVOICE_CONFIGS_QUERY, orgId])
      },
    }
  )
}

export const useIssueinvoice = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async (body: InvoiceParams) => {
      const res = await issueInvoice(body, orgId)
      return res.data
    },
    {
      onSuccess: (_, { description, funding_source_id }) => {
        sendAnalyticsEvent('invoices', 'issue-invoice:success', {
          description,
          funding_source_id,
        })
        addToast({
          type: 'success',
          title: 'Invoice request complete.',
        })
        queryClient.invalidateQueries(PAYBACKS_QUERY)
      },
      onError: () => {
        sendAnalyticsEvent('invoices', 'issue-invoice:failure')
        addToast({
          type: 'error',
          title: 'Could not issue Invoice.',
        })
      },
    }
  )
}

interface InvoiceTransitionRequest extends InvoiceTransition {
  invoiceId: number
}

export const useInvoiceTransition = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async ({ invoiceId, ...rest }: InvoiceTransitionRequest) => {
      const res = await addInvoiceTransition(invoiceId, rest, orgId)
      return res.data
    },
    {
      onSuccess: (_, { invoiceId, status }) => {
        sendAnalyticsEvent('invoices', 'transition-invoice:success', {
          id: invoiceId,
          status,
        })
        queryClient.invalidateQueries([PAYBACKS_QUERY])
        addToast({
          type: 'success',
          title: 'Invoice Transition Complete.',
        })
      },
      onError: () => {
        sendAnalyticsEvent('invoices', 'transition-invoice:failure')
        addToast({
          type: 'error',
          title: 'Could not complete invoice transition.',
        })
      },
    }
  )
}

interface EditInvoiceRequestBody extends EditInvoice {
  invoiceId: number
}

export const useEditInvoice = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async ({ invoiceId, ...rest }: EditInvoiceRequestBody) => {
      const res = await editInvoice(invoiceId, rest, orgId)
      return res.data
    },
    {
      onSuccess: (_, { invoiceId }) => {
        sendAnalyticsEvent('invoices', 'edit-invoice:success', {
          id: invoiceId,
        })
        queryClient.invalidateQueries([PAYBACKS_QUERY])
        addToast({
          type: 'success',
          title: 'Edit invoice successful',
        })
      },
      onError: () => {
        sendAnalyticsEvent('invoices', 'edit-invoice:failure')
        addToast({
          type: 'error',
          title: 'Could not update invoice',
        })
      },
    }
  )
}

const INVOICE_NOTE_QUERY = 'invoice-note'

type AddInvoiceNote = {
  invoiceId: number
  note: string
}

type DeleteInvoiceNote = {
  invoiceId: number
  noteId: number
}

export const useInvoiceNote = (invoiceId: number) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([INVOICE_NOTE_QUERY, orgId, invoiceId], async () => {
    const res = await getInvoiceNote(orgId, invoiceId)
    return res.data
  })
}

export const useAddInvoiceNote = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async ({ invoiceId, note }: AddInvoiceNote) => {
      const res = await addInvoiceNote(orgId, invoiceId, note)
      return res.data
    },
    {
      onSuccess: (_, { invoiceId }) => {
        sendAnalyticsEvent('invoices', 'add-invoice-note:success', {
          id: invoiceId,
        })
        queryClient.invalidateQueries([INVOICE_NOTE_QUERY, orgId, invoiceId])
        addToast({
          type: 'success',
          title: 'Add invoice note successful',
        })
      },
      onError: () => {
        sendAnalyticsEvent('invoices', 'add-invoice-note:failure')
        addToast({
          type: 'error',
          title: 'Could not add invoice note',
        })
      },
    }
  )
}

export const useDeleteInvoiceNote = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async ({ invoiceId, noteId }: DeleteInvoiceNote) => {
      const res = await deleteInvoiceNote(orgId, invoiceId, noteId)
      return res.data
    },
    {
      onSuccess: (_, { invoiceId }) => {
        sendAnalyticsEvent('invoices', 'delete-invoice-note:success', {
          id: invoiceId,
        })
        queryClient.invalidateQueries([INVOICE_NOTE_QUERY, orgId, invoiceId])
        addToast({
          type: 'success',
          title: 'Delete invoice note successful',
        })
      },
      onError: () => {
        sendAnalyticsEvent('invoices', 'delete-invoice-note:failure')
        addToast({
          type: 'error',
          title: 'Could not delete invoice note',
        })
      },
    }
  )
}
