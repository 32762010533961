import { CalendarIcon } from '@radix-ui/react-icons'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { FC } from 'react'
import { Button } from '@/common/button'
import { Popover } from '@/common/popover/Popover'
import { DateRangePickerPopup } from './DateRangePickerPopup'
import { DateRangePreset } from './types'
import { getRangeText } from './utils'

interface Props {
  from?: moment.Moment
  to?: moment.Moment
  preset?: DateRangePreset
  allowedPresets?: DateRangePreset[]
  setRange: (range: {
    from?: moment.Moment
    to?: moment.Moment
    preset?: DateRangePreset
  }) => void
}

export const DateRangePicker: FC<Props> = ({
  from,
  to,
  preset,
  setRange,
  allowedPresets,
}) => {
  const rangeText = useMemo(
    () => getRangeText({ start: from, end: to, preset }),
    [from, to, preset]
  )

  const [popoverOpen, setPopoverOpen] = useState(false)

  return (
    <Popover
      trigger={
        <Button mode="outline" color="black" iconLeft>
          {rangeText}
          <CalendarIcon
            style={{ marginLeft: '8px', transform: 'scale(1.5)' }}
          />
        </Button>
      }
      showClose
      open={popoverOpen}
      setOpen={setPopoverOpen}
      withPortalComponent={false}
    >
      <DateRangePickerPopup
        from={from}
        to={to}
        preset={preset}
        setRange={setRange}
        setOpen={setPopoverOpen}
        allowedPresets={allowedPresets}
      />
    </Popover>
  )
}
