import { FC, useMemo } from 'react'
import { Box } from '@/common/layout'
import { SimpleTable } from '@/common/table/SimpleTable'
import { useOrganizationRoles } from '@/queries/permissions'
import { columns } from '../lib/columns'

export const RolesTable: FC = () => {
  const organizationRolesQuery = useOrganizationRoles()

  const roles = useMemo(() => organizationRolesQuery.data || [], [
    organizationRolesQuery,
  ])
  return (
    <Box css={{ backgroundColor: 'white', borderRadius: '$sm', padding: '$8' }}>
      <SimpleTable data={roles} columns={columns} hideLastColumnBorder />
    </Box>
  )
}
