export const columns = [
  {
    header: 'Name',
    accessorKey: 'location_name',
  },
  {
    header: 'Alternate Name',
    accessorKey: 'alternate_name',
  },
  {
    header: 'Address',
    accessorKey: 'address',
  },
  {
    header: 'City',
    accessorKey: 'city',
  },
  {
    header: 'State',
    accessorKey: 'state',
  },
  {
    header: 'Zip',
    accessorKey: 'zip',
  },
  {
    header: 'Country',
    accessorKey: 'country',
  },
]
