import { ColumnDef } from '@tanstack/react-table'
import { Flex } from '@/common/layout'
import { RemittanceStatusBadge } from '@/components/deduction-report/components/RemittanceStatusBadge'
import { toDollar } from '@/lib/currency'
import { IRemittance } from '@/types/remittances'
import { DeductionReportActionsCell } from '../components/DeductionReportActionsCell'
import { ViewInvoiceLink } from '../components/ViewInvoiceLink'

export const columns: ColumnDef<IRemittance, any>[] = [
  {
    header: 'ID',
    id: 'id',
    accessorFn: row => row.id,
    enableSorting: false,
  },
  {
    header: 'Payroll Pay Date',
    id: 'pay_date',
    accessorFn: row => row.pay_date,
    enableSorting: false,
  },
  {
    header: 'Requested Amount',
    id: 'requested_amount',
    accessorFn: row => toDollar(row.requested_amount),
    enableSorting: false,
  },
  {
    header: 'Confirmed Amount',
    id: 'actual_amount',
    accessorFn: row => toDollar(row.actual_amount),
    enableSorting: false,
  },
  {
    header: 'Invoice ID',
    id: 'payback_id',
    accessorFn: row => row.payback_id,
    cell: ({ row }) => <ViewInvoiceLink original={row.original} />,
    enableSorting: false,
  },
  {
    header: 'Status',
    id: 'status',
    accessorFn: row => row.status,
    cell: ({ row }) => <RemittanceStatusBadge value={row.original.status} />,
    enableSorting: false,
  },
  {
    header: '',
    id: 'actions',
    cell: ({ row }) => (
      <Flex justify="center">
        <DeductionReportActionsCell original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]
