import { FC } from 'react'
import { Badge, BadgeTypes } from '@/common/badge'
import { DEDUCTION_STATUS_LABELS } from '@/lib/statusLabels'
import { DeductionStatus } from '@/types/remittances'

interface Props {
  value: DeductionStatus
}

const getBadgeColor = (value: DeductionStatus): BadgeTypes => {
  switch (value) {
    case 'outstanding':
    case 'pb paid':
    case 'bonus complete':
    case 'po fail':
    case 'pb fail':
    case 'ded requested':
      return 'info'
    case 'ded failed':
    case 'ded retry':
      return 'warning'
    case 'ded completed':
      return 'success'
    default:
      return 'info'
  }
}

export const DeductionStatusBadge: FC<Props> = ({ value }) => {
  const label = DEDUCTION_STATUS_LABELS[value]
  const badgeColor = getBadgeColor(value)

  return <Badge type={badgeColor}>{label}</Badge>
}
