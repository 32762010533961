import { SSOConfig } from './components/SSOConfig'
import { SSOConfigurationContainer } from './components/SSOConfigurationContainer'

export const SSOConfiguration = () => {
  return (
    <SSOConfigurationContainer>
      <SSOConfig />
    </SSOConfigurationContainer>
  )
}
