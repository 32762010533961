import { styled } from '@/styles/stitches.config'

export const StatBox = styled('div', {
  display: 'inline-flex',
  padding: ' 4px 8px',
  borderRadius: '4px',
  fontSize: 14,
})

export const StatLabel = styled('p', {
  fontWeight: 700,
  fontFamily: '$nunito',
  color: 'inherit',
  margin: '0 0 0 4px',
})

export const StatValue = styled('p', {
  fontFamily: '$space',
  fontWeight: 700,
  color: 'inherit',
  margin: 0,
})

export const StatsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  '> div': {
    marginRight: '8px',
  },
})
