import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { useCreateLocation } from '@/queries/location'
import { CreateLocationFormValues } from '@/types/location'
import { useCurrentOrg } from '../auth/hooks/useCurrentOrg'

const schema = yup.object().shape({
  address: yup.string().required(),
  alternate_name: yup.string(),
  name: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.string(),
  postal_code: yup.string().required(),
  organization_id: yup.string(),
  primary_ns: yup.string(),
  secondary_ns: yup.string(),
})

interface Props {
  name: string
  alternate: string
  address: string
  city: string
  state: string
  postalCode: string
  country?: string
}

export const AddLocationForm: FC<Props> = ({
  name,
  alternate,
  address,
  city,
  state,
  postalCode,
  country,
}) => {
  const currentOrg = useCurrentOrg()
  const createLocation = useCreateLocation()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateLocationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
      alternate_name: alternate,
      primary_ns: '',
      secondary_ns: '',
      address,
      city,
      state,
      postal_code: postalCode,
      country: country || '',
      organization_id: `${currentOrg.id}`,
    },
  })

  const onSubmit = (values: CreateLocationFormValues) => {
    createLocation.mutate({
      address: values.address,
      alternate_name: values.alternate_name,
      name: values.name,
      organization_id: values.organization_id || undefined,
      primary_ns: !!values.primary_ns ? values.primary_ns : undefined,
      secondary_ns: !!values.secondary_ns ? values.secondary_ns : undefined,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexGrid css={{ mb: '$8' }} fillSpace>
        <div>
          <Label htmlFor="name">Location Name</Label>
          <Input type="text" {...register('name')} />
          <Text color="alert" size="sm">
            {errors.name?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="alternate_name">Alternate Name</Label>
          <Input type="text" {...register('alternate_name')} />
          <Text color="alert" size="sm">
            {errors.alternate_name?.message}
          </Text>
        </div>
      </FlexGrid>
      <FlexGrid css={{ mb: '$8' }} fillSpace>
        <div>
          <Label htmlFor="address">Address</Label>
          <Input type="text" {...register('address')} />
          <Text color="alert" size="sm">
            {errors.address?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="city">City</Label>
          <Input type="text" {...register('city')} />
          <Text color="alert" size="sm">
            {errors.city?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="state">State</Label>
          <Input type="text" {...register('state')} />
          <Text color="alert" size="sm">
            {errors.state?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="postal_code">Postal Code</Label>
          <Input type="text" {...register('postal_code')} />
          <Text color="alert" size="sm">
            {errors.postal_code?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="country">Country</Label>
          <Input type="text" {...register('country')} />
          <Text color="alert" size="sm">
            {errors.country?.message}
          </Text>
        </div>
      </FlexGrid>
      <FlexGrid css={{ mb: '$8' }} fillSpace>
        <div>
          <Label htmlFor="organization_id">Organization ID</Label>
          <Input type="text" {...register('organization_id')} />
          <Text color="alert" size="sm">
            {errors.organization_id?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="primary_ns">Primary NS</Label>
          <Input type="text" {...register('primary_ns')} />
          <Text color="alert" size="sm">
            {errors.primary_ns?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="secondary_ns">Secondary NS</Label>
          <Input type="text" {...register('secondary_ns')} />
          <Text color="alert" size="sm">
            {errors.secondary_ns?.message}
          </Text>
        </div>
      </FlexGrid>

      <Flex justify="end">
        <Button
          type="submit"
          mode="outline"
          disabled={!isValid || createLocation.isLoading}
        >
          Create Location
        </Button>
      </Flex>
    </form>
  )
}
