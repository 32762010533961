import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { COUNTRIES } from '@/lib/countries'
import { PHONE_REG_EXP } from '@/src/lib/phoneLib'
import { CountryCodeDropdown } from './CountryCodeDropdown'
import { formatPhoneForAPI } from './lib/utilities'

export type TSendPinFormValues = {
  phone: string
  code: {
    code: string
    name: string
    id: string
  }
}

interface Props {
  handleSendPin: (phone: string) => void
  title: string
  loading?: boolean
}

const schema = yup.object().shape({
  phone: yup
    .string()
    .required('Please enter a valid phone number')
    .matches(PHONE_REG_EXP, 'Phone number is not valid'),
})

const defaultValues = { phone: '', code: COUNTRIES[0] }

export const SendPinForm: FC<Props> = ({ handleSendPin, title, loading }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onFormSubmit = (values: TSendPinFormValues) => {
    const formattedPhone = formatPhoneForAPI(values.phone, values.code.code)
    handleSendPin(formattedPhone)
  }

  return (
    <FlexWrap direction="column" fullWidth>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Text as="h3" css={{ mb: '$24' }}>
          {title}
        </Text>
        <Label htmlFor="phone">Phone Number</Label>
        <Flex>
          <CountryCodeDropdown
            selectedCode={watch('code')}
            onCountryCodeChange={code => setValue('code', code)}
          />
          <Input type="tel" {...register('phone')} iconLeft />
        </Flex>
        <Text color="alert">{errors?.phone?.message}</Text>
        <Text color="hint">Standard messaging rates may apply.</Text>
        <Button
          disabled={loading}
          type="submit"
          mode="filled"
          display="block"
          css={{
            mt: '$12',
            width: '100%',
          }}
        >
          Confirm Number
        </Button>
      </form>
    </FlexWrap>
  )
}
