import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useAddPdqStoreRegistration } from '@/queries/pdq'
import { useModalStore } from '@/store/modal'
import { PdqStore } from '@/types/settings'
import { PdqStoreRegistrationFormValues, StoreForm } from './StoreForm'

interface Props {
  store: PdqStore
}

export const AddStoreModal: FC<Props> = ({ store }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const addPdqStoreRegistration = useAddPdqStoreRegistration()
  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleSubmit = (values: PdqStoreRegistrationFormValues) => {
    addPdqStoreRegistration.mutate(
      { id: store.id, values },
      {
        onSuccess: close,
      }
    )
  }

  return (
    <Dialog open={open} setOpen={close} title="Add Store Registration">
      <StoreForm handleFormSubmit={handleSubmit} isLoading={false} />
    </Dialog>
  )
}
