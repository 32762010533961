import { DeductionStatus } from '@/types/remittances'

export const DEDUCTION_STATUS_LABELS: { [key in DeductionStatus]: string } = {
  'ded failed': 'Can\t collect',
  'ded retry': 'Next paycheck',
  'ded completed': 'Deducted',
  'ded requested': 'Pending deduction',
  outstanding: 'Outstanding',
  'pb fail': 'Outstanding',
  'po fail': 'Advance failed',
  'po cancel': 'Advance cancelled',
  'bonus complete': 'Bonus completed',
  'pb paid': 'Advance paid',
  default: 'Default',
}
