import { FC, useState } from 'react'
import { Col, FormGroup, Input, Row, Table } from 'reactstrap'
import { Button } from '@/common/button'
import { InstantCardExample } from './InstantCardExample'
import { CardOrderFormValues } from './lib/orderForm'

interface Props {
  handleOrder: () => void
  loading: boolean
  order?: CardOrderFormValues
}

const CONFIRM = 'CONFIRM'

const InstantCardsConfirmation: FC<Props> = ({
  handleOrder,
  loading,
  order,
}) => {
  const [confirmValue, setConfirmValue] = useState('')

  if (!order) return null

  return (
    <div>
      <h5 className="mb-3">Take a moment to review your order:</h5>
      <Table bordered>
        <tbody>
          <tr>
            <td className="font-weight-bold">Example Card</td>
            <td>
              <InstantCardExample name={`${order.prefixName} 01`} />
            </td>
          </tr>
          <tr>
            <td className="font-weight-bold">Quantity</td>
            <td>{order.count}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Group Name</td>
            <td>{order.prefixName}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Shipping Address</td>
            <td>
              {order.firstName} {order.lastName}
              <br />
              C/O {order.careOf}
              <br />
              {order.address1} {order.address2}
              <br />
              {order.city}, {order.state} {order.zip}
            </td>
          </tr>
        </tbody>
      </Table>
      <p>
        If everything looks correct, type{' '}
        <code className="font-weight-bold">CONFIRM</code> into the field below.
      </p>
      <Row>
        <Col md="6">
          <FormGroup>
            <Input
              type="text"
              onChange={e => {
                setConfirmValue(e.currentTarget.value.toUpperCase())
              }}
              value={confirmValue}
              placeholder="CONFIRM"
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="d-flex">
        <Button
          mode="filled"
          loading={loading}
          css={{ ml: 'auto' }}
          onClick={handleOrder}
          disabled={confirmValue !== CONFIRM}
        >
          Confirm Order
        </Button>
      </div>
    </div>
  )
}

export default InstantCardsConfirmation
