import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router'

const useOrgPath = (
  route: string,
  additionalParams?: {
    [paramName: string]: string | number | boolean | undefined
  }
) => {
  const { orgKey } = useParams<{ orgKey: string }>()
  return useMemo(
    () =>
      generatePath(route, {
        orgKey,
        ...additionalParams,
      }),
    [orgKey, route, additionalParams]
  )
}

export default useOrgPath
