export enum JobStatus {
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  QUEUED = 'queued',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type JobsRecord = {
  additional_info: null | string
  file_path: null | string
  filename: string
  id: number
  job_name: string
  job_status: JobStatus
  org_id: number
  step: null
  summary: null | {
    error_messages: string[]
    failure_messages: string[]
    job_id: number
    num_already_processed: number
    num_paid_out: number
    num_processed: number
    skipped_messages: string[]
  }
  time_completed: string
  time_queued: string
  time_started: string
}
