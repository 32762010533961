import moment from 'moment'
import { FC } from 'react'
import { IRemittance } from '@/types/remittances'

interface Props {
  remittance: IRemittance
}

export const DeductionReportTitle: FC<Props> = ({ remittance }) => {
  const payPeriod = moment(remittance.pay_date).format('MMM Do, YYYY')

  return <>{payPeriod} pay period</>
}
