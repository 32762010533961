import * as yup from 'yup'
import { IOrderInstantCardsConfig } from '@/api/types/instant-cards'

export interface CardOrderFormValues {
  count: number
  prefixName: string
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  careOf: string
}

export interface ICardBulkOrderFormValues {
  count: number
  prefixName: string
  careOf: string
  files: File[]
}

export const formatFormValuesForAPI = (
  values: CardOrderFormValues
): IOrderInstantCardsConfig => ({
  count: values.count,
  card_name_prefix: values.prefixName,
  shipping_address: {
    first_name: values.firstName,
    last_name: values.lastName,
    address_1: values.address1,
    address_2: values.address2,
    city: values.city,
    state: values.state,
    zip: values.zip,
    care_of: values.careOf,
  },
})

export const formatBulkFormValuesForAPI = (
  values: CardOrderFormValues
): IOrderInstantCardsConfig => ({
  count: values.count,
  card_name_prefix: values.prefixName,
  shipping_address: {
    first_name: values.firstName,
    last_name: values.lastName,
    address_1: values.address1,
    address_2: values.address2 || '',
    city: values.city,
    state: values.state?.trim(),
    zip: values.zip,
    care_of: values.careOf,
  },
})

export const cardBulkOrderFormSchema = (isAdmin: boolean) =>
  yup.object().shape({
    prefixName: yup
      .string()
      .required()
      .min(3, ({ min }) => `Must be at least ${min} characters`)
      .max(10, ({ max }) => `Must be at most ${max} characters`)
      .matches(/^[a-zA-Z0-9 ]+$/, 'Please use only alphanumeric characters'),
    careOf: yup
      .string()
      .required()
      .max(50, ({ max }) => `Must be at most ${max} characters`),
    files: yup.array().min(1),
  })

export const cardOrderFormSchema = (isAdmin: boolean) =>
  yup.object().shape({
    count: yup
      .number()
      .required()
      .min(1, ({ min }) => `Must order at least ${min} card`)
      .max(isAdmin ? 500 : 50, () =>
        isAdmin
          ? `There is a 500 card maximum per order`
          : `For orders of 51-500 cards, reach out to your Branch representative`
      ),
    prefixName: yup
      .string()
      .required()
      .min(3, ({ min }) => `Must be at least ${min} characters`)
      .max(10, ({ max }) => `Must be at most ${max} characters`)
      .matches(/^[a-zA-Z0-9 ]+$/, 'Please use only alphanumeric characters'),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    careOf: yup
      .string()
      .required()
      .max(50, ({ max }) => `Must be at most ${max} characters`),
    zip: yup.string().required(),
  })
