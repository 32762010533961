import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { AddRegistrationForm } from './AddRegistrationForm'

export const AddRegistrationModal: FC = () => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const description = (
    <>
      Set up a registration to watch for files at your desired location. To set
      up specific processors that match against filenames and perform different
      processes, you can create a processor under this registration once you've
      finished creating it.
    </>
  )

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Add Registration"
      description={description}
    >
      <AddRegistrationForm handleClose={close} />
    </Dialog>
  )
}
