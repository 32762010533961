import { Card } from '@/common/card'
import { styled } from '@/styles/stitches.config'

export const ProgressCardContainer = styled(Card, {
  mb: '$16',
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
})
