import { DownloadIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Button } from '@/common/button'
import { Spinner } from '@/common/loader'
import { Popover } from '@/common/popover'
import { Text } from '@/common/text'
import {
  CSV_FORMAT_TYPE,
  CSVDownloadForm,
  DownloadFormValues,
} from './CSVDownloadForm'

interface Props {
  CSVDownloading: boolean
  handleDownload: (val?: CSV_FORMAT_TYPE) => void
  disabled?: boolean
}

export const CSVDownloadButton: FC<Props> = ({
  CSVDownloading,
  handleDownload,
  disabled,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const handleSubmit: SubmitHandler<DownloadFormValues> = ({ type }) => {
    handleDownload(type)
    setPopoverOpen(false)
  }

  return (
    <Popover
      trigger={
        <Button
          mode="outline"
          color="black"
          disabled={CSVDownloading || disabled}
          size="md"
          css={{
            ml: '$4',
          }}
        >
          <DownloadIcon
            style={{ marginRight: '8px', transform: 'scale(1.25)' }}
          />
          {CSVDownloading ? (
            <Spinner className="mx-4" width={24} />
          ) : (
            'Download'
          )}
        </Button>
      }
      open={popoverOpen}
      setOpen={setPopoverOpen}
      showClose
    >
      <Text size="lg" bold css={{ mt: '$8', color: 'black' }}>
        Choose Format:
      </Text>
      <CSVDownloadForm onSubmit={handleSubmit} />
    </Popover>
  )
}
