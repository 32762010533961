import { SortingState } from '@tanstack/react-table'
import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { TableFilters, UpdateFilters } from '@/common/filters/types'
import { additionalFilters, paybackStatusOptions } from '../lib/filters'
import { useDownloadPaybacks } from '../lib/useDownloadPaybacks'

interface Props {
  filters: TableFilters
  updateFilters: UpdateFilters
  sorted: SortingState
}

export const PaybackFilters: FC<Props> = ({
  filters,
  sorted,
  updateFilters,
}) => {
  const { loading, handleDownload } = useDownloadPaybacks({
    filtered: filters,
    sorted,
  })

  return (
    <TableFiltersMain
      filters={filters}
      updateFilters={updateFilters}
      statusOptions={paybackStatusOptions}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleDownload={handleDownload}
      disableEnterpriseReporting
    />
  )
}
