import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  deleteTransferFeeRanges,
  getLocationSettings,
  getOrgSettings,
  getPartnerSettings,
  getTransferFeeRanges,
  putTransferFeeRanges,
  setOrgLocationSettings,
  setOrgSettings,
  setPartnerSettings,
} from '@/api/settings'
import {
  OrgSettingsUpdates,
  PartnerSettingsUpdate,
} from '@/api/types/organization'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { cacheNavColor, removeCachedNavColor } from '@/src/sessionstorage'
import { useSettingsStore } from '@/store/settings'
import { useToasterStore } from '@/store/toast'
import { useUser } from '@/store/user'
import { TOrgLocationSettingsUpdates } from '@/types/location'
import { FeeTier } from '@/types/settings'

export const SETTINGS_QUERY = 'settings'
export const useSettingsQuery = () => {
  const setSettings = useSettingsStore(state => state.setSettings)
  const org = useCurrentOrg()

  return useQuery(
    [SETTINGS_QUERY, org?.id],
    async () => {
      const res = await getOrgSettings(org?.id)
      return res.data.data
    },
    {
      onSuccess: data => {
        setSettings(data)
        const brandColor = data.find(x => x.name === 'BRANDING_COLOR_HEX_CODE')
        if (brandColor && brandColor.value) {
          cacheNavColor(brandColor.value)
        } else {
          removeCachedNavColor()
        }
      },
      enabled: !!org,
    }
  )
}

export const useUpdateSettingsMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()

  const queryClient = useQueryClient()
  return useMutation(
    (params: OrgSettingsUpdates) => setOrgSettings(orgId, params),
    {
      onSuccess: (_, params) => {
        sendAnalyticsEvent('settings', 'update:success', params)
        queryClient.invalidateQueries(SETTINGS_QUERY)
        addToast({
          type: 'success',
          title: 'Settings Updated',
        })
      },
      onError: (_, params) => {
        sendAnalyticsEvent('settings', 'update:error', params)
        addToast({
          type: 'error',
          title: 'Failed to update settings.',
          description:
            'Please try again soon, and if the issue persists, contact support.',
        })
      },
    }
  )
}

export const PARTNER_QUERY = 'partner-query'
export const usePartnerQuery = () => {
  const currentOrg = useUser(state => state.currentOrganization)
  const enabled = !!currentOrg?.partner_id
  return useQuery(
    [PARTNER_QUERY, currentOrg?.id],
    async () => {
      try {
        const res = await getPartnerSettings(currentOrg?.id)
        return res.data.data
      } catch {
        return []
      }
    },
    {
      enabled,
    }
  )
}

export const useUpdatePartnerMutation = () => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(
    (params: { partnerId: string; settings: PartnerSettingsUpdate }) =>
      setPartnerSettings(params),
    {
      onSuccess: () => queryClient.invalidateQueries([PARTNER_QUERY, orgId]),
    }
  )
}

const LOCATION_SETTINGS_QUERY = 'location-settings'

export const useLocationSettings = () => {
  const { default_location_id } = useCurrentOrg()
  return useQuery(
    [LOCATION_SETTINGS_QUERY, default_location_id],
    async () => {
      const res = await getLocationSettings(default_location_id)
      return res.data.data
    },
    {
      enabled: !!default_location_id,
    }
  )
}

export const useUpdateOrgLocationSettings = () => {
  const { org_key, default_location_id } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (params: TOrgLocationSettingsUpdates) => {
      const res = await setOrgLocationSettings(org_key, params)
      return res.data.data
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          LOCATION_SETTINGS_QUERY,
          default_location_id,
        ]),
    }
  )
}

const INSTANT_TRANSFER_FEE_RANGES_QUERY = 'instant-transfer-fee-ranges'

export const useGetInstantTransferFeeRanges = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([INSTANT_TRANSFER_FEE_RANGES_QUERY, orgId], async () => {
    const res = await getTransferFeeRanges(orgId)
    return res.data
  })
}

export const usePutInstantTransferFeeRangesMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async (body: FeeTier) => {
      const res = await putTransferFeeRanges(orgId, body)
      return res.data.data
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: `Successfully Updated Instant Transfer Fee Ranges`,
        })
        queryClient.invalidateQueries([
          INSTANT_TRANSFER_FEE_RANGES_QUERY,
          orgId,
        ])
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Instant Transfer Fee Ranges Error',
          description: `Failed to update Instant Transfer Fee Ranges`,
        })
      },
    }
  )
}

export const useDeleteInstantTransferFeeRangesMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async (body: FeeTier) => {
      const res = await deleteTransferFeeRanges(orgId, body)
      return res.data.data
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: `Successfully Deleted Instant Transfer Fee Tier`,
        })
        queryClient.invalidateQueries([
          INSTANT_TRANSFER_FEE_RANGES_QUERY,
          orgId,
        ])
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Delete Instant Transfer Fee Tier Error',
          description: `Failed to delete Instant Transfer Fee Tier`,
        })
      },
    }
  )
}
