import { FC } from 'react'
import { Alert } from '@/common/alert'
import { AlertDialog } from '@/common/modal'
import { useFundingSourceConfig } from '@/hooks/useInvoiceConfig'
import { useDeleteFundingSource } from '@/queries/funding-sources'
import { FundingSource } from '@/types/invoice'

interface Props {
  fundingSource: FundingSource
}

export const DeleteFundingSourceModal: FC<Props> = ({ fundingSource }) => {
  const invoiceConfigs = useFundingSourceConfig(fundingSource.id)
  const deleteFundingSource = useDeleteFundingSource()

  const onConfirm = async () =>
    deleteFundingSource.mutateAsync(fundingSource.id)

  const description = !!invoiceConfigs.config ? (
    <Alert type="danger">
      Funding source is used by an active Invoice Configuration and cannot be
      deleted. Please update configurations and try again.
    </Alert>
  ) : (
    <p>Are you sure you want to delete this funding source?</p>
  )

  return (
    <AlertDialog
      title={`Delete ${fundingSource.name}?`}
      description={description}
      actionDescription={'Delete'}
      handleAction={onConfirm}
      isDestructive={true}
      disabled={!!invoiceConfigs.config || invoiceConfigs.isLoading}
    />
  )
}
