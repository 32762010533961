import { ChevronDownIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { IconButton } from '@/common/button'
import { Card } from '@/common/card'
import { FundingSource as FundingSourceType } from '@/types/invoice'
import { FundingSourceDetails } from './FundingSourceDetails'

interface Props {
  fundingSource: FundingSourceType
}

export const FundingSource: FC<Props> = ({ fundingSource }) => {
  const [open, setOpen] = useState(false)

  return (
    <Card className="my-3">
      <div>
        <div className="d-flex align-items-center">
          <div>
            <p className="mb-0" style={{ fontSize: '1.25rem' }}>
              {fundingSource.name}
            </p>
            <strong style={{ fontSize: '0.75rem' }}>
              Account: ••• {fundingSource.account_number_last_four}
            </strong>
          </div>
          <div className="ml-auto">
            <IconButton tooltip="View Options" onClick={() => setOpen(!open)}>
              <ChevronDownIcon />
            </IconButton>
          </div>
        </div>
      </div>
      {open && <FundingSourceDetails fundingSource={fundingSource} />}
    </Card>
  )
}
