import copy from 'copy-to-clipboard'
import moment from 'moment'
import { FC, useState } from 'react'
import { downloadResource } from '@/api/resources'
import { Flex } from '@/common/layout'
import { Spinner } from '@/common/loader'
import { Text } from '@/common/text'
import { Tooltip } from '@/common/tooltip'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { makeOrgAssetLink } from '@/lib/orgAssetLink'
import { trimStringToLength } from '@/lib/strings'
import { useDeleteResource } from '@/queries/resources'
import { useToasterStore } from '@/store/toast'
import { IResource } from '@/types/resources'
import styles from '../index.module.scss'
import { ResourceCardActions } from './ResourceCardActions'
import {
  ResourceCardContainer,
  ResourceCardHeader,
  ResourceCardOverlay,
} from './styles'

interface Props {
  resource: IResource
}

export const ResourceCard: FC<Props> = ({ resource }) => {
  const { addToast } = useToasterStore()
  const [downloading, setDownloading] = useState(false)
  const [hasCopied, setHasCopied] = useState(false)
  const deleteResource = useDeleteResource()
  const { description, title, file_name } = resource

  const trimmedTitle = trimStringToLength(title, {
    max: 30,
    addEllipsis: true,
  })

  const created = moment(resource.time_created)

  const handleDelete = async () => deleteResource.mutateAsync(resource.id)

  const handleDownload = async () => {
    sendAnalyticsEvent('resources', 'download', { id: resource.id })
    setDownloading(true)
    try {
      const res = await downloadResource(resource.id)
      const fileBlob = new Blob([res.data], { type: resource.type })
      const url = window.URL.createObjectURL(fileBlob)
      const downloadPdf = document.createElement('a')
      downloadPdf.href = url
      downloadPdf.download = resource.file_name
      downloadPdf.click()
      window.URL.revokeObjectURL(url)
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Something went wrong downloading your file.',
      })
    } finally {
      setDownloading(false)
    }
  }

  const handleCopyPublicUrl = () => {
    setHasCopied(true)
    const link = makeOrgAssetLink(resource.id)
    copy(link)
    setTimeout(() => {
      setHasCopied(false)
    }, 1000)
  }

  const renderTitle = () => {
    if (trimmedTitle !== title) {
      return (
        <Tooltip
          side="bottom"
          trigger={
            <Text as="h5" css={{ mb: '$4' }}>
              {trimmedTitle}
            </Text>
          }
        >
          {title}
        </Tooltip>
      )
    }

    return (
      <Text as="h5" css={{ mb: '$4' }}>
        {trimmedTitle}
      </Text>
    )
  }

  return (
    <ResourceCardContainer>
      {downloading && (
        <div className={styles.ResourceCardOverlay}>
          <Spinner />
        </div>
      )}
      {hasCopied && (
        <ResourceCardOverlay>
          <h4>Copied!</h4>
        </ResourceCardOverlay>
      )}
      <Flex direction="column" align="start" fullWidth>
        <ResourceCardHeader justify="between">
          <div>
            {title ? (
              renderTitle()
            ) : (
              <Text as="h5" css={{ mt: '$8' }}>
                {file_name}
              </Text>
            )}
          </div>
          <ResourceCardActions
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            handleCopy={handleCopyPublicUrl}
            resource={resource}
          />
        </ResourceCardHeader>
        <div>
          {description && (
            <Text css={{ mb: '$8' }} bold>
              {description}
            </Text>
          )}

          <Text>{file_name}</Text>
          <Text color="hint">
            {resource.public ? 'Brand Asset' : 'Marketing Asset'}
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Added{' '}
            {created.format('MMM D, YYYY')}
          </Text>
        </div>
      </Flex>
    </ResourceCardContainer>
  )
}
