import { FC } from 'react'
import { useWindowSize } from '@/hooks/useWindowSize'
import { Nav } from './Nav'
import { SubNav } from './subnav/SubNav'

export const NavigationContainer: FC = () => {
  const { isMobile } = useWindowSize()

  return (
    <>
      <Nav isMobile={isMobile} />
      {!isMobile && <SubNav />}
    </>
  )
}
