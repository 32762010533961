import { useState } from 'react'
import { getPayouts } from '@/api/payouts'
import { GetPayoutsParams } from '@/api/types/payouts'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { parseRouteParams } from '@/lib/queryParams'
import { useDisbursementsStore } from '@/store/disbursements'
import { useToasterStore } from '@/store/toast'
import { PayoutRecord } from '@/types/payouts'
import { downloadCSV } from './download'

export const useDownloadPayouts = () => {
  const [loading, setLoading] = useState(false)
  const { filters, sorted } = useDisbursementsStore()
  const { addToast, expireToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()

  const handleDownload = async () => {
    sendAnalyticsEvent('disbursements', 'download', filters)
    let toastId
    const { from, to, ...restFilters } = filters

    const params = {
      ...restFilters,
      time_created_start: from?.format(),
      time_created_end: to?.format(),
      size: 1000,
      sort: sorted[0].id,
      direction: sorted[0].desc ? 'desc' : 'asc',
    }

    setLoading(true)

    try {
      let results: PayoutRecord[] = []
      let lastPage = false
      let nextPage = ''

      toastId = addToast({
        type: 'pending',
        title: 'Downloading disbursements',
        description: `We're fetching your disbursements, it might take a moment.`,
      })

      const {
        data: { content, has_next_page, next_page },
      } = await getPayouts(orgId, params)
      results = [...results, ...content]
      nextPage = next_page

      while (!lastPage && has_next_page) {
        const queryParams = parseRouteParams<GetPayoutsParams>(nextPage)
        const res = await getPayouts(orgId, queryParams)
        results = [...results, ...res.data.content]
        nextPage = res.data.next_page
        lastPage = !res.data.has_next_page
      }

      downloadCSV(results)
    } catch (error) {
      addToast({
        type: 'error',
        title: "Couldn't download results.",
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      if (toastId) {
        expireToast(toastId, 0)
      }
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
