import { FC } from 'react'
import {
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { useIsOrg } from '@/hooks/useIsOrg'
import { TransactionFilters } from './TransactionFilters'

export const TransactionRecordsContainer: FC = ({ children }) => {
  const { isUberOrg } = useIsOrg()

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary={isUberOrg ? 'Backup Balances' : 'Advances'} />
        </PageHeader>
        <PageFilters>
          <TransactionFilters />
        </PageFilters>
      </PageTop>
      <PageBody>{children}</PageBody>
    </>
  )
}
