import { FundingSource } from '@/types/invoice'
import http from './http'
import { ICreateFundingSourceConfig } from './types/invoice'

/**
 * Retrieves the array of an organization's funding sources
 */
export const getFundingSources = (orgId: number) =>
  http.get<FundingSource[]>(`payout/organizations/${orgId}/funding_sources`)

/**
 * Posts a new funding source for the organization
 * @param config Account number, routing number, and nickname
 */
export const createFundingSource = (
  orgId: number,
  config: ICreateFundingSourceConfig
) => {
  return http.post<FundingSource>(
    `payout/organizations/${orgId}/funding_sources`,
    config
  )
}

/**
 * Removes the funding source for the organization
 * @param id the funding source ID
 */
export const deleteFundingSource = (orgId: number, id: number) => {
  return http.delete(`payout/organizations/${orgId}/funding_sources/${id}`)
}
