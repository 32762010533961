import { FC } from 'react'
import { ProgressCardContainer } from './styles'

interface IProps {
  isDisabled: boolean
}

export const ProgressCard: FC<IProps> = ({ isDisabled, children }) => (
  <ProgressCardContainer disabled={isDisabled}>
    {children}
  </ProgressCardContainer>
)
