import { saveAs } from 'file-saver'

const templateCsvContent =
  'Location Name,Alternate Name,Address,City,State,Postal Code,Country\nBobs Mower Repair,2342,1234 Spruce St,Nashville,Tennessee,12345,United States (optional)\nBobs Mower Repair,2390,4321 Oak Ave,Clarksville,Tennessee,12346,United States (optional)'

export const downloadLocationsTemplate = () => {
  const blob = new Blob([templateCsvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch-locations-template.csv')
}
