import { yupResolver } from '@hookform/resolvers/yup'
import { DownloadIcon } from '@radix-ui/react-icons'
import { FC, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { InputDropzone } from '@/components/file-upload/InputDropzone'
import { isEmptyObject } from '@/lib/validators'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { InstantCardsBulkOrderSteps } from './InstantCardsBulkOrderSteps'
import { downloadBulkInstantCardOrderTemplate } from './lib/downloadBulkQualifyTemplate'
import {
  cardBulkOrderFormSchema,
  ICardBulkOrderFormValues,
} from './lib/orderForm'

const InstantCardsBulkOrderModal: FC = () => {
  const { registerModal, removeModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const [open, setOpen] = useState(true)
  const currentOrg = useCurrentOrg()

  const defaultValues = useMemo<ICardBulkOrderFormValues>(
    () => ({
      count: 1,
      prefixName: '',
      careOf: currentOrg.name,
      files: [],
    }),
    [currentOrg]
  )

  const schema = cardBulkOrderFormSchema(isGlobalAdmin)

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ICardBulkOrderFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleFormSubmit = (values: ICardBulkOrderFormValues) => {
    close()
    registerModal(<InstantCardsBulkOrderSteps order={values} />)
  }

  const handleDownloadTemplate = () => {
    downloadBulkInstantCardOrderTemplate()
  }

  return (
    <Modal isOpen={open} toggle={close} centered backdrop="static">
      <ModalHeader toggle={close} tag="h2">
        Bulk Upload
      </ModalHeader>
      <ModalBody>
        <p>
          For each card order, we'll need the worker's first name, last name,
          address, city, state, zip. Please follow the template below.
        </p>
        <Button
          iconRight
          mode="flat"
          color="primary"
          css={{ padding: 0 }}
          onClick={handleDownloadTemplate}
        >
          <DownloadIcon />
          Download Template
        </Button>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FlexGrid css={{ mt: '$16' }}>
            <Flex
              direction="column"
              align="start"
              css={{ width: '100%', mr: '$12' }}
            >
              <Label htmlFor="prefixName">Group Name on Cards</Label>
              <Input
                type="text"
                {...register('prefixName')}
                css={{ mt: '$4' }}
              />
              <Text color="alert" size="sm">
                {errors?.prefixName?.message}
              </Text>
            </Flex>
            <Flex
              direction="column"
              align="start"
              css={{ width: '100%', mr: '$12' }}
            >
              <Label htmlFor="careOf">Care Of</Label>
              <Input type="text" {...register('careOf')} css={{ mt: '$4' }} />
              <Text color="alert" size="sm">
                {errors?.careOf?.message}
              </Text>
            </Flex>
            <Flex
              direction="column"
              align="start"
              css={{ width: '100%', mr: '$12' }}
            >
              <Label htmlFor="count"># of cards per store</Label>
              <Input type="text" {...register('count')} css={{ mt: '$4' }} />
              <Text color="alert" size="sm">
                {errors?.count?.message}
              </Text>
            </Flex>
          </FlexGrid>
          <FlexGrid css={{ mt: '$16' }} fullWidth>
            <InputDropzone
              handleDrop={files => setValue('files', files)}
              files={watch('files')}
              allowMultiple={false}
              label="Upload file (Drag n Drop or Select)"
              hasError={false}
              error=""
              supportedFileTypes={['csv']}
              subtext="Accepts CSV"
            />
          </FlexGrid>
          <Flex>
            <Button
              mode="filled"
              type="submit"
              disabled={!isEmptyObject(errors)}
              css={{ mt: '$4', ml: 'auto' }}
            >
              Review Orders
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default InstantCardsBulkOrderModal
