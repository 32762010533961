import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useRemoveUser, useVerifyUser } from '@/queries/employees'
import { useModalStore } from '@/store/modal'
import { UserEnrollmentRequest } from '@/types/employees'
import { VerifyUserModal } from './VerifyUserModal'

interface Props {
  user: UserEnrollmentRequest
}

export const EnrollmentRequestedActions: FC<Props> = ({
  user: { first_name, last_name, user_id },
}) => {
  const verifyUser = useVerifyUser()
  const removeUser = useRemoveUser()
  const { registerModal } = useModalStore()

  const name = `${first_name} ${last_name}`

  const openDenyModal = () => {
    registerModal(
      <AlertDialog
        title="Deny user"
        description={`${name} will be denied access your organization's Branch account.`}
        actionDescription={'Deny'}
        handleAction={() =>
          removeUser.mutateAsync({
            id: `${user_id}`,
            firstName: first_name,
            lastName: last_name,
          })
        }
        isDestructive={true}
        disabled={removeUser.isLoading}
      />
    )
  }

  const openVerifyModal = () => {
    registerModal(
      <VerifyUserModal
        name={name}
        handleSubmit={employeeId => {
          verifyUser.mutate({
            id: `${user_id}`,
            firstName: first_name,
            lastName: last_name,
            employeeId,
          })
        }}
      />
    )
  }

  return (
    <TableActionDropdown
      menu={[
        { label: 'Enroll', handleClick: openVerifyModal },
        { label: 'Deny', handleClick: openDenyModal },
      ]}
    />
  )
}
