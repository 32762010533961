import { ArrowLeftIcon } from '@radix-ui/react-icons'
import moment from 'moment'
import { FC } from 'react'
import { IconButton } from '@/common/button'
import { DirectDepositWidget } from '@/components/employees/components/DirectDepositWidget'
import { StatusBadge } from '@/components/employees/components/StatusBadge'
import { WalletBadge } from '@/components/employees/components/WalletBadge'
import { STATUS_KEYS } from '@/components/employees/lib/employees.types'
import { formatPhoneNumber } from '@/lib/phoneLib'
import { IOrganizationEmployee } from '@/types/employees'
import { CloseIcon } from '../icons'
import { DetailActions } from '.'
import { DetailProperty } from './components/DetailProperty'
import {
  DetailHeader,
  DetailHeading,
  DetailSection,
  DetailSeperator,
} from './components/styles'
import { EmployeeCardDetails } from './EmployeeCardDetails'

interface Props {
  user: IOrganizationEmployee
  moveBack?: boolean
  remove: () => void
}

export const WorkerDetails: FC<Props> = ({ moveBack, user, remove }) => {
  const ddMoment = user.direct_deposit_form
    ? moment(user.direct_deposit_form.time_created)
    : undefined

  const status = !user.user ? STATUS_KEYS.NOT_ENROLLED : STATUS_KEYS.ENROLLED

  return (
    <>
      <DetailHeader>
        {moveBack && (
          <DetailActions>
            <IconButton onClick={() => remove()}>
              <ArrowLeftIcon />
            </IconButton>
          </DetailActions>
        )}
        <DetailHeading>
          {user.first_name} {user.last_name}
        </DetailHeading>
        {!moveBack && (
          <DetailActions>
            <IconButton onClick={() => remove()}>
              <CloseIcon />
            </IconButton>
          </DetailActions>
        )}
      </DetailHeader>
      <DetailSeperator />
      <DetailSection>
        <DetailProperty label="ID" value={user.employee_id} canCopy />

        <DetailProperty
          label="Account Name"
          value={`${user.user?.first_name} ${user.user?.last_name}`}
          canCopy
        />

        <DetailProperty
          label="Account Phone"
          value={formatPhoneNumber(user.user?.phone)}
          canCopy
        />

        <DetailProperty
          label="Account Email"
          value={user.user?.email}
          canCopy
        />

        <DetailProperty
          label="Roster Name"
          value={`${user.first_name} ${user.last_name}`}
          canCopy
        />

        <DetailProperty
          label="Roster Phone"
          value={formatPhoneNumber(user.phone_number)}
          canCopy
        />

        <DetailProperty
          label="Roster Email"
          value={user.email_address}
          canCopy
        />

        <DetailProperty
          label="Location"
          value={user.location_metadata}
          canCopy
        />

        {!!user.business_name && (
          <DetailProperty
            label="Business Name"
            value={user.business_name}
            canCopy
          />
        )}

        {!!user.ein && <DetailProperty label="EIN" value={user.ein} canCopy />}

        <>
          <DetailProperty
            label="Account #"
            value={user.wallet.account_number}
            canCopy
          />
          <DetailProperty
            label="Routing #"
            value={user.wallet.routing_number}
            canCopy
          />
          <DetailProperty
            label="DD Requested"
            value={ddMoment}
            renderValue={() => (
              <DirectDepositWidget user={user} value={ddMoment} />
            )}
          />
        </>

        <DetailProperty
          label="Wallet Created"
          value={!!user.wallet.wallet_id ? 'Yes' : 'No'}
        />
        <DetailProperty
          label="Active Card"
          value={user.wallet.has_activated_card ? 'Yes' : 'No'}
        />
        <DetailProperty
          label="Minor"
          value={
            typeof user.user?.is_minor === 'boolean'
              ? user.user.is_minor
                ? 'Yes'
                : 'No'
              : undefined
          }
        />
        {user.wallet.status && (
          <DetailProperty
            label="Wallet Status"
            value={user.wallet.status}
            renderValue={() => (
              <WalletBadge statusKey={user.wallet.status} badgeSize="sm" />
            )}
          />
        )}
        <DetailProperty
          label="Wallet Reason Code"
          value={user.wallet.reason_code ? user.wallet.reason_code : 'N/A'}
        />
        <DetailProperty
          label="Status"
          value={status}
          renderValue={() => <StatusBadge user={user} badgeSize="sm" />}
        />
        <DetailProperty
          label="Wallet Created Date"
          value={
            user.wallet.time_created
              ? moment(user.wallet.time_created).format('MM/DD/YYYY')
              : null
          }
        />
      </DetailSection>
      <DetailSection>
        <EmployeeCardDetails employeeId={user.employee_id} />
      </DetailSection>
    </>
  )
}
