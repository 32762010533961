import { FC, useMemo, useState } from 'react'
import { Button } from '@/common/button'
import { Checkbox } from '@/common/checkbox'
import { SearchFilter } from '@/common/filters/SearchFilter'
import { ChatQuestionIcon } from '@/common/icons'
import { Box, Flex } from '@/common/layout'
import { SimpleTable } from '@/common/table/SimpleTable'
import { Text } from '@/common/text'
import { useDebounce } from '@/hooks/useDebounce'
import { isEmptyObject } from '@/lib/validators'
import {
  useRemittanceBalances,
  useUpdateRemittanceDeductions,
} from '@/queries/remittances'
import { useDeductionReport } from '@/store/deduction-report'
import { RemittanceDeductions } from '@/types/remittances'
import { columns } from '../lib/reviewColumns'
import { ProgressCard } from './ProgressCard'
import ProgressCardFooter from './ProgressCardFooter'
import ProgressCardTitle from './ProgressCardTitle'
import { RemittanceTransactions } from './RemittanceTransactions'

interface Props {
  remittanceId: string
}

export const AdjustProgressCard: FC<Props> = ({ remittanceId }) => {
  const [search, setSearch] = useState('')
  const name = useDebounce(search)
  const balances = useRemittanceBalances(remittanceId)
  const updateRemittanceDeductions = useUpdateRemittanceDeductions()
  const {
    adjustments,
    needsAdjustment,
    computed: { getProgressStepPosition },
    setNeedsAdjustment,
    updateProgressStep,
  } = useDeductionReport()

  const stepPosition = getProgressStepPosition('adjust')

  const handleAbleToProcessAdjustments = () => {
    setNeedsAdjustment(false)
  }

  const handleUnableToProcessAdjustments = () => {
    setNeedsAdjustment(true)
  }

  const handleAdjustmentsComplete = () => {
    const payload: RemittanceDeductions = { employees: {} }
    for (let id in adjustments) {
      const adjustment = adjustments[id]
      payload.employees[id] = { next_deduction: adjustment.amount }
    }
    if (isEmptyObject(payload.employees)) return updateProgressStep('pay')
    updateRemittanceDeductions.mutate(
      {
        remittanceId,
        payload,
      },
      {
        onSuccess: () => updateProgressStep('pay'),
      }
    )
  }

  const balancesData = useMemo(() => {
    const data = balances.data?.balances || []
    if (!name) return data
    return data.filter(b => {
      if (b.first_name.toLowerCase() === name.toLowerCase()) return true
      if (b.last_name.toLowerCase() === name.toLowerCase()) return true
      return (
        `${b.first_name} ${b.last_name}`.toLowerCase() === name.toLowerCase()
      )
    })
  }, [balances, name])

  return (
    <ProgressCard isDisabled={stepPosition > 0}>
      <ProgressCardTitle text="Adjust deductions" Icon={ChatQuestionIcon} />
      <Text as="h5">
        Once you’ve processed the CSV, let us know if any deductions need
        adjustment.
      </Text>
      <div>
        <Text css={{ my: '$24' }}>
          Were you able to process all deductions?
        </Text>
        <Flex css={{ mb: '$24' }}>
          <Box css={{ mr: '$12' }}>
            <Checkbox
              id={'adjustment'}
              onChange={handleAbleToProcessAdjustments}
              checked={!needsAdjustment}
              label="yes"
            />
          </Box>
          <Box>
            <Checkbox
              id={'adjustment'}
              onChange={handleUnableToProcessAdjustments}
              checked={needsAdjustment}
              label="No, adjustments are needed"
            />
          </Box>
        </Flex>
      </div>
      {needsAdjustment && (
        <div>
          <Box css={{ mb: '$16' }}>
            <Text css={{ my: '$12' }}>Which deductions need adjustments?</Text>
            <Flex css={{ mb: '$4' }}>
              <SearchFilter
                placeholder="Search by name..."
                defaultValue={search}
                handleChange={v => setSearch(v || '')}
              />
            </Flex>
          </Box>
          <SimpleTable
            data={balancesData}
            columns={columns}
            isLoading={balances.isLoading}
            renderSubComponent={row => (
              <RemittanceTransactions
                employeeId={row.original.employee_id}
                remittanceId={remittanceId}
              />
            )}
          />
        </div>
      )}
      <ProgressCardFooter stepComplete={stepPosition < 0}>
        <Button
          mode={stepPosition < 0 ? 'outline' : 'filled'}
          disabled={stepPosition > 0}
          onClick={handleAdjustmentsComplete}
        >
          Adjustments Complete
        </Button>
      </ProgressCardFooter>
    </ProgressCard>
  )
}
