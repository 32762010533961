const SvgAdvanced = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={48} height={49} {...props}>
    <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <circle fill="#5A2A3C54" cx={24} cy={24} r={24} />
      <path
        d="M15.375 20.364c0-.603.503-1.091 1.125-1.091.621 0 1.125.488 1.125 1.09 0 .603-.504 1.092-1.125 1.092s-1.125-.489-1.125-1.091zm15 7.272c0-.602.503-1.09 1.125-1.09.621 0 1.125.488 1.125 1.09 0 .603-.504 1.091-1.125 1.091s-1.125-.488-1.125-1.09zM14.25 16h19.5c1.243 0 2.25.977 2.25 2.182v11.636C36 31.023 34.993 32 33.75 32h-19.5c-1.243 0-2.25-.977-2.25-2.182V18.182C12 16.977 13.007 16 14.25 16zm0 1.455a.739.739 0 0 0-.75.727v11.636c0 .402.336.727.75.727h19.5c.414 0 .75-.325.75-.727V18.182a.739.739 0 0 0-.75-.727h-19.5zM24 27.636c-2.071 0-3.75-1.628-3.75-3.636s1.679-3.636 3.75-3.636c2.071 0 3.75 1.628 3.75 3.636s-1.679 3.636-3.75 3.636zm0-1.454c1.243 0 2.25-.977 2.25-2.182s-1.007-2.182-2.25-2.182-2.25.977-2.25 2.182 1.007 2.182 2.25 2.182z"
        fill="#5A2A3C"
      />
    </g>
  </svg>
)

export default SvgAdvanced
