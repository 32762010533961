import { Moment } from 'moment'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  addPayPeriod,
  deletePayrollPeriod,
  getPayPeriods,
  updatePayrollPeriod,
} from '@/api/payroll'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useToasterStore } from '@/store/toast'

export interface IPayrollRecord {
  payDate: Moment
  deadline: Moment
  id: number
}

export const PAYROLL_QUERY = 'payroll'
export const usePayroll = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  return useQuery(
    [PAYROLL_QUERY],
    async () => {
      const res = await getPayPeriods(orgId)
      return res.data.data
    },
    {
      onError: () => {
        addToast({
          type: 'error',
          title: 'Error',
          description: `Failed to retrieve Payroll Records`,
        })
      },
    }
  )
}

export const useCreatePayPeriod = () => {
  const { id: orgId } = useCurrentOrg()
  return useMutation(async (params: IPayrollRecord) => {
    const res = await addPayPeriod(
      params.deadline.format('YYYY-MM-DD'),
      params.payDate.format('YYYY-MM-DD'),
      orgId
    )
    return res.data
  })
}

export const useUpdatePayPeriod = () => {
  const queryClient = useQueryClient()
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()
  return useMutation(
    async (params: IPayrollRecord) => {
      const res = await updatePayrollPeriod(
        params.id,
        params.deadline.format('YYYY-MM-DD'),
        params.payDate.format('YYYY-MM-DD'),
        orgId
      )
      return res.data
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Success',
          description: `Updated the payroll period.`,
        })
        queryClient.invalidateQueries([PAYROLL_QUERY])
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Something went wrong.',
          description: `Failed to update the payroll period.`,
        })
      },
    }
  )
}

export const useDeletePayPeriod = () => {
  const queryClient = useQueryClient()
  const { addToast } = useToasterStore()
  return useMutation(
    async (id: number) => {
      const res = await deletePayrollPeriod(id)
      return res.data
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Success',
          description: `Deleted the payroll period.`,
        })
        queryClient.invalidateQueries([PAYROLL_QUERY])
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Something went wrong.',
          description: `Failed to delete the payroll period.`,
        })
      },
    }
  )
}
