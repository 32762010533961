import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'

export type TCompleteRegistrationFormValues = {
  pin: string
  firstName: string
  lastName: string
  email: string
}

interface Props {
  phone: string
  onSubmit: (values: TCompleteRegistrationFormValues) => void
}

const registrationInitialValues = {
  pin: '',
  firstName: '',
  lastName: '',
  email: '',
}

const schema = yup.object().shape({
  pin: yup
    .string()
    .matches(/^[0-9]*$/, 'Pin should only contain numbers.')
    .max(6),
  firstName: yup.string().required('First and Last Name is required'),
  lastName: yup.string().required('First and Last Name is required'),
  email: yup
    .string()
    .required('Required')
    .email('Must be a valid email address'),
})

export const CompleteRegistration: FC<Props> = ({ phone, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: registrationInitialValues,
    resolver: yupResolver(schema),
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text size="xl" css={{ pb: '$16' }}>
        Complete Registration
      </Text>
      <FlexWrap direction="column" size="lg" align="start">
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label>Pin sent to {phone}</Label>
          <Input type="text" {...register('pin')} />
          <Text color="alert">{errors?.pin?.message}</Text>
        </Flex>
        <FlexWrap
          direction="row"
          css={{ width: '100%', '@mobile': { flexDirection: 'column' } }}
          justify="between"
          align="start"
          size="sm"
        >
          <Flex
            direction="column"
            align="start"
            css={{ '@mobile': { width: '100%' } }}
          >
            <Label>First Name</Label>
            <Input type="text" {...register('firstName')} />
          </Flex>
          <Flex
            direction="column"
            align="start"
            css={{ '@mobile': { width: '100%' } }}
          >
            <Label>Last Name</Label>
            <Input type="text" {...register('lastName')} />
          </Flex>
          <Text color="alert">
            {errors?.firstName?.message || errors?.lastName?.message}
          </Text>
        </FlexWrap>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label>Email Address</Label>
          <Input type="text" {...register('email')} />
          <Text color="alert">{errors?.email?.message}</Text>
        </Flex>
        <Flex justify="end" css={{ width: '100%' }}>
          <Button type="submit" mode="filled">
            Register
          </Button>
        </Flex>
      </FlexWrap>
    </form>
  )
}
