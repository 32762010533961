import { Link } from 'react-router-dom'
import { ArrowLeftIcon } from '@/common/icons'

interface IProps {
  to: string
  text: string
}

const PageBack = ({ to, text }: IProps) => {
  return (
    <Link to={to} className="page-back">
      <ArrowLeftIcon />
      {text}
    </Link>
  )
}

export default PageBack
