import { FC } from 'react'
import { MultiButtonFilter } from '@/common/filters/MultiButtonFilter'
import { useResourcesStore } from '@/store/resources'
import { resourceTypeOptions } from '../lib/filters'

export const ResourceFilters: FC = () => {
  const { filters, updateFilters } = useResourcesStore()

  return (
    <div>
      <div className="d-flex">
        <div className="mr-2">
          <MultiButtonFilter
            options={resourceTypeOptions}
            value={filters.type}
            handleChange={val => updateFilters({ type: val })}
          />
        </div>
      </div>
    </div>
  )
}
