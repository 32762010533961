import { saveAs } from 'file-saver'
import moment from 'moment'
import Papa from 'papaparse'
import { InitiationUserRecord } from '@/types/create-accounts'
import { showNaIfUndefined, STATUS_LABELS } from './utils'

export const downloadInitiatedUsersCSV = (records: InitiationUserRecord[]) => {
  const csvData = records.map(record => {
    let row: { [k: string]: string | number | null } = {
      'First Name': showNaIfUndefined(record.employee?.first_name),
      'Last Name': showNaIfUndefined(record.employee?.last_name),
      'Worker ID': record.employee
        ? `="${record.employee?.employee_id}"`
        : 'N/A',
      Status: STATUS_LABELS[record.status] || 'record.status',
      StatusCode: showNaIfUndefined(record.status_code),
      'Account Number': record.organization_wallet
        ? `="${record.organization_wallet?.account_number}"`
        : 'N/A',
      'Routing Number': record.organization_wallet
        ? `="${record.organization_wallet?.routing_number}"`
        : 'N/A',
      'Has Activated Card': showNaIfUndefined(
        typeof record.organization_wallet?.has_activated_card === 'boolean'
          ? `${record.organization_wallet?.has_activated_card}`
          : undefined
      ),
      'Last Updated': moment(
        record.time_modified || record.time_created
      ).format(),
      'Onboarding Link': showNaIfUndefined(record?.onboarding_link),
      Phone: showNaIfUndefined(record?.employee?.phone_number),
      Email: showNaIfUndefined(record?.employee?.email_address),
    }
    return row
  })

  const csvContent = Papa.unparse(csvData)

  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'branch_created_accounts.csv')
}
