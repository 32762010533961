import { useMutation, useQuery } from 'react-query'
import { createLocation, getLocations, updateLocation } from '@/api/location'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useToasterStore } from '@/store/toast'
import { CreateLocationFormValues, ILocationFormValues } from '@/types/location'

type UpdateLocationParam = {
  duplicateId: string
  config: ILocationFormValues
}

export const GET_LOCATIONS_QUERY = 'get-locations'

export const useLocationsQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([GET_LOCATIONS_QUERY, orgId], async () => {
    const { data } = await getLocations(orgId)
    return data.data.locations_info
  })
}

export const useUpdateLocation = () => {
  const { addToast } = useToasterStore()

  return useMutation(
    ({ duplicateId, config }: UpdateLocationParam) => {
      return updateLocation(duplicateId, config)
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Successfully edited the location.',
        })
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Could not edit the location.',
        })
      },
    }
  )
}

export const useCreateLocation = () => {
  const { addToast } = useToasterStore()
  return useMutation(
    async (params: CreateLocationFormValues) => {
      const res = await createLocation(params)
      return res.data.data
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Successfully created the location.',
        })
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Could not create the location.',
        })
      },
    }
  )
}
