import { useState } from 'react'
import { Col, FormGroup, Input, Row, Table } from 'reactstrap'
import { Button } from '@/common/button'
import { addDashesToSocial } from '@/lib/socialLib'
import { QualifyEmployeeFormValues } from './QualifyEmployeeForm'

type Props = {
  handleSubmit: () => void
  loading: boolean
  values?: QualifyEmployeeFormValues
}

const CONFIRM = 'CONFIRM'

export const QualifyEmployeeConfirmation: React.FC<Props> = props => {
  const { handleSubmit, loading, values } = props
  const [confirmValue, setConfirmValue] = useState('')

  if (!values) return null

  return (
    <div>
      <h5 className="mb-3">Take a moment to review the details:</h5>
      <Table bordered>
        <tbody>
          <tr>
            <td className="font-weight-bold">Name</td>
            <td>
              {values.firstName} {values.lastName}
            </td>
          </tr>
          <tr>
            <td className="font-weight-bold">ID</td>
            <td>{values.employeeId}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Birthdate</td>
            <td>{values.birthdate}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">SSN</td>
            <td>{addDashesToSocial(values.ssn)}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Phone</td>
            <td>{values.phone}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Email</td>
            <td>{values.email}</td>
          </tr>
          {values.businessName && (
            <tr>
              <td className="font-weight-bold">Business Name</td>
              <td>{values.businessName}</td>
            </tr>
          )}
          {values.ein && (
            <tr>
              <td className="font-weight-bold">EIN</td>
              <td>{values.ein}</td>
            </tr>
          )}
          <tr>
            <td className="font-weight-bold">Address</td>
            <td>
              {values.address1} {values.address2}
              <br />
              {values.city}, {values.state} {values.zip}
            </td>
          </tr>
        </tbody>
      </Table>
      <p>
        If everything looks correct, type{' '}
        <code className="font-weight-bold">CONFIRM</code> into the field below.
      </p>
      <Row>
        <Col md="6">
          <FormGroup>
            <Input
              type="text"
              onChange={e => {
                setConfirmValue(e.currentTarget.value.toUpperCase())
              }}
              value={confirmValue}
              placeholder="CONFIRM"
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="d-flex">
        <Button
          loading={loading}
          disabled={confirmValue !== CONFIRM}
          type="submit"
          mode="filled"
          onClick={handleSubmit}
          css={{ ml: 'auto' }}
        >
          Start Qualification
        </Button>
      </div>
    </div>
  )
}
