import moment from 'moment'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import ContentEmpty from '@/common/empty'
import { EmptyBarChartIcon } from '@/common/icons'
import { IAdvancesInsightsData } from '@/lib/advancesInsights'

interface IProps {
  data: IAdvancesInsightsData[]
}

const renderTooltip = (props: any) => {
  return props.payload ? (
    <Card>
      <CardBody>
        <CardTitle tag="h6" className="font-weight-bold">
          {moment.unix(props.label).format('MMM DD, YYYY')}
        </CardTitle>
        <CardText>
          {props.payload.length > 0 && props.payload[0].value} advances
        </CardText>
      </CardBody>
    </Card>
  ) : null
}

const PayoutsPerDay = (props: IProps) => {
  const totalAdvances = props.data.reduce((count, curr) => {
    return count + curr.advances
  }, 0)

  return (
    <>
      <h5>Advances Per Day</h5>
      {totalAdvances === 0 ? (
        <ContentEmpty
          icon={<EmptyBarChartIcon />}
          title="No data available"
          height="sm"
        />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={600}
            height={300}
            data={props.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="start.seconds"
              tickFormatter={t => moment.unix(t).format('MMM DD YYYY')}
            />
            <YAxis />
            <Tooltip content={renderTooltip} />
            <Bar dataKey="advances" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default PayoutsPerDay
