import { FC } from 'react'
import { DateRangePicker } from '@/common/datepicker'
import { useAdoptionStore } from '@/store/adoption'

export const AdoptionFilters: FC = () => {
  const { filters, updateFilters } = useAdoptionStore()

  return (
    <div>
      <div className="d-flex">
        <div className="mr-2">
          <DateRangePicker
            from={filters.from}
            to={filters.to}
            preset={filters.preset}
            setRange={updateFilters}
          />
        </div>
      </div>
    </div>
  )
}
