import { GetUserInfoResponse } from '@/api/types/employees'
import { IGetAdminsResponse } from '@/api/types/organization'
import http from './http'

// Retrieve all organization admins
export const getAdmins = (orgId: number) =>
  http.get<IGetAdminsResponse>(`p1/organizations/${orgId}/admins`)

// Remove admin from organization

export const removeAdmin = (orgId: number, adminId: string) =>
  http.delete(`p1/organizations/${orgId}/admins/${adminId}`)

// Retrieve user info
export const getUserInfo = (userId: string) =>
  http.get<GetUserInfoResponse>(`/p1/users/${userId}/info`)
