import { Alert } from '@/common/alert'
import { Loader } from '@/common/loader'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { usePartnersQuery } from '@/src/queries/partners'
import { PARTNER_NAMES } from '../lib/partners'
import { ChooseIntegration } from './ChooseIntegration'
import { PdqIntegration } from './pdq/PdqIntegration'
import { QsrIntegration } from './qsr/QsrIntegration'
import { RasiIntegration } from './rasi/RasiIntegration'
import { UkgIntegration } from './ukg/UkgIntegration'

export const IntegrationResolver: React.FC = () => {
  const currentOrg = useCurrentOrg()

  const { data, isLoading } = usePartnersQuery()

  if (isLoading) {
    return <Loader loading={true} />
  }

  if (!data) {
    return null
  }

  if (!currentOrg.partner_id) {
    return <ChooseIntegration partners={data} />
  }

  const integration = data.find(d => d.id === currentOrg.partner_id)

  if (!integration) return null

  if (integration.name === PARTNER_NAMES.UKG) {
    return <UkgIntegration />
  }

  if (integration.name === PARTNER_NAMES.QSR) {
    return <QsrIntegration />
  }

  if (integration.name === PARTNER_NAMES.PDQ) {
    return <PdqIntegration />
  }

  if (integration.name === PARTNER_NAMES.RASI) {
    return <RasiIntegration partnerId={integration.id} />
  }

  return (
    <Alert type="success">
      <strong>{integration.name}</strong> integration is active.
    </Alert>
  )
}
