import { FC } from 'react'
import { SelectableBox } from '@/common/layout'
import { useModalStore } from '@/store/modal'
import { InvoiceNote } from '@/types/invoice'
import { NoteModal } from './NoteModal'

interface Props {
  original: InvoiceNote
}

export const Note: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()
  const openInvoiceNoteModal = () => {
    registerModal(<NoteModal note={original} />)
  }

  return (
    <SelectableBox onClick={openInvoiceNoteModal}>
      {original.note}
    </SelectableBox>
  )
}
