import { FC } from 'react'
import { ContentProblem } from '@/common/empty/ContentProblem'
import { Box } from '@/common/layout'
import PageErrorBoundary from '@/components/page-error-boundary/PageErrorBoundary'

interface Props {
  hasError?: Error | null | boolean
}

export const PageBody: FC<Props> = ({ hasError, children }) => (
  <Box
    css={{
      padding: '1.5rem 2rem 10rem 2rem',
      width: '100%',
      display: ' block',
    }}
  >
    <PageErrorBoundary>
      {hasError ? <ContentProblem /> : children}
    </PageErrorBoundary>
  </Box>
)
