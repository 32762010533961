import { ReactElement } from 'react'
import { create } from 'zustand'

export type ModalStore = {
  modal: ReactElement | null
  registerModal: (modal: ReactElement) => void
  removeModal: () => void
}

export const useModalStore = create<ModalStore>((set, get) => ({
  modal: null,
  registerModal: (modal: ReactElement) => {
    get().removeModal()
    set(state => ({ ...state, modal }))
  },
  removeModal: () => {
    set(state => ({ ...state, modal: null }))
  },
}))
