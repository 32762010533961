import { FC } from 'react'
import { DetailProperty } from '@/common/detail'
import { PayoutRecord } from '@/types/payouts'

interface Props {
  payout: PayoutRecord
}

export const MetaDataSummary: FC<Props> = ({ payout }) => {
  if (!payout.metadata) return null

  const renderValue = (value: any) => {
    return typeof value === 'object' ? JSON.stringify(value) : `${value}`
  }

  return (
    <>
      <h4 className="detail-section-heading">Metadata</h4>
      {Object.keys(payout.metadata).map(
        key =>
          !!payout.metadata && (
            <DetailProperty
              label={key}
              key={key}
              value={payout.metadata[key]}
              renderValue={renderValue}
              canCopy
            />
          )
      )}
    </>
  )
}
