import { FC } from 'react'
import { Button } from '@/common/button'
import { AdminIcon } from '@/common/icons'
import InstantCardsOrderModal from '@/components/order-instant-cards/InstantCardsOrderModal'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import styles from '../index.module.scss'

export const MarketingMessage: FC = () => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const openInstantCardsOrderModal = () => {
    registerModal(<InstantCardsOrderModal />)
  }

  return (
    <div className={styles.MarketingMessage}>
      <div className="mb-4">
        <img src="/images/card.png" alt="Card" />
      </div>
      <h2 className="mb-4">Give them a card on day one.</h2>
      <p className="mb-4">
        Order a batch of Instant Cards and give them out when you please.
      </p>
      <ul className="mb-4" style={{ textAlign: 'left' }}>
        <li>All the banking features you expect</li>
        <li>No overdraft or additional ATM fees</li>
        <li>Send tips directly to an account</li>
      </ul>

      {isGlobalAdmin ? (
        <Button
          size="lg"
          mode="filled"
          iconRight
          onClick={openInstantCardsOrderModal}
        >
          Order Cards
          <AdminIcon />
        </Button>
      ) : (
        <p>
          <i>Contact your Branch success agent to order cards.</i>
        </p>
      )}
    </div>
  )
}
