import { FC, useMemo } from 'react'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { Table } from '@/common/table'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useRemittancesQuery } from '@/queries/remittances'
import { useRemittances } from '@/store/remittances'
import { DeductionReportsContainer } from './components/DeductionReportsContainer'
import { columns } from './lib/columns'

const initialSortedState = [{ id: 'id', desc: true }]

export const DeductionReports: FC = () => {
  const {
    page,
    pageSize,
    updatePage,
    updatePageSize,
    sorted,
    setSorted,
  } = useRemittances()

  const { data, isLoading, isError } = useRemittancesQuery()

  const remittances = useMemo(() => data?.content || [], [data])

  const hasNoDeductionReports = data && data.content.length < 1

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    data?.total_pages
  )

  return (
    <DeductionReportsContainer error={isError}>
      {hasNoDeductionReports ? (
        <ContentEmpty
          icon={<EmptyTableIcon />}
          title="No payroll deduction reports"
          height="sm"
          subtitle="Once you have payroll deduction reports available to you, you’ll be able to find them here."
        />
      ) : (
        <Table
          columns={columns}
          data={remittances}
          controlledPagination={true}
          isLoading={isLoading}
          page={page}
          sorted={sorted}
          onSortedChange={setSorted}
          initialSortedState={initialSortedState}
          pageRows={pageSize}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          changePage={updatePage}
          updatePageSize={updatePageSize}
        />
      )}
    </DeductionReportsContainer>
  )
}
