import { CrossCircledIcon, Pencil1Icon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useDeleteOrgRole } from '@/queries/permissions'
import { useModalStore } from '@/store/modal'
import { OrganizationRole } from '@/types/permissions'
import { ConfigureRoleModal } from './ConfigureRoleModal'

interface Props {
  original: OrganizationRole
}

export const ActionCell: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()
  const deleteOrgRole = useDeleteOrgRole()

  const openRemoveModal = () => {
    registerModal(
      <AlertDialog
        title="Are you sure?"
        description={`By removing your ${original.name} role, all users that are assigned to this role will no longer have access to Pay Admin if a new role is not assigned.`}
        actionDescription="Remove"
        isDestructive={true}
        handleAction={() => deleteOrgRole.mutateAsync(original.id)}
      />
    )
  }

  const openEditModal = () => {
    registerModal(<ConfigureRoleModal role={original} />)
  }

  return (
    <TableActionDropdown
      menu={[
        {
          label: 'Edit',
          handleClick: openEditModal,
          icon: <Pencil1Icon />,
          hide: original.name === 'Pay Admin',
        },
        {
          label: 'Remove',
          handleClick: openRemoveModal,
          icon: <CrossCircledIcon />,
          hide: original.name === 'Pay Admin',
        },
      ]}
    />
  )
}
