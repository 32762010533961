import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { styled } from '@/styles/stitches.config'
import { Flex } from '../layout'

export const TableActionTrigger = styled(Flex, {
  height: 30,
  width: 30,
  br: '$lg',
  '&:hover': { backgroundColor: '$secondary700' },
  justifyContent: 'center',
  textAlign: 'center',
})

export const TableActionTriggerIcon = styled(DotsHorizontalIcon, {
  height: 25,
  width: 25,
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const TableContainer = styled('div', {
  height: '100%',
  width: '100%',
  overflowY: 'scroll',
  border: '1px solid #E1E1E1',

  fontSize: 14,

  table: {
    width: '100%',
    borderSpacing: 0,
    tableLayout: 'fixed',
    align: 'center',

    tr: {
      borderLeft: 'none',
      borderRight: 'none',
    },

    td: {
      margin: 0,
      padding: '0.5rem',
      border: '1px solid #E1E1E1',
      overflow: 'hidden',
      height: '30px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:first-child': {
        borderLeft: 'none',
      },
    },

    th: {
      margin: 0,
      padding: '0.5rem',
      border: '1px solid #E1E1E1',
      borderTop: 'none',
      height: 50,
      backgroundColor: '#F8F6F4',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      '&:first-child': {
        borderLeft: 'none',
      },
    },
  },
  variants: {
    canHover: {
      true: {
        table: {
          tr: {
            '&:hover': {
              color: '$secondary300',
              backgroundColor: '$secondary900',
              cursor: 'pointer',
            },
          },
        },
      },
    },
    hideLastColumnBorder: {
      true: {
        table: {
          'tr, th, td': {
            '&:nth-last-child(-n+2)': {
              borderRight: 'none',
              borderLeft: 0,
            },
          },
        },
      },
    },
  },
})

export const TableColumnHeader = styled('th', {
  variants: {
    highlighted: {
      true: {
        color: '$primary500',
      },
    },
  },
})

export const TableBodyColumn = styled('td', {})

export const TablePaginationContainer = styled(Flex, {
  fontSize: '17px',
  fontWeight: 700,
  ml: 'auto',
})

export const Resizer = styled('div', {
  position: 'absolute',
  right: '0',
  top: '0',
  height: '100%',
  width: '$4',
  cursor: 'col-resize',
  userSelect: 'none',
  touchAction: 'none',
  '&:hover': { backgroundColor: '$secondary500' },
  variants: {
    resizing: {
      true: {
        background: '$primary500',
      },
    },
  },
})
