import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { IDeduction } from '@/types/remittances'

export const downloadDeductions = (
  deductions: IDeduction[],
  remittanceId: number
) => {
  const data = deductions.map(rec => ({
    EmployeeID: rec.employee.employee_id,
    FirstName: rec.employee.first_name,
    LastName: rec.employee.last_name,
    RequestedAmount: (rec.requested_amount / 100).toFixed(2),
    DeductedAmount: (rec.amount / 100).toFixed(2),
  }))
  const csvContent = Papa.unparse(data)

  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, `remittance_${remittanceId}.csv`)
}
