const ResourcesIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg width={17} height={20} viewBox="0 0 17 20" {...props}>
    <path
      d="M13.268 18.333c.458 0 .83.373.83.834 0 .46-.372.833-.83.833H2.488A2.494 2.494 0 010 17.5V2.917c0-.46.371-.834.83-.834.457 0 .829.373.829.834V17.5c0 .46.37.833.829.833h10.78zm3.368-15.121c.234.234.365.552.364.883v11.738c0 .69-.557 1.25-1.244 1.25H4.146c-.687 0-1.244-.56-1.244-1.25V1.25C2.902.56 3.46 0 4.146 0h8.779c.331 0 .649.132.882.368l2.829 2.844zm-7.6 7.821a.71.71 0 00.708-.712.71.71 0 00-.708-.712H6.09a.71.71 0 00-.708.712.71.71 0 00.708.712h2.946zm3.429-2.847a.71.71 0 00.708-.712.71.71 0 00-.708-.712H6.09a.71.71 0 00-.708.712.71.71 0 00.708.712h6.375z"
      fill="#4CB258"
      fillRule="evenodd"
    />
  </svg>
)

export default ResourcesIcon
