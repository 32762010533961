import classnames from 'classnames'
import { forwardRef } from 'react'
import styles from './index.module.scss'

type Props = React.InputHTMLAttributes<HTMLInputElement>

const SearchDropdownInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { className, ...rest } = props
    const classes = classnames(styles.SearchInput, className)

    return (
      <input
        type="text"
        onKeyPress={e => {
          e.key === 'Enter' && e.preventDefault()
        }}
        ref={ref}
        className={classes}
        {...rest}
      >
        {props.children}
      </input>
    )
  }
)

export default SearchDropdownInput
