import { IEmployeeDirectDepositFormResponse } from '@/api/types/employees'
import http from './http'

export const getDirectDepositForms = (orgId: number) =>
  http.get<IEmployeeDirectDepositFormResponse>(
    `p1/organizations/${orgId}/direct_deposit_forms`
  )

/**
 * Retrieves the file data for an employee's direct deposit form.
 */
export const getDirectDepositForm = (id: number, orgId: number) =>
  http.get<any>(`p1/organizations/${orgId}/direct_deposit_forms/${id}`, {
    responseType: 'arraybuffer',
  })
