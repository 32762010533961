import { PayoutRecord } from '@/types/payouts'

export const makeMetaDataColumns = (records: PayoutRecord[]) => {
  let collectedMetaData: {
    [key: string]: any
  } = {}

  records.forEach(record => {
    if (!record.metadata) return
    // combine this metadata object with the
    // aggregator object on every loop.
    // Don't have to worry about dupes
    collectedMetaData = {
      ...collectedMetaData,
      ...record.metadata,
    }
  })

  // the code below creates a column object for
  // each key in the metadata collection
  return Object.keys(collectedMetaData).map(k => ({
    Header: k,
    accessor: `metadata.${k}`,
    filterable: true,
    sortable: false,
  }))
}
