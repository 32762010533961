import { styled } from '@/styles/stitches.config'

export const VisibilityButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  height: '32px',
  padding: 0,
  border: 'none',
  borderRadius: '$lg',
  background: 'none',
  marginLeft: '$8',

  '&:hover': {
    backgroundColor: '$secondary900',
  },
})
