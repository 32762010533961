import { FC } from 'react'
import { DateRangePreset } from '@/common/datepicker/types'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useExportWorkerPeriodEarnings } from '@/queries/employees'
import { useGrossEarningRecordsStore } from '@/store/gross-earning-records'
import { additionalFilters } from '../lib/filters'

export const GrossEarningFilters: FC = () => {
  const {
    filters,
    updateFilters,

    removeFilter,
  } = useGrossEarningRecordsStore()
  const exports = useExportWorkerPeriodEarnings()

  return (
    <TableFiltersMain
      filters={filters}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
      downloadLoading={exports.isLoading}
      handleDownload={exports.refetch}
      removeFilter={removeFilter}
      allowedDatePickerPresets={[
        DateRangePreset.LAST_WEEK,
        DateRangePreset.LAST_MONTH,
      ]}
    />
  )
}
