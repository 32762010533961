import { ChangeEvent, FC, useCallback, useState } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex, FlexGrid } from '@/common/layout'
import { ToggleSwitch } from '@/common/toggleswitch/ToggleSwitch'
import { useCreateResource } from '@/queries/resources'

interface Props {
  file: File
}

export const ResourceItem: FC<Props> = ({ file }) => {
  const createResource = useCreateResource()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isPublic, setIsPublic] = useState(false)

  const handleDescriptionChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setDescription(evt.currentTarget.value)
    },
    [setDescription]
  )

  const handleNameChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setTitle(evt.currentTarget.value)
    },
    [setTitle]
  )

  const upload = () => {
    createResource.mutate({
      title,
      description,
      file,
      public: isPublic,
    })
  }

  return (
    <FlexGrid direction="column" align="start" fullWidth>
      <br />
      <strong>{file.name}</strong>
      <div>
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          name="name"
          value={title}
          onChange={handleNameChange}
          placeholder="Document name"
        />
      </div>
      <div>
        <Label htmlFor="description">Description</Label>
        <Input
          type="text"
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Description"
        />
      </div>
      <Box css={{ mt: '$12' }}>
        <Label>
          <Flex>
            <ToggleSwitch
              toggle={() => setIsPublic(!isPublic)}
              tooltip={isPublic ? 'Public' : 'Private'}
              enabled={isPublic}
            />
            <Box css={{ ml: '$8' }}>
              Accessible by Public URL (Brand Assets)
            </Box>
          </Flex>
        </Label>
      </Box>
      {createResource.isError && (
        <Alert type="danger" css={{ mt: '$8' }}>
          Resource upload failed.
        </Alert>
      )}
      {createResource.isSuccess && (
        <Alert type="success" css={{ mt: '$8' }}>
          Resource uploaded.
        </Alert>
      )}
      <Flex justify="end">
        {!createResource.isSuccess && (
          <Button
            mode="filled"
            type="submit"
            onClick={upload}
            loading={createResource.isLoading}
            disabled={createResource.isLoading}
          >
            Upload
          </Button>
        )}
      </Flex>
    </FlexGrid>
  )
}
