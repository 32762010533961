import { FC } from 'react'
import { ToggleGroupContainer, ToggleGroupItem } from './styles'

type Option = {
  id: string
  label: string
}

interface CommonProps {
  id: string
  options: Option[]
  disabled?: boolean
}

interface SingleProps extends CommonProps {
  selectType: 'single'
  value: string
  onChange: (id: string, value: string) => void
  onSelect?: (value: string) => void
}

interface MultiProps extends CommonProps {
  selectType: 'multiple'
  value: string[]
  onChange: (value: string[]) => void
  onSelect?: (value: string) => void
}

export const ToggleMultipleGroup: FC<MultiProps> = ({
  id,
  selectType,
  value,
  options,
  disabled,
  onSelect,
  onChange,
}) => {
  return (
    <ToggleGroupContainer
      type={selectType}
      value={value}
      onValueChange={(v: string[]) => onChange(v)}
    >
      {options.map(rec => (
        <ToggleGroupItem
          key={rec.id}
          value={rec.id}
          onClick={() => onSelect && onSelect(rec.id)}
          disabled={disabled}
        >
          {rec.label.toLowerCase()}
        </ToggleGroupItem>
      ))}
    </ToggleGroupContainer>
  )
}

export const ToggleSingleGroup: FC<SingleProps> = ({
  id,
  selectType,
  value,
  options,
  disabled,
  onSelect,
  onChange,
}) => {
  return (
    <ToggleGroupContainer
      type={selectType}
      value={value}
      onValueChange={(v: string) => {
        // as written a value is required
        if (v) onChange(id, v)
      }}
    >
      {options.map(rec => (
        <ToggleGroupItem
          key={rec.id}
          value={rec.id}
          onClick={() => onSelect && onSelect(rec.id)}
          disabled={disabled}
        >
          {rec.label.toLowerCase()}
        </ToggleGroupItem>
      ))}
    </ToggleGroupContainer>
  )
}
