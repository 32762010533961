import { FC } from 'react'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { downloadDDForm } from '@/lib/downloadDDForm'
import { useToasterStore } from '@/store/toast'
import { IEmployeeDirectDepositForm } from '@/types/employees'

interface Props {
  original: IEmployeeDirectDepositForm
}

export const DirectDepositCell: FC<Props> = ({ original }) => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()
  const {
    employee: { employee_id },
    direct_deposit_form,
  } = original

  const handleDownload = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
    if (!direct_deposit_form || !employee_id) return
    try {
      await downloadDDForm(direct_deposit_form, employee_id, orgId)
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Download Failed',
        description:
          "We weren't able to download the verification file at this time.",
      })
    }
  }
  return (
    <div className="link" onClick={handleDownload}>
      Download
    </div>
  )
}
