import qs from 'qs'
import { TQualifyEmployeeData } from '@/types/create-accounts'
import http from './http'
import {
  GetBatchDetailsResponse,
  GetInitiatedUsersRequestParams,
  QualifyEmployeeResponse,
} from './types/create-accounts'

/**
 * Retrieves an organization's roster report
 */
export const getOrganizationEmployeesReport = (orgId: number, params: any) =>
  http.get<string>(
    `/p1/organizations/${orgId}/employee_details/report?${qs.stringify(params)}`
  )

/**
 * Allows Pay Admins to bulk qualify employees through our KYC card process
 * @param config Contains KYC data for each user
 */
export const qualifyEmployees = (orgId: number, config: TQualifyEmployeeData) =>
  http.post<QualifyEmployeeResponse>(
    `/p1/organizations/${orgId}/employees/${config.employee_id}/initiated_users`,
    config
  )

/**
 * Allows Pay Admins to bulk qualify employees through our KYC card process using a CSV
 * @param file CSV containing KYC data for each user
 */
export const bulkQualifyEmployees = (orgId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return http.post(
    `wfm/organizations/${orgId}/processors/standardAccountFileProcessor/jobs`,
    formData
  )
}

/**
 * Allows Pay Admins to cancel the KYC inquiry of an individual user
 */
export const cancelKyc = (orgId: number, initiatedUserId: number) =>
  http.put(
    `/p1/organizations/${orgId}/initiated_users/${initiatedUserId}/cancel`
  )

/**
 * Allows Pay Admins to get all initiation status of employees
 */
export const getInitiatedUsers = (
  orgId: number,
  params: GetInitiatedUsersRequestParams
) => {
  const queryParams = qs.stringify(params)
  return http.get<GetBatchDetailsResponse>(
    `/p1/organizations/${orgId}/initiated_users?${queryParams}`
  )
}
