import { usePermissionsStore } from '@/store/permissions'
import { AdminUsers } from './components/AdminUsers'
import { Company } from './components/Company'
import { CompanyContainer } from './components/CompanyContainer'
import { CompanyDetails } from './components/CompanyDetails'

export const CompanySettings = () => {
  const { isEnabledOrg } = usePermissionsStore(state => state.computed)
  return (
    <CompanyContainer>
      <CompanyDetails />
      {!isEnabledOrg && <AdminUsers />}
      <Company />
    </CompanyContainer>
  )
}
