import { useCurrentOrgInitializer } from './useCurrentOrgInitializer'
import { usePartnerRosterInitializer } from './useSharedPartnerRoster'
import { useUserDetailsInitializer } from './useUserDetailsInitializer'
import { useUserOrgsInitializer } from './useUserOrgsInitializer'
import { useUserRolesInitializer } from './useUserRolesInitializer'

export const useAuthenticatedUserInitializer = () => {
  const orgs = useUserOrgsInitializer()
  const userDetails = useUserDetailsInitializer()
  const userRoles = useUserRolesInitializer()
  const currentOrg = useCurrentOrgInitializer()
  const partnerRoster = usePartnerRosterInitializer()

  const isInitialized =
    orgs.isInitialized &&
    userDetails.isInitialized &&
    userRoles.isInitialized &&
    currentOrg.isInitialized &&
    partnerRoster.isInitialized

  return {
    isInitialized,
  }
}
