import moment from 'moment'
import { FC } from 'react'
import { IconButton } from '@/common/button'
import { DetailProperty } from '@/common/detail'
import { CloseIcon } from '@/common/icons'
import { JobsRecord } from '@/types/jobs'

interface Props {
  record: JobsRecord
  remove: () => void
}

export const JobDetailsView: FC<Props> = ({ record, remove }) => {
  return (
    <>
      <div className="detail-header">
        <h2 className="detail-heading">Job Details</h2>
        <div className="detail-actions">
          <IconButton onClick={remove}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <hr className="detail-heading-hr" />

      <div className="detail-section">
        <DetailProperty
          label="Completed At"
          value={moment(record.time_completed).format('MM/DD/YYYY h:mmA')}
        />

        <DetailProperty label="Filename" value={record.filename} canCopy />

        <DetailProperty label="Job Name" value={record.job_name} canCopy />

        <DetailProperty label="ID" value={record.id} canCopy />

        <DetailProperty label="Job Status" value={record.job_status} />

        <DetailProperty
          label="Status Reason"
          value={record.additional_info}
          canCopy
        />
      </div>

      {record.summary && (
        <div className="detail-section">
          <h4 className="detail-section-heading">Summary Data</h4>
          <pre className="detail-section-code">
            {JSON.stringify(record.summary, null, 2)}
          </pre>
        </div>
      )}
    </>
  )
}
