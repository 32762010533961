import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import { NAV_GROUPS, navGroups } from '@/router/menus'

export const useNavIsActive = (group: NAV_GROUPS) => {
  const location = useLocation()

  return useMemo(() => {
    const navGroupItems = navGroups[group]

    return navGroupItems.some(
      i =>
        !!matchPath(location.pathname, {
          path: i.route,
          exact: true,
          strict: true,
        })
    )
  }, [group, location])
}
