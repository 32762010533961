import { useState } from 'react'
import { Modal } from 'reactstrap'
import { MatchObject } from '@/components/spreadsheet-mapper/useMatchStore'
import { StepGroup, Transition } from '@/components/stepped-modal'
import useSteps from '@/src/hooks/useSteps'
import { useModalStore } from '@/store/modal'
import { ColumnMatchStep, FileUploadStep, ValidateStep } from './components'
import { STEP_IDS, STEPS } from './lib/rosterSteps'

export const ImportRosterContainer = () => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const [columnMatches, setColumnMatches] = useState<{
    [key: string]: string
  }>()
  const [file, setFile] = useState<File>()
  const { currentStep, goToNextStep } = useSteps(STEPS)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleColumnMatchComplete = (matches: { [key: string]: string }) => {
    setColumnMatches(matches)
    goToNextStep()
  }

  return (
    <Modal isOpen={open} toggle={close} centered className="jumbo light-bg">
      <StepGroup>
        <Transition in={currentStep.id === STEP_IDS.UPLOAD}>
          <FileUploadStep
            file={file}
            handleFile={f => setFile(f)}
            handleComplete={goToNextStep}
          />
        </Transition>
        <Transition in={currentStep.id === STEP_IDS.MATCH}>
          <ColumnMatchStep
            handleComplete={handleColumnMatchComplete}
            closeModal={close}
            file={file as File}
          />
        </Transition>
        <Transition in={currentStep.id === STEP_IDS.PROCESS}>
          <ValidateStep
            columnMatches={columnMatches as MatchObject}
            file={file as File}
            closeModal={close}
          />
        </Transition>
      </StepGroup>
    </Modal>
  )
}
