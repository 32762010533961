import classnames from 'classnames'
import copy from 'copy-to-clipboard'
import { useCallback, useRef } from 'react'
import { ClipboardCopyIcon } from '@/common/icons'
import { useToasterStore } from '@/store/toast'

interface Props {
  featured?: boolean
  title?: string
}

export const DetailCopyableValue: React.FC<Props> = ({
  featured,
  title,
  children,
}) => {
  const { addToast } = useToasterStore()
  const ref = useRef<HTMLDivElement>(null)

  const handleCopy = useCallback(() => {
    if (ref?.current) {
      copy(ref.current.innerText)
      addToast({
        type: 'success',
        title: `${title || ''} Copied`,
      })
    }
  }, [addToast, title])

  const classes = classnames('detail-copyable-value', {
    featured,
  })

  return (
    <div className={classes} ref={ref} onClick={handleCopy}>
      {children}
      <ClipboardCopyIcon className="ml-2" width={16} height={16} />
    </div>
  )
}
