import { ChevronDownIcon } from '@/common/icons'
import { SearchDropdownButton } from '@/components/search-dropdown-field'
import RosterListItem from './RosterListItem'
import { IListItem } from './types'

interface IProps {
  selectedItem?: IListItem
  handleClick: () => void
}

const RosterSelectField: React.FC<IProps> = props => {
  const { selectedItem, handleClick } = props

  return (
    <SearchDropdownButton onClick={handleClick}>
      <div className="w-100 d-flex align-items-center">
        <span className="mr-auto">
          {selectedItem ? (
            <RosterListItem entry={selectedItem} />
          ) : (
            <>Enter someone's ID</>
          )}
        </span>

        <ChevronDownIcon width={12} />
      </div>
    </SearchDropdownButton>
  )
}

export default RosterSelectField
