import { DragHandleDots2Icon } from '@radix-ui/react-icons'
import { Card } from '@/common/card'
import { Text } from '@/common/text'
import { styled } from '@/styles/stitches.config'

export const DragIcon = styled(DragHandleDots2Icon, {
  transform: 'scale(2.5)',
  width: '12.22px',
  left: 'calc(50% - 12.22px/2 + 0px)',
  top: '8.33%',
  bottom: '8.33%',
  br: '$sm',
  cursor: 'move',
  '&:hover': {
    backgroundColor: `$secondary800`,
  },
  variants: {
    locked: {
      true: {
        opacity: 0.4,
      },
    },
  },
})

export const DraggableCardContainer = styled(Card, {
  mb: '$8',
  ml: '$12',
  width: '100%',
})

export const ColumnStatusText = styled(Text, {
  backgroundColor: '$secondary800',
  padding: '3.5px 8px',
  br: '$md',
  color: 'black',
  mr: '$12',
})
