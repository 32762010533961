export const toDollar = (
  centsAmount: string | number | null = 0,
  config?: { includeSymbol?: boolean; commafy?: boolean }
) => {
  const { includeSymbol = true, commafy = true } = config || {}
  const pre = includeSymbol ? '$' : ''
  const isValid = ['string', 'number'].includes(typeof centsAmount)
  if (!isValid) return null
  if (!!centsAmount) {
    let formatted = `${(parseFloat(`${centsAmount}`) / 100).toFixed(2)}`
    if (commafy) {
      formatted = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return `${pre}${formatted}`
  }
  return `${pre}0`
}

export const toCents = (dollarAmount: string): string => {
  const val = parseFloat(dollarAmount.replace('$', ''))
  if (Object.is(NaN, val)) {
    return ''
  }
  return (val * 100).toFixed()
}

export const parseAmount = (amount?: string) => {
  if (!amount) return 0
  const value = amount.replace(/[^0-9a-zA-Z.]/g, '')
  const parsedValue = parseFloat(value)
  return parsedValue ? parsedValue : 0
}

// Takes a value (usually from response data), converts it to a number and multiplies it by the multiplier
// Useful for converting cents to dollars, percentages, etc.
export const convert = (multiplier: number, value?: any, fallback?: any) => {
  if (value === '' || value === undefined || value === null) return fallback
  const numericValue = typeof value === 'number' ? value : parseFloat(value)
  return numericValue * multiplier
}
