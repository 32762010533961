import { FC } from 'react'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { ScrollArea } from '@/common/scroll-area'
import { IPayrollRecord } from '@/queries/payroll'
import { PayrollRecordRow } from './PayrollRecordRow'

interface Props {
  payRoll: IPayrollRecord[]
  setPendingPayroll: (pendingPayroll: IPayrollRecord[]) => void
  saveState: 'save' | 'pending'
}

export const PayRollRecords: FC<Props> = ({
  payRoll,
  setPendingPayroll,
  saveState,
}) => {
  return (
    <>
      {payRoll.length < 1 ? (
        <i>No payroll dates set.</i>
      ) : (
        <Flex direction="row" css={{ pb: '$12', width: '100%' }} align="center">
          <Box css={{ width: '37%' }}>
            <Label>Payroll Deadline</Label>
          </Box>
          <Box css={{ width: '33%' }}>
            <Label>Pay Date</Label>
          </Box>
          <Box css={{ width: '25%' }}></Box>
        </Flex>
      )}
      <Box css={{ height: 500, width: '100%' }}>
        <ScrollArea>
          {payRoll.map(record => (
            <PayrollRecordRow
              key={record.id}
              deadline={record.deadline}
              payDate={record.payDate}
              id={record.id}
              setPendingPayroll={setPendingPayroll}
              payRoll={payRoll}
              saveState={saveState}
            />
          ))}
        </ScrollArea>
      </Box>
    </>
  )
}
