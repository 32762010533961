import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { toDollar } from '@/lib/currency'
import { WorkerPeriodEarning } from '@/types/employees'

export const columns: ColumnDef<WorkerPeriodEarning, any>[] = [
  {
    header: 'Date Earned',
    id: 'date_earned',
    accessorFn: row => moment(row.date_earned).format('MM-DD-YYYY'),
  },
  {
    header: 'First Name',
    id: 'first_name',
    accessorFn: row => row.first_name,
  },
  {
    header: 'Last Name',
    id: 'last_name',
    accessorFn: row => row.last_name,
  },
  {
    header: 'ID',
    id: 'employee_id',
    accessorFn: row => row.employee_id,
  },
  {
    header: 'Amount',
    id: 'amount',
    accessorFn: row => toDollar(row.amount),
  },
]
