import { useState } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { postSignupTextCode, signup } from '@/api/auth'
import { Alert } from '@/common/alert'
import { Text } from '@/common/text'
import { ROUTES } from '@/router/routes'
import { getCachedCurrentOrganizationKey } from '@/src/sessionstorage'
import { useUser } from '@/store/user'
import {
  CompleteRegistration,
  TCompleteRegistrationFormValues,
} from './CompleteRegistration'
import LoginTransition from './LoginTransition'
import { SendPinForm } from './SendPinForm'
import { LoginContainer } from './styles'
import UnauthorizedUserCard from './UnauthorizedUserCard'

enum Steps {
  SendPin = 'SendPin',
  CompleteRegistration = 'CompleteRegistration',
  RequestAccess = 'RequestAccess',
}

export const Register = () => {
  const { parseToken } = useUser()
  const [step, setStep] = useState(Steps.SendPin)
  const [error, setError] = useState('')
  const [phone, setPhone] = useState('')
  const location = useLocation()
  const history = useHistory()

  const handleSendPin = async (phone: string) => {
    await postSignupTextCode(phone)
    setPhone(phone)
    setStep(Steps.CompleteRegistration)
  }

  const handleRegistrationComplete = async (
    values: TCompleteRegistrationFormValues
  ) => {
    setError('')
    const inviteCode =
      location.search && new URLSearchParams(location.search).get('invite_code')

    try {
      const res = await signup({
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        phone,
        phone_validation_code: values.pin,
        invite_code: inviteCode || undefined,
      })
      parseToken(res.data.data.token)

      let path = '/workers'
      const orgKey = getCachedCurrentOrganizationKey()
      if (orgKey) {
        path = generatePath(ROUTES.WORKERS, {
          orgKey,
        })
      }

      history.push(path)
    } catch (e) {
      setError(e.response.data.meta.errorDetail)
      setStep(Steps.SendPin)
    }
  }

  return (
    <LoginContainer>
      <LoginTransition key={Steps.SendPin} in={step === Steps.SendPin}>
        <SendPinForm
          handleSendPin={handleSendPin}
          title="Sign up for Pay Admin"
        />
      </LoginTransition>
      <LoginTransition
        key={Steps.CompleteRegistration}
        in={step === Steps.CompleteRegistration}
      >
        <CompleteRegistration
          phone={phone}
          onSubmit={handleRegistrationComplete}
        />
      </LoginTransition>
      <LoginTransition
        key={Steps.RequestAccess}
        in={step === Steps.RequestAccess}
      >
        <UnauthorizedUserCard />
      </LoginTransition>
      {error && (
        <Alert type="danger" css={{ mt: '$4' }}>
          {error}
        </Alert>
      )}
      <Text css={{ display: 'block', mt: '$16' }}>
        Already have an account? <Link to={ROUTES.LOGIN}>Log In</Link>
      </Text>
    </LoginContainer>
  )
}
