import { useCallback, useState } from 'react'

export const useAdditionalFilters = () => {
  const [additionalFiltersOpen, setAdditionalFiltersOpen] = useState(false)
  const [
    additionalFiltersInitialFocusId,
    setAdditionalFiltersInitialFocusId,
  ] = useState<string>()

  // Optional parameter ID can be passed to focus
  // on that field on open
  const toggleAdditionalFilters = useCallback(
    (state: boolean, id?: string) => {
      setAdditionalFiltersOpen(state)
      setAdditionalFiltersInitialFocusId(state ? id : undefined)
    },
    [setAdditionalFiltersOpen, setAdditionalFiltersInitialFocusId]
  )

  return {
    toggleAdditionalFilters,
    additionalFiltersInitialFocusId,
    additionalFiltersOpen,
  }
}
