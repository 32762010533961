import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'

export type ExceptionOptionProps = {
  onHttpError: (err: AxiosError) => void
  onOtherError?: (err: unknown) => void
}

/** Pass an exception error and use the callbacks to handle different error types */
export const handleHttpException = (
  error: unknown,
  options: ExceptionOptionProps
) => {
  if (axios.isAxiosError(error)) {
    options.onHttpError(error)
  } else {
    Sentry.captureException(error)
    options.onOtherError && options.onOtherError(error)
  }
}
