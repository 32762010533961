import {
  Matchers,
  Questions,
} from '@/components/spreadsheet-mapper/useMatchStore'

export const questions: Questions = {
  hasRateType: {
    text: 'do you have a column indicating rate type? (hourly, salary, etc.)',
    label: 'Rate type column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasRate: {
    text: "do you have a table column for each worker's rate?",
    label: 'Rate column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasPhone: {
    text: "do you have a table column for each worker's phone number?",
    label: 'Phone number column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasEmail: {
    text: "do you have a table column for each worker's email address?",
    label: 'Email column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasLocation: {
    text: "do you have a table column for each worker's location?",
    label: 'Location column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasBusinessName: {
    text: 'do you have a table column for a business name?',
    label: 'Business name column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
}

const hasRate = (questions: Questions): boolean => {
  return !questions.hasRate.value
}

const hasRateType = (questions: Questions): boolean => {
  return !questions.hasRateType.value
}

const hasPhone = (questions: Questions): boolean => {
  return !questions.hasPhone.value
}

const hasEmail = (questions: Questions): boolean => {
  return !questions.hasEmail.value
}

const hasLocation = (questions: Questions): boolean => {
  return !questions.hasLocation.value
}

const hasBusinessName = (questions: Questions): boolean => {
  return !questions.hasBusinessName.value
}

export const matchers: Matchers = {
  employeeId: {
    dataField: 'employee_id',
    label: "Worker's ID",
  },
  firstName: {
    dataField: 'first_name',
    label: 'First Name',
  },
  lastName: {
    dataField: 'last_name',
    label: 'Last Name',
  },
  rateType: {
    dataField: 'shift_type',
    label: 'Rate Type',
    shouldHide: hasRateType,
  },
  rate: {
    dataField: 'rate',
    label: 'Rate',
    shouldHide: hasRate,
  },
  phone: {
    dataField: 'phone_number',
    label: 'Phone',
    shouldHide: hasPhone,
  },
  email: {
    dataField: 'email_address',
    label: 'Email',
    shouldHide: hasEmail,
  },
  location: {
    dataField: 'location_metadata',
    label: 'Location',
    shouldHide: hasLocation,
  },
  businessName: {
    dataField: 'business_name',
    label: 'Business Name',
    shouldHide: hasBusinessName,
  },
}
