import { Label } from '@/common/label'
import { Loader } from '@/components/common/loader'
import { CreateSubAccount } from '@/components/subaccount/components/CreateSubAccount'
import { SubAccountCard } from '@/components/subaccount/components/SubAccountCard'
import { useSubAccountQuery } from '@/queries/sub-account'

export const SubAccount = () => {
  const { isLoading, data } = useSubAccountQuery()

  return (
    <>
      <Label>Sub Account</Label>
      <p>
        Organizations can send outstanding payments to a Branch Sub Account.
        This simplifies billing for both Branch customers and our accounting
        team.
      </p>
      <Loader loading={isLoading}>
        {!data ? <CreateSubAccount /> : <SubAccountCard subAccount={data} />}
      </Loader>
    </>
  )
}
