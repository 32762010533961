import { useEffect, useMemo } from 'react'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { Box } from '@/common/layout'
import { Loader } from '@/common/loader'
import { Table } from '@/common/table'
import { SimpleTable } from '@/common/table/SimpleTable'
import { Text } from '@/common/text'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useJobsQuery } from '@/queries/jobs'
import { useSidebar } from '@/src/hooks/useSidebar'
import {
  useProcessorsQuery,
  useRegistrationsQuery,
} from '@/src/queries/file-processing'
import { useFileProcessingSettings } from '@/store/file-processing-settings'
import { JobsRecord } from '@/types/jobs'
import { FileProcessingContainer } from './components/FileProcessingContainer'
import { JobDetailsView } from './components/JobDetailsView'
import { RegistrationDetails } from './components/RegistrationDetails'
import { columns as fileColumns } from './lib/files-columns'
import { columns as jobsColumns } from './lib/jobs-columns'
import { IRegistrationRow, RegistrationType } from './lib/types'

const initialSortedState = [
  {
    id: 'id',
    desc: true,
  },
]

export const FileProcessingSettings = () => {
  const processors = useProcessorsQuery()
  const registrations = useRegistrationsQuery()

  const {
    page,
    pageSize,
    updatePage,
    updatePageSize,
    setSorted,
    sorted,
    ready,
    isReady,
  } = useFileProcessingSettings()
  const jobs = useJobsQuery({ page, pageSize })
  const { sidebar, addSidebar, removeSidebar } = useSidebar()

  const rows = useMemo(() => {
    if (!registrations.data) return []

    let results: IRegistrationRow[] = []

    registrations.data.email_addresses.forEach(email => {
      results.push({
        ...email,
        type: RegistrationType.email,
      })
    })

    registrations.data.s3_directories.forEach(directory => {
      results.push({
        ...directory,
        type: RegistrationType.s3,
      })
    })

    return results
  }, [registrations.data])

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    jobs.data?.total_pages
  )

  const openSidebar = (payout: JobsRecord, id: number) => {
    addSidebar(<JobDetailsView record={payout} remove={removeSidebar} />, id)
  }

  useEffect(() => {
    if (ready) return
    setSorted(initialSortedState)
    isReady()
  }, [isReady, setSorted, ready])

  return (
    <FileProcessingContainer sidebar={sidebar}>
      <Loader loading={registrations.isLoading || processors.isLoading}>
        {rows.length === 0 ? (
          <ContentEmpty
            icon={<EmptyTableIcon />}
            title="You have no active file registrations"
            subtitle="Create one from the top of this page."
            height="sm"
          />
        ) : (
          <>
            <h5 className="mb-3">Files</h5>
            <Box
              css={{
                backgroundColor: 'white',
                padding: '$12',
              }}
            >
              <SimpleTable
                data={rows}
                columns={fileColumns}
                isLoading={registrations.isLoading}
                initialSortedState={[
                  {
                    id: 'id',
                    desc: false,
                  },
                ]}
                renderSubComponent={row => (
                  <RegistrationDetails row={row.original} />
                )}
                hideLastColumnBorder
              />
            </Box>
          </>
        )}
      </Loader>
      <hr className="my-5" />
      <Text as="h5" css={{ mb: '$12' }}>
        Jobs
      </Text>
      <Box
        css={{
          backgroundColor: 'white',
          padding: '$12',
        }}
      >
        <Table
          columns={jobsColumns}
          data={jobs.data?.content || []}
          controlledPagination={true}
          isLoading={jobs.isLoading}
          page={page}
          pageRows={pageSize}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          changePage={updatePage}
          rowOnClickFn={openSidebar}
          onSortedChange={setSorted}
          sorted={sorted}
          initialSortedState={initialSortedState}
          updatePageSize={updatePageSize}
        />
      </Box>
    </FileProcessingContainer>
  )
}
