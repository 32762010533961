/**
 * Array of country name and code
 * @type {Array {code: string, name: string, id: string}}
 */
export const COUNTRIES = [
  {
    code: '1',
    id: 'us',
    name: 'United States',
  },
  {
    code: '1',
    id: 'ca',
    name: 'Canada',
  },
  {
    code: '44',
    id: 'gb',
    name: 'United Kingdom',
  },
  {
    code: '93',
    id: 'af',
    name: 'Afghanistan',
  },
  {
    code: '355',
    id: 'al',
    name: 'Albania',
  },
  {
    code: '213',
    id: 'dz',
    name: 'Algeria',
  },
  {
    code: '1',
    id: 'as',
    name: 'American Samoa',
  },
  {
    code: '376',
    id: 'ad',
    name: 'Andorra',
  },
  {
    code: '244',
    id: 'ao',
    name: 'Angola',
  },
  {
    code: '1',
    id: 'ai',
    name: 'Anguilla',
  },
  {
    code: '672',
    id: 'aq',
    name: 'Antarctica',
  },
  {
    code: '1',
    id: 'ag',
    name: 'Antigua and Barbuda',
  },
  {
    code: '54',
    id: 'ar',
    name: 'Argentina',
  },
  {
    code: '374',
    id: 'am',
    name: 'Armenia',
  },
  {
    code: '297',
    id: 'aw',
    name: 'Aruba',
  },
  {
    code: '61',
    id: 'au',
    name: 'Australia',
  },
  {
    code: '43',
    id: 'at',
    name: 'Austria',
  },
  {
    code: '994',
    id: 'az',
    name: 'Azerbaijan',
  },
  {
    code: '1',
    id: 'bs',
    name: 'Bahamas',
  },
  {
    code: '973',
    id: 'bh',
    name: 'Bahrain',
  },
  {
    code: '880',
    id: 'bd',
    name: 'Bangladesh',
  },
  {
    code: '1',
    id: 'bb',
    name: 'Barbados',
  },
  {
    code: '375',
    id: 'by',
    name: 'Belarus',
  },
  {
    code: '32',
    id: 'be',
    name: 'Belgium',
  },
  {
    code: '501',
    id: 'bz',
    name: 'Belize',
  },
  {
    code: '229',
    id: 'bj',
    name: 'Benin',
  },
  {
    code: '1',
    id: 'bm',
    name: 'Bermuda',
  },
  {
    code: '975',
    id: 'bt',
    name: 'Bhutan',
  },
  {
    code: '591',
    id: 'bo',
    name: 'Bolivia',
  },
  {
    code: '387',
    id: 'ba',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: '267',
    id: 'bw',
    name: 'Botswana',
  },
  {
    code: '55',
    id: 'br',
    name: 'Brazil',
  },
  {
    code: '246',
    id: 'io',
    name: 'British Indian Ocean Territory',
  },
  {
    code: '1',
    id: 'vg',
    name: 'British Virgin Islands',
  },
  {
    code: '673',
    id: 'bn',
    name: 'Brunei',
  },
  {
    code: '359',
    id: 'bg',
    name: 'Bulgaria',
  },
  {
    code: '226',
    id: 'bf',
    name: 'Burkina Faso',
  },
  {
    code: '257',
    id: 'bi',
    name: 'Burundi',
  },
  {
    code: '855',
    id: 'kh',
    name: 'Cambodia',
  },
  {
    code: '237',
    id: 'cm',
    name: 'Cameroon',
  },
  {
    code: '238',
    id: 'cv',
    name: 'Cape Verde',
  },
  {
    code: '1',
    id: 'ky',
    name: 'Cayman Islands',
  },
  {
    code: '236',
    id: 'cf',
    name: 'Central African Republic',
  },
  {
    code: '235',
    id: 'td',
    name: 'Chad',
  },
  {
    code: '56',
    id: 'cl',
    name: 'Chile',
  },
  {
    code: '86',
    id: 'cn',
    name: 'China',
  },
  {
    code: '61',
    id: 'cx',
    name: 'Christmas Island',
  },
  {
    code: '61',
    id: 'cc',
    name: 'Cocos Islands',
  },
  {
    code: '57',
    id: 'co',
    name: 'Colombia',
  },
  {
    code: '269',
    id: 'km',
    name: 'Comoros',
  },
  {
    code: '682',
    id: 'ck',
    name: 'Cook Islands',
  },
  {
    code: '506',
    id: 'cr',
    name: 'Costa Rica',
  },
  {
    code: '385',
    id: 'hr',
    name: 'Croatia',
  },
  {
    code: '53',
    id: 'cu',
    name: 'Cuba',
  },
  {
    code: '599',
    id: 'cw',
    name: 'Curacao',
  },
  {
    code: '357',
    id: 'cy',
    name: 'Cyprus',
  },
  {
    code: '420',
    id: 'cz',
    name: 'Czech Republic',
  },
  {
    code: '243',
    id: 'cd',
    name: 'Democratic Republic of the Congo',
  },
  {
    code: '45',
    id: 'dk',
    name: 'Denmark',
  },
  {
    code: '253',
    id: 'dj',
    name: 'Djibouti',
  },
  {
    code: '1',
    id: 'dm',
    name: 'Dominica',
  },
  {
    code: '1',
    id: 'do',
    name: 'Dominican Republic',
  },
  {
    code: '670',
    id: 'tl',
    name: 'East Timor',
  },
  {
    code: '593',
    id: 'ec',
    name: 'Ecuador',
  },
  {
    code: '20',
    id: 'eg',
    name: 'Egypt',
  },
  {
    code: '503',
    id: 'sv',
    name: 'El Salvador',
  },
  {
    code: '240',
    id: 'gq',
    name: 'Equatorial Guinea',
  },
  {
    code: '291',
    id: 'er',
    name: 'Eritrea',
  },
  {
    code: '372',
    id: 'ee',
    name: 'Estonia',
  },
  {
    code: '251',
    id: 'et',
    name: 'Ethiopia',
  },
  {
    code: '500',
    id: 'fk',
    name: 'Falkland Islands',
  },
  {
    code: '298',
    id: 'fo',
    name: 'Faroe Islands',
  },
  {
    code: '679',
    id: 'fj',
    name: 'Fiji',
  },
  {
    code: '358',
    id: 'fi',
    name: 'Finland',
  },
  {
    code: '33',
    id: 'fr',
    name: 'France',
  },
  {
    code: '689',
    id: 'pf',
    name: 'French Polynesia',
  },
  {
    code: '241',
    id: 'ga',
    name: 'Gabon',
  },
  {
    code: '220',
    id: 'gm',
    name: 'Gambia',
  },
  {
    code: '995',
    id: 'ge',
    name: 'Georgia',
  },
  {
    code: '49',
    id: 'de',
    name: 'Germany',
  },
  {
    code: '233',
    id: 'gh',
    name: 'Ghana',
  },
  {
    code: '350',
    id: 'gi',
    name: 'Gibraltar',
  },
  {
    code: '30',
    id: 'gr',
    name: 'Greece',
  },
  {
    code: '299',
    id: 'gl',
    name: 'Greenland',
  },
  {
    code: '1',
    id: 'gd',
    name: 'Grenada',
  },
  {
    code: '1',
    id: 'gu',
    name: 'Guam',
  },
  {
    code: '502',
    id: 'gt',
    name: 'Guatemala',
  },
  {
    code: '44',
    id: 'gg',
    name: 'Guernsey',
  },
  {
    code: '224',
    id: 'gn',
    name: 'Guinea',
  },
  {
    code: '245',
    id: 'gw',
    name: 'Guinea-Bissau',
  },
  {
    code: '592',
    id: 'gy',
    name: 'Guyana',
  },
  {
    code: '509',
    id: 'ht',
    name: 'Haiti',
  },
  {
    code: '504',
    id: 'hn',
    name: 'Honduras',
  },
  {
    code: '852',
    id: 'hk',
    name: 'Hong Kong',
  },
  {
    code: '36',
    id: 'hu',
    name: 'Hungary',
  },
  {
    code: '354',
    id: 'is',
    name: 'Iceland',
  },
  {
    code: '91',
    id: 'in',
    name: 'India',
  },
  {
    code: '62',
    id: 'id',
    name: 'Indonesia',
  },
  {
    code: '98',
    id: 'ir',
    name: 'Iran',
  },
  {
    code: '964',
    id: 'iq',
    name: 'Iraq',
  },
  {
    code: '353',
    id: 'ie',
    name: 'Ireland',
  },
  {
    code: '44',
    id: 'im',
    name: 'Isle of Man',
  },
  {
    code: '972',
    id: 'il',
    name: 'Israel',
  },
  {
    code: '39',
    id: 'it',
    name: 'Italy',
  },
  {
    code: '225',
    id: 'ci',
    name: 'Ivory Coast',
  },
  {
    code: '1',
    id: 'jm',
    name: 'Jamaica',
  },
  {
    code: '81',
    id: 'jp',
    name: 'Japan',
  },
  {
    code: '44',
    id: 'je',
    name: 'Jersey',
  },
  {
    code: '962',
    id: 'jo',
    name: 'Jordan',
  },
  {
    code: '7',
    id: 'kz',
    name: 'Kazakhstan',
  },
  {
    code: '254',
    id: 'ke',
    name: 'Kenya',
  },
  {
    code: '686',
    id: 'ki',
    name: 'Kiribati',
  },
  {
    code: '383',
    id: 'xk',
    name: 'Kosovo',
  },
  {
    code: '965',
    id: 'kw',
    name: 'Kuwait',
  },
  {
    code: '996',
    id: 'kg',
    name: 'Kyrgyzstan',
  },
  {
    code: '856',
    id: 'la',
    name: 'Laos',
  },
  {
    code: '371',
    id: 'lv',
    name: 'Latvia',
  },
  {
    code: '961',
    id: 'lb',
    name: 'Lebanon',
  },
  {
    code: '266',
    id: 'ls',
    name: 'Lesotho',
  },
  {
    code: '231',
    id: 'lr',
    name: 'Liberia',
  },
  {
    code: '218',
    id: 'ly',
    name: 'Libya',
  },
  {
    code: '423',
    id: 'li',
    name: 'Liechtenstein',
  },
  {
    code: '370',
    id: 'lt',
    name: 'Lithuania',
  },
  {
    code: '352',
    id: 'lu',
    name: 'Luxembourg',
  },
  {
    code: '853',
    id: 'mo',
    name: 'Macao',
  },
  {
    code: '389',
    id: 'mk',
    name: 'Macedonia',
  },
  {
    code: '261',
    id: 'mg',
    name: 'Madagascar',
  },
  {
    code: '265',
    id: 'mw',
    name: 'Malawi',
  },
  {
    code: '60',
    id: 'my',
    name: 'Malaysia',
  },
  {
    code: '960',
    id: 'mv',
    name: 'Maldives',
  },
  {
    code: '223',
    id: 'ml',
    name: 'Mali',
  },
  {
    code: '356',
    id: 'mt',
    name: 'Malta',
  },
  {
    code: '692',
    id: 'mh',
    name: 'Marshall Islands',
  },
  {
    code: '222',
    id: 'mr',
    name: 'Mauritania',
  },
  {
    code: '230',
    id: 'mu',
    name: 'Mauritius',
  },
  {
    code: '262',
    id: 'yt',
    name: 'Mayotte',
  },
  {
    code: '52',
    id: 'mx',
    name: 'Mexico',
  },
  {
    code: '691',
    id: 'fm',
    name: 'Micronesia',
  },
  {
    code: '373',
    id: 'md',
    name: 'Moldova',
  },
  {
    code: '377',
    id: 'mc',
    name: 'Monaco',
  },
  {
    code: '976',
    id: 'mn',
    name: 'Mongolia',
  },
  {
    code: '382',
    id: 'me',
    name: 'Montenegro',
  },
  {
    code: '1',
    id: 'ms',
    name: 'Montserrat',
  },
  {
    code: '212',
    id: 'ma',
    name: 'Morocco',
  },
  {
    code: '258',
    id: 'mz',
    name: 'Mozambique',
  },
  {
    code: '95',
    id: 'mm',
    name: 'Myanmar',
  },
  {
    code: '264',
    id: 'na',
    name: 'Namibia',
  },
  {
    code: '674',
    id: 'nr',
    name: 'Nauru',
  },
  {
    code: '977',
    id: 'np',
    name: 'Nepal',
  },
  {
    code: '31',
    id: 'nl',
    name: 'Netherlands',
  },
  {
    code: '599',
    id: 'bq',
    name: 'Netherlands Antilles',
  },
  {
    code: '687',
    id: 'nc',
    name: 'New Caledonia',
  },
  {
    code: '64',
    id: 'nz',
    name: 'New Zealand',
  },
  {
    code: '505',
    id: 'ni',
    name: 'Nicaragua',
  },
  {
    code: '227',
    id: 'ne',
    name: 'Niger',
  },
  {
    code: '234',
    id: 'ng',
    name: 'Nigeria',
  },
  {
    code: '683',
    id: 'nu',
    name: 'Niue',
  },
  {
    code: '850',
    id: 'kp',
    name: 'North Korea',
  },
  {
    code: '1',
    id: 'mp',
    name: 'Northern Mariana Islands',
  },
  {
    code: '47',
    id: 'no',
    name: 'Norway',
  },
  {
    code: '968',
    id: 'om',
    name: 'Oman',
  },
  {
    code: '92',
    id: 'pk',
    name: 'Pakistan',
  },
  {
    code: '680',
    id: 'pw',
    name: 'Palau',
  },
  {
    code: '970',
    id: 'ps',
    name: 'Palestinian Territory',
  },
  {
    code: '507',
    id: 'pa',
    name: 'Panama',
  },
  {
    code: '675',
    id: 'pg',
    name: 'Papua New Guinea',
  },
  {
    code: '595',
    id: 'py',
    name: 'Paraguay',
  },
  {
    code: '51',
    id: 'pe',
    name: 'Peru',
  },
  {
    code: '63',
    id: 'ph',
    name: 'Philippines',
  },
  {
    code: '64',
    id: 'pn',
    name: 'Pitcairn',
  },
  {
    code: '48',
    id: 'pl',
    name: 'Poland',
  },
  {
    code: '351',
    id: 'pt',
    name: 'Portugal',
  },
  {
    code: '1',
    id: 'pr',
    name: 'Puerto Rico',
  },
  {
    code: '974',
    id: 'qa',
    name: 'Qatar',
  },
  {
    code: '242',
    id: 'cg',
    name: 'Republic of the Congo',
  },
  {
    code: '262',
    id: 're',
    name: 'Reunion',
  },
  {
    code: '40',
    id: 'ro',
    name: 'Romania',
  },
  {
    code: '7',
    id: 'ru',
    name: 'Russia',
  },
  {
    code: '250',
    id: 'rw',
    name: 'Rwanda',
  },
  {
    code: '590',
    id: 'bl',
    name: 'Saint Bartholemy',
  },
  {
    code: '290',
    id: 'sh',
    name: 'Saint Helena',
  },
  {
    code: '1',
    id: 'kn',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: '1',
    id: 'lc',
    name: 'Saint Lucia',
  },
  {
    code: '590',
    id: 'mf',
    name: 'Saint Martin',
  },
  {
    code: '508',
    id: 'pm',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: '1',
    id: 'vc',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: '685',
    id: 'ws',
    name: 'Samoa',
  },
  {
    code: '378',
    id: 'sm',
    name: 'San Marino',
  },
  {
    code: '239',
    id: 'st',
    name: 'Sao Tome and Principe',
  },
  {
    code: '966',
    id: 'sa',
    name: 'Saudi Arabia',
  },
  {
    code: '221',
    id: 'sn',
    name: 'Senegal',
  },
  {
    code: '381',
    id: 'rs',
    name: 'Serbia',
  },
  {
    code: '248',
    id: 'sc',
    name: 'Seychelles',
  },
  {
    code: '232',
    id: 'sl',
    name: 'Sierra Leone',
  },
  {
    code: '65',
    id: 'sg',
    name: 'Singapore',
  },
  {
    code: '1',
    id: 'sx',
    name: 'Sint Maarten',
  },
  {
    code: '421',
    id: 'sk',
    name: 'Slovakia',
  },
  {
    code: '386',
    id: 'si',
    name: 'Slovenia',
  },
  {
    code: '677',
    id: 'sb',
    name: 'Solomon Islands',
  },
  {
    code: '252',
    id: 'so',
    name: 'Somalia',
  },
  {
    code: '27',
    id: 'za',
    name: 'South Africa',
  },
  {
    code: '82',
    id: 'kr',
    name: 'South Korea',
  },
  {
    code: '211',
    id: 'ss',
    name: 'South Sudan',
  },
  {
    code: '34',
    id: 'es',
    name: 'Spain',
  },
  {
    code: '94',
    id: 'lk',
    name: 'Sri Lanka',
  },
  {
    code: '249',
    id: 'sd',
    name: 'Sudan',
  },
  {
    code: '597',
    id: 'sr',
    name: 'Suriname',
  },
  {
    code: '47',
    id: 'sj',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: '268',
    id: 'sz',
    name: 'Swaziland',
  },
  {
    code: '46',
    id: 'se',
    name: 'Sweden',
  },
  {
    code: '41',
    id: 'ch',
    name: 'Switzerland',
  },
  {
    code: '963',
    id: 'sy',
    name: 'Syria',
  },
  {
    code: '886',
    id: 'tw',
    name: 'Taiwan',
  },
  {
    code: '992',
    id: 'tj',
    name: 'Tajikistan',
  },
  {
    code: '255',
    id: 'tz',
    name: 'Tanzania',
  },
  {
    code: '66',
    id: 'th',
    name: 'Thailand',
  },
  {
    code: '228',
    id: 'tg',
    name: 'Togo',
  },
  {
    code: '690',
    id: 'tk',
    name: 'Tokelau',
  },
  {
    code: '676',
    id: 'to',
    name: 'Tonga',
  },
  {
    code: '1',
    id: 'tt',
    name: 'Trinidad and Tobago',
  },
  {
    code: '216',
    id: 'tn',
    name: 'Tunisia',
  },
  {
    code: '90',
    id: 'tr',
    name: 'Turkey',
  },
  {
    code: '993',
    id: 'tm',
    name: 'Turkmenistan',
  },
  {
    code: '1',
    id: 'tc',
    name: 'Turks and Caicos Islands',
  },
  {
    code: '688',
    id: 'tv',
    name: 'Tuvalu',
  },
  {
    code: '1',
    id: 'vi',
    name: 'U.S. Virgin Islands',
  },
  {
    code: '256',
    id: 'ug',
    name: 'Uganda',
  },
  {
    code: '380',
    id: 'ua',
    name: 'Ukraine',
  },
  {
    code: '971',
    id: 'ae',
    name: 'United Arab Emirates',
  },
  {
    code: '598',
    id: 'uy',
    name: 'Uruguay',
  },
  {
    code: '998',
    id: 'uz',
    name: 'Uzbekistan',
  },
  {
    code: '678',
    id: 'vu',
    name: 'Vanuatu',
  },
  {
    code: '379',
    id: 'va',
    name: 'Vatican',
  },
  {
    code: '58',
    id: 've',
    name: 'Venezuela',
  },
  {
    code: '84',
    id: 'vn',
    name: 'Vietnam',
  },
  {
    code: '681',
    id: 'wf',
    name: 'Wallis and Futuna',
  },
  {
    code: '212',
    id: 'eh',
    name: 'Western Sahara',
  },
  {
    code: '967',
    id: 'ye',
    name: 'Yemen',
  },
  {
    code: '260',
    id: 'zm',
    name: 'Zambia',
  },
  {
    code: '263',
    id: 'zw',
    name: 'Zimbabwe',
  },
]
