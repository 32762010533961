import { DirectDeposit } from './components/DirectDeposit'
import { DirectDepositContainer } from './components/DirectDepositContainer'

export const DirectDepositSettings = () => {
  return (
    <DirectDepositContainer>
      <DirectDeposit />
    </DirectDepositContainer>
  )
}
