import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { styled } from '@/styles/stitches.config'

export const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
})

export const Label = styled('label', {
  margin: 0,
  marginLeft: '$8',
  fontWeight: 'bold',
  fontSize: 14,
  color: '$secondary300',
  textTransform: 'capitalize',
})

export const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  unset: 'all',
  margin: 0,
  backgroundColor: '$primary900',
  size: 16,
  borderRadius: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid $secondary500',

  '-webkit-appearance': 'none',

  '&[data-state="checked"]': {
    backgroundColor: '$primary500',
    color: '$primary900',
    borderColor: 'transparent',
  },

  '&:disabled': {
    opacity: 0.5,
  },
})

export const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: 'white',

  '& svg': {
    display: 'block',
  },
})
