import { FC } from 'react'
import { UserEnrollmentRequest } from '@/types/employees'
import { EnrollmentRequestedActions } from './EnrollmentRequestedActions'

interface Props {
  original: UserEnrollmentRequest
}

export const EnrollmentActionDropdown: FC<Props> = ({ original: user }) => (
  <EnrollmentRequestedActions user={user} />
)
