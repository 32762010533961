import { FC, useState } from 'react'
import { Table } from 'reactstrap'
import { BulkUploadStepsModal } from '@/common/bulk-upload/BulkUploadStepsModal'
import { Status } from '@/common/bulk-upload/types'
import { useOrderInstantCardsMutation } from '@/queries/instant-cards'
import { useModalStore } from '@/store/modal'
import { InstantCardExample } from './InstantCardExample'
import {
  formatBulkFormValuesForAPI,
  ICardBulkOrderFormValues,
} from './lib/orderForm'
import { useValidateCsvData } from './lib/useValidateCsvData'

interface Props {
  order: ICardBulkOrderFormValues
}

export const InstantCardsBulkOrderSteps: FC<Props> = ({ order }) => {
  const { count, prefixName, careOf, files } = order
  const [open, setOpen] = useState(true)
  const { removeModal } = useModalStore()

  const orderInstantCardsMutation = useOrderInstantCardsMutation()
  const bulkInstantCardOrderValidation = useValidateCsvData(files)
  const [status, setStatus] = useState<Status>(Status.IDLE)
  const [data, setData] = useState<string[][]>([])

  const onConfirm = async () => {
    setStatus(Status.LOADING)
    try {
      const orderRequest = data.map(shippingData => {
        const config = formatBulkFormValuesForAPI({
          prefixName,
          careOf,
          count,
          firstName: shippingData[2],
          lastName: shippingData[3],
          address1: shippingData[4],
          address2: shippingData[5],
          city: shippingData[6],
          state: shippingData[7],
          zip: shippingData[8],
        })
        return orderInstantCardsMutation.mutateAsync(config)
      })
      await Promise.allSettled(orderRequest)
      setStatus(Status.UPLOAD_SUCCESS)
    } catch (e) {
      setStatus(Status.UPLOAD_ERROR)
    }
  }

  const onClose = () => {
    setData([])
    setOpen(false)
    removeModal()
  }

  return (
    <BulkUploadStepsModal
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
      status={status}
      files={files}
      error={orderInstantCardsMutation.error}
      isLoading={
        orderInstantCardsMutation.isLoading ||
        bulkInstantCardOrderValidation.isLoading
      }
      errorData={bulkInstantCardOrderValidation.data || []}
      isValidating={bulkInstantCardOrderValidation.isLoading}
      setData={setData}
    >
      <Table bordered>
        <tbody>
          <tr>
            <td className="font-weight-bold">Example Card</td>
            <td>
              <InstantCardExample name={`${order.prefixName} 01`} />
            </td>
          </tr>
          <tr>
            <td className="font-weight-bold">Group Name On Cards</td>
            <td>{order.prefixName}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Care Of</td>
            <td>{order.careOf}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Quantity</td>
            <td>{data.length * count}</td>
          </tr>
        </tbody>
      </Table>
    </BulkUploadStepsModal>
  )
}
