import { useEffect, useState } from 'react'
import { useUserDetailsQuery } from '@/queries/auth'
import { useUser } from '@/store/user'

export const useUserDetailsInitializer = () => {
  const { data } = useUserDetailsQuery()
  const [isInitialized, setIsInitialized] = useState(false)
  const setUser = useUser(state => state.setUser)

  useEffect(() => {
    if (data) {
      setUser(data)
      setIsInitialized(true)
    }
  }, [data, setUser, setIsInitialized])

  return { isInitialized }
}
