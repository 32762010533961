import { saveAs } from 'file-saver'
import moment from 'moment'
import Papa from 'papaparse'
import { toDollar } from '@/lib/currency'
import { getPercentage } from '@/lib/percent'
import { IEmployeeDirectDepositForm } from '@/types/employees'

export const downloadCSV = (records: IEmployeeDirectDepositForm[]) => {
  const csvData = records.map(record => ({
    "Worker's ID": record.employee.employee_id,
    Name: `${record.employee.first_name} ${record.employee.last_name}`,
    'Deposit Amount': `${toDollar(record.direct_deposit_form.deposit_amount)}`,
    'Deposit Percentage': `${getPercentage(
      record.direct_deposit_form.deposit_percent || 0,
      100
    )}%`,
    Date: moment(record.direct_deposit_form.time_created).format('YYYY-MM-DD'),
  }))

  const csvContent = Papa.unparse(csvData)
  const blob = new Blob([csvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch_direct_deposit_requests.csv')
}
