import { FC } from 'react'
import { Button } from '@/common/button'

interface Props {
  handleAction: () => void
  disabled: boolean
  isLoading: boolean
}

export const MultiActionButton: FC<Props> = ({
  children,
  handleAction,
  disabled,
  isLoading,
}) => {
  return (
    <Button
      onClick={handleAction}
      disabled={disabled}
      loading={isLoading}
      mode="flat"
    >
      {children}
    </Button>
  )
}
