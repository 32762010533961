import { CheckIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { InputIcon } from '@/common/input'
import { Flex } from '@/common/layout'
import { ScrollArea } from '@/common/scroll-area'
import { COUNTRIES } from '@/src/lib/countries'

interface ICountry {
  code: string
  name: string
  id: string
}

interface Props {
  selectedCode: ICountry
  onCountryCodeChange: (code: ICountry) => void
}

export const CountryCodeDropdown: FC<Props> = ({
  selectedCode,
  onCountryCodeChange,
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <InputIcon
        left
        css={{
          '&:hover': {
            color: '$secondary300',
            backgroundColor: '$secondary900',
          },
        }}
      >
        +{selectedCode.code}
      </InputIcon>
    </DropdownMenuTrigger>
    <DropdownContent style={{ height: 300 }}>
      <ScrollArea>
        {COUNTRIES.map(country => (
          <DropdownMenuItem
            onClick={() => onCountryCodeChange(country)}
            key={country.name}
          >
            <Flex direction="row" justify="between">
              {country.name} (+{country.code})
              {selectedCode.name === country.name ? <CheckIcon /> : null}
            </Flex>
          </DropdownMenuItem>
        ))}
      </ScrollArea>
    </DropdownContent>
  </DropdownMenu>
)
