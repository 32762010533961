import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useDirectDepositStore } from '@/store/direct-deposit'
import { IEmployeeDirectDepositForm } from '@/types/employees'
import { downloadCSV } from '../lib/downloadCSV'

interface Props {
  filteredData: IEmployeeDirectDepositForm[]
}

export const DirectDepositsFilters: FC<Props> = ({ filteredData }) => {
  const {
    filters,

    updateFilters,
  } = useDirectDepositStore()
  const handleDownload = () => downloadCSV(filteredData)
  return (
    <TableFiltersMain
      searchProperty="employee_id"
      filters={filters}
      updateFilters={updateFilters}
      handleDownload={handleDownload}
    />
  )
}
