import { FC, useState } from 'react'
import { Table } from 'reactstrap'
import { BulkUploadStepsModal } from '@/common/bulk-upload/BulkUploadStepsModal'
import { Status } from '@/common/bulk-upload/types'
import { useBulkQualifyEmployeesMutation } from '@/src/queries/create-accounts'
import { useModalStore } from '@/store/modal'
import { useValidateCsvData } from '../lib/useValidateCVSData'

interface Props {
  files: File[]
  columnHeaders: string[]
}

export const BulkQualifyUploadSteps: FC<Props> = ({ files, columnHeaders }) => {
  const [open, setOpen] = useState(true)
  const { removeModal } = useModalStore()
  const [status, setStatus] = useState<Status>(Status.IDLE)
  const bulkQualify = useBulkQualifyEmployeesMutation()
  const bulkQualifyValidation = useValidateCsvData(files)
  const [data, setData] = useState<string[][]>([])

  const onConfirm = () => {
    setStatus(Status.LOADING)
    bulkQualify.mutate(
      { file: files[0] },
      {
        onSuccess: () => setStatus(Status.UPLOAD_SUCCESS),
        onError: () => setStatus(Status.UPLOAD_ERROR),
      }
    )
  }

  const onClose = () => {
    setData([])
    setOpen(false)
    removeModal()
  }

  return (
    <BulkUploadStepsModal
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
      status={status}
      files={files}
      columnHeaders={columnHeaders}
      error={bulkQualify.error}
      errorData={bulkQualifyValidation.data || []}
      isLoading={bulkQualifyValidation.isLoading}
      isValidating={bulkQualifyValidation.isLoading}
      setData={setData}
    >
      <Table bordered>
        <tbody>
          <tr>
            <td className="font-weight-bold">Total Accounts</td>
            <td>{data.length}</td>
          </tr>
        </tbody>
      </Table>
    </BulkUploadStepsModal>
  )
}
