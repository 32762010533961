import { useQuery } from 'react-query'
import { getAdvanceRecords } from '@/api/advances'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useAdvancesStore } from '@/store/advances'

export const ADVANCES_QUERY = 'advances-query'

export const useAdvancesQuery = () => {
  const { id: orgId } = useCurrentOrg()
  const { page, pageSize, sorted, filters } = useAdvancesStore()
  const { from, to, ...restFilters } = filters

  const params = {
    start: from?.format(),
    end: to?.format(),
    size: pageSize,
    page: page + 1,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
    ...restFilters,
  }

  return useQuery([ADVANCES_QUERY, orgId, params], async () => {
    const res = await getAdvanceRecords(orgId, params)
    return res.data.data
  })
}
