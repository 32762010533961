export interface IResponse {
  meta: {
    code: number
    message: string
    errorDetail: string
  }
}

export type PaginatedData<T> = {
  content: T
  page_number: number
  size: number
  total_number_of_elements: number
  total_pages: number
}

export type PaginatedIndefiniteData<T> = {
  content: T
  page_number: number
  size: number
  next_page: string
  prev_page: string
  has_next_page: boolean
  has_prev_page: boolean
}

export enum SHIFT_TYPES {
  DAILY = 'Daily',
  HOURLY = 'Hourly',
}

export enum EWA_TYPE {
  OFF = 'OFF',
  BUSINESS_DEDUCTION = 'BUSINESS_DEDUCTION',
  BUSINESS_FULL_DD = 'BUSINESS_FULL_DD',
  BACKUP_BALANCE = 'BACKUP_BALANCE',
}

export enum INSTANT_TRANSFER_FEE_TYPE {
  FLAT_AMOUNT = 'FLAT_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum PASSCODE_REQUIREMENT {
  OFF = 'OFF', // passcode not required
  LOCATION = 'LOCATION', // passcode required when joining specific location
  ORG = 'ORGANIZATION', // passcode required when joining org and roster match does not succeed
}

export const passcodeRequirementMap = {
  [PASSCODE_REQUIREMENT.OFF]: '0',
  [PASSCODE_REQUIREMENT.LOCATION]: '1',
  [PASSCODE_REQUIREMENT.ORG]: '2',
}

export interface FileValidationError {
  original_csv_line: number
  original_csv_row: string
  status: string
  status_reason: string
}

export enum TabType {
  WALLET = 'wallet',
  REWARDS = 'rewards',
  ACCESS = 'access',
  BUDGET = 'budget',
  SCHEDULE = 'schedule',
  COVERAGE = 'coverage',
  GROW = 'grow',
  CASHOUT = 'cash_out',
}
