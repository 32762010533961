import { useCallback, useState } from 'react'
import { getSelectedText } from '@/lib/getSelectedText'

export const useSidebar = (
  initialSidebar?: React.ReactNode,
  initialSidebarId?: string | number
) => {
  const [sidebar, setSidebar] = useState<React.ReactNode | null>(
    initialSidebar || null
  )
  // the sidebar ID is an optional unique identifier
  // to let us know which sidebar is currently active
  // if rendering from a list
  const [sidebarId, setSidebarId] = useState<React.ReactNode | null>(
    initialSidebarId || null
  )

  const addSidebar = useCallback(
    (comp: React.ReactNode, id: number) => {
      // check if any text is highlighted
      // if it is, don't trigger since the
      // user intended to highlight, not click
      if (!getSelectedText()) {
        setSidebar(comp)
        setSidebarId(id)
      }
    },
    [setSidebar, setSidebarId]
  )

  const removeSidebar = useCallback(() => {
    setSidebar(null)
    setSidebarId(null)
  }, [setSidebar])

  return { sidebar, removeSidebar, addSidebar, sidebarId }
}
