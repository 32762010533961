import { Table } from '@tanstack/react-table'

interface Props<TData> {
  table: Table<TData>
  controlledPagination?: boolean
  changePage?: (page: number) => void
  hasPrevPage?: boolean
  hasNextPage?: boolean
  page: number
}

export const useTablePagination = <TData extends object>({
  table,
  changePage,
  controlledPagination = false,
  hasNextPage,
  hasPrevPage,
  page,
}: Props<TData>) => {
  const previousPageDisabled = controlledPagination
    ? !hasPrevPage
    : !table.getCanPreviousPage()

  const nextPageDisabled = controlledPagination
    ? !hasNextPage
    : !table.getCanNextPage()

  const advancePage = () => {
    changePage && changePage(page + 1)
    table.nextPage()
  }

  const previousPage = () => {
    changePage && changePage(page - 1)
    table.previousPage()
  }

  const pageNumber = table.getState().pagination.pageIndex + 1
  return {
    previousPageDisabled,
    nextPageDisabled,
    advancePage,
    previousPage,
    pageNumber,
  }
}

export const useDefinedTablePagination = (
  page: number,
  totalPages?: number
) => {
  const hasNextPage = totalPages ? page + 1 < totalPages : false

  const hasPrevPage = page > 0
  return { hasNextPage, hasPrevPage }
}

export const useIndefiniteTablePagination = (
  has_next_page?: boolean,
  has_prev_page?: boolean
) => {
  const hasNextPage = has_next_page || has_next_page === null

  const hasPrevPage = has_prev_page || has_prev_page === null
  return { hasNextPage, hasPrevPage }
}
