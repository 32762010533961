import qs from 'qs'
import { GetAdvancesParams } from '@/api/types/paybacks'
import http from './http'
import { GetAdvanceRecordsResponse } from './types/record'

/**
 * Allows customer to view historical transaction records
 */
export const getAdvanceRecords = (orgId: number, params: GetAdvancesParams) => {
  const query = qs.stringify(params)
  return http.get<GetAdvanceRecordsResponse>(
    `p1/organizations/${orgId}/advances?${query}`
  )
}
