import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Select } from '@/common/select'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { useSettingsStore } from '@/store/settings'
import { OrgSettings } from '@/types/organization'
import { HelperText, SectionTitle } from './styles'

type GeneralSetupFormValues = {
  DISB_DESC_TEMPLATE: string
  DISB_DETAILED_DESC_TEMPLATE: string
}

const DISB_DESC_TEMPLATE_OPTIONS = [
  { label: 'TYPE', value: 'TYPE' },
  { label: 'ORGNAME', value: 'ORGNAME' },
  { label: 'ORGNAME_TYPE', value: 'ORGNAME_TYPE' },
]
const DISB_DETAILED_DESC_TEMPLATE_OPTIONS = [
  { label: 'ORGNAME', value: 'ORGNAME' },
  { label: 'ORGNAME_EXTERNALID', value: 'ORGNAME_EXTERNALID' },
  { label: 'CUSTOMVALUE', value: 'CUSTOMVALUE' },
  { label: 'DESCRIPTION', value: 'DESCRIPTION' },
  { label: 'ORGNAME_DESCRIPTION', value: 'ORGNAME_DESCRIPTION' },
]

const resetForm = (settings: OrgSettings) => ({
  DISB_DESC_TEMPLATE: settings.DISB_DESC_TEMPLATE,
  DISB_DETAILED_DESC_TEMPLATE: settings.DISB_DETAILED_DESC_TEMPLATE,
})

export const CustomDisbursementOptions: FC = () => {
  const settings = useSettingsStore(state => state.settings)
  const updateSettings = useUpdateSettingsMutation()
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: resetForm(settings),
  })

  const onSubmitForm = (values: GeneralSetupFormValues) =>
    updateSettings.mutate(values)

  useEffect(() => {
    reset(resetForm(settings))
  }, [settings, reset])

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <SectionTitle>Custom Disbursement Options</SectionTitle>
        <FlexWrap direction="row" align="start" size="lg">
          <FlexWrap direction="column" align="start" size="lg">
            <Label>Disbursement Description Template</Label>
            <Flex css={{ width: 300 }}>
              <Select
                options={DISB_DESC_TEMPLATE_OPTIONS}
                value={watch('DISB_DESC_TEMPLATE')}
                onChange={val =>
                  setValue('DISB_DESC_TEMPLATE', val, { shouldDirty: true })
                }
              />
            </Flex>
            <HelperText size="sm">
              Customize top line text in mobile app for disbursements. Default
              is “TYPE”.
            </HelperText>
          </FlexWrap>
          <FlexWrap direction="column" align="start" size="lg">
            <Label>Disbursement Detailed Description Template</Label>
            <Flex css={{ width: 300 }}>
              <Select
                options={DISB_DETAILED_DESC_TEMPLATE_OPTIONS}
                value={watch('DISB_DETAILED_DESC_TEMPLATE')}
                onChange={val =>
                  setValue('DISB_DETAILED_DESC_TEMPLATE', val, {
                    shouldDirty: true,
                  })
                }
              />
            </Flex>
            <HelperText size="sm">
              Customize subtext in mobile app for disbursements. Default is
              "ORGNAME".
            </HelperText>
          </FlexWrap>
        </FlexWrap>
        <Flex>
          <Button
            type="submit"
            mode="filled"
            disabled={updateSettings.isLoading || !isDirty}
            size="md"
            css={{ ml: 'auto' }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Card>
  )
}
