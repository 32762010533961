import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import { useLogoutQuery } from '@/queries/auth'
import { useCreateOrg } from '@/queries/organizations'
import {
  removeCachedCurrentOrganization,
  removeCachedOrganizations,
  removeCachedUserDetails,
  removeCachedUserRoles,
} from '@/src/sessionstorage'
import { useModalStore } from '@/store/modal'

export type OrgCreationFormValues = {
  name: string
  key: string
}

const schema = yup.object().shape({
  name: yup.string().required(),
  key: yup
    .string()
    .required()
    .matches(/^([a-zA-Z0-9_.-]+)$/),
})

export const OrgCreationModal: FC = () => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const createOrg = useCreateOrg()
  const logout = useLogoutQuery()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OrgCreationFormValues>({
    defaultValues: { name: '', key: '' },
    resolver: yupResolver(schema),
  })

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const onSubmit = (values: OrgCreationFormValues) => {
    createOrg.mutate(values, {
      onSuccess: () => {
        setTimeout(() => {
          removeCachedCurrentOrganization()
          removeCachedOrganizations()
          removeCachedUserDetails()
          removeCachedUserRoles()
          logout.mutate()
          close()
        }, 5000)
      },
    })
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Create Organization"
      description="Make sure you know what you are doing if you want to create an organization here."
    >
      {createOrg.isSuccess || logout.isLoading ? (
        <Alert type="success" css={{ mb: '$24' }}>
          Created a new organization. Now logging you out to refresh the
          application.
        </Alert>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexGrid fillSpace>
            <div>
              <Label>Organization Name</Label>
              <Input type="text" {...register('name')} />
              <Text color="alert" size="sm">
                {errors.name?.message}
              </Text>
            </div>
          </FlexGrid>
          <FlexGrid fillSpace css={{ mt: '$8' }}>
            <div>
              <Label>Organization Key</Label>
              <Input type="text" {...register('key')} />
              <Text color="alert" size="sm">
                {errors.key?.message}
              </Text>
            </div>
          </FlexGrid>
          <Flex justify="end" css={{ mt: '$24' }}>
            <Button disabled={createOrg.isLoading} mode="filled" type="submit">
              Create Organization
            </Button>
          </Flex>
        </form>
      )}
    </Dialog>
  )
}
