import { FC } from 'react'
import { useHistory } from 'react-router'
import { DropdownMenuItem } from '@/common/dropdown'
import { NavItem as NavigationItem } from '@/router/menus'

interface Props {
  item: NavigationItem
}

export const NavItem: FC<Props> = ({ item }) => {
  const history = useHistory()

  return (
    <DropdownMenuItem onClick={() => history.push(item.route)}>
      {item.label}
    </DropdownMenuItem>
  )
}
