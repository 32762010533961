import moment, { Moment } from 'moment'
import { DateRangePreset } from './types'

export const enumerateDaysBetweenDates = (
  startDate?: Moment,
  endDate?: Moment
) => {
  const dates = []

  const currDate =
    startDate?.clone().startOf('day') || moment().subtract(1, 'year')
  const lastDate = endDate?.clone().startOf('day') || moment()

  dates.push(currDate.clone())

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone())
  }

  dates.push(lastDate.clone())

  return dates
}

export const getDateRange = (
  from: Moment,
  to: Moment,
  utcOffset?: string | number,
  format = 'YYYY-MM-DDThh:mm:ss'
) => {
  const fromDate = utcOffset
    ? from
        ?.clone() // creates a new instace of Moment
        .utcOffset(utcOffset, true)
        .startOf('day')
    : from
  const toDate = utcOffset
    ? to
        ?.clone() // creates a new instace of Moment
        .utcOffset(utcOffset, true)
        .add(1, 'day')
        .startOf('day') // Insteads of passing end_time as 11:59:59 we pass 12:00AM
    : to

  return {
    fromDate: fromDate?.format(format),
    toDate: toDate?.format(format),
  }
}

export const toIsoString = (date: Date) => {
  const pad = (num: Number) => {
    return (num < 10 ? '0' : '') + num
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    'Z'
  )
}
/**
 *
 * Given a start date and an end date,
 * return a formatted output
 *
 * _Examples_:
 *
 * **Same day**: Apr 1, 2019
 *
 * **Same month**: Apr 1 - 15, 2019
 *
 * **Same year**: Apr 1 - May 15, 2019
 *
 * **Different year**: Apr 1, 2019 - Jan 1, 2020
 */
export const getRangeText = ({
  start,
  end,
  preset,
}: {
  start?: Moment
  end?: Moment
  preset?: DateRangePreset
}) => {
  // handle presets first
  if (preset) return DATE_PRESET_LABELS[preset]

  // this should never happen and just
  // ensures type safety below
  if (!start || !end) return

  // if its the same day, just return early.
  // we don't need to perform other logic.
  const isSameDay = start.isSame(end, 'day')
  if (isSameDay) return start.format('ddd, MMM D, YYYY')

  const isSameMonth = start.isSame(end, 'month')
  const isSameYear = start.isSame(end, 'year')
  const startFormat = `MMM D${!isSameYear ? ', YYYY' : ''}`
  const endFormat = `${!isSameMonth ? 'MMM ' : ''}D, YYYY`
  const startText = start.format(startFormat)
  const endText = end.format(endFormat)
  return `${startText} - ${endText}`
}

export const DATE_PRESET_LABELS = {
  [DateRangePreset.ALL_TIME]: 'All time',
  [DateRangePreset.LAST_MONTH]: 'Last month',
  [DateRangePreset.LAST_THREE_MONTHS]: 'Last 3 months',
  [DateRangePreset.LAST_WEEK]: 'Last week',
}

export const getPresetDateRange = (preset: DateRangePreset) => {
  let range: { from?: Moment; to?: Moment; preset: DateRangePreset } = {
    from: undefined,
    to: undefined,
    preset,
  }

  if (preset === 'last_month') {
    range.from = moment()
      .subtract(1, 'month')
      .startOf('day')
    range.to = moment().endOf('day')
  }

  if (preset === 'last_three_months') {
    range.from = moment()
      .subtract(3, 'month')
      .startOf('day')
    range.to = moment().endOf('day')
  }

  if (preset === 'last_week') {
    range.from = moment()
      .subtract(1, 'week')
      .startOf('day')
    range.to = moment().endOf('day')
  }

  return range
}
