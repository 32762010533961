import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getAdmins, getUserInfo, removeAdmin } from '@/api/admin-users'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'

export const GET_ADMIN_USERS_QUERY = 'get-admin-users'

export const useAdminUsersQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([GET_ADMIN_USERS_QUERY, orgId], async () => {
    const { data } = await getAdmins(orgId)
    return data.data
  })
}

export const useRemoveAdmin = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (adminId: string) => {
      const res = await removeAdmin(orgId, adminId)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('organization', 'remove-admin:success')
        queryClient.invalidateQueries([GET_ADMIN_USERS_QUERY, orgId])
      },
    }
  )
}

export const USER_INFO_QUERY = 'user-info'

export const useUserInfoQuery = (userId: string) => {
  return useQuery([USER_INFO_QUERY, userId], async () => {
    const res = await getUserInfo(userId)
    return res.data.data
  })
}
