import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useQualifyEmployeeMutation } from '@/src/queries/create-accounts'
import { useModalStore } from '@/store/modal'
import { QualifyEmployeeConfirmation } from './QualifyEmployeeConfirmation'
import {
  QualifyEmployeeForm,
  QualifyEmployeeFormValues,
} from './QualifyEmployeeForm'

export const QualifyEmployeeModal: FC = () => {
  const [open, setOpen] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [values, setValues] = useState<QualifyEmployeeFormValues>()
  const { removeModal } = useModalStore()
  const qualifyEmployee = useQualifyEmployeeMutation()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleSubmit = async (values: QualifyEmployeeFormValues) => {
    setShowConfirmation(true)
    setValues(values)
  }

  const handleConfirmed = async () => {
    if (values) {
      qualifyEmployee.mutate(
        {
          first_name: values.firstName,
          last_name: values.lastName,
          employee_id: values.employeeId,
          birth_date: values.birthdate,
          ssn: values.ssn,
          phone_number: values.phone ? values.phone : null,
          email: values.email ? values.email : null,
          create_employee: values.createEmployee,
          order_card: values.orderCard,
          business_name: values.businessName,
          ein: values.ein,
          address: {
            address_1: values.address1,
            address_2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zip,
          },
        },
        {
          onSuccess: close,
        }
      )
    }
  }

  return (
    <Dialog open={open} setOpen={close} title="Create Account">
      {showConfirmation ? (
        <QualifyEmployeeConfirmation
          loading={qualifyEmployee.isLoading}
          values={values}
          handleSubmit={handleConfirmed}
        />
      ) : (
        <QualifyEmployeeForm handleFormSubmit={handleSubmit} />
      )}
    </Dialog>
  )
}
