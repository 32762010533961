import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  getTokenCookie,
  removeTokenCookie,
  removeUserIdCookie,
  setTokenCookie,
} from '@/src/lib/token'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 3600000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
})

export const sandboxUtilityHttp = axios.create({
  baseURL: process.env.REACT_APP_SANDBOX_UTILITY_URL,
  timeout: 3600000,
})

const setAuthToken = (config: AxiosRequestConfig) => {
  if (process.env.NODE_ENV === 'development') {
    const token = getTokenCookie()
    if (token) config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

const setAuthCookie = (res: AxiosResponse) => {
  if (
    res.headers &&
    res.headers.authorization &&
    process.env.NODE_ENV === 'development'
  ) {
    setTokenCookie(res.headers.authorization)
  }
  return res
}

const reject = (err: AxiosError) => Promise.reject(err)

// We may not want to automatically log out the user if the token is invalid
// when we begin to implement new roles and permissions
const removeAuthCookie = (err: AxiosError) => {
  if (err && err.response && err.response.status === 401) {
    removeUserIdCookie()
    removeTokenCookie()
    window.location.href = '/login'
  }
  return reject(err)
}

// interceptors
http.interceptors.request.use(setAuthToken, reject)
http.interceptors.response.use(setAuthCookie, removeAuthCookie)

export default http
