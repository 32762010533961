import qs from 'qs'
import {
  IGetLocationsResponse,
  IUploadLocationsConfig,
  IUploadLocationsResponse,
} from '@/api/types/location'
import { CreateLocationFormValues, ILocationFormValues } from '@/types/location'
import http from './http'

/**
 * Allows Customer Success to create a single new location
 * @param location location data
 * @param secondaryNs optional secondary namespace value
 */
export const createLocation = async (location: CreateLocationFormValues) => {
  const params = {
    override_name: true,
    override_address: true,
    override_city: true,
    override_state: true,
    override_zip: true,
    override_organization: true,
  }

  const queryString = qs.stringify(params)

  return http.post(`p1/support/locations?${queryString}`, location)
}

/**
 * Retrieves locations under an organization
 */
export const getLocations = async (orgId: number) =>
  http.get<IGetLocationsResponse>(
    `p1/support/locations?organization_id=${orgId}`
  )

/**
 * Allows Customer Success to update existing locations
 * @param id the location ID
 * @param config new location attribute values
 */
export const updateLocation = async (
  id: string,
  config: ILocationFormValues
) => {
  return http.put(`p1/support/locations/${id}`, config)
}

/**
 * Allows Customer Success to batch upload new locations
 * @param file CSV file containing location data
 * @param secondaryNs optional secondary namespace value
 */
export const uploadLocations = async (
  config: IUploadLocationsConfig,
  orgId: number
) => {
  const { secondaryNs, overrides, file } = config
  const formData = new FormData()
  formData.append('file', file)

  const params = {
    secondary_ns: secondaryNs,
    organization_id: orgId || '',
    override_name: overrides && overrides.name,
    override_address: overrides && overrides.address,
    override_city: overrides && overrides.city,
    override_state: overrides && overrides.state,
    override_zip: overrides && overrides.zip,
    override_organization: overrides && overrides.organization,
  }

  const queryString = qs.stringify(params)

  return http.post<IUploadLocationsResponse>(
    `p1/support/locations?${queryString}`,
    formData
  )
}
