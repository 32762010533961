import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createResource, deleteResource, getResources } from '@/api/resources'
import { ICreateResourceFormData } from '@/api/types/resources'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'

export const RESOURCES_QUERY = 'resources-query'

export const useResources = () => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()

  return useQuery(
    [RESOURCES_QUERY],
    async () => {
      const { data } = await getResources(orgId)
      return data.data
    },
    {
      onError: () => {
        addToast({
          type: 'error',
          title: 'Failed to get resources',
        })
      },
    }
  )
}

export const useDeleteResource = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await deleteResource(id)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('resources', 'remove-resource:success')
        queryClient.invalidateQueries([RESOURCES_QUERY])
        addToast({
          type: 'success',
          title: 'Success',
          description: 'Resource has been deleted.',
        })
      },
      onError: () => {
        sendAnalyticsEvent('resources', 'remove-resource:failure')
        addToast({
          type: 'error',
          title: 'Error',
          description: `Resource was not deleted successfully.`,
        })
      },
    }
  )
}

export const useCreateResource = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()
  return useMutation(
    async (data: ICreateResourceFormData) => {
      const res = await createResource(data, orgId)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('resources', 'create-resource:success')
        queryClient.invalidateQueries([RESOURCES_QUERY])
        addToast({
          type: 'success',
          title: 'Success',
          description: 'Resource successfully created.',
        })
      },
      onError: () => {
        sendAnalyticsEvent('resources', 'create-resource:failure')
        addToast({
          type: 'error',
          title: 'Error',
          description: `Failed to create resource.`,
        })
      },
    }
  )
}
