import { cloneElement } from 'react'
import { Transition } from 'react-transition-group'

interface IProps {
  children: React.ReactElement
  in: boolean
}

const StepTransition = (props: IProps) => {
  return (
    <Transition
      in={props.in}
      timeout={350}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {(state: string) =>
        cloneElement(props.children, {
          transition: state,
        })
      }
    </Transition>
  )
}

export default StepTransition
