import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { AddFundingSourceForm } from './AddFundingSourceForm'

export const AddFundingSourceModal: FC = () => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)

  const onClose = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog open={open} setOpen={onClose} title="Add Funding Source">
      <AddFundingSourceForm onSuccess={onClose} />
    </Dialog>
  )
}
