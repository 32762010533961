import { FC, useMemo } from 'react'
import { Card } from '@/common/card'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { Flex } from '@/common/layout'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import { Text } from '@/common/text'
import { toDollar } from '@/lib/currency'
import { useGetWorkerRemittance } from '@/queries/remittances'
import { columns } from '../lib/remittanceTransactionColumns'

interface Props {
  employeeId: string
  remittanceId: string
}

export const RemittanceTransactions: FC<Props> = ({
  employeeId,
  remittanceId,
}) => {
  const workerRemittancesQuery = useGetWorkerRemittance({
    employeeId,
    remittanceId,
  })

  const advances = useMemo(
    () =>
      workerRemittancesQuery.data?.data.employees[employeeId].advances || [],
    [workerRemittancesQuery, employeeId]
  )

  const totalOwed = useMemo(() => {
    return advances.reduce(
      (acc, advanceDeduction) => acc + advanceDeduction.outstanding_amount,
      0
    )
  }, [advances])

  const totalPaid = useMemo(() => {
    return advances.reduce(
      (acc, advanceDeduction) => acc + advanceDeduction.deducted_amount,
      0
    )
  }, [advances])

  if (workerRemittancesQuery.isLoading) return <Loader loading={true} />

  if (advances.length === 0) {
    return (
      <Card css={{ mb: '$8' }}>
        <ContentEmpty
          icon={<EmptyTableIcon />}
          height="xs"
          title="Deductions have not yet been applied"
          subtitle="Deductions applied through the remittance process may appear here if the payment is applied to this advance."
        />
      </Card>
    )
  }

  return (
    <Card css={{ mb: '$8' }}>
      <Flex direction="row" justify="between">
        <h4>Advances</h4>
        <Flex direction="column" align="normal">
          <Text>
            Total Paid: <strong>{toDollar(`${totalPaid}`)}</strong>
          </Text>
          <Text>
            Total Owed: <strong>{toDollar(`${totalOwed}`)}</strong>
          </Text>
        </Flex>
      </Flex>
      <SimpleTable
        data={advances}
        columns={columns}
        isLoading={workerRemittancesQuery.isLoading}
      />
      <hr />
    </Card>
  )
}
