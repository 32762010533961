import { FC } from 'react'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useRemovePdqStore } from '@/src/queries/pdq'
import { useModalStore } from '@/store/modal'
import { PdqStore } from '@/types/settings'
import { AddStoreModal } from './AddStoreModal'
import { EditStoreModal } from './EditStoreModal'
import { RunJobModal } from './RunJobModal'

interface Props {
  original: PdqStore
}

export const StoreActionDropdown: FC<Props> = ({ original }) => {
  const removeStore = useRemovePdqStore()
  const { registerModal } = useModalStore()

  const openEditStoreModal = () => {
    registerModal(<EditStoreModal store={original} />)
  }

  const openAddStoreModal = () => {
    registerModal(<AddStoreModal store={original} />)
  }

  const openRunJobModal = () => {
    registerModal(<RunJobModal store={original} />)
  }

  const handleDelete = () => {
    removeStore.mutate(original.registration?.store_id || '')
  }

  return (
    <TableActionDropdown
      menu={[
        {
          label: 'Edit Registration',
          handleClick: openEditStoreModal,
          hide: !original.registered,
        },
        {
          label: 'Run Job',
          handleClick: openRunJobModal,
          hide: !original.registered || !original.active,
        },
        {
          label: 'Add Registration',
          handleClick: openAddStoreModal,
          hide: original.registered || !original.active,
        },
        {
          label: 'Remove Registration',
          handleClick: handleDelete,
          hide: original.active,
        },
      ]}
    />
  )
}
