import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { TrackingCell } from '../components/TrackingCell'
import { ViewCardsCell } from '../components/ViewCardsCell'

export type InstantCardGroupRow = {
  card_name_prefix: string
  card_product: 'BRANCH_1099_INSTANT' | 'BRANCH_W2_INSTANT'
  count: number
  id: number
  initiated: boolean
  tracking_number: null | string
  time_ordered: null | string
  address_1: string
  address_2: null | string
  city: string
  care_of: null | string
  first_name: string
  middle_name: null | string
  last_name: string
  state: string
  country: null | string
  zip: string
}

export const columns: ColumnDef<InstantCardGroupRow, any>[] = [
  {
    header: 'ID',
    id: 'id',
    size: 100,
    accessorFn: row => row.id,
  },
  {
    header: 'Prefix',
    id: 'prefix',
    accessorFn: row => row.card_name_prefix,
  },
  {
    header: 'Type',
    id: 'card_product',
    accessorFn: row => (row.card_product.includes('W2') ? 'W2' : '1099'),
    enableSorting: false,
  },
  {
    header: 'Count',
    id: 'count',
    accessorFn: row => row.count,
  },
  {
    header: 'To',
    id: 'to',
    accessorFn: row => `${row.first_name} ${row.last_name}`,
  },
  {
    header: 'Care of',
    id: 'care_of',
    accessorFn: row => row.care_of,
  },
  {
    header: 'Shipping Address',
    id: 'address',
    accessorFn: row =>
      `${row.address_1}${row.address_2 ? ` ${row.address_2}` : ''}, ${
        row.city
      }, ${row.state}`,
  },
  {
    header: 'Tracking #',
    id: 'tracking',
    accessorFn: row => row.tracking_number,
    cell: ({ row }) => <TrackingCell value={row.original.tracking_number} />,
  },
  {
    header: 'Order Date',
    id: 'time_ordered',
    accessorFn: row =>
      row.time_ordered ? moment(row.time_ordered).format('MM/DD/YY') : null,
    enableSorting: false,
  },
  {
    header: 'Cards',
    id: 'view-cards',
    accessorFn: row => row.id,
    cell: ({ row }) => <ViewCardsCell value={row.original.id} />,
    enableSorting: false,
  },
]
