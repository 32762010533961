import { useCallback, useMemo, useState } from 'react'

type RowType = 'question' | 'match'

export type MatchObject = { [key: string]: string }

export type Question = {
  value?: any
  text: string
  label: string
  shouldHide?: (q: Questions) => boolean
  options: { value: any; label: string }[]
}

export type Matcher = {
  value?: string
  dataField: string
  label: string
  shouldHide?: (q: Questions) => boolean
}

export type MatchRow = {
  value?: string
  key: string
  dataField: string
  label: string
  hide: boolean
  type: RowType
}

export type QuestionRow = Question & {
  type: RowType
  hide: boolean
  key: string
}

export type Matchers = { [key: string]: Matcher }
export type Questions = { [key: string]: Question }

export const useMatchStore = (m: Matchers, q?: Questions) => {
  const [matchers, setMatchers] = useState(m)
  const [questions, setQuestions] = useState(q)

  const setMatch = useCallback(
    (key: string, csvField: string) => {
      // first remove this match from any other columns
      const updatedMatchers: Matchers = { ...matchers }
      Object.keys(updatedMatchers).forEach(key => {
        if (updatedMatchers[key].value === csvField) {
          updatedMatchers[key].value = undefined
        }
      })
      // then set the new match
      updatedMatchers[key].value = csvField
      setMatchers(updatedMatchers)
    },
    [matchers, setMatchers]
  )

  const setAnswer = useCallback(
    (key: string, value: string) => {
      if (questions) {
        const updatedQuestions = {
          ...questions,
          [key]: {
            ...questions[key],
            value,
          },
        }
        setQuestions(updatedQuestions)
      }
    },
    [questions, setQuestions]
  )

  const questionRows = useMemo(() => {
    const qs = questions || {}
    const allRows = Object.keys(qs || {}).map<QuestionRow>(key => {
      const { value, text, options, label, shouldHide } = qs[key]
      return {
        type: 'question',
        value,
        label,
        text,
        options,
        hide: shouldHide ? shouldHide(qs) : false,
        key,
      }
    })
    const visibleRows = allRows.filter(r => !r.hide)
    return visibleRows
  }, [questions])

  const matchRows = useMemo(() => {
    const allRows = Object.keys(matchers).map<MatchRow>(key => {
      const { value, dataField, label, shouldHide } = matchers[key]
      return {
        type: 'match',
        value,
        dataField,
        label,
        hide: shouldHide && questions ? shouldHide(questions) : false,
        key,
      }
    })
    const visibleRows = allRows.filter(r => !r.hide)
    return visibleRows
  }, [matchers, questions])

  const itemRows = useMemo(() => [...questionRows, ...matchRows], [
    questionRows,
    matchRows,
  ])

  const mapMatchesToObj = useCallback(() => {
    const obj: MatchObject = {}
    matchRows.forEach(r => {
      obj[r.dataField] = r.value || ''
    })
    return obj
  }, [matchRows])

  const hasAnsweredAllQuestions = useMemo(
    () => questionRows.every(m => typeof m.value !== 'undefined'),
    [questionRows]
  )

  const hasAllMatchesSet = useMemo(() => matchRows.every(m => !!m.value), [
    matchRows,
  ])

  const clearMatches = useCallback(() => {
    const updatedMatchers = { ...matchers }
    Object.keys(updatedMatchers).forEach(key => {
      updatedMatchers[key].value = undefined
    })
    setMatchers(updatedMatchers)
  }, [matchers, setMatchers])

  const clearQuestions = useCallback(() => {
    const updatedQuestions = { ...questions }
    Object.keys(updatedQuestions).forEach(key => {
      updatedQuestions[key].value = undefined
    })
    setQuestions(updatedQuestions)
  }, [questions, setQuestions])

  const reset = useCallback(() => {
    clearMatches()
    clearQuestions()
  }, [clearMatches, clearQuestions])

  return {
    matchers,
    questions,
    setMatch,
    setAnswer,
    questionRows,
    matchRows,
    itemRows,
    mapMatchesToObj,
    hasAnsweredAllQuestions,
    hasAllMatchesSet,
    clearMatches,
    clearQuestions,
    reset,
  }
}
