import { IRosterUser } from './employees'

export enum PayoutStatus {
  FAILED = 'FAILED', // payout failed for some reason
  CREATED = 'CREATED', // acknowledge a payout was saved
  'ATTEMPTING PAYBACK' = 'ATTEMPTING PAYBACK', // payout succeeded, but something may have gone wrong with payback
  COMPLETED = 'COMPLETED', // whole process is completed,
  CANCELLED = 'CANCELLED', // payback has been manually cancelled by user
  PAYBACK_FAILED = 'PAYBACK_FAILED',
  SCHEDULED = 'SCHEDULED',
  SKIPPED = 'SKIPPED',
}

export type PayoutRecord = {
  amount: number
  description: string
  external_id: string
  external_store_id: string
  id: number
  failure_reason?: string
  status_reason?: string
  metadata?: {
    [key: string]: any
  }
  employee: IRosterUser
  roster_record: {
    employee_id: string
    first_name?: string
    last_name?: string
    user_id?: number
  }
  created_by?: number
  system_created: boolean
  organization_id: number
  number_of_attempts: number
  payback_id: number | null
  status: PayoutStatus
  disbursement_time: string
  time_created: string
  time_modified: string | null
  time_scheduled: string | null
  invoice_date: string | null
  payout_sub_label: string
  type: string
  reason_code: string
}
