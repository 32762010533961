import { FC, useState } from 'react'
import { Button } from '@/components/common/button'
import { Box, Flex } from '../layout'
import { MoreFiltersDropdownRow } from './MoreFiltersDropdownRow'
import { AdditionalFilterItem, TableFilters, UpdateFilters } from './types'

interface Props {
  initialFocusId?: string
  filters: TableFilters
  updateFilters: UpdateFilters
  menu: AdditionalFilterItem[]
  setPopoverOpen: (open: boolean) => void
}

export const MoreFiltersDropdown: FC<Props> = ({
  filters,
  updateFilters,
  menu,
  initialFocusId,
  setPopoverOpen,
}) => {
  const [values, setValues] = useState<TableFilters>(filters)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation()
    e.preventDefault()
    updateFilters(values)
    setPopoverOpen(false)
  }

  const handleChange = (params: { id: string; value: any }) => {
    return setValues({ ...values, [params.id]: params.value })
  }

  return (
    <Box css={{ padding: '$16' }}>
      <form onSubmit={handleSubmit}>
        <Box css={{ px: '$4', py: '$4', overflow: 'auto', maxHeight: 200 }}>
          {menu.map((m, index) => (
            <MoreFiltersDropdownRow
              value={values[m.id] || ''}
              handleChange={handleChange}
              hasInitialFocus={initialFocusId === m.id || index === 0}
              item={m}
              key={m.id}
            />
          ))}
        </Box>
        <Flex
          css={{ borderTop: '1px solid $secondary700', pt: '$12' }}
          justify="end"
        >
          <Button mode="filled" type="submit">
            Submit
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
