import { Loader } from '@/common/loader'
import { useSSOConfig, useSSOMappings } from '@/queries/sso'
import { SSOForm } from './SSOForm'
import { SSOMappings } from './SSOMappings'

export const SSOConfig = () => {
  const ssoConfig = useSSOConfig()
  const mappings = useSSOMappings()

  return (
    <Loader loading={ssoConfig.isLoading}>
      <SSOForm data={ssoConfig.data} />
      {!!ssoConfig.data && <SSOMappings data={mappings.data || []} />}
    </Loader>
  )
}
