import classnames from 'classnames'
import { FC } from 'react'
import styles from './index.module.scss'

type Props = {
  hasAside?: boolean
}

const StepBody: FC<Props> = ({ children, hasAside }) => {
  const classes = classnames(styles.StepBody, {
    [styles.StepBody_hasAside]: hasAside,
  })
  return (
    <div className={classes}>
      <div className={styles.StepTransition}>{children}</div>
    </div>
  )
}

export default StepBody
