import { GetPartnersResponse } from '@/api/types/organization'
import http from './http'

export const getPartners = () =>
  http.get<GetPartnersResponse>(`p1/partners?size=100`)

export const createPartnerOrganizationMapping = ({
  orgId,
  partnerId,
  referenceId,
}: {
  orgId: number
  partnerId: number
  referenceId: string
}) =>
  http.post(`wfm/partners/${partnerId}/mappings/organizations/${orgId}`, {
    reference_id: referenceId,
  })

export const deletePartnerOrganizationMapping = ({
  partnerId,
  id,
}: {
  partnerId: number
  id: number
}) => http.delete(`wfm/partners/${partnerId}/mappings/organizations/${id}`)

export const getOrganizationStores = (orgId: number) =>
  http.get<string[]>(`payout/organizations/${orgId}/external-store-ids`)
