import { AxiosError } from 'axios'
import { useMutation, useQuery } from 'react-query'
import {
  createOrganization,
  getGroups,
  updateOrganization,
} from '@/api/organizations'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { OrgCreationFormValues } from '@/components/organizations/OrgCreationModal'
import { useToasterStore } from '@/store/toast'

export const useCreateOrg = () => {
  const { addToast } = useToasterStore()
  return useMutation(
    async (params: OrgCreationFormValues) => {
      const res = await createOrganization(params.name, params.key)
      return res.data.data
    },
    {
      onError: (err: AxiosError) => {
        addToast({
          type: 'error',
          title: 'Failed to create organization',
          description: `${err.response?.data?.meta?.errorDetail}`,
        })
      },
    }
  )
}

type UpdateOrgParams = {
  name: string
  logo_url?: string | null
}

export const useUpdateOrganization = () => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()
  return useMutation(
    async (data: UpdateOrgParams) => {
      const res = await updateOrganization(data, orgId)
      return res.data
    },
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Settings Updated',
        })
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Failed to update settings.',
          description:
            'Please try again soon, and if the issue persists, contact support.',
        })
      },
    }
  )
}

const ORG_GROUPS_QUERY = 'org-groups'

export const useGroupsQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([ORG_GROUPS_QUERY, orgId], async () => {
    const { data } = await getGroups(orgId)
    return data.data
  })
}
