import { useCallback, useEffect, useRef, useState } from 'react'
import { SearchDropdownContainer } from '@/components/search-dropdown-field'
import { useFundingSourceMappings } from '@/hooks/useInvoiceConfig'
import useEscapeClickHandler from '@/src/hooks/useEscapeClickHandler'
import useOutsideClickHander from '@/src/hooks/useOutsideClickHandler'
import { IListItem } from './lib/types'
import { StoreList } from './StoreList'
import { StoreSelectField } from './StoreSelectField'

interface IProps {
  setFieldValue: (id: string) => void
  storeId: string
}

export const StoreSelect: React.FC<IProps> = ({ setFieldValue, storeId }) => {
  const [list, setList] = useState<IListItem[]>([])
  const fundingSources = useFundingSourceMappings()
  const [selectedItem, setSelectedItem] = useState<IListItem>()
  const [open, setOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const storeIds = fundingSources
      .filter(rec => !!rec.group_id)
      .map(rec => rec.group_id)

    const mapped = [...new Set(storeIds)].map(id => ({
      id: `${id}`,
      label: `${id}`,
    }))
    setList(mapped)
  }, [fundingSources])

  const hide = useCallback(() => {
    if (open) setOpen(false)
  }, [open, setOpen])

  useEscapeClickHandler(hide)
  useOutsideClickHander(ref, hide, open)

  const handleSelect = (item: IListItem) => {
    setFieldValue(item.id)
    setSelectedItem(item)
    setOpen(false)
  }

  return (
    <SearchDropdownContainer ref={ref}>
      <StoreSelectField
        selectedItem={selectedItem}
        handleClick={() => setOpen(true)}
      />
      {open && (
        <StoreList
          handleSelect={handleSelect}
          loadingData={false}
          list={list}
          id={storeId}
          selected={selectedItem}
        />
      )}
    </SearchDropdownContainer>
  )
}
