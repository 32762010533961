import { RouteProps } from 'react-router-dom'
import { usePermissionsStore } from '@/store/permissions'
import { AuthenticatedRoute } from './AuthenticatedRoute'

const AdminRoute = (props: RouteProps) => {
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  return <AuthenticatedRoute isAuthenticated={() => isGlobalAdmin} {...props} />
}

export default AdminRoute
