import qs from 'qs'
import http from './http'
import {
  GetTimekeepingResponse,
  TimekeepingRequestParams,
} from './types/record'

/**
 * Retrieve time and attendance data
 */
export const getTimeAndAttendanceTable = (
  orgId: number,
  params: TimekeepingRequestParams
) => {
  const query = qs.stringify(params)
  return http.get<GetTimekeepingResponse>(
    `p1/organizations/${orgId}/tracked_times?${query}`
  )
}
