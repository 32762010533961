import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { checkThatAllFilesAreSupported, getFileText } from './utils'

interface Props {
  handleDrop: (files: File[]) => void
  subtext?: string
  className?: string
  label?: string
  files: File[]
  hasError?: boolean
  error?: string
  allowMultiple?: boolean
  supportedFileTypes?: string[]
  placeholder?: string
  disabled?: boolean
  accept?: string | string[]
}

export const InputDropzone = (props: Props) => {
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const {
    subtext,
    className,
    label = 'Filed Upload',
    handleDrop,
    files,
    hasError = false,
    error = '',
    allowMultiple = true,
    supportedFileTypes,
    placeholder,
    disabled,
    accept,
  } = props

  useEffect(() => {
    setShowError(hasError)
    setErrorMsg(error)
  }, [error, hasError])

  const onDrop = (files: File[]) => {
    setShowError(false)
    setErrorMsg('')
    const tooMany = files.length > 1 && !allowMultiple
    const allFilesAreSupported =
      !supportedFileTypes ||
      checkThatAllFilesAreSupported(files, supportedFileTypes)
    if (tooMany || !allFilesAreSupported) {
      setShowError(true)
      setErrorMsg(
        tooMany
          ? 'Only upload one file'
          : 'You submitted an invalid file format'
      )
    } else {
      return handleDrop(files)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  const classes = classnames('input-dropzone', className, {
    'drag-active': isDragActive,
    disabled: disabled,
  })

  const fileText = getFileText(files)

  return (
    <div {...getRootProps()} className={classes}>
      <Flex direction="column" align="start">
        {label && <Label css={{ mb: '$4' }}>{label}</Label>}
        <Input {...getInputProps()} disabled={disabled} />
        <div className="input-dropzone-field">
          <span>{fileText || placeholder}</span>
        </div>

        {showError && (
          <Text color="alert" size="sm" css={{ mt: '$4' }}>
            {errorMsg}
          </Text>
        )}
        {!!subtext && (
          <Text color="hint" size="sm" css={{ mt: '$4' }}>
            {subtext}
          </Text>
        )}
      </Flex>
    </div>
  )
}
