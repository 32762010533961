import { ResetIcon } from '@radix-ui/react-icons'
import { ColumnOrderState } from '@tanstack/react-table'
import { FC, useState } from 'react'
import { Button } from '@/common/button'
import PreviewIcon from '@/common/icons/PreviewIcon'
import { Flex } from '@/common/layout'
import { AlertDialog } from '@/common/modal'
import { Text } from '@/common/text'
import {
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { disbursementColumnsDemo } from '@/components/payouts/lib/columns'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'
import { useModalStore } from '@/store/modal'
import { useToasterStore } from '@/store/toast'
import { ColumnList } from './ColumnList'
import { TablePreview } from './TablePreview'

export const TableConfigurationContainer: FC = ({ children }) => {
  const [hiddenColumns, setHiddenColumns] = useState<{
    [key: string]: boolean
  }>()
  const [columnConfig, setColumnConfig] = useState(disbursementColumnsDemo)
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])

  const { registerModal } = useModalStore()
  const { addToast } = useToasterStore()

  const openTablePreviewModal = () => {
    registerModal(
      <TablePreview columnOrder={columnOrder} hiddenColumns={hiddenColumns} />
    )
  }

  // async for now to simulate API call and work for alert dialog
  const save = async () => {
    addToast({
      title: 'Table columns successfully updated',
      type: 'success',
    })
  }

  const confirmSave = () => {
    registerModal(
      <AlertDialog
        title="Are you sure you want to save?"
        actionDescription="Confirm"
        handleAction={() => save()}
        description={
          <p>
            This will update the disbursement table columns for all users in
            your in your organizaiton
          </p>
        }
      />
    )
  }

  const restoreDefaults = () => {
    addToast({
      title: 'Table columns successfully reset to defaults',
      type: 'success',
    })
  }

  return (
    <SettingsContainer>
      <PageTop simple>
        <PageHeader>
          <PageTitle primary="Disbursement Table Columns" />
          <Button
            color="black"
            css={{ mr: '$8' }}
            iconLeft
            onClick={openTablePreviewModal}
          >
            <PreviewIcon style={{ transform: 'scale(1.1)' }} />
            Preview
          </Button>
          <Button color="black" iconLeft onClick={restoreDefaults}>
            <ResetIcon style={{ transform: 'scale(1.25)' }} />
            Restore Defaults
          </Button>
        </PageHeader>
        <Text as="h5">Drag and drop to reorder or hide</Text>
        <Text color="hint">
          Column settings have no impact on the Disbursements download file. All
          columns will be included regardless.
        </Text>
      </PageTop>
      <PageBody>
        <ColumnList
          setColumnOrder={setColumnOrder}
          setHiddenColumns={setHiddenColumns}
          columnConfig={columnConfig}
          setColumnConfig={setColumnConfig}
        />
        <Flex justify="end" css={{ mt: '$24' }}>
          <Button mode="filled" onClick={confirmSave}>
            Save
          </Button>
        </Flex>
      </PageBody>
    </SettingsContainer>
  )
}
