import { useEffect, useMemo } from 'react'
import { Loader } from '@/common/loader'
import { Table } from '@/common/table/Table'
import { useInstantCardsQuery } from '@/src/queries/instant-cards'
import { useInstantCardsStore } from '@/store/instant-cards'
import { InstantCardsContainer } from './components/InstantCardsContainer'
import { InstantCardsStats } from './components/InstantCardsStats'
import { MarketingMessage } from './components/MarketingMessage'
import { instantCardColumns, InstantCardRow } from './lib/columns'

const initialSortedState = [{ id: 'order_id', desc: true }]

export const InstantCards = () => {
  const {
    setSorted,
    page,
    pageSize,
    updatePage,
    updatePageSize,
    sorted,
    computed: { filterArray },
    isReady,
    filteredData,
    setFilteredData,
    ready,
  } = useInstantCardsStore()
  const orders = useInstantCardsQuery()

  const cards = useMemo<InstantCardRow[]>(() => {
    return (orders.data || [])
      .map(order =>
        (order.cards || []).map(card => ({
          ...card,
          orderId: order.id,
          card_product: order.card_product,
        }))
      )
      .flat()
  }, [orders.data])

  // Default sort
  useEffect(() => {
    if (ready) return
    setSorted(initialSortedState)
    isReady()
  }, [isReady, setSorted, ready])

  return (
    <InstantCardsContainer
      downloadableRows={filteredData as InstantCardRow[]}
      error={orders.error as Error}
    >
      <Loader loading={orders.isLoading}>
        {cards.length > 0 && <InstantCardsStats cards={cards} />}
        {cards.length === 0 ? (
          <MarketingMessage />
        ) : (
          <Table
            columns={instantCardColumns}
            data={cards}
            controlledPagination={false}
            columnFiltersState={filterArray}
            setFilteredData={setFilteredData}
            onSortedChange={setSorted}
            initialSortedState={initialSortedState}
            sorted={sorted}
            page={page}
            pageRows={pageSize}
            updatePageSize={updatePageSize}
            changePage={updatePage}
          />
        )}
      </Loader>
    </InstantCardsContainer>
  )
}
