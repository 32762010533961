import { FC } from 'react'
import { Button } from '@/components/common/button'
import {
  Step,
  StepBody,
  StepFooter,
  StepFooterLeft,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { SimpleTable } from '../table/SimpleTable'

interface Props {
  data: any[]
  columns: any[]
  transition?: string
  handleClose: () => void
  handleSubmit: () => void
}

export const BulkUploadReview: FC<Props> = ({
  data,
  columns,
  transition,
  handleClose,
  handleSubmit,
}) => (
  <Step state={transition}>
    <StepBody>
      <StepTitle>Review your file contents</StepTitle>

      <SimpleTable data={data} columns={columns} />
    </StepBody>
    <StepFooter>
      <StepFooterLeft>
        <Button mode="outline" onClick={handleClose}>
          Cancel
        </Button>
      </StepFooterLeft>
      <StepFooterRight>
        <Button mode="filled" onClick={handleSubmit}>
          Submit
        </Button>
      </StepFooterRight>
    </StepFooter>
  </Step>
)
