import {
  IGetInstantCardGroupsResponse,
  IOrderInstantCardsConfig,
  IOrderInstantCardsResponse,
} from '@/api/types/instant-cards'
import http from './http'

/**
 * Allows Pay Admin to order new instant card batch
 * @param config Data defining how many cards to create, where to ship them, and which generic
 * name to use on the card name fields.
 */
export const orderInstantCards = (
  orgId: number,
  config: IOrderInstantCardsConfig
) =>
  http.post<IOrderInstantCardsResponse>(
    `p1/wallet/organizations/${orgId}/instant_card_orders`,
    config
  )

/**
 * Retrieve all instant card orders
 */
export const getInstantCardOrders = (orgId: number) =>
  http.get<IGetInstantCardGroupsResponse>(
    `p1/wallet/organizations/${orgId}/instant_card_orders?include_cards=true`
  )
