export const readFileAsText = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader()

    reader.onload = () => resolve(reader.result)
    reader.onerror = (err: any) => reject(err)

    reader.readAsText(file)
  })

export const readFileAsDataURL = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader: any = new FileReader()

    reader.onload = () => resolve(reader.result)
    reader.onerror = (err: any) => reject(err)

    reader.readAsDataURL(file)
  })

export const getBase64FromFile = async (file: File) => {
  const result = await readFileAsDataURL(file)
  return result.split(',')[1]
}
