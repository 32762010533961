import { saveAs } from 'file-saver'
import { sendAnalyticsEvent } from '@/lib/analytics'

const templateCsvContent =
  "First Name,Last Name,Worker's ID,Birthdate (YYYY-MM-DD),SSN,Email, Phone, Address 1,Address 2,City,State,ZIP,EIN,Business Name\nAva,Butler,333444,1990-01-25,123456789,ava@gmail.com,2024561414,1234 Garden St,27,Minneapolis,MN,55123,(optional),(optional)\n"

export const downloadBulkQualifyTemplate = () => {
  sendAnalyticsEvent('accounts', 'bulk-upload:download-template')
  const blob = new Blob([templateCsvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch-create-accounts-template.csv')
}
