type Props = { [key: string]: any }

const SvgComplete = (props: Props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-4.775-8.383l2.391 2.391a1.11 1.11 0 001.568-.006l6.332-6.332a1.111 1.111 0 00.008-1.57l.163.164a1.106 1.106 0 00-1.563.002l-5.727 5.726-1.777-1.77a1.116 1.116 0 00-1.568.007l.179-.18a1.108 1.108 0 00-.006 1.568z"
        fill="#4CB258"
      />
    </g>
  </svg>
)

export default SvgComplete
