import { FC } from 'react'
import styles from './index.module.scss'

const StepFooter: FC = props => {
  return (
    <div className={styles.StepFooter}>
      <div className={styles.StepFooterContent}>{props.children}</div>
    </div>
  )
}

export default StepFooter

export const StepFooterRight: FC = props => {
  return <div className={styles.StepFooterRight}>{props.children}</div>
}

export const StepFooterLeft: FC = props => {
  return <div className={styles.StepFooterLeft}>{props.children}</div>
}
