import { SortingState } from '@tanstack/react-table'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { useState } from 'react'
import { getAdvanceRecords } from '@/api/advances'
import { getRangeText } from '@/common/datepicker/utils'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { toDollar } from '@/lib/currency'
import { DEDUCTION_STATUS_LABELS } from '@/lib/statusLabels'
import { useToasterStore } from '@/store/toast'
import { AdvanceRecord } from '@/types/records'

export const formatAdvancesForDownload = (data: AdvanceRecord[]) => {
  return data.map(item => ({
    Date: item.payout_date,
    Name: `${item.roster_first_name} ${item.roster_last_name}`,
    "Worker's ID": item.employee_id,
    Amount: toDollar(item.amount) || '0',
    Tip: toDollar(item.tip) || '0',
    Fee: toDollar(item.fee) || '0',
    Status: DEDUCTION_STATUS_LABELS[item.status],
    'Location ID': item.location_id,
  }))
}

export const useDownloadTransactions = (params: {
  filters: TableFilters
  sorted: SortingState
}) => {
  const { filters, sorted } = params
  const [loading, setLoading] = useState(false)
  const { addToast, expireToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()

  const download = async () => {
    sendAnalyticsEvent('advances', 'download', filters)
    let toastId: string = ''
    setLoading(true)
    const pageSize = 200
    const { from, to, preset, ...restFilters } = filters
    const params = {
      start: from?.format(),
      end: to?.format(),
      size: pageSize,
      page: 0,
      sort: sorted[0].id,
      direction: sorted[0].desc ? 'desc' : 'asc',
      ...restFilters,
    }

    try {
      const res = await getAdvanceRecords(orgId, params)
      const pageCount = res.data.data.total_pages
      let results = [...res.data.data.content]

      if (pageCount > 1) {
        // Create an array with x number of items where
        // value increments by 1 for each item, starting at 0,
        // so that we can map over these to create each request.
        const additionalPages = pageCount - 1
        const pageKeyArray = [...Array(additionalPages).keys()]

        toastId = addToast({
          type: 'pending',
          title: 'Downloading advances',
          description: `We're fetching ${res.data.data.total_number_of_elements} advances, it might take a moment.`,
        })

        const additionalRequests = pageKeyArray.map(x =>
          getAdvanceRecords(orgId, {
            ...params,
            page: 2 + x,
          })
        )
        const responses = await Promise.all(additionalRequests)
        responses.forEach(response => {
          results = [...results, ...response.data.data.content]
        })
      }

      const csvData = formatAdvancesForDownload(results)

      const rangeText = getRangeText({ start: from, end: to, preset })
      const fileTitle = `${rangeText}_branch_advances.csv`

      const csvContent = Papa.unparse(csvData)
      const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, fileTitle)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      addToast({
        type: 'error',
        title: "Couldn't download results.",
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      if (toastId) {
        expireToast(toastId, 0)
      }
      setLoading(false)
    }
  }

  return {
    download,
    loading,
  }
}
