import { FC } from 'react'
import { updateOrganization } from '@/api/organizations'
import { Button } from '@/common/button'
import { AlertDialog } from '@/common/modal'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useModalStore } from '@/store/modal'
import { Partner } from '@/types/organization'

interface Props {
  partners: Partner[]
}

export const ChooseIntegration: FC<Props> = ({ partners }) => {
  const { registerModal } = useModalStore()
  const { id: orgId } = useCurrentOrg()

  const addPartnerToOrg = async (id: number) => {
    await updateOrganization(
      {
        partner_id: id,
      },
      orgId
    )
    window.location.reload()
  }

  const confirmIntegration = (partner: Partner) => {
    registerModal(
      <AlertDialog
        title="Are you sure?"
        actionDescription="Confirm"
        handleAction={() => addPartnerToOrg(partner.id)}
        description={
          <p>
            To configure the {partner.name} integration, please click confirm
            below.
          </p>
        }
      />
    )
  }

  return (
    <>
      <h5>No Integration Selected</h5>
      <p className="mb-4">To get started, please choose one from below:</p>
      <div className="flex">
        {partners.map(partner => (
          <Button
            mode="filled"
            onClick={() => confirmIntegration(partner)}
            css={{ mr: '$4', mt: '$4' }}
            key={partner.id}
          >
            {partner.name}
          </Button>
        ))}
      </div>
    </>
  )
}
