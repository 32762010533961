import { FC, useEffect, useMemo } from 'react'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { Table } from '@/common/table'
import { useIndefiniteTablePagination } from '@/hooks/useTablePagination'
import { useSidebar } from '@/src/hooks/useSidebar'
import { useInitiatedUsersQuery } from '@/src/queries/create-accounts'
import { useCreateAccountsStore } from '@/store/create-accounts'
import { InitiationUserRecord } from '@/types/create-accounts'
import { CreateAccountsContainer } from './components/CreateAccountsContainer'
import { InitiatedUserDetailsView } from './components/InitiatedUserDetailsView'
import { createAccountsColumns } from './lib/create-accounts-columns'

export const CreateAccounts: FC = () => {
  const {
    page,
    pageSize,
    updatePageSize,
    filters,
    sorted,
    setSorted,
    updatePage,
    updateFilters,
    defaultSort,
    isReady,
  } = useCreateAccountsStore()

  // Update query params when filters change
  useUpdateQueryParamFilters(filters)

  // Retrieve query params and update filters on initial load
  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
    isReady()
  }, [queryParams, updateFilters, isReady])

  const { data, isLoading } = useInitiatedUsersQuery({
    page,
    pageSize,
    sorted,
    filtered: filters,
  })

  const initiatedUsersData = useMemo(() => data?.content || [], [data])

  const { sidebar, addSidebar, removeSidebar } = useSidebar()

  const openSidebar = (record: InitiationUserRecord, id: number) => {
    addSidebar(
      <InitiatedUserDetailsView record={record} remove={removeSidebar} />,
      id
    )
  }

  const { hasNextPage, hasPrevPage } = useIndefiniteTablePagination(
    data?.has_next_page,
    data?.has_prev_page
  )

  return (
    <CreateAccountsContainer sidebar={sidebar}>
      <Table
        columns={createAccountsColumns}
        data={initiatedUsersData}
        controlledPagination={true}
        onSortedChange={setSorted}
        sorted={sorted}
        initialSortedState={defaultSort}
        isLoading={isLoading}
        page={page}
        pageRows={pageSize}
        updatePageSize={updatePageSize}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        changePage={updatePage}
        rowOnClickFn={openSidebar}
        hideLastColumnBorder
      />
    </CreateAccountsContainer>
  )
}
