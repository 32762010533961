import { useState } from 'react'
import { Alert } from '@/common/alert'
import Dropzone from '@/components/file-upload'
import { checkThatAllFilesAreSupported } from '@/components/file-upload/utils'
import { FileImportCard } from './FileImportCard'

interface IProps {
  handleDrop: (file: File) => void
  file?: File
}

export const FileUpload = (props: IProps) => {
  const [showError, setShowError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { handleDrop, file } = props

  const onDrop = (files: File[]) => {
    setShowError(false)
    setErrorMsg('')
    const tooMany = files.length > 1
    const allFilesAreSupported = checkThatAllFilesAreSupported(files, ['csv'])
    if (tooMany || !allFilesAreSupported) {
      setShowError(true)
      setErrorMsg(
        tooMany
          ? 'Only upload one file'
          : 'You submitted an invalid file format'
      )
    } else {
      return handleDrop(files[0])
    }
  }

  return (
    <div className="dropzone-container">
      <Dropzone
        onDrop={onDrop}
        title="Drag and drop your CSV"
        subtitle="or click here to select a file"
        className="mb-2"
      />
      {showError && (
        <Alert type="danger" css={{ mt: '$4' }}>
          {errorMsg}
        </Alert>
      )}
      {!!file && <FileImportCard file={file} />}
    </div>
  )
}
