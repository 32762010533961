import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { useAddPayoutMutation } from '@/src/queries/payouts'
import { PayoutRecord } from '@/types/payouts'

interface Props {
  payout: PayoutRecord
}

export const RetryPayoutModal: FC<Props> = ({ payout }) => {
  const addPayout = useAddPayoutMutation()
  const {
    roster_record: { employee_id },
    amount,
    type,
    description,
    metadata,
    external_id,
    external_store_id,
  } = payout

  const retryPayout = async () =>
    addPayout.mutateAsync({
      employee_id: employee_id,
      amount,
      type,
      description,
      metadata,
      external_id,
      external_store_id,
      retry: true,
    })

  return (
    <AlertDialog
      title="Retry Disbursement"
      handleAction={retryPayout}
      actionDescription={'Retry'}
      disabled={addPayout.isLoading}
    />
  )
}
