import { Pencil1Icon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Badge } from '@/common/badge'
import { Button, IconButton } from '@/common/button'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { AlertDialog } from '@/common/modal'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { OrganizationLogo } from '@/components/layout/account/OrganizationLogo'
import { useSetting } from '@/hooks/useSetting'
import { useUpdateOrganization } from '@/queries/organizations'
import {
  removeCachedCurrentOrganization,
  removeCachedOrganizations,
} from '@/src/sessionstorage'
import { useModalStore } from '@/store/modal'
import { DetailsModal } from './DetailsModal'
import styles from './index.module.scss'

export const CompanyDetails: React.FC = () => {
  const currentOrg = useCurrentOrg()
  const brandColor = useSetting('BRANDING_COLOR_HEX_CODE')
  const [editing, setEditing] = useState(false)
  const headerBg = `linear-gradient(to right bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), ${brandColor}`
  const { registerModal } = useModalStore()
  const updateOrganization = useUpdateOrganization()

  const toggleEditing = () => setEditing(!editing)

  const deleteLogo = async () =>
    await updateOrganization.mutateAsync(
      {
        name: currentOrg.name,
        logo_url: null,
      },
      {
        onSuccess: () => {
          removeCachedCurrentOrganization()
          removeCachedOrganizations()
          window.location.reload()
        },
      }
    )

  const openConfirmDeleteLogo = () => {
    registerModal(
      <AlertDialog
        title="Please confirm deletion"
        description="Are you sure you want to delete the company logo?"
        handleAction={deleteLogo}
        actionDescription="Delete"
        isDestructive
        disabled={updateOrganization.isLoading}
      />
    )
  }

  return (
    <>
      <div className={styles.DetailsBanner}>
        <div className={styles.DetailsHeader} style={{ background: headerBg }}>
          <div className={styles.DotContainer}>
            <div className={styles.DotSquareTop} />
            <div className={styles.DotSquare} />
          </div>
          <IconButton className={styles.EditIcon} onClick={toggleEditing}>
            <Pencil1Icon />
          </IconButton>
          <Flex
            css={{
              position: 'absolute',
              top: '56px',
              left: '24px',
              margin: '0',
            }}
          >
            <OrganizationLogo size="xl" />
          </Flex>
        </div>
        <div className={styles.DetailsBody}>
          <div className="d-flex justify-content-between mb-2">
            <h5>{currentOrg.name}</h5>
            <div className="d-inline-flex align-items-center">
              <Label>Branding color</Label>
              <div
                className={styles.BrandColor}
                style={{ backgroundColor: brandColor }}
              />
            </div>
          </div>
          <Flex>
            <Box>
              <Label className={styles.Label}>KEY:</Label>
              <Badge type="secondary">{currentOrg.org_key}</Badge>
            </Box>
            <Box css={{ ml: '$12' }}>
              <Label className={styles.Label}>ID:</Label>
              <Badge type="secondary">{currentOrg.id}</Badge>
            </Box>
            <Box css={{ ml: 'auto' }}>
              <Button
                onClick={openConfirmDeleteLogo}
                mode="outline"
                disabled={!currentOrg.logo_url}
                color="danger"
                size="sm"
              >
                Remove Logo
              </Button>
            </Box>
          </Flex>
        </div>
      </div>
      <DetailsModal isOpen={editing} onClose={toggleEditing} />
    </>
  )
}
