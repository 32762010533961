import classnames from 'classnames'
import { FC } from 'react'
import { Card, Table } from 'reactstrap'
import { Badge } from '@/common/badge'
import styles from './index.module.scss'
import { PreviewColumn } from './useFilePreview'

interface Props {
  column: PreviewColumn
  matchLabel: string | null
  handleMatch: () => void
  disableInteraction: boolean
}

export const ColumnPreview: FC<Props> = ({
  column,
  matchLabel,
  handleMatch,
  disableInteraction,
}) => {
  const classes = classnames(styles.ColumnPreview, {
    [styles.ColumnPreview_Disabled]: disableInteraction,
  })

  return (
    <Card onClick={handleMatch} className={classes}>
      {!!matchLabel && (
        <Badge type="secondary" size="sm">
          {matchLabel}
        </Badge>
      )}
      <Table>
        <thead>
          <tr>
            <th style={{ width: '50px' }} />
            <th>{column.field}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Ex.</th>
            <td>{column.rows.join(', ')}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}
