import { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { removeUserIdCookie } from '@/lib/token'

export interface Props extends RouteProps {
  isAuthenticated: () => boolean
  redirectPath?: string
}

export const AuthenticatedRoute: FC<Props> = ({
  isAuthenticated,
  redirectPath = '/login',
  ...rest
}: Props) => {
  if (!isAuthenticated()) {
    if (redirectPath === '/login') removeUserIdCookie()
    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: { from: rest.location },
        }}
      />
    )
  }

  return <Route {...rest} />
}
