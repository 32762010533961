import { Row } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { Flex } from '@/common/layout'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import { Text } from '@/common/text'
import { toDollar } from '@/lib/currency'
import { useGetAdvanceDeductions } from '@/queries/employees'
import { AdvanceRecord } from '@/types/records'
import { deductionColumns } from '../lib/columns'

interface Props {
  row: Row<AdvanceRecord>
}

export const TransactionDeductions: FC<Props> = ({
  row: {
    original: { transfer_id, amount },
  },
}) => {
  const advanceDeductions = useGetAdvanceDeductions(transfer_id)

  const paybacks = useMemo(
    () => advanceDeductions.data?.filter(pb => pb.amount > 0) || [],
    [advanceDeductions]
  )

  const totalPaid = useMemo(() => {
    return (advanceDeductions.data || []).reduce(
      (acc, advanceDeduction) => acc + advanceDeduction.amount,
      0
    )
  }, [advanceDeductions])

  if (advanceDeductions.isLoading) return <Loader loading={true}></Loader>

  if (paybacks.length === 0) {
    return (
      <>
        <ContentEmpty
          icon={<EmptyTableIcon />}
          height="xs"
          title="Deductions have not yet been applied"
          subtitle="Deductions applied through the remittance process may appear here if the payment is applied to this advance."
        />
      </>
    )
  }

  return (
    <>
      <Flex direction="row" justify="between">
        <h4>Deductions</h4>
        <Flex direction="column" align="normal">
          <Text>
            Total Paid: <strong>{toDollar(`${totalPaid}`)}</strong>
          </Text>
          <Text>
            Total Owed: <strong>{toDollar(`${amount - totalPaid}`)}</strong>
          </Text>
        </Flex>
      </Flex>
      <SimpleTable
        data={paybacks}
        columns={deductionColumns}
        isLoading={advanceDeductions.isLoading}
      />
      <hr />
    </>
  )
}
