import { useEffect, useMemo, useState } from 'react'

type WindowSize = {
  width?: number
  height?: number
}

const MOBILE_WIDTH = 800

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= MOBILE_WIDTH),
    [windowSize.width]
  )

  return { ...windowSize, isMobile }
}
