import qs from 'qs'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { DateRangePreset } from '@/common/datepicker/types'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { PayoutRecord } from '@/types/payouts'

interface Props {
  payout: PayoutRecord
}

export const ViewPaybackLink: FC<Props> = ({
  payout: { payback_id, time_created },
}) => {
  const invoicesPath = useOrgPath(ROUTES.INVOICES)
  const params = qs.stringify({
    filter_id: payback_id,
    filter_preset: DateRangePreset.ALL_TIME,
  })

  return <Link to={`${invoicesPath}?${params}`}>{payback_id}</Link>
}
