import * as SelectPrimitive from '@radix-ui/react-select'
import { styled } from '@/styles/stitches.config'

export const SelectContainer = styled(SelectPrimitive.Select, {
  padding: '$8',
  border: '2px solid black',
})

export const SelectTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  border: '2px solid $secondary700',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$sm',
  padding: '0 $8',
  boxSizing: 'border-box',
  fontSize: 16,
  lineHeight: 1,
  height: 35,
  gap: 5,
  backgroundColor: 'white',
  '&:disabled': {
    backgroundColor: '$secondary900',
  },
  '&:hover': { border: '2px solid $primary500' },
  '&:focus': {
    border: '2px solid $primary500',
  },
  width: '100%',
})

export const SelectIcon = styled(SelectPrimitive.SelectIcon, {
  color: '$secondary300',
  marginLeft: 'auto',
})

export const SelectStyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: '$sm',
  boxShadow: '$toast',
  zIndex: 9999,
})

export const SelectViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
  boxShadow: '$toast',
})

export const SelectItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  fontSize: 16,
  lineHeight: 1,
  borderRadius: '$sm',
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: '$secondary700',
    pointerEvents: 'none',
  },
  '&:hover': {
    backgroundColor: '$primary500',
    color: 'white',
    fontWeight: 'bold',
  },
  '&[data-highlighted]': {
    backgroundColor: '$primary500',
    fontWeight: 'bold',
  },
})

export const SelectLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: `$secondary500`,
})

export const SelectItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const selectScrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: `$secondary700`,
  cursor: 'default',
}

export const SelectScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  selectScrollButtonStyles
)

export const SelectScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  selectScrollButtonStyles
)

export const SelectNew = SelectPrimitive.Root
export const SelectValue = SelectPrimitive.Value
export const SelectGroup = SelectPrimitive.Group
export const SelectItemText = SelectPrimitive.ItemText
