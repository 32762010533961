import { TRemittanceProgressStep } from '../types'

export const STEP_ORDER: TRemittanceProgressStep[] = [
  'download',
  'process',
  'adjust',
  'pay',
]

/**
 * Returns -1 when the step is before current step,
 * 0 when the step is current step,
 * and 1 when the step is a future step
 */
export const getStepPosition = (
  step: TRemittanceProgressStep,
  currentStep: TRemittanceProgressStep,
  stepOrder: TRemittanceProgressStep[]
): number => {
  let pos = -1
  let foundCurrentStep = false

  // return 0 when step is current step
  if (step === currentStep) {
    return 0
  }

  for (let i = 0; i < stepOrder.length; i++) {
    const s = stepOrder[i]
    // if we stumble upon the current step,
    // set its flag to "found" so we know
    // if a step is in the future.
    if (s === currentStep) {
      foundCurrentStep = true
    }
    // note: this would also catch the "0" case
    // if we had not already handled it above.
    if (s === step && foundCurrentStep) {
      pos = 1
      break
    }
    pos = -1
  }
  return pos
}
