import { FC } from 'react'
import { Input } from '../input'
import { Label } from '../label'
import { Flex } from '../layout'
import { AdditionalFilterItem } from './types'

interface Props {
  hasInitialFocus: boolean
  handleChange: (params: { id: string; value: string }) => void
  value: any
  item: AdditionalFilterItem
}

export const MoreFiltersDropdownRow: FC<Props> = ({
  hasInitialFocus,
  handleChange,
  value,
  item,
}) => {
  const input = item.renderInput ? (
    item.renderInput({
      hasInitialFocus,
      item,
      value,
      handleChange: (value: any) => handleChange({ id: item.id, value }),
    })
  ) : (
    <Input
      value={value}
      autoFocus={hasInitialFocus}
      onChange={e => {
        handleChange({ id: item.id, value: e.target.value })
      }}
    />
  )

  return (
    <Flex css={{ mb: '$8' }}>
      <Flex justify="start" css={{ width: '40%', mr: '$12' }}>
        <Label>{item.label}</Label>
      </Flex>
      <Flex justify="start" css={{ width: '60%' }}>
        {input}
      </Flex>
    </Flex>
  )
}
