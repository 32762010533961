import { useCallback } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Button } from '@/common/button'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import {
  AsidePageMain,
  AsidePageSidebar,
  PageBack,
  PageBody,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { useRemittance, useRemittanceDeductions } from '@/queries/remittances'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { CompletedRemittanceDetailsAside } from './components/CompletedRemittanceDetailsAside'
import { DeductionReportTitle } from './components/DeductionReportTitle'
import { RemittanceStatusDescription } from './components/RemittanceStatusDescription'
import { RemittanceTransactions } from './components/RemittanceTransactions'
import styles from './index.module.scss'
import { columns } from './lib/completeColumns'
import { downloadDeductions } from './lib/downloadDeductions'
import { useDownloadRemittance } from './lib/useDownloadRemittance'

export const DeductionReportDetails = () => {
  const { id } = useParams<{ id: string }>()
  const remittance = useRemittance(id)
  const deductions = useRemittanceDeductions(id)

  const { download, pendingDownload } = useDownloadRemittance(remittance.data)

  const remittancesPath = useOrgPath(ROUTES.REMITTANCES)
  const completeRemittancePath = useOrgPath(ROUTES.REMITTANCE_COMPLETE, {
    id: remittance.data?.id || 0,
  })
  const invoicesPath = useOrgPath(ROUTES.INVOICES)

  const downloadDeductionsCsv = useCallback(() => {
    if (!deductions.data || !remittance.data?.id) return
    downloadDeductions(deductions.data, remittance.data?.id)
  }, [deductions.data, remittance.data])

  return (
    <PageBody>
      <Loader loading={remittance.isLoading || deductions.isLoading}>
        {remittance.data && (
          <>
            <AsidePageMain>
              <PageTop>
                <PageBack to={remittancesPath} text={'Back to Remittances'} />
                <PageTitle
                  primary={
                    <DeductionReportTitle remittance={remittance.data} />
                  }
                />
              </PageTop>

              <RemittanceStatusDescription remittance={remittance.data} />

              <div className="d-flex align-items-center mb-4">
                {remittance.data.status === 'started' && (
                  <Link to={completeRemittancePath}>
                    <Button css={{ mr: '$4' }} mode="filled">
                      Complete remittance
                    </Button>
                  </Link>
                )}

                {remittance.data.payback && (
                  <Link
                    to={`${invoicesPath}?filter_id=${remittance.data.payback.id}`}
                  >
                    <Button css={{ mr: '$4' }} mode="filled">
                      View Invoice
                    </Button>
                  </Link>
                )}

                {remittance.data.status !== 'started' && (
                  <Button
                    mode="outline"
                    loading={pendingDownload}
                    disabled={pendingDownload}
                    onClick={download}
                  >
                    Download Remittance File
                  </Button>
                )}
              </div>

              {remittance.data.status !== 'started' &&
                deductions.data &&
                deductions.data.length > 0 && (
                  <div className="mb-1 mt-4">
                    <div className="d-flex justify-content-between">
                      <h5 className="flex-grow-1">Deductions</h5>
                      <Button
                        size="sm"
                        mode="flat"
                        onClick={downloadDeductionsCsv}
                      >
                        Download CSV
                      </Button>
                    </div>
                    <div>
                      <SimpleTable
                        data={deductions.data}
                        columns={columns}
                        renderSubComponent={row => (
                          <RemittanceTransactions
                            employeeId={row.original?.employee?.employee_id}
                            remittanceId={id}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
            </AsidePageMain>
            <AsidePageSidebar>
              <div className={styles.DetailsAside}>
                <CompletedRemittanceDetailsAside data={remittance.data} />
              </div>
            </AsidePageSidebar>
          </>
        )}
      </Loader>
    </PageBody>
  )
}
