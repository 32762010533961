// sorts these in the order we want.
export enum STATUS_KEYS {
  ENROLLMENT_REQUESTED = 'enrollment_requested',
  ENROLLED = 'enrolled',
  NOT_ENROLLED = 'not_enrolled',
}

export const STATUS_LABELS: { [key in STATUS_KEYS]: string } = {
  [STATUS_KEYS.ENROLLMENT_REQUESTED]: 'Enrollment Requested',
  [STATUS_KEYS.ENROLLED]: 'Enrolled',
  [STATUS_KEYS.NOT_ENROLLED]: 'Not Enrolled',
}

export enum WALLET_STATUS {
  NOT_CREATED = 'NOT_CREATED',
  PENDING = 'PENDING',
  UNCLAIMED = 'UNCLAIMED',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
  CREATED = 'CREATED',
  REVIEW = 'REVIEW',
}

export const WALLET_LABELS: { [key in WALLET_STATUS]: string } = {
  [WALLET_STATUS.NOT_CREATED]: 'Not Created',
  [WALLET_STATUS.PENDING]: 'Pending',
  [WALLET_STATUS.UNCLAIMED]: 'Unclaimed',
  [WALLET_STATUS.ACTIVE]: 'Active',
  [WALLET_STATUS.SUSPENDED]: 'Suspended',
  [WALLET_STATUS.CLOSED]: 'Closed',
  [WALLET_STATUS.FAILED]: 'Failed',
  [WALLET_STATUS.CREATED]: 'Created',
  [WALLET_STATUS.REVIEW]: 'Review',
}
