type Props = React.SVGAttributes<SVGSVGElement>

const SvgError = (props: Props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <defs>
      <path
        d="M11.99 2C17.52 2 22 6.48 22 12s-4.48 10-10.01 10C6.47 22 2 17.52 2 12S6.47 2 11.99 2zm-.04 4c-.667 0-1.2.537-1.2 1.2 0 .667.537 1.2 1.2 1.2.667 0 1.2-.537 1.2-1.2 0-.667-.537-1.2-1.2-1.2zm0 4.8a1.2 1.2 0 00-1.2 1.205v4.79a1.2 1.2 0 102.4 0v-4.79c0-.664-.537-1.205-1.2-1.205z"
        id="error_svg__a"
      />
    </defs>
    <g transform="translate(-2 -2)" fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2z"
        fill="#FFF"
      />
      <use
        fill="#F53D46"
        transform="matrix(1 0 0 -1 0 24)"
        xlinkHref="#error_svg__a"
      />
    </g>
  </svg>
)

export default SvgError
