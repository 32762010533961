import { styled } from '@/styles/stitches.config'

export const Badge = styled('span', {
  br: '$lg',
  fontWeight: 'bold',
  variants: {
    type: {
      info: {
        color: '$info700',
        backgroundColor: '$info900',
      },
      success: {
        color: '$success900',
        backgroundColor: '$success200',
      },
      warning: {
        color: '$warn900',
        backgroundColor: '$warn200',
      },
      danger: {
        color: '$danger900',
        backgroundColor: '$danger200',
      },
      secondary: {
        color: '$secondary100',
        backgroundColor: '$secondary700',
      },
    },
    size: {
      sm: {
        fontSize: 12,
        padding: '$6 $8',
      },
      md: {
        fontSize: 14,
        padding: '$8 $12',
      },
      lg: {
        fontSize: 16,
        padding: '$12 $16',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    type: 'info',
  },
})
