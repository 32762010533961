import { FC } from 'react'
import {
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { CashOutsFilters } from './CashOutsFilters'

interface Props {
  error?: Error
}

export const CashOutsContainer: FC<Props> = ({ error, children }) => (
  <>
    <PageTop>
      <PageHeader>
        <PageTitle primary="Cash Outs" />
      </PageHeader>
      <PageFilters>
        <CashOutsFilters />
      </PageFilters>
    </PageTop>
    <PageBody hasError={error}>{children}</PageBody>
  </>
)
