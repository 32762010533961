import { CrossCircledIcon, Pencil1Icon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useAssignUserRoles } from '@/queries/permissions'
import { useModalStore } from '@/store/modal'
import { OrgAdmin } from '@/types/permissions'
import { EditRolesModal } from './EditRolesModal'

interface Props {
  original: OrgAdmin
}

export const AdminActionCell: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()
  const assingRoles = useAssignUserRoles()

  const getRemoveModal = () => (
    <AlertDialog
      title="Are you sure?"
      description={`By removing ${original.first_name} ${original.last_name}, they will no longer have access to Pay Admin.`}
      actionDescription="Yes, Remove"
      isDestructive={true}
      handleAction={() =>
        assingRoles.mutateAsync({
          userId: +original.id,
          data: {
            role_ids: [],
            organization_group_ids: [],
          },
        })
      }
    />
  )

  const openRemoveModal = () => {
    registerModal(getRemoveModal())
  }

  const openEditModal = () => {
    registerModal(<EditRolesModal original={original} />)
  }

  return (
    <TableActionDropdown
      menu={[
        {
          label: 'Edit',
          handleClick: openEditModal,
          icon: <Pencil1Icon />,
        },
        {
          label: 'Remove',
          handleClick: openRemoveModal,
          icon: <CrossCircledIcon />,
        },
      ]}
    />
  )
}
