import { useMemo } from 'react'
import { SimpleTable } from '@/common/table/SimpleTable'
import { useLocationsQuery } from '@/queries/location'
import { LocationsContainer } from './components/LocationsContainer'
import { columns } from './lib/columns'

export const Locations = () => {
  const locations = useLocationsQuery()

  const locationData = useMemo(() => locations?.data || [], [locations])

  return (
    <LocationsContainer error={locations.error as Error}>
      <SimpleTable data={locationData} columns={columns} />
    </LocationsContainer>
  )
}
