import { Button } from '@/common/button'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
  PageViewContainer,
} from '@/components/layout/page'
import { useSharedPartnerRoster } from '@/hooks/usePartnerRoster'
import { useEnrollmentRequests } from '@/queries/employees'
import { ImportRosterContainer } from '@/src/components/roster/ImportRoster'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { EmployeeStats } from './EmployeeStats'

type Props = {
  sidebar: React.ReactNode | null
}

export const EmployeesContainer: React.FC<Props> = ({ sidebar, children }) => {
  const sharedPartnerRoster = useSharedPartnerRoster()
  const { isError } = useEnrollmentRequests()
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const handleAction = () => {
    registerModal(<ImportRosterContainer />)
  }

  return (
    <PageViewContainer sidebar={sidebar}>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Workers" />
          <PageActions>
            {isGlobalAdmin && (
              <Button mode="filled" onClick={handleAction} iconRight>
                Import
                <AdminIcon />
              </Button>
            )}
          </PageActions>
        </PageHeader>
        {!sharedPartnerRoster && <EmployeeStats />}
      </PageTop>

      <PageBody hasError={isError}>{children}</PageBody>
    </PageViewContainer>
  )
}
