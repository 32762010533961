import moment from 'moment'
import Papa from 'papaparse'
import { useState } from 'react'
import { getRemittances } from '@/api/remittances'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { showNaIfUndefined } from '@/components/create-accounts/lib/utils'
import { toDollar } from '@/lib/currency'
import { useToasterStore } from '@/store/toast'
import { IRemittance } from '@/types/remittances'

interface Props {
  filtered: TableFilters
}

export const useDownloadAllRemittances = ({ filtered }: Props) => {
  const { id: orgId } = useCurrentOrg()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasterStore()

  const download = async () => {
    setLoading(true)
    const pageSize = 1000
    try {
      const firstPageOfData = await getRemittances({
        org_id: orgId,
        page: 1,
        size: pageSize,
        ...filtered,
      }).then(data => data.data.data)
      const remittanceData = []
      remittanceData.push(firstPageOfData.content)

      if (firstPageOfData.total_pages > 1) {
        const pageNumbers = Array.from(
          { length: firstPageOfData.total_pages - 1 / 2 },
          (_, i) => i + 2
        )
        const restOfData = await Promise.all(
          pageNumbers.map(page =>
            getRemittances({
              org_id: orgId,
              page: page,
              size: pageSize,
              ...filtered,
            }).then(data => data.data.data.content)
          )
        ).then(data => data.map(remittance => remittance).flat())
        remittanceData.push(restOfData)
      }
      downloadRemittancesCSV(remittanceData.flat())
    } catch (e) {
      addToast({
        type: 'error',
        title: `Error downloading remittances`,
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      setLoading(false)
    }
  }

  return { download, loading }
}

export const downloadRemittancesCSV = (records: IRemittance[]) => {
  const csvData = records.map(record => {
    let row: { [k: string]: string | number | null } = {
      ID: record.id,
      'Payroll Pay Date': showNaIfUndefined(moment(record.pay_date).format()),
      'Requested Amount': toDollar(record.requested_amount),
      'Confirmed Amount': toDollar(record.actual_amount),
      'Invoice ID': showNaIfUndefined(record.payback_id?.toString()),
      Status: record.status,
    }
    return row
  })

  const csvContent = Papa.unparse(csvData)

  const blob = new Blob([csvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'remittances.csv')
}
