import { toDollar } from '@/lib/currency'

interface IProps {
  value: number | string | null
}

const AmountCell: React.FC<IProps> = ({ value }) => {
  return <>{typeof value === 'number' && toDollar(value)}</>
}

export default AmountCell
