import { useEffect, useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import {
  IconFirstTimeAdvances,
  IconSignups,
  IconSignupsAllTime,
} from '@/common/icons'
import { Loader } from '@/components/common/loader'
import { parseAdoptionTimeSeriesData } from '@/lib/adoptionInsights'
import { useGetRosterEntries } from '@/queries/employees'
import { useAdoptionInsightsQuery } from '@/queries/insights'
import { useAdoptionStore } from '@/store/adoption'
import { InsightCard } from '../components/InsightCard'
import StatDisplay from '../components/StatDisplay'
import { AdoptionContainer } from './components/AdoptionContainer'
import DailySignUps from './components/DailySignUps'
import TotalSignUpsToDate from './components/TotalSignUpsToDate'

export const Adoption = () => {
  const { filters, updateFilters } = useAdoptionStore()

  useUpdateQueryParamFilters(filters)
  const queryParams = useQueryParamFilters(filters)

  useEffect(() => {
    updateFilters(queryParams)
  }, [queryParams, updateFilters])

  const roster = useGetRosterEntries()
  const insights = useAdoptionInsightsQuery({
    startDate: filters.from,
    endDate: filters.to,
  })

  const rosterData = useMemo(() => roster.data || [], [roster])
  const report = useMemo(
    () => (insights.data ? parseAdoptionTimeSeriesData(insights.data) : []),
    [insights.data]
  )

  const signupsInRange = useMemo(() => {
    if (report.length < 1) return null
    const beginning = report[0].signupsToDate
    const end = report[report.length - 1].signupsToDate
    return end - beginning
  }, [report])

  const joinedPercentage = useMemo(() => {
    let count = 0
    rosterData.forEach(user => {
      if (user.linked) count += 1
    })
    return Math.round((count / rosterData.length) * 100).toFixed(0)
  }, [rosterData])

  const firstTimeAdvancesInRange = useMemo(() => {
    if (report.length < 1) return 0
    const beginning = report[0].firstTimeAdvancesToDate
    const end = report[report.length - 1].firstTimeAdvancesToDate
    return end - beginning
  }, [report])

  const firstTimeAdvancesAllTime = useMemo(
    () =>
      report.length < 1 ? 0 : report[report.length - 1].firstTimeAdvancesToDate,
    [report]
  )

  const signupsAllTime = useMemo(
    () => (report.length < 1 ? 0 : report[report.length - 1].signupsToDate),
    [report]
  )

  return (
    <AdoptionContainer>
      <Loader loading={insights.isLoading}>
        <Row className="mb-4">
          <Col md={4} className="nopadding">
            <StatDisplay
              stat={signupsInRange}
              ofTotal={`of ${signupsAllTime?.toString()} all time`}
              label="Signups"
              icon={<IconSignups />}
              bgColor="#5A2A3C"
            />
          </Col>
          <Col md={4} className="nopadding">
            <StatDisplay
              stat={firstTimeAdvancesInRange}
              ofTotal={`of ${firstTimeAdvancesAllTime?.toString()} all time`}
              label="First Time Advances"
              icon={<IconFirstTimeAdvances />}
              bgColor="#2A515A"
            />
          </Col>
          <Col md={4} className="nopadding">
            <StatDisplay
              stat={joinedPercentage + '%'}
              ofTotal={`of ${rosterData.length}`}
              label="Joined"
              icon={<IconSignupsAllTime />}
              bgColor="#5A3E2A"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InsightCard>
              <TotalSignUpsToDate data={report} />
            </InsightCard>
          </Col>
          <Col md={6}>
            <InsightCard>
              <DailySignUps data={report} />
            </InsightCard>
          </Col>
        </Row>
      </Loader>
    </AdoptionContainer>
  )
}
