import { Alert } from '@/common/alert'
import { Loader } from '@/components/common/loader'
import { FundingSource } from '@/components/funding-sources/components/FundingSource'
import { useFundingSources } from '@/queries/funding-sources'

export const FundingSources = () => {
  const { isLoading, data } = useFundingSources()
  return (
    <Loader loading={isLoading}>
      {!data || data.length === 0 ? (
        <Alert type="info">No funding sources yet.</Alert>
      ) : (
        data.map(item => <FundingSource fundingSource={item} key={item.id} />)
      )}
    </Loader>
  )
}
