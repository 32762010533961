import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { InstantCard } from '@/types/instant-cards'

const downloadCSV = (cards: InstantCard[]) => {
  sendAnalyticsEvent('instant_cards', 'download', { count: cards.length })
  const csvContent = Papa.unparse(cards)
  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'branch_instant_cards.csv')
}

export default downloadCSV
