import { DetailCopyableValue } from './DetailCopyableValue'

type Props = {
  label: string
  value: any
  renderValue?: (value: any) => React.ReactNode
  canCopy?: boolean
  hasNoData?: boolean
}

export const DetailProperty: React.FC<Props> = props => {
  const { label, value, renderValue, canCopy, hasNoData } = props

  return (
    <div className="detail-property">
      <p className="detail-property-heading">{label}</p>
      <div className="detail-property-value-container">
        {(typeof hasNoData === 'boolean' && hasNoData) || !value ? (
          <p className="detail-property-no-data">No data</p>
        ) : canCopy ? (
          <DetailCopyableValue>
            {renderValue ? renderValue(value) : value}
          </DetailCopyableValue>
        ) : renderValue ? (
          renderValue(value)
        ) : (
          value
        )}
      </div>
    </div>
  )
}
