import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import {
  useCreatePdqRegistrationQuery,
  useUpdatePdqRegistrationQuery,
} from '@/src/queries/pdq'

export type FormValues = {
  id: string
  secret: string
}

interface Props {
  create: boolean
}

const schema = yup.object().shape({
  id: yup.string().required('API ID is required'),
  secret: yup.string().required('API Secret is required'),
})

export const PdqRegistration: FC<Props> = ({ create }) => {
  const createPdqRegistration = useCreatePdqRegistrationQuery()
  const updatePdqRegistration = useUpdatePdqRegistrationQuery()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { id: '', secret: '' },
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: FormValues) => {
    const payload = {
      id: values.id,
      secret: values.secret,
    }
    create
      ? createPdqRegistration.mutate(payload)
      : updatePdqRegistration.mutate(payload)
  }

  const alertMessage = create ? (
    <>
      There's some more information we need before your <strong>PDQ</strong>{' '}
      integration is complete.
    </>
  ) : (
    <>
      We're having trouble connecting to your <strong>PDQ</strong> data. Please
      enter your credentials again below.
    </>
  )

  return (
    <Card>
      <Alert type={create ? 'info' : 'warning'}>{alertMessage}</Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        {createPdqRegistration.isError && (
          <Alert type="danger">
            {(createPdqRegistration.error as any).response.data.errorDetail}
          </Alert>
        )}
        <FlexGrid fillSpace css={{ mt: '$8' }}>
          <div>
            <Label htmlFor="id">API ID</Label>
            <Input type="text" {...register('id')} />
            <Text color="alert" size="sm">
              {errors.id?.message}
            </Text>
          </div>
        </FlexGrid>
        <FlexGrid fillSpace css={{ mt: '$8' }}>
          <div>
            <Label htmlFor="secret">API Secret</Label>
            <Input type="text" {...register('secret')} />
            <Text color="alert" size="sm">
              {errors.secret?.message}
            </Text>
          </div>
        </FlexGrid>
        <Flex justify="end" css={{ mt: '$24' }}>
          <Button
            mode="filled"
            type="submit"
            loading={
              createPdqRegistration.isLoading || updatePdqRegistration.isLoading
            }
            disabled={
              createPdqRegistration.isLoading || updatePdqRegistration.isLoading
            }
          >
            Save
          </Button>
        </Flex>
      </form>
    </Card>
  )
}
