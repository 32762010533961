import moment, { DurationInputArg2, Moment } from 'moment'
import { IPayrollPeriod } from '@/types/payroll'

export type PayRollDate = {
  deadline: Moment
  payDate: Moment
}

export const generatePayroll = (
  payPeriodType: string,
  deadline1: Moment,
  payDate1: Moment,
  deadline2?: Moment,
  payDate2?: Moment
) => {
  const dates = [
    {
      deadline: deadline1,
      payDate: payDate1,
    },
  ]
  if (deadline2 && payDate2) {
    dates.push({
      deadline: deadline2,
      payDate: payDate2,
    })
  }
  switch (payPeriodType) {
    case 'weekly':
      return generatePayRollDates(dates, 52, 'week', payPeriodType)
    case 'semi-monthly':
      if (deadline2 && payDate2) {
        return generatePayRollDates(dates, 12, 'month', payPeriodType)
      }
      return []
    case 'monthly':
      return generatePayRollDates(dates, 12, 'month', payPeriodType)
    default:
      return generatePayRollDates(dates, 26, 'week', payPeriodType)
  }
}

export const generatePayRollDates = (
  dates: PayRollDate[],
  maxAmount: number,
  duration: DurationInputArg2,
  payPeriodType: string
) => {
  const payRollDates = []
  for (let i = 0; i < maxAmount; i++) {
    const amount = payPeriodType === 'biweekly' ? i * 2 : i
    for (let date of dates) {
      payRollDates.push({
        id: Math.floor(Math.random() * 10000),
        deadline: addDate(date.deadline, amount, duration),
        payDate: addDate(date.payDate, amount, duration),
      })
    }
  }
  return payRollDates
}

export const addDate = (
  date: Moment,
  amount: number,
  duration: DurationInputArg2
) => {
  const futureDate = moment(date).add(amount, duration)
  while (date.day() !== futureDate.day()) {
    futureDate.add(1, 'd')
  }
  return futureDate
}

export const formatPayroll = (payroll?: IPayrollPeriod[]) =>
  (payroll || [])
    .map(period => ({
      payDate: moment(period.pay_date),
      deadline: moment(period.payroll_deadline),
      id: period.id,
    }))
    .slice()
    .sort((a, b) => +b.payDate - +a.payDate)
