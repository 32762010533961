import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { SingleDatePicker } from '@/common/datepicker'
import { DollarInput } from '@/common/dollar-field'
import { Input, TextArea } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex, FlexGrid } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { EmployeeSelect } from '@/components/employee-select/EmployeeSelect'
import { StoreSelect } from '@/components/store-id-select/StoreSelect'
import { useSetting } from '@/hooks/useSetting'
import { toDollar } from '@/lib/currency'
import styles from './index.module.scss'
import { MetaDataFieldArray } from './MetaDataFieldArray'

interface Props {
  onSubmit: (values: AddPayoutFormValues) => void
  payoutTypes: string[]
}

export type AddPayoutFormValues = {
  employeeId: string
  employeeName: string
  payoutType: string
  amount: number
  description: string
  externalStoreId: string
  time_scheduled: string | null
  externalId?: string
  metadata: { type: string; value: string }[]
}

export const AddPayoutForm: FC<Props> = ({ onSubmit, payoutTypes }) => {
  const [instant, setInstant] = useState(true)
  const singleDisbursementLimit = useSetting('SINGLE_DISBURSEMENT_LIMIT')
  const maxAmount = parseFloat(singleDisbursementLimit)

  const schema = yup.object().shape({
    employeeId: yup.string().required(),
    description: yup.string().max(128, '128 characters max'),
    amount: yup
      .number()
      .typeError('Must be a valid dollar amount')
      .max(maxAmount / 100, `Enter at most ${toDollar(maxAmount)}`)
      .min(0.01, 'Enter at least $0.01'),
    metadata: yup.array().of(
      yup.object({
        type: yup.string().required(),
        value: yup.string().required('A metadata value is required'),
      })
    ),
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<AddPayoutFormValues>({
    defaultValues: {
      externalId: '',
      employeeId: '',
      employeeName: '',
      payoutType: payoutTypes[0],
      amount: 0,
      description: '',
      time_scheduled: null,
      externalStoreId: '',
      metadata: [],
    },
    resolver: yupResolver(schema),
  })

  const metadataFields = useFieldArray({ name: 'metadata', control })

  const payoutType = watch('payoutType')
  const timeScheduled = watch('time_scheduled')
  const externalStoreId = watch('externalStoreId')

  const payoutTypeOptions = useMemo(
    () => payoutTypes.map(t => ({ value: t, label: t })),
    [payoutTypes]
  )

  useEffect(() => setValue('payoutType', payoutTypes[0]), [
    payoutTypes,
    setValue,
  ])

  const formatPayload = (values: AddPayoutFormValues) => {
    const payload = {
      ...values,
      amount: values.amount * 100,
    }
    onSubmit(payload)
  }

  return (
    <form onSubmit={handleSubmit(formatPayload)}>
      <p>
        Please let us know who should receive this disbursement, the amount, and
        what it's for.
      </p>

      <Box css={{ mb: '$16' }}>
        <Label htmlFor="employeeId">Worker</Label>
        <EmployeeSelect
          handleChange={({ id, firstName, lastName }) => {
            setValue('employeeId', id)
            setValue('employeeName', `${firstName} ${lastName}`)
          }}
        />
        <Text color="alert" size="sm">
          {errors.employeeId?.message}
        </Text>
      </Box>

      <FlexGrid size="lg" align="start" css={{ mb: '$16' }}>
        <Box css={{ width: '50%' }}>
          <Label htmlFor="payoutType">Type</Label>
          <Select
            value={payoutType}
            options={payoutTypeOptions}
            onChange={v => setValue('payoutType', v)}
          />
        </Box>
        <Box css={{ width: '50%' }}>
          <Label htmlFor="amount">Amount</Label>
          <DollarInput register={register} name="amount" />
          <Text color="alert" size="sm">
            {errors.amount?.message}
          </Text>
          <Text color="hint" size="sm">
            {toDollar(singleDisbursementLimit)} Limit
          </Text>
        </Box>
      </FlexGrid>

      <Box css={{ mb: '$16' }}>
        <Label htmlFor="description">Description</Label>
        <TextArea {...register('description')} />
        <Text color="alert" size="sm">
          {errors.description?.message}
        </Text>
      </Box>

      <Box css={{ mb: '$16' }}>
        <Label htmlFor="externalStoreId">Group ID</Label>
        <StoreSelect
          storeId={externalStoreId}
          setFieldValue={(val: string) => setValue('externalStoreId', val)}
        />
      </Box>

      <Box css={{ mb: '$16' }}>
        <Label htmlFor="externalId">Transaction ID (Optional)</Label>
        <Input {...register('externalId')} />
      </Box>

      <Box css={{ mb: '$8' }}>
        <Label>Disbursement Date</Label>
      </Box>

      <FlexGrid css={{ height: 48, mb: '$16' }}>
        <Box css={{ width: '45%' }}>
          <Button
            type="button"
            mode={instant ? 'filled' : 'outline'}
            onClick={() => {
              setValue('time_scheduled', null)
              setInstant(true)
            }}
            css={{
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
            }}
          >
            Instant
          </Button>
          <Button
            type="button"
            onClick={() => setInstant(false)}
            mode={instant ? 'outline' : 'filled'}
            css={{
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0',
            }}
          >
            Scheduled
          </Button>
        </Box>

        {!instant && (
          <Box css={{ width: '55%' }}>
            <div className={styles.DisbursementDatePicker}>
              <SingleDatePicker
                value={timeScheduled ? new Date(timeScheduled) : null}
                onChange={(date: Date) => {
                  const disbursement_date = date?.toISOString() || null
                  setValue('time_scheduled', disbursement_date)
                }}
                minDate={new Date()}
              />
            </div>
          </Box>
        )}
      </FlexGrid>

      <Box css={{ mb: '$16' }}>
        <Label>Optional Metadata</Label>
        <MetaDataFieldArray
          register={register}
          setValue={setValue}
          metadata={metadataFields}
        />
      </Box>

      <Flex justify="end">
        <Button mode="filled">Continue</Button>
      </Flex>
    </form>
  )
}
