import { FC } from 'react'
import { Badge } from '@/common/badge'
import { InstantCard } from '@/types/instant-cards'

interface Props {
  original: InstantCard
}

export const StatusCell: FC<Props> = ({ original: { status } }) => {
  const badgeColor = status === 'CLAIMED' ? 'success' : 'secondary'
  const label = status === 'CLAIMED' ? 'Claimed' : 'Unclaimed'

  return <Badge type={badgeColor}>{label}</Badge>
}
