import { useEffect, useState } from 'react'
import { toDollar } from '@/lib/currency'
import { Input, InputIcon } from '../input'
import { Flex } from '../layout'
import { AdditionalFilterItem } from './types'

type Props = {
  handleChange: (value: string) => void
  value: string
  item: AdditionalFilterItem
  hasInitialFocus: boolean
}

export const AmountFilter: React.FC<Props> = props => {
  const [invalid, setInvalid] = useState(false)
  const [amount, setAmount] = useState('')

  useEffect(() => {
    if (props.value && !isNaN(+props.value)) {
      const dollarified =
        toDollar(props.value, { includeSymbol: false, commafy: false }) || ''
      setAmount(dollarified)
    }
  }, []) // eslint-disable-line

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setInvalid(false)
    setAmount(val)

    if (val !== '') {
      // matches number with two sig figs
      const ex = /^\d+\.?[0-9]*$/
      if (!ex.test(val)) {
        setInvalid(true)
      } else {
        props.handleChange(`${+val * 100}`)
      }
    } else {
      props.handleChange('')
    }
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!invalid) {
      const val = e.target.value
      if (val !== '') {
        const fixed = parseFloat(val).toFixed(2)
        setAmount(fixed)
      } else {
        setAmount('')
      }
    }
  }

  return (
    <Flex css={{ width: '100%' }}>
      <InputIcon>$</InputIcon>
      <Input
        type="number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={amount}
        placeholder="15.00"
        step="0.01"
        // invalid={invalid}
        iconLeft
      />
    </Flex>
  )
}
