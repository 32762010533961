export type CurrentStep = 'VALIDATE' | 'CONFIRM' | 'REVIEW'

export type Step = {
  id: CurrentStep
  label: string
}

export const STEP_IDS: { [key in CurrentStep]: CurrentStep } = {
  VALIDATE: 'VALIDATE',
  CONFIRM: 'CONFIRM',
  REVIEW: 'REVIEW',
}

export const STEPS: Step[] = [
  {
    id: STEP_IDS.VALIDATE,
    label: 'Validate',
  },
  {
    id: STEP_IDS.REVIEW,
    label: 'Review',
  },
  { id: STEP_IDS.CONFIRM, label: 'Confirm' },
]
