import { FC, useCallback, useEffect, useState } from 'react'
import { Input } from '@/common/input'
import { useDeductionReport } from '@/store/deduction-report'
import { usePermissionsStore } from '@/store/permissions'
import { IRemittanceBalance } from '@/types/remittances'

interface Props {
  original: IRemittanceBalance
}

export const PaidCell: FC<Props> = ({ original }) => {
  const {
    adjustments,
    clearEmployeeAdjustment,
    setEmployeeAdjustment,
  } = useDeductionReport()

  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const [invalid, setInvalid] = useState(false)
  const [paid, setPaid] = useState('0.00')

  useEffect(() => {
    let bal = 0
    if (adjustments[original.employee_id]) {
      bal = adjustments[original.employee_id].amount / 100
    } else {
      bal = original.balance / 100
    }
    setPaid(bal.toFixed(2))
    // ignore updates to adjustments object because we
    // handle this case below in the handleChange func
    // eslint-disable-next-line
  }, [setPaid, original.employee_id])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value
      setInvalid(false)
      setPaid(val)

      // matches number with two sig figs
      const ex = /^\d+\.?[0-9]*$/
      if (!ex.test(val)) {
        setInvalid(true)
        return
      }
      const paidInCents = +parseFloat(val).toFixed(2) * 100
      const hasOverpaid = !isGlobalAdmin && paidInCents > original.balance

      if (paidInCents === original.balance || hasOverpaid) {
        clearEmployeeAdjustment(original.employee_id)
        if (hasOverpaid) {
          setInvalid(true)
        }
      } else {
        setEmployeeAdjustment({
          id: original.employee_id,
          amount: paidInCents,
        })
      }
    },
    [clearEmployeeAdjustment, setEmployeeAdjustment, original, isGlobalAdmin]
  )

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!invalid) {
        const val = e.target.value
        const fixed = parseFloat(val).toFixed(2)
        setPaid(fixed)
      }
    },
    [invalid, setPaid]
  )

  return (
    <Input
      type="text"
      value={paid}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
