import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { InvoiceConfigForm } from './InvoiceConfigForm'

interface Props {
  fundingSourceId?: number
}

export const InvoiceConfigModal: FC<Props> = ({ fundingSourceId }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog open={open} setOpen={close} title="Create Invoice Configuration">
      <InvoiceConfigForm onClose={close} fundingSourceId={fundingSourceId} />
    </Dialog>
  )
}
