import { FileValidationError } from '@/types/common'
import http from './http'

/**
 * Validate Disbursement CSV File
 */
export const validateDisbursements = (orgId: number, file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return http.post<FileValidationError[]>(
    `/wfm/organizations/${orgId}/validation/enhancedTimeScheduledDisbursementFileProcessor`,
    formData
  )
}
