import { FC, useState } from 'react'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Box, Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { AddPayoutFormValues } from '@/components/payouts/components/AddPayoutForm'
import { AddInvoiceFormValues } from './AddPaybacksForm'

interface Props {
  loading: boolean
  handleSubmit: () => void
  handleBack: () => void
  btnLabel: string
  values?: AddInvoiceFormValues | AddPayoutFormValues
}

export const ConfirmationModal: FC<Props> = ({
  loading,
  btnLabel,
  handleSubmit,
  handleBack,
  children,
}) => {
  const [confirmValue, setConfirmValue] = useState('')
  return (
    <div>
      <Text as="h5" css={{ mb: '$12' }}>
        Take a moment to review the details:
      </Text>
      {children}
      <Flex css={{ mt: '$12' }}>
        If everything looks correct, type &nbsp;
        <Text bold color="alert">
          CONFIRM
        </Text>
        &nbsp; into the field below.
      </Flex>
      <Box css={{ mb: '$24', mt: '$12', width: 200 }}>
        <Input
          type="text"
          onChange={e => {
            setConfirmValue(e.currentTarget.value.toUpperCase())
          }}
          value={confirmValue}
          placeholder="CONFIRM"
        />
      </Box>
      <Flex justify="between">
        <Button mode="outline" onClick={handleBack}>
          Edit
        </Button>
        <Button
          mode="filled"
          onClick={handleSubmit}
          loading={loading}
          disabled={confirmValue !== 'CONFIRM'}
        >
          {btnLabel}
        </Button>
      </Flex>
    </div>
  )
}
