import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  editPayback,
  getDisbursementsSummary,
  getPaybacks,
  getPaybackTypes,
  retryPayback,
} from '@/api/payouts'
import {
  addPayout,
  createBulkDisbursements,
  editPayout,
  getDisbursementTotal,
  getPayouts,
  getPayoutTypes,
} from '@/api/payouts'
import {
  EditPaybackConfig,
  GetPaybackParams,
  IGetPaybackRecordsResponse,
} from '@/api/types/paybacks'
import {
  GetDisbursementsTotalParams,
  GetPayoutsParams,
  IAddPayoutConfig,
  IEditPayoutConfig,
} from '@/api/types/payouts'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useDisbursementsStore } from '@/store/disbursements'
import { usePaybacksStore } from '@/store/paybacks'
import { useToasterStore } from '@/store/toast'
import { PayoutStatus } from '@/types/payouts'

export const PAYOUT_TYPES_QUERY = 'payout-types'

export const usePayoutTypesQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([PAYOUT_TYPES_QUERY, orgId], async () => {
    const res = await getPayoutTypes(orgId)
    return res.data
  })
}

type EditPayoutParams = {
  id: number
  config: IEditPayoutConfig
}
export const PAYOUTS_QUERY = 'payouts-query'

export const usePayoutsQuery = () => {
  const { id: orgId } = useCurrentOrg()
  const {
    filters,
    sorted,
    paginationParams,
    ready,
    pageSize,
  } = useDisbursementsStore()
  const { from, to, ...restFilters } = filters

  const baseParams: GetPayoutsParams = {
    ...restFilters,
    time_created_start: from?.format(),
    time_created_end: to?.format(),
    size: pageSize,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
  }

  const params = paginationParams || baseParams
  return useQuery(
    [PAYOUTS_QUERY, orgId, params],
    async () => {
      const res = await getPayouts(orgId, params)
      return res.data
    },
    {
      enabled: ready,
      cacheTime: 0,
    }
  )
}

export const useEditPayoutMutation = () => {
  const queryClient = useQueryClient()
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (params: EditPayoutParams) => editPayout(params.id, params.config, orgId),
    {
      onSuccess: (res, { id }) => {
        sendAnalyticsEvent('disbursements', 'edit:success', { id })
        if (res.data.status === PayoutStatus.FAILED) {
          addToast({
            type: 'error',
            title: 'Disbursement failed',
            description:
              'Make sure all account information is up-to-date and try again.',
          })
        }
        queryClient.invalidateQueries(PAYOUTS_QUERY)
      },
      onError: (_, { id }) =>
        sendAnalyticsEvent('disbursements', 'edit:failure', { id }),
    }
  )
}

export const useAddPayoutMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation((params: IAddPayoutConfig) => addPayout(orgId, params), {
    onSuccess: ({ data: { id, status, status_reason, reason_code } }) => {
      sendAnalyticsEvent('disbursements', 'create:success', {
        id,
        status,
      })

      switch (status) {
        case PayoutStatus.FAILED:
          addToast({
            type: 'error',
            title: 'Disbursement failed',
            description:
              'Make sure all account information is up-to-date and try again.',
          })
          break
        case PayoutStatus.SKIPPED:
          let toastDescription = status_reason
          if (reason_code === 'LIKELY_MATCH_FOUND') {
            toastDescription = 'Likely match found'
          } else if (reason_code === 'RETRY_PERIOD_ELAPSED') {
            toastDescription = 'Disbursement is too old to be retried'
          }
          addToast({
            type: 'error',
            title: 'Disbursement skipped',
            description: toastDescription,
          })
          break
        default:
          const action =
            status === PayoutStatus.SCHEDULED ? 'scheduled' : 'sent'
          addToast({
            type: 'success',
            title: `Disbursement successful`,
            description: `Disbursement has been successfully ${action}`,
          })
      }
      queryClient.invalidateQueries(PAYOUTS_QUERY)
    },
    onError: () => {
      sendAnalyticsEvent('disbursements', 'create:failure')
      addToast({
        type: 'error',
        title: 'Disbursement failed',
        description:
          'An error occurred. Make sure all account information is up-to-date and try again.',
      })
    },
  })
}

export const useBulkDisbursementsMutation = () => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(
    (params: { file: File; retry?: boolean }) =>
      createBulkDisbursements(orgId, params.file, params.retry),
    {
      onSuccess: () => {
        sendAnalyticsEvent('disbursements', 'bulk-upload:success')
        queryClient.invalidateQueries(PAYOUTS_QUERY)
      },
      onError: () => sendAnalyticsEvent('disbursements', 'bulk-upload:failure'),
    }
  )
}

export const useDisbursementSummary = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  return useMutation(
    async (params: GetDisbursementsTotalParams) => {
      const res = await getDisbursementTotal(orgId, params)
      return res.data
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('disbursements', 'summary:success')
      },
      onError: () => {
        sendAnalyticsEvent('disbursements', 'summary:failure')
        addToast({
          type: 'error',
          title: 'Disbursement summary failed',
          description: 'An error occurred fetching the disbursement summary.',
        })
      },
    }
  )
}

export const PAYOUT_LOCATION_SUMMARY_QUERY = 'payout-location-summary'

export const usePayoutLocationSummaryQuery = (paybackId: number) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [PAYOUT_LOCATION_SUMMARY_QUERY, paybackId, orgId],
    async () => {
      const res = await getDisbursementsSummary({
        orgId,
        paybackId,
        groupBy: 'external_store_id',
      })
      return res.data
    }
  )
}

export const PAYBACKS_QUERY = 'paybacks-query'

export const usePaybacksQuery = () => {
  const { id: orgId } = useCurrentOrg()

  const {
    page,
    pageSize,
    filters: { from, to, amount, ...restFilters },
    sorted,
  } = usePaybacksStore()
  const params: GetPaybackParams = {
    time_created_start: from?.format(),
    time_created_end: to?.format(),
    page: page + 1,
    size: pageSize,
    amount_max: amount,
    amount_min: amount,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
    ...restFilters,
  }

  return useQuery<IGetPaybackRecordsResponse, Error>(
    [PAYBACKS_QUERY, orgId, params],
    async () => {
      const res = await getPaybacks(orgId, params)
      return res.data
    }
  )
}

export const PAYBACK_TYPES = 'payback-types'

export const usePaybackTypes = () => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([PAYBACK_TYPES, orgId], async () => {
    const res = await getPaybackTypes(orgId)
    return res.data
  })
}

export const useRetryPaybackMutation = (paybackId: number) => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(
    async (params: { fundingSourceId?: number }) => {
      try {
        await retryPayback({
          ...params,
          id: paybackId,
          orgId,
        })
      } catch (err) {
        throw new Error('An error has occurred.')
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PAYBACKS_QUERY)
      },
    }
  )
}

export const useEditPaybackMutation = (paybackId: number) => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(
    async (params: EditPaybackConfig) => {
      try {
        await editPayback(orgId, paybackId, params)
      } catch (err) {
        throw new Error('An error has occurred.')
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PAYBACKS_QUERY)
      },
    }
  )
}
