import { saveAs } from 'file-saver'

const templateCsvContent =
  "Worker's ID,Punch Date,In,Out\n1535,06/12/2019,7:00AM,3:00PM\n5342,06/12/2019,3:00PM,11:00PM\n4320,06/13/2019,7:00AM,3:00PM\n9086,06/14/2019,7:00AM,3:00PM"

export const downloadTimekeepingTemplate = () => {
  const blob = new Blob([templateCsvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch-timekeeping-template.csv')
}
