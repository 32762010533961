import { FC } from 'react'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useSetting } from '@/hooks/useSetting'
import { useModalStore } from '@/store/modal'
import { PayoutRecord, PayoutStatus } from '@/types/payouts'
import { HidePayoutModal } from './HidePayoutModal'
import { RetryPayoutModal } from './RetryPayoutModal'

interface Props {
  original: PayoutRecord
}

const { FAILED, PAYBACK_FAILED, CREATED, SCHEDULED, CANCELLED } = PayoutStatus

export const PayoutActionDropdown: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()

  const manualDisbursementsEnabled =
    useSetting('ENABLE_MANUAL_DISBURSEMENTS') === 'true'

  if (
    ![FAILED, PAYBACK_FAILED, CREATED, SCHEDULED, CANCELLED].includes(
      original.status
    )
  ) {
    return null
  }

  const action = [FAILED, PAYBACK_FAILED].includes(original.status)
    ? 'Hide'
    : 'Cancel'

  const openRetryModal = () => {
    registerModal(<RetryPayoutModal payout={original} />)
  }

  const openHideModal = () => {
    registerModal(<HidePayoutModal payout={original} action={action} />)
  }

  return (
    <TableActionDropdown
      menu={[
        {
          label: 'Retry',
          handleClick: openRetryModal,
          hide: !manualDisbursementsEnabled || original.status === SCHEDULED,
        },
        {
          label: action,
          handleClick: openHideModal,
          hide: original.status === CANCELLED,
        },
      ]}
    />
  )
}
