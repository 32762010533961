import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useEditPdqStoreRegistration } from '@/queries/pdq'
import { useModalStore } from '@/store/modal'
import { PdqStore } from '@/types/settings'
import { PdqStoreRegistrationFormValues, StoreForm } from './StoreForm'

interface Props {
  store: PdqStore
}

export const EditStoreModal: FC<Props> = ({ store }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const editPdqStoreRegistration = useEditPdqStoreRegistration()
  const close = () => {
    setOpen(false)
    removeModal()
  }

  const initialValues = store.registration
    ? {
        rosterEffective: store.registration.roster_effective_date || '',
        timekeepingEffective:
          store.registration.timekeeping_effective_date || '',
        disbursementEffective:
          store.registration.disbursement_effective_date || '',
      }
    : undefined

  const handleSubmit = (values: PdqStoreRegistrationFormValues) => {
    editPdqStoreRegistration.mutate(
      { id: store.id, values },
      {
        onSuccess: close,
      }
    )
  }

  return (
    <Dialog open={open} setOpen={close} title="Edit Store Registration">
      <StoreForm
        isLoading={editPdqStoreRegistration.isLoading}
        handleFormSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </Dialog>
  )
}
