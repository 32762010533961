import { FC, MouseEvent, useMemo } from 'react'
import { Badge, BadgeSize, BadgeTypes } from '@/common/badge'
import { useModalStore } from '@/store/modal'
import { IOrganizationEmployee } from '@/types/employees'
import { STATUS_KEYS, STATUS_LABELS } from '../lib/employees.types'
import { RosterRequestModal } from './RosterRequestModal'

const { ENROLLED, ENROLLMENT_REQUESTED, NOT_ENROLLED } = STATUS_KEYS

interface Props {
  user: IOrganizationEmployee
  badgeSize?: BadgeSize
}

export const StatusBadge: FC<Props> = ({ user, badgeSize }) => {
  const { registerModal } = useModalStore()

  const status = useMemo(() => {
    if (!!user.user) return ENROLLED
    if (user.enrollmentRequests?.length) return ENROLLMENT_REQUESTED
    return NOT_ENROLLED
  }, [user])

  const openVerifyModal = (event: MouseEvent<any>) => {
    event.stopPropagation()
    registerModal(
      <RosterRequestModal
        user={user}
        matchingRequests={user.enrollmentRequests || []}
      />
    )
  }

  let badgeColor: BadgeTypes = 'info'
  switch (status) {
    case ENROLLED:
      badgeColor = 'success'
      break
    case ENROLLMENT_REQUESTED:
      badgeColor = 'warning'
      break
    default:
      badgeColor = 'info'
  }

  return (
    <Badge
      type={badgeColor}
      onClick={e =>
        status === ENROLLMENT_REQUESTED ? openVerifyModal(e) : null
      }
      size={badgeSize}
    >
      {status !== ENROLLMENT_REQUESTED ? STATUS_LABELS[status] : 'Requested'}
    </Badge>
  )
}
