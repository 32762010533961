import { FC } from 'react'
import { useSharedPartnerRoster } from '@/hooks/usePartnerRoster'
import {
  IEnrolledUser,
  INotEnrolledUser,
  IOrganizationEmployee,
} from '@/types/employees'
import { EnrolledActions } from './EnrolledActions'
import { NotEnrolledActions } from './NotEnrolledActions'

interface Props {
  original: IOrganizationEmployee
}

export const UserActionDropdown: FC<Props> = ({ original: user }) => {
  const readOnly = useSharedPartnerRoster()

  if (readOnly) return null

  if (!!user.user) {
    return <EnrolledActions user={user as IEnrolledUser} />
  } else {
    return <NotEnrolledActions user={user as INotEnrolledUser} />
  }
}
