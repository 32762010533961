import { AxiosResponse } from 'axios'
import { FC, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Spinner } from '@/common/loader'
import { Dialog } from '@/common/modal'
import { PAYOUTS_QUERY } from '@/src/queries/payouts'
import { useModalStore } from '@/store/modal'

interface Props {
  action: string
  task: Promise<AxiosResponse<any>>[]
}

export const MultiActionTaskModal: FC<Props> = ({ action, task }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const [taskOngoing, setTaskOngoing] = useState(false)
  const queryClient = useQueryClient()

  const close = useCallback(() => {
    setOpen(false)
    removeModal()
  }, [removeModal])

  useEffect(() => {
    const runTask = async () => {
      setTaskOngoing(true)
      await Promise.allSettled(task)
      queryClient.invalidateQueries(PAYOUTS_QUERY)
      setTaskOngoing(false)
      setTimeout(() => close(), 3000)
    }
    runTask()
  }, [close, queryClient, task])

  const title = (
    <>
      {' '}
      Task{' '}
      {taskOngoing ? (
        <span>
          Running <Spinner color="primary" />
        </span>
      ) : (
        `Finished`
      )}
    </>
  )

  const description = (
    <>
      {taskOngoing
        ? `Currently ${action} the selected disbursements. Please wait.`
        : `Task completed. Thank you for waiting.`}
    </>
  )

  return (
    <Dialog
      open={open}
      setOpen={close}
      title={title}
      description={description}
    />
  )
}
