import { ChevronDownIcon } from '@/common/icons'
import { SearchDropdownButton } from '@/components/search-dropdown-field'
import { IListItem } from './lib/types'
import { StoreListItem } from './StoreListItem'

interface Props {
  selectedItem?: IListItem
  handleClick: () => void
}

export const StoreSelectField: React.FC<Props> = ({
  selectedItem,
  handleClick,
}) => (
  <SearchDropdownButton onClick={handleClick}>
    <div className="w-100 d-flex align-items-center">
      <span className="mr-auto">
        {selectedItem ? (
          <StoreListItem entry={selectedItem} />
        ) : (
          <>Enter store name</>
        )}
      </span>

      <ChevronDownIcon width={12} />
    </div>
  </SearchDropdownButton>
)
