import { FC } from 'react'
import { TableContainer } from '@/common/table/styles'
import { Text } from '@/common/text'
import { toDollar } from '@/lib/currency'
import { AddInvoiceFormValues } from './AddPaybacksForm'
import { ConfirmationModal } from './ConfirmationModal'

interface Props {
  loading: boolean
  handleSubmit: () => void
  handleBack: () => void
  values: AddInvoiceFormValues
}

export const AddPaybackConfirmation: FC<Props> = ({
  loading,
  handleSubmit,
  handleBack,
  values,
}) => (
  <ConfirmationModal
    loading={loading}
    btnLabel="Add Invoice"
    handleSubmit={handleSubmit}
    handleBack={handleBack}
    values={values}
  >
    <TableContainer>
      <table>
        <tbody>
          <tr>
            <td>
              <Text bold>Amount</Text>
            </td>
            <td>{toDollar(values.amount * 100, { includeSymbol: true })}</td>
          </tr>
          <tr>
            <td>
              <Text bold>Funding Source ID</Text>
            </td>
            <td>{values.funding_source_id}</td>
          </tr>
          <tr>
            <td>
              <Text bold>Description</Text>
            </td>
            <td>{values.reason}</td>
          </tr>
          <tr>
            <td>
              <Text bold>PAYMENT TYPE</Text>
            </td>
            <td>{values.payment_type}</td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  </ConfirmationModal>
)
