import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createSSOconfig,
  createSSOmapping,
  deleteSSOmapping,
  disableSSO,
  enableSSO,
  getSSOconfig,
  getSSOmapping,
  getSSOmappings,
  updateSSOconfig,
} from '@/api/sso'
import { CreateSSOConfig, CreateSSOMapping, SSOConfig } from '@/api/types/sso'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

export const SSO_CONFIGURATION = 'sso-config-query'

export const useSSOConfig = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [SSO_CONFIGURATION, orgId],
    async () => {
      const res = await getSSOconfig(orgId)
      return res.data.data
    },
    {
      retry: 0,
    }
  )
}

export const SSO_MAPPINGS = 'sso-mappings-query'

export const useSSOMappings = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([SSO_MAPPINGS, orgId], async () => {
    const res = await getSSOmappings(orgId)
    return res.data.data
  })
}

export const SSO_MAPPING = 'sso-mapping-query'

export const useSSOMapping = (mappingId: number) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([SSO_MAPPING, orgId, mappingId], async () => {
    const res = await getSSOmapping(orgId, mappingId)
    return res.data
  })
}

export const useCreateSSOConfig = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (config: CreateSSOConfig) => {
      const res = await createSSOconfig(orgId, config)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SSO_CONFIGURATION, orgId])
        queryClient.invalidateQueries([SSO_MAPPINGS, orgId])
      },
    }
  )
}

export const useUpdateSSOConfig = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (config: SSOConfig) => {
      const res = await updateSSOconfig(orgId, config)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SSO_CONFIGURATION, orgId])
      },
    }
  )
}

export const useCreateSSOMapping = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (config: CreateSSOMapping) => {
      const res = await createSSOmapping(orgId, config)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SSO_CONFIGURATION, orgId])
        queryClient.invalidateQueries([SSO_MAPPINGS, orgId])
      },
    }
  )
}

export const useDeleteSSOMapping = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (mappingId: number) => {
      const res = await deleteSSOmapping(orgId, mappingId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SSO_CONFIGURATION, orgId])
        queryClient.invalidateQueries([SSO_MAPPINGS, orgId])
      },
    }
  )
}

export const useEnableSSO = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const res = await enableSSO(orgId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SSO_CONFIGURATION, orgId])
      },
    }
  )
}

export const useDisableSSO = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const res = await disableSSO(orgId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SSO_CONFIGURATION, orgId])
      },
    }
  )
}
