import { IOrganization } from '@/types/organization'

const ORGANIZATIONS_CACHE_KEY = 'orgs'
const USER_ROLES_CACHE_KEY = 'userRoles'
const USER_DETAILS_CACHE_KEY = 'userProfile'
const CURRENT_ORG_CACHE_KEY = 'currentOrg'
const NAV_COLOR_KEY = 'navColorKey'

export const getCachedCurrentOrganization = (): IOrganization | undefined => {
  const org = sessionStorage.getItem(CURRENT_ORG_CACHE_KEY)
  return org ? JSON.parse(org) : undefined
}

export const getCachedCurrentOrganizationKey = (): string | undefined => {
  const org = getCachedCurrentOrganization()
  return org ? org.org_key : undefined
}

export const cacheCurrentOrganization = (org: IOrganization): IOrganization => {
  sessionStorage.setItem(CURRENT_ORG_CACHE_KEY, JSON.stringify(org))
  return org
}

export const removeCachedCurrentOrganization = () => {
  sessionStorage.removeItem(CURRENT_ORG_CACHE_KEY)
}

export const removeCachedOrganizations = () => {
  sessionStorage.removeItem(ORGANIZATIONS_CACHE_KEY)
}

export const removeCachedUserRoles = () => {
  sessionStorage.removeItem(USER_ROLES_CACHE_KEY)
}

export const removeCachedUserDetails = () => {
  sessionStorage.removeItem(USER_DETAILS_CACHE_KEY)
}

export const cacheNavColor = (val: string): string => {
  sessionStorage.setItem(NAV_COLOR_KEY, val)
  return val
}

export const getCachedNavColor = (): string | undefined => {
  return sessionStorage.getItem(NAV_COLOR_KEY) || undefined
}

export const removeCachedNavColor = () => {
  sessionStorage.removeItem(NAV_COLOR_KEY)
}
