import { FC } from 'react'
import { AlertDialog } from '@/common/modal'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useDeleteRegistrationMutation } from '@/src/queries/file-processing'
import { useModalStore } from '@/store/modal'
import { IRegistrationRow } from '../lib/types'
import { AddProcessorModal } from './AddProcessorModal'

interface Props {
  original: IRegistrationRow
}

export const RegistrationDropdown: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()
  const deleteMutation = useDeleteRegistrationMutation()

  const description = (
    <>
      Are you sure you want to delete this{' '}
      <strong>{original.type} registration</strong>?
    </>
  )

  const openRemoveModal = () => {
    registerModal(
      <AlertDialog
        title="Delete registration"
        actionDescription="Delete"
        isDestructive={true}
        handleAction={() =>
          deleteMutation.mutateAsync({
            id: original.id,
            registrationType: original.type,
          })
        }
        description={description}
      />
    )
  }

  const openAddProcessorModal = () => {
    registerModal(<AddProcessorModal registration={original} />)
  }

  return (
    <TableActionDropdown
      menu={[
        { label: 'Add Processor', handleClick: openAddProcessorModal },
        { label: 'Delete', handleClick: openRemoveModal },
      ]}
    />
  )
}
