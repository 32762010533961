import { useMemo } from 'react'
import { INSTANT_TRANSFER_FEE_TYPE } from '@/types/common'
import { useSetting } from './useSetting'

export const useFeeTypes = () => {
  const instantTransferFeeType = useSetting(
    'INSTANT_TRANSFER_FEE_TYPE'
  ) as INSTANT_TRANSFER_FEE_TYPE
  const isPercentageFeeType = useMemo(
    () => instantTransferFeeType === INSTANT_TRANSFER_FEE_TYPE.PERCENTAGE,
    [instantTransferFeeType]
  )

  return {
    instantTransferFeeType,
    isPercentageFeeType,
  }
}
