import { CaretLeftIcon, CaretRightIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { IconButton } from '../button'
import { Box } from '../layout'
import { TablePaginationContainer } from './styles'

interface Props {
  previousPageDisabled: boolean
  previousPage: () => void
  nextPageDisabled: boolean
  nextPage: () => void
  pageNumber: number
}

export const TablePagination: FC<Props> = ({
  previousPageDisabled,
  previousPage,
  nextPageDisabled,
  nextPage,
  pageNumber,
}) => (
  <TablePaginationContainer>
    <Box css={{ mr: '$8' }}>
      <IconButton disabled={previousPageDisabled} onClick={previousPage}>
        <CaretLeftIcon
          style={{
            transform: 'scale(1.75)',
          }}
        />
      </IconButton>
    </Box>
    {pageNumber}
    <Box css={{ ml: '$8' }}>
      <IconButton disabled={nextPageDisabled} onClick={nextPage}>
        <CaretRightIcon
          style={{
            transform: 'scale(1.75)',
          }}
        />
      </IconButton>
    </Box>
  </TablePaginationContainer>
)
