import { SortingState } from '@tanstack/react-table'
import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { Button } from '@/common/button'
import { TableFilters, UpdateFilters } from '@/common/filters/types'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { ImportTAContainer } from '@/src/components/time-and-attendance/ImportTA'
import { TIMEKEEPING_QUERY } from '@/src/queries/time-attendance'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { TimekeepingFilters } from './TimekeepingFilters'

interface Props {
  filters: TableFilters
  updateFilters: UpdateFilters
  sorted: SortingState
}

const TimekeepingRecordsContainer: FC<Props> = ({
  filters,
  updateFilters,
  sorted,
  children,
}) => {
  const { registerModal } = useModalStore()

  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const queryClient = useQueryClient()

  const handleImportClose = () => {
    queryClient.invalidateQueries(TIMEKEEPING_QUERY)
  }

  const openImportTimekeepingModal = () => {
    registerModal(<ImportTAContainer handleClose={handleImportClose} />)
  }

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Timekeeping" />

          <PageActions>
            {isGlobalAdmin && (
              <Button
                mode="filled"
                onClick={openImportTimekeepingModal}
                iconRight
              >
                Import Timekeeping
                <AdminIcon />
              </Button>
            )}
          </PageActions>
        </PageHeader>
        <PageFilters>
          <TimekeepingFilters />
        </PageFilters>
      </PageTop>
      <PageBody>{children}</PageBody>
    </>
  )
}

export default TimekeepingRecordsContainer
