import { FC, ReactNode } from 'react'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
  PageViewContainer,
} from '@/components/layout/page'
import { useSetting } from '@/hooks/useSetting'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { AddPayoutModal } from './AddPayoutModal'
import { BulkDisbursementsUploadModal } from './BulkDisbursementsUploadModal'
import { FundingSourceAlert } from './FundingSourceAlert'
import { PayoutFilters } from './PayoutFilters'
import { ViewSummaryModal } from './ViewSummaryModal'

interface Props {
  sidebar: ReactNode
}

export const PayoutsContainer: FC<Props> = ({ children, sidebar }) => {
  const { registerModal } = useModalStore()
  const { hasPermission } = usePermissionsStore(state => state.computed)

  const openAddPayoutModal = () => {
    registerModal(<AddPayoutModal />)
  }

  const openBulkPayoutModal = () => {
    registerModal(<BulkDisbursementsUploadModal />)
  }

  const openViewSummaryModal = () => {
    registerModal(<ViewSummaryModal />)
  }

  const manualDisbursementsEnabled =
    useSetting('ENABLE_MANUAL_DISBURSEMENTS') === 'true'

  const createMenu = [
    {
      label: 'Bulk Upload',
      handleClick: openBulkPayoutModal,
    },
    {
      label: 'Create Single',
      handleClick: openAddPayoutModal,
    },
  ]

  return (
    <PageViewContainer sidebar={sidebar}>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Disbursements" />
          <PageActions>
            <Button
              mode="outline"
              color="black"
              css={{ mr: '$8' }}
              onClick={openViewSummaryModal}
            >
              View Summary
            </Button>

            {manualDisbursementsEnabled &&
              hasPermission('disbursement.create') && (
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <Button mode="filled">Create</Button>
                  </DropdownMenuTrigger>
                  <DropdownContent>
                    {createMenu.map(option => (
                      <DropdownMenuItem
                        onClick={option.handleClick}
                        key={option.label}
                      >
                        {option.label}
                      </DropdownMenuItem>
                    ))}
                  </DropdownContent>
                </DropdownMenu>
              )}
          </PageActions>
        </PageHeader>
        <FundingSourceAlert />
        <PageFilters>
          <PayoutFilters />
        </PageFilters>
      </PageTop>
      <PageBody>{children}</PageBody>
    </PageViewContainer>
  )
}
