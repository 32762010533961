import moment, { Moment } from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { getQueryParamObjectFromString } from '@/lib/queryParams'
import { prefixFilterKey } from '../filters/lib/utils'
import { DateRangePreset } from './types'
import { getPresetDateRange } from './utils'

type DateRangeParams = {
  from?: Moment
  to?: Moment
  preset?: DateRangePreset
}
export const useDateRangeSearchParams = (
  defaultPreset?: DateRangePreset
): DateRangeParams | undefined => {
  const location = useLocation()

  return useMemo(() => {
    let filters: DateRangeParams = getPresetDateRange(
      defaultPreset || DateRangePreset.LAST_MONTH
    )

    if (!location.search) return filters

    const searchParams: any = getQueryParamObjectFromString(location.search)

    if (!searchParams) return filters

    const from = searchParams[prefixFilterKey('from')]
    const to = searchParams[prefixFilterKey('to')]
    const preset = searchParams[prefixFilterKey('preset')]

    const dateRegex = /\d{4}-\d{2}-\d{2}/g

    if (preset) {
      const presetRange = getPresetDateRange(preset)
      filters.from = presetRange.from
      filters.to = presetRange.to
      filters.preset = preset
    }

    if (from && from.match(dateRegex) && to && to.match(dateRegex)) {
      filters.from = moment(from).startOf('day')
      filters.to = moment(to).endOf('day')
      filters.preset = undefined
    }

    return filters
  }, [location, defaultPreset])
}
