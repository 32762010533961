import mixpanel from 'mixpanel-browser'
import TagManager from 'react-gtm-module'

if (process.env.NODE_ENV === 'production') {
  mixpanel.init('b5bf4d92126228fc3ab89dc882264959')

  TagManager.initialize({
    gtmId: 'GTM-5KCQ3KF',
  })
}
