import { FC } from 'react'
import { PayoutRecord } from '@/types/payouts'
import { ViewPaybackLink } from './ViewPaybackLink'

interface Props {
  original: PayoutRecord
}
export const ViewPaybackCell: FC<Props> = ({ original }) => (
  <ViewPaybackLink payout={original} />
)
