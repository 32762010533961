import { GroupsResponse, TOrgUpdates } from '@/api/types/organization'
import http from './http'

/**
 * Allows Customer Success to create a new organization
 * @param name The organization name
 * @param key The organization key (one word, kebab_case)
 */
export const createOrganization = async (name: string, key: string) => {
  return http.post('p1/support/organizations', {
    org_name: name,
    org_key: key,
  })
}

/**
 * Takes an object of whitelisted organization updates and sets them
 * @param settings whitelisted organization updates
 */
export const updateOrganization = (settings: TOrgUpdates, orgId: number) =>
  http.patch(`p1/organizations/${orgId}/info`, settings)

/**
 * Returns store ids associated with an organization
 * @param orgId current organization id
 */
export const getGroups = (orgId: number) =>
  http.get<GroupsResponse>(`p1/organizations/${orgId}/groups`)
