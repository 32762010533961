import { getDirectDepositForm } from '@/api/direct-deposits'
import { IDirectDepositForm } from '@/types/employees'

export const downloadDDForm = async (
  directDeposit: IDirectDepositForm,
  employeeId: string,
  orgId: number
) => {
  try {
    const res = await getDirectDepositForm(directDeposit.id, orgId)
    const fileBlob = new Blob([res.data], { type: 'application/pdf' })
    const data = window.URL.createObjectURL(fileBlob)
    const downloadPdf = document.createElement('a')
    downloadPdf.href = data
    const filename = `dd_${employeeId ? `${employeeId}_` : ''}${
      directDeposit.id
    }.pdf`
    downloadPdf.download = filename
    downloadPdf.click()
  } catch (error) {
    throw error
  }
}
