import { FC } from 'react'
import { Tooltip, TooltipContentProps } from '@/common/tooltip'
import { Switch } from './Switch'

interface Props {
  enabled: boolean
  toggle: (checked: boolean) => void
  tooltip?: string
  disabled?: boolean
  side?: TooltipContentProps['side']
}

export const ToggleSwitch: FC<Props> = ({
  enabled,
  toggle,
  tooltip,
  disabled,
  side = 'top',
}) => {
  if (!!tooltip) {
    return (
      <Tooltip
        side={side}
        trigger={
          <Switch
            onCheckedChange={toggle}
            checked={enabled}
            disabled={disabled}
          />
        }
      >
        {tooltip}
      </Tooltip>
    )
  }

  return (
    <Switch onCheckedChange={toggle} checked={enabled} disabled={disabled} />
  )
}
