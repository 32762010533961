import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import {
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupItem,
  RadioGroupItemContainer,
} from '@/common/radio'
import { Text } from '@/common/text'
import { useUpdateOrgLocationSettings } from '@/queries/settings'
import { useToasterStore } from '@/store/toast'
import { PASSCODE_REQUIREMENT, passcodeRequirementMap } from '@/types/common'

type GeneralSetupFormValues = {
  PASSCODE_REQUIREMENT?: PASSCODE_REQUIREMENT
}

interface Props {
  initialValue?: PASSCODE_REQUIREMENT
}

const resetForm = (data?: PASSCODE_REQUIREMENT) => ({
  PASSCODE_REQUIREMENT: data,
})

const optionLabels = {
  [PASSCODE_REQUIREMENT.OFF]: 'No passcode required',
  [PASSCODE_REQUIREMENT.LOCATION]: 'Passcode required for all users',
  [PASSCODE_REQUIREMENT.ORG]: 'Passcode required for unverified users only',
}

export const PasscodeRequirement: FC<Props> = ({ initialValue }) => {
  const updateSettings = useUpdateOrgLocationSettings()
  const { addToast } = useToasterStore()
  const {
    formState: { isDirty },
    handleSubmit,
    setValue,
    watch,
    register,
    reset,
  } = useForm({
    defaultValues: resetForm(initialValue),
  })

  const onSubmitForm = async (value: GeneralSetupFormValues) => {
    if (!value.PASSCODE_REQUIREMENT) return
    updateSettings.mutate(
      {
        passcode_requirement:
          passcodeRequirementMap[value.PASSCODE_REQUIREMENT],
      },
      {
        onSuccess: () => {
          addToast({
            type: 'success',
            title: `Updated organization's passcode_requirement to "${
              optionLabels[value.PASSCODE_REQUIREMENT!]
            }".`,
          })
        },
        onError: () => {
          addToast({
            type: 'error',
            title:
              'Please try again soon, and if the issue persists, contact support.',
          })
        },
      }
    )
  }

  useEffect(() => {
    reset(resetForm(initialValue))
  }, [initialValue, reset])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Label>Passcode Requirement:</Label>
      <RadioGroup
        defaultValue={watch('PASSCODE_REQUIREMENT')}
        {...register('PASSCODE_REQUIREMENT', {
          onChange: value =>
            setValue('PASSCODE_REQUIREMENT', value.target.value),
        })}
      >
        {(Object.keys(PASSCODE_REQUIREMENT) as Array<
          keyof typeof PASSCODE_REQUIREMENT
        >).map(val => (
          <RadioGroupItemContainer id={val} key={val}>
            <RadioGroupItem value={PASSCODE_REQUIREMENT[val]} color="blue">
              <RadioGroupIndicator color="primary" />
            </RadioGroupItem>
            <Text>{optionLabels[PASSCODE_REQUIREMENT[val]]}</Text>
          </RadioGroupItemContainer>
        ))}
      </RadioGroup>
      <Flex>
        <Button
          type="submit"
          mode="filled"
          disabled={updateSettings.isLoading || !isDirty}
          size="md"
          css={{ ml: 'auto' }}
        >
          Save
        </Button>
      </Flex>
    </form>
  )
}
