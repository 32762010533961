type Props = React.SVGAttributes<SVGSVGElement>

const LetterIcon: React.FC<Props> = props => (
  <svg width={24} height={16} viewBox="0 0 24 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0-4h24v24H0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M22 0H2a2 2 0 00-2 2v12a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm-1 3.5v4a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zM4.5 9.745h8a.755.755 0 110 1.51h-8a.755.755 0 110-1.51zM3.745 7c0-.417.338-.755.755-.755h2a.755.755 0 110 1.51h-2A.755.755 0 013.745 7z"
      />
    </g>
  </svg>
)

export default LetterIcon
