import { useParams } from 'react-router'
import { Loader } from '@/common/loader'
import {
  AsidePageMain,
  AsidePageSidebar,
  PageBack,
  PageBody,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import {
  useRemittance,
  useRemittanceBalances,
  useRemittanceDeductions,
} from '@/queries/remittances'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { AdjustProgressCard } from './components/AdjustProgressCard'
import { RemittanceDetailsAside } from './components/DeductionReportDetailsAside'
import { DownloadProgressCard } from './components/DownloadProgressCard'
import { PayProgressCard } from './components/PayProgressCard'
import { ProcessProgressCard } from './components/ProcessProgressCard'
import styles from './index.module.scss'

export const CompleteRemittance: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const remittance = useRemittance(id)
  const balances = useRemittanceBalances(id)
  const deductions = useRemittanceDeductions(id)

  return (
    <>
      <AsidePageMain>
        <PageTop>
          <PageBack
            to={useOrgPath(ROUTES.REMITTANCES)}
            text={'Back to Remittances'}
          />
          <PageTitle primary="Complete remittance" />
        </PageTop>
        <PageBody
          hasError={
            remittance.isError || balances.isError || deductions.isError
          }
        >
          <Loader
            loading={
              remittance.isLoading || balances.isLoading || deductions.isLoading
            }
          >
            <DownloadProgressCard remittanceId={id} />
            <ProcessProgressCard />
            <AdjustProgressCard remittanceId={id} />
            <PayProgressCard remittanceId={id} />
          </Loader>
        </PageBody>
      </AsidePageMain>
      <AsidePageSidebar>
        <div className={styles.DetailsAside}>
          <RemittanceDetailsAside
            remittanceId={id}
            remittanceBalance={balances.data}
          />
        </div>
      </AsidePageSidebar>
    </>
  )
}
