export const getFileText = (files: File[]): string => {
  let title = ''
  if (files.length === 1) {
    title = files[0].name
  }
  if (files.length > 1) {
    title = `${files.length} files`
  }
  return title
}

export const checkThatAllFilesAreSupported = (
  files: File[],
  supportedFileTypes: string[]
): boolean => {
  // checks if every file is supported in the supportedFileTypes list
  return files.every(file => {
    let fileIsSupported = false
    supportedFileTypes.forEach(type => {
      if (file.name.endsWith(`.${type}`)) {
        fileIsSupported = true
      }
    })
    return fileIsSupported
  })
}
