import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Flex } from '@/common/layout'
import { TableExpander } from '@/common/table'
import { CodeCell } from '../components/CodeCell'
import { RegistrationDropdown } from '../components/RegistrationDropdown'
import { IRegistrationRow } from './types'

export const columns: ColumnDef<IRegistrationRow, any>[] = [
  {
    header: 'ID',
    id: 'id',
    accessorFn: row => row.id,
  },
  {
    header: 'Type',
    id: 'type',
    accessorFn: row => (row.email_key ? 'Email' : 'SFTP'),
  },
  {
    header: 'Target',
    id: 'target',
    accessorFn: row => row.email_key || row.dir_name,
    cell: row => <CodeCell value={row.getValue()} />,
  },
  {
    header: 'Created',
    id: 'created',
    accessorFn: row => moment(row.time_created),
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
  },
  {
    header: 'Processors',
    id: 'processors',
    size: 100,
    accessorFn: row => row.processor_registrations.length,
  },
  {
    header: '',
    accessorKey: 'actions',
    id: 'actions',
    size: 40,
    enableSorting: false,
    cell: ({ row }) => (
      <Flex justify="center">
        <RegistrationDropdown original={row.original} />
      </Flex>
    ),
  },
  {
    id: 'expand',
    size: 40,
    cell: ({ row }) => (
      <Flex justify="center">
        <div
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer' },
          }}
        >
          <TableExpander isExpanded={row.getIsExpanded()} />
        </div>
      </Flex>
    ),
  },
]
