import { DownloadIcon } from '@radix-ui/react-icons'
import { FC, useState } from 'react'
import { Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button } from '@/common/button'
import { InputDropzone } from '@/components/file-upload/InputDropzone'
import { useModalStore } from '@/store/modal'
import { downloadBulkDisbursementsTemplate } from '../lib/downloadBulkDisbursementsTemplate'
import { BulkDisbursementsGuide } from './BulkDisbursementsGuide'
import { BulkDisbursementsUploadSteps } from './BulkDisbursementsUploadSteps'

const COLUMN_HEADERS = [
  'ID',
  'First Name',
  'Last Name',
  'Type',
  'Amount',
  'Transaction ID',
  'Location',
  'Disbursement Date',
  'Description',
]

export const BulkDisbursementsUploadModal: FC = () => {
  const { registerModal, removeModal } = useModalStore()
  const [retry, setRetry] = useState(false)
  const [open, setOpen] = useState(true)
  const [files, setFiles] = useState<File[]>([])

  const handleDrop = async (files: File[]) => setFiles(files)

  const handleReview = async () => {
    close()
    registerModal(
      <BulkDisbursementsUploadSteps
        columnHeaders={COLUMN_HEADERS}
        files={files}
        retry={retry}
      />
    )
  }

  const handleDownloadTemplate = () => {
    downloadBulkDisbursementsTemplate()
  }

  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Modal isOpen={open} toggle={close} centered backdrop="static">
      <ModalHeader toggle={close} tag="h2">
        Bulk Upload
      </ModalHeader>
      <ModalBody>
        <p>
          For each disbursement, we'll need the worker ID, first name, last
          name, type, amount, transaction ID, and description.
        </p>
        <BulkDisbursementsGuide />
        <Button
          iconRight
          mode="flat"
          color="primary"
          css={{ padding: 0 }}
          onClick={handleDownloadTemplate}
        >
          <DownloadIcon />
          Download Template
        </Button>
        <InputDropzone
          handleDrop={handleDrop}
          files={files}
          allowMultiple={false}
          label="Upload file (Drag n Drop or Select)"
          hasError={false}
          error=""
          className="mt-4"
          supportedFileTypes={['csv']}
          subtext="Accepts CSV"
        />

        <Label check>
          <Input
            type="checkbox"
            checked={retry}
            onChange={() => setRetry(!retry)}
            className="position-relative m-0"
          />{' '}
          Retry failed disbursements
        </Label>

        <div className="d-flex justify-content-between align-items-center">
          <Button
            mode="filled"
            css={{ ml: 'auto' }}
            disabled={files.length < 1}
            onClick={handleReview}
          >
            Review
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
