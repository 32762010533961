import { FC } from 'react'
import DatePicker, {
  DatePickerProps,
} from 'react-date-picker/dist/entry.nostyle'
import { DateInputContainer } from './styles'

export const SingleDatePicker: FC<DatePickerProps> = ({
  ...props
}: DatePickerProps) => (
  <DateInputContainer>
    <DatePicker
      {...props}
      dayPlaceholder="DD"
      yearPlaceholder="YYYY"
      monthPlaceholder="MM"
      clearIcon={null}
      calendarType="US"
    />
  </DateInputContainer>
)
