import { useMemo } from 'react'
import { useRolesQuery, useUserPermissionsQuery } from '@/queries/permissions'
import { usePermissionsStore } from '@/store/permissions'

export const useUserRolesInitializer = () => {
  const { isEnabledOrg } = usePermissionsStore(state => state.computed)
  const rolesQuery = useRolesQuery()
  const permissionsQuery = useUserPermissionsQuery()

  const isInitialized = useMemo(
    () =>
      isEnabledOrg
        ? !!rolesQuery.data && !!permissionsQuery.data
        : !!rolesQuery.data,
    [isEnabledOrg, permissionsQuery, rolesQuery]
  )

  const error = useMemo(
    () =>
      isEnabledOrg
        ? rolesQuery.error || permissionsQuery.error
        : rolesQuery.error,
    [isEnabledOrg, permissionsQuery, rolesQuery]
  )

  return {
    isInitialized,
    error,
  }
}
