import { useEffect, useMemo } from 'react'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { AdminIcon } from '@/common/icons'
import { Table } from '@/common/table'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
  PageViewContainer,
} from '@/components/layout/page'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useFundingSources } from '@/queries/funding-sources'
import { useSidebar } from '@/src/hooks/useSidebar'
import { usePaybacksQuery } from '@/src/queries/payouts'
import { useModalStore } from '@/store/modal'
import { usePaybacksStore } from '@/store/paybacks'
import { usePermissionsStore } from '@/store/permissions'
import { PaybackRecord } from '@/types/paybacks'
import { AddPaybacksModal } from './components/AddPaybacksModal'
import { PaybackDetailsView } from './components/PaybackDetailsView'
import { PaybackFilters } from './components/PaybackFilters'
import { columns } from './lib/columns'

const initialSortedState = [
  {
    id: 'invoice_date',
    desc: true,
  },
]

export const Paybacks = () => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const {
    page,
    pageSize,
    filters,
    sorted,
    updatePage,
    updatePageSize,
    setSorted,
    isReady,
    updateFilters,
    ready,
  } = usePaybacksStore()
  const { data: fundingSources } = useFundingSources()
  const { sidebar, addSidebar, removeSidebar } = useSidebar()

  useEffect(() => {
    if (ready) return
    setSorted(initialSortedState)
    isReady()
  }, [isReady, setSorted, ready])

  // Update query params when filters change
  useUpdateQueryParamFilters(filters)

  // Retrieve query params and update filters on initial load
  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
    isReady()
  }, [queryParams, updateFilters, isReady])

  const { data, isLoading, isError } = usePaybacksQuery()

  const paybacks = useMemo(() => data?.content || [], [data])

  const hasFundingSource = useMemo(() => !!(fundingSources || []).length, [
    fundingSources,
  ])

  const hiddenColumns = useMemo(
    () => ({ funding_source_id: hasFundingSource }),
    [hasFundingSource]
  )

  const openSidebar = (record: PaybackRecord, id: number) => {
    addSidebar(
      <PaybackDetailsView payback={record} remove={removeSidebar} />,
      id
    )
  }

  const openAddPayoutModal = () => {
    registerModal(<AddPaybacksModal />)
  }

  const createMenu = [
    {
      label: 'Create Single',
      handleClick: openAddPayoutModal,
    },
  ]

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    data?.total_pages
  )

  return (
    <PageViewContainer sidebar={sidebar}>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Invoices" />
          <PageActions>
            {isGlobalAdmin && (
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <Button mode="filled" iconRight hideIcon={!isGlobalAdmin}>
                    Create
                    <AdminIcon />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownContent>
                  {createMenu.map(option => (
                    <DropdownMenuItem
                      onClick={option.handleClick}
                      key={option.label}
                    >
                      {option.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownContent>
              </DropdownMenu>
            )}
          </PageActions>
        </PageHeader>
        <PageFilters>
          <PaybackFilters
            filters={filters}
            sorted={sorted}
            updateFilters={updateFilters}
          />
        </PageFilters>
      </PageTop>
      <PageBody hasError={isError}>
        <Table
          columns={columns}
          hiddenColumns={hiddenColumns}
          data={paybacks}
          controlledPagination={true}
          sorted={sorted}
          onSortedChange={setSorted}
          initialSortedState={initialSortedState}
          isLoading={isLoading}
          page={page}
          pageRows={pageSize}
          updatePageSize={updatePageSize}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          changePage={updatePage}
          rowOnClickFn={openSidebar}
          hideLastColumnBorder
        />
      </PageBody>
    </PageViewContainer>
  )
}
