import { FC } from 'react'
import { Button } from '@/common/button'
import {
  DropdownContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
  PageViewContainer,
} from '@/components/layout/page'
import { useSetting } from '@/hooks/useSetting'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { BulkQualifyUploadModal } from './BulkQualifyUploadModal'
import { CreateAccountsFilters } from './CreateAccountsFilters'
import { QualifyEmployeeModal } from './QualifyEmployeeModal'

interface Props {
  sidebar: React.ReactNode
}

export const CreateAccountsContainer: FC<Props> = ({ children, sidebar }) => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const bulkKycEnabled = useSetting('ENABLE_BULK_ACCOUNT_CREATION') === 'true'

  const openBulkQualifyUploadModal = () => {
    registerModal(<BulkQualifyUploadModal />)
  }

  const openQualifyEmployeeUploadModal = () => {
    registerModal(<QualifyEmployeeModal />)
  }

  const createMenu = [
    {
      label: 'Bulk Upload',
      handleClick: openBulkQualifyUploadModal,
    },
    {
      label: 'Create Single',
      handleClick: openQualifyEmployeeUploadModal,
    },
  ]

  return (
    <PageViewContainer sidebar={sidebar}>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Create Accounts" />
          {(isGlobalAdmin || bulkKycEnabled) && (
            <PageActions>
              {bulkKycEnabled && (
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <Button mode="filled">Create</Button>
                  </DropdownMenuTrigger>
                  <DropdownContent>
                    {createMenu.map(option => (
                      <DropdownMenuItem
                        onClick={option.handleClick}
                        key={option.label}
                      >
                        {option.label}
                      </DropdownMenuItem>
                    ))}
                  </DropdownContent>
                </DropdownMenu>
              )}
            </PageActions>
          )}
        </PageHeader>
        <PageFilters>
          <CreateAccountsFilters />
        </PageFilters>
      </PageTop>
      <PageBody hasError={false}>{children}</PageBody>
    </PageViewContainer>
  )
}
