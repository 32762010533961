export const ContactSupport = () => (
  <a
    href="mailto:adminsupport@branchapp.com"
    target="_blank"
    style={{ display: 'block', marginTop: '4px' }}
    rel="noreferrer"
  >
    Contact Support
  </a>
)
