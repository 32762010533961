import moment from 'moment'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Card } from '@/common/card'
import ContentEmpty from '@/common/empty'
import { EmptyBarChartIcon } from '@/common/icons'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { IAdoptionInsightsData } from '@/lib/adoptionInsights'

interface IProps {
  data: IAdoptionInsightsData[]
}

const renderTooltip = (props: any) => {
  return props.payload ? (
    <Card>
      <Text as="h6">{moment.unix(props.label).format('MMM DD, YYYY')}</Text>
      <Flex direction="column">
        {props.payload.length > 0 && (
          <span>
            {props.payload[0].value} total users signed up,
            <br />
            {props.payload[1].value} of whom have used Pay
          </span>
        )}
      </Flex>
    </Card>
  ) : null
}

const DailySignUps = (props: IProps) => {
  const totalSignupCount = props.data.reduce((count, curr) => {
    return count + curr.signups
  }, 0)

  return (
    <>
      <h5>Daily Signups</h5>
      {totalSignupCount === 0 ? (
        <ContentEmpty
          icon={<EmptyBarChartIcon />}
          title="No data available"
          height="sm"
        />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={730}
            height={250}
            data={props.data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#A7D79A" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#A7D79A" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4CB258" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#4CB258" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="start.seconds"
              tickFormatter={t => moment.unix(t).format('MMM DD YYYY')}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={renderTooltip} />
            <Bar dataKey="signups" fill="#37536B" />
            <Bar dataKey="usersSignedUpAndHaveSinceUsedPay" fill="#349DF6" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default DailySignUps
