import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { IconButton } from '@/common/button'

interface Props {
  isExpanded: boolean
}

export const TableExpander: FC<Props> = ({ isExpanded }) => (
  <>
    {isExpanded ? (
      <IconButton small>
        <ChevronUpIcon />
      </IconButton>
    ) : (
      <IconButton small>
        <ChevronDownIcon />
      </IconButton>
    )}
  </>
)
