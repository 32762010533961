import { FC } from 'react'
import { Button } from '@/common/button'
import { Box } from '@/common/layout'
import { SimpleTable } from '@/common/table/SimpleTable'
import { useAdminUsersQuery } from '@/queries/admin-users'
import { useModalStore } from '@/store/modal'
import { Admin } from '@/types/organization'
import { userColumns } from '../lib/columns'
import { InviteEmailModal } from './InviteEmailModal'

export const AdminUsers: FC = () => {
  const admins = useAdminUsersQuery()
  const { registerModal } = useModalStore()
  const openInviteEmailModal = () => {
    registerModal(<InviteEmailModal />)
  }

  const filteredRecords = (admins.data || []).filter(
    (record: Admin) => record.scope_type !== 'global'
  )

  return (
    <>
      <hr className="my-5" />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Administrators</h5>
        <Button mode="filled" onClick={openInviteEmailModal}>
          Invite Admin
        </Button>
      </div>
      <Box
        css={{ backgroundColor: 'white', borderRadius: '$sm', padding: '$8' }}
      >
        <SimpleTable
          data={filteredRecords}
          columns={userColumns}
          hideLastColumnBorder
        />
      </Box>
    </>
  )
}
