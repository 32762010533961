import qs from 'qs'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { DateRangePreset } from '@/common/datepicker/types'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  payback: PaybackRecord
}

export const ViewDetailsLink: FC<Props> = ({
  payback: { id, description },
}) => {
  const isRemittance = description.toLowerCase().includes('remittance')

  const detailsPath = useOrgPath(
    isRemittance ? ROUTES.REMITTANCES : ROUTES.DISBURSEMENTS
  )

  const params = useMemo(() => {
    if (isRemittance) {
      return qs.stringify({ filter_payback_id: id })
    }

    return qs.stringify({
      filter_payback_id: id,
      filter_preset: DateRangePreset.ALL_TIME,
    })
  }, [isRemittance, id])

  return (
    <Link to={`${detailsPath}?${params}`}>
      View {isRemittance ? 'Remittance' : 'Disbursements'}
    </Link>
  )
}
