export const AdminShieldIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99998 0C6.4134 0 5.8374 0.148983 5.32463 0.432492L5.23274 0.486369L5.11565 0.559062L5.02182 0.620835C4.80872 0.76941 4.52318 0.840334 4.25733 0.808989L4.12959 0.788829C3.9745 0.759566 3.75292 0.739588 3.52792 0.742268L3.40328 0.743749C2.90022 0.749746 2.40496 0.867964 1.95365 1.08978L1.84132 1.14498C1.53697 1.29459 1.2645 1.48081 1.01601 1.72829C0.734718 2.00844 0.503827 2.33495 0.33354 2.69351C0.112705 3.1585 -0.00124053 3.6669 1.01858e-05 4.18153V7.9401C-0.000649936 9.13265 0.284252 10.3094 0.830964 11.3712C1.37778 12.4331 2.17114 13.3495 3.14433 14.0435L4.9936 15.3593C5.57967 15.7761 6.28086 16 6.99998 16C7.71911 16 8.4204 15.776 9.0061 15.3595L10.8538 14.0449C11.829 13.3494 12.622 12.4333 13.169 11.3712C13.7157 10.3095 14.0006 9.13261 14 7.93852V4.17533C14.0012 3.66635 13.887 3.15794 13.6663 2.69323C13.4968 2.33582 13.2656 2.00889 12.9841 1.72848C12.7356 1.48097 12.4635 1.29493 12.1597 1.14546L12.0457 1.08949C11.5953 0.868011 11.0998 0.749747 10.5967 0.743751L10.472 0.742267L10.3626 0.743801L10.1298 0.753101L9.89613 0.785134L9.80503 0.799659C9.54681 0.846636 9.25739 0.792613 9.02829 0.653369L8.92021 0.581726C8.39207 0.214707 7.70416 0 6.99998 0Z"
      fill="white"
    />
    <path
      d="M6.99995 2.10522C6.77081 2.10522 6.54624 2.16348 6.34596 2.27421L6.22887 2.3469C5.58891 2.79309 4.78516 2.99108 4.01005 2.89969L3.81699 2.87079C3.72762 2.85454 3.64022 2.8463 3.55304 2.84734L3.42834 2.84882C3.2397 2.85107 3.05398 2.8954 2.88474 2.97858L2.77232 3.03383C2.66917 3.08453 2.58259 3.14147 2.50494 3.21881C2.3952 3.3281 2.30507 3.45552 2.23858 3.59552C2.15238 3.77702 2.10791 3.97546 2.1084 4.17634V7.94006C2.10792 8.79849 2.31281 9.64485 2.70602 10.4085C3.09924 11.1721 3.66986 11.8312 4.36969 12.3303L6.21712 13.6448C6.44573 13.8074 6.71934 13.8947 6.99995 13.8947C7.28054 13.8947 7.55424 13.8073 7.7828 13.6447L9.63022 12.3303C10.3303 11.8311 10.9006 11.1722 11.2939 10.4085C11.6871 9.64487 11.892 8.79843 11.8915 7.93958V4.17529C11.892 3.97544 11.8475 3.77699 11.7613 3.59547C11.695 3.45574 11.6048 3.32824 11.495 3.21884C11.4173 3.1415 11.3308 3.08461 11.2278 3.03391L11.115 2.97853C10.946 2.89542 10.7602 2.85107 10.5715 2.84883L10.4469 2.84734L10.3157 2.85258L10.1829 2.87079C9.41392 3.01069 8.60023 2.85779 7.932 2.45165L7.76808 2.34486C7.54331 2.18911 7.27491 2.10522 6.99995 2.10522Z"
      fill="#00CD4D"
    />
    <path
      d="M9.64185 6.96388C9.62513 6.91555 9.59474 6.87311 9.55435 6.84169C9.51396 6.81026 9.46531 6.79122 9.41429 6.78686L7.90873 6.56758L7.234 5.20165C7.21233 5.15698 7.1785 5.11931 7.13639 5.09295C7.09427 5.06659 7.04556 5.05261 6.99586 5.05261C6.94616 5.05261 6.89745 5.06659 6.85533 5.09295C6.81322 5.11931 6.77939 5.15698 6.75772 5.20165L6.08299 6.56493L4.57743 6.78686C4.52846 6.79382 4.48243 6.81433 4.44454 6.84609C4.40666 6.87784 4.37845 6.91957 4.36311 6.96652C4.34907 7.01241 4.34781 7.06124 4.35946 7.10779C4.37112 7.15433 4.39525 7.19683 4.42926 7.23073L5.52205 8.28753L5.25745 9.7882C5.248 9.83773 5.25295 9.88893 5.27171 9.93574C5.29046 9.98256 5.32223 10.023 5.36329 10.0524C5.4033 10.081 5.45049 10.0978 5.49957 10.1011C5.54864 10.1044 5.59766 10.0939 5.64112 10.0709L6.99586 9.36548L8.34531 10.0735C8.38245 10.0945 8.42439 10.1054 8.46703 10.1052C8.52308 10.1054 8.57774 10.0879 8.62314 10.055C8.66419 10.0257 8.69597 9.9852 8.71473 9.93839C8.73348 9.89157 8.73842 9.84038 8.72898 9.79084L8.46438 8.29017L9.55718 7.23336C9.59538 7.20105 9.62362 7.15857 9.6386 7.11087C9.65359 7.06316 9.65471 7.01219 9.64185 6.96388Z"
      fill="white"
    />
  </svg>
)
