import moment from 'moment'
import { FC } from 'react'
import { Card, CardBody, CardText, CardTitle } from 'reactstrap'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import ContentEmpty from '@/common/empty'
import { EmptyLineChartIcon } from '@/common/icons'
import { AdoptionTimeSeriesItem } from '@/types/insights'

interface Props {
  data: AdoptionTimeSeriesItem[]
}

const renderTooltip = (props: any) => {
  return props.payload ? (
    <Card>
      <CardBody>
        <CardTitle tag="h6" className="font-weight-bold">
          {moment.unix(props.label).format('MMM DD, YYYY')}
        </CardTitle>
        <CardText>
          {props.payload.length > 0 && (
            <span>{props.payload[0].value} signups to date</span>
          )}
        </CardText>
      </CardBody>
    </Card>
  ) : null
}

const TotalSignUpsToDate: FC<Props> = ({ data }) => {
  const lastRecord = data[data.length - 1]
  const hasSignups =
    lastRecord && lastRecord.signups_to_date && lastRecord.signups_to_date !== 0

  return (
    <>
      <h5>Total Signups To Date</h5>
      {!hasSignups ? (
        <ContentEmpty
          icon={<EmptyLineChartIcon />}
          title="No data available"
          height="sm"
        />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            width={730}
            height={250}
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#A7D79A" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#A7D79A" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4CB258" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#4CB258" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="start.seconds"
              tickFormatter={t => moment.unix(t).format('MMM DD YYYY')}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={renderTooltip} />
            <Area
              type="monotone"
              dataKey="signups_to_date"
              stroke="#4CB258"
              fillOpacity={0.25}
              fill="url(#colorPv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default TotalSignUpsToDate
