import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { useCreateFundingSource } from '@/queries/funding-sources'
import { FundingSource } from '@/types/invoice'

interface Props {
  onSuccess: (fundingSource: FundingSource) => void
}

export type FormValues = {
  accountNumber: string
  routingNumber: string
  nickname: string
}

const initialValues = {
  accountNumber: '',
  routingNumber: '',
  nickname: '',
}

const schema = Yup.object().shape({
  accountNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]*$/, 'Must only contain numbers'),
  routingNumber: Yup.string()
    .required('Required')
    .matches(/^[0-9]*$/, 'Must only contain numbers'),
  nickname: Yup.string()
    .required('Required')
    .max(30, '30 character maximum'),
})

export const AddFundingSourceForm: FC<Props> = ({ onSuccess }) => {
  const createFundingSource = useCreateFundingSource()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const onFormSubmit = (values: FormValues) => {
    createFundingSource.mutate(
      {
        routing_number: values.routingNumber,
        account_number: values.accountNumber,
        name: values.nickname,
      },
      {
        onSuccess,
      }
    )
  }

  return (
    <FlexGrid direction="column" fullWidth>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div>
          <Label htmlFor="accountNumber">Account Number</Label>
          <Input {...register('accountNumber')} />
          <Text color="alert" size="sm">
            {errors.accountNumber?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="routingNumber">Routing Number</Label>
          <Input {...register('routingNumber')} />
          <Text color="alert" size="sm">
            {errors.routingNumber?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="nickname">Nickname</Label>
          <Input {...register('nickname')} />
          <Text color="alert" size="sm">
            {errors.nickname?.message}
          </Text>
        </div>
        <Flex justify="end" css={{ mt: '$24' }}>
          <Button
            mode="filled"
            type="submit"
            disabled={createFundingSource.isLoading}
          >
            Add Funding Source
          </Button>
        </Flex>
      </form>
    </FlexGrid>
  )
}
