import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import {
  assignUserRoles,
  createRoleForOrg,
  getAdminsForOrg,
  getPermissions,
  getRolesForOrg,
  getUserGroupRestrictions,
  getUserGroups,
  getUserPermissions,
  getUserRoles,
  inviteAdmin,
  removeRoleForOrg,
  updateRoleForOrg,
} from '@/api/permissions'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { ROUTES } from '@/router/routes'
import { usePermissionsStore } from '@/store/permissions'
import { useToasterStore } from '@/store/toast'
import { useUser } from '@/store/user'
import {
  AdminInviteRequest,
  AssignUserRoleRequest,
  UpdateRoleRequest,
} from '@/types/permissions'

export const PERMISSIONS_QUERY = 'permissions-query'

export const usePermissionsQuery = () => {
  const { orgId } = usePermissionsStore()

  return useQuery(
    [PERMISSIONS_QUERY, orgId],
    async () => {
      const res = await getPermissions()
      return res.data
    },
    {
      retry: 1,
    }
  )
}

export const USER_PERMISSIONS_QUERY = 'user-permissions-query'

export const useUserPermissionsQuery = () => {
  const { id } = useUser()
  const { setPermissions } = usePermissionsStore()

  return useQuery(
    [USER_PERMISSIONS_QUERY, id],
    async () => {
      const res = await getUserPermissions(id)
      return res.data
    },
    {
      onSuccess: data => {
        setPermissions(data)
      },
      enabled: !!id,
      retry: 1,
    }
  )
}

export const ROLES_QUERY = 'roles'

export const useRolesQuery = () => {
  const { addToast } = useToasterStore()
  const { id, parseToken } = useUser()
  const { setRoles } = usePermissionsStore()
  const history = useHistory()

  return useQuery(
    [ROLES_QUERY, id],
    async () => {
      const res = await getUserRoles({
        user_id: id,
      })
      return res.data
    },
    {
      onSuccess: data => {
        setRoles(data)
        if (data.find(role => role.name === 'pay_admin')) return

        addToast({
          type: 'error',
          title: 'Unauthorized',
          description: 'You do not have permission to access Pay Admin.',
        })
        parseToken(undefined)
        history.push(ROUTES.REGISTER)
      },
      enabled: !!id,
      retry: 1,
    }
  )
}

export const ORG_ROLE_QUERY = 'org-role-query'

export const useOrganizationRoles = () => {
  const { orgId } = usePermissionsStore()
  return useQuery(
    [ORG_ROLE_QUERY, orgId],
    async () => {
      const res = await getRolesForOrg(orgId)
      return res.data
    },
    {
      enabled: !!orgId,
      retry: 1,
    }
  )
}

export const useCreateOrgRole = () => {
  const { orgId } = usePermissionsStore()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async (data: UpdateRoleRequest) => {
      const res = await createRoleForOrg(orgId, data)
      return res.data
    },
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries([ORG_ROLE_QUERY, orgId])
        sendAnalyticsEvent('permissions', 'create-role:success', {
          ...data,
        })
        addToast({
          type: 'success',
          title: 'Succesfully to created role',
        })
      },
      onError: (_, data) => {
        sendAnalyticsEvent('permissions', 'create-role:failure', {
          ...data,
        })
        addToast({
          type: 'error',
          title: 'Failed to create role',
        })
      },
    }
  )
}

interface EditOrgRole {
  data: UpdateRoleRequest
  roleId: number
}

export const useEditOrgRole = () => {
  const { orgId } = usePermissionsStore()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ roleId, data }: EditOrgRole) => {
      const res = await updateRoleForOrg(roleId, orgId, data)
      return res.data
    },
    {
      onSuccess: (_, { roleId }) => {
        queryClient.invalidateQueries([ORG_ROLE_QUERY, orgId])
        sendAnalyticsEvent('permissions', 'edit-role:success', {
          roleId,
        })
        addToast({
          type: 'success',
          title: 'Succesfully to edited role',
        })
      },
      onError: (_, { roleId }) => {
        sendAnalyticsEvent('permissions', 'edit-role:failure', {
          roleId,
        })
        addToast({
          type: 'error',
          title: 'Failed to edit role',
        })
      },
    }
  )
}

export const useDeleteOrgRole = () => {
  const { orgId } = usePermissionsStore()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async (roleId: number) => {
      const res = await removeRoleForOrg(roleId, orgId)
      return res.data
    },
    {
      onSuccess: (_, roleId) => {
        queryClient.invalidateQueries([ORG_ROLE_QUERY, orgId])
        sendAnalyticsEvent('permissions', 'remove-role:success', {
          roleId,
          orgId,
        })
        addToast({
          type: 'success',
          title: 'Succesfully to removed role',
        })
      },
      onError: (_, roleId) => {
        sendAnalyticsEvent('permissions', 'remove-role:failure', {
          roleId,
          orgId,
        })
        addToast({
          type: 'error',
          title: 'Failed to remove role',
        })
      },
    }
  )
}

export const ORG_ADMINS_QUERY = 'org-admins-query'

export const useOrganizationAdmins = () => {
  const { orgId } = usePermissionsStore()
  return useQuery(
    [ORG_ADMINS_QUERY, orgId],
    async () => {
      const res = await getAdminsForOrg(orgId)
      return res.data
    },
    {
      enabled: !!orgId,
      retry: 1,
    }
  )
}

export const USER_ORG_GROUPS_QUERY = 'user-org-groups-query'

export const useUserOrganizationGroups = (userId: string | null) => {
  const { orgId } = usePermissionsStore()

  return useQuery(
    [USER_ORG_GROUPS_QUERY, orgId, userId],
    async () => {
      const res = await getUserGroups(orgId, userId)
      return res.data
    },
    {
      enabled: !!orgId && !!userId,
      retry: 1,
    }
  )
}

export const USER_ORG_GROUP_RESTRICTIONS_QUERY =
  'user-org-group-restrictions-query'

export const useUserOrganizationGroupRestrictions = (userId: string | null) => {
  const { orgId } = usePermissionsStore()

  return useQuery(
    [USER_ORG_GROUP_RESTRICTIONS_QUERY, orgId, userId],
    async () => {
      const res = await getUserGroupRestrictions(orgId, userId)
      return res.data
    },
    {
      enabled: !!orgId && !!userId,
      retry: 1,
    }
  )
}

export const useInviteAdmin = () => {
  const { orgId } = usePermissionsStore()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async (invite: AdminInviteRequest) => {
      const res = await inviteAdmin(invite)
      return res.data
    },
    {
      onSuccess: (_, { email }) => {
        queryClient.invalidateQueries([ORG_ADMINS_QUERY, orgId])
        sendAnalyticsEvent('permissions', 'invite-admin:success', { email })
        addToast({
          type: 'success',
          title: `Succesfully invited ${email} to the organization`,
        })
      },
      onError: (_, { email }) => {
        sendAnalyticsEvent('permissions', 'invite-admin:failure', {
          email,
        })
        addToast({
          type: 'error',
          title: `Failed to invite ${email} to the organization`,
        })
      },
    }
  )
}

type AssingRoles = {
  data: AssignUserRoleRequest
  userId: number
}

export const useAssignUserRoles = () => {
  const { orgId } = usePermissionsStore()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    async ({ userId, data }: AssingRoles) => {
      const res = await assignUserRoles(orgId, userId, data)
      return res.data
    },
    {
      onSuccess: (_, { userId }) => {
        queryClient.invalidateQueries([ORG_ADMINS_QUERY, orgId])
        queryClient.invalidateQueries([USER_ORG_GROUPS_QUERY, orgId, userId])
        queryClient.invalidateQueries([ORG_ROLE_QUERY, orgId])
        sendAnalyticsEvent('permissions', 'assign-role:success')
        addToast({
          type: 'success',
          title: `Succesfully assigned roles and location`,
        })
      },
      onError: () => {
        sendAnalyticsEvent('permissions', 'assign-role:failure')
        addToast({
          type: 'error',
          title: `Failed to assign roles and locations`,
        })
      },
    }
  )
}
