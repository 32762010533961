import { FC } from 'react'
import { Button } from '@/common/button'
import { FlexWrap } from '@/common/layout'
import { InvoiceConfigModal } from '@/components/settings/invoice-configurations/components/InvoiceConfigModal'
import { useModalStore } from '@/store/modal'
import { FundingSource } from '@/types/invoice'
import { DeleteFundingSourceModal } from './DeleteFundingSourceModal'

interface Props {
  fundingSource: FundingSource
}

export const FundingSourceDetails: FC<Props> = ({ fundingSource }) => {
  const { registerModal } = useModalStore()
  const openConfirmDeleteModal = () =>
    registerModal(<DeleteFundingSourceModal fundingSource={fundingSource} />)

  const openInvoiceConfigModal = () =>
    registerModal(<InvoiceConfigModal fundingSourceId={fundingSource.id} />)

  return (
    <div>
      <FlexWrap justify="between">
        <Button
          mode="outline"
          onClick={openConfirmDeleteModal}
          color="danger"
          css={{ mt: '$12' }}
        >
          Delete
        </Button>
        <Button mode="filled" onClick={openInvoiceConfigModal}>
          Add Invoice Configuration
        </Button>
      </FlexWrap>
    </div>
  )
}
