import { FC } from 'react'
import { Alert } from '@/common/alert'
import { Loader } from '@/common/loader'
import { useQsrRegistration } from '@/src/queries/integrations'
import { QsrIntegrationForm } from './QsrIntegrationForm'

export const QsrIntegration: FC = () => {
  const { data, isLoading } = useQsrRegistration()

  return (
    <>
      <Alert type="success">
        <strong>QSRSoft</strong> integration is active.
      </Alert>
      <Loader loading={isLoading}>
        <QsrIntegrationForm data={data} />
      </Loader>
    </>
  )
}
