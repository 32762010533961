import classnames from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from '@/src/styles/tabs.module.scss'

interface Props {
  to: string
  text: string
  isActive: boolean
  isHidden?: boolean
  compact?: boolean
  onClick?: () => void
}

export const TabsLinkItem: FC<Props> = ({
  to,
  text,
  isActive = false,
  isHidden,
  onClick,
  compact,
}) => {
  if (isHidden) {
    return null
  }
  const classes = classnames(styles.TabItem, {
    [styles.isActive]: isActive,
    [styles.compact]: compact,
  })
  const linkId = text.toLowerCase().replace(/\s/g, '')
  return (
    <Link to={to} id={linkId} className={classes} onClick={onClick}>
      {text}
    </Link>
  )
}
