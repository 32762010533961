import classnames from 'classnames'
import styles from '@/src/styles/tabs.module.scss'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive: boolean
}

const TabsItem: React.FC<IProps> = ({
  isActive,
  children,
  className,
  ...rest
}) => {
  const classes = classnames(styles.TabItem, className, {
    [styles.isActive]: isActive,
  })

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}

TabsItem.defaultProps = {
  isActive: false,
}

export default TabsItem
