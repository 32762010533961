import { Card } from '@/common/card'
import { Flex } from '@/common/layout'
import { styled } from '@/styles/stitches.config'

export const ResourceCardContainer = styled(Card, {
  marginBottom: '$24',
  overflow: 'hidden',
  display: 'flex',
  height: 250,
})

export const ResourceCardHeader = styled(Flex, {
  mb: '$24',
  paddingBottom: '$12',
  borderBottom: '1px solid $secondary700',
  width: '100%',
})

export const ResourceCardOverlay = styled(Card, {
  position: 'absolute',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
  backgroundColor: 'white',
  color: 'black',
  opacity: 0.9,
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
