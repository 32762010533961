import { FC } from 'react'
import {
  ScrollAreaContainer,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaViewport,
  ScrollThumb,
} from './styles'

export const ScrollArea: FC = ({ children }) => (
  <ScrollAreaContainer>
    <ScrollAreaViewport css={{ backgroundColor: 'white' }}>
      {children}
    </ScrollAreaViewport>
    <ScrollAreaScrollbar orientation="vertical">
      <ScrollThumb />
    </ScrollAreaScrollbar>
    <ScrollAreaScrollbar orientation="horizontal">
      <ScrollThumb />
    </ScrollAreaScrollbar>
    <ScrollAreaCorner />
  </ScrollAreaContainer>
)
