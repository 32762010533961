import { ColumnDef } from '@tanstack/react-table'
import { Flex } from '@/common/layout'
import { TableExpander } from '@/common/table'
import { StatusCell } from '../StatusCell'
import { IReviewRow } from './types'

export const columns: ColumnDef<IReviewRow, any>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Alternate Name',
    accessorKey: 'alternate',
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ row }) => <StatusCell statusType={row.original.status} />,
  },
  {
    header: 'Message',
    accessorKey: 'message',
    size: 500,
  },
  {
    id: 'expand',
    size: 40,
    cell: ({ row }) => (
      <Flex justify="center">
        <div
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer' },
          }}
        >
          <TableExpander isExpanded={row.getIsExpanded()} />
        </div>
      </Flex>
    ),
  },
]
