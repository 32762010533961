export function validatorCSVEmails(emailsString: string) {
  const emails = emailsString.split(',')
  let valid = true
  emails.forEach(email => {
    if (!email.includes('@') && !email.includes('.')) {
      valid = false
    }
  })
  return valid
}

export const isEmptyObject = (obj: Object) => Object.keys(obj).length === 0

export const shallowEqual = (object1: Object, object2: Object) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (object1[key as keyof Object] !== object2[key as keyof Object]) {
      return false
    }
  }

  return true
}
