import { FC, useMemo } from 'react'
import { Card } from '@/common/card'
import { DetailHeading } from '@/common/detail'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import { useInvoiceNote } from '@/queries/invoice-configs'
import { usePermissionsStore } from '@/store/permissions'
import { PaybackRecord } from '@/types/paybacks'
import { invoiceNotesColumns } from '../lib/columns'

interface Props {
  payback: PaybackRecord
}

export const InvoiceNotes: FC<Props> = ({ payback }) => {
  const invoiceNotesQuery = useInvoiceNote(payback.id)
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const invoiceNotes = useMemo(() => invoiceNotesQuery.data || [], [
    invoiceNotesQuery,
  ])

  return (
    <Loader loading={invoiceNotesQuery.isLoading}>
      <>
        {invoiceNotes.length > 0 && isGlobalAdmin && (
          <Card>
            <DetailHeading css={{ mb: '$8' }}>Invoice Notes</DetailHeading>
            <SimpleTable data={invoiceNotes} columns={invoiceNotesColumns} />
          </Card>
        )}
      </>
    </Loader>
  )
}
