import { useEffect, useMemo } from 'react'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { Table } from '@/common/table'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useAdvancesQuery } from '@/src/queries/advances'
import { useAdvancesStore } from '@/store/advances'
import { TransactionDeductions } from './components/TransactionDeductions'
import { TransactionRecordsContainer } from './components/TransactionRecordsContainer'
import { columns } from './lib/columns'

const initialSortedState = [
  {
    id: 'advance_date',
    desc: true,
  },
]

export const TransactionRecords = () => {
  const {
    page,
    pageSize,
    sorted,
    filters,
    updateFilters,
    updatePageSize,
    updatePage,
    setSorted,
    isReady,
  } = useAdvancesStore()
  const { data, isLoading } = useAdvancesQuery()

  // Update query params when filters change
  useUpdateQueryParamFilters(filters)

  // Retrieve query params and update filters on initial load
  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
    isReady()
  }, [queryParams, updateFilters, isReady])

  const advances = useMemo(() => data?.content || [], [data])

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    data?.total_pages
  )

  return (
    <TransactionRecordsContainer>
      <Table
        columns={columns}
        data={advances}
        controlledPagination={true}
        sorted={sorted}
        onSortedChange={setSorted}
        initialSortedState={initialSortedState}
        isLoading={isLoading}
        page={page}
        pageRows={pageSize}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        changePage={updatePage}
        updatePageSize={updatePageSize}
        renderSubComponent={row => <TransactionDeductions row={row} />}
        hideLastColumnBorder
      />
    </TransactionRecordsContainer>
  )
}
