import { FC } from 'react'
import { Badge, BadgeSize } from '@/common/badge'
import { WALLET_LABELS, WALLET_STATUS } from '../lib/employees.types'

interface Props {
  statusKey?: WALLET_STATUS
  badgeSize?: BadgeSize
}

export const WalletBadge: FC<Props> = ({ statusKey, badgeSize }) => {
  const { ACTIVE, FAILED } = WALLET_STATUS

  const badgeColor =
    statusKey === ACTIVE ? 'success' : statusKey === FAILED ? 'danger' : 'info'

  if (!statusKey) {
    return null
  }

  return (
    <Badge type={badgeColor} size={badgeSize}>
      {WALLET_LABELS[statusKey]}
    </Badge>
  )
}
