import http from './http'
import {
  ICardProgramsResponse,
  ICreateCardProgram,
  IOrgCardProgramsResponse,
} from './types/card-program'

export const getCardPrograms = () =>
  http.get<ICardProgramsResponse>(`p1/card_programs`)

export const getOrgCardPrograms = (orgId: number) =>
  http.get<IOrgCardProgramsResponse>(`p1/organizations/${orgId}/card_programs`)

export const createCardProgram = (config: ICreateCardProgram) =>
  http.post(`p1/card_programs`, config)

export const assignCardProgram = (orgId: number, program_token: string) =>
  http.post(`p1/organizations/${orgId}/card_programs`, { program_token })

export const removeCardProgram = (orgId: number, program_token: string) =>
  http.delete(
    `p1/organizations/${orgId}/card_programs?program_token=${program_token}`
  )
