import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { LockedIndicator } from '@/common/locked-indicator/LockedIndicator'
import { Text } from '@/common/text'
import Spinner from '@/components/common/loader/Spinner'
import { isSemiColonDelimited24HrTimeRangeList } from '@/lib/customYupValidators'
import { RegexAltPhone } from '@/lib/regex'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { usePermissionsStore } from '@/store/permissions'
import { useSettingsStore } from '@/store/settings'

export type SupportFormValues = {
  SUPPORT_EMAIL: string
  SUPPORT_PHONE: string
  SUPPORT_HELP_CENTER_URL: string
  SUPPORT_HOURS_OF_OPERATION: string
}

const schema = Yup.object().shape({
  SUPPORT_EMAIL: Yup.string().email('Email must be a valid format'),
  SUPPORT_PHONE: Yup.string().matches(
    RegexAltPhone,
    'Phone number must be in format +1 (999) 999-9999'
  ),
  SUPPORT_HELP_CENTER_URL: Yup.string().url(
    'Must be a valid url. Example: https://branchapp.com'
  ),
  SUPPORT_HOURS_OF_OPERATION: Yup.string().test(
    isSemiColonDelimited24HrTimeRangeList
  ),
})

const resetForm = (settings: SupportFormValues) => ({
  SUPPORT_EMAIL: settings.SUPPORT_EMAIL,
  SUPPORT_PHONE: settings.SUPPORT_PHONE,
  SUPPORT_HELP_CENTER_URL: settings.SUPPORT_HELP_CENTER_URL,
  SUPPORT_HOURS_OF_OPERATION: settings.SUPPORT_HOURS_OF_OPERATION,
})

export const Support = () => {
  const { settings, isLoading } = useSettingsStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const updateSettings = useUpdateSettingsMutation()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: resetForm(settings),
  })

  const onSubmit = (values: SupportFormValues) => updateSettings.mutate(values)

  useEffect(() => {
    reset(resetForm(settings))
  }, [settings, reset])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="row" justify="between">
        <Text as="h5">Contact Details</Text>
        <LockedIndicator />
      </Flex>
      <Text css={{ mb: '$24' }}>
        Branch is here to help. Your workers can reach our support team at any
        time using the following contact info.
      </Text>
      <FlexGrid direction="column" fullWidth>
        <div>
          <Label htmlFor="SUPPORT_EMAIL">Support Email</Label>
          <Input {...register('SUPPORT_EMAIL')} />
          <Text color="alert" size="sm">
            {errors.SUPPORT_EMAIL?.message}
          </Text>
        </div>
        <div>
          <Label htmlFor="SUPPORT_PHONE">Support Phone Number</Label>
          <Input {...register('SUPPORT_PHONE')} />
          <Text color="alert" size="sm">
            {errors.SUPPORT_PHONE?.message}
          </Text>
          <Text color="hint">Format: +1 (999) 999-9999</Text>
        </div>
        <div>
          <Label htmlFor="SUPPORT_HELP_CENTER_URL">
            Support Help Center URL
          </Label>
          <Input {...register('SUPPORT_HELP_CENTER_URL')} />
          <Text color="alert" size="sm">
            {errors.SUPPORT_HELP_CENTER_URL?.message}
          </Text>
        </div>
        <hr />
        <Flex direction="row" justify="between">
          <Text as="h5">Hours</Text>
          <LockedIndicator />
        </Flex>
        <div>
          <Label htmlFor="SUPPORT_HOURS_OF_OPERATION">Support Hours</Label>
          <Input {...register('SUPPORT_HOURS_OF_OPERATION')} />
          <Text color="alert" size="sm">
            {errors.SUPPORT_HOURS_OF_OPERATION?.message}
          </Text>
          <Text color="hint">
            Provide support hours for each day of the week, separated by
            semicolons. 24-hour format (Ex: 09:00-17:00). Use "null" for no
            hours.
          </Text>
        </div>
      </FlexGrid>

      <Flex justify="end" css={{ mt: '$24' }}>
        <Button
          mode="filled"
          type="submit"
          loading={updateSettings.isLoading}
          disabled={updateSettings.isLoading || !isGlobalAdmin || !isDirty}
          css={{ ml: 'auto' }}
        >
          Update Settings
        </Button>
      </Flex>
      <hr />
      <div>
        <Text as="h5" css={{ mb: '$12' }}>
          Admin Support
        </Text>
        <Label>Email</Label>
        <p>
          <a href="mailto:adminsupport@branchapp.com">
            adminsupport@branchapp.com
          </a>
        </p>
        <Label>Phone</Label>
        <p>586-785-5768</p>
      </div>
    </form>
  )
}
