import classnames from 'classnames'
import styles from './index.module.scss'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean
}
const SearchDropdownListItem: React.FC<Props> = props => {
  const { className, disabled, ...rest } = props
  const classes = classnames(
    styles.ListItem,
    {
      [styles.ListItem_Disabled]: disabled,
    },
    className
  )

  return (
    <div className={classes} {...rest}>
      {props.children}
    </div>
  )
}

export default SearchDropdownListItem
