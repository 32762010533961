import { getDeductionFile } from '@/api/remittances'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { IRemittance } from '@/types/remittances'

export const downloadRemittance = async (remittance: IRemittance) => {
  sendAnalyticsEvent('remittances', 'details:download', {
    id: remittance.id,
  })
  try {
    const res = await getDeductionFile(`${remittance.id}`)
    const fileBlob = new Blob([res.data], { type: 'text/csv' })
    const data = window.URL.createObjectURL(fileBlob)
    const downloadPdf = document.createElement('a')
    downloadPdf.href = data
    downloadPdf.download = `Branch_PDI_${remittance.pay_date}.csv`
    downloadPdf.click()
  } catch (err) {
    console.error(err)
  }
}
