import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { OrgAdmin } from '@/types/permissions'
import { ConfigureAdminRoles } from './ConfigureAdminRoles'

interface Props {
  original: OrgAdmin
}

export const EditRolesModal: FC<Props> = ({ original }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title={`${original.first_name} ${original.last_name}`}
    >
      <ConfigureAdminRoles
        email={original.email}
        roles={original.roles}
        userId={original.id}
        handleClose={close}
      />
    </Dialog>
  )
}
