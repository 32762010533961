import classnames from 'classnames'
import { Card, CardBody } from 'reactstrap'
import styles from './index.module.scss'

interface IProps {
  title: string
  subtitle: string
  className?: string
  icon: React.ReactElement
}

export const ImportCard = (props: IProps) => {
  const classes = classnames(styles.ImportCard, props.className)
  return (
    <Card className={classes}>
      <CardBody className="d-flex">
        {props.icon}
        <div>
          <h5 className="font-weight-bold">{props.title}</h5>
          <p className="mb-0">{props.subtitle}</p>
        </div>
      </CardBody>
    </Card>
  )
}
