import { useMemo } from 'react'
import { Loader } from '@/common/loader'
import { Table } from '@/common/table'
import { useInstantCardsQuery } from '@/src/queries/instant-cards'
import { useInstantCardsOrderStore } from '@/store/instant-cards-orders'
import { InstantCardOrdersContainer } from './components/InstantCardOrdersContainer'
import { MarketingMessage } from './components/MarketingMessage'
import { columns, InstantCardGroupRow } from './lib/columns'

const initialSortedState = [{ id: 'id', desc: true }]

export const InstantCardOrders = () => {
  const {
    page,
    pageSize,
    sorted,
    setSorted,
    updatePage,
    updatePageSize,
    filteredData,
    setFilteredData,
  } = useInstantCardsOrderStore()
  const orders = useInstantCardsQuery()

  const orderRows = useMemo<InstantCardGroupRow[]>(() => {
    return (orders.data || []).map(({ shipping_address, ...rest }) => ({
      ...rest,
      ...shipping_address,
    }))
  }, [orders.data])

  return (
    <InstantCardOrdersContainer
      downloadableRows={filteredData as InstantCardGroupRow[]}
      error={orders.error as Error}
    >
      <Loader loading={orders.isLoading}>
        {orders.data?.length === 0 ? (
          <MarketingMessage />
        ) : (
          <Table
            columns={columns}
            data={orderRows}
            controlledPagination={false}
            onSortedChange={setSorted}
            sorted={sorted}
            initialSortedState={initialSortedState}
            setFilteredData={setFilteredData}
            isLoading={orders.isLoading}
            page={page}
            pageRows={pageSize}
            updatePageSize={updatePageSize}
            changePage={updatePage}
          />
        )}
      </Loader>
    </InstantCardOrdersContainer>
  )
}
