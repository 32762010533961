import { FC } from 'react'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useRemoveRosterRecord } from '@/queries/employees'
import { useModalStore } from '@/store/modal'
import { INotEnrolledUser } from '@/types/employees'
import { EditRosterUserModal } from './EditRosterUserModal'

interface Props {
  user: INotEnrolledUser
}

export const NotEnrolledActions: FC<Props> = ({ user }) => {
  const removeRosterRecord = useRemoveRosterRecord()
  const { registerModal } = useModalStore()
  const openEditRosterUserModal = () => {
    registerModal(<EditRosterUserModal user={user} />)
  }

  const onRemoveRecord = () => removeRosterRecord.mutate(user.employee_id)

  return (
    <TableActionDropdown
      menu={[
        { label: 'Edit roster record', handleClick: openEditRosterUserModal },
        { label: 'Remove', handleClick: onRemoveRecord },
      ]}
    />
  )
}
