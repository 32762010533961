import { flexRender, Row, Table } from '@tanstack/react-table'
import { Fragment, ReactElement } from 'react'
import { TableBodyColumn } from './styles'

interface Props<TData> {
  table: Table<TData>
  isLoading?: boolean
  rowOnClickFn?: (rowData: TData, rowIndex: number) => void
  renderSubComponent?: (row: Row<TData>) => ReactElement
  pinnedLeftColumnPositions?: number[]
}

export const TableBody = <TData extends Object>({
  table,
  isLoading,
  rowOnClickFn,
  renderSubComponent,
  pinnedLeftColumnPositions = [],
}: Props<TData>) => (
  <>
    {!isLoading && (
      <tbody>
        {table.getRowModel().rows.map(row => (
          <Fragment key={row.id}>
            <tr
              onClick={() =>
                rowOnClickFn ? rowOnClickFn(row.original, Number(row.id)) : null
              }
            >
              {row.getVisibleCells().map((cell, i) => (
                <TableBodyColumn
                  key={cell.id}
                  css={{
                    width: cell.column.getSize(),
                  }}
                  style={
                    table
                      .getState()
                      .columnPinning.left?.includes(cell.column.id)
                      ? {
                          left: pinnedLeftColumnPositions[i],
                          position: 'sticky',
                          zIndex: 2,
                          backgroundColor: 'white',
                          border: 'none',
                        }
                      : {}
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableBodyColumn>
              ))}
            </tr>
            {renderSubComponent && row.getIsExpanded() && (
              <tr>
                <td colSpan={row.getVisibleCells().length}>
                  {renderSubComponent(row)}
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    )}
  </>
)
