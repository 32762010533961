import { FC } from 'react'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { PaybackRecord, PaybackStatus } from '@/types/paybacks'
import { AddNoteModal } from './AddNoteModal'
import { EditInvoiceModal } from './EditInvoiceModal'
import { MarkPaybackCompletedModal } from './MarkPaybackCompletedModal'
import { MarkPaybackFailedModal } from './MarkPaybackFailedModal'
import { RetryFailedPaybackModal } from './RetryFailedPaybackModal'

interface Props {
  original: PaybackRecord
}

const { COMPLETED, FAILED } = PaybackStatus

export const PaybackActionDropdown: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  if (!isGlobalAdmin) {
    return null
  }

  const openMarkAsCompletedModal = () => {
    registerModal(<MarkPaybackCompletedModal record={original} />)
  }

  const openMarkAsFailedModal = () => {
    registerModal(<MarkPaybackFailedModal record={original} />)
  }

  const openRetryFailedModal = () => {
    registerModal(<RetryFailedPaybackModal record={original} />)
  }

  const openAddNoteModal = () => {
    registerModal(<AddNoteModal record={original} />)
  }

  const openEditInvoiceModal = () => {
    registerModal(<EditInvoiceModal record={original} />)
  }

  const menu = [
    {
      label: 'Add Note',
      handleClick: openAddNoteModal,
    },
    {
      label: 'Edit Invoice',
      handleClick: openEditInvoiceModal,
    },
    {
      label: 'Mark as Paid',
      handleClick: openMarkAsCompletedModal,
      hide: original.status === COMPLETED,
    },
    {
      label: 'Mark as Failed',
      handleClick: openMarkAsFailedModal,
      hide: original.status === FAILED || original.status === COMPLETED,
    },
    {
      label: 'Retry',
      handleClick: openRetryFailedModal,
      hide: !(original.status === FAILED),
    },
  ]

  return <TableActionDropdown menu={menu} />
}
