import { FC, useState } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { FundingSourceSelect } from '@/components/funding-source-select'
import { useEditInvoice, useInvoiceTransition } from '@/queries/invoice-configs'
import { useModalStore } from '@/store/modal'
import { PaybackRecord, PaybackStatus } from '@/types/paybacks'

interface Props {
  record: PaybackRecord
}

export const RetryFailedPaybackModal: FC<Props> = ({ record }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const transitionInvoice = useInvoiceTransition()
  const editInvoice = useEditInvoice()
  const [fundingSourceId, setFundingSourceId] = useState(
    record.funding_source_id
  )

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const updateInvoice = async () => {
    editInvoice.mutate(
      {
        invoiceId: record.id,
        description: record.description,
        funding_source_id: fundingSourceId,
        closeTime: record.close_time,
        billTime: record.bill_time,
      },
      {
        onSuccess: transition,
      }
    )
  }

  const transition = async () => {
    transitionInvoice.mutate(
      {
        invoiceId: record.id,
        status: PaybackStatus.BILLED,
      },
      {
        onSuccess: close,
      }
    )
  }

  return (
    <Dialog open={open} setOpen={close} title="Retry Failed Invoice">
      {(transitionInvoice.isError || editInvoice.isError) && (
        <Alert type="danger">
          Encountered an error:{' '}
          {(transitionInvoice.error as any)?.response?.data?.message}
          {(editInvoice.error as any)?.response?.data?.message}
        </Alert>
      )}
      <FundingSourceSelect
        selectedId={fundingSourceId}
        setSelectedId={setFundingSourceId}
      />
      <Flex justify="end">
        <Button
          mode="filled"
          onClick={updateInvoice}
          loading={transitionInvoice.isLoading || editInvoice.isLoading}
        >
          Confirm
        </Button>
      </Flex>
    </Dialog>
  )
}
