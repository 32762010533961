import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons'
import { Spinner } from '@/common/loader'
import { DynamicToast } from './DynamicToast'

export interface FireToastParams {
  type: 'success' | 'error' | 'pending'
  title?: string
  description?: string
}

export interface CreateToastParams extends FireToastParams {
  uid: string
}

const generateIcon = (type: string): JSX.Element => {
  switch (type) {
    case 'success':
      return <CheckCircledIcon width={25} height={25} color="green" />
    case 'error':
      return <ExclamationTriangleIcon width={25} height={25} color="red" />
    case 'pending':
      return <Spinner width={32} />
    default:
      return <></>
  }
}

const createToast = ({
  type,
  title,
  description,
  uid,
}: CreateToastParams): JSX.Element => {
  return (
    <DynamicToast
      title={title}
      description={description}
      contactSupport={type === 'error' ? true : false}
      id={uid}
      icon={generateIcon(type)}
      preventTimeout={type === 'success' ? false : true}
    />
  )
}

export default createToast
