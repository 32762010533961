import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { US_STATES } from '@/lib/states'
import { usePermissionsStore } from '@/store/permissions'
import { useCurrentOrg } from '../auth/hooks/useCurrentOrg'
import { cardOrderFormSchema, CardOrderFormValues } from './lib/orderForm'

interface Props {
  onSubmit: (values: CardOrderFormValues) => void
}

const InstantCardsOrderForm: FC<Props> = ({ onSubmit }) => {
  const currentOrg = useCurrentOrg()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const defaultValues = useMemo<CardOrderFormValues>(
    () => ({
      count: 1,
      prefixName: '',
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: US_STATES[0],
      zip: '',
      careOf: currentOrg.name,
    }),
    [currentOrg]
  )

  const schema = cardOrderFormSchema(isGlobalAdmin)

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CardOrderFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text css={{ mb: '$12' }}>
        Please supply us with the generic group name you want to appear on our
        Instant Cards, the number of cards, and where to ship your ards.
      </Text>
      <Text css={{ mb: '$12' }}>
        <i>
          <strong>Note: </strong>Store these in a safe or lockbox upon receiving
          and do not use any cards if you find that their envelope has been
          opened upon arrival. If you're mailing out cards after receiving, you
          must put it in another envelope to send out to the individual. Lastly,
          please shred unused cards.
        </i>
      </Text>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="prefixName">Group Name On Cards</Label>
          <Input type="text" {...register('prefixName')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.prefixName?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '50%' }}>
          <Label htmlFor="count">Number of Cards</Label>
          <Input type="number" {...register('count')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.count?.message}
          </Text>
        </Flex>
      </Flex>

      <Text as="h5" css={{ my: '$24' }}>
        Shipping Details
      </Text>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="firstName">First Name</Label>
          <Input type="text" {...register('firstName')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.firstName?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label htmlFor="lastName">Last Name</Label>
          <Input type="text" {...register('lastName')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.lastName?.message}
          </Text>
        </Flex>
      </Flex>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="address1">Address 1</Label>
          <Input type="text" {...register('address1')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.address1?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" css={{ width: '100%' }}>
          <Label htmlFor="address2">Address 2</Label>
          <Input type="text" {...register('address2')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.address2?.message}
          </Text>
        </Flex>
      </Flex>

      <Flex css={{ mb: '$12' }}>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="city">City</Label>
          <Input
            type="text"
            {...register('city')}
            css={{ width: '270px', mt: '$4' }}
          />
          <Text color="alert" size="sm">
            {errors?.city?.message}
          </Text>
        </Flex>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$8', mt: '$4' }}
        >
          <Label htmlFor="state">State</Label>
          <Select
            options={US_STATES.map(state => ({ label: state, value: state }))}
            value={watch('state')}
            onChange={val => setValue('state', val)}
          />
          <Text color="alert" size="sm">
            {errors?.state?.message}
          </Text>
        </Flex>
        <Flex
          direction="column"
          align="start"
          css={{ width: '100%', mr: '$12' }}
        >
          <Label htmlFor="zip">Zip</Label>
          <Input type="text" {...register('zip')} css={{ mt: '$4' }} />
          <Text color="alert" size="sm">
            {errors?.zip?.message}
          </Text>
        </Flex>
      </Flex>
      <Flex css={{ width: '100%' }}>
        <Button mode="filled" type="submit" css={{ ml: 'auto' }}>
          Continue
        </Button>
      </Flex>
    </form>
  )
}

export default InstantCardsOrderForm
