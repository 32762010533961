import { FC } from 'react'
import { Alert } from '@/common/alert'
import { Card } from '@/common/card'
import { InitiationUserRecord } from '@/types/create-accounts'

interface Props {
  record: InitiationUserRecord
}

export const AccountFailure: FC<Props> = ({ record }) => {
  if (!record.status_message) {
    return (
      <Alert type="danger" css={{ mt: '$8' }}>
        No failure reasons provided.
      </Alert>
    )
  }

  if (!record.status_message.startsWith('inquiry_status')) {
    return (
      <Alert type="danger" css={{ mt: '$8' }}>
        {record.status_message}
      </Alert>
    )
  }

  const reasons = record.status_message.split(/\n/).map(reason => {
    const field = reason.split(': ')
    const key = field[0]
    const values = field[1].split(', ')
    return {
      key,
      values,
    }
  })

  return (
    <Card>
      <div>
        {reasons.map((reason, i) => (
          <>
            <pre className="mb-1" key={reason.key}>
              {reason.key}
            </pre>
            {reason.values.map(val => (
              <ul className="mb-0" key={val}>
                <li>{val}</li>
              </ul>
            ))}
            {reasons.length - 1 > i && <hr className="my-2" />}
          </>
        ))}
      </div>
    </Card>
  )
}
