import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Text } from '@/common/text'
import { AuthDropdown } from './AuthDropdown'

export type AuthenticatePinFormValues = {
  pin: string
}

interface Props {
  phone: string
  isAuthenticating: boolean
  onSubmit: (pin: string) => void
  handleSendPin: (phone: string) => void
  setShowPinAuth: (show: boolean) => void
}

const schema = yup.object().shape({
  pin: yup
    .string()
    .matches(/^[0-9]*$/, 'Pin should only contain numbers.')
    .max(6)
    .required(),
})

const defaultValues = {
  pin: '',
}

export const AuthenticatePinForm: FC<Props> = ({
  phone,
  isAuthenticating,
  onSubmit,
  handleSendPin,
  setShowPinAuth,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthenticatePinFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const handlePinSubmit = (data: AuthenticatePinFormValues) => {
    onSubmit(data.pin)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(handlePinSubmit)}>
        <Text as="h3" css={{ pb: '$24' }}>
          Enter the pin we sent you.
        </Text>
        <Label htmlFor="pin">Pin sent to {phone}</Label>
        <Input type="text" {...register('pin')} />
        <Text color="alert">{errors?.pin?.message}</Text>
        <Button
          disabled={isAuthenticating}
          type="submit"
          mode="filled"
          display="block"
          css={{
            mt: '$12',
            width: '100%',
          }}
        >
          Log In
        </Button>
      </form>
      <AuthDropdown
        setShowPinAuth={setShowPinAuth}
        isAuthenticating={isAuthenticating}
        handleSendPin={handleSendPin}
        phone={phone}
      />
    </div>
  )
}
