const UnauthorizedUserCard = () => {
  return (
    <>
      <h3 className="pb-3">
        Your account has been created, but you don't have the correct
        permissions to use Pay Admin yet.
      </h3>
      <p>
        To gain access, contact your Branch CSM and they'll give you a hand.
      </p>
    </>
  )
}

export default UnauthorizedUserCard
