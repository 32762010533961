import { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Button } from '@/common/button'
import {
  ColumnPreview,
  MatchCard,
  QuestionCard,
} from '@/components/spreadsheet-mapper'
import {
  PreviewColumn,
  useFilePreview,
} from '@/components/spreadsheet-mapper/useFilePreview'
import {
  MatchObject,
  useMatchStore,
} from '@/components/spreadsheet-mapper/useMatchStore'
import {
  Step,
  StepAside,
  StepBody,
  StepDescription,
  StepFooter,
  StepFooterLeft,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { matchers, questions } from '../lib/csvMatch'

interface IProps {
  handleComplete: (matches: MatchObject) => void
  closeModal: () => void
  file: File
  transition?: string
}

export const ColumnMatchStep = (props: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const { handleComplete, closeModal, transition, file } = props
  const {
    setMatch,
    hasAllMatchesSet,
    hasAnsweredAllQuestions,
    setAnswer,
    itemRows,
    matchRows,
    mapMatchesToObj,
  } = useMatchStore(matchers, questions)
  const columns = useFilePreview(file)

  const onMatch = (csvField: string) => () => {
    if (itemRows.length > currentIndex) {
      setMatch(itemRows[currentIndex].key, csvField)

      setCurrentIndex(currentIndex + 1)
    }
  }

  const onAnswer = (value: any) => {
    setAnswer(itemRows[currentIndex].key, value)
    setCurrentIndex(currentIndex + 1)
  }

  const getMatchLabel = (csvField: string) => {
    const match = matchRows.find(m => m.value === csvField)
    return match ? match.label : null
  }

  const disableMatchInteractions =
    itemRows.length > currentIndex && itemRows[currentIndex].type !== 'match'

  return (
    <Step state={transition}>
      <StepBody hasAside>
        <StepTitle>
          Let{"'"}s make sure we{"'"}re mapping your columns correctly.
        </StepTitle>
        <StepDescription>
          We just need to ask a few questions in order to understand how to
          parse your file. On the right, you{"'"}ll find the columns from your
          file.
        </StepDescription>
        {itemRows.map((item, index) => {
          if (item.type === 'question') {
            return (
              <QuestionCard
                key={item.key}
                question={item as any}
                index={index}
                active={currentIndex === index}
                handleAnswer={onAnswer}
                handleClick={() => setCurrentIndex(index)}
              />
            )
          }
          if (item.type === 'match' && hasAnsweredAllQuestions) {
            return (
              <MatchCard
                key={item.key}
                match={item as any}
                index={index}
                active={currentIndex === index}
                lastIndex={itemRows.length - 1}
                handleClick={() => setCurrentIndex(index)}
              />
            )
          }
          return null
        })}
        {hasAllMatchesSet && (
          <Card className="mt-4">
            <CardBody>
              Great, you{"'"}re all set! When you{"'"}re ready, click below to
              process your roster.
            </CardBody>
          </Card>
        )}
      </StepBody>

      <StepAside>
        <p className="text-white font-weight-bold">YOUR COLUMNS</p>
        {columns.map((col: PreviewColumn) => (
          <ColumnPreview
            key={col.field}
            column={col}
            matchLabel={getMatchLabel(col.field)}
            disableInteraction={disableMatchInteractions}
            handleMatch={onMatch(col.field)}
          />
        ))}
      </StepAside>

      <StepFooter>
        <StepFooterLeft>
          <Button mode="outline" onClick={closeModal}>
            Cancel
          </Button>
        </StepFooterLeft>
        <StepFooterRight>
          <Button
            mode="filled"
            onClick={() => handleComplete(mapMatchesToObj())}
            disabled={!hasAllMatchesSet}
          >
            Process Time and Attendance
          </Button>
        </StepFooterRight>
      </StepFooter>
    </Step>
  )
}
