import { DownloadIcon } from '@radix-ui/react-icons'
import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { InputDropzone } from '@/components/file-upload/InputDropzone'
import { useBulkQualifyEmployeesMutation } from '@/src/queries/create-accounts'
import { useModalStore } from '@/store/modal'
import styles from '../index.module.scss'
import { downloadBulkQualifyTemplate } from '../lib/downloadBulkQualifyTemplate'
import { BulkQualifyUploadSteps } from './BulkQualifyUploadSteps'
import LetterIcon from './LetterIcon'

const COLUMN_HEADERS = [
  'First Name',
  'Last Name',
  "Worker's ID",
  'Birthdate (YYYY-MM-DD)',
  'SSN',
  'Email',
  'Phone',
  'Address 1',
  'Address 2',
  'City',
  'State',
  'ZIP',
  'EIN',
  'Business Name',
]

export const BulkQualifyUploadModal = () => {
  const { registerModal, removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const [files, setFiles] = useState<File[]>([])
  // const [orderCard, setOrderCard] = useState(false)
  const [createEmployee, setCreateEmployee] = useState(true)
  const mutation = useBulkQualifyEmployeesMutation()

  const handleDrop = async (files: File[]) => setFiles(files)

  const handleReview = async () => {
    close()
    registerModal(
      <BulkQualifyUploadSteps columnHeaders={COLUMN_HEADERS} files={files} />
    )
  }
  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Modal isOpen={open} toggle={close} centered backdrop="static">
      <ModalHeader toggle={close} tag="h2">
        Bulk Upload
      </ModalHeader>
      <ModalBody>
        <Text>
          We’ll need their first name, last name, date of birth, full social
          security number, full street address, phone number, and email address.
        </Text>
        <Text color="alert" size="md">
          Note: PO Box addresses are not allowed for successful wallet creation.
        </Text>
        <Flex justify="end">
          <Button
            iconLeft
            size="sm"
            mode="outline"
            color="primary"
            css={{ mt: '$16' }}
            onClick={downloadBulkQualifyTemplate}
          >
            <DownloadIcon />
            Download Template
          </Button>
        </Flex>

        <InputDropzone
          handleDrop={handleDrop}
          files={files}
          allowMultiple={false}
          label="Upload file (Drag n Drop or Select)"
          hasError={false}
          error=""
          supportedFileTypes={['csv']}
          subtext="Accepts CSV"
        />

        <Label css={{ mt: '$4', mb: '$16', display: 'block' }}>
          <input
            style={{ transform: 'translateY(2px)' }}
            type="checkbox"
            onChange={() => setCreateEmployee(!createEmployee)}
            checked={createEmployee}
          />{' '}
          If no roster record exists, create one
        </Label>

        <div className={styles.QualificationCallout}>
          <div className={styles.QualificationCalloutIcon}>
            <LetterIcon />
          </div>
          <p className="mb-0">
            If an individual qualifies, a Branch account will be opened, an
            invite link will be sent via SMS/email, and a debit card may be
            shipped to their associated address. By confirming, you agree to
            have their permission and that all qualified individuals may receive
            a Branch debit card.
          </p>
        </div>

        {mutation.error && (
          <Alert color="danger" css={{ mt: '$8' }}>
            Upload failed. ({mutation.error.message})
          </Alert>
        )}

        <div className="d-flex justify-content-between align-items-center">
          <Button
            mode="filled"
            css={{ ml: 'auto' }}
            disabled={files.length < 1}
            onClick={handleReview}
          >
            Review
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
