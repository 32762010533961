import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { styled } from '@/styles/stitches.config'

export const TooltipContent = styled(TooltipPrimitive.Content, {
  color: '$secondary900',
  backgroundColor: '$secondary100',
  borderRadius: '$sm',
  padding: '$8',
  fontSize: 14,
  maxWidth: '300px',
  zIndex: 1001,
})

export const TooltipArrow = styled(TooltipPrimitive.Arrow, {
  fill: '$secondary100',
})

export const TooltipTrigger = styled(TooltipPrimitive.Trigger, {
  border: 'none',
  background: 'transparent',
})

export type TooltipContentProps = TooltipPrimitive.TooltipContentProps
