import moment from 'moment'
import { FC, useMemo } from 'react'
import { IconButton } from '@/components/common/button'
import { MetaDataSummary } from '@/components/payouts/components/MetaDataSummary'
import { StatusBadge } from '@/components/payouts/components/StatusBadge'
import { ViewPaybackLink } from '@/components/payouts/components/ViewPaybackLink'
import { toDollar } from '@/lib/currency'
import { useAdminUsersQuery } from '@/queries/admin-users'
import { Admin } from '@/types/organization'
import { PayoutRecord, PayoutStatus } from '@/types/payouts'
import { CloseIcon } from '../icons'
import { DetailButton } from './components/DetailButton'
import { DetailProperty } from './components/DetailProperty'
import {
  DetailActions,
  DetailHeader,
  DetailHeading,
  DetailSection,
  DetailSeperator,
} from './components/styles'

interface Props {
  payout: PayoutRecord
  handleNameClick: () => void
  remove: () => void
}

export const PayoutDetails: FC<Props> = ({
  payout,
  handleNameClick,
  remove,
}) => {
  const admins = useAdminUsersQuery()

  const { created_by, system_created } = payout

  const creator = useMemo(() => {
    const admin = (admins.data || []).find(
      (a: Admin) => a.id === `${created_by}`
    )
    if (admin) return `${admin.first_name} ${admin.last_name}`
  }, [admins.data, created_by])

  const author = created_by
    ? created_by === -1
      ? 'System'
      : creator || `ID ${created_by} (name not found)`
    : 'Unknown'

  const created = system_created ? 'Via file' : 'Manually'

  const disbursementTime = moment(payout.disbursement_time)
  const createdTime = moment(payout.time_created)
  const scheduledDate = disbursementTime.isSame(createdTime)
    ? 'Instant'
    : disbursementTime.format()

  const payoutRecordName = useMemo(
    () =>
      payout.roster_record.first_name
        ? `${payout.roster_record.first_name} ${payout.roster_record.last_name}`
        : '',
    [payout]
  )

  return (
    <>
      <DetailHeader>
        <DetailHeading>Disbursement Details</DetailHeading>
        <DetailActions>
          <IconButton onClick={remove}>
            <CloseIcon />
          </IconButton>
        </DetailActions>
      </DetailHeader>

      <DetailSeperator />

      <DetailSection>
        <DetailProperty
          label="Created Date"
          value={createdTime.format()}
          canCopy
        />
        <DetailProperty label="Scheduled Date" value={scheduledDate} canCopy />

        <DetailProperty
          label="Worker ID"
          value={payout.roster_record.employee_id}
          canCopy
        />

        <DetailProperty
          label="Transaction ID"
          value={payout.external_id}
          canCopy
        />

        <DetailProperty
          label="Name"
          value={payoutRecordName}
          renderValue={() => (
            <DetailButton handleClick={handleNameClick}>
              {payoutRecordName}
            </DetailButton>
          )}
        />

        <DetailProperty label="Amount" value={toDollar(payout.amount)} />

        <DetailProperty label="Type" value={payout.type} />

        <DetailProperty
          label="Store ID"
          value={payout.external_store_id}
          canCopy
        />

        {payout.invoice_date && (
          <DetailProperty
            label="Invoice Date"
            value={payout.invoice_date && moment(payout.invoice_date).format()}
          />
        )}

        <DetailProperty label="Created" value={created} />
        <DetailProperty label="Author" value={author} />
        <DetailProperty label="Description" value={payout.description} />

        <DetailProperty
          label="Status"
          value={payout.status}
          renderValue={() => <StatusBadge payout={payout} badgeSize="sm" />}
        />

        {(payout.status === PayoutStatus.FAILED ||
          payout.status === PayoutStatus.CANCELLED) && (
          <DetailProperty
            label="Status Reason"
            value={payout.status_reason || 'No reason provided'}
          />
        )}

        <DetailProperty
          label="Invoice"
          value={payout.payback_id}
          renderValue={() => <ViewPaybackLink payout={payout} />}
        />
      </DetailSection>
      <DetailSection>
        <MetaDataSummary payout={payout} />
      </DetailSection>
    </>
  )
}
