import { calculateSvgSize } from '@/src/lib/svgSize'
import { THEME } from '@/src/lib/theme'

type Props = {
  fill: 'primary' | 'white' | 'dark'
  className?: string
  width?: number
  height?: number
}

const fills = {
  primary: THEME.primaryColor,
  white: '#FFFFFF',
  dark: '#212121',
}

const LogoMark = ({ fill, width, height, ...rest }: Props) => {
  const viewBoxSize = {
    width: 46,
    height: 74,
  }
  const size = calculateSvgSize(viewBoxSize, { width, height })

  return (
    <svg
      width={`${size.width}px`}
      height={`${size.height}px`}
      viewBox={`0 0 ${viewBoxSize.width} ${viewBoxSize.height}`}
      {...rest}
    >
      <title>Branch</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M25.338,65.731 L25.338,44.959 L37.915,34.922 C39.548,38.62 40.568,41.624 40.568,43.331 C40.568,50.799 38.341,53.961 25.338,65.731 Z M5.054,43.331 C5.054,38.525 13.131,23.446 20.284,11.465 L20.284,65.731 C7.282,53.961 5.054,50.799 5.054,43.331 Z M25.338,11.465 C28.942,17.502 32.779,24.322 35.701,30.222 L25.338,38.493 L25.338,11.465 Z M24.945,1.172 C24.481,0.442 23.676,0 22.811,0 C21.946,0 21.141,0.442 20.677,1.172 C18.561,4.507 0,34.02 0,43.331 C0,53.964 4.38,58.318 21.127,73.283 C21.607,73.712 22.209,73.926 22.811,73.926 C23.413,73.926 24.015,73.712 24.495,73.283 C41.242,58.318 45.623,53.964 45.623,43.331 C45.623,34.02 27.061,4.507 24.945,1.172 L24.945,1.172 Z"
          fill={fills[fill]}
        />
      </g>
    </svg>
  )
}

LogoMark.defaultProps = {
  fill: 'primary',
}

export default LogoMark
