import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { Table } from '@/common/table'
import { useSetting } from '@/hooks/useSetting'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useWorkersPeriodEarnings } from '@/queries/employees'
import { ROUTES } from '@/router/routes'
import { useGrossEarningRecordsStore } from '@/store/gross-earning-records'
import { GrossEarningRecordsContainer } from './components/GrossEarningRecordsContainer'
import { columns } from './lib/columns'

export const GrossEarningRecords = () => {
  const isUsingGrossEarnings = useSetting('PERIOD_EARNINGS_FOR_EARNED_WAGES')
  const history = useHistory()
  const {
    filters,
    updateFilters,
    sorted,
    pageSize,
    page,
    setSorted,
    updatePage,
    updatePageSize,
    defaultSort,
  } = useGrossEarningRecordsStore()

  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
  }, [queryParams, updateFilters])

  const periodEarningRecords = useWorkersPeriodEarnings()

  const records = useMemo(() => periodEarningRecords.data?.content || [], [
    periodEarningRecords,
  ])

  useEffect(() => {
    if (isUsingGrossEarnings === 'false') history.push(ROUTES.TIMEKEEPING)
  }, [history, isUsingGrossEarnings])

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    periodEarningRecords.data?.total_pages
  )

  return (
    <GrossEarningRecordsContainer>
      <Table
        columns={columns}
        data={records}
        controlledPagination={true}
        onSortedChange={setSorted}
        sorted={sorted}
        initialSortedState={defaultSort}
        isLoading={periodEarningRecords.isLoading}
        page={page}
        pageRows={pageSize}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        changePage={updatePage}
        updatePageSize={updatePageSize}
      />
    </GrossEarningRecordsContainer>
  )
}
