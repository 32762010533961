import Cookies from 'js-cookie'

// local env only
const tokenCookieKey = 'token'

export const setTokenCookie = (val: string): string => {
  // replace Bearer if it exists in the string
  const token = val.replace('Bearer ', '')
  Cookies.set(tokenCookieKey, token, {
    secure: false,
    sameSite: 'strict',
  })
  return val
}

export const getTokenCookie = (): string | undefined => {
  return Cookies.get(tokenCookieKey)
}

export const removeTokenCookie = (): void => {
  return Cookies.remove(tokenCookieKey)
}

const userIdCookieKey = 'userId'

export const setUserIdCookie = (userId: string): string => {
  Cookies.set(userIdCookieKey, userId, {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
  })
  return userId
}

function jwtDecode(t: string) {
  const token: any = {}
  token.raw = t
  token.header = JSON.parse(window.atob(t.split('.')[0]))
  token.payload = JSON.parse(window.atob(t.split('.')[1]))
  return token
}

export const getUserIdCookie = (): string | null => {
  return Cookies.get(userIdCookieKey) || null
}

export const removeUserIdCookie = (): void => {
  return Cookies.remove(userIdCookieKey)
}

export const parseToken = (token?: string) => {
  return !!token ? jwtDecode(token) : null
}

export const getUserIdFromTokenString = (token: string) => {
  const parsed = parseToken(token)
  return !!parsed ? parsed.payload.sub : null
}
