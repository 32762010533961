import { ExitIcon, GearIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { IconButton } from '@/common/button'
import { FlexWrap } from '@/common/layout'
import PopupHeadIcons from '@/common/popover/PopupHeadIcons'
import { useLogoutQuery } from '@/queries/auth'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { useUser } from '@/store/user'
import { IOrganization } from '@/types/organization'
import { OrganizationDropdown } from './OrganizationDropdown'
import { OrgCreationModal } from './OrgCreationModal'

interface Props {
  setPopoverOpen: (open: boolean) => void
}

export const OrganizationNavigator: FC<Props> = ({ setPopoverOpen }) => {
  const history = useHistory()
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const settingsPath = useOrgPath(ROUTES.SETTINGS)
  const name = useUser(state => state.user?.name)

  const logout = useLogoutQuery()

  const addOrganization = () => {
    registerModal(<OrgCreationModal />)
    setPopoverOpen(false)
  }

  const handleClick = (organization: IOrganization) => {
    history.replace(`/${organization.org_key}/workers`)
    setPopoverOpen(false)
    window.location.reload()
  }

  const handleLogOut = () => {
    logout.mutate()
  }

  return (
    <>
      <FlexWrap css={{ width: '100%', mt: '$16' }}>
        <div>
          <span className="d-block p-2">Hi, {name}</span>
        </div>
        <PopupHeadIcons>
          <IconButton
            tooltip="Settings"
            onClick={() => {
              history.push(settingsPath)
              setPopoverOpen(false)
            }}
          >
            <GearIcon />
          </IconButton>
          <IconButton tooltip="Log Out" onClick={handleLogOut}>
            <ExitIcon />
          </IconButton>
        </PopupHeadIcons>
      </FlexWrap>
      <OrganizationDropdown
        isGlobalAdmin={isGlobalAdmin}
        addOrganization={addOrganization}
        onSelect={handleClick}
        size="md"
        isOnNavbar
      />
    </>
  )
}
