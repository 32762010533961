import { useMutation, useQuery, useQueryClient } from 'react-query'
import { shallow } from 'zustand/shallow'
import {
  editRosterUser,
  exportWorkersPeriodEarnings,
  getAdvanceDeductions,
  getEmployeeCardDetails,
  getEnrollmentMatching,
  getEnrollmentRequests,
  getEnrollmentRequestUser,
  getOrganizationEmployees,
  getOrganizationEmployeeStats,
  getRosterEntries,
  getWorker,
  getWorkerDetails,
  getWorkersPeriodEarnings,
  removeRosterRecord,
  removeUser,
  verifyUser,
} from '@/api/employees'
import { GetRosterParams } from '@/api/types/employees'
import { toIsoString } from '@/common/datepicker/utils'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { downloadCSV } from '@/components/records/grossearnings/lib/downloads'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { handleHttpException } from '@/lib/httpException'
import { parsePaginationParams } from '@/lib/queryParams'
import { useEnrollmentRequestedStore } from '@/store/enrollment-requested'
import { useGrossEarningRecordsStore } from '@/store/gross-earning-records'
import { useRosterStore } from '@/store/roster'
import { useToasterStore } from '@/store/toast'
import { IRosterEdits } from '@/types/employees'

export const ROSTER_QUERY = 'roster'

export const useRosterQuery = () => {
  const { id: orgId } = useCurrentOrg()
  const {
    page,
    pageSize,
    filters,
    sorted,
    paginationParams,
    ready,
  } = useRosterStore()

  const baseParams: GetRosterParams = {
    ...filters,
    page: page + 1,
    size: pageSize,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
    version: 'v2',
  }

  const params = paginationParams || baseParams

  return useQuery(
    [ROSTER_QUERY, orgId, params],
    async () => {
      const res = await getOrganizationEmployees(orgId, params)
      return res.data.data
    },
    {
      enabled: ready,
      select: data => parsePaginationParams<GetRosterParams>(data),
    }
  )
}

export const EMPLOYEE_CARD_DETAILS_QUERY = 'employee-card-details'

export const useEmployeeCardDetailsQuery = (employeeId?: string | null) => {
  const { id: orgId } = useCurrentOrg()

  return useQuery(
    [EMPLOYEE_CARD_DETAILS_QUERY, orgId, employeeId],
    async () => {
      const res = await getEmployeeCardDetails(orgId, employeeId)
      return res.data.data
    },
    {
      retry: 0,
      enabled: !!employeeId,
    }
  )
}

type EmployeeSelectQueryParams = {
  name?: string
  employee_id?: string
  name_or_employee_id?: string
}

export const GET_ROSTER_ENTRIES_QUERY = 'roster-entries'

export const useGetRosterEntries = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([GET_ROSTER_ENTRIES_QUERY], async () => {
    const res = await getRosterEntries(orgId)
    return res.data.data
  })
}

export const EMPLOYEE_SELECT_QUERY = 'employee-select'

export const useEmployeeSelectQuery = ({
  name,
  employee_id,
  name_or_employee_id,
}: EmployeeSelectQueryParams) => {
  const { id: orgId } = useCurrentOrg()

  const params: GetRosterParams = {
    page: 1,
    size: 100,
    sort: 'employee_id',
    direction: 'asc',
    name,
    employee_id,
    name_or_employee_id,
    version: 'v2',
  }

  return useQuery([EMPLOYEE_SELECT_QUERY, orgId, params], async () => {
    const res = await getOrganizationEmployees(orgId, params)
    return res.data.data
  })
}

export const GET_WORKER_DETAILS = 'worker-details'

export const useWorkerDetails = (workerId: string) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([GET_WORKER_DETAILS, orgId, workerId], async () => {
    const res = await getWorkerDetails(orgId, workerId)
    return res.data.data
  })
}

export const GET_ENROLLMENT_REQUESTS = 'enrollment-requests'

export const useEnrollmentRequests = () => {
  const { id: orgId } = useCurrentOrg()
  const { filters, pageSize, paginationParams } = useEnrollmentRequestedStore(
    state => ({
      filters: state.filters,
      pageSize: state.pageSize,
      paginationParams: state.paginationParams,
    }),
    shallow
  )

  const defaultParams = { ...filters, size: pageSize }

  const params = paginationParams || defaultParams
  return useQuery([GET_ENROLLMENT_REQUESTS, orgId, params], async () => {
    const res = await getEnrollmentRequests(orgId, params)
    return res.data.data
  })
}

export const SEARCH_ENROLLMENT_REQUESTS = 'search-enrollment-requests'

export const useSearchEnrollmentRequests = (names: string[]) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [SEARCH_ENROLLMENT_REQUESTS, orgId, names],
    async () => {
      const res = await getEnrollmentMatching(orgId, names)
      return res.data.data.content
    },
    {
      enabled: names.length > 0,
    }
  )
}

export const GET_ENROLLMENT_REQUESTED_USER = 'get-enrollment-requested-user'

export const useOrgUser = (userId?: string) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [GET_ENROLLMENT_REQUESTED_USER, orgId, userId],
    async () => {
      const res = await getEnrollmentRequestUser(orgId, userId)
      return res.data.data
    },
    {
      enabled: !!userId,
    }
  )
}

export const GET_WORKER_QUERY = 'worker-query'

export const useGetWorkerQuery = (workerId?: string) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [GET_WORKER_QUERY, workerId],
    async () => {
      const res = await getWorker(orgId, workerId)
      return res.data.data
    },
    {
      enabled: !!workerId,
    }
  )
}

type EditRosterParams = {
  employeeId: string
  edits: IRosterEdits
}

export const EDIT_ROSTER_USER_QUERY = 'edit-roster-user'

export const useEditRosterUser = () => {
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  return useMutation(
    async (params: EditRosterParams) => {
      const { employeeId, edits } = params
      const res = await editRosterUser(employeeId, edits, orgId)
      return res.data.data
    },
    {
      onSuccess: (data, { edits }) => {
        sendAnalyticsEvent('workers', 'edit:success', { ...edits })
        queryClient.invalidateQueries([ROSTER_QUERY, orgId])
        addToast({
          type: 'success',
          title: 'Success!',
          description: `${data.first_name} ${data.last_name}'s profile was edited.`,
        })
      },
      onError: (_, { edits: { first_name, last_name } }) => {
        sendAnalyticsEvent('workers', 'edit:failure', { first_name, last_name })
        addToast({
          type: 'error',
          title: 'Something went wrong.',
          description: `${first_name} ${last_name}'s profile wasn't edited.`,
        })
      },
    }
  )
}

export const EMPLOYEE_STATS_QUERY = 'employee-stats'

export const useEmployeeStatsQuery = () => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([EMPLOYEE_STATS_QUERY, orgId], async () => {
    const res = await getOrganizationEmployeeStats(orgId)
    return res.data.data
  })
}

export const useRemoveRosterRecord = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  return useMutation(
    async (employeeId: string) => {
      const res = await removeRosterRecord(orgId, employeeId)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROSTER_QUERY, orgId])
        addToast({
          type: 'success',
          title: 'Roster record removed',
        })
      },
    }
  )
}

type RemoveUserParams = {
  id: string
  firstName: string
  lastName: string
}

export const useRemoveUser = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()
  return useMutation(
    async (params: RemoveUserParams) => {
      const res = await removeUser(params.id, orgId)
      return res.data.data
    },
    {
      onSuccess: (_, { id, firstName, lastName }) => {
        queryClient.invalidateQueries(GET_ENROLLMENT_REQUESTS)
        sendAnalyticsEvent('workers', 'remove:success', { id })
        addToast({
          type: 'success',
          title: 'User removed',
          description: `${firstName} ${lastName} is now denied access.`,
        })
      },
      onError: (_, { id }) => {
        addToast({
          type: 'error',
          title: 'Failure',
          description: `Failure to revoke access`,
        })
        sendAnalyticsEvent('workers', 'remove:failure', { id })
      },
    }
  )
}

type VerifyUserParams = {
  id: string
  employeeId: string
  firstName: string
  lastName: string
  previousId?: string
}

export const useVerifyUser = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async (params: VerifyUserParams) => {
      const { id, employeeId } = params
      const res = await verifyUser(id, employeeId, orgId)
      return res.data.data
    },
    {
      onSuccess: (_, { id, employeeId, previousId, firstName, lastName }) => {
        queryClient.invalidateQueries(ROSTER_QUERY)
        queryClient.invalidateQueries(GET_ENROLLMENT_REQUESTS)

        if (previousId) {
          sendAnalyticsEvent('workers', 'changeId:success', {
            userId: id,
            newId: employeeId,
            previousId,
          })
        } else {
          sendAnalyticsEvent('workers', 'enroll:success', { id, employeeId })
        }
        addToast({
          type: 'success',
          title: 'User Verified',
          description: `${firstName} ${lastName} is now enrolled.`,
        })
      },
      onError: (_, { id, employeeId }) =>
        sendAnalyticsEvent('workers', 'enroll:failure', { id, employeeId }),
    }
  )
}

export const ADVANCE_DEDUCTIONS_QUERY = 'advance-deductions'

export const useGetAdvanceDeductions = (transferId: string) => {
  const { addToast } = useToasterStore()

  return useQuery(
    [ADVANCE_DEDUCTIONS_QUERY, transferId],
    async () => {
      const res = await getAdvanceDeductions(transferId)
      return res.data.data
    },
    {
      onError: () => {
        addToast({
          type: 'error',
          title: 'Unable to fetch partial deductions',
        })
      },
    }
  )
}

export const WORKERS_PERIOD_EARNINGS_QUERY = 'workers-period-earnings-query'
export const periodEarningsDateFormat = 'YYYY-MM-DDThh:mm:ss[Z]'
export const useWorkersPeriodEarnings = () => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()
  const { filters, sorted, page } = useGrossEarningRecordsStore()
  const { first_name, last_name, employee_id, from, to } = filters

  const fromDate = new Date(from)
  const toDate = new Date(to)

  const params = {
    org_id: orgId,
    start_date: toIsoString(fromDate),
    end_date: toIsoString(toDate),
    first_name,
    last_name,
    employee_ids: employee_id,
    page: page + 1,
    sort_fields: sorted[0].id,
    direction: sorted[0].desc ? 'DESC' : 'ASC',
  }

  return useQuery(
    [WORKERS_PERIOD_EARNINGS_QUERY, params],
    async () => {
      const res = await getWorkersPeriodEarnings(params)
      return res.data.data
    },
    {
      onError: (err: any) => {
        handleHttpException(err, {
          onHttpError: ({ response }) => {
            const errorDetail = response?.data?.meta?.errorDetail
            addToast({
              type: 'error',
              title: 'Gross Earnings Error',
              description: errorDetail,
            })
          },
          onOtherError: () => {
            addToast({
              type: 'error',
              title: 'Gross Earnings Error',
              description: `Failed to fetch gross earnings`,
            })
          },
        })
      },
      enabled: !!filters?.from && !!filters?.to,
      retry: 0,
    }
  )
}

export const WORKERS_PERIOD_EXPORT_EARNINGS_QUERY =
  'workers-period-export-earnings-query'
export const useExportWorkerPeriodEarnings = () => {
  const { addToast } = useToasterStore()
  const { id: orgId, name: orgName } = useCurrentOrg()
  const { filters, sorted } = useGrossEarningRecordsStore()
  const { first_name, last_name, employee_id, from, to } = filters

  const fromDate = new Date(from)
  const toDate = new Date(to)

  sendAnalyticsEvent('payroll', 'download-gross-earnings', filters)

  const params = {
    org_id: orgId,
    start_date: toIsoString(fromDate),
    end_date: toIsoString(toDate),
    first_name,
    last_name,
    employee_ids: employee_id,
    sort_fields: sorted[0].id,
    direction: sorted[0].desc ? 'DESC' : 'ASC',
  }

  return useQuery(
    [WORKERS_PERIOD_EXPORT_EARNINGS_QUERY, params],
    async () => {
      const res = await exportWorkersPeriodEarnings(params)
      downloadCSV(res.data, orgName)
      return res.data
    },
    {
      onSuccess: () =>
        sendAnalyticsEvent(
          'payroll',
          'download-gross-earnings:success',
          filters
        ),
      onError: (err: any) => {
        sendAnalyticsEvent('payroll', 'download-gross-earnings:fail', filters)
        handleHttpException(err, {
          onHttpError: ({ response }) => {
            const errorDetail = response?.data?.meta?.errorDetail
            addToast({
              type: 'error',
              title: 'Gross Earnings Error',
              description: errorDetail,
            })
          },
          onOtherError: () => {
            addToast({
              type: 'error',
              title: 'Gross Earnings Error',
              description: `Failed to download gross earnings`,
            })
          },
        })
      },
      enabled: false,
      retry: 0,
    }
  )
}
