import { useMemo } from 'react'
import { useGroupsQuery } from '@/queries/organizations'
import { useUserOrganizationGroupRestrictions } from '@/queries/permissions'

export type RowSelection = {
  [key: number]: boolean
}

export const useOrgGroups = (
  search: string,
  isInvite?: boolean,
  userId?: string | null
) => {
  const userOrgGroupRestrictions = useUserOrganizationGroupRestrictions(
    userId ? userId : null
  )
  const orgGroupsQuery = useGroupsQuery()

  const orgGroups = useMemo(() => {
    const groupList = orgGroupsQuery.data || []
    if (!search) return groupList
    return groupList.filter(group =>
      group.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [orgGroupsQuery, search])

  const userOrgGroups = useMemo(() => userOrgGroupRestrictions.data || [], [
    userOrgGroupRestrictions,
  ])

  const initialSelectedRows = useMemo(() => {
    if (isInvite) return {}
    const groups = userOrgGroups.map(group => group.organization_group_id)
    const selection = {} as RowSelection
    groups.forEach(id => {
      selection[id] = true
    })
    return selection
  }, [isInvite, userOrgGroups])

  return {
    userOrgGroups,
    orgGroups,
    initialSelectedRows,
  }
}
