import { Button } from '@/common/button'
import { FileUpload } from '@/components/spreadsheet-mapper/FileUpload'
import {
  Step,
  StepAside,
  StepBody,
  StepDescription,
  StepFooter,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { downloadRosterTemplate } from '../lib/downloadRosterTemplate'
import { TemplateExample } from './'

interface IProps {
  handleComplete: () => void
  handleFile: (file?: File) => void
  file?: File
  transition?: string
}

export const FileUploadStep = (props: IProps) => {
  const { handleComplete, transition, handleFile, file } = props

  return (
    <Step state={transition}>
      <StepBody hasAside>
        <StepTitle>Importing your roster is easy with Branch.</StepTitle>
        <StepDescription>
          All we need is a CSV with a row for each individual{`'`}s First Name,
          Last Name, and ID.{' '}
          <span className="link" onClick={downloadRosterTemplate}>
            Download our template
          </span>{' '}
          to get started.
        </StepDescription>
        <p className="font-weight-bold">Here{`'`}s an example:</p>{' '}
        <TemplateExample />
      </StepBody>

      <StepAside>
        <FileUpload file={file} handleDrop={handleFile} />
      </StepAside>

      <StepFooter>
        <StepFooterRight>
          <Button mode="filled" onClick={handleComplete} disabled={!file}>
            Continue
          </Button>
        </StepFooterRight>
      </StepFooter>
    </Step>
  )
}
