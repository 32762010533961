import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
} from '@radix-ui/react-icons'
import {
  ColumnResizeMode,
  flexRender,
  SortingState,
  Table,
} from '@tanstack/react-table'
import { Box, Flex } from '../layout'
import { Resizer, TableColumnHeader } from './styles'

interface Props<TData> {
  table: Table<TData>
  sorting: SortingState
  pinnedLeftColumnPositions?: number[]
  columnResizeMode: ColumnResizeMode
}
export const TableHeader = <TData extends Object>({
  table,
  sorting,
  pinnedLeftColumnPositions = [],
  columnResizeMode,
}: Props<TData>) => (
  <thead>
    {table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header, i) => (
          <TableColumnHeader
            key={header.index}
            colSpan={header.colSpan}
            css={{
              width: header.column.getSize(),
              alignContent: (header.column.columnDef.meta as any)?.align,
            }}
            style={
              table.getState().columnPinning.left?.includes(header.id)
                ? {
                    left: pinnedLeftColumnPositions[i],
                    position: 'sticky',
                    zIndex: 2,
                    border: 'none',
                  }
                : {}
            }
            highlighted={header.id === sorting[0]?.id}
          >
            {header.isPlaceholder ? null : (
              <div>
                <Flex
                  {...{
                    className: header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                  }}
                >
                  <Flex
                    onClick={header.column.getToggleSortingHandler()}
                    justify="between"
                    fullWidth
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    {header.getContext().column.getCanSort() && (
                      <Box css={{ mr: '$4' }}>
                        {{
                          asc: (
                            <ArrowUpIcon
                              style={{
                                transform: 'scale(1.25)',
                              }}
                            />
                          ),
                          desc: (
                            <ArrowDownIcon
                              style={{
                                transform: 'scale(1.25)',
                              }}
                            />
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <CaretSortIcon
                            style={{
                              transform: 'scale(1.5)',
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Flex>
                  <Resizer
                    {...{
                      onMouseDown: header.getResizeHandler(),
                      onTouchStart: header.getResizeHandler(),
                      style: {
                        transform:
                          columnResizeMode === 'onEnd' &&
                          header.column.getIsResizing()
                            ? `translateX(${
                                table.getState().columnSizingInfo.deltaOffset
                              }px)`
                            : '',
                      },
                    }}
                    resizing={header.column.getIsResizing()}
                  />
                </Flex>
              </div>
            )}
          </TableColumnHeader>
        ))}
      </tr>
    ))}
  </thead>
)
