import { Card } from '@/common/card'
import { Flex, FlexWrap } from '@/common/layout'
import { Loader } from '@/common/loader'
import { useLocationSettings } from '@/queries/settings'
import { EwaType } from './EwaType'
import { Passcode } from './Passcode'
import { PasscodeRequirement } from './PasscodeRequirement'
import { SectionSeperator, SectionTitle } from './styles'

export const OrgLocation = () => {
  const { data: settings, isLoading } = useLocationSettings()

  if (isLoading) {
    return <Loader loading />
  }

  return (
    <>
      <Card>
        <EwaType initialValue={settings?.ewa_type} />
      </Card>
      <SectionSeperator />
      <Card>
        <SectionTitle>Passcode</SectionTitle>
        <FlexWrap align="start" direction="row" justify="between">
          <Flex>
            <PasscodeRequirement
              initialValue={settings?.passcode_requirement}
            />
          </Flex>
          <Flex>
            <Passcode initialValue={settings?.passcode} />
          </Flex>
        </FlexWrap>
      </Card>
    </>
  )
}
