import { useQuery } from 'react-query'
import { getCashouts } from '@/api/cash-outs'
import { GetCashoutParams } from '@/api/types/cash-outs'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useCashoutsStore } from '@/store/cashouts'

export const CASHOUTS_QUERY = 'cashouts-query'

export const useCashouts = () => {
  const { page, sorted, filters, ready, pageSize } = useCashoutsStore()
  const { id: orgId } = useCurrentOrg()

  const queryParams: GetCashoutParams = {
    page: page + 1,
    size: pageSize,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
    ...filters,
  }

  return useQuery(
    [CASHOUTS_QUERY, orgId, queryParams],
    async () => {
      const res = await getCashouts(orgId, queryParams)
      return res.data
    },
    {
      enabled: ready,
    }
  )
}
