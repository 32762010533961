const SvgSignupsAllTime = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={48} height={49} {...props}>
    <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <circle fill="#5A3E2A54" cx={24} cy={24} r={24} />
      <path
        d="M16.5 21a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-2.912 9h-.838a.75.75 0 0 1-.75-.75V25.5a4.5 4.5 0 0 1 4.5-4.5.75.75 0 1 1 0 1.5 3 3 0 0 0-3 3V27h.75a.75.75 0 0 1 .744.657L15.662 33H18a.75.75 0 1 1 0 1.5h-3a.75.75 0 0 1-.744-.657l-.668-5.343zM31.5 21a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm3 7.5v-1.5a3 3 0 0 0-3-3 .75.75 0 1 1 0-1.5 4.5 4.5 0 0 1 4.5 4.5v2.25a.75.75 0 0 1-.75.75h-.838l-.668 5.343A.75.75 0 0 1 33 34.5h-3a.75.75 0 1 1 0-1.5h2.338l.668-5.343A.75.75 0 0 1 33.75 27h.75zM24 19.5a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5zm0-1.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm-2.996 17.325L20.32 28.5H18.75a.75.75 0 0 1-.75-.75V25.5a6 6 0 1 1 12 0v2.25a.75.75 0 0 1-.75.75h-1.571l-.683 6.825a.75.75 0 0 1-.746.675h-4.5a.75.75 0 0 1-.746-.675zM27 27h1.5v-1.5a4.5 4.5 0 1 0-9 0V27H21a.75.75 0 0 1 .746.675l.683 6.825h3.142l.683-6.825A.75.75 0 0 1 27 27z"
        fill="#5A3E2A"
      />
    </g>
  </svg>
)

export default SvgSignupsAllTime
