import classnames from 'classnames'
import { Resizable } from 're-resizable'
import { FC } from 'react'
import { useNavigationTree } from '@/hooks/useNavigationTree'

type Props = {
  sidebar?: React.ReactNode
  sidebarSize?: 'sm' | 'md' | 'lg'
}

export const PageViewContainer: FC<Props> = props => {
  const { children, sidebar, sidebarSize = 'sm' } = props
  const submenu = useNavigationTree()
  const classes = classnames('page-view-container', {
    'sidebar-open': !!sidebar,
    'sidebar-md': sidebarSize === 'md',
    'sidebar-lg': sidebarSize === 'lg',
  })

  return (
    <div className={classes}>
      {children}
      {sidebar && (
        <Resizable
          boundsByDirection
          enable={{
            left: true,
          }}
          defaultSize={{
            width: '400px',
            height: '100%',
          }}
          style={{
            position: 'fixed',
          }}
          className={classnames('page-sidebar', { 'no-submenu': !submenu })}
        >
          {sidebar}
        </Resizable>
      )}
    </div>
  )
}
