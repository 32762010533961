import Papa from 'papaparse'
import { useEffect, useState } from 'react'
import { readFileAsText } from '@/lib/asyncReader'
import { FileValidationError } from '@/types/common'

export enum STATUS_REASON {
  REQUIRED_NAME = 'Name field is required',
  REQUIRED_ADDRESS = 'Address field is required',
  INVALID_STATE = 'State is required and must be 2 characters',
  INVALID_ZIP = 'Zip is required and must be in the form of ##### or #####-####',
}

// Qualify CSV columns
// 0 "Storenum",
// 1 "Store",
// 2 "First Name" required,
// 3 "Last Name" required,
// 4 "Address" required,
// 5 "Address 2" optional,
// 6 "City" required,
// 7 "State" required, min and max length 2 and no spaces,
// 8 "Zip" required regexp = "^\\d{5}(-\\d{4}){0,1}$", message = "Zip must be in the form ##### or #####-####",

const notBlankOrNull = (value: string) => !!value
const isValidZip = (value: string) =>
  notBlankOrNull(value) && /^\d{5}(-\d{4}){0,1}$/.test(value)
const isValidState = (value: string) =>
  notBlankOrNull(value) && value.length === 2

const validateRow = (value: string, index: number) => {
  let reason = ''
  switch (index) {
    case 2:
    case 3:
      if (!notBlankOrNull(value)) reason = STATUS_REASON.REQUIRED_NAME
      return reason
    case 4:
    case 6:
      if (!notBlankOrNull(value)) reason = STATUS_REASON.REQUIRED_ADDRESS
      return reason
    case 7:
      if (!isValidState(value)) reason = STATUS_REASON.INVALID_STATE
      return reason
    case 8:
      if (!isValidZip(value)) reason = STATUS_REASON.INVALID_ZIP
      return reason
    default:
      return reason
  }
}

export const useValidateCsvData = (files: File[]) => {
  const [data, setData] = useState<FileValidationError[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function validate() {
      if (!files.length) return
      const tmpErrors: FileValidationError[] = []
      const fileText = await readFileAsText(files[0])
      const { data: parsedData } = Papa.parse<string[]>(fileText)

      parsedData.slice(1).forEach((row, line) => {
        const failed: FileValidationError = {
          original_csv_line: line + 2,
          original_csv_row: row.join(),
          status: 'FAILED',
          status_reason: '',
        }

        for (let i in row) {
          failed.status_reason = validateRow(row[i], +i)
          if (failed.status_reason) break
        }

        if (failed.status_reason && row.length > 1) {
          tmpErrors.push(failed)
        }
      })
      setData(tmpErrors)
      setIsLoading(false)
    }
    validate()
  }, [files])

  return { data, isLoading }
}
