import { FC } from 'react'
import { Button } from '@/common/button'
import {
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'
import { useModalStore } from '@/store/modal'
import { ConfigureRoleModal } from './ConfigureRoleModal'

export const RolesPermissionsContainer: FC = ({ children }) => {
  const { registerModal } = useModalStore()

  const openCreateRoleModal = () => {
    registerModal(<ConfigureRoleModal isCreate />)
  }

  return (
    <SettingsContainer>
      <PageTop simple>
        <PageHeader>
          <PageTitle primary="Roles & Permissions" />
          <PageActions>
            <Button
              mode="filled"
              css={{ mr: '$4' }}
              onClick={openCreateRoleModal}
            >
              Create Role
            </Button>
          </PageActions>
        </PageHeader>
      </PageTop>
      <PageBody>{children}</PageBody>
    </SettingsContainer>
  )
}
