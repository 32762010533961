import { FC } from 'react'
import { Badge, BadgeSize, BadgeTypes } from '@/common/badge'
import { Tooltip } from '@/components/common/tooltip'
import { PaybackRecord, PaybackStatus } from '@/types/paybacks'

interface Props {
  payback: PaybackRecord
  badgeSize?: BadgeSize
}

const getBadgeColor = (status: PaybackStatus): BadgeTypes => {
  switch (status) {
    case PaybackStatus.OPEN:
      return 'secondary'
    case PaybackStatus.CLOSED:
      return 'info'
    case PaybackStatus.FINALIZED:
      return 'info'
    case PaybackStatus.BILLED:
      return 'info'
    case PaybackStatus.COMPLETED:
      return 'success'
    case PaybackStatus.FAILED:
      return 'danger'
    default:
      return 'info'
  }
}

const description: Record<PaybackStatus, string> = {
  OPEN: 'Invoice is open and pending final amount',
  CLOSED: 'Invoice is closed',
  FINALIZED: 'Invoice amount has been finalized',
  BILLED: 'Invoice is awaiting processing',
  COMPLETED: 'Invoice has been paid',
  FAILED: 'Invoice failed',
}

export const StatusBadge: FC<Props> = ({ payback: { status }, badgeSize }) => {
  const badgeColor = getBadgeColor(status)

  return (
    <Tooltip
      trigger={
        <Badge
          type={badgeColor}
          css={{ textTransform: 'capitalize' }}
          size={badgeSize}
        >
          {status.toLowerCase()}
        </Badge>
      }
    >
      {description[status]}
    </Tooltip>
  )
}
