import { useLocation } from 'react-router'
import { TabsLinkItem } from '@/components/layout/tabs'
import { useIsOrg } from '@/hooks/useIsOrg'
import { NavItem } from '@/router/menus'
import useOrgPath from '@/router/useOrgPath'

type Props = {
  item: NavItem
  compact: boolean
}
export const SubNavItem: React.FC<Props> = ({ item, compact }) => {
  const location = useLocation()
  const urlPath = useOrgPath(item.route)
  const isActive = location.pathname === urlPath
  const { isUberOrg } = useIsOrg()

  const label =
    isUberOrg && item.label === 'Advances' ? 'Backup Balances' : item.label

  return (
    <TabsLinkItem
      isActive={isActive}
      to={urlPath}
      isHidden={item.isHidden}
      text={label}
      compact={compact}
    />
  )
}
