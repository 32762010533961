import { createElement } from 'react'
import Spinner from './Spinner'

interface IProps {
  loading: boolean
  component?: any
  children?: any
}

const Loader = (props: IProps) => {
  const { component = Spinner, loading, children } = props
  return loading ? createElement(component) : children
}

export default Loader
