import { FC } from 'react'
import { Badge } from '@/common/badge'
import { TRemittanceStatus } from '@/types/remittances'

interface Props {
  value: TRemittanceStatus
}

// NOTES
// when status is completed, but invoice doesn't exist
// it means the remittance was completed in legacy mode
// and is paid

export const RemittanceStatusBadge: FC<Props> = ({ value }) => {
  if (value === 'started') {
    return <Badge type="warning">Outstanding</Badge>
  }

  if (value === 'awaiting payment') {
    return <Badge type="info">Needs payment</Badge>
  }

  if (value === 'completed') {
    return <Badge type="success">Completed</Badge>
  }

  if (value === 'processing') {
    return <Badge type="info">Processing</Badge>
  }

  return <Badge type="info">{value}</Badge>
}
