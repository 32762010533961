import qs from 'qs'
import {
  AdminInviteRequest,
  AssignUserRoleRequest,
  CreateUserRoleRequest,
  GetPermissions,
  GetUserRolesParams,
  OrgAdmins,
  OrganizationRole,
  OrgPermissionsResponse,
  UpdateRoleRequest,
  UserOrgGroupRestriction,
  UserOrgGroupsResponse,
  UserPermission,
  UserRole,
} from '@/types/permissions'
import http from './http'

// GLOBAL
export const assignPermission = (roleId: number, permissionId: number) =>
  http.put(`authorization/roles/${roleId}/permissions/${permissionId}`)

// USER
export const assignUserRoles = (
  orgId: number | null,
  userId: number | null,
  body: AssignUserRoleRequest
) => http.put(`authorization/organizations/${orgId}/users/${userId}`, body)

export const removeUserRole = (orgId: number, roleId: number, userId: number) =>
  http.delete(
    `authorization/organizations/${orgId}/roles/${roleId}/users/${userId}`
  )

export const getUserRoles = (params?: GetUserRolesParams) =>
  http.get<UserRole[]>(`authorization/userrole?${qs.stringify(params)}`)

export const createUserRole = (body: CreateUserRoleRequest) =>
  http.post(`authorization/userrole`, body)

export const getUserPermissions = (userId: string | null) =>
  http.get<UserPermission[]>(`authorization/users/${userId}/permissions`)

export const getUserGroups = (orgId: number | null, userId?: string | null) =>
  http.get<UserOrgGroupsResponse>(
    `authorization/organizations/${orgId}/users/${userId}/groups`
  )

export const getUserGroupRestrictions = (
  orgId: number | null,
  userId?: string | null
) =>
  http.get<UserOrgGroupRestriction[]>(
    `authorization/organizations/${orgId}/users/${userId}/groups/restrictions`
  )

// ORGANIZATION

export const getAdminsForOrg = (orgId: number | null) =>
  http.get<OrgAdmins>(`authorization/organizations/${orgId}/users`)

export const getRoleForOrg = (roleId: number, orgId: number) =>
  http.get<OrganizationRole>(
    `authorization/organizations/${orgId}/roles/${roleId}`
  )

export const getRolesForOrg = (orgId: number | null) =>
  http.get<OrganizationRole[]>(`authorization/organizations/${orgId}/roles`)

export const createRoleForOrg = (
  orgId: number | null,
  body: UpdateRoleRequest
) =>
  http.post<OrganizationRole>(
    `authorization/organizations/${orgId}/roles`,
    body
  )

export const updateRoleForOrg = (
  roleId: number,
  orgId: number | null,
  body: UpdateRoleRequest
) => http.put(`authorization/organizations/${orgId}/roles/${roleId}`, body)

export const removeRoleForOrg = (roleId: number, orgId: number | null) =>
  http.delete(`authorization/organizations/${orgId}/roles/${roleId}`)

export const assignPermissionForOrg = (
  roleId: number,
  orgId: number,
  permissionId: number
) =>
  http.put(
    `authorization/organizations/${orgId}/roles/${roleId}/permissions/${permissionId}`
  )

export const removePermissionForOrg = (
  roleId: number,
  orgId: number,
  permissionId: number
) =>
  http.delete(
    `authorization/organizations/${orgId}/roles/${roleId}/permissions/${permissionId}`
  )

export const getPermissions = (params?: GetPermissions) =>
  http.get<OrgPermissionsResponse>(
    `authorization/permissions?${qs.stringify(params)}`
  )

export const inviteAdmin = (invite: AdminInviteRequest) => {
  return http.post(`p2/invites?org_id=${invite.scope}`, invite)
}
