import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useModalStore } from '@/store/modal'

export const ModalContainer = () => {
  const { modal } = useModalStore()

  return (
    <TransitionGroup>
      {modal && (
        <CSSTransition timeout={300} unmountOnExit>
          {modal}
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}
