import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useCashoutsStore } from '@/store/cashouts'

export const CashOutsFilters: FC = () => {
  const { filters, updateFilters } = useCashoutsStore()
  return (
    <TableFiltersMain
      hasDateRange={false}
      filters={filters}
      updateFilters={updateFilters}
      searchProperty="employee_id"
    />
  )
}
