import { CompleteIcon } from '@/common/icons'
import { humanFileSize } from '@/src/lib/fileSize'
import { ImportCard } from './ImportCard'

interface IProps {
  file: File
}

export const FileImportCard = (props: IProps) => {
  const { file } = props
  return (
    <ImportCard
      title={`Processed ${file.name}`}
      className="mb-4"
      icon={<CompleteIcon />}
      subtitle={humanFileSize(file.size, true)}
    />
  )
}
