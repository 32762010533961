import { Regex24HrClockTimeRange, RegExEmail } from './regex'
import { stripWhitespace } from './strings'

export const isValidCommaDelimitedEmailList = (val?: string) =>
  typeof val === 'string'
    ? stripWhitespace(val)
        .split(',')
        .every((v: string) => v.match(RegExEmail))
    : true

/**
 * Checks if a string is a comma delimited list
 * @param msg The error message to display when invalid
 */
export const isCommaDelimitedEmailList = {
  name: 'is-comma-delimited-email-list',
  exclusive: true,
  message: 'Value must be a comma-separated email address list',
  test: isValidCommaDelimitedEmailList,
}

export const isValidSemiColonDelimited24HrTimeRangeList = (val?: string) => {
  if (val) {
    const timeListArr = stripWhitespace(val).split(';')
    return (
      timeListArr.every((v: string) => v.match(Regex24HrClockTimeRange)) &&
      timeListArr.length === 7
    )
  }
  return false
}

export const isSemiColonDelimited24HrTimeRangeList = {
  name: 'is-semi-colon-delimited-24hr-time-range-list',
  exclusive: true,
  message:
    'Value must be a semi-colon-separated time range list with specified format',
  test: isValidSemiColonDelimited24HrTimeRangeList,
}
