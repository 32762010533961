import { FC, useMemo } from 'react'
import { Card } from '@/common/card'
import ContentEmpty from '@/common/empty'
import { EmptyTableIcon } from '@/common/icons'
import { SimpleTable } from '@/common/table/SimpleTable'
import { IProcessorRow, subcolumns } from '../lib/subcolumns'
import { IRegistrationRow } from '../lib/types'

interface Props {
  row: IRegistrationRow
}

export const RegistrationDetails: FC<Props> = ({ row }) => {
  const subrows: IProcessorRow[] = useMemo(
    () =>
      row.processor_registrations.map(processor => ({
        ...processor,
        registrationId: row.id,
        registrationType: row.type,
      })),
    [row]
  )

  return (
    <Card>
      <div>
        {subrows.length === 0 ? (
          <ContentEmpty
            icon={<EmptyTableIcon />}
            title="You have no active file processors"
            height="lg"
          />
        ) : (
          <SimpleTable data={subrows} columns={subcolumns} />
        )}
      </div>
    </Card>
  )
}
