import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Flex } from '@/common/layout'
import { InitiationUserRecord } from '@/types/create-accounts'
import { AccountActionDropdown } from '../components/AccountActionDropdown'
import { UserStatusBadge } from '../components/UserStatusBadge'
import { showNaIfUndefined } from './utils'

export const createAccountsColumns: ColumnDef<InitiationUserRecord, any>[] = [
  {
    accessorFn: row => moment(row.time_created),
    header: 'Date Created',
    id: 'time_created',
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
  },
  {
    accessorFn: row => showNaIfUndefined(row.employee?.first_name),
    header: 'First Name',
    id: 'first_name',
  },
  {
    accessorFn: row => showNaIfUndefined(row.employee?.last_name),
    header: 'Last Name',
    id: 'last_name',
  },
  {
    accessorFn: row => showNaIfUndefined(row.employee?.employee_id),
    header: 'Worker ID',
    id: 'employee_id',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    id: 'status',
    enableSorting: false,
    cell: ({ row }) => <UserStatusBadge value={row.original.status} />,
  },
  {
    accessorFn: row => moment(row.time_modified || row.time_created),
    header: 'Last Updated',
    id: 'time_modified',
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
    enableSorting: false,
  },
  {
    header: '',
    id: 'actions',
    enableSorting: false,
    size: 50,
    cell: ({ row }) => (
      <Flex justify="center">
        <AccountActionDropdown original={row.original} />
      </Flex>
    ),
  },
]
