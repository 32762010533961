import { ColumnDef } from '@tanstack/react-table'
import { Flex } from '@/common/layout'
import { TableExpander } from '@/common/table'
import { Text } from '@/common/text'
import { Tooltip } from '@/common/tooltip'
import { IRemittanceBalance } from '@/types/remittances'
import AmountCell from '../components/AmountCell'
import { NameCell } from '../components/NameCell'
import { PaidCell } from '../components/PaidCell'

export const columns: ColumnDef<IRemittanceBalance, any>[] = [
  {
    header: 'Name',
    id: 'employee_name',
    accessorKey: 'employee_name',
    cell: ({ row }) => <NameCell original={row.original} />,
  },
  {
    header: 'ID',
    accessorKey: 'employee_id',
  },
  {
    header: () => (
      <Tooltip trigger={<Text>Outstanding</Text>}>
        Total amount owed from current and previous pay periods.
      </Tooltip>
    ),
    accessorKey: 'balance',
    cell: ({ row }) => <AmountCell value={row.original.balance} />,
  },
  {
    header: () => (
      <Tooltip trigger={<Text>Deducted</Text>}>
        Amount successfully deducted from workers earnings.
      </Tooltip>
    ),
    accessorKey: 'balance',
    id: 'paid',
    cell: ({ row }) => <PaidCell original={row.original} />,
  },
  {
    id: 'expand',
    size: 40,
    cell: ({ row }) => (
      <Flex justify="center">
        <div
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer' },
          }}
        >
          <TableExpander isExpanded={row.getIsExpanded()} />
        </div>
      </Flex>
    ),
  },
]
