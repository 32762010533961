import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { Button } from '@/common/button'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { GET_LOCATIONS_QUERY } from '@/queries/location'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { AddLocationsModal } from './AddLocationsModal'

interface Props {
  error: Error
}

export const LocationsContainer: FC<Props> = ({ error, children }) => {
  const queryClient = useQueryClient()
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const openAddLocationsModal = () => {
    registerModal(
      <AddLocationsModal
        handleClose={() => {
          queryClient.invalidateQueries(GET_LOCATIONS_QUERY)
        }}
      />
    )
  }

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Locations" />

          {isGlobalAdmin && (
            <PageActions>
              <Button iconRight mode="filled" onClick={openAddLocationsModal}>
                Add Locations
                <AdminIcon />
              </Button>
            </PageActions>
          )}
        </PageHeader>
      </PageTop>
      <PageBody hasError={error}>{children}</PageBody>
    </>
  )
}
