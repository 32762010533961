import { FC, useState } from 'react'
import { IAddPayoutConfig } from '@/api/types/payouts'
import { Loader } from '@/common/loader'
import { Dialog } from '@/common/modal'
import {
  useAddPayoutMutation,
  usePayoutTypesQuery,
} from '@/src/queries/payouts'
import { useModalStore } from '@/store/modal'
import { useToasterStore } from '@/store/toast'
import { AddPayoutConfirmation } from './AddPayoutConfirmation'
import { AddPayoutForm, AddPayoutFormValues } from './AddPayoutForm'

export const AddPayoutModal: FC = () => {
  const [open, setOpen] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [values, setValues] = useState<AddPayoutFormValues>()
  const { addToast } = useToasterStore()
  const { removeModal } = useModalStore()

  const addPayout = useAddPayoutMutation()
  const payoutTypes = usePayoutTypesQuery()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleSubmit = (values: AddPayoutFormValues) => {
    setValues(values)
    setShowConfirmation(true)
  }

  const handleBack = () => {
    setShowConfirmation(false)
  }

  const handleConfirmed = async () => {
    if (values) {
      try {
        let payoutData: IAddPayoutConfig = {
          employee_id: values.employeeId,
          amount: values.amount,
          type: values.payoutType,
          description: values.description,
          time_scheduled: values.time_scheduled,
        }
        if (values.externalStoreId) {
          payoutData.external_store_id = values.externalStoreId
        }
        if (values.externalId) {
          payoutData.external_id = values.externalId
        }
        if (values.metadata.length > 0) {
          let meta: { [key: string]: string } = {}
          values.metadata.forEach(m => {
            meta[m.type] = m.value
          })
          payoutData.metadata = meta
        }
        await addPayout.mutateAsync(payoutData)
        close()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'An Error Occurred',
          description:
            'Could not add a new disbursement at this time. Contact support.',
        })
      }
    }
  }

  return (
    <Dialog open={open} setOpen={close} title="Add Disbursement">
      <Loader loading={payoutTypes.isLoading}>
        <div className={showConfirmation ? 'd-none' : ''}>
          <AddPayoutForm
            onSubmit={handleSubmit}
            payoutTypes={payoutTypes.data || []}
          />
        </div>
      </Loader>
      {showConfirmation && (
        <AddPayoutConfirmation
          values={values}
          handleSubmit={handleConfirmed}
          handleBack={handleBack}
          loading={addPayout.isLoading}
        />
      )}
    </Dialog>
  )
}
