import { FC, useMemo } from 'react'
import { DateRangePicker } from '@/common/datepicker'
import { CSV_FORMAT_TYPE } from '../button/CSVDownloadForm'
import { DateRangePreset } from '../datepicker/types'
import { Flex } from '../layout'
import { DownloadButton } from './DownloadButton'
import { MoreFiltersMenu } from './MoreFiltersMenu'
import { OptionFilter } from './OptionFilter'
import { SearchFilter } from './SearchFilter'
import { TableFiltersContainer } from './styles'
import { TableFilterPills } from './TableFilterPills'
import {
  AdditionalFilterItem,
  RemoveFilter,
  TableFilters,
  UpdateFilters,
} from './types'
import { useAdditionalFilters } from './useAdditionalFilters'

type StatusOptions = { label: string; value: string | number }[]
type TypeOptions = {
  label: string
  value: string
}[]

interface Props {
  filters: TableFilters
  additionalFilters?: AdditionalFilterItem[]
  typeOptions?: TypeOptions
  statusOptions?: StatusOptions
  searchProperty?: string
  searchPlaceholder?: string
  hasDateRange?: boolean
  downloadLoading?: boolean
  canDownloadData?: boolean
  updateFilters: UpdateFilters
  removeFilter?: RemoveFilter
  handleDownload?: () => void
  handleCSVDownload?: (val?: CSV_FORMAT_TYPE) => void
  disableEnterpriseReporting?: boolean
  allowedDatePickerPresets?: DateRangePreset[]
}

export const TableFiltersMain: FC<Props> = ({
  filters,
  additionalFilters,
  statusOptions,
  typeOptions,
  searchProperty,
  searchPlaceholder,
  hasDateRange = true,
  canDownloadData = true,
  updateFilters,
  removeFilter = id => updateFilters({ [id]: undefined }),
  allowedDatePickerPresets,
  ...downloadProps
}) => {
  const {
    toggleAdditionalFilters,
    additionalFiltersInitialFocusId,
    additionalFiltersOpen,
  } = useAdditionalFilters()

  const searchText = useMemo(() => {
    if (searchPlaceholder) return `Find by ${searchPlaceholder}`
    if (searchProperty)
      return `Find by ${searchProperty
        .toLowerCase()
        .replace('_', '')
        .replace('employee', '')}`
  }, [searchPlaceholder, searchProperty])

  return (
    <TableFiltersContainer>
      {hasDateRange && (
        <Flex css={{ mr: '$12' }}>
          <DateRangePicker
            from={filters.from}
            to={filters.to}
            preset={filters.preset}
            setRange={updateFilters}
            allowedPresets={allowedDatePickerPresets}
          />
        </Flex>
      )}

      {typeOptions && (
        <Flex css={{ mr: '$12' }}>
          <OptionFilter
            options={typeOptions}
            value={filters.type}
            defaultLabel="All types"
            handleChange={val => updateFilters({ type: val })}
          />
        </Flex>
      )}

      {statusOptions && (
        <Flex css={{ mr: '$12' }}>
          <OptionFilter
            options={statusOptions}
            value={filters.status}
            defaultLabel="All statuses"
            handleChange={val => updateFilters({ status: val })}
          />
        </Flex>
      )}

      {additionalFilters && (
        <>
          <Flex css={{ mr: '$12' }}>
            <MoreFiltersMenu
              updateFilters={updateFilters}
              filters={filters}
              menu={additionalFilters}
              open={additionalFiltersOpen}
              initialFocusId={additionalFiltersInitialFocusId}
              setOpen={toggleAdditionalFilters}
            />
          </Flex>

          <Flex css={{ mr: '$12' }}>
            <TableFilterPills
              config={additionalFilters}
              filters={filters}
              handleItemRemove={id => removeFilter(id)}
              handleItemClick={id => toggleAdditionalFilters(true, id)}
            />
          </Flex>
        </>
      )}

      <Flex css={{ ml: 'auto' }}>
        {!!searchProperty && (
          <SearchFilter
            defaultValue={filters[searchProperty]}
            placeholder={searchText}
            handleChange={val => updateFilters({ [searchProperty]: val })}
          />
        )}

        {canDownloadData && <DownloadButton {...downloadProps} />}
      </Flex>
    </TableFiltersContainer>
  )
}
