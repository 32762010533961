import { FC } from 'react'
import { StatBox, StatLabel, StatValue } from './styles'

interface Props {
  label: string
  value: string
  color: string
}

export const Stat: FC<Props> = ({ label, value, color }) => (
  <StatBox css={{ bgAlpha10: color, color }}>
    <StatValue>{value}</StatValue>
    <StatLabel>{label}</StatLabel>
  </StatBox>
)

export default Stat
