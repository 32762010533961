import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useAddRegistrationMutation } from '@/src/queries/file-processing'
import { RegistrationType } from '../lib/types'

interface Props {
  handleClose: () => void
}

export interface AddRegistrationFormValues {
  registrationType: RegistrationType
  target: string
}

const schema = yup.object().shape({
  target: yup.string().required(),
})

export const AddRegistrationForm: React.FC<Props> = ({ handleClose }) => {
  const currentOrg = useCurrentOrg()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<AddRegistrationFormValues>({
    defaultValues: {
      registrationType: RegistrationType.email,
      target: currentOrg.org_key || '',
    },
    resolver: yupResolver(schema),
  })

  const registrationType = watch('registrationType')
  const addRegistration = useAddRegistrationMutation()

  const registrationTypeOptions = [
    { label: 'Email', value: RegistrationType.email },
    { label: 'SFTP', value: RegistrationType.s3 },
  ]

  const onSubmit = (values: AddRegistrationFormValues) => {
    addRegistration.mutate(
      {
        registrationType: values.registrationType,
        target: values.target,
      },
      {
        onSuccess: handleClose,
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box css={{ mb: '$16' }}>
        <Label htmlFor="registrationType">Type</Label>
        <Select
          value={registrationType}
          options={registrationTypeOptions}
          onChange={value => setValue('registrationType', value)}
        />
      </Box>

      <Box css={{ mb: '$16' }}>
        <Label htmlFor="target">
          {registrationType === RegistrationType.email
            ? 'Email Address'
            : 'Directory Name'}{' '}
          Target
        </Label>
        <Input {...register('target')} />
        <Text color="alert">{errors?.target?.message}</Text>
      </Box>

      {addRegistration.isError && (
        <Alert color="danger">
          An error occurred while adding registration. Please contact support.
        </Alert>
      )}
      <Flex justify="end">
        <Button
          mode="filled"
          type="submit"
          disabled={addRegistration.isLoading || !isValid}
        >
          Add Registration
        </Button>
      </Flex>
    </form>
  )
}
