import { useEffect, useMemo, useRef, useState } from 'react'
import { Alert } from '@/common/alert'
import { CheckmarkIcon } from '@/common/icons'
import { Loader } from '@/common/loader'
import {
  SearchDropdownDropdown,
  SearchDropdownInput,
  SearchDropdownList,
  SearchDropdownListItem,
} from '@/components/search-dropdown-field'
import { useDebounce } from '@/hooks/useDebounce'
import { useEmployeeSelectQuery } from '@/queries/employees'
import { Employee } from './Employee'
import { ISelectableEmployee } from './types'

interface Props {
  handleSelect: (employee: ISelectableEmployee) => void
  selected?: ISelectableEmployee
}

export const EmployeeList: React.FC<Props> = ({ handleSelect, selected }) => {
  const [search, setSearch] = useState('')
  const name = useDebounce(search)
  const rosterUsers = useEmployeeSelectQuery({ name: name || undefined })
  const inputRef = useRef<HTMLInputElement>(null)

  const employees: ISelectableEmployee[] = useMemo(
    () =>
      (rosterUsers?.data?.content || []).map(
        ({ first_name, last_name, employee_id }) => ({
          firstName: first_name,
          lastName: last_name,
          id: employee_id as string,
        })
      ),
    [rosterUsers.data]
  )

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  return (
    <SearchDropdownDropdown>
      <SearchDropdownInput
        value={search}
        ref={inputRef}
        placeholder="Search worker"
        onChange={e => setSearch(e.target.value)}
      />
      <Loader loading={rosterUsers.isLoading}>
        <SearchDropdownList>
          {employees.length === 0 && (
            <Alert css={{ mt: '$8' }} type="info">
              No matches.
            </Alert>
          )}
          {employees.map((employee, i) => (
            <SearchDropdownListItem
              key={`${employee.id} ${employee.lastName}`}
              tabIndex={0}
              onClick={() => handleSelect(employee)}
              onKeyPress={() => handleSelect(employee)}
            >
              <Employee employee={employee} />

              {selected?.id === employee.id && <CheckmarkIcon />}
            </SearchDropdownListItem>
          ))}
        </SearchDropdownList>
      </Loader>
    </SearchDropdownDropdown>
  )
}
