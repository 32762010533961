import { create } from 'zustand'
import {
  cacheRemittanceProgress,
  getAdjustmentStatusesFromLocalStorage,
  getCachedRemittanceProgressStep,
  saveAdjustmentsToLocalStorage,
} from '@/components/deduction-report/lib/cache'
import {
  getStepPosition,
  STEP_ORDER,
} from '@/components/deduction-report/lib/stepPosition'
import {
  TEmployeeAdjustments,
  TRemittanceProgressStep,
} from '@/components/deduction-report/types'

type EmployeeAdjustment = { id: string; amount: number }

export type DeductionReport = {
  adjustments: TEmployeeAdjustments
  needsAdjustment: boolean
  currentStep: TRemittanceProgressStep
  remittanceId: string
  pendingDownload: boolean
  computed: {
    getProgressStepPosition: (step: TRemittanceProgressStep) => number
  }
  setNeedsAdjustment: (needAdjustment: boolean) => void
  setRemittanceId: (id: string) => void
  setAdjustmentState: (id: string) => void
  setAdjustmentStatuses: (id: string) => void
  setProgressStepFromCache: (id: string) => void
  updateProgressStep: (step: TRemittanceProgressStep) => void
  clearEmployeeAdjustment: (id: string) => void
  setEmployeeAdjustment: (adjustment: EmployeeAdjustment) => void
}

export const useDeductionReport = create<DeductionReport>((set, get) => ({
  adjustments: {},
  needsAdjustment: false,
  currentStep: 'download',
  remittanceId: '',
  pendingDownload: false,
  setNeedsAdjustment: needsAdjustment => {
    if (!needsAdjustment) {
      const remittanceId = get().remittanceId
      set(state => ({ ...state, adjustments: {} }))
      saveAdjustmentsToLocalStorage(remittanceId, {})
    }
    set(state => ({ ...state, needsAdjustment }))
  },
  setRemittanceId: (remittanceId: string) =>
    set(state => ({ ...state, remittanceId })),
  setAdjustmentState: (id: string) => {
    get().setRemittanceId(id)
    get().setAdjustmentStatuses(id)
    get().setProgressStepFromCache(id)
  },
  setAdjustmentStatuses: (id: string) => {
    const cached = getAdjustmentStatusesFromLocalStorage(id)
    set(state => ({ ...state, adjustments: cached }))

    // the cached object may or may not have values. If it does, set "needsAdjustment" to true
    // automatically, since we know the user has attempted to make adjustments.
    const isEmpty =
      Object.entries(cached).length === 0 && cached.constructor === Object

    if (!isEmpty) {
      set(state => ({ ...state, needAdjustment: true }))
    }
  },
  setProgressStepFromCache: (id: string) => {
    const cachedStep = getCachedRemittanceProgressStep(id)
    if (cachedStep) {
      set(state => ({ ...state, currentStep: cachedStep }))
    } else {
      set(state => ({ ...state, currentStep: 'download' }))
    }
  },
  updateProgressStep: (step: TRemittanceProgressStep) => {
    const remittanceId = get().remittanceId
    const stepPosition = get().computed.getProgressStepPosition(step)
    const isPastStep = stepPosition < 0
    // don't update if the user attempts to navigate back to a previous step.
    if (!isPastStep) {
      set(state => ({ ...state, currentStep: step }))
      cacheRemittanceProgress(remittanceId, step)
    }
  },
  clearEmployeeAdjustment: (id: string) => {
    const adjustments = get().adjustments
    const remittanceId = get().remittanceId
    delete adjustments[id]
    set(state => ({ ...state, adjustments }))
    saveAdjustmentsToLocalStorage(remittanceId, adjustments)
  },
  setEmployeeAdjustment: ({ id, amount }: EmployeeAdjustment) => {
    const adjustments = get().adjustments
    const remittanceId = get().remittanceId
    if (adjustments[id]) {
      adjustments[id].amount = amount
    } else {
      adjustments[id] = {
        amount,
      }
    }
    set(state => ({ ...state, adjustments }))
    saveAdjustmentsToLocalStorage(remittanceId, adjustments)
  },
  computed: {
    get getProgressStepPosition() {
      return (step: TRemittanceProgressStep) => {
        const current = get().currentStep
        return getStepPosition(step, current, STEP_ORDER)
      }
    },
  },
}))
