import { Flex } from '@/common/layout'
import { styled } from '@/styles/stitches.config'

export const DatePickerContainer = styled(Flex, {
  width: '35%',
  mr: '$4',
  '.react-date-picker__wrapper': {
    width: 175,
  },
  variants: {
    mobileFullWidth: {
      true: {
        '@mobile': { width: '100%' },
      },
    },
  },
})
