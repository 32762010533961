import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { OrgSettingsUpdates } from '@/api/types/organization'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Checkbox } from '@/common/checkbox'
import { Input, InputIcon } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { LockedIndicator } from '@/common/locked-indicator/LockedIndicator'
import { Text } from '@/common/text'
import Spinner from '@/components/common/loader/Spinner'
import { convert } from '@/lib/currency'
import { isEmptyObject } from '@/lib/validators'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { usePermissionsStore } from '@/store/permissions'
import { useSettingsStore } from '@/store/settings'

export type DirectDepositValues = {
  ENABLE_DIRECT_DEPOSIT_LOGIN_URL: boolean
  DIRECT_DEPOSIT_LOGIN_URL: string
  DIRECT_DEPOSIT_PORTAL_NAME: string
  PAY_DEFAULT_HOURLY_RATE: number | null
  PAY_DEFAULT_SHIFT_LENGTH: string
  SHIFT_LENGTH_LIMIT: string
  PAY_PERIOD_WAGE_LIMIT: number | string
  SINGLE_DISBURSEMENT_LIMIT: number | null
  ORG_DAILY_DISBURSEMENT_LIMIT: number | null
  DEFAULT_PAY_PERIOD_LENGTH: string
  PAY_WITHDRAWAL_PERCENTAGE: number | string
}

const settingsSchema = Yup.object().shape({
  PAY_WITHDRAWAL_PERCENTAGE: Yup.number()
    .nullable(true)
    .typeError('You must specify a number')
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100')
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  DIRECT_DEPOSIT_LOGIN_URL: Yup.string()
    .url('Must be a valid URL')
    .when(['ENABLE_DIRECT_DEPOSIT_LOGIN_URL'], {
      is: (val: boolean) => !!val,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
  DIRECT_DEPOSIT_PORTAL_NAME: Yup.string().when(
    ['ENABLE_DIRECT_DEPOSIT_LOGIN_URL'],
    {
      is: (val: boolean) => !!val,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }
  ),
  ORG_DAILY_DISBURSEMENT_LIMIT: Yup.number()
    .nullable(true)
    .typeError('You must specify a number')
    .min(1, 'Must be greater than or equal to 1'),
})

export const DirectDeposit = () => {
  const { settings, settingsData, isLoading } = useSettingsStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const updateSettings = useUpdateSettingsMutation()

  const payPeriodWageLimitData = useMemo(
    () => settingsData.find(d => d.name === 'PAY_PERIOD_WAGE_LIMIT'),
    [settingsData]
  )

  const payWithdrawalPercentageData = useMemo(
    () => settingsData.find(d => d.name === 'PAY_WITHDRAWAL_PERCENTAGE'),
    [settingsData]
  )

  const defaultValues = useMemo(
    () => ({
      ENABLE_DIRECT_DEPOSIT_LOGIN_URL:
        settings.ENABLE_DIRECT_DEPOSIT_LOGIN_URL === 'true',
      DIRECT_DEPOSIT_LOGIN_URL: settings.DIRECT_DEPOSIT_LOGIN_URL,
      DIRECT_DEPOSIT_PORTAL_NAME: settings.DIRECT_DEPOSIT_PORTAL_NAME,
      PAY_DEFAULT_HOURLY_RATE: convert(
        0.01,
        settings.PAY_DEFAULT_HOURLY_RATE,
        null
      ),
      PAY_DEFAULT_SHIFT_LENGTH: settings.PAY_DEFAULT_SHIFT_LENGTH || '',
      DEFAULT_PAY_PERIOD_LENGTH:
        settings.DEFAULT_PAY_PERIOD_LENGTH === '0'
          ? ''
          : settings.DEFAULT_PAY_PERIOD_LENGTH,
      SHIFT_LENGTH_LIMIT: settings.SHIFT_LENGTH_LIMIT || '',
      PAY_PERIOD_WAGE_LIMIT: convert(0.01, settings.PAY_PERIOD_WAGE_LIMIT, ''),
      ORG_DAILY_DISBURSEMENT_LIMIT: convert(
        0.01,
        settings.ORG_DAILY_DISBURSEMENT_LIMIT,
        null
      ),
      SINGLE_DISBURSEMENT_LIMIT: convert(
        0.01,
        settings.SINGLE_DISBURSEMENT_LIMIT,
        null
      ),
      PAY_WITHDRAWAL_PERCENTAGE: convert(
        100,
        settings.PAY_WITHDRAWAL_PERCENTAGE,
        ''
      ),
    }),
    [settings]
  )

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(settingsSchema),
  })

  useEffect(() => {
    reset(defaultValues)
  }, [reset, settings, defaultValues])

  const onSubmit = async (values: DirectDepositValues) => {
    const {
      ENABLE_DIRECT_DEPOSIT_LOGIN_URL,
      DIRECT_DEPOSIT_LOGIN_URL,
      DIRECT_DEPOSIT_PORTAL_NAME,
      ...rest
    } = values

    const payload: OrgSettingsUpdates = {
      ...(ENABLE_DIRECT_DEPOSIT_LOGIN_URL && {
        DIRECT_DEPOSIT_LOGIN_URL,
        DIRECT_DEPOSIT_PORTAL_NAME,
      }),
      ENABLE_DIRECT_DEPOSIT_LOGIN_URL,
      ...rest,
      PAY_DEFAULT_HOURLY_RATE: convert(100, values.PAY_DEFAULT_HOURLY_RATE, ''),
      PAY_WITHDRAWAL_PERCENTAGE: convert(
        0.01,
        values.PAY_WITHDRAWAL_PERCENTAGE,
        payWithdrawalPercentageData?.default_value
      ),
      PAY_PERIOD_WAGE_LIMIT: convert(
        100,
        values.PAY_PERIOD_WAGE_LIMIT,
        payPeriodWageLimitData?.default_value
      ),

      SINGLE_DISBURSEMENT_LIMIT: convert(
        100,
        values.SINGLE_DISBURSEMENT_LIMIT,
        ''
      ),
      ORG_DAILY_DISBURSEMENT_LIMIT: convert(
        100,
        values.ORG_DAILY_DISBURSEMENT_LIMIT,
        ''
      ),

      DEFAULT_PAY_PERIOD_LENGTH: values.DEFAULT_PAY_PERIOD_LENGTH || '',
    }

    payload.ENABLE_PAY_PERIOD_WAGE_LIMIT = !!payload.PAY_PERIOD_WAGE_LIMIT

    if (values.ENABLE_DIRECT_DEPOSIT_LOGIN_URL) {
      payload.DIRECT_DEPOSIT_LOGIN_URL = values.DIRECT_DEPOSIT_LOGIN_URL
      payload.DIRECT_DEPOSIT_PORTAL_NAME = values.DIRECT_DEPOSIT_PORTAL_NAME
    }

    updateSettings.mutate(payload)
  }

  const toggleDDLogin = () => {
    if (!isEmptyObject(errors)) {
      clearErrors()
    }
    setValue(
      'ENABLE_DIRECT_DEPOSIT_LOGIN_URL',
      !getValues('ENABLE_DIRECT_DEPOSIT_LOGIN_URL')
    )
  }

  const enableLoginUrl = watch('ENABLE_DIRECT_DEPOSIT_LOGIN_URL')
  if (isLoading) {
    return <Spinner />
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justify="between">
          <Text as="h5">Self-Service Portal</Text>
          <LockedIndicator />
        </Flex>
        <Text>
          If you have a self-service portal for workers to enroll in direct
          deposit, provide a link to the portal here, and we’ll link to it in
          the app. If no URL is provided, we’ll route direct deposit requests to
          the notification email address(es) shown above.
        </Text>

        <Box css={{ my: '$8' }}>
          <Checkbox
            id={''}
            onChange={toggleDDLogin}
            checked={enableLoginUrl}
            label="Enable Direct Deposit Website"
          />
        </Box>

        {enableLoginUrl && (
          <Box css={{ my: '$16' }}>
            <Box css={{ mb: '$8' }}>
              <Label htmlFor="DIRECT_DEPOSIT_LOGIN_URL">
                Direct Deposit Login URL
              </Label>
              <Input
                {...register('DIRECT_DEPOSIT_LOGIN_URL')}
                type="text"
                disabled={!isGlobalAdmin}
                placeholder="https://www.abcdef.com"
              />
              <Text color="alert" size="sm">
                {errors?.DIRECT_DEPOSIT_LOGIN_URL?.message}
              </Text>
            </Box>
            <Box css={{ mb: '$8' }}>
              <Label htmlFor="DIRECT_DEPOSIT_PORTAL_NAME">
                Direct Deposit Portal Name
              </Label>
              <Input
                {...register('DIRECT_DEPOSIT_PORTAL_NAME')}
                type="text"
                disabled={!isGlobalAdmin}
                placeholder="ABCDEF Industries"
              />
              <Text color="alert" size="sm">
                {errors?.DIRECT_DEPOSIT_PORTAL_NAME?.message}
              </Text>
            </Box>
          </Box>
        )}

        <Flex justify="between">
          <Text as="h5">Limits</Text>
          <LockedIndicator />
        </Flex>

        <Box css={{ my: '$16' }}>
          <Box css={{ mb: '$8' }}>
            <Label htmlFor="SINGLE_DISBURSEMENT_LIMIT">
              Single Disbursement Limit
            </Label>
            <Flex>
              <InputIcon>$</InputIcon>
              <Input
                {...register('SINGLE_DISBURSEMENT_LIMIT', {
                  valueAsNumber: true,
                })}
                type="number"
                step="0.01"
                min="0.01"
                iconLeft
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="alert" size="sm">
              {errors?.SINGLE_DISBURSEMENT_LIMIT?.message}
            </Text>
          </Box>

          <Box css={{ mb: '$8' }}>
            <Label htmlFor="ORG_DAILY_DISBURSEMENT_LIMIT">
              Organization Daily Disbursement Limit
            </Label>
            <Flex>
              <InputIcon>$</InputIcon>
              <Input
                {...register('ORG_DAILY_DISBURSEMENT_LIMIT', {
                  valueAsNumber: true,
                })}
                type="number"
                step="0.01"
                min="0.01"
                iconLeft
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="alert" size="sm">
              {errors?.ORG_DAILY_DISBURSEMENT_LIMIT?.message}
            </Text>
          </Box>
        </Box>

        <Flex justify="between">
          <Text as="h5">Defaults</Text>
          <LockedIndicator />
        </Flex>

        <Box css={{ my: '$16' }}>
          <Box css={{ mb: '$8' }}>
            <Label htmlFor="PAY_DEFAULT_HOURLY_RATE">Default Hourly Rate</Label>
            <Flex>
              <InputIcon>$</InputIcon>
              <Input
                {...register('PAY_DEFAULT_HOURLY_RATE', {
                  valueAsNumber: true,
                })}
                type="number"
                step="0.01"
                min="0.01"
                iconLeft
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="alert" size="sm">
              {errors?.PAY_DEFAULT_HOURLY_RATE?.message}
            </Text>
          </Box>

          <Box css={{ mb: '$8' }}>
            <Label htmlFor="PAY_PERIOD_WAGE_LIMIT">
              PAY PERIOD WAGE LIMIT (MAX AMOUNT)
            </Label>
            <Flex>
              <InputIcon>$</InputIcon>
              <Input
                {...register('PAY_PERIOD_WAGE_LIMIT', {
                  valueAsNumber: true,
                })}
                type="number"
                step="0.01"
                min="0"
                iconLeft
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="alert" size="sm">
              {errors?.PAY_PERIOD_WAGE_LIMIT?.message}
            </Text>
          </Box>

          <Box css={{ mb: '$8' }}>
            <Label htmlFor="PAY_WITHDRAWAL_PERCENTAGE">
              Pay Period Wage Limit (Max %)
            </Label>
            <Flex>
              <InputIcon>%</InputIcon>
              <Input
                {...register('PAY_WITHDRAWAL_PERCENTAGE', {
                  valueAsNumber: true,
                })}
                type="number"
                step="0.1"
                min="0"
                max="100"
                iconLeft
                placeholder="0"
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="hint" size="sm">
              Enter number from 0 - 100.
            </Text>
            <Text color="alert" size="sm">
              {errors?.PAY_WITHDRAWAL_PERCENTAGE?.message}
            </Text>
          </Box>

          <Box css={{ mb: '$8' }}>
            <Label htmlFor="PAY_DEFAULT_SHIFT_LENGTH">Max Shift Length</Label>
            <Flex>
              <InputIcon>HRS</InputIcon>
              <Input
                {...register('PAY_DEFAULT_SHIFT_LENGTH', {
                  valueAsNumber: true,
                })}
                type="number"
                placeholder="8"
                iconLeft
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="alert" size="sm">
              {errors?.PAY_DEFAULT_SHIFT_LENGTH?.message}
            </Text>
          </Box>

          <Box css={{ mb: '$8' }}>
            <Label htmlFor="DEFAULT_PAY_PERIOD_LENGTH">
              Default Pay Period Length
            </Label>
            <Flex>
              <Input
                {...register('DEFAULT_PAY_PERIOD_LENGTH', {
                  valueAsNumber: true,
                })}
                type="number"
                disabled={!isGlobalAdmin}
              />
            </Flex>
            <Text color="alert" size="sm">
              {errors?.DEFAULT_PAY_PERIOD_LENGTH?.message}
            </Text>
          </Box>
        </Box>

        <Flex direction="column" align="end">
          <Button
            mode="filled"
            type="submit"
            loading={updateSettings.isLoading}
            disabled={!isEmptyObject(errors)}
          >
            Update Settings
          </Button>
        </Flex>
      </form>
    </Card>
  )
}
