import { useEffect, useMemo, useRef, useState } from 'react'
import { Loader } from '@/common/loader'
import {
  SearchDropdownDropdown,
  SearchDropdownInput,
  SearchDropdownList,
  SearchDropdownListItem,
} from '@/components/search-dropdown-field'
import { IListItem } from './lib/types'
import { StoreListItem } from './StoreListItem'
import { StoreListItemNewEntry } from './StoreListItemNewEntry'

interface IProps {
  handleSelect: (item: IListItem) => void
  loadingData: boolean
  list: IListItem[]
  id: string
  selected?: IListItem
}

export const StoreList: React.FC<IProps> = props => {
  const { handleSelect, loadingData, list, selected, id } = props
  const [search, setSearch] = useState(selected?.id || '')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  const sorted = useMemo(() => {
    return list.sort((a, b) => {
      if (!!selected && a.id === selected.id) return -1
      if (a.id > b.id) return 1
      if (b.id > a.id) return -1
      return 0
    })
  }, [list, selected])

  const results = useMemo(() => {
    if (search.length === 0) {
      return sorted
    }
    return sorted.filter(r => {
      const searchFields = [r.id, ...r.id.split(/[ ,]+/)]
      const show = searchFields.some(field => {
        // check if any of the search fields
        // begin with the search value provided
        return field && field.toLowerCase().startsWith(search.toLowerCase())
      })
      return show
    })
  }, [sorted, search])

  const hideNewEntryOption = useMemo(() => {
    const searchHasExactIdMatch = results.some(r => r.id === search)
    return searchHasExactIdMatch || search.length === 0
  }, [results, search])

  const newEntry = useMemo<IListItem>(
    () => ({ label: `New: ${id}`, id: search, newEntry: true }),
    [search, id]
  )

  return (
    <SearchDropdownDropdown>
      <SearchDropdownInput
        value={search}
        ref={inputRef}
        placeholder="Enter store name"
        onChange={e => setSearch(e.target.value)}
      />
      <Loader loading={loadingData}>
        <SearchDropdownList>
          {!hideNewEntryOption && (
            <SearchDropdownListItem
              tabIndex={0}
              onClick={() => handleSelect(newEntry)}
              onKeyPress={() => handleSelect(newEntry)}
            >
              <StoreListItemNewEntry
                entry={newEntry}
                selected={selected?.id === search}
              />
            </SearchDropdownListItem>
          )}

          {results.map(result => (
            <SearchDropdownListItem
              key={result.id}
              tabIndex={0}
              onClick={() => handleSelect(result)}
              onKeyPress={() => handleSelect(result)}
            >
              <StoreListItem
                entry={result}
                selected={selected?.id === result.id}
              />
            </SearchDropdownListItem>
          ))}
        </SearchDropdownList>
      </Loader>
    </SearchDropdownDropdown>
  )
}
