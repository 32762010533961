import { FC } from 'react'
import { PaybackRecord } from '@/types/paybacks'
import { PaybackAmount } from './PaybackAmount'

interface Props {
  original: PaybackRecord
  value: string
}

export const AmountCell: FC<Props> = ({ original, value }) => (
  <PaybackAmount payback={original} value={value} />
)
