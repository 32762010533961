import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { updateOrganization } from '@/api/organizations'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Checkbox, CheckboxLabel } from '@/common/checkbox'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { ToggleMultipleGroup, ToggleSingleGroup } from '@/common/toggle-group'
import { Tooltip } from '@/common/tooltip'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import Spinner from '@/components/common/loader/Spinner'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { useSettingsStore } from '@/store/settings'
import { IOrganization, OrgSettings } from '@/types/organization'
import {
  GeneralSetupDescription,
  GeneralSetupDescriptions,
} from '../lib/generalSetupCheckboxInfo'
import { HelperText, SectionTitle } from './styles'

export type GeneralSetupFormValues = {
  REQUIRE_ROSTER_MATCH_BEFORE_KYC: boolean
  ALLOW_MINOR_ACCOUNTS: boolean
  REQUIRE_FULL_DIRECT_DEPOSIT: boolean
  DIRECT_DEPOSIT_WALLET_PULLBACK: boolean
  REQUIRE_PHYSICAL_CARD_ORDERING: boolean
  ENABLE_BULK_ACCOUNT_CREATION: boolean
  CREATE_ABOUND_ACCOUNT: boolean
  ENABLE_VARIABLE_PAY_PERIODS: boolean
  ENABLE_MANUAL_DISBURSEMENTS: boolean
  ENABLE_INSTANT_CARD_ORDERING: boolean
  ENABLE_REMITTANCE_ACH_PULL: boolean
  ENABLE_EXPENSE_ACCOUNTS: boolean
  ENABLE_ENTERPRISE_REPORTING: boolean
  ONBOARDING_INCLUDE_DD_SUBMISSION: boolean
  DIRECT_DEPOSIT_PRECONFIGURED: boolean
  PERIOD_EARNINGS_FOR_EARNED_WAGES: boolean
  ENABLE_CASH_OUT: boolean
  showInResults: boolean
  SUPPORT_CHAT_ENABLED: boolean
  EMPLOYEE_VERIFICATION_METHODS: string
  INVOICE_PAYMENT_ORIGINATOR: string
  FILE_DELIVERY_METHOD: string
  SFTP_ROOT_DIRECTORY: string
  INSTANT_PAY_1099_ADVANCE_ORG: boolean
  BUSINESS_INFO_ON_STATEMENT: boolean
  ENABLE_REM_FILE_AUTO_CONFIRM: boolean
}

export type Option = {
  id: string
  label: string
}

const EMPLOYEE_VERIFICATION_METHODS: Option[] = [
  { id: 'NAME', label: 'Name' },
  { id: 'EMAIL', label: 'Email' },
  { id: 'PHONE_NUMBER', label: 'Phone Number' },
  { id: 'SSO', label: 'SSO' },
  { id: 'HOSTED', label: 'Hosted' },
  { id: 'API', label: 'API' },
  { id: 'UNVERIFIED_EMAIL', label: 'Unverified Email' },
]

const INVOICE_PAYMENT_CONFIGS: Option[] = [
  { id: 'BRANCH', label: 'BRANCH' },
  { id: 'ORG', label: 'ORG' },
]

const FILE_DELIVERY_METHODS: Option[] = [
  { id: 'PAY_ADMIN', label: 'Pay Admin' },
  { id: 'SFTP', label: 'SFTP' },
]

const isTruthy = (value?: string) => value === 'true'

const resetForm = (settings: OrgSettings, currentOrg: IOrganization) => ({
  ENABLE_BULK_ACCOUNT_CREATION: isTruthy(settings.ENABLE_BULK_ACCOUNT_CREATION),
  REQUIRE_ROSTER_MATCH_BEFORE_KYC: isTruthy(
    settings.REQUIRE_ROSTER_MATCH_BEFORE_KYC
  ),
  DIRECT_DEPOSIT_WALLET_PULLBACK: isTruthy(
    settings.DIRECT_DEPOSIT_WALLET_PULLBACK
  ),
  ALLOW_MINOR_ACCOUNTS: isTruthy(settings.ALLOW_MINOR_ACCOUNTS),
  CREATE_ABOUND_ACCOUNT: isTruthy(settings.CREATE_ABOUND_ACCOUNT),
  PERIOD_EARNINGS_FOR_EARNED_WAGES: isTruthy(
    settings.PERIOD_EARNINGS_FOR_EARNED_WAGES
  ),
  REQUIRE_FULL_DIRECT_DEPOSIT: isTruthy(settings.REQUIRE_FULL_DIRECT_DEPOSIT),
  REQUIRE_PHYSICAL_CARD_ORDERING: isTruthy(
    settings.REQUIRE_PHYSICAL_CARD_ORDERING
  ),
  ENABLE_VARIABLE_PAY_PERIODS: isTruthy(settings.ENABLE_VARIABLE_PAY_PERIODS),
  ENABLE_MANUAL_DISBURSEMENTS: isTruthy(settings.ENABLE_MANUAL_DISBURSEMENTS),
  ENABLE_INSTANT_CARD_ORDERING: isTruthy(settings.ENABLE_INSTANT_CARD_ORDERING),
  ENABLE_REMITTANCE_ACH_PULL: isTruthy(settings.ENABLE_REMITTANCE_ACH_PULL),
  ONBOARDING_INCLUDE_DD_SUBMISSION: isTruthy(
    settings.ONBOARDING_INCLUDE_DD_SUBMISSION
  ),
  DIRECT_DEPOSIT_PRECONFIGURED: isTruthy(settings.DIRECT_DEPOSIT_PRECONFIGURED),
  ENABLE_CASH_OUT: isTruthy(settings.ENABLE_CASH_OUT),
  ENABLE_EXPENSE_ACCOUNTS: isTruthy(settings.ENABLE_EXPENSE_ACCOUNTS),
  SUPPORT_CHAT_ENABLED: isTruthy(settings.SUPPORT_CHAT_ENABLED),
  ENABLE_ENTERPRISE_REPORTING: isTruthy(settings.ENABLE_ENTERPRISE_REPORTING),
  showInResults: currentOrg.show_in_results,
  EMPLOYEE_VERIFICATION_METHODS: settings.EMPLOYEE_VERIFICATION_METHODS,
  INVOICE_PAYMENT_ORIGINATOR: settings.INVOICE_PAYMENT_ORIGINATOR,
  FILE_DELIVERY_METHOD: settings.FILE_DELIVERY_METHOD,
  SFTP_ROOT_DIRECTORY: settings.SFTP_ROOT_DIRECTORY,
  INSTANT_PAY_1099_ADVANCE_ORG: isTruthy(settings.INSTANT_PAY_1099_ADVANCE_ORG),
  BUSINESS_INFO_ON_STATEMENT: isTruthy(settings.BUSINESS_INFO_ON_STATEMENT),
  ENABLE_REM_FILE_AUTO_CONFIRM: isTruthy(settings.ENABLE_REM_FILE_AUTO_CONFIRM),
})

export const GeneralSetup = () => {
  const { settings, isLoading } = useSettingsStore()
  const currentOrg = useCurrentOrg()

  const updateSettings = useUpdateSettingsMutation()

  const {
    formState: { isDirty },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: resetForm(settings, currentOrg),
  })

  useEffect(() => {
    reset(resetForm(settings, currentOrg))
  }, [settings, currentOrg, reset])

  const onSubmitForm = (values: GeneralSetupFormValues) => {
    updateSettings.mutate(values, {
      onSuccess: async () => {
        if (values.showInResults !== currentOrg.show_in_results) {
          await updateOrganization(
            { show_in_results: values.showInResults },
            currentOrg.id
          )
          window.location.reload()
        }
      },
    })
  }

  const updateVal = (
    key: keyof GeneralSetupFormValues,
    val: boolean | string
  ) => {
    setValue(key, val, { shouldDirty: true })
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <FlexWrap direction="column" align="start" size="md">
      <Card>
        <h5>Update a single setting for all of Branch's locations at once.</h5>
        <Text css={{ marginBottom: '15px' }}>
          <strong>NOTE: </strong>Default values below are retrieved from the
          default location (Location ID: {currentOrg.default_location_id})
          settings. These values are not <i>necessarily</i> indicative of the
          values currently set on ALL of this organization's locations, because
          a location's settings could be modified elsewhere in the system.
        </Text>
      </Card>
      <Card css={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <SectionTitle>General Setup</SectionTitle>
          <FlexWrap direction="column" align="start" size="lg">
            <Box>
              <FlexWrap
                direction="column"
                align="start"
                size="lg"
                css={{ height: 250, '@desktop': { height: 'auto' } }}
              >
                {Object.entries(GeneralSetupDescriptions).map(([key, desc]) => (
                  <Flex direction="row" align="center" key={key}>
                    <Checkbox
                      id={key}
                      checked={watch(key as keyof GeneralSetupDescription)}
                      onChange={(_, value) =>
                        updateVal(key as keyof GeneralSetupDescription, value)
                      }
                    />
                    <Tooltip
                      trigger={<CheckboxLabel>{desc.label}</CheckboxLabel>}
                    >
                      {desc.tooltip}
                    </Tooltip>
                  </Flex>
                ))}
              </FlexWrap>
            </Box>
            <FlexWrap direction="column" align="start" size="lg">
              <Label>Worker Verification Methods</Label>
              <FlexWrap direction="column" align="start" size="sm">
                <ToggleMultipleGroup
                  id="EMPLOYEE_VERIFICATION_METHODS"
                  options={EMPLOYEE_VERIFICATION_METHODS}
                  value={watch('EMPLOYEE_VERIFICATION_METHODS')?.split(';')}
                  onChange={val => {
                    updateVal('EMPLOYEE_VERIFICATION_METHODS', val.join(';'))
                  }}
                  selectType="multiple"
                />
                <HelperText size="sm">
                  Controls how the system verifies a worker
                </HelperText>
              </FlexWrap>
              <Label>Invoice Payment Originator</Label>
              <FlexWrap direction="column" align="start" size="sm">
                <ToggleSingleGroup
                  id="INVOICE_PAYMENT_ORIGINATOR"
                  options={INVOICE_PAYMENT_CONFIGS}
                  value={watch('INVOICE_PAYMENT_ORIGINATOR')}
                  onChange={(_, val) => {
                    updateVal('INVOICE_PAYMENT_ORIGINATOR', val)
                  }}
                  selectType="single"
                />
              </FlexWrap>
              <Label>File Delivery Method</Label>
              <FlexWrap direction="column" align="start" size="sm">
                <ToggleSingleGroup
                  id="FILE_DELIVERY_METHOD"
                  options={FILE_DELIVERY_METHODS}
                  value={watch('FILE_DELIVERY_METHOD')}
                  onChange={(_, val) => {
                    updateVal('FILE_DELIVERY_METHOD', val)
                  }}
                  selectType="single"
                />
              </FlexWrap>
              {watch('FILE_DELIVERY_METHOD') === 'SFTP' && (
                <>
                  <Label>STFP Root Directory</Label>
                  <FlexWrap
                    direction="column"
                    align="start"
                    size="sm"
                    css={{ width: '50%' }}
                  >
                    <Input
                      value={watch('SFTP_ROOT_DIRECTORY')}
                      onChange={e => {
                        updateVal('SFTP_ROOT_DIRECTORY', e.target.value)
                      }}
                    />
                    <HelperText size="sm">
                      Directory used for SFTP Files
                    </HelperText>
                  </FlexWrap>
                </>
              )}
            </FlexWrap>
          </FlexWrap>
          <Flex>
            <Button
              type="submit"
              mode="filled"
              disabled={isLoading || !isDirty}
              size="md"
              css={{ ml: 'auto' }}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Card>
    </FlexWrap>
  )
}
