import { FC } from 'react'
import RetryIcon from '@/common/icons/RetryIcon'
import { Spinner } from '@/common/loader'
import { useRetryJob } from '@/queries/jobs'
import { JobsRecord } from '@/types/jobs'
import styles from '../lib/index.module.scss'

interface Props {
  original: JobsRecord
}

export const RetryCell: FC<Props> = ({ original: { id, job_status } }) => {
  const retryJob = useRetryJob()

  // only show retry for failed jobs
  if (job_status !== 'FAILED') return null

  return (
    <div className={styles.RetryCell} onClick={() => retryJob.mutate({ id })}>
      {!retryJob.isLoading ? (
        <div className={styles.RetryCellBtn}>
          <RetryIcon />
          Retry
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
