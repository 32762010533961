import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { toDollar } from '@/lib/currency'
import { CashoutRecord } from '@/types/cash-outs'
import { ViewDetailsCell } from '../components/ViewDetailsCell'

export const columns: ColumnDef<CashoutRecord, any>[] = [
  {
    id: 'id',
    header: 'ID',
    accessorFn: row => row.disbursement.id,
  },
  {
    id: 'employee_name',
    header: 'Name',
    accessorFn: ({ disbursement: { roster_record } }) =>
      `${roster_record.first_name} ${roster_record.last_name}`,
    enableSorting: false,
  },
  {
    id: 'amount',
    header: 'Amount',
    accessorFn: row => toDollar(row.cash_out.amount),
  },
  {
    id: 'fee',
    header: 'Fee',
    accessorFn: row => toDollar(row.cash_out.fee),
    enableSorting: false,
  },
  {
    header: 'Details',
    id: 'viewDisbursements',
    cell: ({ row }) => <ViewDetailsCell original={row.original} />,
    enableSorting: false,
  },
  {
    id: 'time_scheduled',
    header: 'Date',
    accessorFn: row =>
      moment(row.disbursement.time_scheduled).format('MM/DD/YYYY'),
  },
]
