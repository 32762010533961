import { InitiationStatus } from '@/types/create-accounts'

export const showNaIfUndefined = (val?: string | null) => val || 'N/A'

export const STATUS_LABELS: { [key in InitiationStatus]: string } = {
  FAILED: 'Failed',
  LINKED: 'Setup Complete',
  PENDING: 'Pending',
  PENDING_MANUAL: 'User Verification Required',
  CANCELED: 'Canceled',
  UNLINKED: 'Verified',
  PENDING_REVIEW: 'Pending Review',
}
