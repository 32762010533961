import moment from 'moment'
import { useState } from 'react'
import { IEmailRegistrationConfig } from '@/api/types/settings'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { MatchObject } from '@/components/spreadsheet-mapper/useMatchStore'
import { getBase64FromFile } from '@/lib/asyncReader'
import { handleHttpException } from '@/lib/httpException'
import { useAddEmailRegistration, useIngestRoster } from '@/queries/ingestion'

export const useImportRoster = (file: File, matchObject: MatchObject) => {
  const currentOrg = useCurrentOrg()
  const ingestRoster = useIngestRoster()
  const addEmailRegistration = useAddEmailRegistration()
  const [uploadingTestConfig, setUploadingTestConfig] = useState(false)
  const [uploadingConfig, setUploadingConfig] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadingFileSucceeded, setUploadingFileSucceeded] = useState(false)
  const [
    uploadingTestConfigSucceeded,
    setUploadingTestConfigSucceeded,
  ] = useState(false)
  const [uploadingConfigSucceeded, setUploadingConfigSucceeded] = useState(
    false
  )
  const [showGeneralErrorPrompt, setShowGeneralErrorPrompt] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showOverridePrompt, setShowOverridePrompt] = useState(false)

  const processRoster = async (save = false, overrideRosterWarning = false) => {
    const base64File: string = await getBase64FromFile(file)
    const rosterConfig: {
      [key: string]: any
    } = {
      ...matchObject,
      file: base64File,
      filename: file.name,
      organization_id: currentOrg.id,
      save_configuration: save,
      save_data: save,
      override_roster_warning: overrideRosterWarning,
    }
    await ingestRoster.mutateAsync(rosterConfig)
  }

  const createIngestionConfig = async (file: File) => {
    const config: IEmailRegistrationConfig = {
      email_key: currentOrg.org_key,
      processor_registrations: [
        {
          regex: file.name,
          filename: file.name,
          processor_name: 'standardRosterFileProcessor',
          effective_date: moment().format(),
        },
      ],
    }
    await addEmailRegistration.mutateAsync(config)
  }

  const uploadFile = async () => {
    setUploadingFile(true)
    try {
      await createIngestionConfig(file)
      setUploadingFileSucceeded(true)
    } catch (error) {
      setUploadingFileSucceeded(false)
      throw error
    } finally {
      setUploadingFile(false)
    }
  }

  const uploadRoster = async (save = false, overrideRosterWarning = false) => {
    if (save) {
      setUploadingConfig(true)
    } else {
      setUploadingTestConfig(true)
    }
    try {
      await processRoster(save, overrideRosterWarning)
      if (save) {
        setUploadingConfigSucceeded(true)
      } else {
        setUploadingTestConfigSucceeded(true)
      }
    } catch (error) {
      throw error
    } finally {
      if (save) {
        setUploadingConfig(false)
      } else {
        setUploadingTestConfig(false)
      }
    }
  }

  const handleUpload = async (overrideRosterWarning = false) => {
    setShowOverridePrompt(false)
    setErrorMessage(null)
    setShowGeneralErrorPrompt(false)
    setUploadingConfigSucceeded(false)
    setUploadingTestConfigSucceeded(false)
    setUploadingFileSucceeded(false)
    try {
      await uploadRoster()
      await uploadRoster(true, overrideRosterWarning)
      await uploadFile()
    } catch (error) {
      handleHttpException(error, {
        onHttpError: ({ response }) => {
          let details = 'An unknown error occured in uploading roster'
          if (response?.data?.meta?.detail) {
            details = error.response.data.meta.detail
          }
          if (details.toLowerCase().includes('large_roster_change')) {
            setShowOverridePrompt(true)
          } else {
            setShowGeneralErrorPrompt(true)
          }
          setErrorMessage(details)
        },
      })
    }
  }

  return {
    uploadingTestConfig,
    uploadingConfig,
    errorMessage,
    uploadingFile,
    uploadingFileSucceeded,
    uploadingTestConfigSucceeded,
    uploadingConfigSucceeded,
    showOverridePrompt,
    showGeneralErrorPrompt,
    handleUpload,
  }
}
