import moment from 'moment'
import {
  ICachedAdjustments,
  IRemittanceProgresses,
  NpsInfo,
  TEmployeeAdjustments,
  TRemittanceProgressStep,
} from '../types'

const DRAFT_REMITTANCE_ADJUSTMENTS = 'draft_remittance_employee_adjustments'
const DRAFT_REMITTANCE_PROGRESS = 'draft_remittance_flow_progress'
const NPS_INFO = 'nps_info'

export const saveAdjustmentsToLocalStorage = (
  remittanceID: string,
  adjustments: TEmployeeAdjustments
): void => {
  const draft: ICachedAdjustments = {
    remittanceID,
    adjustments,
  }
  localStorage.setItem(DRAFT_REMITTANCE_ADJUSTMENTS, JSON.stringify(draft))
}

export const removeDraftFromLocalStorage = (): void => {
  localStorage.removeItem(DRAFT_REMITTANCE_ADJUSTMENTS)
}

// Attempts to fetch a saved draft from localstorage.
// If the draft is for a different remittance or does
// not exist in localstorage, we return null
export const getAdjustmentStatusesFromLocalStorage = (
  remittanceID: string
): TEmployeeAdjustments => {
  const draftJson = localStorage.getItem(DRAFT_REMITTANCE_ADJUSTMENTS)
  if (draftJson && draftJson.length > 0) {
    const draft: ICachedAdjustments = JSON.parse(draftJson)
    if (draft.remittanceID === remittanceID) {
      return draft.adjustments
    }
  }
  return {}
}

const saveRemittanceProgressesToLocalStorage = (
  cachedProgresses: IRemittanceProgresses
) => {
  localStorage.setItem(
    DRAFT_REMITTANCE_PROGRESS,
    JSON.stringify(cachedProgresses)
  )
}

const getCachedProgresses = (): IRemittanceProgresses => {
  const val = localStorage.getItem(DRAFT_REMITTANCE_PROGRESS)
  return val ? JSON.parse(val) : {}
}

/*
 * Gets a single cached remittance progress
 */
export const getCachedRemittanceProgressStep = (
  remittanceId: string
): TRemittanceProgressStep | null => {
  const cachedProgress = getCachedProgresses()[remittanceId]
  return cachedProgress ? cachedProgress : null
}

/*
 * Remove a single cached remittance progress
 */
export const removeCachedRemittanceProgress = (remittanceId: string) => {
  const cachedProgresses = getCachedProgresses()

  if (cachedProgresses[remittanceId]) {
    delete cachedProgresses[remittanceId]
  }

  saveRemittanceProgressesToLocalStorage(cachedProgresses)
}

/*
 * Caches remittance progess into localstorage.
 * If progress with the same remittance ID
 * already exists, delete it and add the new copy.
 * If the remittance progress is new, add it.
 */
export const cacheRemittanceProgress = (
  remittanceID: string,
  step: TRemittanceProgressStep
) => {
  const cachedEntries = getCachedProgresses()

  // remove duplicative entries from the cached entries object
  delete cachedEntries[remittanceID]

  cachedEntries[remittanceID] = step

  saveRemittanceProgressesToLocalStorage(cachedEntries)
}

export const updateNpsInfoCache = (completed: boolean) => {
  const updatedAt = +moment().format('X')
  const info: NpsInfo = {
    updatedAt,
    completed,
  }

  localStorage.setItem(NPS_INFO, JSON.stringify(info))
}

export const getCachedNpsInfo = (): NpsInfo | null => {
  const val = localStorage.getItem(NPS_INFO)
  return val ? JSON.parse(val) : null
}
