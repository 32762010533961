import { GetSubAccountResponse } from '@/api/types/sub-account'
import http from './http'

/**
 * Retrieves an organization's subaccount
 */
export const getSubAccount = (orgId: number) =>
  http.get<GetSubAccountResponse>(`p1/sub_accounts?organization_id=${orgId}`)

/**
 * Creates a subaccount for organization
 */
export const createSubAccount = (orgId: number) =>
  http.post(`p1/sub_accounts/assign`, {
    organization_id: orgId,
  })
