import { saveAs } from 'file-saver'
import moment from 'moment'
import Papa from 'papaparse'
import { toDollar } from '@/lib/currency'
import { PaybackRecord } from '@/types/paybacks'

export const downloadCSV = (records: PaybackRecord[]) => {
  const csvData = records.map(record => ({
    'Invoice ID': record.id,
    'Effective Date': record.bill_time
      ? moment(record.bill_time).format('MM/DD/YYYY')
      : '',
    Description: record.description,
    Total: toDollar(record.amount),
    'Funding Source': record.funding_source_id,
    Status: record.status,
  }))

  const csvContent = Papa.unparse(csvData)
  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'branch_invoices.csv')
}
