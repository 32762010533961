import { styled } from '@/styles/stitches.config'

const CalendarStyles = {
  ' .react-calendar': {
    width: '350px',
    background: 'white',
    lineHeight: '1.125em',
    br: '$md',
    padding: '12px',
  },

  '.react-calendar--doubleView': {
    width: '600px',
  },

  '.react-calendar--doubleView .react-calendar__viewContainer': {
    display: 'flex',
    margin: '-0.5em',
  },
  '.react-calendar--doubleView .react-calendar__viewContainer > *': {
    width: '50%',
    margin: '0.5em',
  },
  '.react-calendar,\n.react-calendar *,\n.react-calendar *:before,\n.react-calendar *:after': {
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box',
  },
  '.react-calendar button': {
    margin: '0',
    border: '0',
    outline: 'none',
  },
  '.react-calendar button:enabled:hover': { cursor: 'pointer' },
  '.react-calendar__navigation': {
    display: 'flex',
    height: '44px',
    marginBottom: '1em',
  },
  'abbr[title]': {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  '.react-calendar__navigation button': {
    minWidth: '44px',
    background: 'none',
    border: 'none',
    br: '$sm',
    color: '$primary500',
    fontWeight: 'bold',
  },
  '.react-calendar__navigation button:disabled': {
    color: '#999999',
    borderColor: '#e6e6e6',
    opacity: 0.75,
  },
  '.react-calendar__navigation button:enabled:hover,\n.react-calendar__navigation button:enabled:focus': {
    br: '$sm',
  },
  '.react-calendar__month-view__weekdays': {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.8em',
    color: 'black',
  },
  '.react-calendar__month-view__weekdays__weekday': {
    padding: '0.5em',
  },
  '.react-calendar__month-view__weekNumbers .react-calendar__tile': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75em',
    fontWeight: 'bold',
  },
  '.react-calendar__month-view__days__day--weekend': {},
  '.react-calendar__month-view__days__day--neighboringMonth': {
    color: '#757575',
  },
  '.react-calendar__year-view .react-calendar__tile,\n.react-calendar__decade-view .react-calendar__tile,\n.react-calendar__century-view .react-calendar__tile': {
    padding: '2em 0.5em',
  },
  '.react-calendar__tile': {
    maxWidth: '100%',
    padding: '10px 6.6667px',
    border: 'none',
    background: 'none',
    textAlign: 'center',
    lineHeight: '16px',
  },
  '.react-calendar__tile:disabled': { backgroundColor: '#f0f0f0' },
  '.react-calendar__tile:enabled:hover,\n.react-calendar__tile:enabled:focus': {
    backgroundColor: '$secondary800',
    br: '$sm',
  },
  '.react-calendar__tile--now': {
    border: '0.5px solid $secondary700',
    br: '$sm',
  },
  '.react-calendar__tile--now:enabled:hover,\n.react-calendar__tile--now:enabled:focus': {
    border: '0.5px solid $secondary700',
    br: '$sm',
  },
  '.react-calendar__tile--hasActive': {
    br: '$sm',
  },
  '.react-calendar__tile--hasActive:enabled:hover,\n.react-calendar__tile--hasActive:enabled:focus': {
    br: '$sm',
  },
  '.react-calendar__tile--active': {
    background: '$primary500',
    color: 'white',
    fontWeight: 'bold',
    br: '$sm',
  },
  '.react-calendar__tile--active:enabled:hover,\n.react-calendar__tile--active:enabled:focus': {
    background: '$primary500',
    color: 'white',
    br: '$sm',
  },
  '.react-calendar--selectRange .react-calendar__tile--hover': {},

  '.react-calendar__tile--range': {
    background: '#f8f8fa',
    color: '$primary500',
    borderRadius: '0',
  },
  '.react-calendar__tile--rangeStart': {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderTopLeftRadius: '$sm',
    borderBottomLeftRadius: '$sm',
    background: '$primary500',
    color: 'white',
  },
  '.react-calendar__tile--rangeEnd': {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '$sm',
    borderBottomRightRadius: '$sm',
    background: '$primary500',
    color: 'white',
  },
}

export const CalendarContainer = styled('div', CalendarStyles)

export const DualCalendarContainer = styled('div', {
  ...CalendarStyles,
  '.react-calendar--doubleView  .react-calendar__month-view__days__day--neighboringMonth': {
    visibility: 'hidden',
  },
})

export const DateInputContainer = styled('div', {
  '&:disabled': {
    backgroundColor: '$secondary900',
  },
  '.react-date-picker': {
    display: 'inline-flex',
    position: 'relative',
  },
  '.react-date-picker,\n.react-date-picker *,\n.react-date-picker *:before,\n.react-date-picker *:after': {
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    boxSizing: 'border-box',
  },
  '.react-date-picker--disabled': {
    backgroundColor: '$secondary900',
    color: '#6d6d6d',
  },
  '.react-date-picker__wrapper': {
    display: 'flex',
    border: '2px solid $secondary700',
    br: '$sm',
  },
  '.react-date-picker__inputGroup': {
    minWidth: 'calc((4px * 3) +  0.54em * 8  +  0.217em * 2)',
    flexGrow: 1,
    padding: '0 8px',
    boxSizing: 'content-box',
  },
  '.react-date-picker__inputGroup__divider': {
    padding: '1px 0',
    whiteSpace: 'pre',
  },
  '.react-date-picker__inputGroup__input': {
    minWidth: '0.54em',
    height: 31,
    position: 'relative',
    padding: '0 1px',
    border: '0',
    background: 'none',
    font: 'inherit',
    boxSizing: 'content-box',
    MozAppearance: 'textfield',
  },
  '.react-date-picker__inputGroup__input::-webkit-outer-spin-button,\n.react-date-picker__inputGroup__input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  '.react-date-picker__inputGroup__input:invalid': {
    background: 'rgba(255, 0, 0, 0.1)',
  },
  '.react-date-picker__inputGroup__input--hasLeadingZero': {
    marginLeft: '-0.54em',
    paddingLeft: 'calc(1px +  0.54em)',
  },
  '.react-date-picker__button': {
    border: '0',
    background: 'transparent',
    padding: '4px 6px',
  },
  '.react-date-picker__button:enabled': { cursor: 'pointer' },
  '.react-date-picker__button:enabled:hover .react-date-picker__button__icon,\n.react-date-picker__button:enabled:focus .react-date-picker__button__icon': {
    stroke: '$primary500',
  },
  '.react-date-picker__button:disabled .react-date-picker__button__icon': {
    stroke: '#6d6d6d',
  },
  '.react-date-picker__button svg': {
    display: 'inherit',
  },
  '.react-date-picker__calendar': {
    ...CalendarStyles,
    zIndex: 1,
    br: '$md',
    boxShadow: '$toast',
  },
  '.react-date-picker__calendar--closed': { display: 'none' },
})
