import { ROUTES } from './routes'

export type NavItem = NavOverride & {
  label: string
  route: string
}

export type NavOverride = {
  isHidden?: boolean
  isStandalone?: boolean
}

export type GroupedNavItem = NavItem & {
  group: NAV_GROUPS
}

export enum NAV_GROUPS {
  PAYMENTS = 'payments',
  CARDS = 'cards',
  ORGANIZATION = 'organization',
  BILLING = 'billing',
  INVOICES = 'invoices',
  CREATE_ACCOUNTS = 'create_accounts',
}

export const navGroups: { [key in NAV_GROUPS]: NavItem[] } = {
  [NAV_GROUPS.INVOICES]: [
    {
      label: 'Invoices',
      route: ROUTES.INVOICES,

      isStandalone: true,
    },
  ],
  [NAV_GROUPS.PAYMENTS]: [
    {
      label: 'Disbursements',
      route: ROUTES.DISBURSEMENTS,
    },
    {
      label: 'Cash Outs',
      route: ROUTES.CASH_OUTS,
    },
  ],
  [NAV_GROUPS.CARDS]: [
    {
      label: 'Instant Cards',
      route: ROUTES.INSTANT_CARDS,
    },
    {
      label: 'Orders',
      route: ROUTES.INSTANT_CARD_ORDERS,
    },
  ],
  [NAV_GROUPS.ORGANIZATION]: [
    {
      label: 'Workers',
      route: ROUTES.WORKERS,
    },
    {
      label: 'Direct Deposits',
      route: ROUTES.DIRECT_DEPOSITS,
    },
    {
      label: 'Locations',
      route: ROUTES.LOCATIONS,
    },
    {
      label: 'Payroll',
      route: ROUTES.PAYROLL_PERIODS,
    },
    {
      label: 'Timekeeping',
      route: ROUTES.TIMEKEEPING,
    },
    {
      label: 'Gross Earnings',
      route: ROUTES.GROSS_EARNINGS,
    },
    {
      label: 'Resources',
      route: ROUTES.RESOURCES,
    },
    {
      label: 'Integration Status',
      route: ROUTES.JOBS,
    },
    {
      label: 'Adoption Insights',
      route: ROUTES.ADOPTION_INSIGHTS,
    },
  ],
  [NAV_GROUPS.BILLING]: [
    {
      label: 'Advances',
      route: ROUTES.ADVANCES,
    },
    {
      label: 'Remittances',
      route: ROUTES.REMITTANCES,
    },
    {
      label: 'Insights',
      route: ROUTES.ADVANCES_INSIGHTS,
    },
  ],
  [NAV_GROUPS.CREATE_ACCOUNTS]: [
    {
      label: 'Create Accounts',
      route: ROUTES.CREATE_ACCOUNTS,

      isStandalone: true,
    },
  ],
}

export type NavItemOverrides = { [route: string]: NavOverride }
