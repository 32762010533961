import { FC, useMemo } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { usePayoutTypesQuery } from '@/src/queries/payouts'
import { useDisbursementsStore } from '@/store/disbursements'
import { additionalFilters, payoutStatusOptions } from '../lib/filters'
import { useDownloadPayouts } from '../lib/useDownloadPayouts'

export const PayoutFilters: FC = () => {
  const { filters, updateFilters, removeFilter } = useDisbursementsStore()
  const { loading, handleDownload } = useDownloadPayouts()
  const query = usePayoutTypesQuery()

  const typeOptions = useMemo(
    () =>
      (query.data || []).map(type => ({
        label: type,
        value: type.toUpperCase(),
      })),
    [query.data]
  )

  return (
    <TableFiltersMain
      searchProperty="employee_name"
      typeOptions={typeOptions}
      filters={filters}
      updateFilters={updateFilters}
      statusOptions={payoutStatusOptions}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleDownload={handleDownload}
      removeFilter={removeFilter}
      disableEnterpriseReporting
    />
  )
}
