import {
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { SettingsContainer } from '@/components/settings/container/SettingsContainer'

export const DirectDepositContainer: React.FC = ({ children }) => {
  return (
    <SettingsContainer>
      <PageTop simple>
        <PageHeader>
          <PageTitle primary="Worker Payments" />
        </PageHeader>
      </PageTop>
      <PageBody>{children}</PageBody>
    </SettingsContainer>
  )
}
