import { FC } from 'react'
import { Button } from '@/common/button'
import { DownloadCsvIcon } from '@/common/icons'
import { useRemittance } from '@/queries/remittances'
import { useDeductionReport } from '@/store/deduction-report'
import { useDownloadRemittance } from '../lib/useDownloadRemittance'
import { ProgressCard } from './ProgressCard'
import ProgressCardFooter from './ProgressCardFooter'
import ProgressCardTitle from './ProgressCardTitle'

interface Props {
  remittanceId: string
}

export const DownloadProgressCard: FC<Props> = ({ remittanceId }) => {
  const remittance = useRemittance(remittanceId)
  const { download, pendingDownload } = useDownloadRemittance(remittance.data)
  const {
    computed: { getProgressStepPosition },
    updateProgressStep,
  } = useDeductionReport()

  const stepPosition = getProgressStepPosition('download')

  const handleClick = async () => {
    await download()
    updateProgressStep('process')
  }

  return (
    <ProgressCard isDisabled={stepPosition > 0}>
      <ProgressCardTitle text="Download file" Icon={DownloadCsvIcon} />
      <h5>
        Download the CSV containing all deductions taken for the associated pay
        period.
      </h5>
      <ProgressCardFooter stepComplete={stepPosition < 0}>
        {!!remittance.data && (
          <Button
            mode={stepPosition < 0 ? 'outline' : 'filled'}
            loading={pendingDownload}
            disabled={pendingDownload}
            onClick={handleClick}
          >
            Download CSV
          </Button>
        )}
      </ProgressCardFooter>
    </ProgressCard>
  )
}
