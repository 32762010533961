import { useMemo } from 'react'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { usePartnerQuery } from '@/queries/settings'
import { PartnerSettings } from '@/types/organization'

export const usePartnerRosterSettings = () => {
  const partnerRoster = usePartnerQuery()
  return useMemo(
    () =>
      (partnerRoster.data || []).reduce(
        (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
        {} as PartnerSettings
      ),
    [partnerRoster.data]
  )
}

export const useSharedPartnerRoster = () => {
  const { id: orgId } = useCurrentOrg()
  const { parent_org_id } = usePartnerRosterSettings()
  return !!parent_org_id && parent_org_id !== orgId.toString()
}
