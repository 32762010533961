import { styled } from '@/styles/stitches.config'

export const Alert = styled('div', {
  padding: '$8 $16',
  borderRadius: '$md',

  variants: {
    type: {
      info: {
        color: '$info700',
        backgroundColor: '$info900',
      },
      success: {
        color: '$success900',
        backgroundColor: '$success200',
      },
      warning: {
        color: '$warn900',
        backgroundColor: '$warn200',
      },
      danger: {
        color: '$danger900',
        backgroundColor: '$danger200',
      },
      secondary: {
        color: '$secondary100',
        backgroundColor: '$secondary700',
      },
    },
    size: {
      sm: {
        fontSize: 14,
        padding: '$8 $12',
      },
    },
  },

  defaultVariants: {
    type: 'info',
  },
})
