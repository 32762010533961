import moment from 'moment'
import { FC, useState } from 'react'
import { DetailProperty } from '@/common/detail'
import { Dialog } from '@/common/modal'
import { Text } from '@/common/text'
import { useUserInfoQuery } from '@/queries/admin-users'
import { useModalStore } from '@/store/modal'
import { InvoiceNote } from '@/types/invoice'

interface Props {
  note: InvoiceNote
}

export const NoteModal: FC<Props> = ({ note }) => {
  const { removeModal } = useModalStore()

  const userInfo = useUserInfoQuery(`${note.created_by}`)
  const [open, setOpen] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog open={open} setOpen={close} title="Note Details">
      <DetailProperty
        label="Created By"
        value={userInfo.data?.full_name || note.created_by}
      />
      <DetailProperty
        label="Created Date"
        value={moment(note.time_created).format('MM/DD/YYYY')}
      />
      <Text css={{ my: '$16' }}>{note.note}</Text>
    </Dialog>
  )
}
