import { FC, useState } from 'react'
import { Modal } from 'reactstrap'
import { MatchObject } from '@/components/spreadsheet-mapper/useMatchStore'
import { StepGroup, Transition } from '@/components/stepped-modal'
import useSteps from '@/src/hooks/useSteps'
import { useModalStore } from '@/store/modal'
import {
  ColumnMatchStep,
  FileUploadStep,
  ValidateStep,
} from './components/index'
import { STEP_IDS, STEPS } from './lib/taSteps'

interface Props {
  handleClose: () => void
}

export const ImportTAContainer: FC<Props> = ({ handleClose }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const { currentStep, goToNextStep } = useSteps(STEPS)
  const [columnMatches, setColumnMatches] = useState<{
    [key: string]: string
  }>()
  const [file, setFile] = useState<File>()

  const handleColumnMatchComplete = (matches: { [key: string]: string }) => {
    setColumnMatches(matches)
    goToNextStep()
  }

  const close = () => {
    handleClose()
    setOpen(false)
    removeModal()
  }

  return (
    <Modal isOpen={open} toggle={close} centered className="jumbo light-bg">
      <StepGroup>
        <Transition in={currentStep.id === STEP_IDS.UPLOAD}>
          <FileUploadStep
            file={file}
            handleFile={f => setFile(f)}
            handleComplete={goToNextStep}
          />
        </Transition>
        <Transition in={currentStep.id === STEP_IDS.MATCH}>
          <ColumnMatchStep
            handleComplete={handleColumnMatchComplete}
            closeModal={close}
            file={file as File}
          />
        </Transition>
        <Transition in={currentStep.id === STEP_IDS.PROCESS}>
          <ValidateStep
            columnMatches={columnMatches as MatchObject}
            file={file as File}
            closeModal={close}
          />
        </Transition>
      </StepGroup>
    </Modal>
  )
}
