import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Label } from '@/common/label'
import {
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupItem,
  RadioGroupItemContainer,
} from '@/common/radio'

export enum CSV_FORMAT_TYPE {
  RAW = 'raw',
  SPREADSHEET = 'spreadsheet',
}

export type DownloadFormValues = {
  type: CSV_FORMAT_TYPE
}

interface CSVDownloadFormProps {
  onSubmit: SubmitHandler<DownloadFormValues>
}

export const CSVDownloadForm: FC<CSVDownloadFormProps> = ({ onSubmit }) => {
  const { register, handleSubmit, setValue } = useForm<DownloadFormValues>({
    defaultValues: { type: CSV_FORMAT_TYPE.RAW },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup
        defaultValue={CSV_FORMAT_TYPE.RAW}
        {...register('type', {
          onChange: value => setValue('type', value.target.value),
        })}
      >
        <RadioGroupItemContainer>
          <RadioGroupItem value={CSV_FORMAT_TYPE.RAW} id="r1">
            <RadioGroupIndicator />
          </RadioGroupItem>
          <Label htmlFor="r1">Raw Data</Label>
        </RadioGroupItemContainer>
        <RadioGroupItemContainer>
          <RadioGroupItem value={CSV_FORMAT_TYPE.SPREADSHEET} id="r2">
            <RadioGroupIndicator />
          </RadioGroupItem>
          <Label htmlFor="r2">Spreadsheet</Label>
        </RadioGroupItemContainer>
      </RadioGroup>
      <Button
        mode="filled"
        size="md"
        type="submit"
        css={{
          mt: '$4',
        }}
      >
        Confirm
      </Button>
    </form>
  )
}
