import { FC } from 'react'
import { TableFilterPills } from '@/common/filters/TableFilterPills'
import { useAdditionalFilters } from '@/common/filters/useAdditionalFilters'
import { useInstantCardsOrderStore } from '@/store/instant-cards-orders'
import { additionalFilters } from '../lib/filters'

export const InstantCardOrdersFilters: FC = () => {
  const { filters, updateFilters } = useInstantCardsOrderStore()
  const { toggleAdditionalFilters } = useAdditionalFilters()

  return (
    <TableFilterPills
      config={additionalFilters}
      filters={filters}
      handleItemRemove={id => updateFilters({ [id]: undefined })}
      handleItemClick={id => toggleAdditionalFilters(true, id)}
    />
  )
}
