import { CheckIcon, Cross1Icon, TrashIcon } from '@radix-ui/react-icons'
import moment, { Moment } from 'moment'
import { FC, useCallback, useState } from 'react'
import { SingleDatePicker } from '@/common/datepicker'
import { Flex, FlexWrap } from '@/common/layout'
import {
  IPayrollRecord,
  useDeletePayPeriod,
  useUpdatePayPeriod,
} from '@/queries/payroll'
import { PayrollRecordAction } from './PayrollRecordAction'
import { DatePickerContainer } from './styles'

interface Props {
  deadline: Moment
  payDate: Moment
  id: number
  saveState: 'save' | 'pending'
  setPendingPayroll: (pendingPayroll: IPayrollRecord[]) => void
  payRoll: IPayrollRecord[]
}

export const PayrollRecordRow: FC<Props> = ({
  deadline,
  payDate,
  id,
  saveState,
  setPendingPayroll,
  payRoll,
}) => {
  const [deadlineValue, setDeadlineValue] = useState(deadline)
  const [payValue, setPayValue] = useState(payDate)
  const updatePayPeriod = useUpdatePayPeriod()
  const deletePayPeriod = useDeletePayPeriod()
  const [pristine, setPristine] = useState(true)

  const handleXClick = useCallback(() => {
    if (!pristine) {
      setDeadlineValue(deadline)
      setPayValue(payDate)
      setPristine(true)
    } else if (pristine && saveState === 'save') {
      deletePayPeriod.mutate(id)
    } else if (pristine && saveState === 'pending') {
      const updatedPayRoll = payRoll.filter(record => record.id !== id)
      setPendingPayroll(updatedPayRoll)
    }
  }, [
    deadline,
    deletePayPeriod,
    id,
    payDate,
    pristine,
    saveState,
    payRoll,
    setPendingPayroll,
  ])

  const handleConfirmClick = () => {
    setPristine(true)
    if (saveState === 'save') {
      updatePayPeriod.mutate({ id, deadline: deadlineValue, payDate: payValue })
    } else {
      const index = payRoll.findIndex(record => record.id === id)
      payRoll[index] = { id, deadline: deadlineValue, payDate: payValue }
      setPendingPayroll([...payRoll])
    }
  }

  const handleSetDeadline = useCallback((date: Date) => {
    if (!!date) {
      setDeadlineValue(moment(date))
      setPristine(false)
    }
  }, [])

  const handleSetPay = useCallback((date: Date) => {
    if (!!date) {
      setPayValue(moment(date))
      setPristine(false)
    }
  }, [])

  const handleInputFieldFocus = useCallback((id: string) => {}, [])

  return (
    <FlexWrap direction="row" align="start" css={{ padding: '$4' }}>
      <DatePickerContainer
        id={`deadlineDate${id}`}
        onClick={() => handleInputFieldFocus(`deadlineDate${id}`)}
      >
        <SingleDatePicker
          value={deadlineValue.toDate() || null}
          onChange={handleSetDeadline}
          clearIcon={null}
        />
      </DatePickerContainer>
      <DatePickerContainer
        id={`payrollDate${id}`}
        onClick={() => handleInputFieldFocus(`payrollDate${id}`)}
      >
        <SingleDatePicker
          value={payValue.toDate() || null}
          onChange={handleSetPay}
        />
      </DatePickerContainer>
      <Flex direction="row" justify="evenly" css={{ width: '25%' }}>
        <PayrollRecordAction
          icon={
            pristine ? (
              <TrashIcon style={{ transform: 'scale(1.75)' }} />
            ) : (
              <Cross1Icon style={{ transform: 'scale(1.5)' }} />
            )
          }
          tooltip={pristine ? 'Delete' : 'Cancel'}
          handleClick={handleXClick}
        />
        {!pristine && (
          <PayrollRecordAction
            icon={<CheckIcon style={{ transform: 'scale(1.75)' }} />}
            tooltip="Save"
            handleClick={handleConfirmClick}
          />
        )}
      </Flex>
    </FlexWrap>
  )
}
