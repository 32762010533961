import {
  IGetRegistrationsResponse,
  IProcessorRegistrationConfig,
  ISftpRegistrationConfig,
} from '@/api/types/settings'
import { IProcessor } from '@/types/settings'
import http from './http'

/**
 * Allows the user to get a list of available processors
 */
export const getProcessors = () => http.get<IProcessor[]>(`wfm/processors`)

/**
 * Allows the user to create a new SFTP file processor in our system
 * @param config a configuration object that contains the file processor details and other required information.
 */
export const addSftpRegistration = (
  config: ISftpRegistrationConfig,
  orgId: number
) => http.post(`wfm/organizations/${orgId}/registrations/s3`, config)

/**
 * Allows the user to create a new email file processor in our system
 * @param config a configuration object that contains the file processor details and other required information.
 */
export const addEmailRegistrationProcessor = (
  config: IProcessorRegistrationConfig,
  orgId: number
) =>
  http.post(
    `wfm/organizations/${orgId}/registrations/emails/${config.registrationId}/processors`,
    {
      regex: config.regex,
      effective_date: config.effectiveDate,
      processor_name: config.processorName,
    }
  )

/**
 * Allows the user to create a new email file processor in our system
 * @param config a configuration object that contains the file processor details and other required information.
 */
export const addSftpRegistrationProcessor = (
  config: IProcessorRegistrationConfig,
  orgId: number
) =>
  http.post(
    `wfm/organizations/${orgId}/registrations/s3/${config.registrationId}/processors`,
    {
      regex: config.regex,
      effective_date: config.effectiveDate,
      processor_name: config.processorName,
    }
  )

/**
 * Allows the user to retrieve all registrations from the wfm service
 */
export const getRegistrations = (orgId: number) =>
  http.get<IGetRegistrationsResponse>(
    `wfm/organizations/${orgId}/registrations`
  )

/**
 * Deletes an email file processing registration
 */
export const deleteEmailRegistration = (id: number, orgId: number) =>
  http.delete(`wfm/organizations/${orgId}/registrations/emails/${id}`)

/**
 * Deletes an SFTP file processing registration
 */
export const deleteSftpRegistration = (id: number, orgId: number) =>
  http.delete(`wfm/organizations/${orgId}/registrations/s3/${id}`)

/**
 * Deletes an email file processor
 */
export const deleteEmailProcessor = (
  id: number,
  registrationId: number,
  orgId: number
) =>
  http.delete(
    `wfm/organizations/${orgId}/registrations/emails/${registrationId}/processors/${id}`
  )

/**
 * Deletes an SFTP file processor
 */
export const deleteSftpProcessor = (
  id: number,
  registrationId: number,
  orgId: number
) =>
  http.delete(
    `wfm/organizations/${orgId}/registrations/s3/${registrationId}/processors/${id}`
  )
