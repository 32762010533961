import { FC, useMemo, useState } from 'react'
import { Dialog } from '@/common/modal'
import { usePermissionsQuery } from '@/queries/permissions'
import { useModalStore } from '@/store/modal'
import { OrganizationRole } from '@/types/permissions'
import { ConfigureRole } from './ConfigureRole'

interface Props {
  isCreate?: boolean
  role?: OrganizationRole
}

export const ConfigureRoleModal: FC<Props> = ({ role, isCreate = false }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const permissionsQuery = usePermissionsQuery()

  const permissions = useMemo(() => permissionsQuery.data?.content || [], [
    permissionsQuery,
  ])

  const rolePermissions = useMemo(() => {
    const permissions = permissionsQuery.data?.content || []
    return permissions.filter(permission =>
      role?.permissions.includes(permission.name)
    )
  }, [role, permissionsQuery])

  const close = () => {
    setOpen(false)
    removeModal()
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title={`${isCreate ? 'Create' : 'Edit'} Role`}
    >
      <ConfigureRole
        permissions={permissions}
        rolePermissions={rolePermissions}
        role={role}
        isCreate={isCreate}
        handleClose={close}
      />
    </Dialog>
  )
}
