import { FC, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { ModalContainer } from '@/components/modals/ModalContainer'
import { Toaster } from '@/components/toaster'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { NavigationContainer } from '../nav/NavigationContainer'
import { PageShell } from './PageShell'

export const PageContainer: FC = ({ children }) => {
  const location = useLocation()
  const org = useCurrentOrg()

  useEffect(() => {
    const path = location.pathname.replace(`/${org.org_key}/`, '')
    sendAnalyticsEvent('PAGEVIEW', path)
  }, [location.pathname, org.org_key])

  return (
    <PageShell>
      <NavigationContainer />
      <Toaster />
      <ModalContainer />

      {children}
    </PageShell>
  )
}
