type Props = React.SVGAttributes<SVGSVGElement>

const SvgTableEmpty = (props: Props) => (
  <svg width={63} height={88} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M63 9H3c-.552 0-1 .435-1 .972v68.056c0 .537.448.972 1 .972h60V9z"
      />
      <path
        fill="#E6E6E6"
        fillRule="nonzero"
        d="M2 7h60.989v74H2a2 2 0 01-2-2V9a2 2 0 012-2zm61 2H3c-.552 0-1 .435-1 .972v68.056c0 .537.448.972 1 .972h60V9z"
      />
      <rect width={24} height={2} x={6} y={12} fill="#999" rx={1} />
      <g transform="translate(6 20)">
        <g fill="#E6E6E6" transform="translate(10.667 .667)">
          <rect width={16} height={2.667} rx={1.333} />
          <rect width={8} height={2.667} y={4} rx={1.333} />
        </g>
        <rect
          width={15}
          height={2.667}
          x={30}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <rect
          width={9}
          height={2.667}
          x={48}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <circle cx={4} cy={4} r={4} fill="#E6E6E6" />
        <path
          fill="#999"
          d="M4 4.667A1.333 1.333 0 114 2a1.333 1.333 0 010 2.667zm-2.667 2C1.333 5.78 3.11 5.333 4 5.333c.89 0 2.667.447 2.667 1.334v.302S5.526 8 4 8C2.474 8 1.333 6.969 1.333 6.969v-.302z"
        />
      </g>
      <g transform="translate(6 35)">
        <g fill="#E6E6E6" transform="translate(10.667 .667)">
          <rect width={16} height={2.667} rx={1.333} />
          <rect width={8} height={2.667} y={4} rx={1.333} />
        </g>
        <rect
          width={15}
          height={2.667}
          x={30}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <rect
          width={9}
          height={2.667}
          x={48}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <circle cx={4} cy={4} r={4} fill="#E6E6E6" />
        <path
          fill="#999"
          d="M4 4.667A1.333 1.333 0 114 2a1.333 1.333 0 010 2.667zm-2.667 2C1.333 5.78 3.11 5.333 4 5.333c.89 0 2.667.447 2.667 1.334v.302S5.526 8 4 8C2.474 8 1.333 6.969 1.333 6.969v-.302z"
        />
      </g>
      <g transform="translate(6 50)">
        <g fill="#E6E6E6" transform="translate(10.667 .667)">
          <rect width={16} height={2.667} rx={1.333} />
          <rect width={8} height={2.667} y={4} rx={1.333} />
        </g>
        <rect
          width={15}
          height={2.667}
          x={30}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <rect
          width={9}
          height={2.667}
          x={48}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <circle cx={4} cy={4} r={4} fill="#E6E6E6" />
        <path
          fill="#999"
          d="M4 4.667A1.333 1.333 0 114 2a1.333 1.333 0 010 2.667zm-2.667 2C1.333 5.78 3.11 5.333 4 5.333c.89 0 2.667.447 2.667 1.334v.302S5.526 8 4 8C2.474 8 1.333 6.969 1.333 6.969v-.302z"
        />
      </g>
      <g transform="translate(6 65)">
        <g fill="#E6E6E6" transform="translate(10.667 .667)">
          <rect width={16} height={2.667} rx={1.333} />
          <rect width={8} height={2.667} y={4} rx={1.333} />
        </g>
        <rect
          width={15}
          height={2.667}
          x={30}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <rect
          width={9}
          height={2.667}
          x={48}
          y={0.5}
          fill="#E6E6E6"
          rx={1.333}
        />
        <circle cx={4} cy={4} r={4} fill="#E6E6E6" />
        <path
          fill="#999"
          d="M4 4.667A1.333 1.333 0 114 2a1.333 1.333 0 010 2.667zm-2.667 2C1.333 5.78 3.11 5.333 4 5.333c.89 0 2.667.447 2.667 1.334v.302S5.526 8 4 8C2.474 8 1.333 6.969 1.333 6.969v-.302z"
        />
      </g>
      <rect width={12} height={2} x={36} y={12} fill="#999" rx={1} />
      <rect width={6} height={2} x={54} y={12} fill="#999" rx={1} />
      <rect width={1} height={88} x={62.011} fill="#D8D8D8" rx={0.5} />
    </g>
  </svg>
)

export default SvgTableEmpty
