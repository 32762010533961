import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { Alert } from '@/common/alert'
import { SearchDropdownContainer } from '@/components/search-dropdown-field'
import { useDebounce } from '@/hooks/useDebounce'
import { useEmployeeSelectQuery } from '@/queries/employees'
import useEscapeClickHandler from '@/src/hooks/useEscapeClickHandler'
import useOutsideClickHander from '@/src/hooks/useOutsideClickHandler'
import RosterList from './RosterList'
import RosterSelectField from './RosterSelectField'
import { IListItem } from './types'

interface Props {
  setFieldValue: (id: string) => void
  name: string
}

const RosterSelect: FC<Props> = props => {
  const { setFieldValue, name } = props
  const [selectedItem, setSelectedItem] = useState<IListItem>()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState(selectedItem?.id || '')

  const ref = useRef<HTMLDivElement>(null)

  const nameOrId = useDebounce(search, 500)
  const { isLoading, data, error } = useEmployeeSelectQuery({
    name_or_employee_id: nameOrId || undefined,
  })

  const employees: IListItem[] = useMemo(
    () =>
      (data?.content || []).map(
        ({ first_name, last_name, employee_id, user }) => ({
          name: `${first_name} ${last_name}`,
          id: employee_id as string,
          taken: !!user,
        })
      ),
    [data]
  )

  const hide = useCallback(() => {
    if (open) setOpen(false)
  }, [open, setOpen])

  useEscapeClickHandler(hide)
  useOutsideClickHander(ref, hide, open)

  const handleSelect = (item: IListItem) => {
    setFieldValue(item.id)
    setSelectedItem(item)
    setOpen(false)
  }

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  if (error) {
    return (
      <Alert type="danger">
        Failed to fetch roster. Please contact support.
      </Alert>
    )
  }

  return (
    <SearchDropdownContainer ref={ref}>
      <RosterSelectField
        selectedItem={selectedItem}
        handleClick={() => setOpen(true)}
      />
      {open && (
        <RosterList
          handleSelect={handleSelect}
          loadingData={isLoading}
          handleSearch={handleSearch}
          search={search}
          list={employees}
          name={name}
          selected={selectedItem}
        />
      )}
      {selectedItem?.newEntry && (
        <Alert type="info" css={{ mt: '$8' }}>
          Heads up: this worker is not verified by any roster we have received.
          If they are not included on any future roster you upload, they will
          lose access.
        </Alert>
      )}
    </SearchDropdownContainer>
  )
}

export default RosterSelect
