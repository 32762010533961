import { FC } from 'react'
import { AdvanceRecord } from '@/types/records'

interface Props {
  original: AdvanceRecord
}

export const AdvancePaidCell: FC<Props> = ({ original }) => {
  const { amount, outstanding_balance } = original
  if (amount === 0) return <>N/A</>
  if (outstanding_balance === 0) return <>Yes</>
  if (outstanding_balance < amount) return <>Partial</>
  return <>No</>
}
