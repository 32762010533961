type Props = React.SVGAttributes<SVGSVGElement>

const ArrowLeftIcon = (props: Props) => (
  <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      d="M8.705 15.295a1 1 0 00-.002-1.413L3.83 9h11.174c.55 0 .996-.444.996-1 0-.552-.448-1-.996-1H3.83l4.882-4.873A.999.999 0 008.71.71a1.01 1.01 0 00-1.42 0L.71 7.29a1.01 1.01 0 000 1.42l6.58 6.58a.998.998 0 001.415.005z"
      fill="#212121"
      fillRule="evenodd"
    />
  </svg>
)

export default ArrowLeftIcon
