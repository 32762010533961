import moment from 'moment'
import { FC } from 'react'
import { toDollar } from '@/lib/currency'
import { IRemittance } from '@/types/remittances'
import styles from '../index.module.scss'

interface Props {
  data: IRemittance
}

export const CompletedRemittanceDetailsAside: FC<Props> = ({ data }) => (
  <>
    <h3 className="mb-4">Remittance summary</h3>
    <div className={styles.AsideLineItem}>
      <div className={styles.AsideLineItem_Description}>
        <p>Payroll Remittance ID</p>
      </div>
      <div className={styles.AsideLineItem_Value}>{data.id}</div>
    </div>
    <div className={styles.AsideLineItem}>
      <div className={styles.AsideLineItem_Description}>
        <p>Payroll Pay Date</p>
      </div>
      <div className={styles.AsideLineItem_Value}>
        {moment(data.pay_date).format('MMM Do, YYYY')}
      </div>
    </div>
    <hr />
    <div className={styles.AsideLineItem}>
      <div className={styles.AsideLineItem_Description}>
        <h5>Total advanced</h5>
      </div>
      <div className={styles.AsideLineItem_Value}>
        {toDollar(data.requested_amount)}
      </div>
    </div>
    <hr />
    <div className={styles.AsideLineItem}>
      <div className={styles.AsideLineItem_Description}>
        <h5 className="font-weight-bold">Total amount invoiced</h5>
      </div>
      <div className={styles.AsideLineItem_Value}>
        {toDollar(data.actual_amount)}
      </div>
    </div>
  </>
)
