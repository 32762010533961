import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import {
  DropdownContent,
  DropdownIconTrigger,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/common/dropdown'
import { FlexGrid } from '../layout'

interface Props {
  menu: {
    label: string
    handleClick: () => void
    hide?: boolean
    icon?: JSX.Element
  }[]
  disabled?: boolean
}

export const TableActionDropdown: FC<Props> = ({ menu, disabled = false }) => (
  <DropdownMenu modal={false}>
    <DropdownMenuTrigger
      asChild
      onClick={e => e.stopPropagation()}
      disabled={disabled}
    >
      <DropdownIconTrigger>
        <DotsHorizontalIcon style={{ transform: 'scale(1.75)' }} />
      </DropdownIconTrigger>
    </DropdownMenuTrigger>
    <DropdownContent side="left">
      {menu
        .filter(option => !option.hide)
        .map(option => (
          <DropdownMenuItem
            key={option.label}
            onClick={e => {
              option.handleClick()
              e.stopPropagation()
            }}
          >
            <FlexGrid>
              {option?.icon}
              {option.label}
            </FlexGrid>
          </DropdownMenuItem>
        ))}
    </DropdownContent>
  </DropdownMenu>
)
