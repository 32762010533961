import { ArrowLeftIcon } from '@radix-ui/react-icons'
import moment from 'moment'
import { FC } from 'react'
import { IconButton } from '@/common/button'
import { formatPhoneNumber } from '@/lib/phoneLib'
import { useOrgUser } from '@/queries/employees'
import { UserEnrollmentRequest } from '@/types/employees'
import { CloseIcon } from '../icons'
import { Loader } from '../loader'
import { DetailActions } from '.'
import { DetailProperty } from './components/DetailProperty'
import {
  DetailHeader,
  DetailHeading,
  DetailSection,
  DetailSeperator,
} from './components/styles'

interface Props {
  user: UserEnrollmentRequest
  moveBack?: boolean
  remove: () => void
}

export const UserRequestDetails: FC<Props> = ({ moveBack, user, remove }) => {
  const { data, isLoading } = useOrgUser(`${user.user_id}`)

  if (isLoading || !data) {
    return <Loader loading />
  }

  return (
    <>
      <DetailHeader>
        {moveBack && (
          <DetailActions>
            <IconButton onClick={() => remove()}>
              <ArrowLeftIcon />
            </IconButton>
          </DetailActions>
        )}
        <DetailHeading>{`${data.firstName} ${data.lastName}`}</DetailHeading>
        {!moveBack && (
          <DetailActions>
            <IconButton onClick={() => remove()}>
              <CloseIcon />
            </IconButton>
          </DetailActions>
        )}
      </DetailHeader>
      <DetailSeperator />
      <DetailSection>
        <DetailProperty
          label="Phone"
          value={formatPhoneNumber(data.phone)}
          canCopy
        />
        <DetailProperty label="Email" value={data.emailAddress} canCopy />

        <DetailProperty
          label="Wallet Created"
          value={!!data.walletId ? 'Yes' : 'No'}
        />
        <DetailProperty
          label="Active Card"
          value={data.hasActivatedCard ? 'Yes' : 'No'}
        />
        <DetailProperty label="Minor" value={data.minor ? 'Yes' : 'No'} />

        <DetailProperty label="Status" value={'Enrollment Requested'} />
        <DetailProperty
          label="Wallet Created Date"
          value={data.joined ? moment(data.joined).format('MM/DD/YYYY') : null}
        />
      </DetailSection>
    </>
  )
}
