import { FC } from 'react'
import { Props as TooltipProps, Tooltip } from './Tooltip'

interface Props extends TooltipProps {
  showTooltip: boolean
}

export const ConditionalTooltip: FC<Props> = ({ showTooltip, ...props }) => {
  if (!showTooltip) return props.trigger

  return <Tooltip {...props} />
}
