import { Moment } from 'moment'
import { FC } from 'react'
import { Tooltip } from '@/common/tooltip'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { downloadDDForm } from '@/lib/downloadDDForm'
import { useToasterStore } from '@/store/toast'
import { IOrganizationEmployee } from '@/types/employees'

interface Props {
  user: IOrganizationEmployee
  value?: Moment
}

export const DirectDepositWidget: FC<Props> = ({
  user: { direct_deposit_form, employee_id },
  value,
}) => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()

  const handleDownload = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
    if (!direct_deposit_form || !employee_id) return
    try {
      await downloadDDForm(direct_deposit_form, employee_id, orgId)
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Download Failed',
        description:
          "We weren't able to download the verification file at this time.",
      })
    }
  }

  if (!direct_deposit_form) return null

  return (
    <Tooltip
      side="left"
      trigger={
        <div className="link" onClick={handleDownload}>
          {value && value.format('MM/DD/YYYY')}
        </div>
      }
    >
      Download form
    </Tooltip>
  )
}
