import { SortingState } from '@tanstack/react-table'
import { useState } from 'react'
import { getPaybacks } from '@/api/payouts'
import { GetPaybackParams } from '@/api/types/paybacks'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useToasterStore } from '@/store/toast'
import { downloadCSV } from './download'

interface Props {
  filtered: TableFilters
  sorted: SortingState
}

export const useDownloadPaybacks = ({ filtered, sorted }: Props) => {
  const [loading, setLoading] = useState(false)
  const { addToast, expireToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()

  const handleDownload = async () => {
    const { from, to, amount, ...filters } = filtered

    const params: GetPaybackParams = {
      time_created_start: from?.format(),
      time_created_end: to?.format(),
      size: 1000,
      amount_max: amount,
      amount_min: amount,
      sort: sorted[0].id,
      direction: sorted[0].desc ? 'desc' : 'asc',
      ...filters,
    }

    let toastId
    setLoading(true)

    try {
      const res = await getPaybacks(orgId, params)
      const pageCount = res.data.total_pages
      let results = [...res.data.content]

      if (pageCount > 1) {
        // Create an array with x number of items where
        // value increments by 1 for each item, starting at 0,
        // so that we can map over these to create each request.
        const additionalPages = pageCount - 1
        const pageKeyArray = [...Array(additionalPages).keys()]

        toastId = addToast({
          type: 'pending',
          title: 'Downloading invoices',
          description: `We're fetching ${res.data.total_number_of_elements} invoices, it might take a moment.`,
        })

        const additionalRequests = pageKeyArray.map(x =>
          getPaybacks(orgId, {
            ...params,
            page: 2 + x,
          })
        )
        const responses = await Promise.all(additionalRequests)
        responses.forEach(response => {
          results = [...results, ...response.data.content]
        })
      }
      downloadCSV(results)
    } catch (error) {
      addToast({
        type: 'error',
        title: "Couldn't download results.",
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      if (toastId) {
        expireToast(toastId, 0)
      }
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
