import { EyeClosedIcon, EyeOpenIcon, TrashIcon } from '@radix-ui/react-icons'
import { FC, useCallback, useMemo, useState } from 'react'
import { IconButton } from '@/common/button'
import { Card } from '@/common/card'
import { Checkbox } from '@/common/checkbox'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { Loader } from '@/common/loader'
import {
  useRegisterWebhookEvent,
  useRemoveWebhookDefinition,
  useRemoveWebhookRegistration,
  useWebhookRegistrations,
} from '@/queries/webhooks'
import { WebhookDefinition as WebhookDefinitionType } from '@/types/webhooks'
import { VisibilityButton } from './styles'

interface Props {
  data: WebhookDefinitionType
  events?: string[]
}

export const WebhookDefinition: FC<Props> = ({
  data: { id, url, encryption_key },
  events,
}) => {
  const { data: registeredEvents, isLoading } = useWebhookRegistrations(id)
  const deleteWebhook = useRemoveWebhookDefinition()
  const removeEvent = useRemoveWebhookRegistration()
  const registerEvent = useRegisterWebhookEvent()
  const [keyVisible, setKeyVisible] = useState(false)

  const eventIds = useMemo(
    () => (registeredEvents || []).map(rec => rec.event_type),
    [registeredEvents]
  )

  const readableEvents = useMemo(
    () =>
      (events || []).map(rec => ({ id: rec, label: rec.replaceAll('_', ' ') })),
    [events]
  )

  const onSelectEvent = useCallback(
    (event: string) => {
      const registration = (registeredEvents || []).find(
        rec => rec.event_type === event
      )
      if (registration) {
        removeEvent.mutate({
          definitionId: id,
          registrationId: registration.id,
        })
      } else {
        registerEvent.mutate({ definitionId: id, event_type: event })
      }
    },
    [id, registeredEvents, registerEvent, removeEvent]
  )

  return (
    <Card style={{ marginBottom: '16px' }}>
      <Loader loading={isLoading}>
        <div>
          <div>
            <Label>Webhook</Label>
            <p>{url}</p>
          </div>
          <div>
            <Label>Encryption Key</Label>
            <Flex>
              <Input
                value={encryption_key}
                disabled
                type={keyVisible ? 'text' : 'password'}
                css={{ maxWidth: '400px' }}
              />
              <VisibilityButton
                type="button"
                onClick={() => setKeyVisible(!keyVisible)}
              >
                {keyVisible ? <EyeClosedIcon /> : <EyeOpenIcon />}
              </VisibilityButton>
            </Flex>
          </div>
          <Box css={{ mt: '$12' }}>
            <Label>Registered Actions</Label>
            {/* <div>
              <Checkbox
                id="ALL"
                label="All events"
                checked={eventIds.includes('ALL')}
                onChange={id => onSelectEvent(id)}
              />
            </div> */}
            {readableEvents.map(rec => (
              <div>
                <Checkbox
                  id={rec.id}
                  key={rec.id}
                  label={rec.label}
                  checked={eventIds.includes(rec.id)}
                  onChange={id => onSelectEvent(id)}
                />
              </div>
            ))}
          </Box>
          <div>
            <Flex justify="end">
              <IconButton
                color="danger"
                tooltip="Delete webhook"
                placement="top"
                disabled={deleteWebhook.isLoading}
                onClick={() => deleteWebhook.mutate(id)}
              >
                <TrashIcon />
              </IconButton>
            </Flex>
          </div>
        </div>
      </Loader>
    </Card>
  )
}
