import { create } from 'zustand'
import { getRouteDomain } from '@/lib/strings'
import { ROUTES } from '@/router/routes'
import { ScopeType, UserPermission, UserRole } from '@/types/permissions'

const enabledOrgs = [
  '100017',
  '100844',
  '100954',
  '100640',
  '100555',
  '100675',
  '100400',
  '100542',
  '100544',
  '100789',
  '100545',
  '100543',
  '100905',
  '100903',
  '100848',
  '101002',
  '100931',
  '101003',
]

export interface PermissionStore {
  roles: UserRole[]
  permissions: UserPermission[]
  orgId: number | null
  enabledOrgs: string[]
  defaultRoute: string
  setRoles: (roles: UserRole[]) => void
  setPermissions: (permissions: UserPermission[]) => void
  setOrgId: (orgId: number) => void
  computed: {
    isGlobalAdmin: boolean
    isEnabledOrg: boolean
    hasPermission: (permission: string) => boolean
    hasViewPermission: (route: string) => boolean
  }
}

export const usePermissionsStore = create<PermissionStore>((set, get) => ({
  roles: [],
  permissions: [],
  orgId: null,
  enabledOrgs,
  defaultRoute: ROUTES.DISBURSEMENTS,
  setRoles: roles => set(state => ({ ...state, roles })),
  setPermissions: permissions => set(state => ({ ...state, permissions })),
  setOrgId: orgId => set(state => ({ ...state, orgId: orgId })),
  computed: {
    get isGlobalAdmin() {
      return !!get().roles.find(
        r => r.name === 'pay_admin' && r.scope_type === ScopeType.GLOBAL
      )
    },
    get isEnabledOrg() {
      return get().enabledOrgs.includes(`${get().orgId}`)
    },
    get hasPermission() {
      return (permission: string) =>
        !get().computed.isEnabledOrg ||
        !!get().permissions.find(p => p.name === permission)
    },
    get hasViewPermission() {
      return (route: string) => {
        const { hasPermission, isEnabledOrg } = get().computed
        if (route === ROUTES.INVOICES)
          return hasPermission(`${getRouteDomain(route)}.view`)
        if (route === ROUTES.ROLES_PERMISSIONS_SETTINGS)
          return isEnabledOrg ? hasPermission('permission.management') : false
        return true
      }
    },
  },
}))
