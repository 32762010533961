import { FC } from 'react'
import { TableActionDropdown } from '@/common/table/TableActionDropdown'
import { useModalStore } from '@/store/modal'
import { InitiationUserRecord } from '@/types/create-accounts'
import { CancelKycModal } from './CancelKycModal'

interface Props {
  original: InitiationUserRecord
}

export const AccountActionDropdown: FC<Props> = ({ original }) => {
  const { registerModal } = useModalStore()

  const showCancel = ['PENDING', 'PENDING_MANUAL', 'PENDING_REVIEW'].includes(
    original.status
  )

  if (!showCancel) {
    return null
  }

  const openCancelModal = () => {
    registerModal(<CancelKycModal record={original} />)
  }

  return (
    <TableActionDropdown
      menu={[
        {
          label: 'Cancel',
          handleClick: openCancelModal,
          hide: !showCancel,
        },
      ]}
    />
  )
}
