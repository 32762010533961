import { SwitchProps } from '@radix-ui/react-switch'
import { forwardRef } from 'react'
import { StyledSwitch, StyledThumb } from './styles'

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  (props, ref) => (
    <StyledSwitch ref={ref} {...props}>
      <StyledThumb />
    </StyledSwitch>
  )
)
