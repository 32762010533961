import * as SwitchPrimitive from '@radix-ui/react-switch'
import { styled } from '@/styles/stitches.config'

export const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: 40,
  height: '$24',
  backgroundColor: '$secondary300',
  borderRadius: '100px',
  position: 'relative',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&:focus': { boxShadow: `0 0 0 2px $colors$secondary800` },
  '&[data-state="checked"]': {
    backgroundColor: '$success500',
    '&:focus': { boxShadow: `0 0 0 2px $colors$success200` },
  },

  '&:disabled': {
    opacity: 0.5,
  },
})

export const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '100px',
  boxShadow: `0 2px 2px $secondary300`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(17px)' },
})
