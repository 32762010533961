import qs from 'qs'
import { GetJobsResponse } from '@/api/types/jobs'
import http from './http'

/**
 * Retrieve organization jobs
 */
export const getOrgJobs = (orgId: number, params: any) =>
  http.get<GetJobsResponse>(
    `/wfm/organizations/${orgId}/jobs?${qs.stringify(params)}`
  )

/**
 * Gets scheduled jobs for the organization
 */
export const getJobs = (params: any) =>
  http.get<GetJobsResponse>(`/wfm/jobs?${qs.stringify(params)}`)

/**
 * Removes a user's record from the roster and removes them from the location
 */
export const retryJob = (jobId: number) => http.put(`/wfm/jobs/${jobId}`)
