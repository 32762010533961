import { FC, useState } from 'react'
import { Loader } from '@/common/loader'
import { Dialog } from '@/common/modal'
import { useIssueinvoice } from '@/queries/invoice-configs'
import { useModalStore } from '@/store/modal'
import { InvoicePaymentType } from '@/types/invoice'
import { AddPaybackConfirmation } from './AddPaybackConfirmation'
import {
  AddInvoiceFormValues,
  AddPaybacksForm,
  defaultValues,
} from './AddPaybacksForm'

const getAmount = (values: AddInvoiceFormValues) => {
  const amount = Math.round(values.amount * 100)
  return values.payment_type === InvoicePaymentType.CREDIT ? -amount : amount
}

const getType = (values: AddInvoiceFormValues) => {
  return values.payment_type === InvoicePaymentType.CREDIT
    ? 'REFUND'
    : 'REMITTANCE'
}

export const AddPaybacksModal: FC = () => {
  const [open, setOpen] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [invoiceFormValues, setInvoiceFormValues] = useState<
    AddInvoiceFormValues
  >(defaultValues)
  const { removeModal } = useModalStore()
  const issueInvoice = useIssueinvoice()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleSubmit = (values: AddInvoiceFormValues) => {
    setShowConfirmation(true)
    setInvoiceFormValues(values)
  }

  const handleBack = () => {
    setShowConfirmation(false)
  }

  const handleConfirmed = async () => {
    const payload = {
      description: invoiceFormValues.reason,
      funding_source_id: +invoiceFormValues.funding_source_id,
      line_items: [
        {
          amount: getAmount(invoiceFormValues),
          type: getType(invoiceFormValues),
          description: invoiceFormValues.reason,
        },
      ],
    }
    issueInvoice.mutate(payload, {
      onSuccess: close,
    })
  }

  return (
    <Dialog open={open} setOpen={close} title="Add Invoice">
      <Loader loading={issueInvoice.isLoading}>
        <div className={showConfirmation ? 'd-none' : ''}>
          <AddPaybacksForm onSubmit={handleSubmit} />
        </div>
      </Loader>
      {showConfirmation && (
        <AddPaybackConfirmation
          values={invoiceFormValues}
          handleSubmit={handleConfirmed}
          handleBack={handleBack}
          loading={issueInvoice.isLoading}
        />
      )}
    </Dialog>
  )
}
