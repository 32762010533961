import { FC } from 'react'
import {
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { AdoptionFilters } from './AdoptionFilters'

export const AdoptionContainer: FC = ({ children }) => (
  <>
    <PageTop>
      <PageHeader>
        <PageTitle primary="Adoption Insights" />
      </PageHeader>
      <PageFilters>
        <AdoptionFilters />
      </PageFilters>
    </PageTop>
    <PageBody>{children}</PageBody>
  </>
)
