import { useQuery } from 'react-query'
import { validateDisbursements } from '@/api/file-validation'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

export const VALIDATE_BULK_DISBURSEMENTS = 'validate-bulk-disbursements'

export const useValidateBulkDisbursements = (file: File) => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([VALIDATE_BULK_DISBURSEMENTS, orgId, file], async () => {
    const { data } = await validateDisbursements(orgId, file)
    return data
  })
}
