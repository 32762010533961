import { SortingState } from '@tanstack/react-table'
import { useQuery } from 'react-query'
import { getTimeAndAttendanceTable } from '@/api/time-attendance'
import { getDateRange } from '@/common/datepicker/utils'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

type TimekeepingQueryConfig = {
  filters: TableFilters
  utcOffset?: number | string
  page: number
  sorted: SortingState
  pageSize: number
}

export const TIMEKEEPING_QUERY = 'timekeeping-query'

export const useTimekeepingQuery = (config: TimekeepingQueryConfig) => {
  const { page, sorted, filters, utcOffset, pageSize } = config
  const { id: orgId } = useCurrentOrg()
  const { name, employee_id, from, to } = filters

  const { fromDate, toDate } = getDateRange(from, to, utcOffset)

  const params = {
    start_time: fromDate,
    end_time: toDate,
    name,
    employee_id,
    page: page + 1,
    sort: sorted[0].id,
    size: pageSize,
    direction: sorted[0].desc ? 'desc' : 'asc',
  }

  return useQuery([TIMEKEEPING_QUERY, orgId, params], async () => {
    const res = await getTimeAndAttendanceTable(orgId, params)
    return res.data.data
  })
}
