import { FC } from 'react'
import { Button } from '@/common/button'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { usePayroll } from '@/queries/payroll'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { PayrollAdmin } from './PayrollAdmin'

export const PayrollContainer: FC = ({ children }) => {
  const payrollData = usePayroll()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)
  const { registerModal } = useModalStore()

  const openPayrollAdminModal = () => {
    registerModal(<PayrollAdmin />)
  }

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Payroll" />
          {isGlobalAdmin && (
            <PageActions>
              <Button mode="filled" onClick={openPayrollAdminModal} iconRight>
                Manage Payroll
                <AdminIcon />
              </Button>
            </PageActions>
          )}
        </PageHeader>
      </PageTop>
      <PageBody hasError={payrollData.isError}>{children}</PageBody>
    </>
  )
}
