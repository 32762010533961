import { FC, useState } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { useInvoiceTransition } from '@/queries/invoice-configs'
import { useModalStore } from '@/store/modal'
import { PaybackRecord, PaybackStatus } from '@/types/paybacks'

interface Props {
  record: PaybackRecord
}

export const MarkPaybackFailedModal: FC<Props> = ({ record }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const invoiceTransition = useInvoiceTransition()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const complete = async () => {
    invoiceTransition.mutate(
      {
        invoiceId: record.id,
        status: PaybackStatus.FAILED,
      },
      {
        onSuccess: close,
      }
    )
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Mark As Failed"
      description="This invoice will be marked as failed and will stay in this state unless manually retried."
    >
      {invoiceTransition.isError && (
        <Alert type="danger">Encountered an error.</Alert>
      )}
      <Flex justify="end">
        <Button
          mode="filled"
          onClick={complete}
          loading={invoiceTransition.isLoading}
          css={{ mt: '$24' }}
        >
          Mark As Failed
        </Button>
      </Flex>
    </Dialog>
  )
}
