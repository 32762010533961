import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createSubAccount, getSubAccount } from '@/api/sub-account'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

export const GET_SUB_ACCOUNT_QUERY = 'get-sub-account'

export const useSubAccountQuery = () => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([GET_SUB_ACCOUNT_QUERY, orgId], async () => {
    const { data } = await getSubAccount(orgId)
    return data.data
  })
}

export const useCreateSubAccountMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()

  return useMutation(() => createSubAccount(orgId), {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_SUB_ACCOUNT_QUERY)
    },
  })
}
