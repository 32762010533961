import { FC, useMemo } from 'react'
import { Alert, Card, CardBody } from 'reactstrap'
import { ILocationResult } from '@/api/types/location'
import { Button } from '@/common/button'
import { Loader } from '@/common/loader'
import { SimpleTable } from '@/common/table/SimpleTable'
import {
  Step,
  StepBody,
  StepFooter,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { columns } from './lib/columns'
import { IReviewRow, STATUS_TYPES } from './lib/types'
import LocationReview from './LocationReview'

interface Props {
  csvData: string[][]
  returnedRows: ILocationResult[]
  csvSucceeded: boolean
  handleClose: () => void
  loading: boolean
  transition?: string
  secondaryNs: string
}

const getStatus = (code: number): STATUS_TYPES => {
  if (code === 200) {
    return STATUS_TYPES.SUCCEEDED
  }
  if (code === 409) {
    return STATUS_TYPES.REVIEW
  }
  return STATUS_TYPES.FAILED
}

export const LocationUploadReviewStep: FC<Props> = ({
  csvData,
  handleClose,
  secondaryNs,
  returnedRows,
  csvSucceeded,
  loading,
  transition,
}) => {
  const data = useMemo<IReviewRow[]>(() => {
    return (
      csvData
        // chop off the first item in the array
        // since it's column headers
        .slice(1)
        // filter out extraneous rows
        .filter((_, i) => !!returnedRows[i])
        // map to the data model
        .map((col, i) => {
          const { meta, data } = returnedRows[i]
          return {
            name: col[0],
            alternate: col[1],
            address: col[2],
            city: col[3],
            state: col[4],
            postalCode: col[5],
            country: col[6],
            status: getStatus(meta.code),
            message: meta.errorDetail,
            duplicateId: data ? data.existingLocationId : null,
            existingLocation: data ? data.locations_info[0] : null,
          }
        })
    )
  }, [csvData, returnedRows])

  return (
    <Step state={transition}>
      <StepBody>
        <StepTitle>Locations CSV Results</StepTitle>
        <Loader loading={loading}>
          {!loading && (
            <>
              {!csvSucceeded && (
                <Alert color="danger">
                  Your CSV was not parsed correctly. Please make sure it matches
                  the format from the provided template.
                </Alert>
              )}
              <Card className="shadow">
                <CardBody>
                  <SimpleTable
                    data={data}
                    columns={columns}
                    renderSubComponent={row => (
                      <LocationReview
                        reviewRow={row.original}
                        secondaryNs={secondaryNs}
                      />
                    )}
                  />
                </CardBody>
              </Card>
            </>
          )}
        </Loader>
      </StepBody>
      <StepFooter>
        <StepFooterRight>
          <Button mode="filled" onClick={handleClose}>
            Close
          </Button>
        </StepFooterRight>
      </StepFooter>
    </Step>
  )
}
