import { FC } from 'react'
import { PaybackRecord } from '@/types/paybacks'
import { StatusBadge } from './StatusBadge'

interface Props {
  original: PaybackRecord
}

export const StatusCell: FC<Props> = ({ original }) => (
  <StatusBadge payback={original} />
)
