import { FC } from 'react'
import { PaybackRecord } from '@/types/paybacks'
import { ViewDetailsLink } from './ViewDetailsLink'

interface Props {
  original: PaybackRecord
}

export const ViewDetailsCell: FC<Props> = ({ original }) => (
  <ViewDetailsLink payback={original} />
)
