import { blackA } from '@radix-ui/colors'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { keyframes, styled } from '@/styles/stitches.config'

export const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

export const AlertOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  zIndex: 1000,
})

export const AlertDialogContentContainer = styled(
  AlertDialogPrimitive.Content,
  {
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow:
      'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '500px',
    padding: 25,
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&:focus': { outline: 'none' },
    zIndex: 1001,
  }
)

export const AlertDialogTitle = styled(AlertDialogPrimitive.Title, {
  color: '$secondary100',
  fontSize: 17,
  fontWeight: 'bold',
})

export const AlertDialogDescription = styled(AlertDialogPrimitive.Description, {
  marginBottom: 20,
  color: '$secondary300',
  fontSize: 15,
  lineHeight: 1.5,
})

export const DialogOverlay = styled(DialogPrimitive.Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  zIndex: 1000,
})

export const DialogContent = styled(DialogPrimitive.Content, {
  zIndex: 1001,
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: 25,
  '&:focus': { outline: 'none' },
  width: '95vw',
  maxWidth: '600px',
  variants: {
    size: {
      sm: {
        width: '90vw',
        maxWidth: '400px',
      },
      md: {
        width: '95vw',
        maxWidth: '600px',
      },
      lg: {
        width: '95vw',
        maxWidth: '800px',
      },
      xl: {
        width: '95vw',
        maxWidth: '1000px',
      },
      unbound: {
        maxWidth: '95vw',
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export const DialogTitle = styled(DialogPrimitive.Title, {
  margin: 0,
  fontWeight: 'bold',
  color: '$secondary100',
  fontSize: 24,
  mb: '$24',
})

export const DialogDescription = styled(DialogPrimitive.Description, {
  margin: '10px 0 20px',
  color: '$secondary300',
  fontSize: 15,
  lineHeight: 1.5,
})

export const DialogClose = styled(DialogPrimitive.Close, {
  position: 'fixed',
})

export const DialogContainer = styled(DialogPrimitive.Root, {
  width: '100vw',
  height: '100vh',
})

export const AlertDialogContainer = AlertDialogPrimitive.Root
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger
export const AlertDialogAction = AlertDialogPrimitive.Action
export const AlertDialogCancel = AlertDialogPrimitive.Cancel
export const AlertPortal = AlertDialogPrimitive.Portal
export const DialogTrigger = DialogPrimitive.Trigger
export const DialogPortal = DialogPrimitive.Portal
