import { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Spinner } from '@/common/loader'
import { PageContainer } from '@/components/layout/page'
import { identifyUser } from '@/lib/analytics'
import { intercom } from '@/lib/intercom'
import { useUserOrganizationsQuery } from '@/queries/auth'
import { useSettingsQuery } from '@/queries/settings'
import { ROUTES } from '@/router/routes'
import { useUser } from '@/store/user'
import { IOrganization } from '@/types/organization'
import styles from './auth.module.scss'
import { useAuthenticatedUserInitializer } from './hooks/useAuthenticatedUserInitializer'

const AuthenticatedContainer: FC = ({ children }) => {
  useSettingsQuery()
  const history = useHistory()
  const { user, id } = useUser()

  const { data: orgs } = useUserOrganizationsQuery()
  const { isInitialized } = useAuthenticatedUserInitializer()

  useEffect(() => {
    if (isInitialized && user && orgs) {
      const org_keys = orgs.map((p: IOrganization) => p.org_key).join(', ')
      const org_names = orgs.map((p: IOrganization) => p.name).join(', ')
      identifyUser({ user, orgs: orgs.map(p => p.org_key) })
      intercom('boot', {
        app_id: 'mwocdgug',
        user_id: user.user_id,
        name: user.name,
        email: user.email,
        phone: user.phone_number,
        org_keys,
        org_names,
      })
    }
  }, [isInitialized, user, orgs])

  // If no user id present, redirect to login page
  useEffect(() => {
    if (!id) {
      history.push(ROUTES.LOGIN)
    }
  }, [id, history])

  if (!isInitialized) {
    return (
      <div className={styles.PageLoading}>
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <PageContainer>{children}</PageContainer>
    </>
  )
}

export default AuthenticatedContainer
