import { IResponse, PaginatedIndefiniteData } from './common'

export enum ScopeType {
  LOCAL = 'local',
  GLOBAL = 'global',
  ORGANIZATION = 'organization',
}

export interface UserRole {
  id: number
  user_id: number
  role_id: number
  name: string
  description: string
  scope_type: ScopeType
  scope: string | null
}

export interface UserPermission {
  scope_type: ScopeType
  scope: string | null
  name: string
}

export interface UserRoleMetadata {
  id: number
  user_role_id: number
  expiration_date: string
}

export interface UpdateRoleRequest {
  display_name: string
  description: string
  permission_ids: number[]
}

export interface AssignUserRoleRequest {
  role_ids: number[]
  organization_group_ids: string[]
}

export interface CreateUserRoleRequest {
  user_id: number
  role_name: string
  scope_type: ScopeType
  scope?: string
  metadata?: UserRoleMetadata
}

export interface OrganizationRole {
  id: number
  name: string
  description: string
  organization_id: number
  time_created: string
  time_modified: string
  permissions: string[]
}

export interface OrganizationPermission {
  id: number
  name: string
  description: string
  time_created: string
  time_modified: string
}

export type ValidUserRoleParams = 'user_id'

export type GetUserRolesParams = {
  [col in ValidUserRoleParams]?: string | number | null
}

export type ValidPermissionsParams = 'page' | 'size' | 'offset'

export type GetPermissions = {
  [col in ValidPermissionsParams]?: string | number
}

export interface AdminRole {
  id: number
  name: string
  description: string
  scope: string
  scope_type: string
}

export interface OrgGroup {
  id: number
  name: string
}

export interface UserOrgGroup {
  id: number
  name: string
}

export interface UserOrgGroupRestriction {
  organization_group_id: number
  organization_group_name: string
}

export interface AdminPermission {
  id: number
  name: string
  description: string
}

export interface OrgAdmin {
  id: number
  first_name: string
  last_name: string
  email: string
  roles: AdminRole[]
  organization_groups: OrgGroup[]
  permissions: AdminPermission[]
}

export type OrgAdmins = OrgAdmin[]

export type OrgPermissionsResponse = IResponse &
  PaginatedIndefiniteData<OrganizationPermission[]>

export interface AdminInviteRequest {
  role_id: number
  scope_type: ScopeType
  scope: string
  email: string
  organization_group_ids: string[]
}

export type UserOrgGroupsResponse = IResponse &
  PaginatedIndefiniteData<UserOrgGroup[]>
