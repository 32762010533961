import classnames from 'classnames'

type Props = {
  icon?: React.ReactNode
  title: string
  subtitle?: string
  height?: 'sm' | 'lg' | 'xs'
}

const ContentEmpty: React.FC<Props> = props => {
  const classes = classnames('empty-content-container', {
    'height-xs': props.height === 'xs',
    'height-sm': props.height === 'sm',
    'height-lg': props.height === 'lg',
  })
  return (
    <div className={classes}>
      {props.icon}
      <h2>{props.title}</h2>
      {props.subtitle && <p>{props.subtitle}</p>}
      {props.children}
    </div>
  )
}

ContentEmpty.defaultProps = {
  height: 'sm',
}

export default ContentEmpty
