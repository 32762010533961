import { useMemo } from 'react'
import { Card } from '@/common/card'
import { Label } from '@/common/label'
import { FlexWrap } from '@/common/layout'
import { Loader } from '@/common/loader'
import { Select } from '@/common/select'
import {
  useAssignCardProgramMutation,
  useCardProgramsOrgQuery,
  useCardProgramsQuery,
  useRemoveCardProgramMutation,
} from '@/queries/card-program'
import { useToasterStore } from '@/store/toast'

export const CardProgram = () => {
  const cardPrograms = useCardProgramsQuery()
  const orgCardPrograms = useCardProgramsOrgQuery()
  const assignCardProgram = useAssignCardProgramMutation()
  const removeCardProgram = useRemoveCardProgramMutation()
  const { addToast } = useToasterStore()

  const cardProgramOptions = useMemo(
    () =>
      (cardPrograms.data || []).map(rec => ({
        label: rec.token,
        value: rec.token,
      })),
    [cardPrograms]
  )

  const selectedProgram = useMemo(() => {
    let selectedToken = 'BRANCH_W2'
    if (orgCardPrograms.data && orgCardPrograms.data.length > 0) {
      selectedToken = orgCardPrograms.data[0].token
    }
    return cardPrograms.data?.find(rec => rec.token === selectedToken)
  }, [cardPrograms.data, orgCardPrograms.data])

  const onChange = async (value: string) => {
    const assignedProgram =
      cardPrograms.data?.find(rec => rec.token === value) || ''
    if (!selectedProgram || !assignedProgram) {
      return
    }

    await removeCardProgram.mutateAsync(selectedProgram.token)
    assignCardProgram.mutate(assignedProgram.token, {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: `Card program changed to ${assignedProgram.token}`,
        })
      },
    })
  }
  if (cardPrograms.isLoading || orgCardPrograms.isLoading)
    return <Loader loading={true} />

  return (
    <Card>
      <Label>Card Program</Label>
      <FlexWrap direction="column" align="start" size="sm" css={{ width: 300 }}>
        <Select
          options={cardProgramOptions}
          value={selectedProgram?.token}
          onChange={val => onChange(val)}
          placeholder="Select a Card Program"
        />
      </FlexWrap>
    </Card>
  )
}
