import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { Table } from '@/common/table'
import { useSetting } from '@/hooks/useSetting'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { ROUTES } from '@/router/routes'
import { useTimekeepingQuery } from '@/src/queries/time-attendance'
import { useTimeKeepingStore } from '@/store/time-keeping'
import TimekeepingRecordsContainer from './components/TimekeepingRecordsContainer'
import {
  getTableColumns,
  STATIC_COLUMNS,
  TimekeepingColumn,
} from './lib/columns'

const initialSortedState = [
  {
    id: 'first_name',
    desc: false,
  },
]

export const DEFAULT_TIME_ZONE = '-07:00' // Default time zone when querying timekeeping

export const TimekeepingRecords = () => {
  const isUsingGrossEarnings = useSetting('PERIOD_EARNINGS_FOR_EARNED_WAGES')
  const history = useHistory()
  const [columns, setColumns] = useState<TimekeepingColumn[]>(STATIC_COLUMNS)
  const {
    page,
    pageSize,
    filters,
    sorted,
    setSorted,
    updatePage,
    updatePageSize,
    updateFilters,
    setFilteredData,
  } = useTimeKeepingStore()

  useUpdateQueryParamFilters(filters)

  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
  }, [queryParams, updateFilters])

  useEffect(() => {
    setColumns(
      getTableColumns({
        startDate: filters.from,
        endDate: filters.to,
      })
    )
  }, [filters.from, filters.to])

  const records = useTimekeepingQuery({
    sorted,
    page,
    filters,
    pageSize,
    utcOffset: DEFAULT_TIME_ZONE,
  })

  const rows = useMemo(() => {
    return (records.data?.content || []).map((row: any) => {
      let totalHours = 0
      if (row.tracked_times) {
        totalHours = row.tracked_times.reduce((acc: number, time: any) => {
          return acc + time.hours
        }, 0)
      }
      return { ...row, total_hours: totalHours }
    })
  }, [records.data])

  useEffect(() => {
    if (isUsingGrossEarnings === 'true') history.push(ROUTES.GROSS_EARNINGS)
  }, [history, isUsingGrossEarnings])

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    records.data?.total_pages
  )

  return (
    <TimekeepingRecordsContainer
      filters={filters}
      updateFilters={updateFilters}
      sorted={sorted}
    >
      <Table
        columns={columns}
        data={rows}
        controlledPagination={true}
        onSortedChange={setSorted}
        sorted={sorted}
        initialSortedState={initialSortedState}
        isLoading={records.isLoading}
        page={page}
        pageRows={pageSize}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        changePage={updatePage}
        updatePageSize={updatePageSize}
        setFilteredData={setFilteredData}
        pinnedColumns={{
          left: ['employee_id', 'first_name', 'totalHours'],
        }}
      />
    </TimekeepingRecordsContainer>
  )
}
