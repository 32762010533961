import { DetailCopyableValue } from '@/common/detail'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

export const UniqueLinks = () => {
  const { id: orgId } = useCurrentOrg()

  return (
    <Flex direction="column" align="start">
      <h5>Unique Links</h5>
      <p>Share these links with your user’s to help them onboard correctly.</p>

      <Box css={{ mb: '$24' }}>
        <Label>Web Onboarding</Label>
        <DetailCopyableValue featured title="Web Onboarding Link">
          {`${process.env.REACT_APP_ONBOARDING_URL}?org=${orgId}`}
        </DetailCopyableValue>
        <Text color="hint">
          Allow workers to register for Branch in a secure and easy-to-deploy
          way. By default, the onboarding flow will be cobranded with your logo
          and company name. Information about individuals' accounts will show up
          in Pay Admin once they're registered.
          <br />
          To learn more about customization,{' '}
          <a
            href="https://docs.branchapp.com/hosted-flow"
            target="_blank"
            rel="noreferrer"
          >
            visit our docs
          </a>
          .
        </Text>
      </Box>
      <Box css={{ mb: '$24' }}>
        <Label>Mobile Onboarding Link</Label>
        <DetailCopyableValue featured title="Mobile Onboarding Link">
          {`https://brnch.app.link?$deeplink_path=screens/org-search?id=${orgId}`}
        </DetailCopyableValue>
        <Text color="hint">
          When registering for Branch on the mobile app, users can skip
          organization selection and get right into setting up their account.
        </Text>
      </Box>
    </Flex>
  )
}
