import { yupResolver } from '@hookform/resolvers/yup'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { FC, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Button } from '@/common/button'
import { Input, TextArea } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { ToggleSwitch } from '@/common/toggleswitch/ToggleSwitch'
import { Tooltip } from '@/common/tooltip'
import { useCreateOrgRole, useEditOrgRole } from '@/queries/permissions'
import { OrganizationPermission, OrganizationRole } from '@/types/permissions'

interface Props {
  isCreate: boolean
  handleClose: () => void
  role?: OrganizationRole
  permissions: OrganizationPermission[]
  rolePermissions: OrganizationPermission[]
}

export type ConfigureRoleFormValues = {
  name: string
  description: string
  permissions: string[]
}

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  description: Yup.string().required('Description is required.'),
  permissions: Yup.array(),
})

export const ConfigureRole: FC<Props> = ({
  role,
  permissions,
  rolePermissions,
  isCreate,
  handleClose,
}) => {
  const createOrgRole = useCreateOrgRole()
  const editOrgRole = useEditOrgRole()

  const defaultValues = useMemo(
    () => ({
      name: role ? role.name : '',
      description: role ? role.description : '',
      permissions: isCreate ? [] : rolePermissions.map(p => p.name),
    }),
    [isCreate, role, rolePermissions]
  )

  const isDisabled = useMemo(
    () => (!isCreate && role ? role?.name === 'Pay Admin' : false),
    [isCreate, role]
  )

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset, rolePermissions])

  const switchToggle = (checked: boolean, p: string) =>
    checked
      ? setValue('permissions', [...getValues('permissions'), p])
      : setValue(
          'permissions',
          getValues('permissions').filter(item => item !== p)
        )

  const onSubmit = (values: ConfigureRoleFormValues) => {
    const permissionIds = permissions
      .filter(p => values.permissions.includes(p.name))
      .map(p => p.id)

    if (isCreate) {
      return createOrgRole.mutate(
        {
          display_name: values.name,
          description: values.description,
          permission_ids: permissionIds,
        },
        { onSuccess: handleClose }
      )
    }

    if (role) {
      return editOrgRole.mutate(
        {
          data: {
            display_name: values.name,
            description: values.description,
            permission_ids: permissionIds,
          },
          roleId: role.id,
        },
        { onSuccess: handleClose }
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Flex direction="column" align="start" fullWidth css={{ mb: '$16' }}>
          <Label htmlFor="name">Name</Label>
          <Input
            {...register('name')}
            type="text"
            placeholder="Role Name"
            disabled={isDisabled}
          />
          <Text color="alert" size="sm">
            {errors?.name?.message}
          </Text>
        </Flex>
        <Flex direction="column" align="start" fullWidth css={{ mb: '$16' }}>
          <Label
            htmlFor="description"
            css={{ display: 'flex', alignItems: 'center' }}
          >
            <Text css={{ mr: '$4' }}>Description</Text>
            <Tooltip trigger={<QuestionMarkCircledIcon />}>
              Briefly explain the permissions and responsibilities associated
              with this role.
            </Tooltip>
          </Label>
          <TextArea
            {...register('description')}
            placeholder="Role Description"
            disabled={isDisabled}
          />
          <Text color="alert" size="sm">
            {errors?.description?.message}
          </Text>
        </Flex>
        <Text bold css={{ mb: '$16' }}>
          Permissions
        </Text>
        <Flex align="start" justify="between" css={{ mb: '$8' }}>
          <Flex align="start" direction="column">
            <Label css={{ mt: '$4' }}>Create Disbursements</Label>
            <Text color="hint" size="sm">
              Allow admin to send disbursements to workers.
            </Text>
          </Flex>

          <ToggleSwitch
            toggle={(checked: boolean) => {
              switchToggle(checked, 'disbursement.create')
            }}
            enabled={watch('permissions').includes('disbursement.create')}
            disabled={isDisabled}
          />
        </Flex>
        <Flex align="start" justify="between" css={{ mb: '$8' }}>
          <Flex align="start" direction="column">
            <Label css={{ mt: '$4' }}>Provision/Adjust Admins</Label>
            <Text color="hint" size="sm">
              Allow admin to edit roles and invite team members.
            </Text>
          </Flex>

          <ToggleSwitch
            toggle={(checked: boolean) => {
              switchToggle(checked, 'permission.management')
            }}
            enabled={watch('permissions').includes('permission.management')}
            disabled={isDisabled}
          />
        </Flex>
        <Flex align="start" justify="between" css={{ mb: '$8' }}>
          <Flex align="start" direction="column">
            <Label css={{ mt: '$4' }}>View Invoices</Label>
            <Text color="hint" size="sm">
              Allow admin to view invoices.
            </Text>
          </Flex>

          <ToggleSwitch
            toggle={(checked: boolean) => {
              switchToggle(checked, 'invoices.view')
            }}
            enabled={watch('permissions').includes('invoices.view')}
            disabled={isDisabled}
          />
        </Flex>
      </div>
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button mode="filled" type="submit">
          {isCreate ? 'Create' : 'Update'}
        </Button>
      </Flex>
    </form>
  )
}
