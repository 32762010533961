import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { toDollar } from '@/lib/currency'
import { getPercentage } from '@/lib/percent'
import { IEmployeeDirectDepositForm } from '@/types/employees'
import { DirectDepositCell } from '../components/DirectDepositCell'

export const columns: ColumnDef<IEmployeeDirectDepositForm, any>[] = [
  {
    header: "Worker's ID",
    id: 'employee_id',
    accessorFn: row => row.employee.employee_id,
  },
  {
    header: 'Name',
    id: 'name',
    accessorFn: row => `${row.employee.first_name} ${row.employee.last_name}`,
  },
  {
    header: 'Deposit Amount',
    id: 'deposit_amount',
    accessorFn: row => toDollar(row.direct_deposit_form.deposit_amount),
  },
  {
    header: 'Deposit Percentage',
    id: 'deposit_percent',
    accessorFn: row =>
      `${getPercentage(row.direct_deposit_form.deposit_percent || 0, 100)}%`,
  },
  {
    header: 'Date',
    id: 'time_created',
    accessorFn: row =>
      moment(row.direct_deposit_form.time_created).format('YYYY-MM-DD'),
  },
  {
    header: 'Download',
    id: 'download',
    cell: ({ row }) => <DirectDepositCell original={row.original} />,
    enableSorting: false,
  },
]
