import moment, { Moment } from 'moment'
import { FC, useState } from 'react'
import { Calendar } from 'react-calendar'
import { DualCalendarContainer } from '@/common/datepicker/styles'
import { Flex, FlexWrap } from '@/common/layout'
import { Button } from '@/components/common/button'
import { DateRangePreset } from './types'
import { DATE_PRESET_LABELS } from './utils'

const today = moment().endOf('day')
const presets: {
  id: DateRangePreset
  text: string
  start: Moment | null
  end: Moment | null
}[] = [
  {
    id: DateRangePreset.LAST_WEEK,
    text: DATE_PRESET_LABELS.last_week,
    start: moment()
      .subtract(1, 'week')
      .startOf('day'),
    end: today,
  },
  {
    id: DateRangePreset.LAST_MONTH,
    text: DATE_PRESET_LABELS.last_month,
    start: moment()
      .subtract(1, 'month')
      .startOf('day'),
    end: today,
  },
  {
    id: DateRangePreset.LAST_THREE_MONTHS,
    text: DATE_PRESET_LABELS.last_three_months,
    start: moment()
      .subtract(3, 'month')
      .startOf('day'),
    end: today,
  },
  {
    id: DateRangePreset.ALL_TIME,
    text: DATE_PRESET_LABELS.all_time,
    start: null,
    end: null,
  },
]

interface Props {
  from?: moment.Moment
  to?: moment.Moment
  preset?: DateRangePreset
  setRange: (range: {
    from?: moment.Moment
    to?: moment.Moment
    preset?: DateRangePreset
  }) => void
  setOpen: (open: boolean) => void
  allowedPresets?: DateRangePreset[]
}

export const DateRangePickerPopup: FC<Props> = ({
  from = null,
  to = null,
  preset,
  setRange,
  setOpen,
  allowedPresets = [],
}) => {
  const [draftRange, setDraftRange] = useState<{
    from: moment.Moment | null
    to: moment.Moment | null
    preset: DateRangePreset | undefined
  }>({ from, to, preset })

  const submitCustom = () => {
    setRange({
      from: draftRange?.from?.clone().startOf('day'),
      to: draftRange.to
        ? draftRange?.to?.clone().endOf('day')
        : draftRange?.from?.clone().endOf('day'),
      preset: draftRange.preset,
    })
    setOpen(false)
  }

  const submitPreset = (
    preset: DateRangePreset,
    range: { from: moment.Moment | null; to: moment.Moment | null }
  ) => {
    setDraftRange({
      from: range.from?.startOf('day') || null,
      to: range.to?.endOf('day') || null,
      preset: preset,
    })
  }

  const handleOnDatesChange = (values: Date[]) => {
    setDraftRange({
      from: moment(values[0]),
      to: values[1] ? moment(values[1]) : null,
      preset: undefined,
    })
  }

  return (
    <div>
      <Flex direction="column">
        <DualCalendarContainer>
          <Calendar
            onChange={handleOnDatesChange}
            value={[
              draftRange?.from?.toDate() || null,
              draftRange?.to?.toDate() || null,
            ]}
            selectRange={true}
            allowPartialRange={true}
            showDoubleView={true}
            showNeighboringMonth={false}
            calendarType="US"
          />
        </DualCalendarContainer>
        <FlexWrap direction="row" justify="between" css={{ width: '100%' }}>
          <Flex justify="start">
            {presets.map(({ text, start, id, end }) => {
              if (allowedPresets?.length > 0 && !allowedPresets?.includes(id))
                return null
              const isSelected = id === draftRange.preset
              return (
                <Button
                  mode={isSelected ? 'outline' : 'flat'}
                  color="primary"
                  key={text}
                  onClick={e => {
                    e.preventDefault()
                    submitPreset(id, { from: start, to: end })
                  }}
                >
                  {text}
                </Button>
              )
            })}
          </Flex>
          <Flex>
            <Button color="primary" mode="filled" onClick={submitCustom}>
              Apply
            </Button>
          </Flex>
        </FlexWrap>
      </Flex>
    </div>
  )
}
