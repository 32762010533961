import { MoreFiltersOptionsInput } from './MoreFiltersOptionsInput'
import { RenderInputProps } from './types'

export const FlagFilterOptions: React.FC<RenderInputProps> = props => {
  return (
    <MoreFiltersOptionsInput
      {...props}
      options={[
        { value: '', label: 'All' },
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ]}
    />
  )
}

export const BoolFilterOptions: React.FC<RenderInputProps> = props => {
  return (
    <MoreFiltersOptionsInput
      {...props}
      options={[
        { value: '', label: 'All' },
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ]}
    />
  )
}
