import { Cross2Icon } from '@radix-ui/react-icons'
import { FC, useEffect } from 'react'
import { useToasterStore } from '@/store/toast'
import { ContactSupport } from './ContactSupport'
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastRow,
  ToastTitle,
  ToastViewport,
} from './styles'

interface Props {
  id: string
  title?: string
  description?: string
  icon: React.ReactElement
  preventTimeout: boolean
  contactSupport: boolean
}

export const DynamicToast: FC<Props> = ({
  icon,
  title,
  description,
  id,
  preventTimeout,
  contactSupport,
}) => {
  const { expireToast, removeToast } = useToasterStore()

  useEffect(() => {
    if (!preventTimeout) {
      expireToast(id)
    }
  })

  const close = () => removeToast(id)

  return (
    <ToastProvider swipeDirection="right">
      <Toast open={true}>
        <ToastRow>
          <ToastIcon>{icon}</ToastIcon>
          <ToastDescription>
            {!!title && <ToastTitle>{title}</ToastTitle>}
            {!!description && description}
            {contactSupport && <ContactSupport />}
          </ToastDescription>
          <ToastClose onClick={close}>
            <Cross2Icon />
          </ToastClose>
        </ToastRow>
      </Toast>
      <ToastViewport />
    </ToastProvider>
  )
}
