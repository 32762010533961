import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { UserEnrollmentRequest } from '@/types/employees'

export const downloadEnrollmentRequests = (users: UserEnrollmentRequest[]) => {
  sendAnalyticsEvent('workers', 'download-enrollment-requests', {
    count: users.length,
  })

  const csvContent = Papa.unparse(users)
  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'branch_user_enrollment_requests.csv')
}
