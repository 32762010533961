import moment from 'moment'
import { FC } from 'react'
import { IconButton } from '@/common/button'
import { DetailProperty } from '@/common/detail'
import { CloseIcon } from '@/common/icons'
import { formatPhoneNumber } from '@/lib/phoneLib'
import { InitiationUserRecord } from '@/types/create-accounts'
import { StatusReason } from './StatusReason'
import { UserStatusBadge } from './UserStatusBadge'

interface Props {
  record: InitiationUserRecord
  remove: () => void
}

export const InitiatedUserDetailsView: FC<Props> = ({ record, remove }) => {
  return (
    <>
      <div className="detail-header">
        <h2 className="detail-heading">Account Details</h2>
        <div className="detail-actions">
          <IconButton onClick={remove}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <hr className="detail-heading-hr" />

      <div className="detail-section">
        <DetailProperty
          label="First Name"
          value={record.employee?.first_name}
        />
        <DetailProperty label="Last Name" value={record.employee?.last_name} />
        <DetailProperty
          label="Phone"
          value={formatPhoneNumber(record.employee?.phone_number)}
        />
        <DetailProperty label="Email" value={record.employee?.email_address} />

        <DetailProperty
          label="Last Updated"
          value={moment(record.time_modified || record.time_created).format()}
          canCopy
        />

        <DetailProperty
          label="Worker ID"
          value={record.employee?.employee_id}
          canCopy
        />

        <DetailProperty
          label="Status"
          value={record.status}
          renderValue={() => (
            <UserStatusBadge value={record.status} badgeSize="sm" />
          )}
        />

        <DetailProperty label="Status Code" value={record.status_code} />

        <DetailProperty label="Status Message" value={record.status_message} />

        <DetailProperty
          label="Magic Link"
          canCopy
          value={record.onboarding_link}
          renderValue={val => (
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '200px',
              }}
            >
              {val}
            </div>
          )}
        />

        {record.organization_wallet && (
          <DetailProperty
            label="Has Activated Card"
            value={
              record.organization_wallet?.has_activated_card ? 'Yes' : 'No'
            }
          />
        )}

        <StatusReason record={record} />

        {record.organization_wallet && (
          <div className="detail-section">
            <h4 className="detail-section-heading">Wallet Info</h4>
            <DetailProperty
              label="Account #"
              value={record.organization_wallet?.account_number}
              canCopy
            />
            <DetailProperty
              label="Routing #"
              value={record.organization_wallet?.routing_number}
              canCopy
            />
            <DetailProperty
              label="Status"
              value={record.organization_wallet?.status}
            />
            <DetailProperty
              label="Reason Code"
              value={record.organization_wallet?.reason_code}
            />
          </div>
        )}
      </div>
    </>
  )
}
