import { FC, useEffect, useMemo } from 'react'
import { GetPayoutsParams } from '@/api/types/payouts'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { Box } from '@/common/layout'
import { Table } from '@/common/table'
import { useMultiActionDisbursementHeader } from '@/hooks/useMultiActionDisbursementHeader'
import { useIndefiniteTablePagination } from '@/hooks/useTablePagination'
import { parseRouteParams } from '@/lib/queryParams'
import { useAdminUsersQuery } from '@/queries/admin-users'
import { usePayoutsQuery } from '@/src//queries/payouts'
import { useSidebar } from '@/src/hooks/useSidebar'
import { useDisbursementsStore } from '@/store/disbursements'
import { PayoutRecord } from '@/types/payouts'
import { MultiActionHeader } from './components/MultiActionHeader'
import { PayoutDetailsView } from './components/PayoutDetailsView'
import { PayoutsContainer } from './components/PayoutsContainer'
import { columns } from './lib/columns'

export const Payouts: FC = () => {
  useAdminUsersQuery()
  const { rowsSelected, setRowsSelected } = useMultiActionDisbursementHeader()

  const {
    page,
    filters,
    sorted,
    setSorted,
    updatePage,
    updateFilters,
    setPaginationParams,
    isReady,
    defaultSort,
    pageSize,
    updatePageSize,
  } = useDisbursementsStore()

  useUpdateQueryParamFilters(filters)

  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
    isReady()
  }, [queryParams, updateFilters, isReady])

  const { sidebar, addSidebar, removeSidebar } = useSidebar()
  const openSidebar = (payout: PayoutRecord, id: number) => {
    addSidebar(<PayoutDetailsView payout={payout} remove={removeSidebar} />, id)
  }
  const { data, isLoading } = usePayoutsQuery()

  const disbursementData = useMemo(() => data?.content || [], [data])

  const { hasNextPage, hasPrevPage } = useIndefiniteTablePagination(
    data?.has_next_page,
    data?.has_prev_page
  )

  const changePage = (nextPage: number) => {
    if (!data) return
    const pageParams = nextPage > page ? data.next_page : data.prev_page
    const pageFilters = parseRouteParams<GetPayoutsParams>(pageParams)
    const params = nextPage ? pageFilters : undefined

    updatePage(nextPage)
    setPaginationParams(params)
  }

  const selectedRowData = useMemo(() => {
    const selectedRowIndices = Object.keys(rowsSelected)
    return selectedRowIndices.map(index => disbursementData[Number(index)])
  }, [rowsSelected, disbursementData])

  return (
    <PayoutsContainer sidebar={sidebar}>
      <MultiActionHeader
        payouts={selectedRowData}
        setRowsSelected={setRowsSelected}
      />
      <Box css={{ mt: '$12' }}>
        <Table
          columns={columns}
          data={disbursementData}
          controlledPagination={true}
          onSortedChange={setSorted}
          sorted={sorted}
          initialSortedState={defaultSort}
          isLoading={isLoading}
          page={page}
          pageRows={pageSize}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
          changePage={changePage}
          updatePageSize={updatePageSize}
          rowOnClickFn={openSidebar}
          selected={rowsSelected}
          setSelected={setRowsSelected}
          hideLastColumnBorder
        />
      </Box>
    </PayoutsContainer>
  )
}
