import { FC } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Flex } from '@/common/layout'
import PageTitle from '../../layout/page/PageTitle'
import { SettingsNavMenu } from './SettingsNavMenu'
import { NavHeader, PageSettingsNav } from './styles'

export const SettingsContainer: FC = ({ children }) => (
  <DndProvider backend={HTML5Backend}>
    <Flex
      justify="normal"
      align="normal"
      css={{ backgroundColor: '$secondary900' }}
    >
      <PageSettingsNav>
        <NavHeader>
          <PageTitle primary="Settings" />
        </NavHeader>
        <SettingsNavMenu />
      </PageSettingsNav>
      <Flex
        direction="column"
        justify="normal"
        align="normal"
        css={{ flex: 1 }}
      >
        {children}
      </Flex>
    </Flex>
  </DndProvider>
)
