import { useQuery } from 'react-query'
import { getPartners } from '@/api/partners'

export const PARTNERS_QUERY = 'partners'

export const usePartnersQuery = () => {
  return useQuery(PARTNERS_QUERY, async () => {
    const res = await getPartners()
    return res.data.data.content
  })
}
