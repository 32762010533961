import { create } from 'zustand'
import {
  createTableFilterSlice,
  TableFiltersSlice,
} from './slices/table-filters'

export const useFileProcessingSettings = create<TableFiltersSlice>()(
  (...a) => ({
    ...createTableFilterSlice(...a),
  })
)
