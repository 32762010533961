import { FC } from 'react'

interface Props {
  value: string | null
}

export const TrackingCell: FC<Props> = ({ value }) => {
  if (!value) return null
  return (
    <a
      href={`https://www.fedex.com/fedextrack/?trknbr=${value}`}
      target="_blank"
      rel="noreferrer"
    >
      {value}
    </a>
  )
}
