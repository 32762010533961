import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useTimeKeepingStore } from '@/store/time-keeping'
import { additionalFilters } from '../lib/filters'
import { useDownloadTimeKeeping } from '../lib/useDownloadTimeKeeping'

export const TimekeepingFilters: FC = () => {
  const { handleDownload, loading } = useDownloadTimeKeeping()
  const { filters, updateFilters } = useTimeKeepingStore()

  return (
    <TableFiltersMain
      searchProperty="name"
      filters={filters}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleDownload={handleDownload}
    />
  )
}
