import { FC } from 'react'
import { getVerificationFile } from '@/api/invoice-configs'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'
import { AdvanceRecord } from '@/types/records'

interface Props {
  original: AdvanceRecord
}

export const VerificationFileCell: FC<Props> = ({ original }) => {
  const { addToast } = useToasterStore()
  const { transfer_id } = original
  const { id: orgId } = useCurrentOrg()

  const downloadFile = async () => {
    sendAnalyticsEvent('advances', 'download-verification-file', {
      id: transfer_id,
    })
    try {
      const res = await getVerificationFile(transfer_id, orgId)
      const fileBlob = new Blob([res.data], { type: 'application/pdf' })
      const data = window.URL.createObjectURL(fileBlob)
      const downloadPdf = document.createElement('a')
      downloadPdf.href = data
      downloadPdf.download = `verification_${transfer_id}.pdf`
      downloadPdf.click()
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Download Failed',
        description:
          "We weren't able to download the verification file at this time.",
      })
    }
  }

  return (
    <div className="link" onClick={downloadFile}>
      View File
    </div>
  )
}
