import { DropdownMenuContentProps, Portal } from '@radix-ui/react-dropdown-menu'
import { FC } from 'react'
import { StyledArrow, StyledContent } from './styles'

interface Props extends DropdownMenuContentProps {}

export const DropdownContent: FC<Props> = ({ children, ...props }) => (
  <Portal>
    <StyledContent {...props}>
      {children}
      <StyledArrow />
    </StyledContent>
  </Portal>
)
