import { Flex } from '@/common/layout'
import { styled } from '@/styles/stitches.config'

export const NavContainer = styled(Flex, {
  flexGrow: 1,
  variants: {
    mobile: {
      true: {
        justifyContent: 'space-between',
      },
    },
  },
})
