import { useMemo } from 'react'
import { OrgSettingsUpdates } from '@/api/types/organization'
import { Card } from '@/common/card'
import { useFeeTypes } from '@/hooks/useFeeTypes'
import {
  useDeleteInstantTransferFeeRangesMutation,
  useGetInstantTransferFeeRanges,
  usePutInstantTransferFeeRangesMutation,
  useUpdateSettingsMutation,
} from '@/queries/settings'
import { INSTANT_TRANSFER_FEE_TYPE } from '@/types/common'
import { FeeTier } from '@/types/settings'
import { InstantTransferFeeForm } from './InstantTransferFeeForm'

const toCents = (value: any) => {
  if (isNaN(value)) return null
  return value * 100
}

export type TiersValues = {
  type: INSTANT_TRANSFER_FEE_TYPE
  tiers: FeeTier[]
  percentage: number | null
}

export const defaultTier: FeeTier = {
  min: 0,
  max: null,
  fee: null,
}
const MAX_INT = 2147483647
const isMaxInt = (value: number) => value === MAX_INT
const getMaxAmount = (value: number) => {
  return isMaxInt(value) ? null : value / 100
}

export const InstantTransferFees = () => {
  const { instantTransferFeeType } = useFeeTypes()
  const {
    data: instantTransferFeeRanges,
    isLoading,
  } = useGetInstantTransferFeeRanges()
  const {
    mutate: instantTransferFeeRangesMutation,
    isLoading: isLoadingInstantTransferFeeRanges,
  } = usePutInstantTransferFeeRangesMutation()
  const deleteInstantTransferFeeRange = useDeleteInstantTransferFeeRangesMutation()

  const updateSettings = useUpdateSettingsMutation()

  const tierValues = useMemo(
    () =>
      (instantTransferFeeRanges?.data || []).map(rec => ({
        min: rec.min_amount ? rec.min_amount / 100 : 0,
        max: rec.max_amount ? getMaxAmount(rec.max_amount) : null,
        fee: rec.fee ? rec.fee / 100 : 0,
      })) as FeeTier[],
    [instantTransferFeeRanges?.data]
  )

  const onSubmitInstantTransferFee = async (values: TiersValues) => {
    onSubmitInstantransferFeeType(values.type)
    if (values.type === INSTANT_TRANSFER_FEE_TYPE.PERCENTAGE) return
    const tiers = values.tiers.map(rec => ({
      min: toCents(rec.min),
      max: toCents(rec.max) || null,
      fee: toCents(rec.fee) || 0,
    }))
    tiers.forEach(tier => instantTransferFeeRangesMutation(tier))
  }

  const onRemoveInstantTransferFee = (rec: FeeTier) => {
    onSubmitInstantransferFeeType(INSTANT_TRANSFER_FEE_TYPE.FLAT_AMOUNT)
    deleteInstantTransferFeeRange.mutate({
      min: toCents(rec.min),
      max: rec.max === null ? MAX_INT : toCents(rec.max) || null,
      fee: toCents(rec.fee),
    })
  }

  const instantTransferFeeData = useMemo<TiersValues>(() => {
    return {
      type: instantTransferFeeType,
      tiers: tierValues || [],
      percentage: 2,
    }
  }, [instantTransferFeeType, tierValues])

  const onSubmitInstantransferFeeType = (value: string) => {
    let payload: OrgSettingsUpdates = {
      INSTANT_TRANSFER_FEE_TYPE: value,
    }
    updateSettings.mutate(payload)
  }
  return (
    <Card>
      {instantTransferFeeType && (
        <InstantTransferFeeForm
          data={instantTransferFeeData}
          isLoading={
            isLoading ||
            isLoadingInstantTransferFeeRanges ||
            deleteInstantTransferFeeRange.isLoading ||
            updateSettings.isLoading
          }
          onSubmit={onSubmitInstantTransferFee}
          onRemove={onRemoveInstantTransferFee}
        />
      )}
    </Card>
  )
}
