import { ReactElement } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import createToast, { FireToastParams } from '@/components/toaster/createToast'

export type Toast = {
  id: string
  component: ReactElement
}

export type ToastStore = {
  toasts: Toast[]
  addToast: (fireToastParams: FireToastParams) => string
  removeToast: (id: string) => void
  expireToast: (id: string, timeoutMs?: number) => void
}

export const useToasterStore = create<ToastStore>((set, get) => ({
  toasts: [],
  addToast: (fireToastParams: FireToastParams) => {
    const toasts = get().toasts
    const toastId = uuidv4()
    const newToast = createToast({ ...fireToastParams, uid: toastId })
    toasts.push({ id: toastId, component: newToast })
    set(state => ({ ...state, toasts }))
    return toastId
  },
  removeToast: (id: string) => {
    const toasts = get().toasts.filter(toast => toast.id !== id)
    set(state => ({ ...state, toasts }))
  },
  expireToast: (id: string, timeoutMs = 6000) => {
    setTimeout(() => {
      get().removeToast(id)
    }, timeoutMs)
  },
}))
