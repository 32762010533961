import qs from 'qs'
import {
  GetRemittanceParams,
  GetWorkerRemittanceResponse,
  IDeductionsResponse,
  IGetRemittanceResponse,
  IGetRemittancesResponse,
  IRemittanceBalancesResponse,
  UpdateRemittanceDeductions,
} from '@/api/types/remittances'
import { TEmployeeAdjustments } from '@/components/deduction-report/types'
import { IResponse } from '@/types/common'
import http from './http'

/**
 * Retrieve all remittances for the organization
 */
export const getRemittances = (params: GetRemittanceParams) =>
  http.get<IGetRemittancesResponse>(`p1/remittances?${qs.stringify(params)}`)

/**
 * Retrieves a single remittance
 * @param id remittance ID
 */
export const getRemittance = (id: string) =>
  http.get<IGetRemittanceResponse>(`p1/remittances/${id}`)

/**
 * Get Worker Remittance
 */
export const getWorkerRemittance = (
  remittanceID: string,
  employeeId: string
) => {
  return http.get<GetWorkerRemittanceResponse>(
    `p1/remittances/${remittanceID}/employees/${employeeId}/advances`
  )
}

/**
 * Retrieves deductions under a specific remittance
 * @param id remittance ID
 */
export const getRemittanceDeductions = (id: string) =>
  http.get<IDeductionsResponse>(`p1/remittances/${id}/employee_deductions`)

/**
 * Downloads a file containing deduction data
 * @param id remittance ID
 */
export const getDeductionFile = (id: string) =>
  http.get<string>(`p1/remittances/${id}/download_deduction_file`)

/**
 * Marks the remittance as pending payment
 * @param remittanceID
 * @param form an object with an array of employee amounts and any adjustments.
 * Partial amount without a termination flag is implicitly partial payback
 */
export const submitRemittance = (
  remittanceId: string,
  form: TEmployeeAdjustments
) =>
  http.post<IGetRemittanceResponse>(
    `p1/remittances/${remittanceId}/deductions`,
    {
      deductions: form,
    }
  )

/**
 * Retrieves employee balances under a specific remittance
 * @param id remittance ID
 */
export const getRemittanceBalances = (id: string) =>
  http.get<IRemittanceBalancesResponse>(
    `p1/remittances/${id}/employee_balances`
  )

/**
 * Update Worker Advances
 */
export const updateWorkerRemittance = ({
  remittanceId,
  payload,
}: UpdateRemittanceDeductions) => {
  return http.patch<IResponse>(
    `p1/remittances/${remittanceId}/employees/advances`,
    payload
  )
}
