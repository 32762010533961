import { AdditionalFilterItem } from '@/common/filters/types'
import { JobStatus } from '@/types/jobs'

export const jobStatusOptions: { label: string; value: JobStatus }[] = [
  { value: JobStatus['FAILED'], label: 'Failed' },
  { value: JobStatus['COMPLETED'], label: 'Completed' },
  { value: JobStatus['QUEUED'], label: 'Queued' },
  { value: JobStatus['CANCELLED'], label: 'Cancelled' },
  { value: JobStatus['IN_PROGRESS'], label: 'In Progress' },
]

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'filename', label: 'File Name' },
  { id: 'job_name', label: 'Job Name' },
]
