import { FC } from 'react'
import { Alert } from '@/common/alert'
import { useSetting } from '@/hooks/useSetting'
import { FundingSource } from '@/types/invoice'

interface Props {
  fundingSource: FundingSource
}

export const FundingSourceAlert: FC<Props> = ({ fundingSource }) => {
  const remittanceAchPullEnabled =
    useSetting('ENABLE_REMITTANCE_ACH_PULL') === 'true'

  if (!remittanceAchPullEnabled) {
    return null
  }

  return (
    <Alert type="info" css={{ mb: '$4', mt: '$16' }}>
      <h5>Nice, you've already got a bank account connected to Branch!</h5>
      We will automatically collect payment from your account called{' '}
      <strong>"{fundingSource.name}"</strong> that ends in{' '}
      <strong>{fundingSource.account_number_last_four}</strong>.
    </Alert>
  )
}
