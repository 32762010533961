import { Children, FC, useState } from 'react'
import { Button } from '@/common/button'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { formatPhoneNumber } from '@/lib/phoneLib'
import { useVerifyUser } from '@/queries/employees'
import { useModalStore } from '@/store/modal'
import { IOrganizationEmployee, UserEnrollmentRequest } from '@/types/employees'

interface Props {
  user: IOrganizationEmployee
  matchingRequests: UserEnrollmentRequest[]
}

export const RosterRequestModal: FC<Props> = ({ user, matchingRequests }) => {
  const { removeModal } = useModalStore()
  const verifyUser = useVerifyUser()
  const [open, setOpen] = useState(true)
  const [saving, setSaving] = useState(false)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const handleVerify = (request: UserEnrollmentRequest) => {
    setSaving(true)
    verifyUser.mutate(
      {
        id: `${request.user_id}`,
        employeeId: `${user.employee_id}`,
        firstName: user.first_name,
        lastName: user.last_name,
      },
      { onSuccess: close }
    )
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title={`Enroll ${user.first_name} ${user.last_name}`}
    >
      <p>
        <strong>Worker ID:</strong> {user.employee_id}
      </p>
      <p>
        <strong>Phone:</strong> {formatPhoneNumber(user.user?.phone)}
      </p>
      <p>
        <strong>Email:</strong> {user.email_address}
      </p>
      <hr />
      <h5>Enrollment Requests ({matchingRequests.length})</h5>
      <hr />
      <table>
        <thead>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Phone</strong>
            </td>
            <td>
              <strong>Email</strong>
            </td>
            <td></td>
          </tr>
        </thead>
        {Children.toArray(
          matchingRequests.map(request => (
            <tbody>
              <tr>
                <td>
                  {request.first_name} {request.last_name}
                </td>
                <td>{formatPhoneNumber(request.phone)}</td>
                <td>{request.email}</td>
                <td>
                  <Flex justify="center">
                    <Button
                      mode="filled"
                      disabled={saving}
                      onClick={() => handleVerify(request)}
                      size="sm"
                    >
                      Enroll
                    </Button>
                  </Flex>
                </td>
              </tr>
            </tbody>
          ))
        )}
      </table>
    </Dialog>
  )
}
