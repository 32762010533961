import { AdditionalSettings } from './components/AdditionalSettings'
import { OnboardingContainer } from './components/OnboardingContainer'
import { UniqueLinks } from './components/UniqueLinks'

export const OnboardingSettings = () => {
  return (
    <OnboardingContainer>
      <UniqueLinks />
      <AdditionalSettings />
    </OnboardingContainer>
  )
}
