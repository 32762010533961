// returns a number from 0 to 255,
// anything over 128 is "light",
// anything under 128 is "dark"
export const getLuminance = (val: string) => {
  const color = val.substring(1) // strip #
  const rgb = parseInt(color, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  return 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
}
