import { ColumnOrderState } from '@tanstack/react-table'
import update from 'immutability-helper'
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react'
import { DraggableBox } from './DraggableBox'

export interface ColumnItem {
  id: string
  hidden: boolean
  locked: boolean
  text: string
}

export interface ContainerState {
  cards: ColumnItem[]
}

interface Props {
  setColumnOrder: (columnOrder: ColumnOrderState) => void
  setHiddenColumns: (hiddenColumns: { [key: string]: boolean }) => void
  columnConfig: ColumnItem[]
  setColumnConfig: Dispatch<SetStateAction<ColumnItem[]>>
}

export const ColumnList: FC<Props> = ({
  setColumnOrder,
  setHiddenColumns,
  columnConfig,
  setColumnConfig,
}) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setColumnConfig((prevCards: ColumnItem[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as ColumnItem],
          ],
        })
      )
    },
    [setColumnConfig]
  )

  useEffect(() => setColumnOrder(columnConfig.map(card => card.id)), [
    columnConfig,
    setColumnOrder,
  ])

  const toggleHiddenState = useCallback(
    (id: string) => {
      const newBoxState = columnConfig.map(box => {
        if (box.id === id) {
          box.hidden = !box.hidden
        }
        return box
      })
      setColumnConfig(newBoxState)
      setHiddenColumns(
        newBoxState.reduce((acc, box) => {
          acc[box.id] = !box.hidden
          return acc
        }, {} as { [key: string]: boolean })
      )
    },
    [columnConfig, setHiddenColumns, setColumnConfig]
  )
  const renderColumnItem = useCallback(
    (columnItem: ColumnItem, index: number) => {
      return (
        <DraggableBox
          key={columnItem.id}
          index={index}
          moveCard={moveCard}
          columnItem={columnItem}
          toggleHiddenState={() => toggleHiddenState(columnItem.id)}
        />
      )
    },
    [moveCard, toggleHiddenState]
  )

  return (
    <>
      <div>{columnConfig.map((column, i) => renderColumnItem(column, i))}</div>
    </>
  )
}
