import { SortingState } from '@tanstack/react-table'
import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useRosterStore } from '@/store/roster'
import { additionalFilters } from '../lib/filters'
import { useDownloadRoster } from '../lib/useDownloadRoster'

interface Props {
  sorted: SortingState
}

export const EmployeeFilters: FC<Props> = ({ sorted }) => {
  const { filters, updateFilters } = useRosterStore()
  const { handleDownload, loading } = useDownloadRoster({
    filters,
    sorted,
  })

  return (
    <TableFiltersMain
      filters={filters}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleCSVDownload={handleDownload}
      hasDateRange={false}
    />
  )
}
