type Props = React.SVGAttributes<SVGSVGElement>

const SvgDownloadCsvIcon = (props: Props) => (
  <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F5F5F5"
        d="M28.37 32.27H2.762c-1.505 0-2.727-1.14-2.727-2.55V2.6C.035 1.19 1.257.049 2.762.049H28.37c1.507 0 2.73 1.143 2.73 2.551v27.118c0 1.41-1.222 2.552-2.729 2.552"
      />
      <path
        fill="#E2E8EB"
        d="M6.733 10.284c0 .384.307.696.693.696h16.283a.692.692 0 00.693-.696.692.692 0 00-.693-.695H7.426a.692.692 0 00-.693.695zm0 3.917c0 .384.307.695.693.695h16.283a.692.692 0 00.693-.695.692.692 0 00-.693-.695H7.426a.692.692 0 00-.693.695zm0 3.917c0 .384.307.695.693.695h16.283a.692.692 0 00.693-.695.691.691 0 00-.693-.694H7.426a.692.692 0 00-.693.694zm-.001 3.918a.7.7 0 00.703.695h10.392a.697.697 0 00.703-.695.7.7 0 00-.703-.696H7.435a.697.697 0 00-.703.696z"
      />
      <path
        fill="#6CD27D"
        d="M39.812 30.906a8.906 8.906 0 01-8.906 8.906A8.906 8.906 0 0122 30.906 8.906 8.906 0 0130.906 22a8.906 8.906 0 018.906 8.906"
      />
      <path
        fill="#F5F5F5"
        d="M30.58 35.456a.462.462 0 00.651 0l2.553-3.553a.46.46 0 00-.326-.786h-.765a.462.462 0 01-.46-.46v-3.975a.46.46 0 00-.46-.46h-1.734a.461.461 0 00-.46.46v3.974a.46.46 0 01-.46.461h-.766a.46.46 0 00-.326.786l2.553 3.553z"
      />
    </g>
  </svg>
)

export default SvgDownloadCsvIcon
