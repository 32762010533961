import moment from 'moment'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  addEmailRegistrationProcessor,
  addSftpRegistration,
  addSftpRegistrationProcessor,
  deleteEmailProcessor,
  deleteEmailRegistration,
  deleteSftpProcessor,
  deleteSftpRegistration,
  getProcessors,
  getRegistrations,
} from '@/api/file-processing'
import { addEmailRegistration } from '@/api/ingestion'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'
import { RegistrationType } from '../components/settings/file-processing/lib/types'

export const GET_PROCESSORS_QUERY = 'get-processors'

export const useProcessorsQuery = () => {
  return useQuery([GET_PROCESSORS_QUERY], async () => {
    const { data } = await getProcessors()
    return data
  })
}

export const GET_REGISTRATIONS_QUERY = 'get-registrations'

export const useRegistrationsQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([GET_REGISTRATIONS_QUERY], async () => {
    const { data } = await getRegistrations(orgId)
    return data
  })
}

export const useDeleteRegistrationMutation = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (config: { id: number; registrationType: RegistrationType }) => {
      if (config.registrationType === RegistrationType.email) {
        return deleteEmailRegistration(config.id, orgId)
      } else {
        return deleteSftpRegistration(config.id, orgId)
      }
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent(
          'file_processing',
          'delete-registration:success',
          payload
        )
        addToast({
          type: 'success',
          title: 'Deleted registration',
        })
        queryClient.invalidateQueries(GET_PROCESSORS_QUERY)
        queryClient.invalidateQueries(GET_REGISTRATIONS_QUERY)
      },
    }
  )
}

export const useAddRegistrationMutation = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (config: { target: string; registrationType: RegistrationType }) => {
      if (config.registrationType === RegistrationType.email) {
        return addEmailRegistration(
          {
            email_key: config.target,
          },
          orgId
        )
      } else {
        return addSftpRegistration(
          {
            dir_name: config.target,
          },
          orgId
        )
      }
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent(
          'file_processing',
          'add-registration:success',
          payload
        )
        addToast({
          type: 'success',
          title: 'Added registration',
        })
        queryClient.invalidateQueries(GET_PROCESSORS_QUERY)
        queryClient.invalidateQueries(GET_REGISTRATIONS_QUERY)
      },
    }
  )
}

export const useAddProcessorMutation = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (config: {
      registrationType: RegistrationType
      registrationId: number
      regex: string
      processorType: string
    }) => {
      const payload = {
        regex: config.regex,
        registrationId: config.registrationId,
        processorName: config.processorType,
        effectiveDate: moment().format(),
      }
      if (config.registrationType === RegistrationType.email) {
        return addEmailRegistrationProcessor(payload, orgId)
      } else {
        return addSftpRegistrationProcessor(payload, orgId)
      }
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent('file_processing', 'add-processor:success', payload)
        addToast({
          type: 'success',
          title: 'Added processor',
        })
        queryClient.invalidateQueries(GET_PROCESSORS_QUERY)
        queryClient.invalidateQueries(GET_REGISTRATIONS_QUERY)
      },
      onError: (_, payload) => {
        sendAnalyticsEvent('file_processing', 'add-processor:error', payload)
        addToast({
          type: 'error',
          title: 'Failed to add processor',
          description:
            'Please try again soon, and if the issue persists, contact support.',
        })
      },
    }
  )
}

export const useDeleteProcessorMutation = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    (config: {
      id: number
      registrationId: number
      registrationType: RegistrationType
    }) => {
      if (config.registrationType === RegistrationType.email) {
        return deleteEmailProcessor(config.id, config.registrationId, orgId)
      } else {
        return deleteSftpProcessor(config.id, config.registrationId, orgId)
      }
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent(
          'file_processing',
          'delete-processor:success',
          payload
        )
        addToast({
          type: 'success',
          title: 'Removed processor',
        })
        queryClient.invalidateQueries(GET_PROCESSORS_QUERY)
        queryClient.invalidateQueries(GET_REGISTRATIONS_QUERY)
      },
    }
  )
}
