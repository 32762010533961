import * as Stitches from '@stitches/react'
import { Flex } from '@/common/layout'
import { styled } from '@/styles/stitches.config'

export const OrgLogoBubble = styled(Flex, {
  br: '100px',
  overflow: 'hidden',
  margin: '0.25rem 0.5rem',
  backgroundColor: 'white',
  boxShadow: '0 1rem 3rem rgba(0, 0, 0, 0.175)',

  img: {
    display: 'block',
    width: '100%',
  },

  variants: {
    size: {
      sm: {
        width: '$24',
        height: '$24',
        padding: '0 2px',
      },
      lg: {
        width: '$48',
        height: '$48',
        padding: '0 4px',
        fontSize: '1.5rem',
      },
      xl: {
        width: '128px',
        height: '128px',
        padding: '0 16px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        boxShadow: ' 0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
      },
    },
  },

  defaultVariants: {
    size: 'sm',
  },
})

export type OrgLogoBubbleVariants = Stitches.VariantProps<typeof OrgLogoBubble>
