import classnames from 'classnames'
import { FC } from 'react'

interface Props {
  className?: string
}

const PopupHeadIcons: FC<Props> = props => {
  const { children, className } = props

  const classes = classnames(
    className,
    'd-flex flex-grow-1 align-items-center justify-content-end'
  )

  return <div className={classes}>{children}</div>
}

export default PopupHeadIcons
