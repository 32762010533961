import { useEffect, useState } from 'react'

interface IStep {
  id: string
  label?: string
}

type Hook = {
  currentStep: IStep
  currentStepIndex: number
  setCurrentStep: (step: IStep) => void
  goToNextStep: () => void
  goToPreviousStep: () => void
  goToStepIndex: (index: number) => void
  goToStepID: (id: string) => void
}

const useSteps = <T extends IStep>(steps: T[]): Hook => {
  const [currentStep, setCurrentStep] = useState<IStep>(steps[0])
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  useEffect(() => {
    setCurrentStepIndex(steps.findIndex(s => s.id === currentStep.id))
  }, [currentStep.id, steps])

  const goToNextStep = () => {
    if (currentStepIndex !== steps.length - 1) {
      const step: T = steps[currentStepIndex + 1]
      setCurrentStep(step)
    }
  }

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      const step: T = steps[currentStepIndex - 1]
      setCurrentStep(step)
    }
  }

  const goToStepIndex = (index: number) => {
    const step: T = steps[index]
    setCurrentStep(step)
  }

  const goToStepID = (id: string) => {
    const step: T | undefined = steps.find(s => s.id === id)
    if (step) {
      setCurrentStep(step)
    }
  }

  return {
    setCurrentStep,
    currentStepIndex,
    currentStep,
    goToNextStep,
    goToPreviousStep,
    goToStepIndex,
    goToStepID,
  }
}

export default useSteps
