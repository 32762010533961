import { Route, Switch } from 'react-router'
import { Flex } from '@/common/layout'
import { LogoMark } from '@/common/logos'
import { ROUTES } from '@/router/routes'
import { Login } from './Login'
import { Register } from './Register'
import { AuthContainerContent, AuthLogoContainer } from './styles'

export const AuthContainer = () => (
  <Flex justify="center">
    <AuthContainerContent>
      <AuthLogoContainer>
        <LogoMark />
      </AuthLogoContainer>
      <Switch>
        <Route exact path={ROUTES.LOGIN} component={Login} />
        <Route exact path={ROUTES.REGISTER} component={Register} />
      </Switch>
    </AuthContainerContent>
  </Flex>
)
