import { FC, ReactNode } from 'react'
import { Button } from '@/common/button'

interface Props {
  icon: ReactNode
  handleClick: () => void
  tooltip: string
}

export const PayrollRecordAction: FC<Props> = ({ handleClick, icon }) => {
  return (
    <Button onClick={handleClick} color="black" mode="flat" size="lg">
      {icon}
    </Button>
  )
}
