import { ColumnDef } from '@tanstack/table-core'
import { Flex } from '@/common/layout'
import { TableExpander } from '@/common/table'
import { IDeduction } from '@/types/remittances'
import AmountCell from '../components/AmountCell'
import { CompletedNameCell } from '../components/CompletedNameCell'

export const columns: ColumnDef<IDeduction, any>[] = [
  {
    header: 'Name',
    id: 'employee_name',
    accessorKey: 'employee_name',
    cell: ({ row }) => <CompletedNameCell original={row.original} />,
  },
  {
    header: 'ID',
    id: 'employee_id',
    accessorFn: row => row.employee.employee_id,
  },
  {
    header: 'Requested Amount',
    accessorFn: row => row.requested_amount,
    enableSorting: false,
    cell: ({ row }) => <AmountCell value={row.original.requested_amount} />,
  },
  {
    header: 'Deducted Amount',
    accessorKey: 'amount',
    enableSorting: false,
    cell: ({ row }) => <AmountCell value={row.original.amount} />,
  },
  {
    id: 'expand',
    size: 40,
    cell: ({ row }) => (
      <Flex justify="center">
        <div
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: 'pointer' },
          }}
        >
          <TableExpander isExpanded={row.getIsExpanded()} />
        </div>
      </Flex>
    ),
  },
]
