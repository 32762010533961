import { FC } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { AdminShieldIcon } from '@/components/common/icons'
import useOrgPath from '@/router/useOrgPath'
import { usePermissionsStore } from '@/store/permissions'
import { SettingsNavItemType } from './menu'
import { AdminIconContainer, IconContainer, NavItem } from './styles'

interface Props {
  item: SettingsNavItemType
}

export const SettingsNavItem: FC<Props> = ({ item }) => {
  const location = useLocation()
  const urlPath = useOrgPath(item.route)
  const isActive = location.pathname === urlPath
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  if ((item.isAdmin && !isGlobalAdmin) || item.isHidden) return null

  const Icon = item.icon

  return (
    <NavItem to={item.route} as={Link} active={isActive}>
      <IconContainer>
        <Icon />
        {item.isAdmin && (
          <AdminIconContainer>
            <AdminShieldIcon />
          </AdminIconContainer>
        )}
      </IconContainer>
      {item.label}
    </NavItem>
  )
}
