import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { IRegistrationRow } from '../lib/types'
import { AddProcessorForm } from './AddProcessorForm'

interface Props {
  registration: IRegistrationRow
}

export const AddProcessorModal: FC<Props> = ({ registration }) => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const description = (
    <>
      Set up a registration to watch for files at your desired location. To set
      up specific processors that match against filenames and perform different
      processes, you can create a processor under this registration once you've
      finished creating it.
    </>
  )

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Add Processor"
      description={description}
    >
      <AddProcessorForm registration={registration} handleClose={close} />
    </Dialog>
  )
}
