import { LockClosedIcon } from '@radix-ui/react-icons'
import { Identifier, XYCoord } from 'dnd-core'
import { FC, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Box, Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { ToggleSwitch } from '@/common/toggleswitch/ToggleSwitch'
import { ColumnItem } from './ColumnList'
import { ColumnStatusText, DraggableCardContainer, DragIcon } from './styles'

interface Props {
  columnItem: ColumnItem
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  toggleHiddenState: (id: string) => void
}

interface DragItem extends ColumnItem {
  index: number
  type: string
}

export const DraggableBox: FC<Props> = ({
  columnItem: { id, hidden, locked, text },
  index,
  moveCard,
  toggleHiddenState,
}) => {
  const [draggable, setDraggable] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'DraggableBox',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'DraggableBox',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => draggable,
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <FlexGrid
      ref={ref}
      data-handler-id={handlerId}
      css={{
        opacity,
        pointerEvents: locked ? 'none' : 'pointer',
      }}
    >
      <DragIcon
        onMouseDown={() => setDraggable(true)}
        onMouseUp={() => setDraggable(false)}
        locked={locked}
      />
      <DraggableCardContainer compact>
        <Flex justify="between">
          <Box>
            <Text size="lg" bold>
              {id}
            </Text>
            <Text color="hint">Description</Text>
          </Box>
          <Flex css={{ ml: 'auto' }}>
            {hidden && !locked && <ColumnStatusText>Hidden</ColumnStatusText>}
            {locked ? (
              <>
                <ColumnStatusText>Locked</ColumnStatusText>
                <LockClosedIcon
                  style={{
                    width: '40px',
                    height: '24px',
                  }}
                />
              </>
            ) : (
              <ToggleSwitch
                enabled={!hidden}
                toggle={() => toggleHiddenState(id)}
              />
            )}
          </Flex>
        </Flex>
      </DraggableCardContainer>
    </FlexGrid>
  )
}
