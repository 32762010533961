import { Table } from 'reactstrap'

export const TemplateExample = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Worker's ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Rate Type (optional)</th>
          <th>Rate (optional)</th>
          <th>Phone (optional)</th>
          <th>Email (optional)</th>
          <th>Location (optional)</th>
          <th>Business Name (optional)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>123456</td>
          <td>Johnny</td>
          <td>Appleseed</td>
          <td>hourly</td>
          <td>15.00</td>
          <td>+14155552671</td>
          <td>johnny@org.com</td>
          <td>Store 1234</td>
          <td>Business 21</td>
        </tr>
        <tr>
          <td>7891234</td>
          <td>Agnes</td>
          <td>Arber</td>
          <td>hourly</td>
          <td>20.00</td>
          <td>+14155552672</td>
          <td>agnes@org.com</td>
          <td>Store 1235</td>
          <td>Business 22</td>
        </tr>
        <tr>
          <td>2352355</td>
          <td>Bob</td>
          <td>Ross</td>
          <td>hourly</td>
          <td>15.00</td>
          <td>+14155552673</td>
          <td>bob@org.com</td>
          <td>Store 1236</td>
          <td>Business 23</td>
        </tr>
      </tbody>
    </Table>
  )
}
