import { useMemo } from 'react'
import { FilterPill } from './FilterPill'
import { AdditionalFilterItem, TableFilters } from './types'

type Props = {
  config: AdditionalFilterItem[]
  filters: TableFilters
  handleItemClick: (id: string) => void
  handleItemRemove: (id: string) => void
}

export const TableFilterPills: React.FC<Props> = props => {
  const { config, filters, handleItemClick, handleItemRemove } = props
  const hasActiveFilters = useMemo(
    () => config.some(c => filters[c.id] !== undefined),
    [filters, config]
  )

  if (!hasActiveFilters) return null

  return (
    <div className="filter-pill-container">
      {config.map(
        item =>
          filters[item.id] !== undefined && (
            <FilterPill
              key={item.id}
              label={item.label}
              value={filters[item.id]}
              onClick={() => handleItemClick(item.id)}
              removeFilter={() => handleItemRemove(item.id)}
              formatValue={item.formatValue}
            />
          )
      )}
    </div>
  )
}
