import { useMutation, useQuery, useQueryClient } from 'react-query'
import { WebhookPayload } from '@/api/types/webhooks'
import {
  createWebhookDefinition,
  createWebhookRegistration,
  deleteWebhookDefinition,
  deleteWebhookRegistration,
  getWebhookDefinition,
  getWebhookDefinitions,
  getWebhookEventTypes,
  getWebhookRegistrations,
  mockWebhookEvent,
} from '@/api/webhooks'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useToasterStore } from '@/store/toast'
import { WebhookEventType } from '@/types/webhooks'

const WEBHOOKS_EVENTS_QUERY = 'webhooks-events'

export const useWebhookEventsQuery = () =>
  useQuery([WEBHOOKS_EVENTS_QUERY], async () => {
    const res = await getWebhookEventTypes()
    return res.data
  })

const WEBHOOKS_DEFINITIONS = 'webhooks-definitions'

export const useWebhookDefinitions = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([WEBHOOKS_DEFINITIONS, orgId], async () => {
    const res = await getWebhookDefinitions(orgId)
    return res.data
  })
}

const WEBHOOKS_DEFINITION = 'webhooks-definition'

export const useWebhookDefinition = (definitionId: number) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([WEBHOOKS_DEFINITION, orgId, definitionId], async () => {
    const res = await getWebhookDefinition(orgId, definitionId)
    return res.data
  })
}

const WEBHOOK_REGISTRATIONS = 'webhook-registrations'

export const useWebhookRegistrations = (definitionId: number) => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([WEBHOOK_REGISTRATIONS, orgId, definitionId], async () => {
    const res = await getWebhookRegistrations(orgId, definitionId)
    return res.data.content
  })
}

export const useCreateWebhookDefinition = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation((url: string) => createWebhookDefinition(orgId, url), {
    onSuccess: () =>
      queryClient.invalidateQueries([WEBHOOKS_DEFINITIONS, orgId]),
  })
}

type RegisterWebhookConfig = {
  definitionId: number
  event_type: string
}

export const useRegisterWebhookEvent = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    ({ definitionId, event_type }: RegisterWebhookConfig) =>
      createWebhookRegistration(orgId, definitionId, event_type),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([WEBHOOK_REGISTRATIONS, orgId]),
    }
  )
}

type DeleteWebhookRegistrationConfig = {
  definitionId: number
  registrationId: number
}

export const useRemoveWebhookRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    ({ definitionId, registrationId }: DeleteWebhookRegistrationConfig) =>
      deleteWebhookRegistration(orgId, definitionId, registrationId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([WEBHOOK_REGISTRATIONS, orgId]),
    }
  )
}

export const useRemoveWebhookDefinition = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    (definitionId: number) => deleteWebhookDefinition(orgId, definitionId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([WEBHOOKS_DEFINITIONS, orgId]),
    }
  )
}

interface MockWebhookEventConfig {
  data: WebhookPayload
  event: WebhookEventType
}

export const useMockWebhookEvent = () => {
  const { id: orgId, partner_id } = useCurrentOrg()
  const { addToast } = useToasterStore()
  return useMutation(
    ({ event, data }: MockWebhookEventConfig) =>
      mockWebhookEvent(orgId, partner_id, event, data),
    {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Webhook event fired',
        })
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Error firing webhook event',
        })
      },
    }
  )
}
