import moment from 'moment'
import { FC } from 'react'
import { Card } from '@/common/card'
import { Loader } from '@/common/loader'
import { useEmployeeCardDetailsQuery } from '@/queries/employees'
import { DetailProperty } from './components/DetailProperty'
import { DetailHeading } from './components/styles'

interface Props {
  employeeId?: string | null
}

export const EmployeeCardDetails: FC<Props> = ({ employeeId }) => {
  const employeeCardDetails = useEmployeeCardDetailsQuery(employeeId)
  return (
    <Card>
      <DetailHeading css={{ mb: '$8' }}>Card Details</DetailHeading>
      <Loader loading={employeeCardDetails.isLoading}>
        <DetailProperty
          label="Physical Card Last Four"
          value={employeeCardDetails.data?.newest_physical_last_four}
        />
        <DetailProperty
          label="Newest Activated Card Last Four"
          value={employeeCardDetails.data?.newest_activated_last_four}
        />
        <DetailProperty
          label="Card Ordered Date"
          value={
            employeeCardDetails.data?.newest_physical_ordered_time
              ? moment(
                  employeeCardDetails.data?.newest_physical_ordered_time
                ).format('MM/DD/YYYY')
              : null
          }
        />
        <DetailProperty
          label="Card Activated Date"
          value={
            employeeCardDetails.data?.newest_card_activation_time
              ? moment(
                  employeeCardDetails.data?.newest_card_activation_time
                ).format('MM/DD/YYYY')
              : null
          }
        />
      </Loader>
    </Card>
  )
}
