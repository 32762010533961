import { FC } from 'react'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { useSetting } from '@/hooks/useSetting'
import { OrgLogoBubble, OrgLogoBubbleVariants } from './styles'

interface Props {
  size?: OrgLogoBubbleVariants['size']
}

export const OrganizationLogo: FC<Props> = ({ size = 'sm' }) => {
  const { logo_url: logoUrl, name } = useCurrentOrg()
  const brandColor = useSetting('BRANDING_COLOR_HEX_CODE')

  return (
    <OrgLogoBubble size={size}>
      {!logoUrl ? (
        <span style={{ color: brandColor, margin: 'auto' }}>{name[0]}</span>
      ) : (
        <img src={logoUrl} alt="Organization Logo" />
      )}
    </OrgLogoBubble>
  )
}
