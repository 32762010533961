import {
  ColumnOrderState,
  ColumnResizeMode,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { Flex } from '@/common/layout'
import { useTablePagination } from '@/hooks/useTablePagination'
import { TableProps } from '@/types/table'
import { PageSizeSelector } from './PageSizeSelector'
import { TableContainer } from './styles'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { TableLoadingComponent } from './TableLoadingComponent'
import { TablePagination } from './TablePagination'

export const Table = <TData extends object>({
  data,
  columns,
  controlledPagination,
  onSortedChange,
  initialSortedState,
  sorted = [],
  columnFiltersState = [],
  page,
  updatePageSize,
  pageRows,
  hasNextPage,
  hasPrevPage,
  changePage,
  isLoading = false,
  hiddenColumns,
  rowOnClickFn,
  setFilteredData,
  renderSubComponent,
  pinnedColumns = { left: [] },
  enableMultiRowSelection,
  selected,
  setSelected,
  hideLastColumnBorder = false,
  columnOrderState = [],
}: TableProps<TData>) => {
  const [sorting, setSorting] = useState<SortingState>(sorted)
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const [columnResizeMode] = useState<ColumnResizeMode>('onChange')
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    columnOrderState
  )

  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getRowCanExpand: () => true,
    state: {
      sorting: sorting,
      columnFilters: columnFiltersState,
      columnVisibility: hiddenColumns,
      expanded,
      pagination: { pageIndex: page, pageSize: pageRows },
      columnPinning: pinnedColumns,
      rowSelection: selected,
      columnOrder: columnOrder,
    },
    enablePinning: true,
    onColumnOrderChange: setColumnOrder,
    enableMultiRowSelection: enableMultiRowSelection,
    onRowSelectionChange: setSelected,
    manualSorting: controlledPagination,
    manualPagination: controlledPagination,
    manualFiltering: controlledPagination,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    onStateChange: () => {
      if (setFilteredData)
        setFilteredData(table.getSortedRowModel().rows.map(row => row.original))
    },
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  const {
    previousPageDisabled,
    nextPageDisabled,
    advancePage,
    previousPage,
    pageNumber,
  } = useTablePagination({
    table,
    changePage,
    controlledPagination,
    hasNextPage,
    hasPrevPage,
    page,
  })

  useEffect(() => {
    if (onSortedChange) {
      const sortingRule = (sorting.length > 0
        ? sorting
        : initialSortedState) as SortingState

      onSortedChange(sortingRule)
    }
  }, [sorting, initialSortedState, onSortedChange])

  const pinnedLeftColumnPositions = useMemo(() => {
    let currentVal = 0
    return table.getAllColumns().map((_, i) => {
      if (i === 0) return 0
      return (currentVal += table.getAllColumns()[i - 1].getSize())
    })
  }, [table])

  return (
    <Flex direction="column" css={{ height: '100%' }}>
      <TableContainer
        canHover={rowOnClickFn ? true : false}
        hideLastColumnBorder={hideLastColumnBorder}
      >
        <table>
          <TableHeader
            table={table}
            sorting={sorting}
            pinnedLeftColumnPositions={pinnedLeftColumnPositions}
            columnResizeMode={columnResizeMode}
          />
          <TableBody
            table={table}
            isLoading={isLoading}
            rowOnClickFn={rowOnClickFn}
            renderSubComponent={renderSubComponent}
            pinnedLeftColumnPositions={pinnedLeftColumnPositions}
          />
        </table>

        <TableLoadingComponent isLoading={isLoading} dataSize={data.length} />
      </TableContainer>
      <Flex css={{ mt: '$16', ml: 'auto' }}>
        <TablePagination
          previousPageDisabled={previousPageDisabled}
          previousPage={previousPage}
          nextPageDisabled={nextPageDisabled}
          nextPage={advancePage}
          pageNumber={pageNumber}
        />
        {updatePageSize && (
          <PageSizeSelector pageSize={pageRows} onChange={updatePageSize} />
        )}
      </Flex>
    </Flex>
  )
}
