import { styled } from '@/styles/stitches.config'

export const Flex = styled('div', {
  display: 'flex',

  variants: {
    inline: {
      true: {
        display: 'inline',
      },
    },
    inLineBlock: {
      true: {
        display: 'inline-block',
      },
    },
    fullWidth: {
      true: {
        flex: 1,
      },
    },
    align: {
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      start: {
        alignItems: 'start',
      },
      normal: {
        alignItems: 'normal',
      },
    },
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      between: {
        justifyContent: 'space-between',
      },
      evenly: {
        justifyContent: 'space-evenly',
      },
      normal: {
        justifyContent: 'normal',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    direction: {
      column: {
        flexDirection: 'column',
      },
      row: {
        flexDirection: 'row',
      },
    },
  },

  defaultVariants: {
    align: 'center',
    justify: 'start',
  },
})

export const FlexGrid = styled(Flex, {
  variants: {
    size: {
      sm: {
        gap: '$4',
      },
      md: {
        gap: '$8',
      },
      lg: {
        gap: '$12',
      },
    },
    fillSpace: {
      true: {
        '& > *': {
          flex: 1,
        },
      },
    },
    fullWidth: {
      true: {
        '& > *': {
          width: '100%',
          '& > div:has(input)': {
            width: '100%',
          },
        },
      },
    },
  },

  defaultVariants: {
    size: 'md',
    fillSpace: false,
  },
})

export const FlexWrap = styled(FlexGrid, {
  flexWrap: 'wrap',
})

export const Box = styled('div', {})

export const SelectableBox = styled('button', {
  width: '100%',
  border: 0,
  padding: 0,
  background: 'none',
  textAlign: 'left',
})
