export const TemplateExample = () => (
  <table>
    <thead>
      <tr>
        <th>Worker's ID</th>
        <th>Punch In</th>
        <th>Punch Out</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>123456</td>
        <td>04/18/2019 12:00:00</td>
        <td>04/18/2019 18:00:00</td>
      </tr>
      <tr>
        <td>7891234</td>
        <td>04/19/2019 08:00:00</td>
        <td>04/19/2019 16:00:00</td>
      </tr>
      <tr>
        <td>2352355</td>
        <td>04/19/2019 16:00:00</td>
        <td>04/19/2019 22:00:00</td>
      </tr>
    </tbody>
  </table>
)
