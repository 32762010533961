import { FC } from 'react'
import { WorkerDetails } from '@/common/detail'
import { Loader } from '@/common/loader'
import { useWorkerDetails } from '@/queries/employees'
import { IRosterUser } from '@/types/employees'

interface Props {
  record: IRosterUser
  remove: () => void
}

export const WorkerDetailsLookup: FC<Props> = ({ record, remove }) => {
  const { data: worker, isLoading } = useWorkerDetails(record.employee_id)

  if (isLoading || !worker) {
    return <Loader loading />
  }

  return <WorkerDetails user={worker} remove={remove} />
}
