import { ColumnDef } from '@tanstack/react-table'
import { FileValidationError } from '@/types/common'

export const errorColumns: ColumnDef<FileValidationError, any>[] = [
  {
    header: 'Line #',
    accessorKey: 'original_csv_line',
  },
  {
    header: 'Status Reason',
    accessorKey: 'status_reason',
    size: 500,
  },
]
