import styles from './index.module.scss'
import { ISelectableEmployee } from './types'

interface IProps {
  employee: ISelectableEmployee
}

export const Employee: React.FC<IProps> = props => {
  const { employee } = props

  const rosterName = `${employee.firstName} ${employee.lastName}`

  return (
    <span className={styles.Employee}>
      <p className={styles.EmployeeName}>{rosterName}</p>
      <p className={styles.EmployeeId}>ID: {employee.id}</p>
    </span>
  )
}
