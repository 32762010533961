import { FC } from 'react'
import { Alert } from '@/common/alert'
import { Loader } from '@/common/loader'
import { useUkgRegistration } from '@/src/queries/integrations'
import { UkgIntegrationForm } from './UkgIntegrationForm'

export const UkgIntegration: FC = () => {
  const { data, isLoading } = useUkgRegistration()

  return (
    <div>
      {data ? (
        <Alert type="success">
          <strong>UKG</strong> integration is active.
        </Alert>
      ) : (
        <Alert type="warning">
          <strong>UKG</strong> integration is active but not configured.
        </Alert>
      )}
      <Loader loading={isLoading}>
        <UkgIntegrationForm data={data} />
      </Loader>
    </div>
  )
}
