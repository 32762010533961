import { styled } from '@/styles/stitches.config'

export const Input = styled('input', {
  display: 'block',
  all: 'unset',
  width: '100%',
  boxSizing: 'border-box',
  color: '$secondary500',
  borderRadius: '$sm',
  border: '2px solid $secondary700',
  padding: '0 $8',
  fontSize: 16,
  lineHeight: 1,
  height: 35,
  gap: 5,
  backgroundColor: 'white',
  '&:disabled': {
    backgroundColor: '$secondary900',
  },
  variants: {
    inline: {
      true: {
        width: 'auto',
        display: 'inline-block',
      },
    },
    iconRight: {
      true: {
        borderRight: 'none',
        borderRadius: '$sm 0 0 $sm',
      },
    },
    iconLeft: {
      true: {
        borderLeft: 'none',
        borderRadius: '0 $sm $sm 0',
      },
    },
  },
})

export const InputIcon = styled('span', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  padding: '0 $8',
  boxSizing: 'border-box',
  border: '2px solid $secondary700',
  height: 35,
  variants: {
    right: {
      true: {
        borderLeft: 'none',
        borderRight: '2px solid $secondary700',
        borderRadius: '0 $sm $sm 0',
        float: 'right',
      },
    },
    left: {
      true: {
        borderRight: 'none',
        borderRadius: '$sm 0 0 $sm',
        float: 'left',
      },
    },
  },
  defaultVariants: {
    left: true,
  },
})

export const TextArea = styled('textarea', {
  display: 'block',
  width: '100%',
  padding: '$16',
  color: '$secondary500',
  borderRadius: '$sm',
  border: '2px solid $secondary700',
  fontSize: 16,
  lineHeight: 1,
  backgroundColor: 'white',
  '&:disabled': {
    backgroundColor: '$secondary900',
  },
})
