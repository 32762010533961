import qs from 'qs'
import { PdqStore } from '@/types/settings'
import http from './http'

export const editPdqStoreRegistration = (
  storeId: string,
  params: {
    rosterEffective?: string
    timekeepingEffective?: string
    disbursementEffective?: string
  },
  orgId: number
) =>
  http.put(
    `wfm/organizations/${orgId}/registrations/pdq/stores/${storeId}/registration`,
    {
      roster_effective_date: params.rosterEffective,
      timekeeping_effective_date: params.timekeepingEffective,
      disbursement_effective_date: params.disbursementEffective,
    }
  )

export const postPdqStoreRegistration = (
  storeId: string,
  params: {
    rosterEffective?: string
    timekeepingEffective?: string
    disbursementEffective?: string
  },
  orgId: number
) =>
  http.post(
    `wfm/organizations/${orgId}/registrations/pdq/stores/${storeId}/registration`,
    {
      roster_effective_date: params.rosterEffective,
      timekeeping_effective_date: params.timekeepingEffective,
      disbursement_effective_date: params.disbursementEffective,
    }
  )

export const removePdqStore = (orgId: number, storeId: string) =>
  http.delete(
    `wfm/organizations/${orgId}/registrations/pdq/stores/${storeId}/registration`
  )

/**
 * Allows the user to get stores associated with a PDQ integration
 */
export const getPdqStores = (orgId: number) =>
  http.get<PdqStore[]>(`wfm/organizations/${orgId}/registrations/pdq/stores`)

/**
 * Allows the user to get the organiation's PDQ integration registration
 */
export const getPdqRegistration = (orgId: number) =>
  http.get(`wfm/organizations/${orgId}/registrations/pdq`)

export const postPdqRegistration = (
  orgId: number,
  params: { id: string; secret: string }
) =>
  http.post(`wfm/organizations/${orgId}/registrations/pdq`, {
    api_id: params.id,
    api_key: params.secret,
  })

export const putPdqRegistration = (
  orgId: number,
  params: { id: string; secret: string }
) =>
  http.put(`wfm/organizations/${orgId}/registrations/pdq`, {
    api_id: params.id,
    api_key: params.secret,
  })

export const runPdqRegistrationJob = (
  orgId: number,
  {
    types,
    store_id,
    date,
  }: { types: string[]; store_id?: string; date?: string }
) => {
  const queryParams = qs.stringify(
    { store_id, type: types, date },
    { arrayFormat: 'repeat' }
  )

  return http.post(`wfm/organizations/${orgId}/jobs/pdq?${queryParams}`)
}
