import { CreateWebhookDefinition } from './components/CreateWebhookDefinition'
import { WebhookDefinitions } from './components/WebhookDefinitions'
import { WebhooksContainer } from './components/WebhooksContainer'

export const Webhooks = () => (
  <WebhooksContainer>
    <CreateWebhookDefinition />
    <WebhookDefinitions />
  </WebhooksContainer>
)
