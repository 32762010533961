import { PhoneNumberUtil } from 'google-libphonenumber'
import moment from 'moment'
import Papa from 'papaparse'
import { useEffect, useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { readFileAsText } from '@/lib/asyncReader'
import { FileValidationError } from '@/types/common'

export enum STATUS_REASON {
  REQUIRED_NAME = 'First Name and Last Name is required',
  REQUIRED_EMAILORPHONE = 'One of phone number or email address must be provided',
  INVALID_BDAY = 'Birth Date is required and must be in the form of YYYY-MM-DD',
  INVALID_SSN = 'SSN is required and must be in the form of #########',
  INVALID_EMAIL = 'Email is invalid',
  INVALID_PHONE = 'Phone number is invalid',
  REQUIRED_ADDRESS = 'Need a valid address',
  REQUIRED_CITY = 'City is required',
  REQUIRED_STATE = 'State is required',
  INVALID_ZIPCODE = 'Zipcode must be a in the format of ##### or #####-####',
}

// Qualify CSV columns
// 0 'First Name' required,
// 1 'Last Name' required,
// 2 "Worker's ID",
// 3 'Birthdate (YYYY-MM-DD)' required and proper format,
// 4 'SSN' required 9 numbers,
// 5 'Email' must have valid email or phone number,
// 6 'Phone' must have valid email or phone number,
// 7 'Address 1' required,
// 8 'Address 2',
// 9 'City' required,
// 10 'State' required,
// 11 'ZIP' required,

const notBlankOrNull = (value: string) => !!value

const isValidBirthDay = (value: string) =>
  notBlankOrNull(value) && moment(value, 'YYYY-MM-DD', true).isValid()

const isValidSSN = (value: string) =>
  notBlankOrNull(value) && /[0-9]{9}/.test(value)

const isValidEmail = (email: string) => notBlankOrNull(email) && isEmail(email)

const isValidPhoneNumber = (value: string) => {
  const phoneUtil = new PhoneNumberUtil()
  try {
    const number = phoneUtil.parse(value, 'US')
    return phoneUtil.isValidNumber(number)
  } catch (e) {
    return false
  }
}

const isValidZipCode = (value: string) =>
  notBlankOrNull(value) && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)

const validateRow = (value: string, index: number) => {
  let reason = ''
  switch (index) {
    case 0:
    case 1:
      if (!notBlankOrNull(value)) reason = STATUS_REASON.REQUIRED_NAME
      return reason
    case 3:
      if (!isValidBirthDay(value)) reason = STATUS_REASON.INVALID_BDAY
      return reason
    case 4:
      if (!isValidSSN(value)) reason = STATUS_REASON.INVALID_SSN
      return reason
    case 5:
      if (!isValidEmail(value)) reason = STATUS_REASON.INVALID_EMAIL
      return reason
    case 6:
      if (!isValidPhoneNumber(value)) reason = STATUS_REASON.INVALID_PHONE
      return reason
    case 7:
      if (!notBlankOrNull(value)) reason = STATUS_REASON.REQUIRED_ADDRESS
      return reason
    case 9:
      if (!notBlankOrNull(value)) reason = STATUS_REASON.REQUIRED_CITY
      return reason
    case 10:
      if (!notBlankOrNull(value)) reason = STATUS_REASON.REQUIRED_STATE
      return reason
    case 11:
      if (!isValidZipCode(value)) reason = STATUS_REASON.INVALID_ZIPCODE
      return reason
    default:
      return reason
  }
}

export const useValidateCsvData = (files: File[]) => {
  const [data, setData] = useState<FileValidationError[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function validate() {
      if (!files.length) return
      const tmpErrors: FileValidationError[] = []
      const fileText = await readFileAsText(files[0])
      const { data: parsedData } = Papa.parse<string[]>(fileText)

      parsedData.slice(1).forEach((row, line) => {
        const failed: FileValidationError = {
          original_csv_line: line + 2,
          original_csv_row: row.join(),
          status: 'FAILED',
          status_reason: '',
        }

        for (let i in row) {
          failed.status_reason = validateRow(row[i], +i)
          if (failed.status_reason) break
        }

        if (failed.status_reason && row.length > 1) {
          tmpErrors.push(failed)
        }
      })
      setData(tmpErrors)
      setIsLoading(false)
    }
    validate()
  }, [files])

  return { data, isLoading }
}
