import { FC } from 'react'
import { Button } from '@/common/button'
import { ProcessDeductionsIcon } from '@/common/icons'
import { useDeductionReport } from '@/store/deduction-report'
import { ProgressCard } from './ProgressCard'
import ProgressCardFooter from './ProgressCardFooter'
import ProgressCardTitle from './ProgressCardTitle'

export const ProcessProgressCard: FC = () => {
  const {
    updateProgressStep,
    computed: { getProgressStepPosition },
  } = useDeductionReport()

  const stepPosition = getProgressStepPosition('process')

  const handleContinue = () => {
    updateProgressStep('adjust')
  }

  return (
    <ProgressCard isDisabled={stepPosition > 0}>
      <ProgressCardTitle
        text="Process deductions"
        Icon={ProcessDeductionsIcon}
      />
      <h5>
        Process the downloaded CSV through your payroll system to make sure all
        deductions can be taken.
      </h5>
      <ProgressCardFooter stepComplete={stepPosition < 0}>
        <Button
          mode={stepPosition < 0 ? 'outline' : 'filled'}
          disabled={stepPosition > 0}
          onClick={handleContinue}
        >
          CSV Processed
        </Button>
      </ProgressCardFooter>
    </ProgressCard>
  )
}
