import { saveAs } from 'file-saver'
import { sendAnalyticsEvent } from '@/lib/analytics'

const templateCsvContent =
  'ID,First Name,Last Name,Type,Amount,Transaction ID,Location,Disbursement Date (YYYY-MM-DD),Description\n333444,Ava,Butler,tips,100,1234,1234,2021-12-30,test desc'

export const downloadBulkDisbursementsTemplate = () => {
  sendAnalyticsEvent('disbursements', 'download-bulk-disbursements-template')
  const blob = new Blob([templateCsvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch-bulk-disbursements-template.csv')
}
