import { FC } from 'react'
import { DetailsView, UserRequestDetails } from '@/common/detail'
import { UserEnrollmentRequest } from '@/types/employees'

type Props = {
  user: UserEnrollmentRequest
  remove: () => void
}

export const EnrollmentRequestDetailsView: FC<Props> = ({ user, remove }) => (
  <DetailsView>
    <UserRequestDetails user={user} remove={remove} />
  </DetailsView>
)
