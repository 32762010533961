import { FC } from 'react'
import { IRemittance } from '@/types/remittances'

interface Props {
  remittance: IRemittance
}

export const RemittanceStatusDescription: FC<Props> = ({ remittance }) => {
  let title = ''
  let description = ''

  if (remittance.status === 'completed') {
    // if the remittance status is completed and there's an invoice,
    // we know to use the invoice status instead of the remittance status
    // to look for payment.
    if (remittance.payback) {
      // if the invoice status is anything but COMPLETED
      // we still require payment
      if (remittance.payback.status !== 'COMPLETED') {
        title = "Looks like we're waiting on a successful payment."
        description =
          'If you have any questions, please reach out to your Branch Customer Success representative.'
      } else {
        // if the invoice status is completed we've been paid
        title = 'You paid us for this remittance.'
        description =
          'If you have any questions, please reach out to your Branch Customer Success representative.'
      }
    } else {
      // remittance status is completed
      // but no invoice, so this is a successful legacy payment.
      title = 'Deductions for this remittance have been confirmed.'
      description =
        'An invoice was created for the total amount confirmed, and a summary of requested and confirmed amounts can be found below.'
    }
  } else {
    // handle when the remittance is not completed
    title = 'Looks like we still need to finish the remittance process.'
    description =
      'Please process these deductions through your payroll system and let us know if anything needs to be fixed.'
  }

  return (
    <>
      <h4>{title}</h4>
      <p>{description}</p>
    </>
  )
}
