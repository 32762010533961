import { SortingState } from '@tanstack/react-table'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  bulkQualifyEmployees,
  cancelKyc,
  getInitiatedUsers,
  qualifyEmployees,
} from '@/api/create-accounts'
import { GetInitiatedUsersRequestParams } from '@/api/types/create-accounts'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useCreateAccountsStore } from '@/store/create-accounts'
import { useToasterStore } from '@/store/toast'
import { TQualifyEmployeeData } from '@/types/create-accounts'

export interface InitiatedUsersQueryConfig {
  page?: number
  pageSize: number
  filtered: TableFilters
  sorted: SortingState
}

export const createInitiatedUserParams = ({
  page,
  pageSize,
  sorted,
  filtered,
}: InitiatedUsersQueryConfig): GetInitiatedUsersRequestParams => {
  const { to, from, ...restFilters } = filtered
  return {
    page: (page || 0) + 1,
    size: pageSize,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
    time_created_start: from?.toISOString(),
    time_created_end: to?.toISOString(),
    ...restFilters,
  }
}

export const INITIATED_USERS_QUERY = 'initiated-users'

export const useInitiatedUsersQuery = (config: InitiatedUsersQueryConfig) => {
  const { id: orgId } = useCurrentOrg()
  const { ready } = useCreateAccountsStore()
  const params = createInitiatedUserParams(config)

  return useQuery(
    [INITIATED_USERS_QUERY, orgId, params],
    async () => {
      const res = await getInitiatedUsers(orgId, params)
      return res.data.data
    },
    {
      enabled: ready,
    }
  )
}

export const useQualifyEmployeeMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()

  const queryClient = useQueryClient()
  return useMutation(
    async (params: TQualifyEmployeeData) => {
      const res = await qualifyEmployees(orgId, params)
      return res.data.data
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent('accounts', 'create:success', {
          employee_id: payload.employee_id,
        })
        queryClient.invalidateQueries(INITIATED_USERS_QUERY)
      },
      onError: (err: AxiosError) => {
        sendAnalyticsEvent('accounts', 'create:failure')
        addToast({
          type: 'error',
          title: 'Could not create account',
          description: err.response?.data?.meta?.errorDetail,
        })
      },
    }
  )
}

export const useBulkQualifyEmployeesMutation = () => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation<
    void,
    Error,
    {
      file: File
    },
    unknown
  >(
    async params => {
      try {
        await bulkQualifyEmployees(orgId, params.file)
      } catch (err) {
        throw new Error(err.response?.data?.meta.errorDetail)
      }
    },
    {
      onSuccess: () => {
        sendAnalyticsEvent('accounts', 'bulk-upload:success')
        queryClient.invalidateQueries(INITIATED_USERS_QUERY)
      },
      onError: () => sendAnalyticsEvent('accounts', 'bulk-upload:failure'),
    }
  )
}

export const CANCEL_KYC_MUTATION = 'cancel-kyc-mutation'

export const useCancelKycMutation = (id: number) => {
  const { id: orgId } = useCurrentOrg()

  const queryClient = useQueryClient()
  return useMutation(() => cancelKyc(orgId, id), {
    onSuccess: () => {
      sendAnalyticsEvent('accounts', 'cancel-kyc:success')
      queryClient.invalidateQueries(INITIATED_USERS_QUERY)
    },
  })
}
