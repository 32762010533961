import { FC, useMemo } from 'react'
import { Button } from '@/common/button'
import { Box } from '@/common/layout'
import { SimpleTable } from '@/common/table/SimpleTable'
import { useOrganizationAdmins } from '@/queries/permissions'
import { useModalStore } from '@/store/modal'
import { ScopeType } from '@/types/permissions'
import { adminColumns } from '../lib/columns'
import { ConfigureAdminModal } from './ConfigureAdminModal'

export const Admins: FC = () => {
  const organizationAdmins = useOrganizationAdmins()
  const { registerModal } = useModalStore()
  const openInviteEmailModal = () => {
    registerModal(<ConfigureAdminModal />)
  }

  const filteredAdmins = useMemo(
    () =>
      organizationAdmins.data?.filter(
        record =>
          !!record.roles.find(
            r => r.scope_type !== ScopeType.GLOBAL.toUpperCase()
          )
      ) || [],
    [organizationAdmins]
  )

  return (
    <>
      <hr className="my-5" />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Administrators</h5>
        <Button mode="filled" onClick={openInviteEmailModal}>
          Invite Admin
        </Button>
      </div>
      <Box
        css={{ backgroundColor: 'white', borderRadius: '$sm', padding: '$8' }}
      >
        <SimpleTable
          data={filteredAdmins}
          columns={adminColumns}
          hideLastColumnBorder
        />
      </Box>
    </>
  )
}
