import { FC, useMemo, useState } from 'react'
import { Table } from 'reactstrap'
import { BulkUploadStepsModal } from '@/common/bulk-upload/BulkUploadStepsModal'
import { Status } from '@/common/bulk-upload/types'
import { parseAmount, toCents, toDollar } from '@/lib/currency'
import { useValidateBulkDisbursements } from '@/queries/file-validation'
import { useBulkDisbursementsMutation } from '@/src/queries/payouts'
import { useModalStore } from '@/store/modal'

interface Props {
  retry: boolean
  files: File[]
  columnHeaders: string[]
}

export const BulkDisbursementsUploadSteps: FC<Props> = ({
  files,
  retry,
  columnHeaders,
}) => {
  const [open, setOpen] = useState(true)
  const { removeModal } = useModalStore()
  const [status, setStatus] = useState<Status>(Status.IDLE)
  const bulkDisbursement = useBulkDisbursementsMutation()
  const [data, setData] = useState<string[][]>([])
  const bulkDisbursementValidation = useValidateBulkDisbursements(files[0])

  const totalAmount = useMemo(
    () =>
      data
        .map(payout => parseAmount(payout[4]))
        .reduce((prev, curr) => prev + curr, 0),
    [data]
  )

  const onConfirm = () => {
    setStatus(Status.LOADING)
    bulkDisbursement.mutate(
      { file: files[0], retry },
      {
        onSuccess: () => setStatus(Status.UPLOAD_SUCCESS),
        onError: () => setStatus(Status.UPLOAD_ERROR),
      }
    )
  }

  const onClose = () => {
    setOpen(false)
    setData([])
    removeModal()
  }

  return (
    <BulkUploadStepsModal
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
      status={status}
      files={files}
      columnHeaders={columnHeaders}
      error={bulkDisbursement.error || bulkDisbursementValidation.error}
      errorData={bulkDisbursementValidation.data || []}
      isLoading={bulkDisbursementValidation.isLoading}
      isValidating={bulkDisbursementValidation.isLoading}
      setData={setData}
    >
      <Table bordered>
        <tbody>
          <tr>
            <td className="font-weight-bold">Total Amount</td>
            <td>{toDollar(toCents(`${totalAmount}`))}</td>
          </tr>
          <tr>
            <td className="font-weight-bold">Total Disbursements</td>
            <td>{data.length}</td>
          </tr>
        </tbody>
      </Table>
    </BulkUploadStepsModal>
  )
}
