import { useEffect } from 'react'
import { Alert } from 'reactstrap'
import { Button } from '@/common/button'
import { CompleteIcon, ErrorIcon } from '@/common/icons'
import Spinner from '@/components/common/loader/Spinner'
import { FileImportCard, ImportCard } from '@/components/spreadsheet-mapper'
import { MatchObject } from '@/components/spreadsheet-mapper/useMatchStore'
import {
  Step,
  StepAside,
  StepBody,
  StepDescription,
  StepFooter,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { useImportRoster } from '@/hooks/useImportRoster'

interface IProps {
  file: File
  columnMatches: MatchObject
  transition?: string
  closeModal: () => void
}

export const ValidateStep = (props: IProps) => {
  const { transition, closeModal, file, columnMatches } = props
  const {
    handleUpload,
    uploadingTestConfig,
    uploadingTestConfigSucceeded,
    uploadingConfig,
    uploadingConfigSucceeded,
    uploadingFile,
    uploadingFileSucceeded,
    errorMessage,
    showOverridePrompt,
    showGeneralErrorPrompt,
  } = useImportRoster(file, columnMatches)

  useEffect(() => {
    handleUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!file || !columnMatches) return null

  const testUploadingConfigIcon = uploadingTestConfig ? (
    <Spinner />
  ) : !uploadingTestConfigSucceeded ? (
    <ErrorIcon />
  ) : (
    <CompleteIcon />
  )

  const uploadingConfigIcon = uploadingConfig ? (
    <Spinner />
  ) : !uploadingConfigSucceeded ? (
    <ErrorIcon />
  ) : (
    <CompleteIcon />
  )

  const uploadingFileIcon = uploadingFile ? (
    <Spinner />
  ) : !uploadingFileSucceeded ? (
    <ErrorIcon />
  ) : (
    <CompleteIcon />
  )

  return (
    <Step state={transition}>
      <StepBody hasAside>
        <StepTitle>
          Great! Hold tight, we are importing your users into our system.
        </StepTitle>
        <StepDescription>This should just take a moment.</StepDescription>
        {uploadingFileSucceeded && (
          <Alert color="success" className="mt-4">
            You are good to go! Your roster has been successfully processed.
          </Alert>
        )}
        {showOverridePrompt && (
          <Alert color="info" className="mt-4">
            <p>{errorMessage}.</p>
            <Button
              color="danger"
              mode="outline"
              onClick={() => handleUpload(true)}
            >
              Upload with Override
            </Button>
          </Alert>
        )}
        {showGeneralErrorPrompt && (
          <Alert color="danger" className="mt-4">
            We were not able to process your roster in the format you gave us.
            Please check your CSV to make sure there are no issues and try
            again. <i>(Error: {errorMessage})</i>
          </Alert>
        )}
      </StepBody>

      <StepAside>
        {file && <FileImportCard file={file} />}

        <ImportCard
          title="Testing"
          className="mb-4"
          icon={testUploadingConfigIcon}
          subtitle="We just need to check if your file will work."
        />
        {uploadingTestConfigSucceeded && (
          <ImportCard
            title="Saving Configuration"
            className="mb-4"
            icon={uploadingConfigIcon}
            subtitle="Adding the new configuration."
          />
        )}
        {uploadingConfigSucceeded && (
          <ImportCard
            title="Saving File Contents"
            className="mb-4"
            icon={uploadingFileIcon}
            subtitle="Uploading the new roster."
          />
        )}
      </StepAside>

      <StepFooter>
        <StepFooterRight>
          <Button
            mode="filled"
            onClick={closeModal}
            disabled={uploadingFile || uploadingConfig || uploadingTestConfig}
          >
            Close
          </Button>
        </StepFooterRight>
      </StepFooter>
    </Step>
  )
}
