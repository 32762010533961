import { useMemo, useState } from 'react'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { OptionFilter } from '@/common/filters/OptionFilter'
import { Flex, FlexWrap } from '@/common/layout'
import { useFundingSources } from '@/queries/funding-sources'
import { useInvoiceConfigs } from '@/queries/invoice-configs'
import { useModalStore } from '@/store/modal'
import { InvoiceConfigForm } from './components/InvoiceConfigForm'
import { InvoiceConfigModal } from './components/InvoiceConfigModal'
import { InvoiceConfigurationsContainer } from './components/InvoiceConfigurationsContainer'
import { Payments } from './components/Payments'

export const InvoiceConfigurations = () => {
  const [filterId, setFilterId] = useState<number | string | undefined>()
  const { data } = useInvoiceConfigs()
  const { data: fundingSources } = useFundingSources()
  const { registerModal } = useModalStore()

  const openInvoiceConfigModal = () => {
    registerModal(<InvoiceConfigModal />)
  }

  const filterOptions = useMemo(
    () =>
      (fundingSources || []).map(rec => ({
        label: rec.name,
        value: rec.id,
      })),
    [fundingSources]
  )

  const configurations = useMemo(() => {
    const active = (data || []).filter(rec => rec.enabled)
    if (!filterId) return active

    return active.filter(rec => rec.funding_source_id === filterId)
  }, [data, filterId])

  return (
    <InvoiceConfigurationsContainer>
      <Payments />
      <Flex justify="between" align="center">
        <h5 className="my-3">Invoice Configurations</h5>
        <FlexWrap>
          <OptionFilter
            value={filterId}
            defaultLabel="All"
            options={filterOptions}
            handleChange={id => setFilterId(id)}
          />
          <Button onClick={openInvoiceConfigModal} mode="filled">
            Create Invoice Configuration
          </Button>
        </FlexWrap>
      </Flex>

      {configurations.map(rec => (
        <Card key={rec.id} style={{ marginBottom: '16px' }}>
          <InvoiceConfigForm data={rec} />
        </Card>
      ))}
    </InvoiceConfigurationsContainer>
  )
}
