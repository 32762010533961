import { ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Flex } from '@/common/layout'
import { IRegistrationProcessor } from '@/types/settings'
import { CodeCell } from '../components/CodeCell'
import { ProcessorCell } from '../components/ProcessorCell'
import { ProcessorDropdown } from '../components/ProcessorDropdown'
import { RegistrationType } from './types'

export type IProcessorRow = IRegistrationProcessor & {
  registrationId: number
  registrationType: RegistrationType
}

export const subcolumns: ColumnDef<IProcessorRow, any>[] = [
  {
    header: 'Processor',
    id: 'processor',
    accessorFn: row => row.processor_name,
    cell: ({ row }) => <ProcessorCell original={row.original} />,
  },
  {
    header: 'Regex',
    id: 'regex',
    accessorFn: row => row.regex,
    cell: row => <CodeCell value={row.getValue()} />,
  },
  {
    header: 'Created',
    id: 'created',
    accessorFn: row => moment(row.time_created),
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
  },
  {
    header: 'Effective',
    id: 'effective',
    accessorFn: row => moment(row.effective_date),
    cell: row => (row.getValue() ? row.getValue().format('MM/DD/YYYY') : null),
  },
  {
    header: 'Active?',
    id: 'active',
    accessorFn: row => (row.active ? 'True' : 'False'),
  },
  {
    header: '',
    accessorKey: 'id',
    id: 'actions',
    size: 40,
    cell: ({ row }) => (
      <Flex justify="center">
        <ProcessorDropdown original={row.original} />
      </Flex>
    ),
    enableSorting: false,
  },
]
