import { FC } from 'react'
import { Button } from '@/common/button'
import { AdminIcon } from '@/common/icons'
import {
  PageActions,
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { AddResourceModal } from './AddResourceModal'
import { ResourceFilters } from './ResourceFilters'

export const ResourcesContainer: FC = ({ children }) => {
  const { registerModal } = useModalStore()
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const openAddResourceModal = () => {
    registerModal(<AddResourceModal />)
  }

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Resources" />
          {isGlobalAdmin && (
            <PageActions>
              <Button mode="filled" onClick={openAddResourceModal} iconRight>
                Add Resources
                <AdminIcon />
              </Button>
            </PageActions>
          )}
        </PageHeader>
        <PageFilters>
          <ResourceFilters />
        </PageFilters>
      </PageTop>
      <PageBody>{children}</PageBody>
    </>
  )
}
