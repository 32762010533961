import { usePermissionsStore } from '@/store/permissions'
import { Admins } from './components/Admins'
import { RolesPermissionsContainer } from './components/RolesPermissionsContainer'
import { RolesTable } from './components/RolesTable'

export const RolesPermissionsSettings = () => {
  const { hasPermission } = usePermissionsStore(state => state.computed)

  return (
    <RolesPermissionsContainer>
      <RolesTable />
      {hasPermission('permission.management') && <Admins />}
    </RolesPermissionsContainer>
  )
}
