import { FC, useMemo } from 'react'
import { LogoMark } from '@/common/logos'
import { MobileNav } from '@/common/mobile-nav'
import { OrganizationMenu } from '@/components/organizations/OrganizationMenu'
import { useNavigationTree } from '@/hooks/useNavigationTree'
import { useSetting } from '@/hooks/useSetting'
import { getLuminance } from '@/lib/color'
import { getCachedNavColor } from '@/src/sessionstorage'
import { NavLink } from './nav-item'
import { NavContainer } from './styles'

interface Props {
  isMobile: boolean
}

export const Nav: FC<Props> = ({ isMobile }) => {
  const BRANDING_COLOR_HEX_CODE = useSetting('BRANDING_COLOR_HEX_CODE')
  const { permittedNavigationTree } = useNavigationTree()
  const navColor = useMemo(() => {
    if (BRANDING_COLOR_HEX_CODE) {
      return BRANDING_COLOR_HEX_CODE
    } else {
      const cachedColor = getCachedNavColor()
      return cachedColor || '#2a5a42'
    }
  }, [BRANDING_COLOR_HEX_CODE])

  const bgIsDark = useMemo(() => {
    const lum = getLuminance(navColor)
    // luminance will be between 0 and 255,
    // 160 is just a magic number for what
    // feels like a good spot to switch.
    return lum < 160
  }, [navColor])

  return (
    <>
      <div className="MainNavPadder" />
      <div
        className={`MainNav ${bgIsDark ? 'Dark' : 'Light'}`}
        style={{ backgroundColor: navColor }}
      >
        <LogoMark
          height={32}
          className="logomark"
          fill={bgIsDark ? 'white' : 'dark'}
        />

        <NavContainer mobile={isMobile}>
          {!isMobile && (
            <>
              <div className="NavSeparator" />
              {permittedNavigationTree.map(segment => (
                <NavLink key={segment.route} {...segment} />
              ))}
            </>
          )}
          {isMobile && <MobileNav />}
          <OrganizationMenu />
        </NavContainer>
      </div>
    </>
  )
}
