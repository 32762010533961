import qs from 'qs'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { getQueryParamObjectFromString } from '@/lib/queryParams'
import { prefixFilterKey, stripPrefixFromFilterKey } from './lib/utils'
import { TableFilters } from './types'

export const useUpdateQueryParamFilters = (filters: TableFilters = {}) => {
  const history = useHistory()

  useEffect(() => {
    const existingParams: any = getQueryParamObjectFromString(
      history.location.search
    )

    let params: { [key: string]: string } = existingParams || {}

    Object.keys(params).forEach(key => {
      const filterKeys = Object.keys(filters)
      if (
        filterKeys.length &&
        !filterKeys.includes(stripPrefixFromFilterKey(key))
      ) {
        delete params[key]
      }
    })

    Object.keys(filters).forEach(key => {
      const updatedFilter = filters[key]

      // get rid of date range keys,
      // we'll deal with them later
      if (!['from', 'to', 'preset'].includes(key)) {
        params[prefixFilterKey(key)] = `${updatedFilter}`
      }
    })

    if (filters.from && filters.to) {
      params[prefixFilterKey('from')] = filters.from.format('YYYY-MM-DD')
      params[prefixFilterKey('to')] = filters.to.format('YYYY-MM-DD')
      delete params[prefixFilterKey('preset')]
    }

    if (filters.preset) {
      params[prefixFilterKey('preset')] = filters.preset
      delete params[prefixFilterKey('from')]
      delete params[prefixFilterKey('to')]
    }

    const search = qs.stringify(params)

    history.push({
      search,
    })
  }, [filters, history])
}
