import classnames from 'classnames'
import { FC, useEffect, useRef } from 'react'
import { Badge } from '@/common/badge'
import { Button } from '@/common/button'
import { ArrowRightIcon, CompleteIcon } from '@/common/icons'
import { QuestionRow } from '@/components/spreadsheet-mapper/useMatchStore'
import styles from './index.module.scss'

interface Props {
  question: QuestionRow
  active: boolean
  handleClick: () => void
  handleAnswer: (value: any) => void
  index: number
}

export const QuestionCard: FC<Props> = ({
  question,
  handleClick,
  active,
  index,
  handleAnswer,
}) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => {
    if (active && index !== 0) {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [active, index])

  const classes = classnames(styles.MatchingRow, {
    [styles.MatchingRow_Active]: active,
  })

  const answerOption = question.options.find(o => o.value === question.value)

  const preText = index === 0 ? 'First' : 'Next'

  return (
    <div ref={ref} className={classes} onClick={handleClick}>
      <div className={styles.MatchingRowFlex}>
        <span className={styles.MatchInfoNumber}>{index + 1}</span>
        <div>
          {active && (
            <>
              <p className="mb-3 font-weight-bold">
                {preText}, {question.text}
              </p>
              {question.options.map(option => (
                <Button
                  mode={question.value === option.value ? 'filled' : 'outline'}
                  key={option.label}
                  css={{ mr: '$4' }}
                  onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation()
                    handleAnswer(option.value)
                  }}
                >
                  {option.label}
                </Button>
              ))}
            </>
          )}
          {!active && (
            <div className={styles.MatchInfo}>
              <Badge type="secondary" size="sm">
                {question.label}
              </Badge>

              {typeof answerOption !== 'undefined' && (
                <>
                  <ArrowRightIcon className={styles.ArrowIcon} />
                  <Badge
                    type="secondary"
                    size="sm"
                  >{`${answerOption.label}`}</Badge>
                  <CompleteIcon className={styles.CompleteIcon} />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
