import { AxiosPromise } from 'axios'
import { FC, useState } from 'react'
import { Modal } from 'reactstrap'
import { ILocationResult, IUploadLocationsResponse } from '@/api/types/location'
import { Loader } from '@/common/loader'
import { CsvConfirmStep } from '@/components/csv-confirm'
import { StepGroup, Transition } from '@/components/stepped-modal'
import useParsedCsv from '@/src/hooks/useParsedCsv'
import useSteps from '@/src/hooks/useSteps'
import { useModalStore } from '@/store/modal'
import { LocationUploadReviewStep } from './LocationUploadReviewStep'

export type TCurrentStep = 'CONFIRM' | 'REVIEW'

export type TStep = {
  id: TCurrentStep
  label: string
}

export const STEP_IDS: { [key in TCurrentStep]: TCurrentStep } = {
  CONFIRM: 'CONFIRM',
  REVIEW: 'REVIEW',
}

export const STEPS: TStep[] = [
  { id: STEP_IDS.CONFIRM, label: 'Confirm' },
  {
    id: STEP_IDS.REVIEW,
    label: 'Review',
  },
]

interface Props {
  handleSubmit: () => AxiosPromise<IUploadLocationsResponse>
  onClose?: () => void
  files: File[]
  secondaryNs: string
}

export const LocationUploadModal: FC<Props> = ({
  files,
  handleSubmit,
  onClose,
  secondaryNs,
}) => {
  const steps = useSteps(STEPS)
  const parsed = useParsedCsv(files)
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [csvSucceeded, setCsvSucceeded] = useState(false)
  const [returnedRows, setReturnedRows] = useState<ILocationResult[]>([])

  const onSubmit = async () => {
    setLoading(true)
    steps.goToNextStep()
    setCsvSucceeded(false)
    try {
      const res = await handleSubmit()
      setCsvSucceeded(true)
      setReturnedRows(res.data.data.csv_processor)
      return res
    } catch (err) {
      setCsvSucceeded(false)
      return err
    } finally {
      setLoading(false)
    }
  }

  const close = () => {
    if (onClose) onClose()
    setOpen(false)
    removeModal()
  }

  return (
    <Modal isOpen={open} toggle={close} centered className="jumbo light-bg">
      <Loader loading={parsed.loading}>
        {!parsed.loading && parsed.csv.length > 1 && (
          <StepGroup>
            <Transition in={steps.currentStep.id === STEP_IDS.CONFIRM}>
              <CsvConfirmStep
                handleSubmit={onSubmit}
                csvData={parsed.csv}
                title="Confirm your file contents"
                handleClose={close}
              />
            </Transition>
            <Transition in={steps.currentStep.id === STEP_IDS.REVIEW}>
              <LocationUploadReviewStep
                csvData={parsed.csv}
                secondaryNs={secondaryNs}
                handleClose={close}
                loading={loading}
                csvSucceeded={csvSucceeded}
                returnedRows={returnedRows}
              />
            </Transition>
          </StepGroup>
        )}
      </Loader>
    </Modal>
  )
}
