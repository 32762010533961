import { FC } from 'react'
import ContentEmpty from '@/common/empty'
import { Card } from '../card'

export const ContentProblem: FC = () => (
  <Card>
    <ContentEmpty
      title="We ran into a problem."
      height="xs"
      subtitle="Try refreshing the page. If the problem continues, contact your Branch representative."
    />
  </Card>
)
