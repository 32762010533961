import { FC, useState } from 'react'
import { Box } from '@/common/layout'
import { Text } from '@/common/text'
import { Popover } from '@/components/common/popover'
import { MoreFiltersDropdown } from './MoreFiltersDropdown'
import { AdditionalFilterItem, TableFilters, UpdateFilters } from './types'

interface Props {
  filters: TableFilters
  updateFilters: UpdateFilters
  menu: AdditionalFilterItem[]
  initialFocusId?: string
  open: boolean
  setOpen: (open: boolean) => void
}

export const MoreFiltersMenu: FC<Props> = ({
  filters,
  menu,
  updateFilters,
  initialFocusId,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  return (
    <Box css={{ margin: 'auto' }}>
      <Popover
        trigger={
          <Text color="primary" css={{ fontSize: '14px', fontWeight: 'bold' }}>
            View All Filters
          </Text>
        }
        open={popoverOpen}
        setOpen={setPopoverOpen}
        showClose
      >
        <MoreFiltersDropdown
          filters={filters}
          updateFilters={updateFilters}
          menu={menu}
          initialFocusId={initialFocusId}
          setPopoverOpen={setPopoverOpen}
        />
      </Popover>
    </Box>
  )
}
