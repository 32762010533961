import { RowData, SortingState } from '@tanstack/react-table'
import { StateCreator } from 'zustand'
import { TableFilters } from '@/common/filters/types'

export type TableFiltersSlice = {
  ready: boolean
  filters: TableFilters
  page: number
  pageSize: number
  sorted: SortingState
  defaultSort: SortingState
  paginationParams: object | undefined
  computed: {
    filterArray: { id: string; value: string }[]
  }
  filteredData: RowData[]
  setFilteredData: (filteredData: RowData[]) => void
  isReady: () => void
  setSorted: (sorted: SortingState) => void
  updatePage: (page: number) => void
  updatePageSize: (pageSize: number) => void
  updateFilters: (updatedFilters: TableFilters) => void
  removeFilter: (id: string) => void
  setPaginationParams: (paginationParams?: object) => void
}

export const createTableFilterSlice: StateCreator<TableFiltersSlice, [], []> = (
  set,
  get
) => ({
  filters: {},
  page: 0,
  pageSize: 25,
  ready: false,
  sorted: [
    {
      id: 'time_created',
      desc: true,
    },
  ],
  defaultSort: [
    {
      id: 'time_created',
      desc: true,
    },
  ],
  filteredData: [],
  setFilteredData: (filteredData: RowData[]) => {
    set(state => ({ ...state, filteredData }))
  },
  paginationParams: undefined,
  computed: {
    get filterArray() {
      return Object.keys(get().filters).map(id => ({
        id,
        value: get().filters[id],
      }))
    },
  },
  updatePage: (page: number) => set(state => ({ ...state, page })),
  updatePageSize: (pageSize: number) => set(state => ({ ...state, pageSize })),
  setSorted: (sorted: SortingState) => {
    get().updatePage(0)
    set(state => ({ ...state, sorted, paginationParams: undefined }))
  },

  updateFilters: (updatedFilters: TableFilters) => {
    set(state => {
      Object.keys(updatedFilters).forEach(filterKey => {
        if (
          updatedFilters[filterKey] === '' ||
          updatedFilters[filterKey] === undefined
        ) {
          delete state.filters[filterKey]
          delete updatedFilters[filterKey]
        }
      })
      return {
        ...state,
        page: 0,
        paginationParams: undefined,
        filters: { ...state.filters, ...updatedFilters },
      }
    })
  },
  removeFilter: (id: string) => {
    set(state => {
      const filters = { ...state.filters }
      delete filters[id]
      return { ...state, filters, page: 0 }
    })
  },
  setPaginationParams: (paginationParams?: object) =>
    set(state => ({ ...state, paginationParams })),
  isReady: () => set(state => ({ ...state, ready: true })),
})
