import qs from 'qs'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useTableFilterSearchParams } from '@/common/filters/useTableFilterSearchParams'
import { getQueryParamObjectFromString } from '@/lib/queryParams'
import { prefixFilterKey } from './lib/utils'
import { TableFilters, UpdateFilters } from './types'

export const useTableFilters = (initialState: TableFilters = {}) => {
  const tableSearchParams = useTableFilterSearchParams()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [filters, setFilters] = useState(() => ({
    ...initialState,
    ...tableSearchParams,
  }))
  const history = useHistory()

  const updatePage = useCallback((val: number) => setPage(val), [setPage])
  const updatePageSize = useCallback((val: number) => setPageSize(val), [
    setPageSize,
  ])

  // update query parameters to reflect
  // currently active filters
  const updateQueryParameters = useCallback(
    (updatedFilters: TableFilters) => {
      const existingParams: any = getQueryParamObjectFromString(
        history.location.search
      )

      let params: { [key: string]: string } = existingParams || {}

      Object.keys(updatedFilters).forEach(key => {
        const updatedFilter = updatedFilters[key]

        // we don't want to store empty strings or undefineds
        if (updatedFilter === '' || updatedFilter === undefined) {
          const existingParam = params[prefixFilterKey(key)]
          if (existingParam) {
            delete params[prefixFilterKey(key)]
          }
          return
        }

        // get rid of date range keys,
        // we'll deal with them later
        if (!['from', 'to', 'preset'].includes(key)) {
          params[prefixFilterKey(key)] = `${updatedFilter}`
        }
      })

      if (updatedFilters.from && updatedFilters.to) {
        params[prefixFilterKey('from')] = updatedFilters.from.format(
          'YYYY-MM-DD'
        )
        params[prefixFilterKey('to')] = updatedFilters.to.format('YYYY-MM-DD')
        delete params[prefixFilterKey('preset')]
      }

      if (updatedFilters.preset) {
        params[prefixFilterKey('preset')] = updatedFilters.preset
        delete params[prefixFilterKey('from')]
        delete params[prefixFilterKey('to')]
      }

      const search = qs.stringify(params)

      history.push({
        search,
      })
    },
    [history]
  )

  const updateFilters = useCallback<UpdateFilters>(
    updatedFilters => {
      updateQueryParameters(updatedFilters)

      setFilters(f => {
        // remove empty strings and undefined values
        Object.keys(updatedFilters).forEach(filterKey => {
          if (
            updatedFilters[filterKey] === '' ||
            updatedFilters[filterKey] === undefined
          ) {
            delete f[filterKey]
            delete updatedFilters[filterKey]
          }
        })
        return {
          ...f,
          ...updatedFilters,
        }
      })
      updatePage(0)
    },
    [setFilters, updateQueryParameters, updatePage]
  )

  const removeFilter = useCallback(
    (id: string) => {
      setFilters(f => {
        delete f[id]
        return f
      })
      updatePage(0)
    },
    [setFilters, updatePage]
  )

  // memoized value that formats filters
  // for React-Table
  const filterArray = useMemo(
    () =>
      Object.keys(filters).map(id => ({
        id,
        value: filters[id],
      })),
    [filters]
  )

  return {
    filters,
    filterArray,
    removeFilter,
    updateFilters,
    page,
    updatePage,
    pageSize,
    updatePageSize,
  }
}
