import { IGetIngestionConfigurationsResponse } from '@/api/types/ingestion'
import { IEmailRegistrationConfig } from '@/api/types/settings'
import http from './http'

/**
 * Allows the user to get a response of all the ingestion configurations we have
 * Where the config data is in YAML form
 */
export const getIngestionConfigurations = (orgId: number) =>
  http.get<IGetIngestionConfigurationsResponse>(
    `/v2/proto/ingest/configurations?organization_id=${orgId}`
  )

/**
 * Allows the user to import a file of roster data into our system
 * @param config a configuration object that contains the file to be uploaded and other required information.
 */
export const importRoster = (config: { [key: string]: string }) =>
  http.post('/v2/proto/ingest/roster', config)

/**
 * Allows the user to create a new email file processor in our system
 * @param config a configuration object that contains the file processor details and other required information.
 */
export const addEmailRegistration = (
  config: IEmailRegistrationConfig,
  orgId: number
) => http.post(`wfm/organizations/${orgId}/registrations/emails`, config)

/**
 * Allows Pay Admins to import time and attendance data
 * @param config Contains the file along with configuration information
 */
export const importTA = (config: { [key: string]: string }) =>
  http.post('/v2/proto/ingest/timekeeping', config)
