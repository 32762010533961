import { styled } from '@/styles/stitches.config'

export const Card = styled('div', {
  padding: '$24',
  backgroundColor: 'white',
  borderRadius: '$lg',
  boxShadow: '$card',
  variants: {
    compact: {
      true: {
        padding: '$16',
        br: '$md',
      },
    },
  },
})
