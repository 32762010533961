import { useEffect, useMemo } from 'react'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { Table } from '@/common/table'
import { useDefinedTablePagination } from '@/hooks/useTablePagination'
import { useOrgJobsQuery } from '@/queries/jobs'
import { useJobsStore } from '@/store/jobs'
import { JobsContainer } from './components/JobsContainer'
import { columns } from './lib/columns'

const initialSortedState = [
  {
    id: 'id',
    desc: true,
  },
]

export const Jobs = () => {
  const {
    page,
    filters,
    sorted,
    setSorted,
    updatePage,
    updatePageSize,
    updateFilters,
    isReady,
    pageSize,
  } = useJobsStore()

  useUpdateQueryParamFilters(filters)

  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
    isReady()
  }, [queryParams, updateFilters, isReady])

  const { data, isLoading } = useOrgJobsQuery({
    page,
    pageSize,
    sorted,
    filters,
  })

  const jobs = useMemo(() => data?.content || [], [data])

  const { hasNextPage, hasPrevPage } = useDefinedTablePagination(
    page,
    data?.total_pages
  )

  return (
    <JobsContainer>
      <Table
        columns={columns}
        data={jobs}
        controlledPagination={true}
        sorted={sorted}
        onSortedChange={setSorted}
        initialSortedState={initialSortedState}
        isLoading={isLoading}
        page={page}
        pageRows={pageSize}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        changePage={updatePage}
        updatePageSize={updatePageSize}
      />
    </JobsContainer>
  )
}
