import { PlusCircledIcon, TrashIcon } from '@radix-ui/react-icons'
import { FC, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  CreateInvoiceConfigMapping,
  CreateInvoiceConfiguration,
  InvoiceConfiguration,
} from '@/api/types/invoice'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Select } from '@/common/select'
import { FundingSourceSelect } from '@/components/funding-source-select'
import {
  useCreateInvoiceConfig,
  useCreateInvoiceConfigMapping,
  useDeleteInvoiceConfig,
  useDeleteInvoiceConfigMapping,
  useUpdateInvoiceConfig,
} from '@/queries/invoice-configs'
import { FundingSource } from '@/types/invoice'
import { typeOptions } from '../utils/typeOptions'
import { AddFundingSourceForm } from './AddFundingSourceForm'
import { CardInset, CardInsetHeading } from './styles'

interface Props {
  data?: InvoiceConfiguration
  fundingSourceId?: number
  onClose?: () => void
}

const defaultMapping = {
  group_id: null,
  type: null,
}

export const InvoiceConfigForm: FC<Props> = ({
  data,
  fundingSourceId,
  onClose,
}) => {
  const [newFundingSource, setNewFundingSource] = useState(false)
  const createInvoiceConfig = useCreateInvoiceConfig()
  const updateInvoiceConfig = useUpdateInvoiceConfig()
  const deleteInvoiceConfig = useDeleteInvoiceConfig()
  const deleteMapping = useDeleteInvoiceConfigMapping()
  const createMapping = useCreateInvoiceConfigMapping()

  const { register, setValue, watch, handleSubmit, control } = useForm<
    CreateInvoiceConfiguration
  >({
    defaultValues: {
      name: data?.name || '',
      funding_source_id: data?.funding_source_id,
      mappings: data?.mappings || [{ ...defaultMapping }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'mappings',
    control,
  })

  useEffect(() => {
    if (!fundingSourceId) return
    setValue('funding_source_id', fundingSourceId)
  }, [setValue, fundingSourceId])

  const onSubmit = (values: CreateInvoiceConfiguration) => {
    if (data) {
      const { mappings, ...payload } = values
      updateInvoiceConfig.mutate(
        { configId: data.id, data: payload },
        {
          onSuccess: () => {
            const unsavedMappings = mappings
              .filter(rec => !rec.time_created)
              .map(({ type, group_id }) => ({
                type,
                group_id,
              })) as CreateInvoiceConfigMapping[]
            unsavedMappings.forEach(mapping => {
              createMapping.mutate({ configId: data.id, data: mapping })
            })
          },
        }
      )
      return
    }

    createInvoiceConfig.mutate(values, {
      onSuccess: () => onClose && onClose(),
    })
  }

  const onCreateFundingSource = (fundingSource: FundingSource) => {
    setValue('funding_source_id', fundingSource.id)
    setNewFundingSource(false)
  }

  const addMapping = () => append({ ...defaultMapping })

  const removeMapping = (index: number) => {
    remove(index)
    if (data) {
      const rec = data.mappings[index]
      if (rec) {
        deleteMapping.mutate({
          configId: rec.invoice_configuration_id,
          mappingId: rec.id,
        })
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label>Name</Label>
        <Flex>
          <Input {...register('name', { required: true })} />
        </Flex>
        <div>
          <FundingSourceSelect
            selectedId={watch('funding_source_id') || undefined}
            setSelectedId={id => {
              setValue('funding_source_id', id)
              setNewFundingSource(false)
            }}
            createable={!data}
            onCreate={() => {
              setNewFundingSource(true)
              setValue('funding_source_id', undefined)
            }}
          />
        </div>
        {data?.close_expression && (
          <div>
            <Label htmlFor="close_expression">Closing Formula</Label>
            <p>{data.close_expression}</p>
          </div>
        )}

        {data?.bill_expression && (
          <div>
            <Label htmlFor="bill_expression">Billing Formula</Label>
            <p>{data.bill_expression}</p>
          </div>
        )}
        <div>
          <Label>Mappings</Label>
          {fields.map((rec, index) => (
            <FlexWrap key={rec.id} align="center" css={{ marginBottom: '$4' }}>
              <Input
                {...register(`mappings.${index}.group_id` as const)}
                type="text"
                placeholder="Group Id"
                css={{ width: '25%' }}
                disabled={!!rec.time_created}
              />
              <Flex css={{ width: '60%' }}>
                <Select
                  options={typeOptions}
                  disabled={!!rec.time_created}
                  value={rec.type || ''}
                  onChange={val =>
                    setValue(`mappings.${index}.type` as any, val)
                  }
                />
              </Flex>

              <Button
                size="md"
                color="danger"
                mode="outline"
                onClick={() => removeMapping(index)}
                iconRight
              >
                <TrashIcon />
              </Button>
            </FlexWrap>
          ))}
        </div>
        <Flex justify="between" css={{ marginTop: '$8' }}>
          <Button onClick={addMapping} type="button">
            <PlusCircledIcon />
            Add Mapping
          </Button>
          <div>
            {!!data && (
              <Button
                color="danger"
                mode="outline"
                css={{ mr: '$4' }}
                disabled={
                  deleteInvoiceConfig.isLoading || updateInvoiceConfig.isLoading
                }
                onClick={() => deleteInvoiceConfig.mutate(data.id)}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              mode="filled"
              disabled={
                updateInvoiceConfig.isLoading ||
                createInvoiceConfig.isLoading ||
                deleteInvoiceConfig.isLoading
              }
            >
              Save
            </Button>
          </div>
        </Flex>
      </form>
      {newFundingSource && (
        <CardInset>
          <CardInsetHeading>Create Funding Source</CardInsetHeading>
          <AddFundingSourceForm onSuccess={onCreateFundingSource} />
        </CardInset>
      )}
    </>
  )
}
