import { FC } from 'react'
import { Badge, BadgeTypes } from '@/common/badge'
import { STATUS_TYPES } from './lib/types'

interface Props {
  statusType: STATUS_TYPES
}

export const StatusCell: FC<Props> = ({ statusType }) => {
  let color: BadgeTypes = 'danger'
  let text = 'Failed'

  if (statusType === STATUS_TYPES.SUCCEEDED) {
    color = 'success'
    text = 'Succeeded'
  }

  if (statusType === STATUS_TYPES.REVIEW) {
    color = 'warning'
    text = 'Review'
  }

  return <Badge type={color}>{text}</Badge>
}
