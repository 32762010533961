import { useMemo } from 'react'
import { useInvoiceConfigs } from '@/queries/invoice-configs'

export const useFundingSourceConfig = (fundingSourceId: number) => {
  const { data, isLoading } = useInvoiceConfigs()

  const config = useMemo(
    () => (data || []).find(rec => rec.funding_source_id === fundingSourceId),
    [data, fundingSourceId]
  )

  return { isLoading, config }
}

export const useFundingSourceMappings = () => {
  const { data } = useInvoiceConfigs()
  return useMemo(() => (data || []).map(rec => rec.mappings).flat(), [data])
}
