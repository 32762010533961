import { saveAs } from 'file-saver'
import { sendAnalyticsEvent } from '@/lib/analytics'

const templateCsvContent =
  'Storenum,Store,First Name,Last Name,Address,Address 2,City,State,Zip\n1,Branch,General,Manager,123 Street Ave,,New York,NY,12345'

export const downloadBulkInstantCardOrderTemplate = () => {
  sendAnalyticsEvent('instant_cards', 'download-template')
  const blob = new Blob([templateCsvContent], {
    type: 'text/plain;charset=utf-8',
  })
  saveAs(blob, 'branch-bulk-instant-card-order-template.csv')
}
