import { useEffect, useState } from 'react'
import parseCSV from '@/lib/parseCSV'

const useParsedCsv = (
  files: File[]
): {
  loading: boolean
  err: Error | null
  wrongFileType: boolean
  csv: string[][]
} => {
  const [loading, setLoading] = useState(true)
  const [err, setErr] = useState<Error | null>(null)
  const [wrongFileType, setWrongFileType] = useState(false)
  const [csv, setCsv] = useState<string[][]>([])

  useEffect(() => {
    if (files.length <= 0) return
    setWrongFileType(false)
    setLoading(true)

    const file = files[0]
    const isSupported = file.name.endsWith(`.csv`)
    if (!isSupported) {
      setWrongFileType(true)
      setLoading(false)
      setErr(new Error('Wrong file type'))
      return
    }

    const parse = async () => {
      try {
        const result = await parseCSV(file)
        setCsv(result)
      } catch (err) {
        setErr(err)
      } finally {
        setLoading(false)
      }
    }

    parse()
  }, [files])

  return {
    loading,
    err,
    wrongFileType,
    csv,
  }
}

export default useParsedCsv
