export type CurrentStep = 'UPLOAD' | 'MATCH' | 'PROCESS'

export type Step = {
  id: CurrentStep
  label: string
}

export const STEP_IDS: { [key in CurrentStep]: CurrentStep } = {
  UPLOAD: 'UPLOAD',
  MATCH: 'MATCH',
  PROCESS: 'PROCESS',
}

export const STEPS: Step[] = [
  { id: STEP_IDS.UPLOAD, label: 'Upload' },
  {
    id: STEP_IDS.MATCH,
    label: 'Match',
  },
  { id: STEP_IDS.PROCESS, label: 'Process' },
]
