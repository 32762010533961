import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { styled } from '@/styles/stitches.config'

const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$8',
})

const StyledItem = styled(ToggleGroupPrimitive.Item, {
  all: 'unset',
  padding: '$4 $8',
  backgroundColor: 'white',
  border: '1px solid $secondary700',
  borderRadius: '$sm',
  color: '$secondary300',
  display: 'flex',
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'capitalize',

  '&:hover': {
    backgroundColor: '$secondary900',
  },

  '&[data-state=on]': {
    backgroundColor: '$primary900',
    borderColor: '$primary700',
    color: '$primary500',
  },

  '&:focus': {
    position: 'relative',
    boxShadow: '$focus',
  },
})

// Exports
export const ToggleGroupContainer = StyledToggleGroup
export const ToggleGroupItem = StyledItem
