import { FC } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Button } from '@/common/button'
import { TableContainer } from '@/common/table/styles'
import {
  Step,
  StepBody,
  StepFooter,
  StepFooterLeft,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'

interface Props {
  handleSubmit: () => void
  csvData: string[][]
  title: string
  handleClose: () => void
  transition?: string
}

export const CsvConfirmStep: FC<Props> = ({
  csvData,
  handleSubmit,
  title,
  transition,
  handleClose,
}) => (
  <Step state={transition}>
    <StepBody>
      <StepTitle>{title}</StepTitle>
      <Card>
        <CardBody>
          <TableContainer>
            <table>
              <thead>
                <tr>
                  {csvData[0].map(h => (
                    <th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.slice(1).map((col, i) => (
                  <tr key={i}>
                    {col.map(c => (
                      <td key={c}>{c}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </CardBody>
      </Card>
    </StepBody>
    <StepFooter>
      <StepFooterLeft>
        <Button mode="outline" onClick={handleClose}>
          Cancel
        </Button>
      </StepFooterLeft>
      <StepFooterRight>
        <Button color="tertiary" mode="filled" onClick={handleSubmit}>
          Submit
        </Button>
      </StepFooterRight>
    </StepFooter>
  </Step>
)
