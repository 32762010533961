import { FC } from 'react'
import { PayoutRecord } from '@/types/payouts'
import { StatusBadge } from './StatusBadge'

interface Props {
  original: PayoutRecord
}

export const StatusCell: FC<Props> = ({ original }) => (
  <StatusBadge payout={original} showTooltip />
)
