import { useCallback, useRef, useState } from 'react'
import { SearchDropdownContainer } from '@/components/search-dropdown-field'
import useEscapeClickHandler from '@/src/hooks/useEscapeClickHandler'
import useOutsideClickHander from '@/src/hooks/useOutsideClickHandler'
import { EmployeeList } from './EmployeeList'
import { EmployeeSelectField } from './EmployeeSelectField'
import { ISelectableEmployee } from './types'

interface Props {
  handleChange: (employee: ISelectableEmployee) => void
}

export const EmployeeSelect: React.FC<Props> = ({ handleChange }) => {
  const [selectedEmployee, setSelectedEmployee] = useState<
    ISelectableEmployee
  >()
  const [open, setOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const hide = useCallback(() => {
    if (open) setOpen(false)
  }, [open, setOpen])

  useEscapeClickHandler(hide)
  useOutsideClickHander(ref, hide, open)

  const handleSelect = (emp: ISelectableEmployee) => {
    handleChange(emp)
    setSelectedEmployee(emp)
    setOpen(false)
  }

  return (
    <SearchDropdownContainer ref={ref}>
      <EmployeeSelectField
        employee={selectedEmployee}
        handleClick={() => setOpen(true)}
      />
      {open && (
        <EmployeeList handleSelect={handleSelect} selected={selectedEmployee} />
      )}
    </SearchDropdownContainer>
  )
}
