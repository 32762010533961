import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Text } from '@/common/text'
import { useUpdateLocation } from '@/queries/location'
import { ILocationFormValues } from '@/types/location'
import { useCurrentOrg } from '../auth/hooks/useCurrentOrg'
import { IReviewRow } from './lib/types'

const schema = yup.object().shape({
  address: yup.string().required(),
  alternate_name: yup.string(),
  name: yup.string().required(),
  organization_id: yup.string(),
  primary_ns: yup.string(),
  secondary_ns: yup.string(),
})
interface Props {
  reviewRow: IReviewRow
  secondaryNs: string
}

export const UpdateLocationForm: FC<Props> = ({ reviewRow, secondaryNs }) => {
  const location = reviewRow.existingLocation
  const duplicateId = reviewRow.duplicateId as string
  const updateLocation = useUpdateLocation()
  const currentOrg = useCurrentOrg()

  const defaultValues = useMemo<ILocationFormValues>(
    () => ({
      address: location?.address || '',
      alternate_name: location?.alternate_name || '',
      name: location?.location_name || '',
      organization_id: `${currentOrg?.id}` || '',
      primary_ns: currentOrg?.org_key || '',
      secondary_ns: secondaryNs,
    }),
    [currentOrg, location, secondaryNs]
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILocationFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = (values: ILocationFormValues) => {
    updateLocation.mutate({
      duplicateId,
      config: {
        address: values.address,
        alternate_name: values.alternate_name,
        name: values.name,
        organization_id: !!values.organization_id
          ? values.organization_id
          : undefined,
        primary_ns: !!values.primary_ns ? values.primary_ns : undefined,
        secondary_ns: !!values.secondary_ns ? values.secondary_ns : undefined,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexWrap size="lg">
        <FlexWrap size="lg" css={{ flexGrow: 1 }}>
          <Flex direction="column" align="start" css={{ flexGrow: 1 }}>
            <Label htmlFor="address">Address</Label>
            <Input type="text" {...register('address')} css={{ mt: '$4' }} />
            <Text color="alert" size="sm">
              {errors?.address?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="start" css={{ flexGrow: 1 }}>
            <Label htmlFor="name">Location Name</Label>
            <Input type="text" {...register('name')} css={{ mt: '$4' }} />
            <Text color="alert" size="sm">
              {errors?.name?.message}
            </Text>
          </Flex>
        </FlexWrap>

        <FlexWrap size="lg" css={{ flexGrow: 1 }}>
          <Flex direction="column" align="start" css={{ flexGrow: 1 }}>
            <Label htmlFor="alternate_name">Altername Name</Label>
            <Input
              type="text"
              {...register('alternate_name')}
              css={{ mt: '$4' }}
            />
            <Text color="alert" size="sm">
              {errors?.alternate_name?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="start" css={{ flexGrow: 1 }}>
            <Label htmlFor="organization_id">Organization ID</Label>
            <Input
              type="text"
              {...register('organization_id')}
              css={{ mt: '$4' }}
            />
            <Text color="alert" size="sm">
              {errors?.organization_id?.message}
            </Text>
          </Flex>
        </FlexWrap>

        <FlexWrap size="lg" css={{ flexGrow: 1 }}>
          <Flex direction="column" align="start" css={{ flexGrow: 1 }}>
            <Label htmlFor="primary_ns">Primary NS</Label>
            <Input type="text" {...register('primary_ns')} css={{ mt: '$4' }} />
            <Text color="alert" size="sm">
              {errors?.primary_ns?.message}
            </Text>
          </Flex>
          <Flex direction="column" align="start" css={{ flexGrow: 1 }}>
            <Label htmlFor="secondary_ns">Secondary NS</Label>
            <Input
              type="text"
              {...register('secondary_ns')}
              css={{ mt: '$4' }}
            />
            <Text color="alert" size="sm">
              {errors?.secondary_ns?.message}
            </Text>
          </Flex>
        </FlexWrap>
      </FlexWrap>
      <Button
        type="submit"
        mode="outline"
        css={{
          mt: '$12',
        }}
        loading={updateLocation.isLoading}
      >
        Save
      </Button>
    </form>
  )
}
