import { FC } from 'react'
import { Button } from '@/components/common/button'

interface Props {
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const DetailButton: FC<Props> = ({ children, handleClick }) => {
  return (
    <Button mode="flat" onClick={handleClick}>
      {children}
    </Button>
  )
}
