export const RegExNumWith2SigFigs = /^[0-9]+\.?[0-9]?[0-9]?$/

// eslint-disable-next-line
export const RegExEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

export const YearMonthDayRegex = /^\d{4}-\d{2}-\d{2}$/

export const RegexPhone = /^(1-)?\d{3}-\d{3}-\d{4}$/

// +1 (999) 999-9999 format
export const RegexAltPhone = /^((\+\d+\s))\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/

export const Regex24HrClockTimeRange = /^((?:[01]\d|2[0-3])(?::?[0-5]\d)?-(?:[01]\d|2[0-3])(?::?[0-5]\d)|null)$/

// case instensitive po box regex
export const RegexPOBox = /\b(?:P(?:ost(?:al)?|[.])?\s*(?:O(?:ffice)?|[.])?\s*)?Box\b/i
