import { useState } from 'react'
import { useQueryClient } from 'react-query'
import {
  createPartnerOrganizationMapping,
  deletePartnerOrganizationMapping,
} from '@/api/partners'
import { Alert } from '@/common/alert'
import { IconButton } from '@/common/button'
import { Card } from '@/common/card'
import { AddIcon, TrashIcon } from '@/common/icons'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Box, Flex } from '@/common/layout'
import { Loader } from '@/common/loader'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import {
  PARTNER_MAPPINGS_QUERY,
  usePartnerMappingsQuery,
} from '@/src/queries/integrations'
import { useToasterStore } from '@/store/toast'

type Props = {
  partnerId: number
}

export const RasiIntegration: React.FC<Props> = ({ partnerId }) => {
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()
  const { data, isLoading } = usePartnerMappingsQuery(partnerId)
  const { addToast } = useToasterStore()
  const [value, setValue] = useState('')

  const addMapping = async () => {
    try {
      await createPartnerOrganizationMapping({
        orgId,
        partnerId,
        referenceId: value,
      })
      queryClient.invalidateQueries(PARTNER_MAPPINGS_QUERY)
    } catch (e) {
      addToast({
        type: 'error',
        title: 'An error occurred',
        description: e.response.data.errorDetail,
      })
    }
  }

  const removeMapping = async (id: number) => {
    await deletePartnerOrganizationMapping({
      partnerId,
      id,
    })
    queryClient.invalidateQueries(PARTNER_MAPPINGS_QUERY)
  }

  return (
    <>
      <Alert type="success">
        <strong>RASI</strong> integration is active.
      </Alert>
      <Card css={{ mt: '$24' }}>
        <Text as="h5" css={{ mb: '$12' }}>
          Reference Mappings
        </Text>
        <div>
          <Label>New mapping</Label>
          <Flex css={{ mb: '$12' }}>
            <Input
              type="text"
              value={value}
              onChange={e => setValue(e.target.value)}
              css={{ mr: '$8' }}
            />
            <IconButton onClick={addMapping}>
              <AddIcon />
            </IconButton>
          </Flex>
          <Loader loading={isLoading}>
            <Box css={{ mb: '$12' }}>Existing References:</Box>
            {!data || data.length === 0 ? (
              <Text css={{ fontStyle: 'italic' }}>
                No references exist yet.
              </Text>
            ) : (
              data.map(mapping => (
                <Flex align="center">
                  <IconButton onClick={() => removeMapping(mapping.id)}>
                    <TrashIcon />
                  </IconButton>
                  <Text as="h6">{mapping.reference_id}</Text>
                </Flex>
              ))
            )}
          </Loader>
        </div>
      </Card>
    </>
  )
}
