import moment from 'moment'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { DateRangePicker } from '@/common/datepicker'
import { DateRangePreset } from '@/common/datepicker/types'
import { toIsoString } from '@/common/datepicker/utils'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { Select } from '@/common/select'
import { TableContainer } from '@/common/table/styles'
import { Text } from '@/common/text'
import { toDollar } from '@/lib/currency'
import { useGroupsQuery } from '@/queries/organizations'
import { useDisbursementSummary } from '@/queries/payouts'
import { useDisbursementsStore } from '@/store/disbursements'
import { useModalStore } from '@/store/modal'
import { Group } from '@/types/organization'

export type ViewSummaryModalFormValues = {
  time_last_attempted_start: moment.Moment
  time_last_attempted_end: moment.Moment
  preset?: DateRangePreset
  external_store_id?: string
  preSelectStoreId: string
}

export const ViewSummaryModal = () => {
  const [open, setOpen] = useState(true)
  const { removeModal } = useModalStore()
  const { filters } = useDisbursementsStore()

  const groupsQuery = useGroupsQuery()

  const storeOptions = useMemo(() => {
    const data = (groupsQuery.data || []).map((group: Group) => ({
      label: group.name,
      value: group.name,
    }))
    if (data.length > 0) {
      data.push({ label: 'Manual Search', value: 'manual-search' })
    }
    return data
  }, [groupsQuery.data])

  const defaultValues = {
    time_last_attempted_start: filters?.from || moment().startOf('day'),
    time_last_attempted_end: filters?.to || moment().endOf('day'),
    preset: filters?.from && filters?.to ? filters.preset : undefined,
    external_store_id: '',
    preSelectStoreId: '',
  }

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  })

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const disbursementSummary = useDisbursementSummary()

  const timeLastAttempedStart = watch('time_last_attempted_start')

  const timeLastAttempedEnd = watch('time_last_attempted_end')

  const preset = watch('preset')

  const handleGenerateSummary = (values: ViewSummaryModalFormValues) => {
    const params = {
      time_last_attempted_start: toIsoString(
        values.time_last_attempted_start.toDate()
      ),
      time_last_attempted_end: toIsoString(
        values.time_last_attempted_end.toDate()
      ),
      external_store_id: values.external_store_id || undefined,
    }

    disbursementSummary.mutate(params)
  }

  const handleStoreIdSelect = (value: string) => {
    if (value === 'manual-search') {
      setValue('external_store_id', '')
    } else {
      setValue('external_store_id', value)
    }
    setValue('preSelectStoreId', value)
  }

  const handleEnterStoreId = (value: string) => {
    setValue('preSelectStoreId', 'manual-search')
    setValue('external_store_id', value)
  }

  return (
    <Dialog open={open} setOpen={setOpen} title="View Disbursement Summary">
      <form onSubmit={handleSubmit(handleGenerateSummary)}>
        <FlexGrid fullWidth direction="column">
          <div>
            <Label>Date Range</Label>
            <DateRangePicker
              from={timeLastAttempedStart}
              to={timeLastAttempedEnd}
              preset={preset}
              setRange={values => {
                setValue('time_last_attempted_start', values.from)
                setValue('time_last_attempted_end', values.to)
                setValue('preset', values.preset)
              }}
              allowedPresets={[
                DateRangePreset.LAST_WEEK,
                DateRangePreset.LAST_MONTH,
                DateRangePreset.LAST_THREE_MONTHS,
              ]}
            />
            <Text color="hint">Maximum 3 month range</Text>
          </div>
          <div>
            {storeOptions.length > 0 && (
              <>
                <Label>Select a Store</Label>
                <Select
                  value={watch('preSelectStoreId')}
                  onChange={handleStoreIdSelect}
                  options={storeOptions}
                />
                <Text color="hint">
                  You can select a store here, or type one below
                </Text>
              </>
            )}
          </div>
          <div>
            <Label>Store</Label>
            <Input
              placeholder="Store ID"
              {...register('external_store_id')}
              onChange={e => handleEnterStoreId(e.target.value)}
            />
          </div>
        </FlexGrid>
        <Flex justify={'end'} css={{ mt: '$24' }}>
          <Button
            mode="filled"
            type="submit"
            disabled={disbursementSummary.isLoading}
            loading={disbursementSummary.isLoading}
          >
            Generate Summary
          </Button>
        </Flex>
      </form>
      <TableContainer css={{ mt: '$24' }}>
        <table>
          <thead>
            <tr>
              <th>Total Number of Disbursements</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {disbursementSummary.data &&
                  disbursementSummary.data?.total_number_of_disbursements}
              </td>
              <td>
                {disbursementSummary.data &&
                  toDollar(disbursementSummary.data?.total_amount)}
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button mode="flat" onClick={close}>
          Close
        </Button>
      </Flex>
    </Dialog>
  )
}
