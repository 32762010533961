import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { InstantCardGroupRow } from './columns'

const downloadCSV = (orders: InstantCardGroupRow[]) => {
  const csvContent = Papa.unparse(orders)
  sendAnalyticsEvent('instant_cards', 'download', { count: orders.length })

  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'branch_instant_card_orders.csv')
}

export default downloadCSV
