import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { keyframes, styled } from '@/styles/stitches.config'

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
})

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
})

export const Root = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  marginBottom: '1rem',
  border: '1px solid rgba(0,0,0,0.2)',
})

export const Item = styled(AccordionPrimitive.Item, {
  marginTop: 1,

  '&:first-child': {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  '&:last-child': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  '&:focus-within': {
    position: 'relative',
    zIndex: 1,
  },
})

export const Header = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
})

export const Trigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  fontSize: 15,
  fontWeight: 'bold',
  lineHeight: 1,
  backgroundColor: 'transparent',

  width: '100%',
  padding: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:focus': {
    outline: 'none',
  },

  '&[data-state="open"]': {
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
})

export const Content = styled(AccordionPrimitive.Content, {
  overflow: 'auto',
  minHeight: 'auto',
  padding: '1rem',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
})

export const Arrow = styled(ChevronDownIcon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },
})
