import { AdditionalFilterItem } from '@/common/filters/types'
import { InitiationStatus } from '@/types/create-accounts'
import { STATUS_LABELS } from './utils'

export const statusOptions: { value: InitiationStatus; label: string }[] = [
  { value: 'FAILED', label: STATUS_LABELS.FAILED },
  { value: 'LINKED', label: STATUS_LABELS.LINKED },
  { value: 'PENDING', label: STATUS_LABELS.PENDING },
  { value: 'PENDING_MANUAL', label: STATUS_LABELS.PENDING_MANUAL },
  { value: 'UNLINKED', label: STATUS_LABELS.UNLINKED },
  { value: 'PENDING_REVIEW', label: STATUS_LABELS.PENDING_REVIEW },
]

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'name', label: 'Name' },
  { id: 'batch_id', label: 'Batch ID' },
  { id: 'kyc_inquiry_reference_id', label: 'KYC Ref ID' },
  { id: 'account_token', label: 'Account Token' },
]
