import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { AdditionalFilterItem } from '@/common/filters/types'
import { useRemittances } from '@/store/remittances'
import { useDownloadAllRemittances } from '../lib/downloadRemittancesCSV'

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'payback_id', label: 'Invoice ID' },
]

export const DeductionReportFilters: FC = () => {
  const { filters, updateFilters } = useRemittances()
  const { download, loading } = useDownloadAllRemittances({
    filtered: filters,
  })

  return (
    <TableFiltersMain
      filters={filters}
      updateFilters={updateFilters}
      additionalFilters={additionalFilters}
      downloadLoading={loading}
      handleDownload={download}
      hasDateRange={false}
    />
  )
}
