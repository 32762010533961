import { IntegrationContainer } from './components/IntegrationContainer'
import { IntegrationResolver } from './components/IntegrationResolver'

export const IntegrationSettings = () => {
  return (
    <IntegrationContainer>
      <IntegrationResolver />
    </IntegrationContainer>
  )
}
