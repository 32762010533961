import qs from 'qs'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'
import { IRemittance } from '@/types/remittances'

interface Props {
  original: IRemittance
}

export const ViewInvoiceLink: FC<Props> = ({ original: { payback_id } }) => {
  const invoicesPath = useOrgPath(ROUTES.INVOICES)
  const params = qs.stringify({
    filter_id: payback_id,
  })

  return <Link to={`${invoicesPath}?${params}`}>{payback_id}</Link>
}
