import moment from 'moment'
import { FC, useEffect, useMemo } from 'react'
import { useQueryParamFilters } from '@/common/filters/useQueryParamFilters'
import { useUpdateQueryParamFilters } from '@/common/filters/useUpdateQueryParamFilters'
import { Box } from '@/common/layout'
import { Table } from '@/common/table'
import { useDirectDepositFormsQuery } from '@/queries/direct-deposits'
import { useDirectDepositStore } from '@/store/direct-deposit'
import { DirectDepositsContainer } from './components/DirectDepositsContainer'
import { DirectDepositsFilters } from './components/DirectDepositsFilters'
import { columns } from './lib/columns'

const initialSortedState = [{ id: 'date', desc: false }]

export const DirectDeposits: FC = () => {
  const {
    filters,
    computed: { filterArray },
    page,
    updateFilters,
    updatePage,
    isReady,
    sorted,
    pageSize,
  } = useDirectDepositStore()
  const ddData = useDirectDepositFormsQuery()

  // Update query params when filters change
  useUpdateQueryParamFilters(filters)

  // Retrieve query params and update filters on initial load
  const queryParams = useQueryParamFilters(filters)
  useEffect(() => {
    updateFilters(queryParams)
    isReady()
  }, [queryParams, updateFilters, isReady])

  const filteredData = useMemo(() => {
    if (filters.from && filters.to && ddData.data) {
      return ddData.data.filter(
        dd =>
          moment(dd.direct_deposit_form.time_created) >= filters.from &&
          moment(dd.direct_deposit_form.time_created) <= filters.to
      )
    }
    return ddData.data || []
  }, [filters.to, filters.from, ddData])

  return (
    <DirectDepositsContainer
      error={ddData.error as Error}
      filteredData={filteredData}
    >
      <Box css={{ mb: '$12' }}>
        <DirectDepositsFilters filteredData={filteredData} />
      </Box>
      <Table
        columns={columns}
        data={filteredData}
        controlledPagination={false}
        columnFiltersState={filterArray}
        initialSortedState={initialSortedState}
        sorted={sorted}
        page={page}
        changePage={updatePage}
        pageRows={pageSize}
      />
    </DirectDepositsContainer>
  )
}
