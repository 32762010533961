import { FC } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './auth.module.scss'

type Props = {
  key: string
  in: boolean
}

const LoginTransition: FC<Props> = props => {
  return (
    <CSSTransition
      in={props.in}
      timeout={300}
      unmountOnExit={true}
      className={styles.TransitionItem}
    >
      <div>{props.children}</div>
    </CSSTransition>
  )
}

export default LoginTransition
