import { SortingState } from '@tanstack/react-table'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getJobs, getOrgJobs, retryJob } from '@/api/jobs'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useFileProcessingSettings } from '@/store/file-processing-settings'
import { useToasterStore } from '@/store/toast'

interface JobsQueryConfig {
  page: number
  pageSize: number
}

export const GET_JOBS_QUERY = 'get-jobs'

export const useJobsQuery = (config: JobsQueryConfig) => {
  const { id: orgId } = useCurrentOrg()

  const {
    page,
    pageSize,

    sorted,
  } = useFileProcessingSettings()

  const params = {
    page: page + 1,
    size: pageSize,
    org_id: orgId,
    sort: sorted[0]?.id,
    direction: sorted[0]?.desc ? 'desc' : 'asc',
  }

  return useQuery([GET_JOBS_QUERY, orgId, params], async () => {
    const { data } = await getJobs(params)
    return data
  })
}

export const ORG_JOBS_QUERY = 'org-jobs'

interface OrgsJobsQueryConfig extends JobsQueryConfig {
  filters: TableFilters
  sorted: SortingState
}

export const useOrgJobsQuery = (config: OrgsJobsQueryConfig) => {
  const { id: orgId } = useCurrentOrg()
  const { page, pageSize, sorted, filters } = config
  const { from, to, ...restFilters } = filters

  const params = {
    time_completed_start: from?.format(),
    time_completed_end: to?.format(),
    page: page + 1,
    size: pageSize,
    sort: sorted[0].id,
    direction: sorted[0].desc ? 'desc' : 'asc',
    ...restFilters,
  }

  return useQuery([ORG_JOBS_QUERY, orgId, params], async () => {
    const res = await getOrgJobs(orgId, params)
    return res.data
  })
}

export const useRetryJob = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    (config: { id: number }) => {
      return retryJob(config.id)
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent('file_processing', 'retry-job:success', payload)
        addToast({
          type: 'success',
          title: 'Job successfully retried',
        })
        queryClient.invalidateQueries(GET_JOBS_QUERY)
      },
      onError: (_, payload) => {
        sendAnalyticsEvent('file_processing', 'retry-job:failure', payload)
        addToast({
          type: 'error',
          title: 'Failed to run job',
        })
      },
    }
  )
}
