import { AmountFilter } from '@/common/filters/AmountFilter'
import { AdditionalFilterItem } from '@/common/filters/types'
import { toDollar } from '@/lib/currency'
import { PayoutStatus } from '@/types/payouts'

export const payoutStatusOptions: { label: string; value: PayoutStatus }[] = [
  { value: PayoutStatus['ATTEMPTING PAYBACK'], label: 'Attempting Invoice' },
  { value: PayoutStatus['CANCELLED'], label: 'Cancelled' },
  { value: PayoutStatus['COMPLETED'], label: 'Completed' },
  { value: PayoutStatus['CREATED'], label: 'Created' },
  { value: PayoutStatus['FAILED'], label: 'Failed' },
  { value: PayoutStatus['PAYBACK_FAILED'], label: 'Invoice Failed' },
  { value: PayoutStatus['SCHEDULED'], label: 'Scheduled' },
]

export const payoutTypeOptions = [
  { value: 'Tips', label: 'Tips' },
  { value: 'Mileage', label: 'Mileage' },
  { value: 'Paycheck', label: 'Paycheck' },
  { value: 'Miscellaneous', label: 'Miscellaneous' },
  { value: 'Device', label: 'Device' },
  { value: 'Cashback', label: 'Cashback' },
  { value: 'Delivery', label: 'Delivery' },
]

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'payback_id', label: 'Invoice ID' },
  { id: 'external_store_id', label: 'Store ID' },
  { id: 'metadata.shift_timestamp', label: 'Shift Timestamp' },
  { id: 'employee_id', label: 'Worker ID' },
  {
    id: 'amount',
    label: 'Amount',
    formatValue: val => toDollar(val) || 'None',
    renderInput: props => <AmountFilter {...props} />,
  },
  { id: 'external_id', label: 'Trans. ID' },
]
