const SvgSignups = (props: React.SVGAttributes<SVGElement>) => (
  <svg width={48} height={49} {...props}>
    <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
      <circle fill="#5A2A3C54" cx={24} cy={24} r={24} />
      <path
        d="M13.5 18h12a.75.75 0 1 1 0 1.5h-12v12.75c0 .414.336.75.75.75h18a.75.75 0 0 0 .75-.75v-9a.75.75 0 1 1 1.5 0v9a2.25 2.25 0 0 1-2.25 2.25h-18A2.25 2.25 0 0 1 12 32.25v-16.5a2.25 2.25 0 0 1 2.25-2.25h16.5a.75.75 0 1 1 0 1.5h-16.5a.75.75 0 0 0-.75.75V18zm21.817.995l-5.724 5.723a.75.75 0 0 1-.383.205l-2.813.562a.75.75 0 0 1-.882-.882l.563-2.812a.75.75 0 0 1 .205-.383l5.722-5.723A2.335 2.335 0 0 1 33.657 15c.62 0 1.215.246 1.61.646l.05.045a2.338 2.338 0 0 1 0 3.304zm-1.061-1.06c.326-.327.326-.857.042-1.145l-.05-.045a.835.835 0 0 0-1.182 0l-5.562 5.563-.298 1.486 1.487-.297 5.563-5.562zM17.25 25.5a.75.75 0 1 1 0-1.5h4.5a.75.75 0 1 1 0 1.5h-4.5zm0 4.5a.75.75 0 1 1 0-1.5H27a.75.75 0 1 1 0 1.5h-9.75z"
        fill="#5A2A3C"
      />
    </g>
  </svg>
)

export default SvgSignups
