import { FC, useMemo } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { FlexWrap } from '@/common/layout'
import { Loader } from '@/common/loader'
import { Text } from '@/common/text'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { OrganizationDropdown } from '@/components/organizations/OrganizationDropdown'
import { usePartnerRosterSettings } from '@/hooks/usePartnerRoster'
import { useUserOrganizationsQuery } from '@/queries/auth'
import { useUpdatePartnerMutation } from '@/queries/settings'
import { IOrganization } from '@/types/organization'
import { SectionTitle } from './styles'

export const SharedPartner: FC = () => {
  const currentOrg = useCurrentOrg()
  const partnerRosterSettings = usePartnerRosterSettings()
  const updatePartnerOrg = useUpdatePartnerMutation()
  const orgs = useUserOrganizationsQuery()

  const associateOrg = (organization: IOrganization) => {
    if (!currentOrg.partner_id) {
      return
    }

    updatePartnerOrg.mutate({
      partnerId: currentOrg.partner_id.toString(),
      settings: { parent_org_id: organization.id.toString() },
    })
  }

  const removeOrgAssociation = () => {
    if (!currentOrg.partner_id) {
      return
    }
    updatePartnerOrg.mutate({
      partnerId: currentOrg.partner_id.toString(),
      settings: { parent_org_id: '' },
    })
  }

  const sharedPartnerOrg = useMemo(() => {
    if (!partnerRosterSettings.parent_org_id || !orgs.data?.length) return null
    return orgs.data.find(
      (rec: IOrganization) =>
        rec.id.toString() === partnerRosterSettings.parent_org_id
    )
  }, [partnerRosterSettings, orgs])

  if (!currentOrg.partner_id) {
    return null
  }

  return (
    <Card>
      <FlexWrap size="lg">
        <SectionTitle>Shared Partner Roster Organization</SectionTitle>
        <Text>
          By selecting a Shared Partner Roster Organization, every organization
          with a partner ID of {currentOrg.partner_id} will use the roster of
          the selected organization.
        </Text>
        {!sharedPartnerOrg ? (
          <Alert type="info" css={{ width: '100%' }}>
            To enable Shared Partner Roster, select an organization below.
          </Alert>
        ) : (
          <Alert type="warning" css={{ width: '100%' }}>
            <FlexWrap size="lg">
              <Text>Partner Org:</Text>
              <Text bold>{sharedPartnerOrg.name}</Text>
            </FlexWrap>
          </Alert>
        )}
        {!sharedPartnerOrg && (
          <Loader loading={updatePartnerOrg.isLoading}>
            <OrganizationDropdown
              isGlobalAdmin
              onSelect={() => null}
              excludeCurrentOrg
            />
          </Loader>
        )}
      </FlexWrap>
    </Card>
  )
}
