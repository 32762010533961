import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@/router/routes'
import useOrgPath from '@/router/useOrgPath'

interface Props {
  value: number
}

export const ViewOrderCell: FC<Props> = ({ value }) => {
  const path = useOrgPath(ROUTES.INSTANT_CARD_ORDERS)
  return <Link to={`${path}?filter_id=${value}`}>{value}</Link>
}
