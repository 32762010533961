import classnames from 'classnames'
import { FC } from 'react'
import styles from './index.module.scss'

type Props = {
  state?: string
}

const Step: FC<Props> = props => {
  const { state } = props
  const classes = classnames(styles.Step, styles[`Step_${state}`])
  return <div className={classes}>{props.children}</div>
}

export default Step
