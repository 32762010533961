import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Label } from '@/common/label'
import { Flex, FlexWrap } from '@/common/layout'
import { Select } from '@/common/select/Select'
import { useUpdateSettingsMutation } from '@/queries/settings'
import { useSettingsStore } from '@/store/settings'
import { OrgSettings } from '@/types/organization'
import { HelperText, SectionTitle } from './styles'

interface GeneralSetupFormValues {
  ONBOARDING_FLOW_CONFIG: string
}

const ONBOARDING_FLOW_CONFIG_OPTIONS = [
  { label: 'LIMITED', value: 'LIMITED' },
  { label: 'PREKYC', value: 'PREKYC' },
  { label: 'V1.01', value: 'V1.01' },
]

const resetForm = (settings: OrgSettings) => ({
  ONBOARDING_FLOW_CONFIG: settings.ONBOARDING_FLOW_CONFIG,
})

export const OnboardingFlowConfig: FC = () => {
  const settings = useSettingsStore(state => state.settings)
  const updateSettings = useUpdateSettingsMutation()
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: resetForm(settings),
  })

  const onSubmitForm = (values: GeneralSetupFormValues) =>
    updateSettings.mutate(values)

  useEffect(() => {
    reset(resetForm(settings))
  }, [settings, reset])

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <SectionTitle>Create Accounts: Onboarding Flow</SectionTitle>
        <FlexWrap direction="column" align="start" size="lg">
          <Label>Onboarding Flow Config</Label>
          <FlexWrap
            direction="column"
            align="start"
            size="sm"
            css={{ width: 300 }}
          >
            <Select
              options={ONBOARDING_FLOW_CONFIG_OPTIONS}
              value={watch('ONBOARDING_FLOW_CONFIG')}
              onChange={val =>
                setValue('ONBOARDING_FLOW_CONFIG', val, { shouldDirty: true })
              }
              placeholder="Select a value"
            />
          </FlexWrap>
          <HelperText size="sm">
            Defines the onboarding flow used when Creating Wallets via Pay Admin
            or Partner-API. Default is LIMITED
          </HelperText>
        </FlexWrap>
        <Flex>
          <Button
            type="submit"
            mode="filled"
            disabled={updateSettings.isLoading || !isDirty}
            size="md"
            css={{ ml: 'auto' }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Card>
  )
}
