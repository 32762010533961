import { saveAs } from 'file-saver'
import moment from 'moment'
import Papa from 'papaparse'
import { toDollar } from '@/lib/currency'
import { PayoutRecord } from '@/types/payouts'
import { makeMetaDataColumns } from './metadata'

export const downloadCSV = (records: PayoutRecord[]) => {
  const metaDataColumns = makeMetaDataColumns(records)

  const csvData = records.map(record => {
    // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    // PLEASE READ IF CHANGING THIS CODE:
    //
    // Customers have asked us to keep this CSV structure
    // the same because they have Excel jobs configured
    // precisely for this file. Unless you have a good reason,
    // try not to change column names or value formatting,
    // and put new columns at the END of the file.
    //
    let row: { [k: string]: string | number | null } = {}

    row['Created Date'] = moment(record.time_created).format('lll')
    row["Worker's ID"] = record.roster_record.employee_id
    row[
      'Name'
    ] = `${record.roster_record.first_name} ${record.roster_record.last_name}`
    row['Amount'] = toDollar(record.amount)
    row['Type'] = record.type
    row['Invoice ID'] = record.payback_id
    row['External Store ID'] = record.external_store_id

    metaDataColumns.forEach(metaCol => {
      row[metaCol.Header] = record.metadata
        ? record.metadata[metaCol.Header]
        : ''
    })

    row.Status = record.status || ''
    row['Status Reason'] = record.status_reason || 'N/A'
    row['Description'] = record.description || 'N/A'
    row['Transaction ID'] = record.external_id || 'N/A'
    row['Scheduled Date'] = record.time_scheduled
      ? moment(record.time_scheduled).format('lll')
      : 'N/A'
    return row
  })

  const csvContent = Papa.unparse(csvData)

  const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, 'branch_disbursements.csv')
}
