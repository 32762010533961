import { AdvancesTimeSeriesItem } from '@/types/insights'

export interface IAdvancesInsightsData {
  advances: number
  advanced: number
  advancesToDate: number
  advancedToDate: number
  advanceRanges: {
    amount: number
    min: { value: number }
    max: { value: number }
    currencyCode: string
  }[]
  start: { seconds: number }
}

export const parseAdvancesTimeSeriesData = (
  data: AdvancesTimeSeriesItem[]
): IAdvancesInsightsData[] =>
  data.map(d => ({
    advances: d.advances || 0,
    advanced: d.advanced || 0,
    advancesToDate: d.advances_to_date || 0,
    advancedToDate: d.advanced_to_date || 0,
    advanceRanges: d.advance_ranges.map(x => ({
      amount: x.amount || 0,
      min: {
        value: x.min?.value || 0,
      },
      max: {
        value: x.max?.value || 0,
      },
      currencyCode: x.currency_code,
    })),
    start: { ...d.start },
  }))
