import { AdditionalFilterItem } from '@/common/filters/types'

export const statusOptions: { label: string; value: string }[] = [
  { value: 'CLAIMED', label: 'Claimed' },
  { value: 'UNCLAIMED', label: 'Unclaimed' },
]

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'name_on_card', label: 'Name on Card' },
  { id: 'id', label: 'Card ID' },
  { id: 'account_number', label: 'Account #' },
  { id: 'routing_number', label: 'Routing #' },
  { id: 'order_id', label: 'Order ID' },
]
