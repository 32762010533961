import { FC } from 'react'
import { Select } from '../select'
import { RenderInputProps } from './types'

interface Props extends RenderInputProps {
  options: { value?: string; label: string }[]
}

export const MoreFiltersOptionsInput: FC<Props> = ({
  value,
  handleChange,
  options,
}) => <Select value={value} onChange={handleChange} options={options} />
