import { useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { useDateRangeSearchParams } from '@/common/datepicker/useDateRangeSearchParams'
import { useTableFilters } from '@/common/filters/useTableFilters'
import { IconAdvanced, IconAdvancedAllTime, IconAdvances } from '@/common/icons'
import { Loader } from '@/components/common/loader'
import { parseAdvancesTimeSeriesData } from '@/lib/advancesInsights'
import { toDollar } from '@/lib/currency'
import { useTransactionInsightsQuery } from '@/queries/insights'
import { InsightCard } from '../components/InsightCard'
import StatDisplay from '../components/StatDisplay'
import AdvanceDistribution from './components/AdvanceDistribution'
import AdvancedPerDay from './components/AdvancedPerDay'
import AdvancedToDate from './components/AdvancedToDate'
import AdvancesPerDay from './components/AdvancesPerDay'
import AdvancesToDate from './components/AdvancesToDate'
import { PayAnalyticsContainer } from './components/PayAnalyticsContainer'
import useAggregatedRange from './lib/useAggregatedRange'

export const TransactionInsights = () => {
  const dateRange = useDateRangeSearchParams()
  const { filters, updateFilters } = useTableFilters(dateRange)
  const insights = useTransactionInsightsQuery({
    startDate: filters.from,
    endDate: filters.to,
  })

  const report = useMemo(
    () => (insights.data ? parseAdvancesTimeSeriesData(insights.data) : []),
    [insights.data]
  )

  const aggregatedRange = useAggregatedRange(report)

  const advancesInRange = useMemo(() => {
    if (report.length < 1) return null
    const beginning = report[0].advancesToDate
    const end = report[report.length - 1].advancesToDate
    return end - beginning
  }, [report])

  const advancedInRange = useMemo(() => {
    if (report.length < 1) return null
    const beginning = report[0].advancedToDate
    const end = report[report.length - 1].advancedToDate
    return end - beginning
  }, [report])

  const advancedAllTime = useMemo(
    () => (report.length < 1 ? 0 : report[report.length - 1].advancedToDate),
    [report]
  )

  return (
    <PayAnalyticsContainer filters={filters} updateFilters={updateFilters}>
      <Loader loading={insights.isLoading}>
        <Row className="mb-4">
          <Col md={4}>
            <StatDisplay
              stat={advancesInRange}
              label="Advances"
              icon={<IconAdvances />}
              bgColor="#2A515A"
            />
          </Col>
          <Col md={4}>
            <StatDisplay
              stat={toDollar(advancedInRange)}
              label="Advanced"
              icon={<IconAdvanced />}
              bgColor="#5A2A3C"
            />
          </Col>
          <Col md={4}>
            <StatDisplay
              stat={toDollar(advancedAllTime)}
              label="Advanced To Date"
              icon={<IconAdvancedAllTime />}
              bgColor="#5A3E2A"
            />
          </Col>
        </Row>

        <InsightCard>
          <AdvancesToDate data={report} />
        </InsightCard>

        <Row>
          <Col md={6}>
            <InsightCard>
              <AdvancesPerDay data={report} />
            </InsightCard>
          </Col>
          <Col md={6}>
            <InsightCard>
              <AdvanceDistribution data={aggregatedRange} />
            </InsightCard>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InsightCard>
              <AdvancedToDate data={report} />
            </InsightCard>
          </Col>
          <Col md={6}>
            <InsightCard>
              <AdvancedPerDay data={report} />
            </InsightCard>
          </Col>
        </Row>
      </Loader>
    </PayAnalyticsContainer>
  )
}
