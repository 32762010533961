import {
  CreateSSOConfig,
  CreateSSOMapping,
  SSOConfig,
  SSOMappingsResponse,
  SSOResponse,
} from '@/api/types/sso'
import http from './http'

export const getSSOconfig = (orgId: number) =>
  http.get<SSOResponse>(`p1/organizations/${orgId}/single_sign_on`)

export const getSSOmappings = (orgId: number) =>
  http.get<SSOMappingsResponse>(
    `p1/organizations/${orgId}/single_sign_on/mappings`
  )

export const getSSOmapping = (orgId: number, mappingId: number) =>
  http.get(`p1/organizations/${orgId}/single_sign_on/mappings/${mappingId}`)

export const createSSOconfig = (orgId: number, data: CreateSSOConfig) =>
  http.post(`p1/organizations/${orgId}/single_sign_on`, data)

export const updateSSOconfig = (orgId: number, data: SSOConfig) =>
  http.put(`p1/organizations/${orgId}/single_sign_on`, data)

export const enableSSO = (orgId: number) =>
  http.put(`p1/organizations/${orgId}/single_sign_on/enable`)

export const disableSSO = (orgId: number) =>
  http.put(`p1/organizations/${orgId}/single_sign_on/disable`)

export const createSSOmapping = (orgId: number, data: CreateSSOMapping) =>
  http.post(`p1/organizations/${orgId}/single_sign_on/mappings`, data)

export const deleteSSOmapping = (orgId: number, mappingId: number) =>
  http.delete(`p1/organizations/${orgId}/single_sign_on/mappings/${mappingId}`)
