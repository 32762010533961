import classnames from 'classnames'
import styles from './index.module.scss'

const SearchDropdownDropdown: React.FC<React.HTMLAttributes<
  HTMLDivElement
>> = props => {
  const { className, ...rest } = props
  const classes = classnames(styles.Dropdown, className)

  return (
    <div className={classes} {...rest}>
      {props.children}
    </div>
  )
}

export default SearchDropdownDropdown
