import { Button } from '@/common/button'
import { CheckmarkIcon } from '@/common/icons'
import styles from './index.module.scss'
import { IListItem } from './types'

interface IProps {
  entry: IListItem
  selected?: boolean
}

const RosterListItemNewEntry: React.FC<IProps> = props => {
  const { entry, selected } = props

  return (
    <>
      <span className={styles.RosterListItem}>
        <p className={styles.RosterListItemName}>{entry.name}</p>
        <p className={styles.RosterListItemId}>ID: {entry.id}</p>
      </span>
      {selected && <CheckmarkIcon />}
      {!selected && (
        <Button mode="outline" size="sm">
          Create
        </Button>
      )}
    </>
  )
}

export default RosterListItemNewEntry
