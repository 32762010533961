import classnames from 'classnames'
import { FC } from 'react'

type Props = {
  simple?: boolean
}

const PageTop: FC<Props> = ({ children, simple }) => {
  const classes = classnames('page-top', {
    'page-top--simple': simple,
  })
  return <div className={classes}>{children}</div>
}

PageTop.defaultProps = {
  simple: false,
}

export default PageTop
