type Props = React.SVGAttributes<SVGSVGElement>

const SvgDownload = (props: Props) => (
  <svg width={19} height={18} viewBox="0 0 19 18" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M17.578 12.495c.244 0 .479.09.652.25.173.161.27.38.27.609v1.313c0 1.565-1.357 2.833-3.03 2.833H3.526C1.854 17.498.5 16.23.5 14.667v-1.313c0-.474.411-.859.918-.859.507 0 .918.385.918.859v1.313c.001.614.533 1.112 1.19 1.112H15.47c.657 0 1.19-.498 1.19-1.112v-1.313c0-.474.411-.859.918-.859zM9.498.5c.811 0 1.469.615 1.469 1.373v6.42c0 .098.085.178.19.178h2.013a.74.74 0 01.669.402.652.652 0 01-.114.735l-3.672 3.949a.757.757 0 01-.555.236.757.757 0 01-.554-.236L5.27 9.608a.652.652 0 01-.113-.735.74.74 0 01.668-.402H7.84c.105 0 .19-.08.19-.177v-6.42C8.03 1.113 8.686.5 9.497.5z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default SvgDownload
