import {
  IUkgRegistrationConfig,
  IUkgVerifyCredsResponse,
  QsrRegistrationConfig,
  QsrRegistrationResponse,
} from '@/api/types/settings'
import { PartnerOrganizationMapping } from '@/types/settings'
import http from './http'

export const getQsrRegistration = (orgId: number) =>
  http.get<QsrRegistrationResponse>(
    `wfm/organizations/${orgId}/registrations/qsrsoft`
  )

export const createQsrRegistration = (
  orgId: number,
  data: QsrRegistrationConfig
) => http.post(`wfm/organizations/${orgId}/registrations/qsrsoft`, data)

export const removeQsrLocation = (orgId: number, storeId: string) =>
  http.delete(
    `wfm/organizations/${orgId}/registrations/qsrsoft/stores/${storeId}`
  )

export const getUkgRegistration = (orgId: number) =>
  http.get(`wfm/organizations/${orgId}/registrations/kronos`)

export const createUkgRegistration = (
  orgId: number,
  data: IUkgRegistrationConfig
) => http.post(`wfm/organizations/${orgId}/registrations/kronos`, data)

export const deleteUkgRegistration = (orgId: number) =>
  http.delete(`wfm/organizations/${orgId}/registrations/kronos`)

export const verifyUkgAuth = (data: IUkgRegistrationConfig) =>
  http.post<IUkgVerifyCredsResponse>(`kronos/auth`, data)

export const runUkgJobs = (orgId: number) =>
  http.post(`wfm/organizations/${orgId}/jobs/kronos`)

export const getPartnerOrganizationMappings = (
  partnerId: number,
  orgId: number
) =>
  http.get<PartnerOrganizationMapping[]>(
    `wfm/partners/${partnerId}/mappings/organizations/${orgId}`
  )
