import classnames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useNavigationTree } from '@/hooks/useNavigationTree'
import { throttle } from '@/lib/throttle'
import { Tabs } from '../../tabs'
import { SubNavItem } from './SubNavItem'

interface Props {
  className?: string
}

export const SubNav: FC<Props> = ({ className }) => {
  const [scrolledToTop, setScrolledToTop] = useState(true)
  const { submenu } = useNavigationTree()

  useEffect(() => {
    const handleScroll = () => {
      setScrolledToTop(window.scrollY === 0)
    }
    window.addEventListener('scroll', throttle(handleScroll, 100))
    return () =>
      window.removeEventListener('scroll', throttle(handleScroll, 100))
  }, [setScrolledToTop])

  const classes = classnames(
    'SubNav',
    {
      SubNav_scrolled: !scrolledToTop,
    },
    className
  )

  if (!submenu) return null

  return (
    <>
      <div className="SubNavPadder" />
      <div className={classes}>
        <Tabs className="page-tabs">
          {submenu.map(item => (
            <SubNavItem key={item.route} compact={!scrolledToTop} item={item} />
          ))}
        </Tabs>
      </div>
    </>
  )
}
