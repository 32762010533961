import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { getQueryParamObjectFromString } from '@/lib/queryParams'
import {
  FILTER_KEY_PREFIX,
  prefixFilterKey,
  stripPrefixFromFilterKey,
} from './lib/utils'

export const useTableFilterSearchParams = ():
  | { [id: string]: any }
  | undefined => {
  const location = useLocation()

  return useMemo(() => {
    if (!location.search) return

    let filters: { [id: string]: any } = {}

    const searchParams: any = getQueryParamObjectFromString(location.search)

    if (!searchParams) return filters

    Object.keys(searchParams)
      .filter(key => {
        const hasPrefix = key.startsWith(FILTER_KEY_PREFIX)
        const keyInBlacklist = [
          prefixFilterKey('from'),
          prefixFilterKey('to'),
          prefixFilterKey('preset'),
        ].includes(key)
        return hasPrefix && !keyInBlacklist
      })
      .forEach(key => {
        const filter = searchParams[key]
        filters[stripPrefixFromFilterKey(key)] = filter
      })

    return filters
  }, [location])
}
