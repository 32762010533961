import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getInstantCardOrders, orderInstantCards } from '@/api/instant-cards'
import { IOrderInstantCardsConfig } from '@/api/types/instant-cards'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'

export const GET_INSTANT_CARDS_QUERY = 'get-instant-cards'

export const useInstantCardsQuery = () => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([GET_INSTANT_CARDS_QUERY, orgId], async () => {
    const { data } = await getInstantCardOrders(orgId)
    return data.data
  })
}

export const useOrderInstantCardsMutation = () => {
  const { id: orgId } = useCurrentOrg()
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()

  return useMutation(
    (config: IOrderInstantCardsConfig) => orderInstantCards(orgId, config),
    {
      onSuccess: res => {
        const { count } = res.data.data
        let toastMsg = `${count} cards have been successfully ordered.`
        sendAnalyticsEvent('instant_cards', 'order-cards:success', { count })

        if (!res.data.data.initiated) {
          toastMsg = `Your order of ${count} card${
            count === 1 ? '' : 's'
          } is in progress. Check back soon to see card order statuses.`
        }

        addToast({
          type: 'success',
          title: 'Instant Cards Ordered',
          description: toastMsg,
        })
        queryClient.invalidateQueries([GET_INSTANT_CARDS_QUERY, orgId])
      },
      onError: () => sendAnalyticsEvent('instant_cards', 'order-cards:failure'),
    }
  )
}
