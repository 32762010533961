import { useMemo } from 'react'
import { RouteProps } from 'react-router-dom'
import { usePermissionsStore } from '@/store/permissions'
import { AuthenticatedRoute } from './AuthenticatedRoute'

const ProtectedRoute = (props: RouteProps) => {
  const {
    computed: { hasViewPermission },
    defaultRoute,
  } = usePermissionsStore()
  const domain = useMemo(() => props.path?.toString() || '', [props])

  return (
    <AuthenticatedRoute
      redirectPath={defaultRoute}
      isAuthenticated={() => hasViewPermission(domain)}
      {...props}
    />
  )
}

export default ProtectedRoute
