export type FundingSource = {
  id: number
  account_number_last_four: string
  name: string
}

export type LineItem = {
  amount: number
  type: string
  description: string
}

export enum InvoicePaymentType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface InvoiceNote {
  note: string
  id: number
  created_by: number
  time_created: string
  time_modified: string
}
