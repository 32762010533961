import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons'
import * as SelectPrimitive from '@radix-ui/react-select'
import { FC } from 'react'
import {
  SelectContainer,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectStyledContent,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from './styles'

interface Option {
  label: string
  value?: any
}

interface Props {
  placeholder?: string
  options?: Option[]
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const SelectContent: FC = ({ children, ...props }) => (
  <SelectPrimitive.Portal>
    <SelectStyledContent {...props}>{children}</SelectStyledContent>
  </SelectPrimitive.Portal>
)

export const Select: FC<Props> = ({
  placeholder,
  options,
  value,
  onChange,
  disabled,
}) => (
  <SelectContainer value={value ? value : undefined} onValueChange={onChange}>
    <SelectTrigger disabled={disabled}>
      <SelectValue placeholder={placeholder} />
      <SelectIcon>
        <ChevronDownIcon />
      </SelectIcon>
    </SelectTrigger>
    <SelectContent>
      <SelectScrollUpButton>
        <ChevronUpIcon />
      </SelectScrollUpButton>
      <SelectViewport>
        <SelectGroup>
          {options?.map(option => (
            <SelectItem value={option.value} key={option.value}>
              <SelectItemText>{option.label}</SelectItemText>
              <SelectItemIndicator>
                <CheckIcon />
              </SelectItemIndicator>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectViewport>
      <SelectScrollDownButton>
        <ChevronDownIcon />
      </SelectScrollDownButton>
    </SelectContent>
  </SelectContainer>
)
