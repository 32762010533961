import { useCallback, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import { GroupedNavItem, NAV_GROUPS, navGroups, NavItem } from '@/router/menus'
import { ROUTES } from '@/router/routes'
import { usePermissionsStore } from '@/store/permissions'
import { useIsOrg } from './useIsOrg'
import { useSetting } from './useSetting'

export type NavigationSegment = NavItem & {
  submenu: NavItem[]
  show: boolean
  group: NAV_GROUPS
}

export const useNavigationTree = () => {
  const { isUberOrg } = useIsOrg()
  const location = useLocation()
  const cashoutEnabled = useSetting('ENABLE_CASH_OUT')
  const isUsingGrossEarnings = useSetting('PERIOD_EARNINGS_FOR_EARNED_WAGES')
  const { isEnabledOrg, hasViewPermission } = usePermissionsStore(
    state => state.computed
  )

  const navigationItems = useMemo(() => {
    const config = {
      [ROUTES.DISBURSEMENTS]: { isStandalone: cashoutEnabled === 'false' },
      [ROUTES.CASH_OUTS]: { isHidden: cashoutEnabled === 'false' },
      [ROUTES.TIMEKEEPING]: { isHidden: isUsingGrossEarnings === 'true' },
      [ROUTES.GROSS_EARNINGS]: { isHidden: isUsingGrossEarnings === 'false' },
    }
    return Object.keys(navGroups).reduce((prev, curr) => {
      const group = curr as NAV_GROUPS
      const groupedItems = navGroups[group].map(item => {
        if (isEnabledOrg) {
          item.isHidden = !hasViewPermission(item.route)
        }
        const itemConfig = config[item.route]
        const navItem = itemConfig ? { ...item, ...itemConfig } : item
        return { ...navItem, group }
      })
      prev.push(...groupedItems)
      return prev
    }, [] as GroupedNavItem[])
  }, [cashoutEnabled, hasViewPermission, isEnabledOrg, isUsingGrossEarnings])

  const permittedNavigationItems = useMemo(
    () => navigationItems.filter(item => !item.isHidden),
    [navigationItems]
  )

  const getSubmenus = useCallback(
    (group: NAV_GROUPS) => navigationItems.filter(item => item.group === group),
    [navigationItems]
  )

  const submenu = useMemo(() => {
    const match = navigationItems.find(
      i =>
        !!matchPath(location.pathname, {
          path: i.route,
          exact: true,
          strict: true,
        })
    )
    const matchGroup = navigationItems.filter(i => i.group === match?.group)
    return match && !match.isStandalone ? matchGroup : null
  }, [location, navigationItems])

  const navTree = useMemo(
    () => [
      {
        group: NAV_GROUPS.ORGANIZATION,
        label: 'Organization',
        route: ROUTES.WORKERS,
        submenu: getSubmenus(NAV_GROUPS.ORGANIZATION),
        show: true,
      },
      {
        group: NAV_GROUPS.BILLING,
        label: isUberOrg ? 'Backup Balances' : 'Advances',
        route: ROUTES.ADVANCES,
        submenu: getSubmenus(NAV_GROUPS.BILLING),
        show: true,
      },
      {
        group: NAV_GROUPS.PAYMENTS,
        label: 'Disbursements',
        route: ROUTES.DISBURSEMENTS,
        submenu: getSubmenus(NAV_GROUPS.PAYMENTS),
        show: true,
      },
      {
        group: NAV_GROUPS.INVOICES,
        label: 'Invoices',
        route: ROUTES.INVOICES,
        submenu: getSubmenus(NAV_GROUPS.INVOICES),
        show: true,
      },
      {
        group: NAV_GROUPS.CARDS,

        label: 'Cards',
        route: ROUTES.INSTANT_CARDS,
        submenu: getSubmenus(NAV_GROUPS.CARDS),
        show: true,
      },
      {
        group: NAV_GROUPS.CREATE_ACCOUNTS,
        label: 'Create Accounts',
        route: ROUTES.CREATE_ACCOUNTS,
        submenu: getSubmenus(NAV_GROUPS.CREATE_ACCOUNTS),
        show: true,
      },
    ],
    [isUberOrg, getSubmenus]
  ) as NavigationSegment[]

  const permittedNavigationTree = useMemo(() => {
    return navTree.map(item => {
      const hasSubMenu = permittedNavigationItems.find(
        pni => pni.group === item.group
      )
      if (!hasSubMenu) item.show = false
      return item
    })
  }, [permittedNavigationItems, navTree]) as NavigationSegment[]

  return { navTree, permittedNavigationTree, submenu }
}
