import { FC } from 'react'
import { IDeduction } from '@/types/remittances'

interface Props {
  original: IDeduction
}

export const CompletedNameCell: FC<Props> = ({ original }) => {
  const firstName = original.employee?.first_name
  const lastName = original.employee?.last_name

  return (
    <span>
      {firstName} {lastName}
    </span>
  )
}
