type TProps = React.HTMLAttributes<SVGSVGElement>

const SvgEmptyBarChart = (props: TProps) => (
  <svg width={174} height={88} viewBox="0 0 174 88" {...props}>
    <defs>
      <path id="EmptyBarChart_svg__a" d="M0 0h85.511v72.844H0z" />
      <path
        d="M7.33 26.04c0-1.075.867-1.946 1.954-1.946 1.08 0 1.955.869 1.955 1.946v46.943h-3.91V26.04z"
        id="EmptyBarChart_svg__d"
      />
      <path
        d="M23.821 17.807c0-1.077.868-1.95 1.955-1.95 1.079 0 1.954.874 1.954 1.95v55.176h-3.909V17.807z"
        id="EmptyBarChart_svg__f"
      />
      <path
        d="M40.313 50.497c0-1.082.867-1.959 1.954-1.959 1.08 0 1.955.87 1.955 1.96v22.485h-3.91V50.497z"
        id="EmptyBarChart_svg__h"
      />
      <path
        d="M56.804 30.292c0-1.075.868-1.947 1.955-1.947a1.95 1.95 0 011.954 1.947v42.69h-3.909v-42.69z"
        id="EmptyBarChart_svg__j"
      />
      <path
        d="M73.295 26.04c0-1.075.868-1.946 1.955-1.946 1.08 0 1.955.869 1.955 1.946v46.943h-3.91V26.04z"
        id="EmptyBarChart_svg__l"
      />
      <filter
        x="-25.6%"
        y="-2%"
        width="202.3%"
        height="108.2%"
        filterUnits="objectBoundingBox"
        id="EmptyBarChart_svg__c"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.235482812 0 0 0 0 0.653838873 0 0 0 0 0.969474614 0 0 0 0.16 0"
          in="shadowOffsetOuter1"
        />
      </filter>
      <filter
        x="-25.6%"
        y="-1.8%"
        width="202.3%"
        height="107%"
        filterUnits="objectBoundingBox"
        id="EmptyBarChart_svg__e"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.235482812 0 0 0 0 0.653838873 0 0 0 0 0.969474614 0 0 0 0.16 0"
          in="shadowOffsetOuter1"
        />
      </filter>
      <filter
        x="-25.6%"
        y="-4.1%"
        width="202.3%"
        height="116.4%"
        filterUnits="objectBoundingBox"
        id="EmptyBarChart_svg__g"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.235482812 0 0 0 0 0.653838873 0 0 0 0 0.969474614 0 0 0 0.16 0"
          in="shadowOffsetOuter1"
        />
      </filter>
      <filter
        x="-25.6%"
        y="-2.2%"
        width="202.3%"
        height="109%"
        filterUnits="objectBoundingBox"
        id="EmptyBarChart_svg__i"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.235482812 0 0 0 0 0.653838873 0 0 0 0 0.969474614 0 0 0 0.16 0"
          in="shadowOffsetOuter1"
        />
      </filter>
      <filter
        x="-25.6%"
        y="-2%"
        width="202.3%"
        height="108.2%"
        filterUnits="objectBoundingBox"
        id="EmptyBarChart_svg__k"
      >
        <feOffset dx={2} dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.235482812 0 0 0 0 0.653838873 0 0 0 0 0.969474614 0 0 0 0.16 0"
          in="shadowOffsetOuter1"
        />
      </filter>
    </defs>
    <g transform="translate(1)" fill="none" fillRule="evenodd">
      <g
        stroke="#E6E6E6"
        strokeDasharray="1.955555555555555,3.911111111111111"
        strokeLinecap="round"
        strokeWidth={0.978}
      >
        <path d="M.084 68.565H171.74M.252 56.39h171.656M.084 43.745H171.74M.252 31.803h171.656M.084 19.862H171.74M.084 7.92H171.74" />
      </g>
      <g transform="translate(.489 7.822)">
        <mask id="EmptyBarChart_svg__b" fill="#fff">
          <use xlinkHref="#EmptyBarChart_svg__a" />
        </mask>
        <g mask="url(#EmptyBarChart_svg__b)">
          <use
            fill="#000"
            filter="url(#EmptyBarChart_svg__c)"
            xlinkHref="#EmptyBarChart_svg__d"
          />
          <use fill="#349DF6" xlinkHref="#EmptyBarChart_svg__d" />
        </g>
        <g mask="url(#EmptyBarChart_svg__b)">
          <use
            fill="#000"
            filter="url(#EmptyBarChart_svg__e)"
            xlinkHref="#EmptyBarChart_svg__f"
          />
          <use fill="#349DF6" xlinkHref="#EmptyBarChart_svg__f" />
        </g>
        <g mask="url(#EmptyBarChart_svg__b)">
          <use
            fill="#000"
            filter="url(#EmptyBarChart_svg__g)"
            xlinkHref="#EmptyBarChart_svg__h"
          />
          <use fill="#349DF6" xlinkHref="#EmptyBarChart_svg__h" />
        </g>
        <g mask="url(#EmptyBarChart_svg__b)">
          <use
            fill="#000"
            filter="url(#EmptyBarChart_svg__i)"
            xlinkHref="#EmptyBarChart_svg__j"
          />
          <use fill="#349DF6" xlinkHref="#EmptyBarChart_svg__j" />
        </g>
        <g mask="url(#EmptyBarChart_svg__b)">
          <use
            fill="#000"
            filter="url(#EmptyBarChart_svg__k)"
            xlinkHref="#EmptyBarChart_svg__l"
          />
          <use fill="#349DF6" xlinkHref="#EmptyBarChart_svg__l" />
        </g>
      </g>
      <path
        d="M.084 80.526H171.74"
        stroke="#D8D8D8"
        strokeWidth={0.978}
        strokeLinecap="square"
      />
      <rect fill="#D8D8D8" x={85.511} width={1} height={88} rx={0.5} />
    </g>
  </svg>
)

export default SvgEmptyBarChart
