import { useState } from 'react'
import { getTimeAndAttendanceTable } from '@/api/time-attendance'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useTimeKeepingStore } from '@/store/time-keeping'
import { useToasterStore } from '@/store/toast'
import { TimeKeepingRecord } from '@/types/records'
import { downloadCSV } from './downloads'

const downloadDateFormat = 'YYYY-MM-DDThh:mm:ss'

export const useDownloadTimeKeeping = () => {
  const [loading, setLoading] = useState(false)
  const { addToast, expireToast } = useToasterStore()
  const { id: orgId, name: orgName } = useCurrentOrg()

  const { filters, sorted } = useTimeKeepingStore()

  const { name, employee_id, from, to } = filters

  const handleDownload = async () => {
    if (to.diff(from, 'days') > 31)
      return addToast({
        type: 'error',
        title: 'Date range too large',
        description: 'Please select a date range of 31 days or less.',
      })

    sendAnalyticsEvent('payroll', 'download-time-keeping', filters)

    const params = {
      start_time: from.format(downloadDateFormat),
      end_time: to.format(downloadDateFormat),
      name,
      employee_id,
      page: 1,
      sort: sorted[0].id,
      direction: sorted[0].desc ? 'desc' : 'asc',
    }

    let results: TimeKeepingRecord[] = []
    let currentPage = 1
    let totalPage = 1
    let toastId

    setLoading(true)

    try {
      toastId = addToast({
        type: 'pending',
        title: 'Downloading timekeeping records',
        description: `We're fetching your timekeeping records, it might take a moment.`,
      })

      const res = await getTimeAndAttendanceTable(orgId, params)
      const { content, page_number, total_pages } = res.data.data
      currentPage = page_number
      totalPage = total_pages
      results = [...results, ...content]

      while (currentPage < totalPage) {
        params.page = currentPage + 1
        const response = await getTimeAndAttendanceTable(orgId, params)
        currentPage = response.data.data.page_number
        totalPage = response.data.data.total_pages
        results = [...results, ...content]
      }

      downloadCSV(results, orgName, { start: from, end: to })
    } catch (error) {
      addToast({
        type: 'error',
        title: "Couldn't download results.",
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      if (toastId) {
        expireToast(toastId, 0)
      }
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
