import { FC, useState } from 'react'
import { Dialog } from '@/common/modal'
import { InputDropzone } from '@/components/file-upload/InputDropzone'
import { useModalStore } from '@/store/modal'
import { ResourceItem } from './ResourceItem'

export const AddResourceModal: FC = () => {
  const { removeModal } = useModalStore()
  const [open, setOpen] = useState(true)
  const [files, setFiles] = useState<File[]>([])

  const close = () => {
    removeModal()
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      setOpen={close}
      title="Add Resources"
      description="Please supply your resources name and description. These will
    help our users understand what they are downloading."
    >
      <InputDropzone
        handleDrop={setFiles}
        files={[]}
        allowMultiple
        label="Upload file (Drag n Drop or Select)"
        subtext="Only image files and PDFs are allowed"
        accept="image/*,application/pdf"
      />
      {files.map(file => (
        <ResourceItem key={file.name} file={file} />
      ))}
    </Dialog>
  )
}
