import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { Select } from '@/common/select'
import { Text } from '@/common/text'
import { useProcessorsQuery } from '@/src/queries/file-processing'
import { useAddProcessorMutation } from '@/src/queries/file-processing'
import { IRegistrationRow } from '../lib/types'

interface Props {
  handleClose: () => void
  registration: IRegistrationRow
}

export interface IAddProcessorFormValues {
  processorType: string
  regex: string
}

const schema = yup.object().shape({
  regex: yup.string().required(),
})

export const AddProcessorForm: FC<Props> = ({ handleClose, registration }) => {
  const addProcessor = useAddProcessorMutation()
  const processors = useProcessorsQuery()

  const processorsData = useMemo(() => processors.data || [], [processors])

  const processorOptions = useMemo(
    () => processorsData.map(item => ({ value: item.key, label: item.name })),
    [processorsData]
  )

  const initialValues = {
    processorType: processorsData[0].key,
    regex: '',
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const onFormSubmit = (values: IAddProcessorFormValues) =>
    addProcessor.mutate(
      {
        registrationId: registration.id,
        registrationType: registration.type,
        regex: values.regex,
        processorType: values.processorType,
      },
      {
        onSuccess: handleClose,
      }
    )

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div>
        <Label htmlFor="processorType">Type</Label>
        <Select
          placeholder="Select Pay Period"
          value={watch('processorType')}
          onChange={val => setValue('processorType', val)}
          options={processorOptions}
        />
      </div>
      <div>
        <Label htmlFor="description">Regex</Label>
        <Input {...register('regex')} />
        <Text>{errors.regex?.message}</Text>
      </div>
      <Flex justify="end">
        <Button
          mode="filled"
          type="submit"
          disabled={addProcessor.isLoading}
          css={{ mt: '$24' }}
        >
          Add Processor
        </Button>
      </Flex>
    </form>
  )
}
