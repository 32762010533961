import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createQsrRegistration,
  createUkgRegistration,
  deleteUkgRegistration,
  getPartnerOrganizationMappings,
  getQsrRegistration,
  getUkgRegistration,
  removeQsrLocation,
  runUkgJobs,
  verifyUkgAuth,
} from '@/api/integrations'
import { updateOrganization } from '@/api/organizations'
import {
  IUkgRegistrationConfig,
  QsrRegistrationConfig,
} from '@/api/types/settings'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { USER_ORGANIZATIONS_QUERY } from '@/queries/auth'

export const useRemovePartnerId = () => {
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async () => {
      const res = await updateOrganization({ partner_id: null }, orgId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USER_ORGANIZATIONS_QUERY)
      },
    }
  )
}

export const PARTNER_MAPPINGS_QUERY = 'partner-mappings'

export const usePartnerMappingsQuery = (partnerId: number) => {
  const { id: orgId } = useCurrentOrg()

  return useQuery([PARTNER_MAPPINGS_QUERY, partnerId, orgId], async () => {
    const res = await getPartnerOrganizationMappings(partnerId, orgId)
    return res.data
  })
}

export const QSR_REGISTRATION_QUERY = 'qsr-registration'

export const useQsrRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([QSR_REGISTRATION_QUERY, orgId], async () => {
    const res = await getQsrRegistration(orgId)
    return res.data
  })
}

export const useQsrCreateRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (params: QsrRegistrationConfig) => {
      const res = await createQsrRegistration(orgId, params)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QSR_REGISTRATION_QUERY)
      },
    }
  )
}

export const useQsrRemoveLocation = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (storeId: string) => {
      const res = await removeQsrLocation(orgId, storeId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QSR_REGISTRATION_QUERY)
      },
    }
  )
}

const UKG_REGISTRATION_QUERY = 'ukg-registration'

export const useUkgRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([UKG_REGISTRATION_QUERY, orgId], async () => {
    const res = await getUkgRegistration(orgId)
    return res.data
  })
}

export const useUkgCreateRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IUkgRegistrationConfig) => {
      const res = await createUkgRegistration(orgId, params)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(UKG_REGISTRATION_QUERY)
      },
    }
  )
}

export const useUkgDeleteRegistration = () => {
  const { id: orgId } = useCurrentOrg()
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const res = await deleteUkgRegistration(orgId)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(UKG_REGISTRATION_QUERY)
      },
    }
  )
}

export const useRunUkgJobs = () => {
  const { id: orgId } = useCurrentOrg()
  return useMutation(async () => {
    const res = await runUkgJobs(orgId)
    return res.data
  })
}

export const useVerifyUkgAuth = () => {
  return useMutation(async (params: IUkgRegistrationConfig) => {
    const res = await verifyUkgAuth(params)
    return res.data.data
  })
}
