import { FC } from 'react'
import { Alert } from '@/common/alert'
import { Loader } from '@/common/loader'
import * as Accordion from '@/components/common/accordion'
import { usePayoutTypesQuery } from '@/src/queries/payouts'
import { Ul } from './styles'

export const BulkDisbursementsGuide: FC = () => {
  const payoutTypes = usePayoutTypesQuery()

  return (
    <Accordion.Root type="multiple">
      <Accordion.Item value="1">
        <Accordion.Header>
          <Accordion.Trigger>
            Format Guide
            <Accordion.Arrow />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          <Alert type="info" css={{ mb: '$8' }}>
            All columns must be present.
          </Alert>
          <Ul>
            <li>
              <strong>Worker ID</strong> - The worker's unique id.
            </li>
            <li>
              <strong>First Name</strong> - The worker's first name.
            </li>
            <li>
              <strong>Last Name</strong> - The worker's last name.
            </li>
            <li>
              <strong>Type</strong> - Available types:{' '}
              <Loader loading={payoutTypes.isLoading}>
                {payoutTypes.data?.map((type, i) => (
                  <code key={type}>
                    {type}
                    {payoutTypes.data.length === i + 1 ? '' : ', '}
                  </code>
                ))}
              </Loader>
            </li>
            <li>
              <strong>Amount</strong> - The total dollar amount. Cannot be less
              than 0.
            </li>
            <li>
              <strong>Transaction ID</strong> - A unique value for each
              disbursement. Used to prevent duplicate payouts.
            </li>
            <li>
              <strong>Description</strong> - The description of the
              disbursement. This will show up on the workers transaction
              details. <strong>Leave empty if N/A</strong>.
            </li>
            <li>
              <strong>Location</strong> - The store ID.{' '}
              <strong>Leave empty if N/A</strong>.
            </li>
            <li>
              <strong>Disbursement Date</strong> - Fill this in only if the
              payment is scheduled for a future date, otherwise leave blank. The
              format must be (YYYY-MM-DD).
            </li>
          </Ul>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
