import { useMemo, useState } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { SearchFilter } from '@/common/filters/SearchFilter'
import { Box } from '@/common/layout'
import { useSharedPartnerRoster } from '@/hooks/usePartnerRoster'
import { useEnrollmentRequests } from '@/queries/employees'
import { useSidebar } from '@/src/hooks/useSidebar'
import { IOrganizationEmployee, UserEnrollmentRequest } from '@/types/employees'
import { EmployeeDetailsView } from './components/EmployeeDetailsView'
import { EmployeeRosterTable } from './components/EmployeeRosterTable'
import { EmployeesContainer } from './components/EmployeesContainer'
import { EnrollmentRequestDetailsView } from './components/EnrollmentRequestDetailsView'
import { EnrollmentRequestedTable } from './components/EnrollmentRequestedTable'

export const Employees = () => {
  const [showRequests, setShowRequests] = useState(false)
  const sharedPartnerRoster = useSharedPartnerRoster()
  const { data: pendingUsers, isLoading } = useEnrollmentRequests()
  const { sidebar, addSidebar, removeSidebar } = useSidebar()
  const [search, setSearch] = useState('')

  const hasEnrollmentRequest = useMemo(
    () =>
      !isLoading &&
      !!pendingUsers?.content.length &&
      !showRequests &&
      !sharedPartnerRoster,
    [isLoading, pendingUsers, showRequests, sharedPartnerRoster]
  )

  const openEmployeeSidebar = (user: IOrganizationEmployee, id: number) => {
    addSidebar(<EmployeeDetailsView user={user} remove={removeSidebar} />, id)
  }

  const toggleShowRequests = () => setShowRequests(!showRequests)

  const openEnrollmentRequestSidebar = (
    user: UserEnrollmentRequest,
    id: number
  ) => {
    addSidebar(
      <EnrollmentRequestDetailsView user={user} remove={removeSidebar} />,
      id
    )
  }

  return (
    <EmployeesContainer sidebar={sidebar}>
      <Box css={{ width: 300, mb: '$32' }}>
        <SearchFilter
          placeholder="Find by name..."
          defaultValue={search}
          fullWidth
          handleChange={v => setSearch(v || '')}
        />
      </Box>
      {hasEnrollmentRequest && (
        <Alert type="info">
          <div className="d-flex justify-content-between align-items-center">
            <span>You have users requesting enrollment</span>
            {!!pendingUsers?.content.length && (
              <Button
                mode="filled"
                onClick={() => setShowRequests(!showRequests)}
              >
                View Requests
              </Button>
            )}
          </div>
        </Alert>
      )}
      {showRequests && (
        <EnrollmentRequestedTable
          openEmployeeSidebar={openEnrollmentRequestSidebar}
          toggleView={toggleShowRequests}
          search={search}
        />
      )}
      <EmployeeRosterTable
        openEmployeeSidebar={openEmployeeSidebar}
        search={search}
      />
    </EmployeesContainer>
  )
}
