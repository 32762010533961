import * as React from 'react'
import { TableFilters, UpdateFilters } from '@/common/filters/types'
import {
  PageBody,
  PageFilters,
  PageHeader,
  PageTitle,
  PageTop,
} from '@/components/layout/page'
import { PayAnalyticsFilters } from './PayAnalyticsFilters'

type Props = {
  filters: TableFilters
  updateFilters: UpdateFilters
}

export const PayAnalyticsContainer: React.FC<Props> = props => {
  const { filters, updateFilters } = props

  return (
    <>
      <PageTop>
        <PageHeader>
          <PageTitle primary="Pay Insights" />
        </PageHeader>
        <PageFilters>
          <PayAnalyticsFilters
            filters={filters}
            updateFilters={updateFilters}
          />
        </PageFilters>
      </PageTop>
      <PageBody>{props.children}</PageBody>
    </>
  )
}
