import { VariantProps } from '@stitches/react'
import { Flex } from '@/common/layout'
import { Text } from '@/common/text'
import { styled } from '@/styles/stitches.config'

export const OrgItemSubTitle = styled(Text, {
  textTransform: 'uppercase',
  fontSize: 12,
  color: '$secondary500',
})

export const OrgDropDownItem = styled('div', {
  color: '$secondary100',
  fontWeight: 'bold',
  fontSize: 16,
  border: 'none',
  borderBottom: '1px solid $secondary800',
  backgroundColor: 'white',
  width: '100%',
  padding: '$8 $16',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: '$secondary800',
  },
})

export const OrgDropDownContainer = styled(Flex, {
  height: 600,
  variants: {
    size: {
      md: {
        width: 400,
        '@mobile': { width: '100vw' },
      },
      lg: {
        width: '100%',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
})

export type OrgDropDownContainerVariants = VariantProps<
  typeof OrgDropDownContainer
>
