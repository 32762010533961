import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import shallow from 'zustand/shallow'
import {
  getRemittance,
  getRemittanceBalances,
  getRemittanceDeductions,
  getRemittances,
  getWorkerRemittance,
  submitRemittance,
  updateWorkerRemittance,
} from '@/api/remittances'
import {
  GetRemittanceParams,
  UpdateRemittanceDeductions,
} from '@/api/types/remittances'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { TEmployeeAdjustments } from '@/components/deduction-report/types'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { handleHttpException } from '@/lib/httpException'
import { useDeductionReport } from '@/store/deduction-report'
import { useRemittances } from '@/store/remittances'
import { useToasterStore } from '@/store/toast'
import { RemittanceBalance } from '@/types/remittances'

export const REMITTANCE_QUERY = 'remittance'

export const useRemittance = (id: string) => {
  const { addToast } = useToasterStore()

  const resetAdjustmentState = useDeductionReport(
    state => state.setAdjustmentState
  )

  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [REMITTANCE_QUERY, orgId, id],
    async () => {
      const res = await getRemittance(id)
      return res.data.data
    },
    {
      onError: (err: AxiosError) => {
        addToast({
          type: 'error',
          title: `Unable to fetch remittance`,
          description: `${err.response?.data?.meta?.errorDetail}`,
        })
      },
      retry: 1,
      onSettled: () => resetAdjustmentState(id),
    }
  )
}

export const REMITTANCE_BALANCES_QUERY = 'remittance-balances'

export const useRemittanceBalances = (remittanceId: string) => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()
  const adjustments = useDeductionReport(state => state.adjustments)
  return useQuery(
    [REMITTANCE_BALANCES_QUERY, orgId, remittanceId],
    async () => {
      const res = await getRemittanceBalances(remittanceId)
      return res.data.data
    },
    {
      select: data => {
        const owed = (data || []).reduce((acc, curr) => {
          let amount = acc + curr.balance
          const employeeAdjustment = adjustments[curr.employee_id]
          if (employeeAdjustment) {
            return amount - (curr.balance - employeeAdjustment.amount)
          }
          return amount
        }, 0)
        return { balances: data, owed } as RemittanceBalance
      },
      retry: 1,
      onError: (err: any) => {
        handleHttpException(err, {
          onHttpError: ({ response }) => {
            addToast({
              type: 'error',
              title: `Unable to fetch remittance balances`,
              description: `${response?.data?.meta?.errorDetail}`,
            })
          },
        })
      },
    }
  )
}

export const REMITTANCE_DEDUCTIONS_QUERY = 'remittance-deductions'

export const useRemittanceDeductions = (remittanceId: string) => {
  const { addToast } = useToasterStore()
  const { id: orgId } = useCurrentOrg()
  return useQuery(
    [REMITTANCE_DEDUCTIONS_QUERY, orgId, remittanceId],
    async () => {
      const res = await getRemittanceDeductions(remittanceId)
      return res.data.data
    },
    {
      onError: (err: AxiosError) => {
        addToast({
          type: 'error',
          title: `Unable to fetch remittance balance deductions`,
          description: `${err.response?.data?.meta?.errorDetail}`,
        })
      },
      retry: 1,
    }
  )
}

type CreateRemittanceDeductions = {
  remittanceId: string
  form: TEmployeeAdjustments
}

export const useCreateRemittanceDeductions = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()
  return useMutation(
    async ({ remittanceId, form }: CreateRemittanceDeductions) => {
      const res = await submitRemittance(remittanceId, form)
      return res.data
    },
    {
      onSuccess: ({ data: { id } }) => {
        sendAnalyticsEvent('remittances', 'create-deductions:success', { id })
        queryClient.invalidateQueries([REMITTANCE_QUERY, orgId, id])
        queryClient.invalidateQueries([REMITTANCE_BALANCES_QUERY, orgId, id])
        queryClient.invalidateQueries([REMITTANCE_DEDUCTIONS_QUERY, orgId, id])
      },
      onError: (err: AxiosError, { remittanceId }) => {
        sendAnalyticsEvent('remittances', 'create-deductions:error', {
          id: remittanceId,
        })
        addToast({
          type: 'error',
          title: `Unable to create remittance deduction`,
          description: `${err.response?.data?.meta?.errorDetail}`,
        })
      },
    }
  )
}

export type GetWorkerRemittanceParams = {
  employeeId: string
  remittanceId: string
}

export const WORKER_REMITTANCE_QUERY = 'worker-remittance'

export const useGetWorkerRemittance = (data: GetWorkerRemittanceParams) => {
  const { addToast } = useToasterStore()
  return useQuery(
    [WORKER_REMITTANCE_QUERY, data.remittanceId, data.employeeId],
    async () => {
      const res = await getWorkerRemittance(data.remittanceId, data.employeeId)
      return res.data
    },
    {
      onError: (err: AxiosError) => {
        addToast({
          type: 'error',
          title: `Unable to fetch worker remittance`,
          description: `${err.response?.data?.meta?.errorDetail}`,
        })
      },
    }
  )
}

export const useUpdateRemittanceDeductions = () => {
  const { addToast } = useToasterStore()
  const queryClient = useQueryClient()
  const { id: orgId } = useCurrentOrg()

  return useMutation(
    async (payload: UpdateRemittanceDeductions) => {
      const res = await updateWorkerRemittance(payload)
      return res.data
    },
    {
      onSuccess: (_, payload) => {
        sendAnalyticsEvent('remittances', 'update-deductions:success')
        queryClient.invalidateQueries([
          REMITTANCE_DEDUCTIONS_QUERY,
          orgId,
          payload.remittanceId,
        ])
      },
      onError: (err: AxiosError, { remittanceId }) => {
        sendAnalyticsEvent('remittances', 'update-deductions:error', {
          id: remittanceId,
        })
        addToast({
          type: 'error',
          title: `Unable to update remittance deduction`,
          description: `${err.response?.data?.meta?.errorDetail}`,
        })
      },
    }
  )
}

export const GET_REMITTANCES_QUERY = 'get-remittances'

export const useRemittancesQuery = () => {
  const { id: orgId } = useCurrentOrg()

  const { filters, page, pageSize } = useRemittances(
    state => ({
      filters: state.filters,
      page: state.page,
      pageSize: state.pageSize,
      paginationParams: state.paginationParams,
      filterArray: state.computed.filterArray,
    }),
    shallow
  )

  const params: GetRemittanceParams = {
    page: page + 1,
    size: pageSize,
    org_id: orgId,
    ...filters,
  }

  return useQuery([GET_REMITTANCES_QUERY, orgId, params], async () => {
    const res = await getRemittances(params)
    return res.data.data
  })
}
