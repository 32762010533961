import { FC, useMemo } from 'react'
import { Button } from '@/common/button'
import { PayDocumentIcon } from '@/common/icons'
import { AlertDialog } from '@/common/modal'
import { toDollar } from '@/lib/currency'
import { useFundingSources } from '@/queries/funding-sources'
import { useRemittanceBalances } from '@/queries/remittances'
import { useDeductionReport } from '@/store/deduction-report'
import { useModalStore } from '@/store/modal'
import { usePostRemittance } from '../lib/usePostRemittance'
import { FundingSourceAlert } from './FundingSourceAlert'
import { ProgressCard } from './ProgressCard'
import ProgressCardFooter from './ProgressCardFooter'
import ProgressCardTitle from './ProgressCardTitle'

interface Props {
  remittanceId: string
}

export const PayProgressCard: FC<Props> = ({ remittanceId }) => {
  const balances = useRemittanceBalances(remittanceId)
  const getProgressStepPosition = useDeductionReport(
    state => state.computed.getProgressStepPosition
  )
  const { postRemittance } = usePostRemittance(remittanceId)
  const { registerModal } = useModalStore()
  const { data } = useFundingSources()

  const fundingSource = useMemo(() => {
    if (data && data.length > 0) return data[0]
  }, [data])

  const description = (
    <>
      By marking your remittance as complete, you agree to pay Branch{' '}
      {toDollar(balances.data?.owed)}. Please make sure all deductions are
      correct or updated where relevant.
      {fundingSource && <FundingSourceAlert fundingSource={fundingSource} />}
      <br />
      Are you sure you want to complete this remittance?
    </>
  )

  const openPostRemittanceModal = () => {
    registerModal(
      <AlertDialog
        title="Complete remittance"
        description={description}
        actionDescription={'Complete'}
        handleAction={postRemittance}
      />
    )
  }

  const stepPosition = getProgressStepPosition('pay')

  return (
    <ProgressCard isDisabled={stepPosition > 0}>
      <ProgressCardTitle text="Pay remittance" Icon={PayDocumentIcon} />
      <h5>
        By marking your remittance as complete, you agree to pay Branch{' '}
        {toDollar(balances.data?.owed)}. Please make sure all deductions are
        correct or updated where relevant.
      </h5>

      {fundingSource && <FundingSourceAlert fundingSource={fundingSource} />}
      <ProgressCardFooter>
        <Button
          mode={stepPosition < 0 ? 'outline' : 'filled'}
          disabled={stepPosition > 0}
          onClick={openPostRemittanceModal}
        >
          Complete remittance
        </Button>
      </ProgressCardFooter>
    </ProgressCard>
  )
}
