import { Button } from '@/common/button'
import { FileUpload } from '@/components/spreadsheet-mapper/FileUpload'
import {
  Step,
  StepAside,
  StepBody,
  StepDescription,
  StepFooter,
  StepFooterRight,
  StepTitle,
} from '@/components/stepped-modal'
import { downloadTimekeepingTemplate } from '../lib/downloadTimekeepingTemplate'
import { TemplateExample } from './'

interface IProps {
  handleComplete: () => void
  handleFile: (file?: File) => void
  file?: File
  transition?: string
}

export const FileUploadStep = (props: IProps) => {
  const { file, transition, handleComplete, handleFile } = props

  return (
    <Step state={transition}>
      <StepBody hasAside>
        <StepTitle>
          Importing data from your Time and Attendance files is easy with
          Branch.
        </StepTitle>
        <StepDescription>
          All we need is a CSV with a row for each worker{`'`}s ID, Punch In,
          and Punch Out records.{' '}
          <span className="link" onClick={downloadTimekeepingTemplate}>
            Download our template
          </span>{' '}
          to get started.
        </StepDescription>
        <p className="font-weight-bold">Here{`'`}s an example:</p>{' '}
        <TemplateExample />
      </StepBody>

      <StepAside>
        <FileUpload file={file} handleDrop={handleFile} />
      </StepAside>

      <StepFooter>
        <StepFooterRight>
          <Button mode="filled" onClick={handleComplete} disabled={!file}>
            Continue
          </Button>
        </StepFooterRight>
      </StepFooter>
    </Step>
  )
}
