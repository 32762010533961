import { FC } from 'react'
import { TableFiltersMain } from '@/common/filters/TableFiltersMain'
import { useInstantCardsStore } from '@/store/instant-cards'
import { InstantCard } from '@/types/instant-cards'
import downloadCSV from '../lib/download'
import { additionalFilters, statusOptions } from '../lib/filters'

interface Props {
  downloadableRows: InstantCard[]
}

export const InstantCardFilters: FC<Props> = ({ downloadableRows }) => {
  const { filters, updateFilters, removeFilter } = useInstantCardsStore()
  const handleDownload = () => {
    downloadCSV(downloadableRows)
  }

  return (
    <TableFiltersMain
      searchProperty="name_on_card"
      searchPlaceholder="card name"
      filters={filters}
      statusOptions={statusOptions}
      updateFilters={updateFilters}
      removeFilter={removeFilter}
      additionalFilters={additionalFilters}
      handleDownload={handleDownload}
      hasDateRange={false}
    />
  )
}
