import { FC, useMemo } from 'react'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  payback: PaybackRecord
  value: string
}

export const PaybackAmount: FC<Props> = ({
  payback: { amount, description },
  value,
}) => {
  const isPending = useMemo(() => !amount, [amount])

  const isRefund = useMemo(
    () =>
      description?.toLowerCase().startsWith('refund') || value?.includes('-'),
    [description, value]
  )

  const currentValue = useMemo(() => {
    if (isPending) return 'Pending'
    return isRefund ? `+ ${value?.replace('-', '')}` : value
  }, [isPending, isRefund, value])

  return (
    <span
      style={{
        color: isRefund ? 'green' : '',
      }}
    >
      {currentValue}
    </span>
  )
}
