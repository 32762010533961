import { useQuery } from 'react-query'
import { getDirectDepositForms } from '@/api/direct-deposits'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'

const DD_FORMS_QUERY = 'dd-forms-query'
export const useDirectDepositFormsQuery = () => {
  const { id: orgId } = useCurrentOrg()
  return useQuery([DD_FORMS_QUERY, orgId], async () => {
    const res = await getDirectDepositForms(orgId)
    return res.data.data
  })
}
