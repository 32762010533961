import { AmountFilter } from '@/common/filters/AmountFilter'
import { AdditionalFilterItem } from '@/common/filters/types'
import { toDollar } from '@/lib/currency'
import { PaybackStatus } from '@/types/paybacks'

export const paybackStatusOptions: { label: string; value: PaybackStatus }[] = [
  { value: PaybackStatus.OPEN, label: 'Open' },
  { value: PaybackStatus.CLOSED, label: 'Closed' },
  { value: PaybackStatus.FINALIZED, label: 'Finalized' },
  { value: PaybackStatus.BILLED, label: 'Billed' },
  { value: PaybackStatus.COMPLETED, label: 'Completed' },
  { value: PaybackStatus.FAILED, label: 'Failed' },
]

export const additionalFilters: AdditionalFilterItem[] = [
  { id: 'id', label: 'ID' },
  {
    id: 'amount',
    label: 'Amount',
    formatValue: val => toDollar(val) || 'None',
    renderInput: props => <AmountFilter {...props} />,
  },
  {
    id: 'funding_source_id',
    label: 'Funding Acct',
  },
]
