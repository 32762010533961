import { FC, useState } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { TextArea } from '@/common/input'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { useEditInvoice } from '@/queries/invoice-configs'
import { useModalStore } from '@/store/modal'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  record: PaybackRecord
}

export const EditInvoiceModal: FC<Props> = ({ record }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const [description, setDescription] = useState(record.description)
  const editInvoice = useEditInvoice()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const updateInvoice = () => {
    editInvoice.mutate(
      {
        invoiceId: record.id,
        description: description,
        funding_source_id: record.funding_source_id,
        closeTime: record.close_time,
        billTime: record.bill_time,
      },
      {
        onSuccess: close,
      }
    )
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDescription(e.target.value)

  return (
    <Dialog open={open} setOpen={close} title={`Edit Invoice ${record.id}`}>
      <TextArea onChange={handleChange} value={description} />
      {editInvoice.isError && (
        <Alert color="danger">Encountered an error.</Alert>
      )}
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button
          mode="filled"
          onClick={updateInvoice}
          loading={editInvoice.isLoading}
        >
          Submit
        </Button>
      </Flex>
    </Dialog>
  )
}
