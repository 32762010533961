import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button } from '@/common/button'
import { Card } from '@/common/card'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex, FlexGrid } from '@/common/layout'
import { Text } from '@/common/text'
import { YearMonthDayRegex } from '@/lib/regex'
import {
  useRunUkgJobs,
  useUkgCreateRegistration,
  useUkgDeleteRegistration,
  useVerifyUkgAuth,
} from '@/src/queries/integrations'
import { useModalStore } from '@/store/modal'
import { useToasterStore } from '@/store/toast'
import { UkgDeleteConfirmationModal } from './UkgDeleteConfirmationModal'

type UkgRegistrationForm = {
  vanity_url: string
  app_key: string
  username: string
  password: string
  consumer_key: string
  consumer_secret: string
  roster_effective_date: string
  timekeeping_effective_date: string
}

const schema = yup.object().shape({
  vanity_url: yup.string().required(),
  app_key: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().required(),
  consumer_key: yup.string().required(),
  consumer_secret: yup.string().required(),
  roster_effective_date: yup
    .string()
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
  timekeeping_effective_date: yup
    .string()
    .matches(YearMonthDayRegex, 'Must be in the format YYYY-MM-DD'),
})

interface Props {
  data?: any
}

export const UkgIntegrationForm: FC<Props> = ({ data }) => {
  const { addToast } = useToasterStore()
  const runJobs = useRunUkgJobs()
  const createRegistration = useUkgCreateRegistration()
  const deleteRegistration = useUkgDeleteRegistration()
  const configured = useMemo(() => !!data, [data])
  const verifyCreds = useVerifyUkgAuth()
  const { registerModal } = useModalStore()

  const initialValues = {
    vanity_url: data?.vanity_url || '',
    app_key: data?.app_key || '',
    username: data?.username || '',
    password: data?.password || '',
    consumer_key: data?.consumer_key || '',
    consumer_secret: data?.consumer_secret || '',
    timekeeping_effective_date: !!data
      ? moment(data.timekeeping_effective_date).format('YYYY-MM-DD')
      : '',
    roster_effective_date: !!data
      ? moment(data.roster_effective_date).format('YYYY-MM-DD')
      : '',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const onFormSubmit = async ({
    roster_effective_date,
    timekeeping_effective_date,
    ...rest
  }: UkgRegistrationForm) => {
    // There isn't currently an update endpoint,
    // so if data exists, delete the record and create a new one
    if (configured) {
      try {
        await deleteRegistration.mutateAsync()
      } catch (e) {
        console.log(e)
        return
      }
    }

    const formatDate = (date: string) =>
      moment(date || undefined)
        .startOf('day')
        .utc()
        .format()

    const payload = {
      ...rest,
      processor_registrations: [
        {
          dataview_name: 'Branch Roster File',
          processor_name: 'kronosDataviewRosterApiProcessor',
          effective_date: formatDate(roster_effective_date),
        },
        {
          dataview_name: 'Branch Timekeeping',
          processor_name: 'kronosDataviewTimekeepingApiProcessor',
          effective_date: formatDate(timekeeping_effective_date),
        },
      ],
    }

    createRegistration.mutate(payload, {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Integration registration was successful',
        })
      },
    })
  }

  const handleVerification = () => {
    const {
      roster_effective_date,
      timekeeping_effective_date,
      ...rest
    } = getValues()
    const payload = { ...rest, processor_registrations: [] }

    verifyCreds.mutate(payload, {
      onSuccess: ({ is_authed }) => {
        addToast({
          type: is_authed ? 'success' : 'error',
          title: `${is_authed ? 'Valid' : 'Invalid'} credentials`,
        })
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'An error occurred',
          description:
            'An error occurred while attempting to validate your credentials',
        })
      },
    })
  }

  const handleRunJobs = () => {
    runJobs.mutate(undefined, {
      onSuccess: () => {
        addToast({
          type: 'success',
          title: 'Jobs have started running',
        })
      },
    })
  }

  const openDeleteConfirmationModal = () => {
    registerModal(<UkgDeleteConfirmationModal />)
  }

  return (
    <Card>
      <Text as="h5" css={{ mb: '$8' }}>
        Configure Integration
      </Text>
      {configured && (
        <Button
          mode="filled"
          onClick={() => handleRunJobs()}
          loading={runJobs.isLoading}
        >
          Run Jobs
        </Button>
      )}
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <FlexGrid size="lg" direction="column" fullWidth>
          <div>
            <Label htmlFor="vanity_url">Vanity URL</Label>
            <Input {...register('vanity_url')} />
            <Text color="alert" size="sm">
              {errors.vanity_url?.message}
            </Text>
          </div>
          <div>
            <Label htmlFor="app_key">App Key</Label>
            <Input {...register('app_key')} />
            <Text color="alert" size="sm">
              {errors.app_key?.message}
            </Text>
          </div>
          <div>
            <Label htmlFor="username">Username</Label>
            <Input {...register('username')} />
            <Text color="alert" size="sm">
              {errors.username?.message}
            </Text>
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <Input {...register('password')} />
            <Text color="alert" size="sm">
              {errors.password?.message}
            </Text>
          </div>
          <div>
            <Label htmlFor="consumer_key">Consumer Key</Label>
            <Input {...register('consumer_key')} />
            <Text color="alert" size="sm">
              {errors.consumer_key?.message}
            </Text>
          </div>
          <div>
            <Label htmlFor="consumer_secret">Consumer Secret</Label>
            <Input {...register('consumer_secret')} />
            <Text color="alert" size="sm">
              {errors.consumer_secret?.message}
            </Text>
          </div>
          <FlexGrid>
            <div>
              <Label htmlFor="roster_effective_date">
                Roster Effective Date
              </Label>
              <Input
                {...register('roster_effective_date')}
                placeholder="2021-12-30"
              />
              <Text color="alert" size="sm">
                {errors.roster_effective_date?.message}
              </Text>
            </div>
            <div>
              <Label htmlFor="timekeeping_effective_date">
                Timekeeping Effective Date
              </Label>
              <Input
                {...register('timekeeping_effective_date')}
                placeholder="2021-12-30"
              />
              <Text color="alert" size="sm">
                {errors.timekeeping_effective_date?.message}
              </Text>
            </div>
          </FlexGrid>
          <Flex justify="between">
            <Button
              mode="filled"
              onClick={handleVerification}
              disabled={verifyCreds.isLoading || !isDirty || !isValid}
            >
              Verify Credentials
            </Button>
            <Button
              mode="filled"
              type="submit"
              disabled={createRegistration.isLoading}
            >
              Save
            </Button>
          </Flex>
        </FlexGrid>
      </form>
      <Button
        color="danger"
        mode="outline"
        size="md"
        css={{
          mt: '$24',
        }}
        onClick={openDeleteConfirmationModal}
        loading={deleteRegistration.isLoading}
      >
        Delete UKG Integration
      </Button>
    </Card>
  )
}
