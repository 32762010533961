import * as Stitches from '@stitches/react'
import { keyframes, styled } from '@/styles/stitches.config'

const shine = keyframes({
  to: {
    backgroundPositionX: '-200%',
  },
})

export const Button = styled('button', {
  display: 'inline-flex',
  lineHeight: 1,
  backgroundColor: 'white',
  border: '1px solid $secondary700',
  borderRadius: '$sm',
  fontWeight: 'bold',
  boxShadow: '$button',
  alignItems: 'center',
  transition: '200ms color, 200ms background-color',
  textAlign: 'center',

  '&:disabled': {
    color: '#999999',
    borderColor: '#e6e6e6',
    opacity: 0.75,
  },

  '&:hover': {
    color: '$secondary300',
    backgroundColor: '$secondary900',
  },

  '& svg': {
    fill: 'white',
    height: '12px',
    width: '12px',
  },

  variants: {
    display: {
      block: {
        display: 'block',
      },
    },
    color: {
      primary: {
        color: '$primary500',
      },
      tertiary: {
        color: '$tertiary500',
      },
      danger: {
        color: '$warn500',
      },
      black: {
        color: 'black',
      },
    },
    mode: {
      flat: {
        border: 'none',
        boxShadow: 'none',
        '&:hover': {
          color: 'black',
          backgroundColor: '$secondary800',
        },
      },
      filled: {
        color: 'white',
        backgroundColor: '$primary500',
      },
      outline: {
        border: '2px solid $primary500',
      },
    },
    hideIcon: {
      true: {
        '& svg': {
          display: 'none',
        },
      },
    },
    iconRight: {
      true: {
        '& svg': {
          marginLeft: '$4',
        },
      },
    },
    iconLeft: {
      true: {
        '& svg': {
          marginRight: '$4',
        },
      },
    },
    size: {
      sm: {
        fontSize: 12,
        padding: '$6 $8',
      },
      md: {
        fontSize: 14,
        padding: '$8 $12',
      },
      lg: {
        fontSize: 16,
        padding: '$12 $16',
      },
    },
    loading: {
      true: {
        color: '$secondary300',
        pointerEvents: 'none',
        background:
          'linear-gradient(115deg, $secondary900 8%, white 25%, $secondary900 33%)',
        backgroundSize: '200% 100%',
        animation: `1.5s ${shine} linear infinite`,
      },
    },
  },
  compoundVariants: [
    {
      color: 'primary',
      mode: 'outline',
      css: {
        borderColor: '$primary500',
        '&:hover': {
          color: 'white',
          backgroundColor: '$primary500',
        },
      },
    },
    {
      color: 'black',
      mode: 'outline',
      css: {
        border: '1px solid rgba(0,0,0,0.1)',
      },
    },
    {
      color: 'primary',
      mode: 'filled',
      css: {
        color: 'white',
        backgroundColor: '$primary500',
        border: '2px solid $primary500',
        '&:hover': {
          color: 'white',
          backgroundColor: '$primary500',
        },
        '&:disabled': {
          color: '#999999',
          backgroundColor: '#e6e6e6',
        },
      },
    },
    {
      color: 'tertiary',
      mode: 'outline',
      css: {
        borderColor: '$tertiary500',
        '&:hover': {
          color: 'white',
          backgroundColor: '$tertiary500',
        },
      },
    },
    {
      color: 'tertiary',
      mode: 'filled',
      css: {
        color: 'white',
        backgroundColor: '$tertiary500',
        border: '2px solid $tertiary500',
        '&:hover': {
          color: 'white',
          backgroundColor: '$tertiary500',
        },
        '&:disabled': {
          color: '#999999',
          backgroundColor: '#e6e6e6',
        },
      },
    },
    {
      color: 'danger',
      mode: 'outline',
      css: {
        borderColor: '$warn500',
        '&:hover': {
          color: 'white',
          backgroundColor: '$warn500',
        },
      },
    },
    {
      color: 'danger',
      mode: 'filled',
      css: {
        color: 'white',
        backgroundColor: '$warn500',
        border: '2px solid $warn500',
        '&:hover': {
          color: 'white',
          backgroundColor: '$warn500',
        },
        '&:disabled': {
          color: '#999999',
          backgroundColor: '#e6e6e6',
        },
      },
    },
  ],

  defaultVariants: {
    size: 'md',
    color: 'tertiary',
  },
})

export const StyledIconButton = styled('button', {
  width: '$32',
  height: '$32',
  padding: '$8',
  margin: 0,
  color: '$secondary300',
  backgroundColor: 'transparent',
  transition: 'background-color 0.2s ease-in-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'none',
  borderRadius: '24px',
  fontSize: '16px',

  '-webkit-appearance': 'none',

  '& svg': {
    width: '$24',
    height: '$24',
  },

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$secondary700',
  },

  '&:focus': {
    outline: 'none',
    backgroundColor: '$secondary700',
  },

  '&:disabled': {
    pointerEvents: 'none',
    opacity: '.33',
  },

  '@mobile': {
    padding: 0,

    '& svg': {
      width: '$16',
      height: '$16',
    },
  },

  variants: {
    color: {
      primary: {
        backgroundColor: '$tertiary500',

        '& svg path': {
          fill: 'white',
        },
      },
      secondary: {
        border: '2px solid $tertiary500',

        '& svg path': {
          fill: '$tertiary500',
        },
      },
      danger: {
        border: '2px solid $secondary500',

        '& svg path': {
          fill: '$secondary500',
        },

        '&:hover': {
          borderColor: '$warn500',

          '& svg path': {
            fill: '$warn500',
          },
        },
      },
    },
    small: {
      true: {
        '& svg': {
          width: '$16',
          height: '$16',
        },
      },
    },
  },
})

export type StyledIconButtonVariants = Stitches.VariantProps<
  typeof StyledIconButton
>

export type ButtonVariants = Stitches.VariantProps<typeof Button>
