import { ColumnDef } from '@tanstack/react-table'
import { InstantCard } from '@/types/instant-cards'
import { StatusCell } from '../components/StatusCell'
import { ViewOrderCell } from '../components/ViewOrderCell'

export type InstantCardRow = InstantCard & {
  orderId: number
  card_product: 'BRANCH_1099_INSTANT' | 'BRANCH_W2_INSTANT'
}

export const instantCardColumns: ColumnDef<InstantCardRow, any>[] = [
  {
    accessorKey: 'orderId',
    id: 'order_id',
    header: 'Order ID',
    cell: row => <ViewOrderCell value={row.getValue()} />,
    maxSize: 140,
  },

  {
    accessorKey: 'name_on_card',
    header: 'Card Name',
    id: 'name_on_card',
  },
  {
    accessorKey: 'id',
    header: 'ID',
    id: 'id',
    enableSorting: true,
  },
  {
    accessorFn: row =>
      row.employee_first_name
        ? `${row.employee_first_name} ${row.employee_last_name}`
        : '',
    header: 'Name',
    id: 'name',
  },
  {
    accessorFn: row => (row.card_product.includes('W2') ? 'W2' : '1099'),
    header: 'Type',
    id: 'card_product',
    enableSorting: false,
  },
  {
    accessorKey: 'account_number',
    header: 'Account Number',
    id: 'account_number',
    enableSorting: true,
  },
  {
    accessorKey: 'routing_number',
    header: 'Routing Number',
    id: 'routing_number',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    id: 'status',
    enableSorting: false,
    cell: ({ row }) => <StatusCell original={row.original} />,
    minSize: 200,
  },
]
