import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/common/button'
import { Input } from '@/common/input'
import { Label } from '@/common/label'
import { Flex } from '@/common/layout'
import { useUpdateOrgLocationSettings } from '@/queries/settings'
import { useToasterStore } from '@/store/toast'

type GeneralSetupFormValues = {
  passCode?: string
}

interface Props {
  initialValue?: string
}

const resetForm = (passCode?: string) => ({ passCode: passCode })

export const Passcode: FC<Props> = ({ initialValue }) => {
  const updateSettings = useUpdateOrgLocationSettings()
  const { addToast } = useToasterStore()
  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: resetForm(initialValue),
  })

  const onSubmitForm = async (value: GeneralSetupFormValues) => {
    updateSettings.mutate(
      { passcode: value.passCode },
      {
        onSuccess: () => {
          addToast({
            type: 'success',
            title: `Updated location passcodes to ${value.passCode}.`,
          })
        },
        onError: () => {
          addToast({
            type: 'error',
            title:
              'Please try again soon, and if the issue persists, contact support.',
          })
        },
      }
    )
  }

  useEffect(() => {
    reset(resetForm(initialValue))
  }, [initialValue, reset])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Label>Passcode</Label>
      <Flex>
        <Input type="text" {...register('passCode')} css={{ width: 400 }} />
        <Button
          type="submit"
          mode="filled"
          disabled={updateSettings.isLoading || !isDirty}
          size="md"
          css={{ ml: '$8' }}
        >
          Save
        </Button>
      </Flex>
    </form>
  )
}
