import moment from 'moment'
import { FC } from 'react'
import { toDollar } from '@/lib/currency'
import { useRemittance } from '@/queries/remittances'
import { RemittanceBalance } from '@/types/remittances'
import styles from '../index.module.scss'

interface Props {
  remittanceBalance?: RemittanceBalance
  remittanceId: string
}

export const RemittanceDetailsAside: FC<Props> = ({
  remittanceBalance,
  remittanceId,
}) => {
  const remittance = useRemittance(remittanceId)

  if (!remittance.data) {
    return null
  }

  return (
    <>
      <h3 className="mb-4">Remittance summary</h3>
      <div className={styles.AsideLineItem}>
        <div className={styles.AsideLineItem_Description}>
          <p>Payroll Remittance ID</p>
        </div>
        <div className={styles.AsideLineItem_Value}>{remittanceId}</div>
      </div>
      <div className={styles.AsideLineItem}>
        <div className={styles.AsideLineItem_Description}>
          <p>Payroll Pay Date</p>
        </div>
        <div className={styles.AsideLineItem_Value}>
          {moment(remittance.data.pay_date).format('MMM Do, YYYY')}
        </div>
      </div>
      <hr />
      <div className={styles.AsideLineItem}>
        <div className={styles.AsideLineItem_Description}>
          <h5>Total advanced</h5>
          <p>{remittanceBalance?.balances.length} individuals</p>
        </div>
        <div className={styles.AsideLineItem_Value}>
          {toDollar(remittance.data.requested_amount)}
        </div>
      </div>
      <hr />
      <div className={styles.AsideLineItem}>
        <div className={styles.AsideLineItem_Description}>
          <h5 className="font-weight-bold">Total amount billed</h5>
        </div>
        <div className={styles.AsideLineItem_Value}>
          {toDollar(remittanceBalance?.owed)}
        </div>
      </div>
    </>
  )
}
