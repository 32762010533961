import { FC } from 'react'
import {
  UseFieldArrayReturn,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form'
import { Button, IconButton } from '@/common/button'
import { TrashIcon } from '@/common/icons'
import { Input } from '@/common/input'
import { Box, Flex, FlexGrid } from '@/common/layout'
import { Select } from '@/common/select'
import { AddPayoutFormValues } from './AddPayoutForm'

interface Props {
  metadata: UseFieldArrayReturn<AddPayoutFormValues, 'metadata', 'id'>
  setValue: UseFormSetValue<AddPayoutFormValues>
  register: UseFormRegister<AddPayoutFormValues>
}

export const MetaDataFieldArray: FC<Props> = ({
  metadata: { fields, remove, append },
  register,
  setValue,
}) => {
  const typeOptions = [{ value: 'shift_timestamp', label: 'Shift Timestamp' }]

  return (
    <Box>
      {fields.map((item, index) => (
        <FlexGrid key={index} css={{ mb: '$8' }}>
          <Box>
            <Select
              value={item.type}
              onChange={e =>
                setValue(
                  `metadata.${index}.type` as 'metadata.0.type',
                  e.target.value
                )
              }
              options={typeOptions}
            />
          </Box>
          <Box>
            <Input {...register(`metadata.${index}.value` as const)} />
          </Box>
          <Box>
            <IconButton
              id={`metadata.${index}`}
              onClick={() => remove(index)}
              type="button"
            >
              <TrashIcon />
            </IconButton>
          </Box>
        </FlexGrid>
      ))}
      <Flex css={{ mt: '$4' }}>
        <Button
          mode="outline"
          type="button"
          css={{ mt: '$4' }}
          onClick={() => append({ type: 'shift_timestamp', value: '' })}
        >
          Add
        </Button>
      </Flex>
    </Box>
  )
}
