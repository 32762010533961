import { LockClosedIcon } from '@radix-ui/react-icons'
import { CSSProperties, FC } from 'react'
import { AdminBadge } from '@/common/admin-badge/AdminBadge'
import { Label } from '@/common/label'
import { Tooltip } from '@/common/tooltip'
import { usePermissionsStore } from '@/store/permissions'

interface Props {
  hideLabel?: boolean
  style?: CSSProperties
}

export const LockedIndicator: FC<Props> = ({ hideLabel, style }) => {
  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  return isGlobalAdmin ? (
    <AdminBadge />
  ) : (
    <div style={style}>
      <Tooltip side="bottom" trigger={<LockClosedIcon />}>
        Contact Branch about updating
      </Tooltip>
      {!hideLabel && <Label css={{ ml: '$8' }}>Locked</Label>}
    </div>
  )
}
