import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Loader } from '@/common/loader'
import {
  SearchDropdownDropdown,
  SearchDropdownInput,
  SearchDropdownList,
  SearchDropdownListItem,
} from '@/components/search-dropdown-field'
import RosterListItem from './RosterListItem'
import RosterListItemNewEntry from './RosterListItemNewEntry'
import { IListItem } from './types'

interface IProps {
  handleSelect: (employee: IListItem) => void
  handleSearch: (value: string) => void
  search: string
  loadingData: boolean
  list: IListItem[]
  name: string
  selected?: IListItem
}

const RosterList: React.FC<IProps> = props => {
  const {
    handleSelect,
    handleSearch,
    search,
    loadingData,
    list,
    selected,
    name,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  const sorted = useMemo(() => {
    return list.sort((a, b) => {
      if (!!selected && a.id === selected.id) return -1
      if (a.name > b.name) return 1
      if (b.name > a.name) return -1
      return 0
    })
  }, [list, selected])

  const hideNewEntryOption = useMemo(() => {
    const searchHasExactIdMatch = sorted.some(
      r => r.id.toLowerCase() === search.toLowerCase()
    )
    return searchHasExactIdMatch || search.length === 0
  }, [sorted, search])

  const onResultSelect = useCallback(
    (result: IListItem) => {
      return !result.taken ? handleSelect(result) : null
    },
    [handleSelect]
  )

  const newEntry = useMemo<IListItem>(
    () => ({ name: `New: ${name}`, id: search, newEntry: true }),
    [search, name]
  )

  return (
    <SearchDropdownDropdown>
      <SearchDropdownInput
        value={search}
        ref={inputRef}
        placeholder="Enter someone's ID"
        onChange={e => handleSearch(e.target.value)}
      />
      <Loader loading={loadingData}>
        <SearchDropdownList>
          {!hideNewEntryOption && (
            <SearchDropdownListItem
              tabIndex={0}
              onClick={() => handleSelect(newEntry)}
              onKeyPress={() => handleSelect(newEntry)}
            >
              <RosterListItemNewEntry
                entry={newEntry}
                selected={selected?.id === search}
              />
            </SearchDropdownListItem>
          )}

          {sorted.map(entry => (
            <SearchDropdownListItem
              key={`${entry.id} ${entry.name}`}
              tabIndex={0}
              disabled={entry.taken}
              onClick={() => onResultSelect(entry)}
              onKeyPress={() => onResultSelect(entry)}
            >
              <RosterListItem
                entry={entry}
                selected={selected?.id === entry.id}
              />
            </SearchDropdownListItem>
          ))}
        </SearchDropdownList>
      </Loader>
    </SearchDropdownDropdown>
  )
}

export default RosterList
