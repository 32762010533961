import qs from 'qs'
import {
  EmployeeCardDetailsResponse,
  GetWorkersPeriodEarningsParamsResponse,
  IGetEmployeeStatsResponse,
  IOrganizationEmployeeResponse,
  IOrganizationEmployeesResponse,
  IOrgUserDetailsResponse,
  IRosterUsersResponse,
  UserEnrollmentRequestsResponse,
  WorkersPeriodEarningsParams,
} from '@/api/types/employees'
import { IAdvanceDeductionsResponse } from '@/api/types/remittances'
import { IRosterEdits } from '@/types/employees'
import http from './http'

/**
 * Retrieves an organization's roster records (NEW)
 */
export const getOrganizationEmployees = (orgId: number, params: any) =>
  http.get<IOrganizationEmployeesResponse>(
    `/p1/organizations/${orgId}/employee_details?${qs.stringify(params)}`,
    { timeout: 0 }
  )

/**
 * Retrieves a employee's card details
 */
export const getEmployeeCardDetails = (
  orgId: number,
  employeeId?: string | null
) =>
  http.get<EmployeeCardDetailsResponse>(
    `/p1/organizations/${orgId}/employee_details/${employeeId}/card_details`
  )

/**
 * Retrieves an organization's roster records
 */
export const getRosterEntries = (orgId: number) =>
  http.get<IRosterUsersResponse>(`/p1/organizations/${orgId}/employees`)

/**
 * Retrieve user enrollment requests for the org
 */
export const getEnrollmentRequests = (orgId: number, params?: any) =>
  http.get<UserEnrollmentRequestsResponse>(
    `p1/organizations/${orgId}/enrollment_requests?${qs.stringify(params)}`
  )

/**
 * Retrieve matching enrollment requests for provided names
 */
export const getEnrollmentMatching = (orgId: number, names: string[]) => {
  const params = `name=${names.join('&name=')}`
  return http.get<UserEnrollmentRequestsResponse>(
    `p1/organizations/${orgId}/enrollment_requests?${params}`
  )
}

/**
 * Retrieve enrollment-requested user details
 */
export const getEnrollmentRequestUser = (orgId: number, userId?: string) =>
  http.get<IOrgUserDetailsResponse>(
    `p1/organizations/${orgId}/enrollment_requests/${userId}/details`
  )

export const getWorker = (orgId: number, workerId?: string) =>
  http.get<IOrganizationEmployeesResponse>(
    `/p1/organizations/${orgId}/employee_details?employee_id=${workerId}`
  )

/**
 * Allows user to edit a roster record on the fly
 * @param employeeId roster record employee ID
 * @param edits editable values in the roster record
 */
export const editRosterUser = (
  employeeId: string,
  edits: IRosterEdits,
  orgId: number
) => http.put(`p1/organizations/${orgId}/employees/${employeeId}`, edits)

/**
 * Removes a user's record from the roster and removes them from the location
 */
export const removeUser = (id: string, orgId: number) =>
  http.delete(`/p1/organizations/${orgId}/users/${id}`)

/**
 * Verifies that a user belongs to an organization and adds them to the roster.
 */
export const verifyUser = (id: string, employeeId: string, orgId: number) =>
  http.post(`/p1/organizations/${orgId}/users/${id}/verify`, {
    employee_id: employeeId,
  })

/**
 * Retrieves deductions related to a specific advance
 * @param id advance ID
 */
export const getAdvanceDeductions = (id: string) =>
  http.get<IAdvanceDeductionsResponse>(`p1/advances/${id}/deductions`)

/**
 * Retrieve employee period earnings
 */
export const getWorkersPeriodEarnings = (
  params: WorkersPeriodEarningsParams
) => {
  return http.get<GetWorkersPeriodEarningsParamsResponse>(
    `p1/organizations/period_earnings`,
    {
      params,
    }
  )
}

/**
 * export employee period earnings
 */
export const exportWorkersPeriodEarnings = (
  params: WorkersPeriodEarningsParams
) => {
  return http.get<string>(
    `p2/organizations/${params.org_id}/export/period_earnings`,
    {
      params,
    }
  )
}

/**
 * Retrieves an organization's roster statistics
 */
export const getOrganizationEmployeeStats = (orgId: number) =>
  http.get<IGetEmployeeStatsResponse>(
    `/p1/organizations/${orgId}/employees/stats`
  )

/**
 * Retrieve worker details by employee id
 */
export const getWorkerDetails = (orgId: number, employeeId: string) =>
  http.get<IOrganizationEmployeeResponse>(
    `p1/organizations/${orgId}/employee_details/${employeeId}`
  )

export const removeRosterRecord = (orgId: number, employeeId: string) => {
  return http.delete(`p1/organizations/${orgId}/employees?empIds=${employeeId}`)
}
