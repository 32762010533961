import { FC, useEffect, useMemo, useState } from 'react'
import { GetEnrollmentRequestedParams } from '@/api/types/employees'
import { Button } from '@/common/button'
import { Table } from '@/common/table'
import { useIndefiniteTablePagination } from '@/hooks/useTablePagination'
import { parseRouteParams } from '@/lib/queryParams'
import { useEnrollmentRequests } from '@/queries/employees'
import { useEnrollmentRequestedStore } from '@/store/enrollment-requested'
import { UserEnrollmentRequest } from '@/types/employees'
import { enrollmentColumns } from '../lib/enrollment-columns'
import { EnrollmentRequestedFilters } from './EnrollmentRequestedFilters'

interface Props {
  openEmployeeSidebar: (user: UserEnrollmentRequest, id: number) => void
  toggleView: () => void
  search: string
}

const initialSortedState = [
  {
    id: 'id',
    desc: false,
  },
]

export const EnrollmentRequestedTable: FC<Props> = ({
  openEmployeeSidebar,
  toggleView,
  search,
}) => {
  const [filteredData, setFilteredData] = useState<UserEnrollmentRequest[]>([])

  const {
    page,
    pageSize,
    updatePageSize,
    sorted,
    computed: { filterArray },
    updatePage,
    setSorted,
    isReady,
    setPaginationParams,
    updateFilters,
    ready,
  } = useEnrollmentRequestedStore()

  useEffect(() => {
    updateFilters({
      fuzzy_name: search,
      desc: false,
    })
  }, [updateFilters, search])

  const { isLoading, data } = useEnrollmentRequests()

  const { hasNextPage, hasPrevPage } = useIndefiniteTablePagination(
    data?.has_next_page,
    data?.has_prev_page
  )

  const changePage = (nextPage: number) => {
    if (!data) return
    const pageParams = nextPage > page ? data.next_page : data.prev_page
    const pageFilters = parseRouteParams<GetEnrollmentRequestedParams>(
      pageParams
    )
    const params = nextPage ? pageFilters : undefined

    updatePage(nextPage)
    setPaginationParams(params)
  }

  useEffect(() => {
    if (ready) return
    setSorted(initialSortedState)
    isReady()
  }, [isReady, setSorted, ready])

  const tableData = useMemo(() => data?.content || [], [data])

  return (
    <>
      <div className="d-flex justify-content-between align-items-baseline mb-2">
        <div className="d-inline-flex">
          <h4 className="">Enrollment Requested</h4>
          <Button
            mode="flat"
            onClick={toggleView}
            size="sm"
            css={{ textDecoration: 'underline', ml: '$4' }}
          >
            Hide
          </Button>
        </div>
        <div className="d-inline-flex justify-content-end mb-2">
          <EnrollmentRequestedFilters
            filteredData={filteredData.length > 0 ? filteredData : tableData}
          />
        </div>
      </div>
      <Table
        columns={enrollmentColumns}
        data={tableData}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        controlledPagination={true}
        sorted={sorted}
        onSortedChange={setSorted}
        initialSortedState={initialSortedState}
        columnFiltersState={filterArray}
        setFilteredData={setFilteredData}
        isLoading={isLoading}
        page={page}
        pageRows={pageSize}
        updatePageSize={updatePageSize}
        changePage={changePage}
        rowOnClickFn={openEmployeeSidebar}
      />
    </>
  )
}
