import { FC } from 'react'
import { DetailProperty } from '@/common/detail'
import { Box } from '@/common/layout'
import { SubAccount } from '@/types/sub-account'

interface Props {
  subAccount: SubAccount
}

export const SubAccountDetails: FC<Props> = ({ subAccount }) => (
  <Box css={{ padding: 10 }}>
    <DetailProperty
      canCopy
      label="Account Number"
      value={subAccount.account_number}
    />
    <DetailProperty
      canCopy
      label="Routing Number"
      value={subAccount.routing_number}
    />
  </Box>
)
