import { useState } from 'react'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'
import { IRemittance } from '@/types/remittances'
import { downloadRemittance } from './download'

export const useDownloadRemittance = (remittance?: IRemittance) => {
  const [pendingDownload, setPendingDownload] = useState(false)
  const { addToast } = useToasterStore()

  const download = async () => {
    if (remittance) {
      sendAnalyticsEvent('remittances', 'download', { id: remittance.id })
      setPendingDownload(true)
      try {
        if (remittance) {
          await downloadRemittance(remittance)
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: `Download remittances failure`,
        })
      } finally {
        setPendingDownload(false)
      }
    }
  }

  return {
    download,
    pendingDownload,
  }
}
