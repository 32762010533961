import { PropsWithChildren } from 'react'
import { FieldPath, UseFormRegister } from 'react-hook-form'
import { Input, InputIcon } from '@/common/input'
import { Flex } from '@/common/layout'

interface Props<FormValues> {
  name: FieldPath<FormValues>
  register: UseFormRegister<FormValues>
}

export const DollarInput = <T,>({
  name,
  register,
}: PropsWithChildren<Props<T>>) => (
  <Flex>
    <InputIcon>$</InputIcon>
    <Input
      {...register(name, {
        valueAsNumber: true,
      })}
      step="0.01"
      type="number"
      iconLeft
    />
  </Flex>
)
