import { FC, useMemo, useState } from 'react'
import { createResource } from '@/api/resources'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { InputDropzone } from '@/components/file-upload/InputDropzone'
import { Dimensions, getImageFileDimensions } from '@/lib/fileSize'
import { makeOrgAssetLink } from '@/lib/orgAssetLink'
import { Alert } from '../alert'

interface Props {
  title: string
  description: string
  handleSubmit: (url: string) => void
  label?: string
  placeholder?: string
  disabled?: boolean
  accept?: string | string[]
  acceptedHeight?: number
}

const DEFAULT_ACCEPTED_FILE_TYPE = 'image/*'

export const UploadAsset: FC<Props> = ({
  handleSubmit,
  title,
  description,
  acceptedHeight,
  label,
  placeholder,
  disabled,
  accept = DEFAULT_ACCEPTED_FILE_TYPE,
}) => {
  const [files] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [dimensionInvalid, setDimensionInvalid] = useState<string | null>()
  const { id: orgId } = useCurrentOrg()

  const isDimensionValid = (d: Dimensions) => {
    if (!acceptedHeight) return true
    const { height } = d
    return height <= acceptedHeight
  }

  const upload = async (assets: File[]) => {
    setLoading(true)
    setError(null)
    setDimensionInvalid(null)

    try {
      const file = assets[0]
      const dimensions = await getImageFileDimensions(file)
      const isValid = isDimensionValid(dimensions)
      if (!isValid) {
        setDimensionInvalid('Image must follow the required size')
        setLoading(false)
        return
      }
      const res = await createResource(
        {
          title,
          description,
          file,
          public: true,
        },
        orgId
      )
      const link = makeOrgAssetLink(res.data.data.id)
      handleSubmit(link)
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  const subText = useMemo(() => {
    let text = `File type must be an ${
      accept === DEFAULT_ACCEPTED_FILE_TYPE ? 'image' : 'accepted'
    } type`

    if (acceptedHeight) {
      text = `${text}. Image height must be ${acceptedHeight}px or less.`
    }

    return text
  }, [accept, acceptedHeight])

  return (
    <>
      <InputDropzone
        handleDrop={upload}
        files={files}
        accept={accept}
        allowMultiple
        placeholder={placeholder}
        disabled={loading || disabled}
        label={label || 'Upload file (Drag n Drop or Select)'}
        subtext={subText}
      />
      {error && <Alert type="danger">Asset upload failed.</Alert>}
      {dimensionInvalid && (
        <Alert type="warning">Image must follow the required size</Alert>
      )}
    </>
  )
}
