import {
  Matchers,
  Questions,
} from '@/components/spreadsheet-mapper/useMatchStore'

const punchDateIsNotSeparate = (questions: Questions): boolean => {
  return !questions.punchDateIsSeparate.value
}

const noRateCol = (questions: Questions): boolean => {
  return !questions.hasRate.value
}

const noRateTypeCol = (questions: Questions): boolean => {
  return noRateCol(questions) || !questions.hasRateType.value
}

const selectedPunches = (questions: Questions): boolean => {
  return questions.hoursWorkedFormat.value === 'punches'
}

const selectedHours = (questions: Questions): boolean => {
  return questions.hoursWorkedFormat.value === 'hours'
}

const noPayPeriodEnd = (questions: Questions): boolean => {
  return !questions.hasPayPeriodEnd.value
}

export const questions: Questions = {
  hoursWorkedFormat: {
    text: 'how would you like to denote hours worked?',
    label: 'Hours worked format?',
    options: [
      { value: 'punches', label: 'Time punches' },
      { value: 'hours', label: 'Daily hours worked' },
    ],
  },
  punchDateIsSeparate: {
    text: 'is the punch date a separate column from the punch times?',
    label: 'Punch dates separate?',
    shouldHide: selectedHours,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasPayPeriodEnd: {
    text: 'do you have a column for your pay period end?',
    label: 'Pay period end?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasRate: {
    text: "do you have a table column for each worker's rate?",
    label: 'Rate column?',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  hasRateType: {
    text: 'do you have a column indicating rate type? (hourly, salary, etc.)',
    label: 'Rate type column?',
    shouldHide: noRateCol,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
}

export const matchers: Matchers = {
  rate: {
    dataField: 'rate',
    label: 'Rate',
    shouldHide: noRateCol,
  },
  rateType: {
    dataField: 'shift_type',
    label: 'Rate Type',
    shouldHide: noRateTypeCol,
  },
  employeeId: {
    dataField: 'employee_id',
    label: "Worker's ID",
  },
  punchDate: {
    dataField: 'punch_date',
    label: 'Punch Date',
    shouldHide: punchDateIsNotSeparate,
  },

  // `workDate` and `punchIn` use the
  // same data field, but the label is
  // different depending on the hours worked
  // format

  workDate: {
    dataField: 'punch_in',
    label: 'Work Date',
    shouldHide: selectedPunches,
  },
  punchIn: {
    dataField: 'punch_in',
    label: 'Punch In',
    shouldHide: selectedHours,
  },

  punchOut: {
    dataField: 'punch_out',
    label: 'Punch Out',
    shouldHide: selectedHours,
  },
  payPeriodEnd: {
    dataField: 'pay_period_end',
    label: 'Pay Period End',
    shouldHide: noPayPeriodEnd,
  },
  hours: {
    dataField: 'hours',
    label: 'Hours',
    shouldHide: selectedPunches,
  },
}
