import { DateRangePicker } from '@/common/datepicker'
import { TableFilters, UpdateFilters } from '@/common/filters/types'

export type Props = {
  filters: TableFilters
  updateFilters: UpdateFilters
}

export const PayAnalyticsFilters: React.FC<Props> = props => {
  const { filters, updateFilters } = props

  return (
    <div>
      <div className="d-flex">
        <div className="mr-2">
          <DateRangePicker
            from={filters.from}
            to={filters.to}
            preset={filters.preset}
            setRange={updateFilters}
          />
        </div>
      </div>
    </div>
  )
}
