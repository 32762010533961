import { ChevronDownIcon } from '@radix-ui/react-icons'
import { SearchDropdownButton } from '@/components/search-dropdown-field'
import { Employee } from './Employee'
import { ISelectableEmployee } from './types'

interface IProps {
  employee?: ISelectableEmployee
  handleClick: () => void
}

export const EmployeeSelectField: React.FC<IProps> = props => {
  const { employee, handleClick } = props

  return (
    <SearchDropdownButton onClick={handleClick}>
      <div className="w-100 d-flex align-items-center">
        <span className="mr-auto">
          {employee ? <Employee employee={employee} /> : <>Search worker</>}
        </span>

        <ChevronDownIcon width={12} />
      </div>
    </SearchDropdownButton>
  )
}
