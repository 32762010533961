import { useMemo } from 'react'
import { getPercentage } from '@/lib/percent'
import { InstantCard } from '@/types/instant-cards'

const useStatusMetrics = (cards: InstantCard[]) => {
  return useMemo(() => {
    const totalCount = cards.length
    const claimedCount = cards.filter(c => c.status === 'CLAIMED').length
    const unclaimedCount = cards.filter(c => c.status === 'UNCLAIMED').length
    const claimedPercentage = getPercentage(claimedCount, totalCount)
    const unclaimedPercentage = getPercentage(unclaimedCount, totalCount)

    return {
      claimedCount,
      claimedPercentage,
      unclaimedCount,
      unclaimedPercentage,
    }
  }, [cards])
}

export default useStatusMetrics
