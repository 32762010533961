import { SortingState } from '@tanstack/react-table'
import { saveAs } from 'file-saver'
import { useState } from 'react'
import { getOrganizationEmployeesReport } from '@/api/create-accounts'
import { GetRosterParams } from '@/api/types/employees'
import { CSV_FORMAT_TYPE } from '@/common/button/CSVDownloadForm'
import { TableFilters } from '@/common/filters/types'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { sendAnalyticsEvent } from '@/lib/analytics'
import { useToasterStore } from '@/store/toast'

interface Props {
  filters: TableFilters
  sorted: SortingState
}

export const useDownloadRoster = ({ filters, sorted }: Props) => {
  const [loading, setLoading] = useState(false)
  const { addToast, expireToast } = useToasterStore()
  const { id: orgId, name } = useCurrentOrg()

  const handleDownload = async (type = CSV_FORMAT_TYPE.RAW) => {
    sendAnalyticsEvent('workers', 'download', filters)
    let toastId
    setLoading(true)
    try {
      const params: GetRosterParams = {
        sort: sorted[0].id,
        direction: sorted[0].desc ? 'desc' : 'asc',
        useSpreadsheetFormat:
          type === CSV_FORMAT_TYPE.SPREADSHEET ? true : false,
        ...filters,
      }
      const { data: csvContent } = await getOrganizationEmployeesReport(
        orgId,
        params
      )
      const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8' })
      saveAs(
        blob,
        `${name ? name.split(' ').join('_') : 'branch'}_user_directory.csv`
      )
    } catch (error) {
      addToast({
        type: 'error',
        title: "Couldn't download results.",
        description:
          'Please try again soon, and if the issue persists, contact support.',
      })
    } finally {
      if (toastId) {
        expireToast(toastId, 0)
      }
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
