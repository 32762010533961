import { usePartnerQuery } from '@/queries/settings'
import { useUser } from '@/store/user'

export const usePartnerRosterInitializer = () => {
  const currentOrg = useUser(state => state.currentOrganization)
  const partnerSettings = usePartnerQuery()

  return {
    isInitialized: partnerSettings.isSuccess || !currentOrg?.partner_id,
  }
}
