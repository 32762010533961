import { FC, useEffect, useMemo, useRef } from 'react'
import { Button } from '@/components/common/button'
import {
  Step,
  StepBody,
  StepFooter,
  StepFooterLeft,
  StepTitle,
} from '@/components/stepped-modal'
import { FileValidationError } from '@/types/common'
import { Alert } from '../alert'
import { Loader } from '../loader'
import { SimpleTable } from '../table/SimpleTable'

interface Props {
  data: FileValidationError[]
  error: any
  columns: any[]
  isValidating: boolean
  transition?: string
  handleClose: () => void
  handleSubmit: () => void
}

export const BulkUploadValidate: FC<Props> = ({
  data,
  error,
  columns,
  transition,
  isValidating,
  handleClose,
  handleSubmit,
}) => {
  const moveOn = useRef(handleSubmit)
  const canMoveOn = useMemo(() => {
    const allAMountNull = data.every(
      el => el.status_reason === 'Disbursement amount was null'
    )
    return !isValidating && !error && allAMountNull
  }, [isValidating, data, error])

  useEffect(() => {
    if (!canMoveOn) return
    moveOn.current()
  }, [canMoveOn])

  return (
    <Step state={transition}>
      <StepBody>
        <StepTitle>
          {isValidating
            ? 'Validating your file contents...'
            : 'We encountered some issues.'}
        </StepTitle>
        <Loader loading={isValidating}>
          {error && (
            <Alert css={{ mt: '$4' }} type="danger">
              File is not valid. ({(error as any)?.message})
            </Alert>
          )}
          {data.length > 0 && (
            <>
              <Alert css={{ mt: '$4' }} type="danger">
                The contents of your file contains invalid lines. Please check
                the table below.
              </Alert>
              <SimpleTable data={data} columns={columns} />
            </>
          )}
        </Loader>
      </StepBody>
      <StepFooter>
        <StepFooterLeft>
          <Button color="danger" mode="outline" onClick={handleClose}>
            Exit
          </Button>
        </StepFooterLeft>
      </StepFooter>
    </Step>
  )
}
