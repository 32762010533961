import moment from 'moment'
import { useCallback, useState } from 'react'
import { addEmailRegistration, importTA } from '@/api/ingestion'
import { IEmailRegistrationConfig } from '@/api/types/settings'
import { useCurrentOrg } from '@/components/auth/hooks/useCurrentOrg'
import { MatchObject } from '@/components/spreadsheet-mapper/useMatchStore'
import { getBase64FromFile } from '@/lib/asyncReader'
import { getCachedCurrentOrganizationKey } from '@/src/sessionstorage'

const createIngestionConfig = async (file: File, orgId: number) => {
  const orgKey = getCachedCurrentOrganizationKey() || ''
  const config: IEmailRegistrationConfig = {
    email_key: orgKey,
    processor_registrations: [
      {
        filename: file.name,
        processor_name: 'standardTimekeepingFileProcessor',
        effective_date: moment().format(),
      },
    ],
  }
  try {
    await addEmailRegistration(config, orgId)
    return true
  } catch (error) {
    throw error
  }
}

export const useImportTA = (file: File, matchObject: MatchObject) => {
  const [uploadingTestConfig, setUploadingTestConfig] = useState(false)
  const [uploadingConfig, setUploadingConfig] = useState(false)
  const [errorMessage, setErrorMessage] = useState('false')
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadingFileSucceeded, setUploadingFileSucceeded] = useState(false)
  const [
    uploadingTestConfigSucceeded,
    setUploadingTestConfigSucceeded,
  ] = useState(false)
  const [uploadingConfigSucceeded, setUploadingConfigSucceeded] = useState(
    false
  )
  const [showGeneralErrorPrompt, setShowGeneralErrorPrompt] = useState(false)

  const { id: orgId } = useCurrentOrg()

  const ingestTAFile = useCallback(
    async (save = false) => {
      const base64File: string = await getBase64FromFile(file)
      const config: {
        [key: string]: any
      } = {
        ...matchObject,
        file: base64File,
        filename: file.name,
        organization_id: orgId,
        save_configuration: save,
        save_data: save,
      }

      try {
        await importTA(config)
        return true
      } catch (error) {
        throw error
      }
    },
    [file, matchObject, orgId]
  )

  const uploadFile = useCallback(
    async (orgId: number) => {
      setUploadingFile(true)
      try {
        await createIngestionConfig(file, orgId)
        setUploadingFileSucceeded(true)
        return true
      } catch (error) {
        setUploadingFileSucceeded(false)
        throw error
      } finally {
        setUploadingFile(false)
      }
    },
    [file, setUploadingFileSucceeded, setUploadingFile]
  )

  const uploadTimekeeping = useCallback(
    async (save = false) => {
      if (save) {
        setUploadingConfig(true)
      } else {
        setUploadingTestConfig(true)
      }

      try {
        await ingestTAFile(save)
        if (save) {
          setUploadingConfigSucceeded(true)
        } else {
          setUploadingTestConfigSucceeded(true)
        }
        return true
      } catch (error) {
        throw error
      } finally {
        if (save) {
          setUploadingConfig(false)
        } else {
          setUploadingTestConfig(false)
        }
      }
    },
    [
      setUploadingConfig,
      setUploadingTestConfig,
      setUploadingConfigSucceeded,
      setUploadingTestConfigSucceeded,
      ingestTAFile,
    ]
  )

  const handleUpload = useCallback(
    async (orgId: number) => {
      setErrorMessage('')
      setShowGeneralErrorPrompt(false)
      setUploadingConfigSucceeded(false)
      setUploadingTestConfigSucceeded(false)
      setUploadingFileSucceeded(false)

      try {
        await uploadTimekeeping()
        await uploadTimekeeping(true)
        await uploadFile(orgId)
      } catch (error) {
        const details = error?.response?.data?.meta?.detail
        setShowGeneralErrorPrompt(true)
        setErrorMessage(details)
      }
    },
    [uploadFile, uploadTimekeeping]
  )

  return {
    uploadingTestConfig,
    uploadingConfig,
    errorMessage,
    uploadingFile,
    uploadingFileSucceeded,
    uploadingTestConfigSucceeded,
    uploadingConfigSucceeded,
    showGeneralErrorPrompt,
    handleUpload,
  }
}
