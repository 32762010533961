import { FC } from 'react'
import { IconButton } from '@/common/button'
import { DownloadIcon, LinkIcon, TrashIcon } from '@/common/icons'
import { AlertDialog } from '@/common/modal'
import { useModalStore } from '@/store/modal'
import { usePermissionsStore } from '@/store/permissions'
import { IResource } from '@/types/resources'
import styles from '../index.module.scss'

interface Props {
  handleDelete: () => Promise<any>
  handleDownload: () => void
  handleCopy: () => void
  resource: IResource
}

export const ResourceCardActions: FC<Props> = ({
  handleDelete,
  handleDownload,
  handleCopy,
  resource,
}) => {
  const { registerModal } = useModalStore()

  const { isGlobalAdmin } = usePermissionsStore(state => state.computed)

  const openRemoveModal = () => {
    registerModal(
      <AlertDialog
        title="Delete resource"
        description="This resource will no longer be available to the organization."
        isDestructive={true}
        actionDescription="Delete"
        handleAction={handleDelete}
      />
    )
  }

  return (
    <div className={styles.ResourceCardHeaderAside}>
      {resource.public && (
        <IconButton
          id={`resource-${resource.id}-link`}
          tooltip="Copy URL to Clipboard"
          onClick={handleCopy}
        >
          <LinkIcon />
        </IconButton>
      )}
      {!resource.public && (
        <IconButton
          id={`resource-${resource.id}-download`}
          tooltip="Download Resource"
          onClick={handleDownload}
        >
          <DownloadIcon />
        </IconButton>
      )}
      {isGlobalAdmin && (
        <IconButton
          id={`resource-${resource.id}-delete`}
          tooltip="Delete Resource"
          onClick={openRemoveModal}
        >
          <TrashIcon />
        </IconButton>
      )}
    </div>
  )
}
