import {
  CreateInvoiceConfigMapping,
  CreateInvoiceConfiguration,
  EditInvoice,
  InvoiceConfiguration,
  InvoiceConfigurationResponse,
  InvoiceParams,
  InvoiceTransition,
  UpdateInvoiceConfiguration,
} from '@/api/types/invoice'
import { InvoiceNote } from '@/types/invoice'
import http from './http'

export const getInvoiceConfigs = (orgId: number) =>
  http.get<InvoiceConfigurationResponse>(
    `payout/organizations/${orgId}/invoices/configurations`
  )

export const createInvoiceConfig = (
  orgId: number,
  data: CreateInvoiceConfiguration
) =>
  http.post<InvoiceConfiguration>(
    `payout/organizations/${orgId}/invoices/configurations`,
    data
  )

export const updateInvoiceConfiguration = (
  orgId: number,
  configId: number,
  data: UpdateInvoiceConfiguration
) =>
  http.put<InvoiceConfiguration>(
    `payout/organizations/${orgId}/invoices/configurations/${configId}`,
    data
  )

export const deleteInvoiceConfig = (orgId: number, configId: number) =>
  http.delete(
    `payout/organizations/${orgId}/invoices/configurations/${configId}`
  )

export const createInvoiceConfigMapping = (
  orgId: number,
  configId: number,
  data: CreateInvoiceConfigMapping
) =>
  http.post(
    `payout/organizations/${orgId}/invoices/configurations/${configId}/mappings`,
    data
  )

export const deleteInvoiceConfigMapping = (
  orgId: number,
  configId: number,
  mappingId: number
) =>
  http.delete(
    `payout/organizations/${orgId}/invoices/configurations/${configId}/mappings/${mappingId}`
  )

/**
 * GET Invoice Notes
 */
export const getInvoiceNote = (orgId: number, invoiceId: number) =>
  http.get<InvoiceNote[]>(
    `/payout/organizations/${orgId}/invoices/${invoiceId}/notes`
  )

/**
 * Add Invoice Notes
 */
export const addInvoiceNote = (
  orgId: number,
  invoiceId: number,
  note: string
) =>
  http.post(`/payout/organizations/${orgId}/invoices/${invoiceId}/notes`, {
    note,
  })

/**
 * Delete Invoice Notes
 */
export const deleteInvoiceNote = (
  orgId: number,
  invoiceId: number,
  noteId: number
) =>
  http.delete(
    `/payout/organizations/${orgId}/invoices/${invoiceId}/notes/${noteId}`
  )

/**
 * Allows Customer Success to issue an Invoice
 */
export const issueInvoice = (body: InvoiceParams, orgId: number) =>
  http.post(`payout/organizations/${orgId}/invoices`, body)

/**
 * Change invoice status
 */
export const addInvoiceTransition = (
  invoiceId: number,
  body: InvoiceTransition,
  orgId: number
) =>
  http.post(
    `payout/organizations/${orgId}/invoices/${invoiceId}/transitions`,
    body
  )

//   **
//  * update invoice
//  */
export const editInvoice = (
  invoiceId: number,
  body: EditInvoice,
  orgId: number
) => http.put(`payout/organizations/${orgId}/invoices/${invoiceId}`, body)

/**
 * Retrieves the file data for a transaction's verification file.
 */
export const getVerificationFile = (transactionID: string, orgId: number) =>
  http.get<any>(
    `p1/organizations/${orgId}/pay/deduction_form?id=${transactionID}`,
    {
      responseType: 'arraybuffer',
    }
  )
