import { FC, useState } from 'react'
import { Alert } from '@/common/alert'
import { Button } from '@/common/button'
import { TextArea } from '@/common/input'
import { Flex } from '@/common/layout'
import { Dialog } from '@/common/modal'
import { useAddInvoiceNote } from '@/queries/invoice-configs'
import { useModalStore } from '@/store/modal'
import { PaybackRecord } from '@/types/paybacks'

interface Props {
  record: PaybackRecord
}

export const AddNoteModal: FC<Props> = ({ record }) => {
  const { removeModal } = useModalStore()

  const [open, setOpen] = useState(true)
  const [note, setNote] = useState('')
  const addInvoiceNote = useAddInvoiceNote()

  const close = () => {
    setOpen(false)
    removeModal()
  }

  const complete = () => {
    addInvoiceNote.mutate(
      {
        invoiceId: record.id,
        note,
      },
      {
        onSuccess: close,
      }
    )
  }

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNote(e.target.value)

  return (
    <Dialog open={open} setOpen={close} title="Add Invoice Note">
      <TextArea onChange={handleNoteChange} />
      {addInvoiceNote.isError && (
        <Alert color="danger">Encountered an error.</Alert>
      )}
      <Flex justify="end" css={{ mt: '$24' }}>
        <Button
          mode="filled"
          onClick={complete}
          loading={addInvoiceNote.isLoading}
        >
          Submit
        </Button>
      </Flex>
    </Dialog>
  )
}
